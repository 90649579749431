<div class="wp_flex--container flex-column">
    <div class="wp_flex__item--inherit wp_flex__item--vcenter">
        <!--<div class="wp-txt-body1 wp-mr-10" *ngIf="startTime">20th Oct 2019</div>-->
        <!--<div class="wp-txt-body1 wp-txt--secondary" *ngIf="startTime">09:00 AM - 01:00 PM</div>-->

        <div class="wp-txt-body1 wp-mr-10" *ngIf="dates && dates.length>0">{{dates[0].startTime | dateFormat:'date'}} - {{dates[dates.length-1].endTime | dateFormat:'date'}}</div>
        <div class="wp-txt-body1 btn-link" [matMenuTriggerFor]="DatesPopup" *ngIf="dates && dates.length>0">{{startTime | dateFormat:'time'}} - {{endTime | dateFormat:'time'}}</div>
        <!--<button mat-button class="mat-btn-primary" [matMenuTriggerFor]="DatesPopup">Dates Popup</button>-->
        <mat-menu #DatesPopup="matMenu" class="dates-popup opt-regular-booking">
            <div (click)="$event.stopPropagation();">
                <div class="wp_flex--container">
                    <div class="wp_flex__item wp-mb-10">
                        <h4 class="wp-heading-4">Apply answers to selected dates</h4>
                    </div>
                </div>
                <div class="wp_flex--container wp-mb-10">
                    <div class="wp_flex__item">
                        <mat-checkbox [(ngModel)]="isAllSelected" name="isAllSelected" (change)="selectAll($event)">All</mat-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <mat-accordion>
                            <div *ngFor="let mnth of yearMonthList; let h=index" >
                                <mat-expansion-panel *ngIf="mnth.dates && mnth.dates.length>0">
                                    <mat-expansion-panel-header>
                                        <div class="wp_flex--container">
                                            <div class="wp_flex__item">
                                                <mat-checkbox [checked]="isAllSelected" (change)="selectedMonthChanged($event,mnth.month)" name="{{mnth.month}}-j">{{mnth.month}} </mat-checkbox>
                                            </div>
                                        </div>
                                    </mat-expansion-panel-header>
                                    <div class="wp_flex--container flex-column">
                                        <div class="wp_flex__item" *ngFor="let dt of mnth.dates; let j=index">
                                            <mat-checkbox (change)="selectedDatesChanged($event,dt)" [checked]="selectedDatess.indexOf(dt) >= 0" name="{{dt}}-j">{{dt}}</mat-checkbox>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </div>
                        </mat-accordion>
                    </div>

                </div>
            </div>

            <div class="wp_flex--container justify-content-end wp-mt-10">
                <div class="wp_flex__item--inherit">
                    <button mat-button class="mat-btn-primary--small" (click)="onClickSave()">Apply</button>
                </div>
            </div>
        </mat-menu>

    </div>
    <div class="wp_flex__item--inherit wp_flex__item--vcenter">
        <div class="wp-txt-body2 font__semibold mat-expansion-panel-header-title">Q{{questionNumber}}. {{questionText}}</div>
        <span style="color: red;">{{mandatorySign}}</span>
    </div>
</div>
