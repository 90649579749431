import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

import { FacilitySummary } from 'src/modules/models/asset/facility-summary';
import { Store, UpdateFacility, Redirect, DeleteFacility, SetImmediateData, AppState, ICart } from 'src/modules/store/index';
import { BookingSaveStarted, BookingSaveCompleted, FacilitySearchLoading, FacilitySearchLoaded } from 'src/modules/store/loading/actions'


import { environment } from 'src/environments/environment';

import { BookingService } from 'src/modules/services/booking.service';

import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditModalComponent } from 'src/modules/booking-portal/booking-cart/edit-modal/edit-modal.component';
import { SystemConfig } from 'src/modules/system-configuration'
import { MatSnackBar } from '@angular/material/snack-bar';;
//import { PriceConcession } from 'src/modules/models/booking/price-group'
import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';
import { DeleteConfirmationComponent } from '../../../shared/delete-confirmation/delete-confirmation.component';
import { BookingCommonComponent } from 'src/modules/booking-portal/booking-common.component';

import { DecimalPipe } from '@angular/common'
import { UpsellSummary } from 'src/modules/models/item/item';
import { Router } from '@angular/router';
import { FacilityAdaptor } from 'src/modules/models/asset/facility-adaptor'
import { ItemService } from 'src/modules/services/item.service';
import { ItemPriceGroup } from 'src/modules/models/item/item-price-group';
import { BespokePackage } from 'src/modules/models/asset/bespoke-package';
import { BookingAdaptor } from 'src/modules/models/booking/booking-adaptor';
import { Booking } from 'src/modules/models/booking/booking';
import { AppSetting } from 'src/modules/models/settings/casual-portal/app-setting';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BookingStatus } from 'src/modules/models/booking/booking-status';
import moment from 'moment';
import { BookingContact } from 'src/modules/models/booking/booking-contact';
import { BookingPackages } from 'src/modules/models/booking/booking-packages';
import { Package } from 'src/modules/models/booking/package';
import { PriceConcession } from 'src/modules/models/client/client';
import { Event } from 'src/modules/models/booking/event'
import { BookingItemAssociation, BookingItems } from 'src/modules/models/booking/booking-items';
import { Utility } from 'src/modules/utility';
import { ClearCart, ResetBooking, SaveBooking } from 'src/modules/store/actions';
import { Action, ErrorType } from 'src/modules/models/enum';
import { WarningMessageComponent } from 'src/modules/shared/warning-message/warning-message.component';
import { Item } from 'src/modules/models/booking/item';
import { ILoadingStatus } from 'src/modules/store/loading/status-store';
import { Observable, ReplaySubject, Subject, Subscriber, Subscription, from } from "rxjs";
import { AssetService } from 'src/modules/services/asset.service';
import { TimeSlot } from 'src/modules/models/regular-portal/booking/manage-teams/time-slot';
import { SuccessMessageComponent } from 'src/modules/shared/success-message/success-message.component';
import { AdHocDocument } from 'src/modules/models/booking/adhoc-document'
@Component({
    selector: 'opt-cart-panel',
    templateUrl: './cart-panel.component.html'
})

export class CartPanelComponent extends BookingCommonComponent implements OnInit {
    @Input() facilitySummaries: FacilitySummary[];
    @Input() defaultEventName: string;
    @Input() isValidPackage: Promise<boolean>;
    @Input() selectedPackageDetail: BespokePackage;

    //@Input() isAllImmediateConfirmed: boolean;

    @Output() submitted: EventEmitter<number> = new EventEmitter<number>();
    @Output() makeEventNameValidation: EventEmitter<number> = new EventEmitter<number>();
    @Output() validatePackage: EventEmitter<number> = new EventEmitter<number>();
    @Output() ChangedStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

    value: boolean = false;

    isAgreed: boolean = false;
    isUpdated: boolean = false;
    isSaved: boolean = false;
    // url: string;
    bookingTermAndConditionMessage: string;
    bookingCartPageTopNavPannelMessage: any;

    systemDefaultPriceConsessionId: number;
    isSystemAllowedImmediateConfirmation: boolean;

    packageItems: any[] = [];
    eventTotal: number;
    bookingData: AppSetting;

    minAmount: number;
    maxAmount: number;

    PackageTotalLabel: string = "Event Total";

    booking: Booking;
    isAllImmediateConfirmed: boolean;

    cartData: ICart;
    errorMessages: string[] = [];
    warningMessages: string[] = [];
    isInternalUser: boolean = environment.IsInternalUser;
    validBookingTime = new Subject<boolean>();// new ReplaySubject(); //Subject<boolean>(); with this subscriber do not fire when same value is passed twice
    validBookingTimeSubs: Subscription;

    _buttonComplete: boolean = false;
    _isValidPackageSubscriber: Subscription;
    selectedBusinessArea: string;
    businessAreas: string[] = [];
    packageClasses: string[] = [];
    enableCartDataLog: boolean;


    constructor(private store: Store<any>, private _bookingService: BookingService, public dialog: MatDialog, private _sysConfig: SystemConfig,
        private _itemService: ItemService, private _assetService: AssetService,
        public snackBar: MatSnackBar, private router: Router, private sanitizer: DomSanitizer) {
        super(store, _itemService, snackBar);
        this.customerDefaultPriceConsessionId = 0;
        this.systemDefaultPriceConsessionId = 0;
        this.isSystemAllowedImmediateConfirmation = false;
        this.isLoggedIn = false;
        this.bookingData = (environment.AppSetting as AppSetting);
    }

    ngOnInit() {
        super.ngOnInit();


        // this.url = environment.TermsandConditionLink;
        this.bookingTermAndConditionMessage = environment.BookingTermAndConditionMessage;
        this.enableCartDataLog = environment.EnableCartDataLog;

        this.bookingCartPageTopNavPannelMessage = this.sanitizer.bypassSecurityTrustHtml(environment.Messages.BookingCartPageTopNavPannel);
        //this.priceDisplayOption = environment.PriceDisplayOption;
        //this.cart_subscriber = this.cart$.subscribe(state => {
        //    if (state != undefined && state.packageFilter != undefined && state.packageFilter != null)
        //        this.selectedClass = state.packageFilter.PackageClass;
        //    this.isAllImmediateConfirmed = state.isAllImmediateConfirmed;
        //    this.isLoggedIn = (state.contact != null && state.contact.firstName != null && state.contact.firstName != "") ? true : false;
        //    this.customerDefaultPriceConsessionId = (this.isLoggedIn) ? (state.contact.client.priceConcessionId != "") ? state.contact.client.priceConcessionId : 0 : 0;
        //    this.addedFacilities = state.addedFacilites;
        //    this.priceChangedFacilities = state.priceChangedFacilities;
        //    this.hasZeroPrice = state.hasZeroPrice;
        //});
        this.cart$.safeSubscribe(this, state => {
            this.addedFacilities = state.addedFacilites;
            this.priceChangedFacilities = state.priceChangedFacilities;
            //this.hasZeroPrice = state.hasZeroPrice;

            this.booking = state.booking;
            this.isAllImmediateConfirmed = state.isAllImmediateConfirmed;
            this.calculateTotalPrice(state);

            this.cartData = state as ICart;
        });

        if (this.bookingData.BookingConfig.PackageTotalLabel) {
            this.PackageTotalLabel = this.bookingData.BookingConfig.PackageTotalLabel;
        }


    }

    ngOnDestroy() {
        if (this._isValidPackageSubscriber) {
            this._isValidPackageSubscriber.unsubscribe();
        }


    }

    calculateTotalPrice(state) {
        let minAmount = 0;
        let maxAmount = 0;
        this.eventTotal = 0;
        if (state.addedFacilites != undefined && state.addedFacilites.length > 0) {
            state.addedFacilites.forEach((fc) => {
                minAmount += (fc.item.calculatedMinPrice && fc.item.calculatedMinPrice.priceIncludingTax) ? fc.item.calculatedMinPrice!.priceIncludingTax :
                    ((fc.item.calculatedMaxPrice && fc.item.calculatedMaxPrice.priceIncludingTax) ? fc.item.calculatedMaxPrice!.priceIncludingTax : 0);
                maxAmount += (fc.item.calculatedMaxPrice && fc.item.calculatedMaxPrice.priceIncludingTax) ? fc.item.calculatedMaxPrice!.priceIncludingTax :
                    (fc.item.calculatedMinPrice && fc.item.calculatedMinPrice.priceIncludingTax) ? fc.item.calculatedMinPrice!.priceIncludingTax : 0;
                if (fc.upsellSummaries && fc.upsellSummaries.length > 0) {
                    fc.upsellSummaries.forEach((fcUpsell) => {
                        minAmount += (fcUpsell.minPrice && fcUpsell.minPrice.priceIncludingTax) ? fcUpsell.minPrice!.priceIncludingTax :
                            ((fcUpsell.maxPrice && fcUpsell.maxPrice.priceIncludingTax) ? fcUpsell.maxPrice!.priceIncludingTax : 0);
                        maxAmount += (fcUpsell.maxPrice && fcUpsell.maxPrice.priceIncludingTax) ? fcUpsell.maxPrice!.priceIncludingTax :
                            (fcUpsell.minPrice && fcUpsell.minPrice.priceIncludingTax) ? fcUpsell.minPrice!.priceIncludingTax : 0;
                    })
                }
            })
        }
        if (state.packageFilter && state.packageFilter.Package && state.packageFilter.PackageDetail && state.packageFilter.PackageDetail.packageItems) {
            state.packageFilter.PackageDetail.packageItems.forEach((packegeItem) => {
                let packageItemTotal = 0;
                if (packegeItem.calculatedPackageItemPriceGroups) {
                    packegeItem.calculatedPackageItemPriceGroups.forEach((data: any) => {
                        packageItemTotal += data['priceIncludingTax'];
                    });
                    this.eventTotal += packageItemTotal;
                }
                // if (packegeItem.packageItemPriceInfo) {
                //     packageItemTotal += packegeItem.packageItemPriceInfo.priceIncludingTax;
                // }
                // else{
                //     this.eventTotal += packageItemTotal;
                // }
                // this.eventTotal += packageItemTotal;
            })
        }
        if (this.eventTotal != undefined) {
            this.minAmount = minAmount + this.eventTotal;
            this.maxAmount = maxAmount + this.eventTotal;
        } else {
            this.minAmount = minAmount;
            this.maxAmount = maxAmount;
        }

    }

    getDate() {
        let minDate = 0;
        let date;
        this.addedFacilities.forEach((facility) => {
            let startDate = new Date(facility.startTime);
            if (minDate) {
                if (startDate.getDate() < minDate) {
                    minDate = startDate.getDate();
                    date = facility.startTime;
                }
            } else {
                minDate = startDate.getDate();
                date = facility.startTime;
            }
        })
        return date;
    }

    isValidFacilitySummaries(facilitySummaries: FacilitySummary[]) {
        let selectedPackageClass = environment.PackageClasses.find(a => a.Id == this.selectedClass);
        let isvalid = (facilitySummaries != undefined) && (facilitySummaries.length > 0);
        let isAttendeesValid = true;
        let isValidTime = true;
        let leadDays = (environment.LeadDaysForBooking == null) ? 0 : environment.LeadDaysForBooking;
        let minimumDate = new Date();
        minimumDate.setDate(minimumDate.getDate() + leadDays);
        if (leadDays != 0)
            minimumDate.setHours(0, 0, 0, 0);

        if (isvalid) {
            facilitySummaries.forEach(function (fc) {
                if (isAttendeesValid) {
                    isAttendeesValid = (fc.attendees != undefined && !isNaN(fc.attendees) && fc.attendees != 0)
                }
                if (isValidTime) {
                    isValidTime = (fc.startTime != undefined && (new Date(fc.startTime) > minimumDate));
                }
                if ((!fc.item.calculatedMinPrice || !(fc.item.calculatedMinPrice.priceIncludingTax > -1)) &&
                    (!fc.item.calculatedMaxPrice || !(fc.item.calculatedMaxPrice.priceIncludingTax > -1))) {
                    isvalid = false;
                }
                fc.upsellSummaries.forEach(function (ups) {
                    if ((!ups.minPrice || !(ups.minPrice.priceIncludingTax > -1)) &&
                        (!ups.maxPrice || !(ups.maxPrice.priceIncludingTax > -1))) {
                        isvalid = false;
                    }

                });

            });
        }
        return { isvalid: isvalid, isAttendeesValid: isAttendeesValid, isValidTime: isValidTime };
    }
    isValid(facilitySummaries: FacilitySummary[]) {
        let av = this.isValidFacilitySummaries(facilitySummaries);
        return av.isvalid && av.isAttendeesValid && av.isValidTime;
    }
    public openEditPopup(val: FacilitySummary) {

        this.dialog.open(EditModalComponent, {
            data: { facility: val },
            panelClass: ['custom-dialog-container', 'w60modal'],
            height: 'auto',
            width: '60%'
            // DialogPosition : top
        });
    }

    clickEvent2() {
        //this.value = !this.value;
        this.ChangedStatus.emit(true);
    }

    isHaveupsellSummaryPrice(facility) {
        let price = this.upsellSummaryPrice(facility);
        return (price > -1)
    }
    deleteItem(obj: FacilitySummary) {
        let dialogRef = this.dialog.open(DeleteConfirmationComponent, {
            data: {
                message: ' Are you sure you need to delete this venue?',
                yesText: 'YES',
                noText: 'NO'
            },
            panelClass: ['custom-dialog-container', 'w30modal'],
            height: 'auto',
            width: '30%'
        });
        const sub = dialogRef.componentInstance.onAdd.subscribe((data: any) => {
            if (data == true) {
                this.store.dispatch(new DeleteFacility(obj.index));
            }
        });
    }



    async setLoading() {
        this.store.dispatch(new FacilitySearchLoading());
        //this.cd.detectChanges();

    }

    async clickSubmitEnquiry() {
        this.setLoading();
        setTimeout(() => this.onClickSubmitEnquiry(), 1);

    }

    async onClickSubmitEnquiry() {
        let loading = true;
        let _enableCartDataLog = environment.EnableCartDataLog;
        let isAlreadyBooked = (this.cartData && this.cartData.booking && this.cartData.booking.id) ? true : false;

        if (this.validBookingTimeSubs) {
            this.validBookingTimeSubs.unsubscribe();
        }

        //this.validatePackage.emit(1);
        if (!isAlreadyBooked) {
            this.validatePackage.emit(1);

            if (this._isValidPackageSubscriber) {
                this._isValidPackageSubscriber.unsubscribe();
            }

            let alreadyBooked = this.booking && this.booking.id ? true : false;
            //check if already booking exist
            if (alreadyBooked) {
                let _bkTIme = this.cartData.BookingCreatedTime;
                if (_bkTIme) {
                    let _dateNow = new Date();
                    let bookingExpireTimeInMinutes = (environment.AppSetting as AppSetting).BookingConfig.BookingExpireTimeInMinutes ? (environment.AppSetting as AppSetting).BookingConfig.BookingExpireTimeInMinutes : "15";
                    if ((bookingExpireTimeInMinutes && (_dateNow.getHours() * 60 + _dateNow.getMinutes()) - (_bkTIme.getHours() * 60 + _bkTIme.getMinutes())) > Number(bookingExpireTimeInMinutes)) {
                        //if that booking is there since 15 min expire it then a new bboking will be created
                        this.expireBooking(this.booking.id)
                        this.store.dispatch(new ResetBooking()); //  clear the booking in cart
                    }
                }
            }

            this._isValidPackageSubscriber = from(this.isValidPackage).safeSubscribe(this, _inputIsValidPackage => {
                console.log(_inputIsValidPackage); // data from parent

                if (_inputIsValidPackage) {

                    let isPriceConcessionAvailable = FacilityAdaptor.isPriceConcessionAvailable(this.facilitySummaries, this.selectedClass);
                    let selectedPackageClassDetail = environment.PackageClasses.find(a => a.Id == this.selectedClass);
                    let _appSetting = environment.AppSetting as AppSetting;
                    if (environment.IsAllowMultiplePriceConsession ||
                        _appSetting.BookingConfig.PriceDisplayOption == 1 ||
                        _appSetting.BookingConfig.PriceDisplayOption == 2 ||
                        (selectedPackageClassDetail.PriceMinConsessionId == "0" && selectedPackageClassDetail.PriceMaxConsessionId == "0")
                        || (_appSetting.BookingConfig.PriceDisplayOption == 3 &&
                            (isPriceConcessionAvailable.isHasValidMinPriceConcession || isPriceConcessionAvailable.isHasValidMaxPriceConcession))
                    ) {
                        let ValidFacilitySummary = this.isValidFacilitySummaries(this.facilitySummaries);

                        if (!ValidFacilitySummary.isValidTime) {
                            this.snackBar.openFromComponent(AlertMessageComponent, {
                                duration: 5000,
                                verticalPosition: 'top',
                                data: [environment.InsufficientSelectedTime],
                            });

                            this.store.dispatch(new FacilitySearchLoaded());

                            return;
                        }
                        if (this.isAgreed) {
                            if (ValidFacilitySummary.isvalid) {
                                if (ValidFacilitySummary.isAttendeesValid) {
                                    if (this.defaultEventName != null && this.defaultEventName != "" && this.defaultEventName != undefined) {
                                        if (this.validateMandatoryQuestion()) {
                                            loading = false;
                                            this._sysConfig.loadAppConfiguration().then(async () => {
                                                this.isSystemAllowedImmediateConfirmation = environment.ImmediateConfirmation.Allow;
                                                this.systemDefaultPriceConsessionId = environment.ImmediateConfirmation.DefaultPriceConsessionId;

                                                //validate on submit booking


                                                let _validationOutPut;
                                                let _BookingData = this.getBookingData();

                                                this.errorMessages = [];
                                                this.warningMessages = [];


                                                this.validBookingTimeSubs = this.validBookingTime.safeSubscribe(this, async _validBookingTime => {

                                                    if (this.cartData.userDefinedFunctions && this.cartData.userDefinedFunctions.length > 0) {
                                                        _validationOutPut = await this.CustomFunctionOnAddItemToBooking(_BookingData);
                                                    }

                                                    if (_validBookingTime && (!_validationOutPut || (_validationOutPut && _validationOutPut.ContinueProcess))) {

                                                        _BookingData.bookingPackages.forEach(x => {
                                                            x.bookingItems.forEach(y => {
                                                                let _itemId = y.item.id;
                                                                let _itemType = y.item.type;

                                                                y.item = new Item()
                                                                y.item.id = _itemId;
                                                                y.item.type = _itemType;
                                                            });
                                                        });


                                                        if (_enableCartDataLog) {
                                                            let _postCartData = JSON.parse(JSON.stringify(this.cartData));

                                                            if (_postCartData.hasOwnProperty('bespokePackages')) {
                                                                delete _postCartData.bespokePackages;
                                                            }
                                                            if (_postCartData.hasOwnProperty('userDefinedFunctions')) {
                                                                delete _postCartData.userDefinedFunctions;
                                                            }
                                                            if (_postCartData && _postCartData.AddedDocuments && _postCartData.AddedDocuments.length > 0) {
                                                                _postCartData.AddedDocuments.forEach((doc: AdHocDocument) => {
                                                                    // Delete the 'documentFile' property from each item
                                                                    delete doc.documentFile;
                                                                });
                                                            }

                                                            this._bookingService.PostCartDetails(_postCartData).subscribe((data: any) => {
                                                            },
                                                                err => {
                                                                    console.log("Cart Details Error : ", err);
                                                                });
                                                        }
                                                        // this.store.dispatch(new DoBooking());
                                                        if (!this.booking || !this.booking.id) {

                                                            //   this.store.dispatch(new DoBooking());
                                                            this.store.dispatch(new SaveBooking(_BookingData));

                                                        }
                                                        else {
                                                            //if booking id is there and immediate booking redirect to payment
                                                            if ((this.cartData.contact.client != undefined && this.cartData.contact.client != null)) {
                                                                if (this.isAllImmediateConfirmed) {

                                                                    this.store.dispatch(new SetImmediateData(this.booking));

                                                                } else {
                                                                    this.store.dispatch(new FacilitySearchLoaded());

                                                                }
                                                            }
                                                        }
                                                    } else {
                                                        if (this.errorMessages.length > 0) {
                                                            this.snackBar.openFromComponent(AlertMessageComponent, {
                                                                duration: 3000,
                                                                verticalPosition: 'top',
                                                                data: this.errorMessages,
                                                            });
                                                        }

                                                        if (this.warningMessages.length > 0) {
                                                            this.snackBar.openFromComponent(WarningMessageComponent, {
                                                                duration: 3000,
                                                                verticalPosition: 'top',
                                                                data: this.warningMessages,
                                                            });
                                                        }
                                                    }
                                                });

                                                if ((environment.AppSetting as AppSetting).BookingConfig.CheckAvailability.DisplayPreliminarySlotAsUnavailble && !alreadyBooked) {
                                                    await this.ValidateAvailabilityAtSubmit(_BookingData);
                                                }
                                                else {
                                                    this.validBookingTime.next(true); // subscribe as availble time slot
                                                }

                                            });
                                        }

                                    }
                                    else {
                                        this.makeEventNameValidation.emit(1);
                                    }
                                } else {
                                    this.snackBar.openFromComponent(AlertMessageComponent, {
                                        duration: 3000,
                                        verticalPosition: 'top',
                                        data: ["Please specify the number of attendees"],
                                    });
                                }
                            } else {
                                this.snackBar.openFromComponent(AlertMessageComponent, {
                                    duration: 5000,
                                    verticalPosition: 'top',
                                    data: ["Selected event type is not applicable for selected items, please change back the event type."],
                                });
                            }
                        } else {
                            this.snackBar.openFromComponent(AlertMessageComponent, {
                                duration: 5000,
                                verticalPosition: 'top',
                                data: ["Please accept the terms and conditions to proceed."],
                            });
                        }

                    }
                }

                if (loading) {
                    this.store.dispatch(new FacilitySearchLoaded());
                }
            });

        }
        else if ((this.cartData.contact && this.cartData.contact.client != undefined && this.cartData.contact.client != null)) {
            if (this.isAllImmediateConfirmed) {

                this.store.dispatch(new SetImmediateData(this.booking));

            } else {
                this.store.dispatch(new FacilitySearchLoaded());

            }
        } else {
            this.store.dispatch(new FacilitySearchLoaded());
            this.store.dispatch(new ClearCart()); //clearing the cart as old booking details are availble
            this.store.dispatch(new Redirect("CART-LOGIN"));
        }
    }

    validateMandatoryQuestion() {
        let allMandatoryQuestionsAnswerd = true;
        if (this.selectedPackageDetail && this.selectedPackageDetail.originalQuestion != undefined && this.selectedPackageDetail.originalQuestion.length > 0) {

            let filteredOriginalQuestions = this.selectedPackageDetail.originalQuestion.filter(pq => pq.bookingStatusId == environment.BookingStatus || (this.isAllImmediateConfirmed && String(environment.BookingApproveStatus) == pq.bookingStatusId));
            if (filteredOriginalQuestions && filteredOriginalQuestions.length > 0 && (this.selectedPackageDetail.question == undefined || this.selectedPackageDetail.question.length == 0)) {
                allMandatoryQuestionsAnswerd = false;

                this.snackBar.openFromComponent(AlertMessageComponent, {
                    duration: environment.messageDuration,
                    verticalPosition: 'top',
                    data: ["Please change package again and try."],
                });
                return allMandatoryQuestionsAnswerd;
            }

        }


        if (this.selectedPackageDetail && this.selectedPackageDetail.question && this.selectedPackageDetail.question.length > 0) {
            this.selectedPackageDetail.question.forEach(pq => {
                if (allMandatoryQuestionsAnswerd && pq.mandatory && (!FacilityAdaptor.isQuestionAnswerd(pq))) {
                    allMandatoryQuestionsAnswerd = false;
                }
            });
        }
        if (!allMandatoryQuestionsAnswerd) {
            this.snackBar.openFromComponent(AlertMessageComponent, {
                duration: environment.messageDuration,
                verticalPosition: 'top',
                data: ["Please fill all mandatory event questions."],
            });
            return allMandatoryQuestionsAnswerd;
        }
        if (this.facilitySummaries && this.facilitySummaries.length > 0) {
            this.facilitySummaries.forEach(fc => {
                if (allMandatoryQuestionsAnswerd && fc.item && fc.item && fc.item.question && fc.item.question.length > 0) {
                    fc.item.question.forEach(fq => {
                        if (allMandatoryQuestionsAnswerd && fq.mandatory && (!FacilityAdaptor.isQuestionAnswerd(fq))) {
                            allMandatoryQuestionsAnswerd = false;
                        }
                    });
                }
                if (allMandatoryQuestionsAnswerd && fc.upsellSummaries && fc.upsellSummaries.length > 0) {
                    fc.upsellSummaries.forEach(us => {
                        if (allMandatoryQuestionsAnswerd && us.question && us.question.length > 0) {
                            us.question.forEach(uq => {
                                if (allMandatoryQuestionsAnswerd && uq.mandatory && (!FacilityAdaptor.isQuestionAnswerd(uq))) {
                                    allMandatoryQuestionsAnswerd = false;
                                }
                            });
                        }
                    });
                }

            });
        }
        if (!allMandatoryQuestionsAnswerd) {
            this.snackBar.openFromComponent(AlertMessageComponent, {
                duration: environment.messageDuration,
                verticalPosition: 'top',
                data: ["Please fill all mandatory questions."],
            });
            return allMandatoryQuestionsAnswerd;
        }
        return allMandatoryQuestionsAnswerd;
    }



    //isPriceRange(minPrice, maxPrice) {
    //    if (minPrice == undefined || maxPrice == undefined)
    //        return false;

    //    var min = minPrice != undefined ? minPrice!.priceIncludingTax : 0;
    //    var max = maxPrice != undefined ? maxPrice!.priceIncludingTax : 0;
    //    // return min != max;
    //    var dp: DecimalPipe = new DecimalPipe('en');
    //    return dp.transform(min, '1.2-2') != dp.transform(max, '1.2-2');
    //}

    isPriceRange(facilitySummary: FacilitySummary, upsell: UpsellSummary = null, isUpsell: boolean) {

        if (!isUpsell && facilitySummary && facilitySummary.item && (facilitySummary.item.calculatedMinPrice == undefined || facilitySummary.item.calculatedMinPrice.priceIncludingTax == undefined || facilitySummary.item.calculatedMaxPrice == undefined || facilitySummary.item.calculatedMaxPrice.priceIncludingTax == undefined)) return false;
        if ((isUpsell && upsell.minPrice == null && upsell.maxPrice == null)) return false;

        //if (this.customerDefaultPriceConsessionId != 0 || this.isSystemAllowedImmediateConfirmation && facilitySummary.item.allowImmediateConfirmedBookings == 1) {
        //    return false;
        //} else {

        if (isUpsell)
            return BookingAdaptor.isRange(upsell.minPrice, upsell.maxPrice);
        else if (facilitySummary && facilitySummary.item)
            return BookingAdaptor.isRange(facilitySummary.item.calculatedMinPrice, facilitySummary.item.calculatedMaxPrice);
        // }
    }

    deleteUpsellItem(facilitySummary: FacilitySummary, upsellSummary: UpsellSummary) {
        let indexnum = facilitySummary.upsellSummaries.findIndex(x => x.id == upsellSummary.id);
        if (indexnum != undefined) {
            facilitySummary.upsellSummaries.splice(indexnum, 1);
            this.store.dispatch(new UpdateFacility(facilitySummary));
        }
    }

    signUp() {
        this.store.dispatch(new Redirect("SIGNUPFROMBOOKINGDETAIL"));
    }

    Login() {
        this.router.navigate(["/client/login"], { queryParams: { DirectLoginFromBooking: "1" } });
    }

    upsellSummaryPrice(facility) {
        var maxupsellPrice = facility.maxPrice;
        var minupsellPrice = facility.minPrice;
        var upsellPriceAvailble = (maxupsellPrice != undefined) ? maxupsellPrice : minupsellPrice
        if (upsellPriceAvailble != undefined) {
            if (maxupsellPrice.priceIncludingTax != null) {
                return maxupsellPrice.priceIncludingTax;
            } else if (minupsellPrice.priceIncludingTax != null) {
                return minupsellPrice.priceIncludingTax;
            }
        }
    }
    getPreviousFacilitySummary(facilitySummary: FacilitySummary) {
        var priceChangeFacility = this.priceChangedFacilities.find(x => x.index == facilitySummary.index);
        return priceChangeFacility
    }
    getPreviousUpsell(facilitySummary: FacilitySummary, upsell: UpsellSummary = null) {
        var priceChangeFacility = this.priceChangedFacilities.find(x => x.index == facilitySummary.index);
        if (priceChangeFacility && priceChangeFacility.upsellSummaries) {
            var prevUpsell = priceChangeFacility.upsellSummaries.filter(u => u.id == upsell.id);
            if (prevUpsell && prevUpsell.length > 0)
                return prevUpsell[0]
        }
    }
    isPriceChangedAfterLoggedIn(facilitySummary: FacilitySummary, upsell: UpsellSummary = null) {
        var priceChangeFacility = this.getPreviousFacilitySummary(facilitySummary);
        if (!upsell) {
            let min, max, prevmin, prevmax;
            if (facilitySummary.item.calculatedMinPrice != undefined)
                min = facilitySummary.item.calculatedMinPrice.priceIncludingTax;
            if (facilitySummary.item.calculatedMaxPrice != undefined)
                max = facilitySummary.item.calculatedMaxPrice.priceIncludingTax;

            if (priceChangeFacility) {
                if (priceChangeFacility.item.calculatedMinPrice != undefined)
                    prevmin = priceChangeFacility.item.calculatedMinPrice.priceIncludingTax;
                if (priceChangeFacility.item.calculatedMaxPrice != undefined)
                    prevmax = priceChangeFacility.item.calculatedMaxPrice.priceIncludingTax;
            }
            return this.isLoggedIn && this.priceChangedFacilities != undefined && this.priceChangedFacilities.length > 0 &&
                (min != prevmin || max != prevmax)

        } else {
            var prevUpsell = this.getPreviousUpsell(facilitySummary, upsell);
            if (prevUpsell) {
                return this.isLoggedIn && this.priceChangedFacilities != undefined && this.priceChangedFacilities.length > 0 &&
                    ((upsell.minPrice && upsell.minPrice.priceIncludingTax &&
                        Math.round(upsell.minPrice.priceIncludingTax * 1000) / 1000 != Math.round(prevUpsell.minPrice.priceIncludingTax * 1000) / 1000) ||
                        (upsell.maxPrice && upsell.maxPrice.priceIncludingTax &&
                            Math.round(upsell.maxPrice.priceIncludingTax * 1000) / 1000 != Math.round(prevUpsell.maxPrice.priceIncludingTax * 1000) / 1000)
                    );
            }
        }

    }


    getBookingData() {
        var appsetting = environment.AppSetting as AppSetting;
        let _bk = new Booking();

        //booking.eventStartDate and eventDate are created omly after booking is created and patched
        //initially this doesnt have a value
        /*  if(this.booking){
              _bk.eventStartDate = this.booking.eventStartDate;
              _bk.eventEndDate = this.booking.eventEndDate;
          }*/

        _bk.bookingStatus = new BookingStatus();
        _bk.bookingStatus.id = (this.cartData.isAllImmediateConfirmed) ? String(environment.BookingApproveStatus) : String(environment.BookingStatus);

        _bk.salesChannelId = environment.SalesChannel;
        _bk.eventName = this.cartData.packageFilter.EventName;
        _bk.poReference = this.cartData.packageFilter.ledgerNumber;
        //_bk.temporary = false;

        // _bk.confirmed = false;
        _bk.tid = "1";
        _bk.attendees = 1;
        _bk.cancelled = false;
        _bk.margin = 0;
        _bk.bookingPackages = [];

        //if (this.cartData.contact.client != undefined) {
        //    _bk.contact = new BookingContact();
        //    _bk.contact.id = this.cartData.contact.id;
        //}

        _bk.confirmed = true;
        _bk.temporary = false;

        let tid = 1;

        let selectedPackage = this.cartData.bespokePackages.find(p => p.id == this.cartData.packageFilter.Package.toString());
        if (selectedPackage == undefined) {
            return;
        }

        let bkpackages = new BookingPackages();
        bkpackages.event = new Event();
        bkpackages.package = new Package();
        bkpackages.package.id = selectedPackage.id;
        if (appsetting.BookingConfig.SetEventAsPackageName) {
            bkpackages.name = _bk.eventName;
        }
        else {
            bkpackages.name = selectedPackage.name;
        }
        bkpackages.package.name = selectedPackage.name;

        bkpackages.package.userDefinedFields = selectedPackage.userDefinedFields;

        //bkpackages.priceTypeId = selectedPackage.priceTypeId;
        bkpackages.event.id = selectedPackage.eventId.toString();
        bkpackages.quantity = "1";
        //bkpackages.taxRate = 0;
        //bkpackages.margin = 0;
        bkpackages.attendees = 1;
        //bkpackages.singleDayPackage = false;
        bkpackages.id = ((_bk.bookingPackages.length + 1) * (-1)).toString();
        bkpackages.bookingItems = [];
        let isNewPackage = true;

        let selectedPackageClassDetail = environment.PackageClasses.find(a => a.Id == this.cartData.packageFilter.PackageClass);


        var priceConcessionId;
        if (this.cartData.contact.client && this.cartData.contact.client.priceConcessionId &&
            this.cartData.contact.client.priceConcessionId != 0 &&
            this.cartData.contact.client.priceConcessionId != "") {
            priceConcessionId = this.cartData.contact.client.priceConcessionId.toString();
        } else if (environment.ImmediateConfirmation.DefaultPriceConsessionId != 0)
            if (environment.ImmediateConfirmation.Allow &&
                environment.ImmediateConfirmation.DefaultPriceConsessionId != 0 &&
                this.cartData.isAllImmediateConfirmed) {
                priceConcessionId = environment.ImmediateConfirmation.DefaultPriceConsessionId.toString();
            } else if (appsetting.BookingConfig.DefaultPriceConsessionId && appsetting.BookingConfig.DefaultPriceConsessionId != 0) { // 0 is not an id
                priceConcessionId = appsetting.BookingConfig.DefaultPriceConsessionId.toString();
            }
            else {
                //Todo this need to be validate when adding new facilities
                if (!environment.IsAllowMultiplePriceConsession) {
                    if (((environment.AppSetting as (AppSetting)).BookingConfig.PriceDisplayOption == 3) && (!(selectedPackageClassDetail.PriceMinConsessionId == "" && selectedPackageClassDetail.PriceMaxConsessionId == ""))) {
                        let isPriceConcessionAvailable = FacilityAdaptor.isPriceConcessionAvailable(this.cartData.addedFacilites, this.cartData.packageFilter.PackageClass);
                        if (isPriceConcessionAvailable.isHasValidMinPriceConcession) priceConcessionId = (isPriceConcessionAvailable.PriceMinConsessionId);
                        if (isPriceConcessionAvailable.isHasValidMaxPriceConcession) priceConcessionId = (isPriceConcessionAvailable.PriceMaxConsessionId);
                    }
                }
            }

        if (priceConcessionId != null) {
            _bk.priceConcession = new PriceConcession();
            _bk.priceConcession.id = priceConcessionId;
        }

        if (appsetting.BookingConfig.ConsiderClientPaymentTerm == true && this.cartData.contact.client.paymentTerm) {
            _bk.paymentTerm = this.cartData.contact.client.paymentTerm;

        }



        // var facilityVenueEmailId = "";
        this.cartData.addedFacilites.forEach((fc, i) => {
            if (this.cartData.packageFilter.Attendees != null) {
                _bk.attendees = this.cartData.packageFilter.Attendees;
                bkpackages.attendees = this.cartData.packageFilter.Attendees;
            }
            else {
                _bk.attendees = (fc.attendees > _bk.attendees) ? fc.attendees : _bk.attendees;
                bkpackages.attendees = (fc.attendees > bkpackages.attendees) ? fc.attendees : bkpackages.attendees;
            }

            // if (fc.venue && fc.venue.email && facilityVenueEmailId == "") {
            //     facilityVenueEmailId = fc.venue.email;
            // }
            // populate BookingItems
            var bookingItem = new BookingItems();
            //Temp solu
            var tp = FacilityAdaptor.getItemPrefix(fc.item.type.toLowerCase()) + fc.item.type;
            if (fc.item.type.toLowerCase() == "assetitem") {
                tp = "PrivateBookingGeneralAdmissionItem";
            }
            bookingItem.type = tp;
            // bookingItemTypeNames.push(tp);

            let qty = FacilityAdaptor.getItemQuantity(fc.item, fc.attendees);

            bookingItem.allocations = [];
            bookingItem.quantity = qty;
            // bookingItem.item.id = fc.item.id;
            // bookingItem.item.type = fc.item.type;

            bookingItem.item = fc.item;

            bookingItem.startTime = Utility.convertToISO(Utility.convertISOToDate(fc.startTime));
            bookingItem.endTime = Utility.convertToISO(Utility.convertISOToDate(fc.endTime));
            bookingItem.upsell = true;
            bookingItem.name = fc.item.name;

            // populate suitable package


            if (fc.itemPrice != undefined && !(isNaN(fc.itemPrice.concessionId))) {
                //bookingItem.priceTypeId = fc.itemPrice.priceTypeId;
            }

            // populate BookingPackages

            let packageStartTime = null;
            let packageEndTime = null;


            // Package start and End time calculation
            if (isNewPackage) {
                packageStartTime = Utility.convertToISO(Utility.convertISOToDate(fc.startTime));
                packageEndTime = Utility.convertToISO(Utility.convertISOToDate(fc.endTime));
                isNewPackage = false;
            }
            else {
                if (Utility.convertISOToDate(fc.startTime) < Utility.convertISOToDate(bkpackages.startDate)) {
                    packageStartTime = Utility.convertToISO(Utility.convertISOToDate(fc.startTime));
                }
                else {
                    packageStartTime = Utility.convertToISO(Utility.convertISOToDate(bkpackages.startDate));
                }

                if (Utility.convertISOToDate(fc.endTime) > Utility.convertISOToDate(bkpackages.endDate)) {
                    packageEndTime = Utility.convertToISO(Utility.convertISOToDate(fc.endTime));
                }
                else {
                    packageEndTime = Utility.convertToISO(Utility.convertISOToDate(bkpackages.endDate));
                }
            }

            bkpackages.startDate = packageStartTime;
            bkpackages.endDate = packageEndTime;

            bookingItem.id = (tid * (-1)).toString();
            if (bookingItem.allocations == undefined || bookingItem.allocations == null) {
                bookingItem.allocations = [];
            }

            fc.allocations.forEach(function (alc) {

                alc.id = (tid * (-1)).toString();
                alc.timeslot.id = (tid * (-1)).toString();
                alc.attendees = fc.attendees;
                bookingItem.allocations.push(alc);
                tid++;
            });

            bookingItem.facilityId = fc.id;

            bkpackages.bookingItems.push(bookingItem);


            if (fc.upsellSummaries != null && fc.upsellSummaries != undefined && fc.upsellSummaries.length > 0) {
                fc.upsellSummaries.forEach(function (upsellSummary) {

                    // populate BookingItems
                    var bookingUpsellItem = new BookingItems();
                    bookingUpsellItem.type = FacilityAdaptor.getItemPrefix(upsellSummary.type.toLowerCase()) + upsellSummary.type;
                    // bookingItemTypeNames.push(FacilityAdaptor.getItemPrefix(upsellSummary.type.toLowerCase()) + upsellSummary.type);
                    bookingUpsellItem.quantity = upsellSummary.qty;
                    bookingUpsellItem.item.id = upsellSummary.id
                    bookingUpsellItem.item.type = upsellSummary.type;
                    bookingUpsellItem.startTime = Utility.convertToISO(Utility.convertISOToDate(fc.startTime));
                    bookingUpsellItem.endTime = Utility.convertToISO(Utility.convertISOToDate(fc.endTime));
                    bookingUpsellItem.upsell = true;
                    bookingUpsellItem.name = upsellSummary.name;

                    // populate suitable package

                    upsellSummary.itemPrice = upsellSummary.minPrice == undefined || upsellSummary.minPrice == null ? upsellSummary.maxPrice : upsellSummary.minPrice;

                    if (upsellSummary.itemPrice != undefined && !(isNaN(upsellSummary.itemPrice.concessionId))) {
                        bookingUpsellItem.priceTypeId = upsellSummary.itemPrice.priceTypeId;
                    }

                    // populate BookingPackages Time

                    let packageStartTime = null;
                    let packageEndTime = null;

                    if (Utility.convertISOToDate(upsellSummary.startTime) < Utility.convertISOToDate(bkpackages.startDate)) {
                        packageStartTime = Utility.convertToISO(Utility.convertISOToDate(upsellSummary.startTime));
                    }
                    else {
                        packageStartTime = Utility.convertToISO(Utility.convertISOToDate(bkpackages.startDate));
                    }

                    if (Utility.convertISOToDate(upsellSummary.endTime) > Utility.convertISOToDate(bkpackages.endDate)) {
                        packageEndTime = Utility.convertToISO(Utility.convertISOToDate(upsellSummary.endTime));
                    }
                    else {
                        packageEndTime = Utility.convertToISO(Utility.convertISOToDate(bkpackages.endDate));
                    }


                    bkpackages.startDate = packageStartTime;
                    bkpackages.endDate = packageEndTime;

                    bookingUpsellItem.id = (tid * (-1)).toString();

                    bookingUpsellItem.bookingItemAssociations = [];
                    let bookingItemAssociation = new BookingItemAssociation();
                    bookingItemAssociation.id = (tid * (-1)).toString();;
                    bookingItemAssociation.allocatedQuantity = upsellSummary.qty;
                    bookingItemAssociation.startTime = Utility.convertToISO(Utility.convertISOToDate(upsellSummary.startTime));
                    bookingItemAssociation.endTime = Utility.convertToISO(Utility.convertISOToDate(upsellSummary.endTime));
                    bookingItemAssociation.asset.id = fc.id;
                    bookingItemAssociation.primaryBookingSpaceItem = {
                        id: bookingItem.id,
                        type: "bookingItem"
                    }

                    bookingUpsellItem.bookingItemAssociations.push(bookingItemAssociation);

                    bkpackages.bookingItems.push(bookingUpsellItem);
                    tid++;


                });
            }



        });
        _bk.bookingPackages.push(bkpackages);

        return _bk;
    }

    async CustomFunctionOnAddItemToBooking(bookingData) {
        //check for user defined validation
        let _validationOutPut;
        let _validateFunction = this.cartData.userDefinedFunctions.find(x => (x.customFunctionAction.action == Action.OnSubmitEnquiry))

        if (_validateFunction && _validateFunction.functionName && window[_validateFunction.functionName]) {

            let _options = {

                preliminaryAsUnavailable: this.bookingData.BookingConfig.CheckAvailability.DisplayPreliminarySlotAsUnavailble

            }

            _validationOutPut = await window[_validateFunction.functionName](bookingData, _options);

            if (_validationOutPut && _validationOutPut.Messages && _validationOutPut.Messages.length > 0) {

                _validationOutPut.Messages.forEach(er => {
                    if (er.ErrorType == ErrorType.Error) {
                        this.errorMessages.push(er.Detail)

                    }
                    if (er.ErrorType == ErrorType.Warning) {
                        this.warningMessages.push(er.Detail)

                    }

                });


            }


        }

        return _validationOutPut;

    }

    showEventTotal() {
        let filter = this.cartData && this.cartData.packageFilter;

        if (filter) {
            let packageDetail = filter.PackageDetail;
            if (packageDetail && packageDetail.packageItems && packageDetail.packageItems.length > 0) {
                return true;
            }
        }

        return false;
    }

    ValidateAvailabilityAtSubmit(BookingData: Booking) {

        let _bookingItems: BookingItems[] = [];
        BookingData.bookingPackages.map(bp => bp.bookingItems.forEach(bi => _bookingItems.push(bi)));

        var _bookingDates = _bookingItems.map(dt => (new Date(dt.startTime)));
        var _sortedDates = _bookingDates.sort((a, b) => a.getTime() - b.getTime())

        var _formData = {
            startTime: Utility.convertToISO(new Date(new Date(_sortedDates[0]).setHours(0, 0))),
            endTime: Utility.convertToISO(new Date(new Date(_sortedDates[_sortedDates.length - 1]).setHours(23, 59)))
        }

        // get facilty ids and join with comas
        let _assetIds = BookingData.bookingPackages.map(bp => bp.bookingItems.filter(bi => bi.facilityId).map(bi => bi.facilityId)).map(fi => fi).join(",");

        if (this.cartData && this.cartData.booking && (!this.cartData.booking.id || this.cartData.booking.id == "" || Number(this.cartData.booking.id) < 0)) // booking not saved
        {
            //check whether this is a bookable time
            let _timeslotSearch = this._assetService.timeslotSearch(_assetIds, _formData.startTime, _formData.endTime)
                .safeSubscribe(this, async data => {

                    let _unAvailableTimeslots: TimeSlot[] = [];
                    let ids = _assetIds.split(',')

                    ids.forEach(element => {
                        let _bookedFacilties = BookingData.bookingPackages.map(bp => bp.bookingItems.filter(bi => bi.facilityId == element))[0];

                        _bookedFacilties.forEach(_bF => {
                            let _bsStrat = moment(_bF.startTime);
                            let _bsEnd = moment(_bF.endTime);

                            data.forEach(ts => {
                                if (ts.asset.id == element && _bsStrat.format('YYYY-MM-DD') == moment(Utility.convertToISO(ts.startTime)).format('YYYY-MM-DD') && // unavailble slot for a facility of the same date
                                    _bsStrat.diff(moment(Utility.convertToISO(ts.endTime)), 'minutes') < 0 && // unavailble slot et is greater than booked slots st and
                                    _bsEnd.diff(moment(Utility.convertToISO(ts.startTime)), 'minutes') > 0 && // unavailble slot st is less than booked slots et then the slot is already booked
                                    ((environment.AppSetting as AppSetting).BookingConfig.CheckAvailability.DisplayPreliminarySlotAsUnavailble || !ts.preliminary)) // not a preliminary timeslot
                                {
                                    _unAvailableTimeslots.push(ts);
                                }
                            })
                        })
                    });

                    if (_unAvailableTimeslots && _unAvailableTimeslots.length > 0) {
                        this.errorMessages.push("Selected timeslot is unavailable as it is already booked for the selected date");
                        this.validBookingTime.next(false);
                    }
                    else {
                        this.validBookingTime.next(true);
                    }
                });
        }
        else {
            this.validBookingTime.next(true);
        }

    }

    expireBooking(bookingiId): boolean {
        let _immediateBookingFailedStatus = (environment.AppSetting as AppSetting).BookingConfig.ImmediateBookingFailedStatus.toString();

        if (_immediateBookingFailedStatus && Number(_immediateBookingFailedStatus) != 0) {

            let expired = true;
            var _bk = new Booking();
            _bk.id = bookingiId;
            _bk.bookingStatus = new BookingStatus();
            _bk.bookingStatus.id = (environment.AppSetting as AppSetting).BookingConfig.ImmediateBookingFailedStatus.toString();
            _bk.type = "booking";
            _bk.isExpired = true;

            this._bookingService.BookingPatch(bookingiId, _bk).subscribe(result => {
                if (!result.body.isError) {
                    expired = true;
                    return expired;
                }
                else {
                    result.body.errors.forEach(e => {
                        if (e.ErrorMessage == "Payment Already Done") {
                            expired = false;
                            return expired;
                        }
                    });
                }

                // this.store.dispatch(new FacilitySearchLoaded());
            }, err => {
                expired = false;
                return expired;

            });

            return expired;
        }
    }

}
