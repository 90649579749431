<div class="wm-modals-container wm-custom-modal-wrapper" style="max-width: unset;">
  <div class="mmc-modal-header mmc_flex--container wm-header" >
    <div class="sub-header-left">
      <div class="_icon-carge">
        <span class="material-icons wm-client-search-icon">
          add
          </span>
      </div>
      <div class="left-header-title">
        <span>{{ data.data.type === WMCustomerOptions.EDIT_CUSTOMER ? WMModelTitle.EDIT_CLIENT : WMModelTitle.ADD_CLIENT }}</span>
      </div>
    </div>
    <div class="sub-header-right">
      <div class="mmc_flex__item--inherit mmc_flex__item--vcenter close-icon-cage mmc_just-content-center mat-button">
        <button  mat-button (click)="close()" (keydown.enter)="close()" class="mat-button"><i class="material-icons">close</i></button>
      </div>
    </div>
  </div>
  <div class="wm-client-search-body">
    <iframe [src]="url" scrolling="no" class="wm_search-add-client_iframe" style="overflow: hidden;"></iframe>
  </div>
</div>
