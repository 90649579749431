<mat-expansion-panel [expanded]="true" [disabled]="true">
  <mat-expansion-panel-header  class="mat-header">
    <opt-question-header [dates]="dates" [isMandatory]="bookingQues?.question?.mandatory"
      [questionNumber]="questionNumber" [questionText]="bookingQues?.question?.questionText"
      (popselectedDates)="RecveselectedDates($event)"></opt-question-header>
  </mat-expansion-panel-header>
  <div class="mmc_flex--container fd-col">
    
    <div class="mmc_flex__item m1 mr-3 p25 ">
      <span class="pw-txt-s2 pr-2 pw-text-color__tertiary">{{getUplodedFiles().length}} {{"QUESTIONPOPUP.files uploaded" | optimotranslate}}</span>
      <ul class="list-group" style="background: transparent;height: 155px;
      overflow: auto;">
        <li *ngFor="let d of getUplodedFiles(); let i=index"
          class="list-group-item d-flex justify-content-between align-items-center px-0"
          style="background: transparent; border: none;">
          <div class="mmc_flex--container fd-col w-100">
            <div class="mmc_flex__item p75">
              <span style="color: #517cdc;
              font-weight: bold;cursor: pointer;" (click)="viewFile(d.id)"  >{{d.name}}</span>
            </div>
            <div class="mmc_flex__item text-right p25">
              <span (click)="removeFile(d.id)" style="cursor: pointer;"> <i class="material-icons">close</i></span>
            </div>
          </div>

        </li>
      </ul>
    </div>
    <div class="mmc_flex__item m1 mr-3 p75 ">
      <div class="mmc_flex--container fd-col mb-2">
        <div class="mmc_flex__item m1 mr-3 p50 ">
          <span class="pw-txt-s2 pr-2 pw-text-color__tertiary">{{minCount}} {{"QUESTIONPOPUP.documents required" | optimotranslate}}</span>
        </div>
        <div class="mmc_flex__item m1 p50 text-right">
          <span class="pw-txt-s2  pw-text-color__tertiary">{{"QUESTIONPOPUP.Maximum" | optimotranslate}} {{documentUpldSize}}</span>
        </div>
      </div>
      <div class="file-upload--card" *ngIf="maxCount >getUplodedFiles().length; else fileUpload">
        <input id="fileupload" type="file" (change)="onFileChanged($event)" #fileInput style="display:none"
          #fileInputTag>
        <file-drop class="file-drop" headertext="" (onFileDrop)="dropped($event)" showBrowseBtn="true"
          browseBtnLabel="Select a file from your computer">
          <div class="mmc_flex__item--vcenter" onclick="$('#fileupload').trigger('click'); return false;">
            <div class="mmc_flex--container">
              <div class="mmc_flex__item--vcenter upload-icon mcc-file-upload">
                <i class="material-icons pw-ico-color">insert_drive_file</i>
              </div>
            </div>
          </div>
          <div class="mmc_flex__item--vcenter doc-up-text--mobile d-mobile">
            <span class="link-primary" onclick="$('#fileupload').trigger('click'); return false;">{{"QUESTIONPOPUP.Select a file from your device" | optimotranslate }}</span>
          </div>
          <div class="mmc_flex__item--vcenter doc-up-text--desktop h-mobile">
            <span class="pw-txt-b1 pr-2 pw-text-color__tertiary">{{"QUESTIONPOPUP.Drag your files here or" | optimotranslate}}</span>
            <span class="link-primary" onclick="$('#fileupload').trigger('click'); return false;">{{"QUESTIONPOPUP.Select a file from your device" | optimotranslate}}</span>
          </div>
        </file-drop>
      </div>
      <ng-template #fileUpload>
        <div class="file-upload--card">
          <div class="file-drop py-3" >
            <div class="mmc_flex--container mb-3">
              <div class="mmc_flex__item--vcenter m-auto">
                <div class="mmc_flex--container">
                  <div class="mmc_flex__item--vcenter upload-icon mcc-file-upload">
                    <i class="material-icons pw-ico-color">block</i>
                  </div>
                </div>
              </div>
            </div>
            <div class="mmc_flex--container">
              <div class="mmc_flex__item--vcenter m-auto">
                <span class="pw-txt-s1  pw-text-color__tertiary">
                 {{"QUESTIONPOPUP.Maximum number of documents for the question has been Uploaded" | optimotranslate}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

    </div>
  </div>
</mat-expansion-panel>
