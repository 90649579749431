
import {debounceTime} from 'rxjs/operators';
import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { DecimalPipe } from '@angular/common';

import { FacilitySummary } from 'src/modules/models/asset/facility-summary';
import { UpsellSummary, UnitPrice, BookingItemPrices } from 'src/modules/models/item/item';
import { FacilityAdaptor } from 'src/modules/models/asset/facility-adaptor';
import { BookingAdaptor } from 'src/modules/models/booking/booking-adaptor';
import { UpsellAdaptor } from 'src/modules/models/asset/upsell-adaptor';
import { environment } from 'src/environments/environment';

import { AssetService } from 'src/modules/services/asset.service';
import { ItemService } from 'src/modules/services/item.service';

import { Utility } from 'src/modules/utility';
import { Store, ICart, UpdateFacility } from 'src/modules/store/index';
import { ILoadingStatus } from 'src/modules/store/loading/status-store';
import { FacilitySearchLoading, FacilitySearchLoaded, LoadingCompleted } from 'src/modules/store/loading/actions';
import { Observable, BehaviorSubject, Subscription } from "rxjs";
import { ItemPriceGroup } from 'src/modules/models/item/item-price-group';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AppSetting } from 'src/modules/models/settings/casual-portal/app-setting';
@Component({
    selector: 'opt-upsell',
    templateUrl: './upsell.component.html'
})

export class UpsellComponent implements OnInit {
    @Output() onSubmit = new EventEmitter<any>(true);
    cart$: Observable<ICart>;
    loader$: Observable<ILoadingStatus>;
    cart_subscriber: any;
    facilitySummary: any;
    upsells: UpsellSummary[] = [];

    recordCount: number = 0;
    pageSize = 5;
    pagenumber = 0;
    pageSizeOptions: number[] = [5, 10, 25, 100];
    searchString: string = "";

    selectedItemType: number = 0;
    selectedClass: number;
    selectedClassDetails: any;

    upsell: any;
    isEdit: boolean = false;
    //availableConsessions = [];
    bookingItem: any;
    qtyChange$: BehaviorSubject<UpsellSummary> = new BehaviorSubject(null);
    subscription: Subscription = new Subscription();
    packageClasses: any[];

   

   // isLoggedIn: boolean;
   // customerDefaultPriceConsessionId: number;
    isAllImmediateConfirmed: boolean;
    //isSystemAllowedImmediateConfirmation: boolean;
    //systemDefaultPriceConsessionId: number;
  priceConsessionId: number;
  isBookingEdit: boolean = false;
  isGroupByFacilityNameInBookingView: boolean = false;
  
  _upsell =   (environment.AppSetting as AppSetting).BookingConfig.UpsellPrice;

    constructor(public dialogRef: MatDialogRef<UpsellComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private _assetService: AssetService, private _itemService: ItemService, private store: Store<any>) {
        this.facilitySummary = data.fc;
        console.log("facilitySummary -- ", this.facilitySummary)
        this.upsell = data.us;
        this.isEdit = data.isEdit;
        this.cart$ = this.store.select('cart');
        this.loader$ = this.store.select('loader');
        this.packageClasses = this._upsell.PackageClases.filter(cls => cls.Id != "0");
      this.isBookingEdit = data.isBookingEdit;
       // this.isSystemAllowedImmediateConfirmation = environment.ImmediateConfirmation.Allow;
       // this.systemDefaultPriceConsessionId = environment.ImmediateConfirmation.DefaultPriceConsessionId;
        //this.isGroupByFacilityNameInBookingView=environment.IsGroupByFacilityNameInBookingView
        console.log(data);
       
        this.cart_subscriber = this.cart$.subscribe(state => {

            this.isAllImmediateConfirmed = state.isAllImmediateConfirmed;
            this.priceConsessionId = BookingAdaptor.getPriceConcession(state);
            if (!data.isBookingEdit) {
                /*
                if (state.packageFilter != undefined && state.packageFilter != null && state.packageFilter.PackageClass > 0) {
                    this.selectedClass = state.packageFilter.PackageClass;
                    this.availableConsessions = [];
                    if (this.priceConsessionId != 0) {
                        this.availableConsessions.push(this.priceConsessionId);
                    } else {

                        let isPriceConcessionAvailable = FacilityAdaptor.isPriceConcessionAvailable(state.addedFacilites, this.selectedClass);
                        if (isPriceConcessionAvailable.isHasValidMinPriceConcession) this.availableConsessions.push(isPriceConcessionAvailable.PriceMinConsessionId);
                        if (isPriceConcessionAvailable.isHasValidMaxPriceConcession) this.availableConsessions.push(isPriceConcessionAvailable.PriceMaxConsessionId);
                    }
                } else {
                    if (this.priceConsessionId != 0) {
                        let selectedClassDetails = this.packageClasses.find(a => a.PriceMinConsessionId == this.priceConsessionId || a.PriceMaxConsessionId == this.priceConsessionId);
                        if (selectedClassDetails != undefined) this.selectedClass = selectedClassDetails.Id;
                    } else {
                        this.selectedClass = environment.DefaultPackageClassId;
                    }
                }
                this.selectedClassDetails = this.packageClasses.find(a => a.Id == this.selectedClass);
                */
                this.selectedClass = (state.packageFilter != undefined && state.packageFilter != null && !isNaN(state.packageFilter.PackageClass)) ?
                    state.packageFilter.PackageClass : environment.DefaultPackageClassId;
                this.selectedClassDetails = this.packageClasses.find(a => a.Id == this.selectedClass);
            } else {
                this.bookingItem = data.bookingItemSummary;
                //this.availableConsessions.push(data.priceConcessionId);
                this.priceConsessionId = data.priceConcessionId;
                //Todo need to get from packag
                this.selectedClassDetails = this.packageClasses.find(a => a.PriceMinConsessionId == data.priceConcessionId || a.PriceMaxConsessionId == data.priceConcessionId);
                if (this.selectedClassDetails != undefined) this.selectedClass = this.selectedClassDetails.Id;
            }
        });
    }

    ngOnInit() {
        this.onSearch();
        this.subscription.add(this.qtyChange$.pipe(debounceTime(500)).subscribe(val => {
            if (val != null) {
                console.log("VALUEEE- -", val)
                this.getItemPrices([val]);
            }
            //this.calculatePrice(val);
           
        }));

    }


    ngOnDestroy() {
        if (this.cart_subscriber)
            this.cart_subscriber.unsubscribe();
        this.subscription.unsubscribe();
    }

    searchClick() {
        this.pagenumber = 0;
        this.onSearch();
    }

   
    isPriceRange(upsell:UpsellSummary) {
        return BookingAdaptor.isRange(upsell.minPrice, upsell.maxPrice);

    }

    isUnitPriceRange(price: UnitPrice) {
        if (price == undefined) return false;
        return BookingAdaptor.isRange(price.minPrice, price.maxPrice);
    }
   
    isAvailable(upsell: UpsellSummary) {
        if (this.facilitySummary && this.facilitySummary.item)
        {
            //sales category matching
            var ups = upsell.salesCategories.filter(s => this.facilitySummary.item.salesCategories.findIndex(isc => isc.id == s.id) >= 0);
            if (ups)
            {                
                if (upsell.itemPriceGroups != undefined && upsell.itemPriceGroups.length > 0) {
                    if (environment.IsAllowMultiplePriceConsession) return true;
                    if (this.priceConsessionId != 0) {
                        var index = upsell.itemPriceGroups.findIndex(ipg => ipg.concessionId == this.priceConsessionId)
                        if (index >= 0)
                            return true;
                    } else
                    {
                        return true;
                    }
                } 
            }
        }
        /*
        if (this.priceConsessionId != 0) {
            if (upsell.itemPriceGroups != undefined && upsell.itemPriceGroups.length > 0) {
                var index = this.availableConsessions.findIndex(pg => upsell.itemPriceGroups.findIndex(ipg => ipg.concessionId == pg) >= 0);
                if (index != undefined && index >= 0) return true;
                else return false;
            }
        } else {
            let selectedPackageClassDetail = environment.UpsellPrice.PackageClases.find(a => a.Id == this.selectedClass);
            if (selectedPackageClassDetail == undefined || (selectedPackageClassDetail.PriceMinConsessionId == "" && selectedPackageClassDetail.PriceMaxConsessionId == ""))
                return true;

            if (upsell.itemPriceGroups != undefined && upsell.itemPriceGroups.length > 0) {
                var index = this.availableConsessions.findIndex(pg => upsell.itemPriceGroups.findIndex(ipg => ipg.concessionId == pg) >= 0);
                if (index != undefined && index >= 0) return true;
                else return false;
            }
        }
        */
        return false;
    }
   

    pageclickevent(pageEvent: PageEvent) {
        this.pageSize = pageEvent.pageSize;
        this.pagenumber = pageEvent.pageIndex;
        this.onSearch();
    }

    onEnter() {
        if (this.searchString != undefined || this.searchString != "") this.onSearch();
    }

    setPageSizeOptions(setPageSizeOptionsInput: string) {
        this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }

    errorHandler(event) {
        event.target.src = environment.ApiUrl + "dist/assets/images/no-image.png";
    }

    onSearch() {
        let thisPage = this;

        if (this.facilitySummary) {
            let salesCategories = "";
            let upsellName = "";
            let startTime = "";
            let endTime = "";

            if (!this.data.isBookingEdit) {
                salesCategories = this.facilitySummary.item.salesCategories.map(s => s.id).join(",");
                startTime = Utility.convertToISO(Utility.convertISOToDate(this.facilitySummary.startTime));
                endTime = Utility.convertToISO(Utility.convertISOToDate(this.facilitySummary.endTime));
            } else {
              salesCategories = this.data.fc.bookingItemSalesCategories;
              //if (!environment.IsGroupByFacilityNameInBookingView) {
                startTime = Utility.convertToISO(Utility.convertISOToDate(this.data.bookingItemSummary.startTime));
                endTime = Utility.convertToISO(Utility.convertISOToDate(this.data.bookingItemSummary.endTime));
              //}
            }

            if (this.isEdit && this.upsell != undefined) { upsellName = this.upsell.name }

            let result = this._itemService.GetUpsells(salesCategories, this.searchString, startTime, endTime, this.pagenumber + 1, this.pageSize, this.selectedItemType, upsellName);
            result.subscribe((result) => {
               
                this.upsells = [];
                this.recordCount = 0;
                if (result.Data && result.Data.length > 0) {
                    let time = 0;
                    result.Data.forEach((c, i) => {
                        let upsellSummary = Utility.toCamel(c) as UpsellSummary;
                        console.log(upsellSummary);
                        let salesChannel = c.ItemSalesChannels.filter(salesChannel => salesChannel.allowImmediateConfirmedBookings == 1);
                        upsellSummary.allowImmediateConfirmedBookings = (salesChannel.length > 0) ? 1 : 0;
                        if (!thisPage.data.isBookingEdit) {
                            var exsitingItem = this.facilitySummary.upsellSummaries.find(fs => fs.id == upsellSummary.id);
                            if (exsitingItem != undefined) {
                                upsellSummary.isAdded = true;
                                upsellSummary.qty = exsitingItem.qty;
                                upsellSummary.startTime = startTime;
                                upsellSummary.endTime = endTime;
                                upsellSummary.minPrice = exsitingItem.minPrice;
                                upsellSummary.maxPrice = exsitingItem.maxPrice;
                                upsellSummary.url = exsitingItem.url;
                            } else {
                                upsellSummary.qty = 1;
                                upsellSummary.startTime = startTime;
                                upsellSummary.endTime = endTime;
                                UpsellAdaptor.populateItemDetail(upsellSummary, upsellSummary.itemPriceGroups, this.selectedClass, null, null, this.priceConsessionId);
                            }
                        }
                        else {
                            var exsitingUpsellItem = (thisPage.data.bookingItemSummary.upsellSummaries != undefined) ? thisPage.data.bookingItemSummary.upsellSummaries.find(fs => fs.id == upsellSummary.id) : undefined;
                            upsellSummary.isAdded = (exsitingUpsellItem != undefined);
                            //if (environment.IsGroupByFacilityNameInBookingView) {
                              thisPage.facilitySummary.bookingItemSummaries.forEach((itemSummary, index) => {
                                if (upsellSummary.isAdded) {
                                  exsitingUpsellItem = (itemSummary.upsellSummaries != undefined) ? itemSummary.upsellSummaries.find(fs => fs.id == upsellSummary.id) : undefined;
                                  upsellSummary.isAdded = (exsitingUpsellItem != undefined);
                                }
                              });
                            //}
                            upsellSummary.qty = (exsitingUpsellItem != undefined) ? exsitingUpsellItem.qty : 1;
                            upsellSummary.startTime = startTime;
                            upsellSummary.endTime = endTime;
                            UpsellAdaptor.populateItemDetail(upsellSummary, upsellSummary.itemPriceGroups, this.selectedClass, null, null, this.priceConsessionId);
                        }
                        console.log("CALL 2")
                    
                        upsellSummary.availabilityUl = false;
                        if (upsellSummary.qtyPerDay != undefined && upsellSummary.qtyPerDay == 0)
                        {
                            upsellSummary.availabilityUl = true;
                        }
                        else if (upsellSummary.itemAvailabilityPrivate && upsellSummary.itemAvailabilityPrivate.length > 0) {
                            upsellSummary.availabilityQty = upsellSummary.itemAvailabilityPrivate[0].availableAssetsCount;

                        }
                        else if (upsellSummary.itemAvailability && upsellSummary.itemAvailability.length > 0)
                        {
                          
                            upsellSummary.availabilityQty = Number(upsellSummary.itemAvailability[0].availability);
                 
                
                        }
                       

                        this.upsells.push((upsellSummary));

                    });

                    this.recordCount = result.Meta.TotalResults;
                    var res = this._assetService.getItemThumbnails(thisPage.upsells);

                    if (res != null) {
                        var resPromise = res.toPromise();
                        resPromise.then(img => {
                            if (img && img.length > 0) {
                                img.forEach((c, i) => {
                                    if (thisPage.upsells) {
                                        var facilityIndex = thisPage.upsells.findIndex(i => i.id == c.id);
                                        if (facilityIndex > -1) {
                                            var facilityObj = thisPage.upsells[facilityIndex];
                                            facilityObj.url = environment.ApiUrl + c.url;
                                            thisPage.upsells[facilityIndex] = facilityObj;
                                        }
                                    }
                                });
                            }
                        });
                    }
              }
              //if (!(environment.IsGroupByFacilityNameInBookingView && this.data.isBookingEdit))
                this.getItemPrices(this.upsells, this.data.isBookingEdit,true);
                //  this.store.dispatch(new FacilitySearchLoaded());
            }, err => {
                //    this.store.dispatch(new FacilitySearchLoaded());
            });


        }
    }

    update(upsell: UpsellSummary) {
        var thisPage = this;
        if (this.data.isBookingDetailsEdit && (upsell.qty <= upsell.availabilityQty || upsell.availabilityUl == true)) {
            this.getItemPrices([upsell], true);
            thisPage.onSubmit.emit(upsell);
        } else {
            var exsitingItem = this.facilitySummary.upsellSummaries.find(fs => fs.id == upsell.id);
            if (exsitingItem != undefined) {
                exsitingItem.qty = upsell.qty;
                exsitingItem.minPrice = upsell.minPrice;
                exsitingItem.maxPrice = upsell.maxPrice;
                exsitingItem.itemPrice = upsell.itemPrice;
                this.store.dispatch(new UpdateFacility(this.facilitySummary));
                this.dialogRef.close();
            }
        }
    }

    addQty(upsell: UpsellSummary) {
        
        if (upsell.isAdded && !this.isEdit) return;

            upsell.qty++;
        
        if (this._upsell.PriceDisplayOption == 3 && this.selectedClassDetails == undefined && this.priceConsessionId == 0) return;

        //if (upsell.qty <= upsell.itemAvailabilityPrivate.availableAssetsCount && upsell.itemAvailabilityPrivate.availableAssetsCount != null) {
        //    this.disableQty = true;
        //}

        upsell.minPrice = undefined;
        upsell.maxPrice = undefined;
       
        this.qtyChange$.next(upsell);
        upsell.loaded = false;
    }

    reduceQty(upsell: UpsellSummary) {
      
        if (upsell.isAdded && !this.isEdit) return;

        if (upsell.qty != 1) {
            upsell.qty--;
            if (this._upsell.PriceDisplayOption == 3 && this.selectedClassDetails == undefined && this.priceConsessionId == 0) return;

          //if (!(environment.IsGroupByFacilityNameInBookingView && this.data.isBookingEdit)) {
            upsell.minPrice = undefined;
            upsell.maxPrice = undefined;
          
            this.qtyChange$.next(upsell);
            console.log("CALL 3")
            upsell.loaded = false;
        }
    }

    qtyChange(upsell: UpsellSummary) {
       

        if (upsell.isAdded && !this.isEdit) return;

        if (upsell.qty == 0 || upsell.qty == null) {
            upsell.qty = 1;
        }

        if (this._upsell.PriceDisplayOption == 3 && this.selectedClassDetails == undefined && this.priceConsessionId == 0) return;

        upsell.minPrice = undefined;
        upsell.maxPrice = undefined;
        // this.calculatePrice(upsell);
        console.log("CALL 4")
        if (upsell.qty <= upsell.availabilityQty || upsell.availabilityUl == true) {
            this.getItemPrices([upsell]);
        }   
        upsell.loaded = false;

    }

    add(upsell: UpsellSummary) {
        upsell.isAdded = true;
        if (this.data.isBookingEdit == true && (upsell.qty <= upsell.availabilityQty || upsell.availabilityUl == true)) {
            upsell.url = upsell.url.replace(environment.ApiUrl, '');  
            this.onSubmit.emit(upsell);
            this.dialogRef.close();
            this.getItemPrices([upsell], true);
            return;
        }
        this.facilitySummary.upsellSummaries.push(upsell);
        
        if (upsell.qty <= upsell.availabilityQty || upsell.availabilityUl == true) {
            this.onSubmit.emit(upsell);
            this.getItemPrices([upsell]);
        }     
        this.store.dispatch(new UpdateFacility(this.facilitySummary));
    }
    /*
    calculatePrice(upsell: UpsellSummary, isEditUpdate = false) {
        var thisPage = this;
        if (this.selectedClassDetails == undefined) return;

        upsell.minPrice = undefined;
        upsell.maxPrice = undefined;

        let count = 0;
        // for min price 
        // this.store.dispatch(new FacilitySearchLoading());
        let resultMin = thisPage._itemService.CalculateItemPrice(upsell.id, this.selectedClassDetails.PriceMinConsessionId, upsell.qty, Utility.convertToISO(Utility.convertISOToDate(upsell.startTime)), Utility.convertToISO(Utility.convertISOToDate(upsell.endTime)));
        resultMin.subscribe((result) => {
            if (result.calculatedItemPriceGroups != undefined && result.calculatedItemPriceGroups.length > 0) {
                result.calculatedItemPriceGroups.forEach((cip, i) => {
                    if (!(cip instanceof Array)) {
                        if (cip.priceIncludingTax != 0)
                            upsell.minPrice = cip;
                    }
                });
            }

            count++;
            if (count == 2) this.setUpsellPrices(upsell, isEditUpdate);
        },
            err => {
                console.log("Error : calculateUpsellItemPrice - min  " + upsell.name, err);
                count++;
                if (count == 2) this.setUpsellPrices(upsell, isEditUpdate);
            }
        );

        // for max price 
        setTimeout(function () {
            let resultMax = thisPage._itemService.CalculateItemPrice(upsell.id, thisPage.selectedClassDetails.PriceMaxConsessionId, upsell.qty, Utility.convertToISO(Utility.convertISOToDate(upsell.startTime)), Utility.convertToISO(Utility.convertISOToDate(upsell.endTime)));
            resultMax.subscribe((result) => {
                if (result.calculatedItemPriceGroups != undefined && result.calculatedItemPriceGroups.length > 0) {
                    result.calculatedItemPriceGroups.forEach((cip, i) => {
                        if (!(cip instanceof Array)) {
                            if (cip.priceIncludingTax != 0)
                                upsell.maxPrice = cip;
                        }
                    });
                }

                count++;
                if (count == 2) thisPage.setUpsellPrices(upsell, isEditUpdate);
            },
                err => {
                    console.log("Error : calculateUpsellItemPrice - max  " + upsell.name, err);
                    count++;
                    if (count == 2) thisPage.setUpsellPrices(upsell, isEditUpdate);
                }
            );
        }, 75);
    }*/

    setUpsellPrices(upsell: UpsellSummary, isEditUpdate: boolean) {
       // upsell.minPrice = (upsell.minPrice == undefined || upsell.minPrice == null) ? upsell.maxPrice : upsell.minPrice;
       // upsell.maxPrice = (upsell.maxPrice == undefined || upsell.maxPrice == null) ? upsell.minPrice : upsell.maxPrice;

        if (upsell.isAdded)
        {
            this.store.dispatch(new UpdateFacility(this.facilitySummary));
        }
        if (this.data.isBookingEdit && isEditUpdate && upsell.isAdded) {
          this.onSubmit.emit(upsell);
          this.dialogRef.close();
        }

        //   this.store.dispatch(new FacilitySearchLoaded());
    }

    getItemPrices(upsellSummaries: UpsellSummary[], isEditUpdate = false,isSearch=false) {
        var thisPage = this;
        let priceConsession = { minConsessionId: this.priceConsessionId, maxConsessionId: 0 };


        if (this.priceConsessionId == 0) {
            if (this._upsell.PriceDisplayOption == 3) {
                let selectedPackageClass = this._upsell.PackageClases.find(a => a.Id == this.selectedClass);
                if (selectedPackageClass.PriceMinConsessionId != "" && selectedPackageClass.PriceMaxConsessionId != "") {
                    priceConsession.minConsessionId = selectedPackageClass.PriceMinConsessionId;
                    priceConsession.maxConsessionId = selectedPackageClass.PriceMaxConsessionId;
                }
            }

            else if (this._upsell.PriceDisplayOption == 2) {
                priceConsession.minConsessionId = this._upsell.PriceMinConsessionId;
                priceConsession.maxConsessionId = this._upsell.PriceMaxConsessionId;
            }

         
        }
       
       

        let upsellItemRequest = [];
        upsellSummaries.forEach((upsellSummary, index) => {
            //let allowImmediateConfirmedBookings = (!isEditUpdate) ? (this.facilitySummary.item == undefined) ? this.facilitySummary.allowImmediateConfirmedBookings : this.facilitySummary.item.allowImmediateConfirmedBookings : this.facilitySummary.allowImmediateConfirmedBookings;

            if (this._upsell.PriceDisplayOption == 1 && this.priceConsessionId == 0) {
                priceConsession.minConsessionId = upsellSummary.minPriceConcessionId;
                priceConsession.maxConsessionId = upsellSummary.maxPriceConcessionId;
            }
            let item = {
                index: index,
                itemId: upsellSummary.id,
                minPriceConcessionId: priceConsession.minConsessionId,
                maxPriceConcessionId: priceConsession.maxConsessionId,
                quantity: upsellSummary.qty,
                startTime: Utility.convertToISO(Utility.convertISOToDate(upsellSummary.startTime)),
                endTime: Utility.convertToISO(Utility.convertISOToDate(upsellSummary.endTime))
            };

            upsellItemRequest.push(item);
          

        });


        if (upsellItemRequest.length > 0) {
            let itemPrice = this._itemService.GetItemPrices(upsellItemRequest);
            itemPrice.subscribe(result => {
                if (result != undefined) {
                    result.forEach(data => {

                        upsellSummaries[data.index].minPrice = new ItemPriceGroup;
                        upsellSummaries[data.index].maxPrice = new ItemPriceGroup;
                     

                        upsellSummaries[data.index].minPrice.priceIncludingTax = data.minimumPrice;
                      upsellSummaries[data.index].maxPrice.priceIncludingTax = data.maximumPrice;
                      if (!isSearch)
                        this.setUpsellPrices(upsellSummaries[data.index], isEditUpdate);
                        upsellSummaries[data.index].bookingItemPrices = (data.bookingItemPrices) ? data.bookingItemPrices as BookingItemPrices[] : [];
                   

                    });

                    //var upsell: UpsellSummary;
                    //upsell.loaded = true;
                    upsellSummaries.forEach((upsellSummary, index) => {
                        upsellSummary.loaded = true;
                    });
                    
                }
            });

           
        }

       
    }
}
