import { ClientRegistration, EnquiryBooking } from './client-registration-config';


export class AppSetting {
  ClientRegistrationConfig: ClientRegistration;

  SubmitEnquiryConfig: SubmitEnquiryConfiguration;

  BookingConfig: BookingConfig;

  IsDisableInputsInPayment: boolean;

  AllowToDoManualInvoiceSearch: boolean;

  DateFormat: string;

  TimeFormat: string;

  DateTimeFormat: string;

  ExternalIntegrationIdForPaymentGateway: string;

  GoogleAnalyticsGTag: string;

  PageTitle: string;

  ShowMapView: MapView;

  ExternalIntegrationIdForAddress: number;

  FacilityPageSetting: FacilityPageSetting;

  APIMessages: Message[];

  AddEditPopupPageSettings: AddEditPopupPageSettings;

  RegularPortalLoginRedirectToMyBooking: boolean;

  AnyPortalSignOutRedirectToSignIn: boolean;

  ClientLoginIsRequired: boolean;

  EnableLanguageTranslation: boolean;

  MenuSettings: MenuSettings[];

  ShowCalendarView: CalendarView;

  SeatSelectionWithOnePackage: boolean;

  OrganizationName : string = "";

  OpenWordAsPDF : boolean;

  EnquiryBookingConfig: EnquiryBooking;
}

export class MenuSettings{
  Disabled: string;
  Id: string;
  Title: string;
  BookingPermittedClientCategoryIds: BookingPermittedClientCategoryIds;
}

export class BookingPermittedClientCategoryIds {
  Value: string;
}

export class BookingConfig {
    BookingExpireTimeInMinutes: string;
    ImmediateBookingFailedStatus: string;
    BookingLeadDayValidationMessage: any;
    DisableDatePickerForLeadDays: boolean;
    LeadDaysForBooking: number;
    SalesChannel: number;
    Currency: string;
    PackageClases: any[];
    DefaultBussinessAreaId: number;
    DefaultPackageClassId: number;
    PriceDisplayOption: number;
    PriceMinConsessionId: number;
    ConsiderClientPaymentTerm: boolean;
    IsDefaultExpandAlternativeConfigurationForFacility: boolean;
    PriceMaxConsessionId: number;
    PriceTypeId: number;
    SetEventAsPackageName: boolean;
    PackageEntityConfigurationProfileId: number;
    BookingEntityConfigurationProfileId: number;
    ItemEntityConfigurationProfileId: number;

    GoogleAPIKEY: string;
    ClientBookingApprovalPendingStatus: number;
    ClientBookingApprovedStatus: number;
    BookingStatus: string;
    BookingApproveStatus: string;
    BookingExpireStatus: string;
    MinimumBookingDuration: number;
    BookingSearchDescriptionTextLimitHigh: number;
    BookingSearchDescriptionTextLimitMedium: number;
    BookingSearchDescriptionTextLimitLow: number;
    TermsandConditionLink: string;
    LatestFutureBookingDate: string;
    LatestFutureBookingDateErrorMessage: string;
    AllowToDoBookingForNonOperationalHours: string;
    BookingTermAndConditionMessage: string;
    CommunityClassAlertMessage: string;
    PriceTypeFilters: PriceTypeFilters;
    UpsellPrice: UpsellPrice;
    BookingStates: any[];
    Messages: Messages;
    IsAllowMultiplePriceConsession: string;
    IsShowSetupTimeInCalendar: string;
    ImmediateConfirmation: ImmediateConfirmation;
    ConfigurationSelection: ConfigurationSelection;
    CheckAvailability: CheckAvailability;
    AutomaticallyPickItemBasedOnFacility: boolean;
    IsBookingSearchByClient: boolean;
    IsGroupByFacilityNameInBookingView: boolean;
    PriceConcessions: PriceConcessions[]
  BookingRestrictions: any[];
  SortOptions: any[];
  BookingEditDisableStates: string;

  //IsDefaultExpandAlternativeConfigurationForFacility: boolean;
  MaximumDocUploadSize: number;
  FacilityFilters: FacilityFilter[];

  ExternalIntegrationIdForAddress: number;
  IsEnableManualAddress: boolean;
  RegularHireAccessNoteMandatory: boolean;

  BookingExpiryInMinutes: number;
  DefaultPriceConsessionId: number;

  PackageAdditionalChargesLabel: string;
  PackageTotalLabel: string;
  BookingCancellation: BookingCancellation;
  ChangeBooking: ChangeBooking;
  ClientApprovedBooking: ClientApprovedBooking;

    //PackageAdditionalChargesLabel: string;
    //PackageTotalLabel: string;
    IsValidateSalesCategory: boolean;
}

export class FacilityFilter {
  Visible: boolean;
  DefaultValue: string;
  Id: string;
  Label: string;
}

export class ImmediateConfirmation {
  Allow: boolean;
  DefaultPriceConsessionId: number;
}
export class PriceConcessions {
  Id: string;
  Name: string;

}
export class UpsellPrice {
  PriceDisplayOption: number;
  PackageClases: any[];
  PriceMinConsessionId: number;
  PriceMaxConsessionId: number;
}

export class PriceTypeFilters {
  HalfDayHours: string;
  FullDayHours: string;
  SessionHours: string;
}

export class CheckAvailability {
  EnableAutoAvailabilityCheck: boolean;
  EnableTimeLineAdvancedMode: boolean;
  TimeLineColors: any[];
  DisplayPreliminarySlotAsUnavailble: boolean;
}
export class ConfigurationSelection {
  Enabled: boolean;
  DisplayMode: number;
  DisplayBumpInOutNotification: boolean;

}


export class Messages {
    BookingDetailsPage: string;
    BookingCartPageTopNavPannel: string;
    InsufficientSelectedTime: string;
    CartPageDocumentDescription: string;
    BookingDetailsPageCouncilCreatedBookings: string;
}


export class SubmitEnquiryConfiguration {
  public Message: string;
}

export class MapView {
  ShowMapView: boolean;
  IsDefault: boolean;
}

export class FacilityPageSetting {
  public EnableAvailableFacilitiesOption: boolean;
  public SearchGoogleMapCenterLatitude: number;
  public SearchGoogleMapCenterLongitude: number;
  public SearchGoogleMapZoom: number;
  public FacilitySearchTextPlaceHolder: string;
  public FacilityScreenMainDescription: string;
  public AutomaticallySearchFacility: boolean;
  public FacilitySearchFilterRememberOption: number;
}
export class BookingCancellation {
  public task: Task;
}
export class ChangeBooking {
  public task: Task;
}
export class Task {
  subject: string;
  assignedUsers: string;
  assignedUserGroups: string;
  fromUser: string;
}

export class Message {
  APICode: string;
  Value: string;
}

export class ClientApprovedBooking {
  public task: Task;
}
export class AddEditPopupPageSettings {
  EnableCategoryAndEventSelection: boolean;
  MandatoryCategoryAndEventSelection: boolean;
}

export class CalendarView {
  isEnable: boolean;
  IsDefault: boolean;
  TimeLineColors: any[];
}

export enum Condition {
  TRUE = 'true',
  FALSE = 'false',
  CONDITIONAL = 'conditional'
}
