import { BaseResource } from '../base-resource';
import { Question } from 'src/modules/models/item/question';
import { PackageItem } from 'src/modules/models/product/cp-package-item';
import { PackageUserDefinedField } from 'src/modules/models/user-defined-field/package-user-defined-field';
export class BespokePackage extends BaseResource {
    name: string;
    businessAreaId: number;
    businessArea: string;
    packageClassId: number;
    priceTypeId: number
    priceType: string;
    taxId: number;
    eventId: number;
    salesCategories: string[];
    price: number;
    attendees: number;
    question: Question[];
    totalAnsweredQuestions: number;
    packageItems: PackageItem[];
    AllowImmediateConfirmedBookings: number;
    originalQuestion: Question[];

    userDefinedFields : PackageUserDefinedField[]
}