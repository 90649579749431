import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpRequest, HttpHandler, HttpEvent,
    HttpInterceptor,
    HttpResponse, HttpErrorResponse, HttpEventType, HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Observable, throwError ,of} from 'rxjs';
import { map, catchError, switchMap, tap, mergeMap } from 'rxjs/operators';
import { Store, ICart, AddFacility, PackageSearch, SignOut, SetContact } from 'src/modules/store/index';
import { FacilitySearchLoading, FacilitySearchLoaded, BookingSaveStarted, BookingSaveCompleted } from 'src/modules/store/loading/actions';
import { ILoadingStatus } from 'src/modules/store/loading/status-store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';
import { AppSetting } from 'src/modules/models/settings/casual-portal/app-setting';
import { Booking } from 'src/modules/models/booking/booking';
import { BookingStatus } from 'src/modules/models/booking/booking-status';
import { BookingService } from '../booking.service';
import { Contact } from 'src/modules/models/client/contact';
import { WarningAlertMessageComponent } from 'src/modules/shared/warning-alert-message/warning-alert-message.component';

@Injectable()
export class APIRequestHandler implements HttpInterceptor {

    isInternalUser: boolean = environment.IsInternalUser;

    constructor(private _http: HttpClient, private store: Store<ILoadingStatus>,
                private cart_store: Store<ICart>, private snackBar: MatSnackBar,
                private router: Router, private _bookingService: BookingService) {
    }

    requestCounter: number = 0;
    redirecting = false;
    sessionWarning = false;
    warningmessagedisplayed = false;
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // disable spinner for ticketing
        const isDisableLoader = request.url.includes('disableSpinner');
        if (!isDisableLoader) {
            if (!request.url.includes('dist/assets/i18n'))
                this.requestCounter++;
        } else {
            request = request.clone({
                url: request.url.replace('&disableSpinner', '')
            });
        }

        if (this.requestCounter === 1 && !isDisableLoader) {
            setTimeout(() => { this.store.dispatch(new FacilitySearchLoading()); });
        }


        request = request.clone({
            setHeaders: {

                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        return next.handle(request).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {

                    if(event.body){
                        this.sessionWarning = Boolean(event.body["isWrning"]);
                    }
                    
                    if (!request.url.includes('dist/assets/i18n')) {
                        this.requestCounter--;
                    }

                    if (this.requestCounter == 0){
                        if (this.sessionWarning && !this.warningmessagedisplayed) {
                            this.clearCartContactData("Session Expired",true);
                        }
                        setTimeout(() => { this.store.dispatch(new FacilitySearchLoaded()); });
                    }
                } else {
                   // console.log(event)
                }
                return event;
            }, ((err: any) => {
                return (err);
            }), () => {
            }),
            catchError((err) => {
               if (!request.url.includes('dist/assets/i18n')){
                          this.requestCounter--;
                     }
               if (this.requestCounter == 0) {
                   if (this.sessionWarning && !this.warningmessagedisplayed) {
                       this.clearCartContactData("Session Expired", true);
                   }
                    setTimeout(() => { this.store.dispatch(new FacilitySearchLoaded()); });
                }
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {

                        if (err.error == "Session Timeout") {
                            if (!this.redirecting) {
                                this.redirecting = true;
                                this.openSnackBarError(["Session Timeout"]);
                                let _cart;
                                if (localStorage.getItem('cart'))
                                    _cart = JSON.parse(localStorage.getItem('cart'));
                                if (_cart != undefined && _cart != null && _cart["contact"] && _cart["contact"]["id"]) {
                                    _cart["contact"] = { firstName: "", profilePicture: "" };
                                    localStorage.setItem('cart', JSON.stringify(_cart));
                                    localStorage.removeItem('rbStore');
                                    // window["ShowSessionOut"] = "1";
                                }
                        this.clearCartContactData("Session Timeout")
                                //this.cart_store.dispatch(new SignOut());
                                if (this.requestCounter > 0) {
                                    this.requestCounter = 0;
                                    setTimeout(() => { this.store.dispatch(new FacilitySearchLoaded()); });
                                }

                                if(this.isInternalUser){
                                  window.open(environment.OptimoHomeUrl.replace('/Home/App',''), "_self");
                                }

                                else{
                                  this.router.navigate(['/client/login']);
                                }
                                //  return next.handle(request);
                            }
                            return throwError(err);

                      }
                      /*else
                      {
                        return this._http.get((environment.ApiUrl + "/api/system/renew-token?token")).pipe(mergeMap(res => {
                            environment.Token = res.toString();
                            request = request.clone({
                                setHeaders: {
                                    'Token': res.toString(),
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                }
                            });
                            return next.handle(request);
                        }));
                    }*/
                } else {
                    return throwError(err);
                }

                } else {
                    return throwError(err);
                }
            })
        );

    }
    openSnackBarError(message) {
        this.snackBar.openFromComponent(AlertMessageComponent, {
            data: message,
            // duration: 3000,
            verticalPosition: 'top'
        });
    }

    openSnackbarWarning(message){
        this.snackBar.openFromComponent(WarningAlertMessageComponent, {
            data: message,
            //duration: 5000,
            verticalPosition: 'top'
        });
    }

    ResetSavedBooking(bookingId){
        let  _immediateBookingFailedStatus=(environment.AppSetting as AppSetting).BookingConfig.ImmediateBookingFailedStatus.toString();

        if(_immediateBookingFailedStatus && Number(_immediateBookingFailedStatus)!=0){
          var _bk = new Booking();
          _bk.id = bookingId;
          _bk.bookingStatus = new BookingStatus();
          _bk.bookingStatus.id = (environment.AppSetting as AppSetting).BookingConfig.ImmediateBookingFailedStatus.toString();
          _bk.type = "booking";
          _bk.isExpired = true;

          this._bookingService.BookingPatch(_bk.id, _bk).subscribe(result => {
            if (!result.body.isError) {
            //   console.log("booking expire successfull");
            }
            });
        }

    } 

    clearCartContactData(message: string, isWarning: boolean = false) {
        let _cart;
        if (localStorage.getItem('cart'))
            _cart = JSON.parse(localStorage.getItem('cart'));
        
        
        if (_cart != undefined && _cart != null && _cart["contact"] && _cart["contact"]["id"]) {
            if (_cart["contact"] && _cart["contact"]["id"]) {
                _cart["contact"] = { firstName: "", profilePicture: "" };
            }

            if (!isWarning && _cart["booking"] && _cart["booking"]["id"]) {

                this.ResetSavedBooking(_cart["booking"]["id"]);

                _cart["booking"] = {};

                //cart no need to be cleared when making old booking expired 
                // if (_cart["addedFacilites"] && _cart["addedFacilites"][0]["id"]) {
                //     _cart["addedFacilites"] = [];
                // // window["ShowSessionOut"] = "1";
                // }

                // window["ShowSessionOut"] = "1";
            }
            localStorage.setItem('cart', JSON.stringify(_cart));
            localStorage.removeItem('rbStore');
            this.store.dispatch(new SetContact(new Contact(), null, null, null, null, null));
            // window["ShowSessionOut"] = "1";

            if (isWarning) {
                this.warningmessagedisplayed = true;
                this.openSnackbarWarning([message]);
            }
            else {
                this.openSnackBarError([message]);
            }
        }

    }
}

export const OptHttpProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: APIRequestHandler,
    multi: true
};
