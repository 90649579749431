import { Tree, TreeNode } from './tree-data'

export interface MonthView
{
    displayDate: Date;
    year: number;
    dayId: number;
    monthDays: DayView[]
}

export class WeekView
{
    displayDate: Date;
    year: number;
    weekId: number;
    weekDays: DayView[]
}
export class DayView {
    date: Date;
    year: number;
    dayId: number;
    hours: HourView[]
    daySegments: DaySegmentView[]
}
export class HourView {
    date: Date;
    hourSegments: HourSegmentView[];
    styleClass?: string;
}
export class HourSegmentView {
    date: Date;
    startMin: number;
    endMin: number;
    selected: boolean = false;
    styleClass?: string;
}
export class DaySegmentView {
    date: Date;
    startMin: number;
    endMin: number;
    hours?: HourView[];
}


export class EventView {
    startDate: Date;
    startMin: number;
    durationMin: number;
    label: string;
    treeNode: TreeNode;
    startTime: Date;
    endTime: Date;
    eventViewType: number;   // 1:currentBooking , 2 : Booked Location , 3:Blocked TimeSlots
   // bookingItemId: string;
    BookingItemIds: string[] = [];
    description: string;

    bookingPackageName: string;
    addHocCalanderSelectedSlots: any[] = [];
    BookingPackageId: string;
    topPosition: number=0;
}

