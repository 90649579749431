import { BOOKING_FLOW_OPTION } from "src/modules/public-web/components/layout/models/enums";

export class PublicEvent {
    eventDates: any[];
    // eventAssets: EventAsset[];
    eventTimeslots: any[];
    priceMinimum: number;
    priceMaximum: number;
    hasSeating: boolean;
    hasDining: boolean;
    hasPrivateSuites: boolean;
    hasBrandingAreas: boolean;
    name: string;
    description: string;
    eventCategoryId: number;
    eventCategory: string;
    eventGroupId: number;
    eventGroup: string;
    eventStartDate: Date;
    eventEndDate: Date;
    eventStartTime: Date;
    eventEndTime: Date;
    eventManagerId: number;
    eventManagerUserName: string;
    keywords: string;
    minimumMargin: number;
    webDescription: string;
    shortDescription;
    documents: Document[];
    eventTypeId: number;
    id: string;
    type: string;
    venueName: string;
    restrictSingleSeat: boolean;
    bookingFlowOptionId: BOOKING_FLOW_OPTION;
}

export interface PostResponse {
    id: string;
    status: boolean;
}
