
import { BaseResource } from '../base-resource';

export class Note extends BaseResource {
    public plainText: string;
    public richText: string;
    public html: string;
    public entityTypeID: string;
    public entityType: string;
    public noteTypeId: string;
    public noteType: string;
    public entityConfigurationProfileId: string;
    public imageUrl: string;
    public imageName: string;
    public baseString: string;
    public imageType: string;
    public insertedTime: Date;
}

export enum EntityNoteType {
  WasteCollectionNotes = '10',
  ReportMissedCollectionNotes = '11',
  DriverNotes = '12',
  RescheduleNotes = '13'
}

export enum EntityNoteTypeName {
  WasteCollectionNotes = 'Waste Collection Notes',
  ReportMissedCollectionNotes = 'Report Missed Collection Notes',
  DriverNotes = 'Driver Notes',
  RescheduleNotes = 'Reschedule Notes'
}

