import { BaseResource } from '../base-resource';
import { Event } from './event';
import { BookingItems } from './booking-items';
import { jsonIgnore } from 'json-ignore';
import { Note } from '../waste-management/wm-note';
import { BookingDetailStatus } from '../waste-management/wm-collection-status';


export class BookingPackages extends BaseResource {
    constructor() {
        super();
        this.bookingItems = [];
        this.type = 'BookingPackage';
    }

    endDate: string;
    margin: number;
    name: string;
    priceTypeId: number;
    quantity: string;
    singleDayPackage: false;
    startDate: string;
    taxRate: number;
    bookingItems: BookingItems[];
    package: any;
    event: Event;
    attendees: number;
    collectionNote: Note[];
    bookingDetailStatus: BookingDetailStatus;
    grossAmount?: number;
    unitPrice:number;
    totalAmount:number;
    netAmount: number;
    attendeeCaptureProfile: any;
    // For temp use for PublicWEb
    image: string;
    @jsonIgnore() entityConfigurationProfileId: number;
}
