import { Component, OnInit, ViewChild, OnDestroy, Input, SimpleChanges, OnChanges, ChangeDetectorRef,Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FacilitySummary } from 'src/modules/models/asset/facility-summary';
import { Moment } from 'moment';
import moment from 'moment';

import { Utility } from 'src/modules/utility';
import { environment } from 'src/environments/environment';
import { Configuration } from 'src/modules/models/asset/configuration';
import { MomentDateAdapter } from 'src/modules/booking-portal/common/date-time-adapter/moment-date-adapter';
import { EditModalComponent } from 'src/modules/booking-portal/booking-cart/edit-modal/edit-modal.component';
import { BaseComponent } from 'src/modules/shared/base.component';
import { Subject } from 'rxjs';
import { ScrollTopService } from 'src/modules/services/scroll-top.service';
import { Assetsearchparams } from 'src/modules/models/asset/asset-search-params';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatCalendar, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { PriceRangeComponent } from 'src/modules/booking-portal/modals/price-range/price-range.component';
import { DateFormat } from 'src/modules/date-format';
import { CalenderTimeslot } from 'src/modules/models/asset/timeslots';
export const MY_FORMATS = {
  parse: { dateInput: 'LL' },
  display: {
      dateInput: 'DD MMM YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'mobile-month-calendar',
  templateUrl: './mobile-month-calendar.component.html',
 
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
  // ,
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileMonthCalenderComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {


  // @Input() facilities: FacilitySummary[];
  @Input() facilities: FacilitySummary[];
  @Input() bookingDate: Moment;
  @Input() changed: Boolean;
  @Input() selectedBookingSlots: Object = {};
  // @Input() searchParam : Assetsearchparams;
  // @Input() textLimit
  // @Input() showAvailableFacilities: Boolean;

  @ViewChild('calendar') calendar: any;// MatCalendar<Date>;
  searchTime: { startTime: string; endTime: string; };

  constructor(
    private dialog: MatDialog,private cdr: ChangeDetectorRef) {
      super();
      
      var _leadDays = (environment.LeadDaysForBooking == null) ? 0 : environment.LeadDaysForBooking;

      const startTime = new Date();
      startTime.setDate(startTime.getDate() + _leadDays)
      const date = new Date();
      startTime.setHours(date.getHours())
      const endTime = moment(startTime).add(environment.MinimumBookingDuration, 'm').toDate();
      this.searchTime = { startTime: Utility.convertToISO(startTime), endTime: Utility.convertToISO(endTime) };
      // this._cd.markForCheck();
  }

  public mobileDays: any[];
  public mobileFacilityConfig: Configuration;
  mobileMinDate : Date;
  EndTime: Date;
  private monthChange = new Subject<Date>();
  date = new Date();
  selectedslots : Object = {};
  popupOpen : Boolean = false;
  selectedFacility : FacilitySummary;
  //StartTime: Date;
  // mobilebookingDate : Date = new Date();

  ngOnInit(): void {
    // this.mobileFacilityConfig = this.facility.configuration;
    // this.mobileDays = this.facility.calenderTimeslots.filter(cs => (!(cs.isSelectedTimeSlot && cs.isBumpInBumpOut)) && !(cs.isUnAvailableDueToOperationalHours));

    // this.mobileMinDate = this.bookingDate;
    // this.initialization();
    // this.mobilebookingDate.setDate(this.bookingDate.toDate().getDate() - 1);
    // this.bookingDate;
    // this._cd.markForCheck()
  }

  ngOnChanges(changes: SimpleChanges) {
      if(changes && changes.bookingDate && changes.bookingDate.currentValue) {
        this.bookingDate = changes.bookingDate.currentValue;

        const startTime = new Date(this.bookingDate.toDate());
        const date = new Date();
        startTime.setHours(date.getHours())
        const endTime = moment(startTime).add(environment.MinimumBookingDuration, 'm').toDate();
        this.searchTime = { startTime: Utility.convertToISO(startTime), endTime: Utility.convertToISO(endTime) };
      }
      if (changes && changes.facility && changes.facility.currentValue) {
        // this.facilities = changes.facilities.currentValue;
        this.facilities = changes.facility.currentValue;
      }
      if (changes && changes.selectedBookingSlots && changes.selectedBookingSlots.currentValue) {
        // this.facilities = changes.facilities.currentValue;
        this.selectedslots = changes.selectedBookingSlots.currentValue;
        this.cdr.markForCheck();                    
        this.cdr.detectChanges();
      }

      var leadDays = (environment.LeadDaysForBooking == null) ? 0 : environment.LeadDaysForBooking;
      if (environment.DisableDatePickerForLeadDays) {
          var currentDate = new Date();
          currentDate.setDate(currentDate.getDate() + leadDays)
          this.mobileMinDate = currentDate;
      }
      this.movetoMonth();

  }

  ngOnDestroy(): void {
  }

  openPriceConfigurationPopup(facility: FacilitySummary) {
    var _facilities: FacilitySummary[] = [];
    _facilities.push(facility);
    const dialogRef = this.dialog.open(PriceRangeComponent, {
      panelClass: ['price-range-modal'],
      width: '50vw',
      height: 'auto',
      data: { facilityId: facility.id, facilities: _facilities },
    });
  }
  
  dateClass(facility) {
    return (date: Moment): MatCalendarCellCssClasses => {
      if(!this.popupOpen){
        console.log(date, facility)

        let selectedslot = this.selectedslots[facility.id]
        let facilitySelectedSlots : any []
        if(selectedslot){
          facilitySelectedSlots= selectedslot.filter(x => x.date.toDateString() == date.toDate().toDateString());
        }
        let _eventDate = date.toDate().toDateString();
        let dateinDate = date.toDate();

        this.mobileFacilityConfig = facility.configuration;
        this.mobileDays = facility.calenderTimeslots.filter(cs => (!(cs.isSelectedTimeSlot && cs.isBumpInBumpOut)) && !(cs.isUnAvailableDueToOperationalHours));

        let mobileDayUnAvailable = this.mobileDays.filter(days => days.startTime.toDateString() == _eventDate || days.endTime.toDateString() == _eventDate);
        let mobileAvailble = facility.availableTimeslots.filter(days => days.startTime.toDateString() == _eventDate);
        let availability = "";

        //if the day has selected times
        if(selectedslot && facilitySelectedSlots && facilitySelectedSlots.length > 0){
          availability= "mobile-calendar-Selected";
          return availability;
        }
        // //if the day matches today
        // else if (date.format("DD/MM/YYYY") == this.bookingDate.format("DD/MM/YYYY")) {
        //   availability = "mobile-calendar-today";
        // }
        
        const morethanaDayUnavailable_SLots = facility.calenderTimeslots.find((slot:CalenderTimeslot) => (slot.endTime.getDate() - slot.startTime.getDate()) > 1 && 
                                                                              (slot.endTime.getDate() > date.toDate().getDate() &&  slot.startTime.getDate() < date.toDate().getDate()) &&
                                                                              slot.endTime.toDateString() == date.toDate().toDateString() && slot.startTime.toDateString() ==date.toDate().toDateString());
        if( morethanaDayUnavailable_SLots){
          return availability = "mobile-calendar-unavailble";;
        }
        //else check wether its a bookable date
        else if (this.mobileMinDate && dateinDate && (dateinDate.getDate() >= this.mobileMinDate.getDate() || dateinDate.getMonth() > this.mobileMinDate.getMonth() || dateinDate.getFullYear() > this.mobileMinDate.getFullYear())) {
          
          //if there are unavailble slots this can be either fully or partial day
          if (mobileDayUnAvailable.length > 0) {
            let partilayavailble = true;

            //if there are availble slots too this will be a partail day
            if(mobileAvailble.length > 0){
              partilayavailble =true;

              let config = this.mobileFacilityConfig;
              if (config) {
                let slotswithConfig = mobileAvailble.find(day => (day.end - day.start) > (config.setupTime + config.teardownTime) / 60.0);

                //if there are no availbe slot time with higher than setupteardown its a unavailble slot
                if(!slotswithConfig){
                  partilayavailble =false;
                }

              }
            }
            else {
              partilayavailble = false;
            }
            availability = partilayavailble ? "mobile-calendar-partiallyavailble " : "mobile-calendar-unavailble";
          }

          //if its fully available and
          //if the day matches today
          else if (date.format("DD/MM/YYYY") == this.bookingDate.format("DD/MM/YYYY")) {
            availability = "mobile-calendar-today";
          }
          
          //else fully availble
          else {
            availability = "mobile-calendar-availble";
          }
        }
        return availability;
      }
    };
  }


  // mobileDates = (event: any) => {
  //   let _eventDate = event.toDate().getDate();
  //   let _eventMonth = event.toDate().getMonth();
  //   let _eventYear = event.toDate().getFullYear();
  //   let mobileDayAvailbility = this.mobileDays.filter(days => days.startTime.getDate() == _eventDate || days.endTime.getDate() == _eventDate)
  //   //let avalability =  "mat-calendar-body-disabled";
  //   let avalability = "";

  //   //if date is the booking date
  //   if( event.format("DD/MM/YYYY") == this.bookingDate.format("DD/MM/YYYY"))
  //   {
  //     avalability = "mobile-calendar-today";
  //   }//  25 < 28 ;; 30 < 28

  //   //when date is greater than booking date
  //   else if (event.format("DD/MM/YYYY") > this.bookingDate.format("DD/MM/YYYY")){
  //     //when bookings are there on the date
  //     if(mobileDayAvailbility.length > 0){
  //       let partilayavailble = false;
  //       let f_endTime = 0;
  //       let config = this.mobileFacilityConfig;

  //       if(config){
  //         mobileDayAvailbility.forEach(day=> {
  //           // Utility.convertISOToDate(this.facility.openingTime)
  //           // if(day.start != f_endTime && f_endTime != 0){
  //           //   partilayavailble =true;
  //           // }

  //           // f_endTime = day.end;

  //           // if(!partilayavailble && (day.start == Utility.convertISOToDate(this.facility.openingTime) || day.end == Utility.convertISOToDate(this.facility.closingTime))){
  //           //   partilayavailble =false;
  //           // }

  //           partilayavailble = (day.end - day.start) > (config.setupTime + config.teardownTime) / 60.0 ;

  //         });
  //       }
  //       else{
  //         partilayavailble =true;
  //       }

  //       //check for unavailble or partially availble
  //       avalability = partilayavailble ?"mobile-calendar-partiallyavailble " : "mobile-calendar-unavailble";

  //     }

  //     //when no bookings availble on the date
  //     else{
  //       avalability = "mobile-calendar-availble";
  //     }
  //   }

  //   // this._cd.markForCheck()

  //   return avalability;

  // }

  DateChanged($event, SelectedFacility : FacilitySummary){
    let selectedDate : Date = $event.toDate();
    let _fc = Object.assign([], this.facilities); // after the close of edit popup the changes need to be reflected
    this.facilities = [];
    let selectAttendees : number = 0;
      // this.facilityTypeFilterSetting = environment.FacilityFilters.find(ff => ff.Id == "facilityType");
      // this.facilityListFilterSetting = environment.FacilityFilters.find(ff => ff.Id == "facility");
    let attendeesFilterSetting = (environment.FacilityFilters && environment.FacilityFilters.length) ? environment.FacilityFilters.find(ff => ff.Id == "attendees") : null;
    let advancedfilterAtenndence = attendeesFilterSetting.DefaultValue
    try {

      if (advancedfilterAtenndence != "" && advancedfilterAtenndence != null && advancedfilterAtenndence != undefined) {
          selectAttendees = parseInt(advancedfilterAtenndence);
      }
    } catch (e) {
        selectAttendees = 0;
    }

    let searchableTime = new Date();
    searchableTime.setDate(selectedDate.getDate());
    searchableTime.setMonth(selectedDate.getMonth());
    searchableTime.setFullYear(selectedDate.getFullYear())

    //let StartTime = searchabledate;// this.bookingDate.toDate();
    let EndTime = moment(searchableTime).add(environment.MinimumBookingDuration, 'm').toDate();

    SelectedFacility.startTime = Utility.convertToISO(searchableTime);
    SelectedFacility.endTime = Utility.convertToISO(EndTime);
    SelectedFacility.attendees = selectAttendees; 
    // this.popupOpen = true;
    let _selectedFacility = Object.assign(new FacilitySummary, SelectedFacility);
    this.selectedFacility = _selectedFacility;

    // if the selected facilt has available time timeslots for the day its either partialy or fullyavailable then bookable
    this.popupOpen = _selectedFacility.availableTimeslots.filter(t => t.startTime.toDateString() == selectedDate.toDateString()).length > 0; // true;
    let _isSelected = (this.selectedslots[SelectedFacility.id] && this.selectedslots[SelectedFacility.id].length >0) ? (this.selectedslots[SelectedFacility.id].filter(x => x.date.toDateString() == selectedDate.toDateString())).length > 0: false;

    // if already selected slot or popupcannot be opened return without ding anything
    if(_isSelected || !this.popupOpen){
      this.reset(_fc)
      return;
    }

    let dialogRef = this.dialog.open(EditModalComponent, {
    
      data: { facility: SelectedFacility, isaddfacility: true },
      panelClass: ['custom-dialog-container', 'w60modal'],
      height: 'auto',
      width: '60%'
      // DialogPosition : top

    });

    //after closing the editmodal popup
    dialogRef.afterClosed().safeSubscribe(this, result =>{
      // console.log($event,  EndTime)
      $event = null;
      $event = null;
      this.reset(_fc)
    });

  }

  reset(_fc){
    this.popupOpen = false;
    this.facilities = [..._fc]; // get the original list
    this.facilities.map((_fc, index) => {
      if(_fc.id == this.selectedFacility.id){ //  check for matching facilty from original
        this.facilities[index] = this.selectedFacility; // replace with changes
      }
    });
    console.log(this.facilities.map(f => f.calenderTimeslots));
    // this.facilities = this.selectedFacility;
    
    // this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  movetoMonth() {
    // const today = new Date();
    // let future = new Date();
    // future.setDate(today.getDate() + 31);
    // this.date = future;
    if(this.calendar){
      this.calendar.activeDate = this.bookingDate;
      //this.calendar.updateTodaysDate();
    }
  }

}
