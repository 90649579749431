import { BaseResource } from 'src/modules/models/base-resource';
import { AnswerChoice } from '../question/answer';
import { BookingQuestionChoice } from '../question/booking-question';

export class BookingAnswerChoice extends BaseResource {
    constructor() {
        super();
        this.type = "BookingAnswerChoice";
    }
name: string;
quantity: number;
bookingQuestionChoice: BookingQuestionChoice;
}