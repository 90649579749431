<div class="mmc_alert __warning-alert">
        <div class="alert-main-wrapper">
            <div class="mmc_flex__item--inherit alert-close-btn">
                <mat-icon>info_outline</mat-icon>
                 <!-- <button mat-icon-button tabindex="0">
                    <mat-icon (click)="close()" (keydown.enter)="close()">cancel</mat-icon>
                </button> -->
            </div>
            <div class="pl-2 text-content alert-err-msg">
                <div class="font__small" *ngFor="let errorMessage of data">{{errorMessage}}</div>
            </div>           
        </div>
</div>