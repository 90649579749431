import { Component, Inject, Input, Output, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
//import { BookingQuestion } from 'src/modules/models/regular-portal/booking/question/booking-question';
//import { Question, SingleChoiceQuestion } from 'src/modules/models/regular-portal/booking/question/question';
//import { Question as Questions } from 'src/modules/models/regular-portal/booking/question/question-patch/question';
import { BookingQuestion, SingleChoiceQuestionAnswer } from 'src/modules/models/regular-portal/booking/question/booking-question';
import { BookingAnswerChoice } from 'src/modules/models/regular-portal/booking/question/booking-answer-choice';
import { Question } from 'src/modules/models/regular-portal/booking/question/question';
import * as _ from 'lodash';

@Component({
  selector: 'opt-single-choice-question',
  templateUrl: './single-choice-question.component.html',
})
export class SingleChoiceQuestionComponent implements OnInit {
  @Input() dates: any[];
  @Input() bookingQues: SingleChoiceQuestionAnswer;
  @Output() QuestionAnswerOutput = new EventEmitter();
  @Output() DateSelectedOutput = new EventEmitter();
  @Input() answeredQuestionIds: string[];
  @Input() questionNumber: number;

  selectedAnswer: string;
  answeredQuestions: SingleChoiceQuestionAnswer;


  displayArrayData: any[] = [];


  constructor() {
  }
  ngOnInit() {

    this.selectedAnswer = "0";
    this.displayArrayData.push(this.dates)
    var thisComponent = this;

    // if (this.bookingQues.bookingAnswerChoice != undefined && this.bookingQues.bookingAnswerChoice.bookingQuestionChoice != undefined) {
    if (this.bookingQues.bookingAnswerChoice != undefined) {
      if(this.bookingQues.bookingAnswerChoice.bookingQuestionChoice) {
        this.selectedAnswer = this.bookingQues.bookingAnswerChoice.bookingQuestionChoice.id;
      } else {
        this.selectedAnswer = this.bookingQues.bookingAnswerChoice.id;
      }
      this.answeredQuestions = this.bookingQues;
      //if (this.dates != undefined && this.dates.length > 0) {
      //    this.dates.forEach(function (dt, i) {
      //       thisComponent.addOrRemoveQuestionID(dt.actualQuestionTobePatch.id, true);
      //    });
      //} else {
      thisComponent.addOrRemoveQuestionID(this.bookingQues.id, true);
      this.bookingQues.conditionalBookingQuestions =
        this.checkConditionalQuestions(this.bookingQues.conditionalBookingQuestions)
      // }

    }
    if (!(this.dates != undefined && this.dates.length > 0))
      this.QuestionAnswerOutput.emit(this.bookingQues);
  }

  selectedAnswChoice(id, ansChoice, index) {
    var thisComponent = this;
    if (this.dates != undefined && this.dates.length > 0) {
      this.dates.forEach(function (dt, i) {
        dt.actualQuestionTobePatch.bookingQuestionChoices.forEach(function (bqc, i) {
          if (bqc.name == ansChoice.name) {
            dt.actualQuestionTobePatch.bookingAnswerChoice = new BookingAnswerChoice();
            dt.actualQuestionTobePatch.bookingAnswerChoice.id = (+(bqc.id) * -1).toString();
            dt.actualQuestionTobePatch.bookingAnswerChoice.type = "BookingAnswerChoice";
            dt.actualQuestionTobePatch.bookingAnswerChoice.name = bqc.name;
            dt.actualQuestionTobePatch.bookingAnswerChoice.bookingQuestionChoice = _.cloneDeep(bqc);
            // dt.actualQuestionTobePatch.bookingAnswerChoice.bookingQuestionChoice.a
          }
        });

        //thisComponent.addOrRemoveQuestionID(dt.actualQuestionTobePatch.id, true);
      });
    }
    // else {
    thisComponent.addOrRemoveQuestionID(this.bookingQues.id, true);
    this.bookingQues.conditionalBookingQuestions =
      this.checkConditionalQuestions(this.bookingQues.conditionalBookingQuestions)
    // }
    var thisComponent = this;
    this.bookingQues.bookingQuestionChoices.forEach(function (bqc, i) {
      if (bqc.name == ansChoice.name) {
        thisComponent.bookingQues.bookingAnswerChoice = new BookingAnswerChoice();
        // thisComponent.bookingQues.bookingAnswerChoice.id = parseInt(bqc.id) > 0 ? (+(bqc.id) * -1).toString() : bqc.id;
        thisComponent.bookingQues.bookingAnswerChoice.id = (+(bqc.id) * -1).toString();
        thisComponent.bookingQues.bookingAnswerChoice.type = "BookingAnswerChoice";
        thisComponent.bookingQues.bookingAnswerChoice.name = bqc.name;
        thisComponent.bookingQues.bookingAnswerChoice.bookingQuestionChoice = _.cloneDeep(bqc);
      }
    });

    this.bookingQues.answeredTime = new (Date);
    if (!(this.dates != undefined && this.dates.length > 0))
      this.QuestionAnswerOutput.emit(this.bookingQues);
  }
  conditionalQuestionAnswerChange(val) {
    if (this.bookingQues.conditionalBookingQuestions && this.bookingQues.conditionalBookingQuestions.length > 0) {
      const i = this.bookingQues.conditionalBookingQuestions.findIndex(a => a.id == val.id && a.question.id === val.question.id);
      if (i !== -1)
        this.bookingQues.conditionalBookingQuestions[i] = val;
      this.bookingQues.answeredTime = new (Date);
      this.QuestionAnswerOutput.emit(this.bookingQues);
    }
  }
  addOrRemoveQuestionID(bookingId: string, isAnswered: boolean) {
    let index = this.answeredQuestionIds.findIndex(x => x == bookingId);
    if (index >= 0) {
      if (!isAnswered) {
        this.answeredQuestionIds.splice(index, 1)
      }
    } else {
      if (isAnswered) {
        this.answeredQuestionIds.push(bookingId);
      }
    }
  }
  checkConditionalQuestions(conditionalQues: BookingQuestion[]) {
    if (conditionalQues) {
      conditionalQues.forEach(bq => {
        const filterd = bq.bookingConditionalQuestionAnswers.find(a => a.answerChoiceId === this.bookingQues.bookingAnswerChoice.bookingQuestionChoice.id)
        if (!!filterd) {
          bq.canDisplayQuestion = true;
        } else {
          bq.canDisplayQuestion = false;
        }
      })
    }
    return conditionalQues
  }
  RecveselectedDates(event) {

    console.log('Evenet')
    console.log(event);

    this.displayArrayData.push(event);
    this.DateSelectedOutput.emit(event);
  }

}
