<div class="edit-booking-modal">
  <!--<div style="max-height:80vh" class="mat-dialog-content">-->

    <div class="mmc-modal-header mmc_flex--container">
      <div class="mmc_flex__item--inherit mmc_flex__item--vcenter icon-cage mmc_just-content-center extra-icon">
          <i class="material-icons">event_note</i>
      </div>
      <div class="mmc_flex__item mmc_flex__item--vcenter">
          <span class="section-title">Extra Allocation</span>
      </div>
      <div class="mmc_flex__item--inherit mmc_flex__item--vcenter close-icon-cage mmc_just-content-center">
          <button mat-button [mat-dialog-close]="true" tabindex="0"><i class="material-icons" >close</i></button>
      </div>
  </div>



  <div class="extra-body">
      <div class="extra-body-header-wrapper">
        <div class="extra-body-image">
          <img class="img" src="{{apiUrl}}{{upsellSummary.url}}" (error)="errorHandler($event)" />
        </div>
        <div class="extra-header-title-wrapper">
          <div class="extra-select-item-name">
            {{upsellSummary.name}}
          </div>
          <div class="extra-select-item-quantity">
            (x{{upsellSummary.qty}})
          </div>
        </div>
        <div class="extra-header-price">
          $132.50
        </div>
      </div>

      <div class="extra-content-wrapper">
        <div class="extra-content-title">
          {{facilitySummary.name}} ({{bookingItemUpsells.length}})
        </div>
        <div class="extra-contetnt-check">
          <section class="example-section">
            <mat-checkbox class="example-margin" [(ngModel)]="isAllSelected" name="isAllSelected" (change)="selectAll($event)">Select All</mat-checkbox>
          </section>
        </div>
      </div>

      <div class="extra-content-table">

        <table class="table" *ngIf="bookingItemUpsells?.length>0">
          <tbody>
            <tr  *ngFor="let upsellSummary of bookingItemUpsells">
              <td>
                <div class="table-check">
                <section class="example-section">
                  <mat-checkbox class="example-margin" [checked]="upsellSummary.isSelected" [disabled]="upsellSummary.isReadOnly" (change)="selectChange(upsellSummary)">{{facilitySummary.name}}</mat-checkbox>
                </section>
              </div>
            </td>
              <td>
                <div class="table-date-wrapper">
                  <div class="table-start-date">
                    {{upsellSummary.startTime |dateFormat}}
                  </div>  
                  <div class="table-start-time">
                    {{upsellSummary.startTime |dateFormat:'time'}} - {{upsellSummary.endTime |dateFormat:'time'}}
                  </div>            
                </div>
              </td>
              <td>
                <div class="table-image-wrapper">
                  <div class="table-image">
                    <i class="fa fa-users" aria-hidden="true"></i>
                  </div>
                  <div class="table-quantity">
                    {{upsellSummary.quantity}}
                  </div>
                </div>
              </td>
              <td>
                <div class="table-price" *ngIf="!upsellSummary.isReadOnly">
                  <div class="total__amount pw-txt-h3_small pw-text-bold" *ngIf="isPriceRange(upsellSummary)">{{upsellSummary.minPrice?.priceIncludingTax | CurrencyFormat}} - {{upsellSummary.maxPrice?.priceIncludingTax | CurrencyFormat}}</div>
                  <div class="total__amount pw-txt-h3_small pw-text-bold" *ngIf="!isPriceRange(upsellSummary)">{{upsellSummary.minPrice?.priceIncludingTax | CurrencyFormat}}</div>
                </div>
                <div class="table-price" *ngIf="upsellSummary.isReadOnly">
                  <div class="total__amount pw-txt-h3_small pw-text-bold">{{upsellSummary.totalAmount | CurrencyFormat}}</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

  </div>

  <div class="mmc-modal-footer mmc_flex--container extra-allocation-footer">
      <div class="mmc_flex__item item-w-25 mmc_flex__item--vcenter mmc_just-content-center extra-footer-wrap">
          <div class="btn__card main mmc_flex__item--vcenter check-availability--btn extra-footer-btn" (click)="apply()" (keydown.enter)="apply()">APPLY</div>
      </div>
  </div>
</div>
