import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";


@Pipe({
    name: 'optimotranslate',
    pure: false
})

export class OptimoTranslate implements PipeTransform {

    constructor(private translate: TranslateService) { }

    transform(value: string, ...args: any[]) {
        if (value != null) {
            let translatedValue = this.translate.instant(value)
            if (value !== translatedValue) {
                //to check whether sent value was added in the translation file (en.json or other language)
                return translatedValue;
            } else {
                if (translatedValue.includes('.'))
                    return translatedValue.split('.').slice(1).join('.');
                else
                    return translatedValue;
            }
        }else   
            return '';
    }
}