export class BaseAddress {
  PropId?: number;
  PrimaryAddress?: string;
}

export class WmAddress extends BaseAddress {
  Address1?: string;
  Address2?: string;
  Address3?: string;
  City?: string;
  State?: string;
  PostCode?: string;
  IsValidForCommercial: Boolean;
  ExternalAddressID?: string;
  AddressType: string;
  CountryID?: number;
  County?: string;
  Country?: string;
  StateCode?: string;
  ThemeId?: string;
  ZoneId?: number;
  Electorate?: string;
  Units: number;
  AddressCategory?: string;
  AddressCategoryValid: Boolean;

  /**
   *
   */
  constructor(obj?) {
    super();

    if (obj) {
      this.Address1 = obj.address1 ? obj.address1 : '';
      this.Address2 = obj.address2 ? obj.address2 : '';
      this.Address3 = obj.address3 ? obj.address3 : '';
      this.StateCode = obj.stateCode ? obj.stateCode : '';
      this.State = obj.state ? obj.state : '';
      this.City = obj.city ? obj.city : '';
      this.PostCode = obj.postCode ? obj.postCode : '';
      this.County = obj.county ? obj.county : '';
    }

  }
}

export class BaseStreet {
  Description?: number;
  ComponentDescription4?: string;
}

export class WmStreetList {
  suburb: string;
  street: string;
}
