<div class="mat-dialog-content questionnaire-modal">
    <div class="modal-header ">
        <div class="mmc_flex--container mmc_flex__item--vcenter">
            <div class="mmc_flex__item title-icon">
                <i class="material-icons">
                    star_border
                </i>
            </div>
            <div class="mmc_flex__item title">
                <div class="font__h5 font__semibold">Questionnaire</div>
            </div>
            <div class="mmc_flex__item icon">
                <button class="mat-icon-button mat-button" mat-dialog-close="true" tabindex="0">
                    <mat-icon class="mat-icon material-icons" role="img" aria-hidden="true">close</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="modal-body">
        <!--modal body start-->
    </div>
    <ng-template>
        <div class="container" style="min-height:400px">
        </div>
    </ng-template>
</div>