<div class="mmc_alert __error mcc_warning after-date-warning" style="width:100%;">
    <div class="row">
        <div class="col sm 12 col md 12 col lg 12">
            <div class=" ">
                <div class="mmc_flex--container inner-wrapper mmc--depth__2">
                    <div class="mmc_flex__item--vcenter">
                        <i class="material-icons alert--icon">info_outline</i>
                    </div>
                    <div class="mmc_flex__item--vcenter middle-content">
                        <div class="text-content">
                            <div class="font__h5 wider-text">{{data}}</div>
                        </div>
                    </div>
                    <div class="mmc_flex__item--inherit mmc_flex__item--vcenter close-icon-cage mmc_just-content-center">
                        <button mat-button [mat-dialog-close]="true" tabindex="0"><i class="material-icons">close</i></button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!--<div class="row">
    <div class="col-sm-12" style="text-align:right; margin-top:10px;">
        <button mat-button class="mmc-btn-primary" mat-dialog-close="true">OK</button>
    </div>
</div>-->