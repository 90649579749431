/*
 * Public API Surface of opt-ngx-gallery
 */

/*export * from './lib/opt-ngx-gallery.service';
export * from './lib/opt-ngx-gallery.component';
export * from './lib/opt-ngx-gallery.module';
*/

export { NgxGalleryModule } from './lib/index';
export { NgxGalleryOptions } from './lib/ngx-gallery-options.model';
export { NgxGalleryImage } from './lib/ngx-gallery-image.model';
export { NgxGalleryAnimation } from './lib/ngx-gallery-animation.model';