import { Component,OnInit, ComponentFactoryResolver, ViewChild} from '@angular/core';
import { SiteHeaderDirective } from 'src/modules/layout/site-header.directive';
import { SiteFooterDirective } from 'src/modules/layout/site-footer.directive';
import { environment } from 'src/environments/environment'
@Component({
    selector: 'app-site-layout',
    templateUrl: './site-layout.component.html'
})

export class SiteLayoutComponent implements OnInit {
    @ViewChild(SiteHeaderDirective, { static: true }) header!: SiteHeaderDirective;
    @ViewChild(SiteFooterDirective, { static: true }) footer!: SiteFooterDirective;
    theme: string = 'mcc';
    constructor(private componentFactoryResolver: ComponentFactoryResolver) {
        if (environment.Theme && !environment.IsInternalUser) {
            this.theme = environment.Theme;
        } else {
            this.theme = '';
        }
    }

    ngOnInit() {
        this.loadComponent();

    }

    async loadComponent() {
         const { SiteHeaderComponent } = await import('src/modules/layout/site-layout-' + this.theme+'/site-header/site-header.component.ts');
         const { SiteFooterComponent } = await import('src/modules/layout/site-layout-' + this.theme +'/site-footer/site-footer.component.ts');
        //const { SiteHeaderComponent } = await require('src/modules/layout/site-layout-' + this.theme+'/site-header/site-header.component.ts');
        //const { SiteFooterComponent } = await require('src/modules/layout/site-layout-' + this.theme +'/site-footer/site-footer.component.ts');

        const hdrComponentFactory = this.componentFactoryResolver.resolveComponentFactory(SiteHeaderComponent);
        const ftrComponentFactory = this.componentFactoryResolver.resolveComponentFactory(SiteFooterComponent);
        this.header.viewContainerRef.clear();
        this.header.viewContainerRef.createComponent(hdrComponentFactory);
        this.footer.viewContainerRef.clear();
        this.footer.viewContainerRef.createComponent(ftrComponentFactory);
    }
}
