import {
  Component,
  OnInit,
  ViewChild,
  Output,
  Input,
  EventEmitter,
  ViewChildren,
  QueryList,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
  Inject,
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormGroupDirective,
  NgForm,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { Deserializer } from "json-api-format";

import { CustomerService } from "src/modules/services/customer.service";
import { SystemService } from "src/modules/services/system.service";
import { QASIntegrationService } from "src/modules/services/qas-integration.service";

import { IndividualClient } from "src/modules/models/client/Individual-client";
import { ReferenceData } from "src/modules/models/client/referenceData";
import { Contact } from "src/modules/models/client/contact";
import { CommunicationMethod } from "src/modules/models/client/client";
import {
  Client,
  Address,
  User,
  Venue,
  Tax,
  PriceConcession,
} from "src/modules/models/client/client";
import { QASAddress } from "src/modules/models/client/qas-address";
import { Addresses, AddressDetail } from "src/modules/models/client/Address";

import {
  ClientRegistration,
  CommunicationTypeValidation,
} from "src/modules/models/settings/casual-portal/client-registration-config";

import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AlertMessageComponent } from "src/modules/shared/alert-message/alert-message.component";
import { environment } from "src/environments/environment";
import { SystemConfig } from "src/modules/system-configuration";

import {
  map,
  startWith,
  debounceTime,
  distinctUntilChanged,
  mergeMap,
} from "rxjs/operators";
import { Utility } from "src/modules/utility";

import { CustomerAdditionalDetail } from "src/modules/models/client/customer-additional-detail";
import { Note } from "src/modules/models/client/note";
import { DomSanitizer } from "@angular/platform-browser";
import { AppSetting } from "src/modules/models/settings/casual-portal/app-setting";
import { Subject } from "rxjs/Subject";
import { BaseComponent } from "src/modules/shared/base.component";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "src/modules/store";
import {
  CheckBookingHasClient,
  SetGuestContact,
} from "../../../../store/public-web/public-web-actions";
import { Country } from "src/modules/models/public-web/customer/country";

@Component({
  selector: "opt-guest-checkout",
  templateUrl: "./guest-checkout.component.html",
  styleUrls: ["./_guest-checkout.scss"],
})
export class GuestClientComponent extends BaseComponent implements OnInit {
  //#region prop
  individuallientSaveForm: FormGroup;
  private fileReader = new FileReader();
  addressChanged: Subject<string> = new Subject<string>();
  Image: string;
  ImageEx: string;
  Email: string;
  SelectedTitleId: string;
  SelectedTitleName: string;
  SelectedPhoneTypeId: string;
  SelectedPhoneTypeName: string;
  SelectedEmailTypeId: string;
  SelectedEmailTypeName: string;
  SelectedComTypeId: string;
  SelectedComTypeName: string;
  addressSearchText: string;
  IsEnableManualAddress: boolean = false;
  PrivacyPolicyLink: string;
  TermsandConditionLink: string;
  IsFromLogin: boolean;
  IsFromBookingDetail: boolean;
  IsFromWasteBooking: boolean;
  IsFromWasteBookingCart: boolean;
  CurrentUrl: string;
  Address_Country: string;
  Address_Line1: string;
  Address_Line2: string;
  Address_Line3: string;
  Address_City: string;
  Address_CountyCode: string;
  Address_PostCode: string;
  DefaultCountryId: number;
  CommunicationTypeValidation: CommunicationTypeValidation[] = [];
  loadingStyle: boolean;
  QASLoader: boolean;
  appData: AppSetting;
  Client: IndividualClient;
  Titles: Array<ReferenceData>;
  Contact: Contact;
  CommunicationTypes: Array<ReferenceData>;
  CommunicationTypesPhone: Array<ReferenceData>;
  CommunicationTypesEmail: Array<ReferenceData>;
  CustomerRegConfig = new ClientRegistration();
  AddressList: Addresses;
  filteredOptions: AddressDetail[];
  TempAdd: Array<string>;
  isChecked = false;
  isEmailSent: boolean;
  siteKey: string;
  size: string;
  lang: string;
  theme: string;
  type: string;
  recaptcha;
  isCaptchaVerified = false;
  defaultCountryName: string;
  isLoad = false;
  addressLoaded = false;
  defaultCurrencyId: number;
  phoneNumberPattern: RegExp;
  agreedText: string;
  emailPattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^ <>() \[\]\\.,;: \s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  nameValidation: RegExp;
  pwPolicy: any;
  showValidationMessage: boolean = false;
  validationMessage: string = "";
  isValidEnforceComplexPassword: boolean;
  isValidCharactersMinimum: boolean;
  isValidOneLowercase: boolean;
  isValidOneUppercase: boolean;
  isValidOneDigit: boolean;
  isValidOneSpecialCharacter: boolean;
  isDisabledSubmit: boolean = true;
  isPasswordValid: boolean = true;
  isNoResultFound: boolean = false;
  isFromPublicWeb = false;
  placholderSampleNumber: string;
  priceConcessionQuestionText: string;
  PriceConcessionAnswerOptions: any[] = [];
  additionalDetailNote: string;
  url: string;
  additionalDetail: CustomerAdditionalDetail;
  modalResponse = {
    clientAlreadyExist: false,
    guestRegisterSucceed: false,
    email: undefined,
  };
  countries: Country[];
  isLoading: boolean = false;
  //#endregion

  constructor(
    public dialogRef: MatDialogRef<GuestClientComponent>,
    private _customerService: CustomerService,
    private _systemService: SystemService,
    private _qasIntegrationService: QASIntegrationService,
    private _formBuilder: FormBuilder,
    private route: Router,
    private _sysConfig: SystemConfig,
    private store: Store<any>,
    public snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
    dialogRef.disableClose = true;
    this.SelectedTitleId = "";
    this.SelectedTitleName = "";
    this.Client = new IndividualClient();
    this.Titles = new Array<ReferenceData>();
    this.CommunicationTypes = new Array<ReferenceData>();
    this.CommunicationTypesPhone = new Array<ReferenceData>();
    this.CommunicationTypesEmail = new Array<ReferenceData>();
    this.AddressList = new Addresses();
    this.AddressList.AddressDetails = [];
    this.Contact = new Contact();
    this.Contact.client = new Client();
    this.Image = "";
    this.ImageEx = "";
    this.IsFromBookingDetail = false;
    this.IsFromWasteBooking = false;
    this.IsFromLogin = false;
    this.PrivacyPolicyLink = "";
    this.TermsandConditionLink = "";
    this.DefaultCountryId = 0;
    var appsettings = environment.AppSetting as AppSetting;
    this.nameValidation = new RegExp(
      appsettings.ClientRegistrationConfig.NameValidation
    );
    this.defaultCurrencyId = environment.DefaultCurrencyId;
    this.defaultCountryName = environment.DefaultCountryName;
    this.DefaultCountryId = parseInt(environment.DefaultCountryId);
    this.siteKey = environment.ReCaptchaSiteKey;
    this.size = "Normal";
    this.lang = "en";
    this.theme = "Light";
    this.type = "Image";
    this.recaptcha = "recaptcha1";
    this.appData = environment.AppSetting as AppSetting;
    this.isPasswordValid = true;
    this.addressChanged
      .pipe(debounceTime(1000))
      .subscribe((model) => this.loadAddress(model));
    if (
      environment.PriceConcessionQuestionText &&
      environment.PriceConcessionQuestionText != ""
    ) {
      this.priceConcessionQuestionText =
        environment.PriceConcessionQuestionText;
      this.PriceConcessionAnswerOptions =
        environment.PriceConcessionAnswerOptions
          ? environment.PriceConcessionAnswerOptions
          : [];
    }
    this.additionalDetail = new CustomerAdditionalDetail();
    this.additionalDetail.note = new Note();
    this.additionalDetail.documents = [];
  }

  ngOnInit(): void {
    this._customerService.GetCountries().safeSubscribe(this, (c) => {
      if (c) {
        this.countries = c;
        this.Address_Country = this.countries.find(x => parseInt(x.id) == this.DefaultCountryId).name;
      }
    });
    this.individuallientSaveForm = this._formBuilder.group({
      FirstName: [
        "",
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(this.nameValidation),
        ],
      ],
      // TASK: Remove Validations Other Than Email , Name , Contact No
      // LastName: [
      //   "",
      //   [
      //     Validators.required,
      //     Validators.maxLength(50),
      //     Validators.pattern(this.nameValidation),
      //   ],
      // ],

      LastName: [""],
      // END TASK: Remove Validations Other Than Email , Name , Contact No
      //   PersonalPhoneNumber: ['', Validators.pattern(this.phoneNumberPattern)],

      // TASK: Remove Validations Other Than Email , Name , Contact No
      UserName: [""],
      // UserName: ["", [Validators.required, Validators.maxLength(50)]],
      // END TASK: Remove Validations Other Than Email , Name , Contact No

      PersonalPhoneNumber: [""],
      PersonalEmail: [
        "",
        [Validators.pattern(this.emailPattern), Validators.required],
      ],
      // TASK: Remove Validations Other Than Email , Name , Contact No
      Address_Country: [""],
      // Address_Line1: ["", [Validators.maxLength(50), Validators.required]],
      // END TASK: Remove Validations Other Than Email , Name , Contact No
      Address_Line1: [""],
      Address_Line2: [""],
      Address_Line3: [""],
      Address_City: [""],
      Address_CountyCode: [""],
      Address_PostCode: [""],
      Address: [""],
      // recaptcha1: ['', Validators.required],
    });

    if (environment.DisableUsernameTextBox) {
      this.individuallientSaveForm.controls.UserName.disable();
    }
    this.QASLoader = false;
    this.GetConfigurationSettings();
    // address functions
    this._sysConfig.loadAppConfiguration().then(() => {
      console.log("value added ");
      // this.agreedText = environment.AgreedText;

      this.GetDropDownDetails();
      // this.url = environment.PrivacyPolicyLink;
    });
  }
  GetConfigurationSettings() {
    let result = this._systemService.loadConfiguration();
    result.subscribe((data) => {
      if (data != null) {
        this.CustomerRegConfig = data.ClientRegistrationConfig;
        this.PrivacyPolicyLink =
          data.ClientRegistrationConfig.PrivacyPolicyLink;
        this.TermsandConditionLink =
          data.ClientRegistrationConfig.TermsandConditionLink;
        this.DefaultCountryId = Number(
          data.ClientRegistrationConfig.DefaultCountryId.Id
        );
        //  this.CommunicationTypeValidation = data.ClientRegistrationConfig.CommunicationTypeValidation;
        this.IsEnableManualAddress = true;
      }
    });
  }
  SelectTitle(selectedTitleId: string) {
    this.SelectedTitleId = selectedTitleId;
    this.SelectedTitleName = this.Titles.find(
      (i) => i.id === selectedTitleId
    ).name;
  }

  SelectComType(
    selectedComTypeId: string,
    basicComType: number,
    isAdditional: boolean
  ) {
    if (!isAdditional) {
      if (basicComType == 1) {
        this.SelectedPhoneTypeId = selectedComTypeId;
        this.SelectedPhoneTypeName = this.CommunicationTypesPhone.find(
          (i) => i.id === selectedComTypeId
        ).name;
        this.mobileNumberValidation();
      } else if (basicComType == 2) {
        this.SelectedEmailTypeId = selectedComTypeId;
        this.SelectedEmailTypeName = this.CommunicationTypesEmail.find(
          (i) => i.id === selectedComTypeId
        ).name;
      }
    } else {
      if (basicComType == 1) {
        this.SelectedPhoneTypeId = selectedComTypeId;
        this.SelectedPhoneTypeName = this.CommunicationTypesPhone.find(
          (i) => i.id === selectedComTypeId
        ).name;
        this.mobileNumberValidation();
      } else if (basicComType == 2) {
        this.SelectedEmailTypeId = selectedComTypeId;
        this.SelectedEmailTypeName = this.CommunicationTypesEmail.find(
          (i) => i.id === selectedComTypeId
        ).name;
      }
    }
  }
  GetDropDownDetails() {
    let titleResult = this._customerService.getClientTiltles();
    titleResult.subscribe(
      (data) => {
        if (data != null) {
          this.Titles = data;
        }
        this.SelectedTitleId = this.Titles[0].id;
        this.SelectedTitleName = this.Titles[0].name;
      },
      (err) => { }
    );
    let communicationTypeResult = this._customerService.CommunicationTypes();
    communicationTypeResult.subscribe(
      (data) => {
        if (data != null) {
          for (let comType of data) {
            if (comType.format == "1") {
              let comPhone = new ReferenceData();
              comPhone.id = comType.id;
              comPhone.name = comType.name;
              var isExist = comPhone.name.toLowerCase().search("office");
              if (isExist == -1) {
                this.CommunicationTypesPhone.push(comPhone);
              }
            } else if (comType.format == "2") {
              let comEmail = new ReferenceData();
              comEmail.id = comType.id;
              comEmail.name = comType.name;
              var isExist = comEmail.name.toLowerCase().search("office");
              if (isExist == -1) {
                this.CommunicationTypesEmail.push(comEmail);
              }
            } else {
              let com = new ReferenceData();
              com.id = comType.id;
              com.name = comType.name;

              this.CommunicationTypes.push(com);
            }
          }
        }

        var CommunicationTypesMobileIndex = this.CommunicationTypesPhone.map(
          function (e) {
            return e.name;
          }
        ).indexOf("Mobile");
        this.SelectedPhoneTypeId =
          this.CommunicationTypesPhone[CommunicationTypesMobileIndex].id;
        this.SelectedPhoneTypeName =
          this.CommunicationTypesPhone[CommunicationTypesMobileIndex].name;
        this.SelectedEmailTypeId = this.CommunicationTypesEmail[0].id;
        this.SelectedEmailTypeName = this.CommunicationTypesEmail[0].name;
        this.SelectedComTypeId = this.CommunicationTypes[0].id;
        this.SelectedComTypeName = this.CommunicationTypes[0].name;
        this.mobileNumberValidation();
      },
      (err) => { }
    );
  } //Get drop down details
  loadAddress(searchText: string) {
    this.AddressList = new Addresses();
    if (searchText) {
      if (searchText.trim() != "") {
        this.GetQASAddress(false, "", searchText).subscribe((data) => {
          this.isNoResultFound = false;
          if (
            data != null &&
            data.AddressDetails != null &&
            data.AddressDetails.length > 0
          ) {
            this.AddressList = data;
          } else {
            this.isNoResultFound = true;
            this.AddressList = new Addresses();
          }
          this.QASLoader = false;
        });
      } else {
        this.QASLoader = false;
        this.AddressList.AddressDetails = [];
      }
    }
  }

  GetQASAddress(
    isFormattedAddress: boolean,
    QASID: string,
    searchText: string
  ) {
    searchText.trim() == ""
      ? ((this.QASLoader = false), (this.AddressList.AddressDetails = []))
      : "";

    !isFormattedAddress && searchText.trim() != ""
      ? (this.QASLoader = true)
      : (this.QASLoader = false);

    let qasAddressParam = new QASAddress();
    qasAddressParam.isFormattedAddress = isFormattedAddress;
    qasAddressParam.QASId = QASID;
    qasAddressParam.SearchText = searchText;
    return this._customerService.getQASAddress(qasAddressParam);
  }

  GetFormattedAddress(address: string) {
    console.log("Address loading");
    let selectedAdd = this.AddressList.AddressDetails.find(
      (f) => f.Address == address
    );
    // console.log(selectedAdd)
    let postCode = selectedAdd.Postcode;
    this.GetQASAddress(true, selectedAdd.QASID, "").subscribe((data) => {
      this.AddressList = new Addresses();
      if (
        data != null &&
        data.AddressDetails != null &&
        data.AddressDetails.length > 0
      ) {
        this.Address_Country =
          data.AddressDetails[0].Country == ""
            ? this.defaultCountryName
            : Utility.sentenceCase(data.AddressDetails[0].Country);
        this.Address_Line1 = Utility.sentenceCase(
          data.AddressDetails[0].AddressLine1
        );
        this.Address_Line2 =
          data.AddressDetails[0].AddressLine2 != null
            ? Utility.sentenceCase(data.AddressDetails[0].AddressLine2)
            : " ";
        this.Address_Line3 =
          data.AddressDetails[0].AddressLine3 != null
            ? Utility.sentenceCase(data.AddressDetails[0].AddressLine3)
            : " ";
        this.Address_City = Utility.sentenceCase(
          data.AddressDetails[0].Locality
        );
        this.Address_CountyCode = data.AddressDetails[0].StateCode;
        this.Address_PostCode = data.AddressDetails[0].Postcode;
        this.addressLoaded = true;
        this.loadingStyle = true;
      }
    });
    this.AddressList = new Addresses();
    this.QASLoader = false;
  } //Format selected address

  findAddress(value) {
    this.addressChanged.next(value);
  }
  EmailAdressChanged(email) {
    this.isLoading = true;
    if (this.individuallientSaveForm.controls.PersonalEmail.valid) {
      this._customerService
        .validateGuestEmail(email)
        .safeSubscribe(this, (v) => {
          this.isLoading = false;
          if (v?.clientExist) {
            // const formControl = this.individuallientSaveForm.get('PersonalEmail');
            // if (formControl) {
            //   // activate the error message
            //   formControl.setErrors({
            //     serverError: 'Client already exist'
            //   });
            // }
            this.modalResponse.clientAlreadyExist = true;
            this.modalResponse.guestRegisterSucceed = false;
            this.modalResponse.email = email;
            this.dialogRef.close(this.modalResponse);
          }
        });
    }
  }
  onChange(event) {
    this.isChecked = !this.isChecked;
  }

  handleSuccess(e) {
    this.isCaptchaVerified = true;
  }

  handleExpire() {
    this.isCaptchaVerified = false;
  }

  handleLoad() {
    console.log("load");
  }
  //  validation functions
  mobileNumberValidation() {
    var sampleValueArray = [];
    this.individuallientSaveForm.get("PersonalPhoneNumber").clearValidators();
    this.CommunicationTypeValidation = environment.CommunicationTypeValidation;
    if (this.CommunicationTypeValidation.length > 0) {
      this.CommunicationTypeValidation.forEach((ctv) => {
        if (
          ctv.Rule != undefined &&
          ctv.Rule.length > 0 &&
          ctv.CommunicationTypeId != undefined &&
          ctv.CommunicationTypeId != ""
        ) {
          var ids = ctv.CommunicationTypeId.split(",");
          if (
            ids != undefined &&
            ids.length > 0 &&
            ids.indexOf(this.SelectedPhoneTypeId) > -1
          ) {
            ctv.Rule.forEach((rule) => {
              if (this.DefaultCountryId.toString() == rule.countryId) {
                this.phoneNumberPattern = new RegExp(rule.Value);
                if (rule.SampleNumber != "") {
                  sampleValueArray.push(rule.SampleNumber);
                }
              }
            });

            if (
              this.phoneNumberPattern == undefined ||
              this.phoneNumberPattern == null
            ) {
              var selectedRule = ctv.Rule.find((a) => a.countryId == "");
              this.phoneNumberPattern = new RegExp(selectedRule.Value);
            }
          }
        }
      });
      this.placholderSampleNumber = sampleValueArray.join(", ");
    }
    if (
      this.phoneNumberPattern != undefined &&
      this.phoneNumberPattern != null
    ) {
      console.log(
        "value added pattern",
        this.phoneNumberPattern,
        "phone type ",
        this.SelectedPhoneTypeId,
        "PlaceHolderNumber",
        this.placholderSampleNumber
      );
      //Temprory fix for phone number validation

      // this.individuallientSaveForm
      //   .get("PersonalPhoneNumber")
      //   .setValidators([Validators.pattern(this.phoneNumberPattern)]);

      //End of tempory fix
      this.individuallientSaveForm
        .get("PersonalPhoneNumber")
        .updateValueAndValidity();
    }
  }
  SaveIndividualClient() {
    this.Client.type = "individualclient";
    this.Client.tid = "1";
    this.Client.firstName =
      this.individuallientSaveForm.controls.FirstName.value;
    this.Client.lastName = this.individuallientSaveForm.controls.LastName.value;
    this.Client.titleId = +this.SelectedTitleId;
    this.Client.title = this.SelectedTitleName;
    this.Client.clientCategoryId =
      this.CustomerRegConfig.GuestClientConfig.ClientCategoryConfig.Id;
    this.Client.clientCategory =
      this.CustomerRegConfig.GuestClientConfig.ClientCategoryConfig.Name;
    this.Client.clientTypeId =
      this.CustomerRegConfig.GuestClientConfig.ClientTypeConfig.Id;
    this.Client.clientType =
      this.CustomerRegConfig.GuestClientConfig.ClientTypeConfig.Name;
    this.Client.active = true;
    this.Client.dateRegistered = new Date();
    this.Client.clientName =
      this.SelectedTitleName +
      " " +
      this.individuallientSaveForm.controls.FirstName.value +
      " " +
      this.individuallientSaveForm.controls.LastName.value;
    this.Client.creditLimit = 0;
    this.Client.birthYear = 0;
    this.Client.birthMonth = 0;
    this.Client.birthDay = 0;
    this.Client.anniversaryYear = 0;
    this.Client.anniversaryMonth = 0;
    this.Client.anniversaryDate = 0;

    this.PriceConcessionAnswerOptions.forEach((PriceConcessionAnswerText) => {
      if (PriceConcessionAnswerText.PriceConcessionId) {
        if (PriceConcessionAnswerText.Key == this.additionalDetailNote) {
          this.Client.priceConcession = new PriceConcession();
          this.Client.priceConcession.id =
            PriceConcessionAnswerText.PriceConcessionId;
        }
      }
    });

    let userEmail = this.individuallientSaveForm.controls.PersonalEmail.value;
    let username = this.individuallientSaveForm.controls.UserName.value;
    this.Client.userName = username;

    this.Client.defaultCurrencyId = this.defaultCurrencyId;
    let accManager = new User();
    accManager.type = "user";
    accManager.id = this.CustomerRegConfig.AccountManager.Id.toString();
    accManager.userName = this.CustomerRegConfig.AccountManager.Name;

    this.Client.accountManager = accManager;
    this.Client.creditLimit = 0;

    let defVenue = new Venue();
    defVenue.type = "venue";
    defVenue.id = this.CustomerRegConfig.Venue.Id.toString();
    defVenue.name = this.CustomerRegConfig.Venue.Name;

    this.Client.defaultVenue = defVenue;
    if (this.CustomerRegConfig.Tax.Id > 0) {
      this.Client.tax = new Tax();
      this.Client.tax.id = this.CustomerRegConfig.Tax.Id.toString();
      this.Client.tax.name = this.CustomerRegConfig.Tax.Name;
    }
    this.Client.profilePicture = "";
    let addt = new Address();
    addt.tid = "1";
    addt.country = this.individuallientSaveForm.controls.Address_Country.value;
    addt.address1 = this.individuallientSaveForm.controls.Address_Line1.value;
    addt.address2 = this.individuallientSaveForm.controls.Address_Line2.value;
    addt.address3 = this.individuallientSaveForm.controls.Address_Line3.value;
    let selectedCountry = this.countries.find(country => country.name.toLocaleLowerCase() == this.individuallientSaveForm.controls.Address_Country.value.toLocaleLowerCase())
    if (selectedCountry) {
      addt.countryID = parseInt(selectedCountry.id);
    } else {
      addt.countryID = this.DefaultCountryId;
    }
    addt.city = this.individuallientSaveForm.controls.Address_City.value;
    addt.county =
      this.individuallientSaveForm.controls.Address_CountyCode.value;
    addt.postCode =
      this.individuallientSaveForm.controls.Address_PostCode.value;

    this.Client.address = addt;

    this.Client.communicationMethods = new Array<CommunicationMethod>();
    if (
      this.individuallientSaveForm.controls.PersonalPhoneNumber.value != null &&
      this.individuallientSaveForm.controls.PersonalPhoneNumber.value != ""
    ) {
      let comMethod = new CommunicationMethod();
      comMethod.tid = "3";
      comMethod.communicationTypeID = this.SelectedPhoneTypeId;
      comMethod.communicationType = this.SelectedPhoneTypeName;
      comMethod.value =
        this.individuallientSaveForm.controls.PersonalPhoneNumber.value;
      //contact.communicationMethods.push(comMethod);
      this.Client.communicationMethods.push(comMethod);
    }
    if (
      this.individuallientSaveForm.controls.PersonalEmail.value != null &&
      this.individuallientSaveForm.controls.PersonalEmail.value != ""
    ) {
      let comMethod = new CommunicationMethod();
      comMethod.tid = "4";
      comMethod.communicationTypeID = this.SelectedEmailTypeId;
      comMethod.communicationType = this.SelectedEmailTypeName;
      comMethod.value =
        this.individuallientSaveForm.controls.PersonalEmail.value;
      //contact.communicationMethods.push(comMethod);
      this.Client.communicationMethods.push(comMethod);
    }
    if (this.individuallientSaveForm.invalid) {
      this.snackBar.openFromComponent(AlertMessageComponent, {
        duration: 3000,
        verticalPosition: "top",
        data: ["Please check the indicated fields."],
      });
    } else if (this.addressLoaded || this.IsEnableManualAddress) {
      let clientSaveResult = this._customerService.individualClientSave(
        this.Client
      );
      clientSaveResult.subscribe(
        (data) => {
          if (data != null) {
            if (data.id != null) {
              this.Contact.ref = data.contacts[0].ref;
              this.Contact.id = data.contacts[0].id;
              this.Contact.type = data.type;
              this.Contact.client.id = data.id;
              this.Contact.client.clientRef = data.clientRef;
              this.Contact.title = data.title;
              this.Contact.client.clientName = data.clientName;
              this.Contact.client.clientRef = data.clientRef;
              this.Contact.firstName =
                this.individuallientSaveForm.controls.FirstName.value;
              this.Contact.lastName =
                this.individuallientSaveForm.controls.LastName.value;
              this.Contact.userName = username;
              this.Contact.invoiceEmailAddress = userEmail;
              if (data.priceConcession && data.priceConcession.id) {
                this.Contact.client.priceConcessionId = data.priceConcession.id;
              }
              this._customerService
                .SendCustomerRegisterConfirmation(
                  userEmail,
                  data.contacts[0].id
                )
                .subscribe((result) => { });
              if (data.profilePicture != undefined)
                this.Contact.profilePicture = data.profilePicture;
              // additional details save
              if (
                this.additionalDetailNote ||
                this.additionalDetail.documents.length > 0
              ) {
                this.additionalDetail.note.entityTypeID = "5";
                this.additionalDetail.contactId = this.Contact.id;
                this.additionalDetail.contactRef = this.Contact.ref;
                this.additionalDetail.clientId = this.Contact.client.id;
                this.additionalDetail.clientRef = this.Contact.client.clientRef;
                this.additionalDetail.isUpdate = false;
                this.additionalDetail.note.plainText = "";
                this.additionalDetail.sendAdditionalDetailNotificationMail =
                  (this.additionalDetailNote != undefined &&
                    this.additionalDetailNote != "") ||
                  this.additionalDetail.documents.length > 0;
                if (this.additionalDetailNote) {
                  this.additionalDetail.note.plainText =
                    "Client Note - Additional Note Created At : " +
                    Utility.convertToISO(new Date()) +
                    " " +
                    this.additionalDetailNote;
                }
                if (
                  this.individuallientSaveForm.controls
                    .Request_To_Regular_Booking_Access_Note.value
                ) {
                  this.additionalDetail.note.plainText +=
                    "Client Note - Reqular Booking Note - 1 Created At: " +
                    Utility.convertToISO(new Date()) +
                    " " +
                    this.individuallientSaveForm.controls
                      .Request_To_Regular_Booking_Access_Note.value;
                }

                let clientAdditionalDetailSaveResult =
                  this._customerService.AdditionalDetail(this.additionalDetail);
                clientAdditionalDetailSaveResult.subscribe(
                  (data) => {
                    if (data.isError) {
                      let errorData = [];
                      if (data.errors != null && data.errors.length > 0) {
                        data.errors.forEach((error, i) => {
                          errorData.push(error.detail);
                        });
                        this.snackBar.openFromComponent(AlertMessageComponent, {
                          duration: 3000,
                          verticalPosition: "top",
                          data: errorData,
                        });
                      }
                    } else {
                      this.redirect();
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              } else {
                this.redirect();
              }
            }
          }
        },
        (error) => {
          let err = JSON.parse(error.message);
          console.log(err);
          let errorData = [];
          if (err.errorCode == 500) {
            errorData.push(err.errorMessage);
          } else if (err.errorCode == 400) {
            if (err.errors) {
              if (err.errors != null && err.errors.length > 0) {
                err.errors.forEach((error, i) => {
                  errorData.push(error.detail);
                });
              }
            }
          }
          this.snackBar.openFromComponent(AlertMessageComponent, {
            duration: 3000,
            verticalPosition: "top",
            data: errorData,
          });
        }
      );
    }
  }
  get getPublicWebRedirection() {
    let url = localStorage.getItem("PublicWebRedirection");
    localStorage.removeItem("PublicWebRedirection");
    return url ? url : this.data.publicBookingReturnUrl;
  }
  redirect() {
    this.store.dispatch(new SetGuestContact(this.Contact));
    this.store.dispatch(new CheckBookingHasClient());
    this.dialogRef.close();
    let _redirectFullUrl;
    let localRedirection = this.getPublicWebRedirection;
    let checkoutRedirection = localStorage.getItem(
      "PublicWebRedirectToPublicPortal"
    );
    let bookingApplicationRedirection = localStorage.getItem("PublicWebBookingApplicationPortal");
    if (checkoutRedirection) {
      localStorage.removeItem("PublicWebRedirectToPublicPortal");
      _redirectFullUrl = checkoutRedirection;
    } else if (bookingApplicationRedirection) {
      localStorage.removeItem("PublicWebBookingApplicationPortal");
      _redirectFullUrl = bookingApplicationRedirection;
    } else {
      if (localRedirection) {
        _redirectFullUrl =
          environment.PublicWebPortalUrl + ("#/" + localRedirection);
      } else {
        _redirectFullUrl = environment.PublicWebPortalUrl;
      }
    }
    window.location.href = _redirectFullUrl;
  }
}
