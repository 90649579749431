
export class QuestionPackageFilters {
    type: string;
    constructor() {
        this.type = "QuestionPackageFilters";
    }
    id: string;
    name: string;
    questionItemFilters: QuestionItemFilters[];

    // answer: Answer;
}
export class QuestionItemFilters{
    constructor() {
        this.type = "QuestionItemFilters";
    }
    type: string;
    id: string;
    name: string;
}