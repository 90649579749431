export class UserData {
    public userId: number;
    public contactId: number;
    public defaultVenueId: number;
    public contactEmail: string;
    public userName: string;
    public firstName: string;
    public lastName: string;
    public userImage: string;
    public profilePicture: string;
    public userImageUrl: string;
  //  public UserRightList: UserAccessRights[];
    public venueName: string;
    public jobPosition: string;
   // public Groups: Groups[];


}