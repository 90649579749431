import { BaseResource } from '../../../base-resource';
import { Timeslot } from './timeslot';

export class Allocation extends BaseResource {
    constructor() {
        super();
        this.timeslot = new Timeslot();
        this.type = "Allocation";
    }

    timeslot: Timeslot;
    attendees: number;
}