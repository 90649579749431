import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';

import { FacilityAdaptor } from 'src/modules/models/asset/facility-adaptor';
import { FacilitySummary } from 'src/modules/models/asset/facility-summary';
import { Facility } from 'src/modules/models/asset/facility';
import { CalenderTimeslot, Timeslot } from 'src/modules/models/asset/timeslots';
import { environment } from 'src/environments/environment';

import { AssetService } from 'src/modules/services/asset.service';

import { CalendarModalComponent } from 'src/modules/booking-portal/common/calendar-modal/calendar-modal.component';
import { EditModalComponent } from 'src/modules/booking-portal/booking-cart/edit-modal/edit-modal.component';
import { RegularBookingSettings } from 'src/modules/models/settings/regular-portal/regular-booking-setting';
import { MomentDateAdapter } from 'src/modules/booking-portal/common/date-time-adapter/moment-date-adapter';
import { Utility } from 'src/modules/utility';

import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'opt-ngx-gallery';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';

// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';
import { AppSetting } from 'src/modules/models/settings/casual-portal/app-setting';
import { DomSanitizer } from '@angular/platform-browser';
import { Booking } from 'src/modules/models/client/client';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
    parse: { dateInput: 'LL' },
    display: {
        dateInput: 'DD MMM YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'opt-mmc-slider',
    templateUrl: './mmc-slider.component.html',
    providers: [
        // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
        // application's root module. We provide it at the component level here, due to limitations of
        // our example generation script.
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
    ]
})

export class MmcSliderComponent implements OnInit {
    @Input() facilityDetailDTO: Facility;
    @Input() facilityAvailablity: boolean;
    @Input() startDateTime: string;
    @Input() endDateTime: string;
    @Input() searchFacilities: FacilitySummary[];
    @Input() facilityId: string;
    @Input() defaultTimeIsAny: boolean;
    @Input() dateInUrl: any;
    @Input() viewOnly: any;
    @Input() bookingTypeId: any;  
    @Output() childSearchEvent = new EventEmitter();
    @Output() childBookingEnquiry = new EventEmitter();
    @Output() changeStatus = new EventEmitter();
    @Input() bookingDetails: Booking;

    date = moment();
    isValidStartTime: boolean;
    isValidEndTime: boolean;
    startTime: Date | undefined = new Date();
    endTime: Date | undefined = new Date();
    minDate: Date = new Date();
    minStartDate: Date;
    maxStartDate: Date;
    minEndDate: Date;
    maxEndDate: Date;
    isValidStart: boolean;
    isValidEnd: boolean;
    searchedStartDate: Date;
    searchedEndDate: Date;
    isConfigurationEnabled: boolean;
    galleryOptions: NgxGalleryOptions[] = [];
    galleryImages: NgxGalleryImage[] = [];
    ftc: FacilitySummary = new FacilitySummary();
    timelable: string;
    timelableDate: any;
    SelectedDate: any;
    isTimeChanged: boolean;
    showUnavailableFutureDateMessage: boolean = false;
    showUnavailableFutureDateErrorMessage: string;
    showUnavailableOperationalMessage: boolean = false;
    calenderDate: string;
    isShowSetupTimeInCalendar: boolean;
    isEnableTimeLineAdvancedMode: boolean;
    //Price Hiding system setting
    displayPrice: boolean;
    facilitySearchTextPlaceHolder: string;
    FacilityScreenMainDescription: any;

    constructor(private _assetService: AssetService, private dialog: MatDialog, private sanitizer: DomSanitizer) {
        this.isShowSetupTimeInCalendar = environment.IsShowSetupTimeInCalendar;
        this.isEnableTimeLineAdvancedMode = environment.CheckAvailability.EnableTimeLineAdvancedMode;
        this.isConfigurationEnabled = environment.ConfigurationSelection.Enabled;
        var _appSettings = environment.AppSetting as AppSetting;  
        this.facilitySearchTextPlaceHolder = _appSettings.FacilityPageSetting.FacilitySearchTextPlaceHolder;
        this.FacilityScreenMainDescription = this.sanitizer.bypassSecurityTrustHtml(_appSettings.FacilityPageSetting.FacilityScreenMainDescription);
        
    }

    ngOnInit(): void {

        if (this.bookingTypeId) {
            var _regularXmlData = (environment.RegularBookingSetting as RegularBookingSettings);
            var _filteredBookingType = _regularXmlData.booking.bookingTypes.find(x => x.id == this.bookingTypeId);
            if (_filteredBookingType != undefined) {
                this.displayPrice = _filteredBookingType.displayPrice;
            }
        }

        var leadDays = (environment.LeadDaysForBooking == null) ? 0 : environment.LeadDaysForBooking;
        if (environment.DisableDatePickerForLeadDays) {
            var currentDate = new Date();
            currentDate.setDate(currentDate.getDate() + leadDays);
            this.minDate = currentDate;
        }

        this.isTimeChanged = false;
        if (!this.defaultTimeIsAny) {
            if (this.startDateTime && this.endDateTime) {
                this.date = moment(this.startDateTime);
                this.startTime = Utility.convertISOToDate(this.startDateTime);
                this.endTime = Utility.convertISOToDate(this.endDateTime);
                this.setTimeLabel();
            }
        } else {
            this.timelable = 'Any';
            var dt = Utility.convertISOToDate(this.startDateTime);
            this.startTime = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;

            if (this.startTime.getHours() == 23 && this.startTime.getMinutes() == 30) {
                this.endTime = moment(this.startTime).add(environment.MinimumBookingDuration - 1, 'm').toDate();
            } else {
                this.endTime = moment(this.startTime).add(environment.MinimumBookingDuration, 'm').toDate();
            }
            if (this.dateInUrl != null)
                this.date = moment(this.dateInUrl);
        }
        this.ftc = this.searchFacilities[0];
        this.loadImages();
        this.initializeTime();
        this.timelableDate = this.date;
       
    }

    setTimeLabel() {
        var start = moment(this.startTime.toISOString());
        var end = moment(this.endTime.toISOString());

        this.timelable = " | " + start.format(environment.TimeFormat) + " - " + end.format(environment.TimeFormat);
        this.timelableDate = this.date;
        this.SelectedDate = this.date;
    }

    openTimePopup() {

        this.dialog.open(CalendarModalComponent, {
            panelClass: ['custom-dialog-container', 'w60modal'],
            //height: '90vh',
            width: '60%',
            data: { slots: this.ftc.calenderTimeslots, availableSlots: this.ftc.availableTimeslots, configuration: this.ftc.getSelectedConfiguration(), isAny: this.timelable == 'Any' ? true : false }
        });
    }

    loadFacilityTimeslots() {
        var fc = this.facilityId;
        var dt = new Date(this.date.toDate());
        this.calenderDate = dt.getFullYear().toString() + dt.getMonth().toString() + dt.getDate().toString();
        let dtS = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
        let dtE = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
        this.ftc.calenderTimeslots = []
        this.ftc.availableTimeslots = [];
        

        if (this.facilityDetailDTO && this.facilityDetailDTO.openingTime) {
            var ots = new CalenderTimeslot();
            let stTime = new Date(0, 0, 0, 0, 0, 0, 0);

            ots.start = 0;
            ots.startTime = stTime;
            ots.isUnAvailableDueToOperationalHours = true;

            let ctTime = Utility.convertISOToDate(this.facilityDetailDTO.openingTime);
            var otm = ctTime.getMinutes();
            if (otm == 59) {
                ctTime.setMinutes(0, 0, 0);
                ctTime.setHours(ctTime.getHours() + 1);
            }
            ots.end = (ctTime.getHours() + ctTime.getMinutes() / 60);
            ots.endTime = ctTime;
            this.ftc.calenderTimeslots.push(ots)
            this.ftc.timeslotLoaded = true;
        }

        if (this.facilityDetailDTO && this.facilityDetailDTO.closingTime) {
            var cts = new CalenderTimeslot();
            let endStartTime = Utility.convertISOToDate(this.facilityDetailDTO.closingTime);
            cts.end = 24.0;
            cts.endTime = new Date(0, 0, 0, 24, 0, 0, 0);
            cts.isUnAvailableDueToOperationalHours = true;
            cts.start = (endStartTime.getHours() + endStartTime.getMinutes() / 60);
            cts.startTime = endStartTime;
            this.ftc.calenderTimeslots.push(cts);
            this.ftc.timeslotLoaded = true;
        }

        dtE.setHours(23, 59, 0, 0);
        this._assetService.timeslotSearch(fc, Utility.convertToISO(dtS), Utility.convertToISO(dtE)).subscribe(res => {
            if (res) {
                res.forEach(t => {
                    if (t.asset.id) {
                        if (!this.ftc.calenderTimeslots)
                            this.ftc.calenderTimeslots = [];

                        var arr: Timeslot[] = [];
                        arr.push(t);
                        var getSearchDate = new Date(this.date.year(), this.date.month(), this.date.date());
                        var ts = FacilityAdaptor.populateTimeslot(arr, getSearchDate);
                        if (ts) {
                            ts.forEach(x => {
                                this.ftc.calenderTimeslots.push(x);
                            });
                        }
                    }
                });

                FacilityAdaptor.populateAvailableTimeslots([this.ftc], dtS);
                if (!this.isTimeChanged) {
                    var state = this.defaultTimeIsAny && (this.ftc.getAvailabilityLabel() == 'FULLY AVAILABLE');
                    var availabilityState = this.checkAvailabilityForAny(this.ftc.availableTimeslots);

                    if (state && availabilityState) {
                        this.changeStatus.next(true);
                    } else if (state && !availabilityState) {
                        this.changeStatus.next(false);
                    }
                }
            }
            if (this.isEnableTimeLineAdvancedMode) this.drawSelectedTimeline();
        });
    }
    addToBooking() {
        //if (this.isConfigurationEnabled || this.ftc.attendees == 0) {
        if(this.bookingDetails && !this.bookingDetails.id){
            let dialogRef = this.dialog.open(EditModalComponent, {
                data: { facility: this.ftc, isaddfacility: true },
                panelClass: ['custom-dialog-container', 'w60modal'],
                height: 'auto',
                width: '60%'
                // DialogPosition : top

            });

            dialogRef.afterClosed().subscribe(result => { })
            
        }
    }

    bookingEnquiry() {
        if (this.facilityAvailablity && !this.showUnavailableFutureDateMessage && !this.showUnavailableOperationalMessage) {
            this.childBookingEnquiry.next({ timeslots: this.ftc.calenderTimeslots });
        }

        
       

    }

    apply() {
        this.showUnavailableFutureDateMessage = false;
        this.showUnavailableOperationalMessage = false;

        if (this.isValidStart && this.isValidEnd) {
            var selectedDate = this.date.toDate();
            selectedDate.setHours(0, 0, 0, 0);
            var futureDate = new Date(environment.LatestFutureBookingDate);
            futureDate.setHours(0, 0, 0, 0);

            this.showUnavailableFutureDate(selectedDate, futureDate);
            this.setTimeLabel();

            this.ftc.getAvailabilityLabel();
            var dt = new Date(this.date.toDate());
            let seletedDate = dt.getFullYear().toString() + dt.getMonth().toString() + dt.getDate().toString();
            if (seletedDate != this.calenderDate) {
                this.loadFacilityTimeslots();
            }

            this.setTimeLabel();
            var start = moment(this.startTime.toISOString());
            var end = moment(this.endTime.toISOString());
            this.isTimeChanged = true;

            this.searchedStartDate = this.startTime;
            this.searchedEndDate = this.endTime;

            if (!environment.AllowToDoBookingForNonOperationalHours) {
                let searchStartTime = new Date(this.date.year(), this.date.month(), this.date.date());
                searchStartTime.setHours(this.startTime.getHours(), this.startTime.getMinutes(), this.startTime.getSeconds());

                let searchEndTime = new Date(this.date.year(), this.date.month(), this.date.date());
                searchEndTime.setHours(this.endTime.getHours(), this.endTime.getMinutes(), this.endTime.getSeconds());

                if (this.facilityDetailDTO.openingTime) {
                    let openingTime = Utility.changeDatePart(Utility.convertISOToDate(this.facilityDetailDTO.openingTime), Utility.convertISOToDate(Utility.convertToISO(this.startTime)));
                    if (searchStartTime < openingTime && searchStartTime.toTimeString() != openingTime.toTimeString()) {
                        this.showUnavailableOperationalMessage = true;
                        return;
                    }
                }
                if (this.facilityDetailDTO.closingTime) {
                    let closingTime = Utility.changeDatePart(Utility.convertISOToDate(this.facilityDetailDTO.closingTime), Utility.convertISOToDate(Utility.convertToISO(this.endTime)));
                    if (searchEndTime > closingTime && searchEndTime.toTimeString() != closingTime.toTimeString()) {
                        this.showUnavailableOperationalMessage = true;
                        return;
                    }
                }
            }
            this.childSearchEvent.next({ 'date': new Date(this.date.year(), this.date.month(), this.date.date()), 'startTime': this.startTime, 'endTime': this.endTime });
            if (this.isEnableTimeLineAdvancedMode) this.drawSelectedTimeline();
        }
    }

    async loadImages() {
        if (this.facilityId != undefined) {
            var pr = this._assetService.loadFacilityImages(this.facilityId).toPromise();
            return await pr.then(images => {
                var showThumbnail = true;
                if (images && images.large) {
                    showThumbnail = (images.large.length > 1);

                    this.galleryOptions = [
                        {
                            width: '100%',
                            height: '700px',
                            thumbnails: showThumbnail,
                            thumbnailsColumns: 5,
                            imageAnimation: NgxGalleryAnimation.Slide,
                            preview: false
                        },
                        {
                            breakpoint: 768,
                            width: '100%',
                            height: '100vh',
                            imagePercent: 80,
                            thumbnails: showThumbnail,
                            thumbnailsPercent: 20,
                            thumbnailsMargin: 20,
                            thumbnailMargin: 20
                        },
                        {
                            thumbnails: showThumbnail,
                            breakpoint: 400
                        }
                    ];

                    images.large.forEach(i => {
                        this.galleryImages.push(
                            {
                                small: i,
                                medium: i,
                                big: i
                            });
                    });
                }
            });
        }
    }

    checkAvailabilityForAny(arr) {
        var status = false;
        if (arr.length > 0) {
            arr.forEach(x => {
                let duration: number = +x.endTime - +x.startTime;
                let minutes = (duration / (1000 * 60));
                if (minutes > environment.MinimumBookingDuration) {
                    status = true;
                }
            });
        }
        return status;
    }

    //#region time picker

    initializeTime(): void {
        this.minStartDate = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
        this.maxStartDate = new Date();
        this.maxStartDate.setHours(24, 0, 0);
        this.maxStartDate = moment(this.maxStartDate).add(environment.MinimumBookingDuration * -1, 'm').toDate();

        this.minEndDate = moment(this.startTime).add(environment.MinimumBookingDuration, 'm').toDate();
        this.maxEndDate = new Date();
        this.maxEndDate.setHours(24, 0, 0);
        this.loadFacilityTimeslots();
        this.dateChanged();
    }

    dateChanged() {
        if (this.isValidStartTime && this.isValidEndTime) {
            var start = moment(this.startTime.toISOString());
            var end = moment(this.endTime.toISOString());
            this.childSearchEvent.next({ 'date': new Date(this.date.year(), this.date.month(), this.date.date()), 'startTime': this.startTime, 'endTime': this.endTime });
        }

        var dt = this.date.toDate();
        this.startTime = Utility.changeDatePart(this.startTime, this.date.toDate());
        var today = new Date();

        if (!(dt.getFullYear() == today.getFullYear() && dt.getMonth() == today.getMonth() && dt.getDate() == today.getDate())) {
            dt.setHours(0, 0, 0);
            this.minStartDate = dt;
        } else {
            if (new Date(this.startTime) < new Date()) {
                this.startTime = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
            }
            else {
                let newstartTime = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
                newstartTime.setHours(this.startTime.getHours());
                newstartTime.setMinutes(this.startTime.getMinutes());
                this.startTime = newstartTime;
            }
            this.minStartDate = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
        }

        this.maxStartDate = Utility.changeDatePart(new Date(this.maxStartDate), this.date.toDate());
        this.maxStartDate.setMinutes(this.maxStartDate.getMinutes() + 1);

        let newEndTime = moment(this.startTime).add(environment.MinimumBookingDuration, 'm').toDate();
        newEndTime.setHours(this.endTime.getHours(), this.endTime.getMinutes(), 0, 0);

        this.endTime = newEndTime;
        this.minEndDate = new Date(moment(this.startTime).add(environment.MinimumBookingDuration, 'm').toDate());
        this.maxEndDate = new Date(this.minEndDate);
        this.maxEndDate.setHours(24, 0, 0, 0);

        var selectedDate = this.date.toDate();
        selectedDate.setHours(0, 0, 0, 0);
        var futureDate = new Date(environment.LatestFutureBookingDate);
        futureDate.setHours(0, 0, 0, 0);

        this.showUnavailableFutureDate(selectedDate, futureDate);
    }

    showUnavailableFutureDate(selectedDate: Date, futureDate: Date) {
        if (selectedDate > futureDate) {
            this.showUnavailableFutureDateMessage = true;
            this.showUnavailableFutureDateErrorMessage = environment.LatestFutureBookingDateErrorMessage.replace("#FacilityName#", this.facilityDetailDTO.name);
            this.showUnavailableFutureDateErrorMessage = this.showUnavailableFutureDateErrorMessage.replace("#LatestFutureBookingDate#", moment(environment.LatestFutureBookingDate).format(environment.DateFormat));
            return;
        }
    }

    startTimeChanged() {
        if (this.startTime != null) {
            var dtObj = Utility.roundToMinDuration(this.startTime, environment.MinimumBookingDuration);
            if (dtObj.isDateChanged) this.startTime = moment(dtObj.newDate).toDate();

            this.minEndDate = moment(this.startTime).add(environment.MinimumBookingDuration, 'm').toDate();

            if (moment(this.startTime) >= moment(this.endTime))
                this.endTime = moment(this.startTime).add(environment.MinimumBookingDuration, 'm').toDate();

            var dt = this.date.toDate();
            var today = new Date();

            if ((dt.getFullYear() == today.getFullYear() && dt.getMonth() == today.getMonth() && dt.getDate() == today.getDate())) {
                if (this.startTime < today) {
                    this.startTime = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
                }
            }
            if (this.endTime.getFullYear() != this.startTime.getFullYear() ||
                this.endTime.getMonth() != this.startTime.getMonth() || this.endTime.getDate() != this.startTime.getDate()) {
                this.endTime.setFullYear(this.startTime.getFullYear(), this.startTime.getMonth(), this.startTime.getDate());
            }
        }
    }

    endTimeChanged() {
        if (this.endTime != null) {
            if (this.endTime.getFullYear() == this.maxEndDate.getFullYear() &&
                this.endTime.getMonth() == this.maxEndDate.getMonth() &&
                this.endTime.getDate() == this.maxEndDate.getDate() &&
                this.endTime.getHours() == this.maxEndDate.getHours() &&
                this.endTime.getMinutes() == this.maxEndDate.getMinutes()
            ) {
                this.endTime = moment(this.endTime).add(-1, 'm').toDate();

            } else {
                var dtObj = Utility.roundToMinDuration(this.endTime, environment.MinimumBookingDuration, true);
                if (dtObj.isDateChanged)
                    this.endTime = moment(dtObj.newDate).toDate();

                if (moment(this.startTime) >= moment(this.endTime))
                    this.endTime = moment(this.startTime).add(environment.MinimumBookingDuration, 'm').toDate();
            }
        }
        return this.endTime;
    }

    //#endregion
    drawSelectedTimeline() {
        this.ftc.calenderTimeslots = this.ftc.calenderTimeslots.filter(x => !x.isSelectedTimeSlot);

        let searchdate = new Date(this.date.year(), this.date.month(), this.date.date());
        let selectedStart = Utility.appendTimePart(searchdate, Utility.convertISOToDate(this.startTime), true);
        let selectedEnd = Utility.appendTimePart(searchdate, Utility.convertISOToDate(this.endTime), true);
        FacilityAdaptor.populateSelectedTimeslot(this.ftc, selectedStart, selectedEnd);
        
    }
    enableBookButton(facility: FacilitySummary) {
        return facility.item && facility.configuration && (facility.item.itemMinPrice || facility.item.itemMaxPrice)
    }
}