<div class="mat-dialog-content calender-modal-wrapper">
    <div class="modal-header ">
        <div class="mmc_flex--container" *ngIf="isPopup">
            <div class="mmc_flex__item--inherit icon">
                <button class="mat-icon-button mat-button" tabindex="0" (click)="close()" (keydown.enter)="close()">
                    <mat-icon class="mat-icon material-icons" role="img" aria-hidden="true">close</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="modal-body">
        <div class="calendar-modal">
            <div class="container" style="min-width: 100% !important;">
                <div class="topic" *ngIf="!isEnableTimeLineAdvancedMode">
                    <div class="mmc_flex--container">
                        <div class="mmc_flex__item font__semibold available-title" *ngIf="availableSlots && availableSlots.length>=1">
                            Available time slot(s)
                        </div>
                        <div class="mmc_flex__item font__semibold" *ngIf="!availableSlots || availableSlots.length==0">
                            No time slots are available
                        </div>
                        <div *ngIf="availableSlots && availableSlots.length==1">
                            <div class="mmc_flex__item" *ngFor="let slot of availableSlots;">
                                <span class="font__black font__semibold">{{slot.startTime | dateFormat:'time'}}</span>
                                <span class="to">to</span>
                                <span class="font__black font__semibold">{{slot.endTime | dateFormat:'time'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="availableSlots && availableSlots.length>1 && !isEnableTimeLineAdvancedMode" class="chip-cage">
                    <div class="mmc_flex--container chip-set" *ngFor="let slot of availableSlots;">
                        <div class="mmc_flex__item--vcenter single-chip">
                            <span class="font__black font__semibold">{{slot.startTime | dateFormat:'time'}}</span>
                            <span class="to">to</span>
                            <span class="font__black font__semibold">{{slot.endTime | dateFormat:'time'}}</span>
                        </div>
                    </div>
                </div>
                <div class="legend d-none">
                    <div class="mmc_flex--container w-80">
                        <div *ngFor="let timeLineItem of timeLineItems">
                            <div class="mmc_flex__item mmc_flex__item--vcenter mr-3">
                                <div class="leg-1" [ngStyle]="{backgroundColor:getLegendColor(timeLineItem)}">

                                </div>
                                <span class="pw-txt-s2 pl-2">{{timeLineItem.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="man-popup-cop" *ngIf="!isEnableTimeLineAdvancedMode && slots">
                    <div class="timeline">
                        <ng-container *ngFor="let slot of slots; let i = index">
                            <div class="time-line--sub" [style.left.%]="(slot.start/24) * 100" [style.width.%]="(slot.end - slot.start) /24 * 100" *ngIf="!slot.isUnAvailableDueToOperationalHours"></div>
                            <div class="time-line--unavailable" [style.left.%]="(slot.start/24) * 100" [style.width.%]="(slot.end - slot.start) /24 * 100" *ngIf="slot.isUnAvailableDueToOperationalHours"></div>
                        </ng-container>
                    </div>
                    <div class="time-line-separator">
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                    </div>
                </div> -->

                <!--timeline new-->
                <div class="man-popup-cop" *ngIf="slots">                   
                    <div class="{{getTimelineClass()}}" [ngStyle]="{'backgroundColor':getTimeLineColor('available')}">
                        <ng-container *ngFor="let slot of slots; let i = index">
                            <div *ngIf="!this.isAny || (this.isAny && !slot.isBumpInBumpOut && !slot.isSelectedTimeSlot)" class="{{getClass(slot)}}" [ngStyle]="{'backgroundColor':getBg(slot)}" [style.left.%]="(slot.start/24) * 100" [style.width.%]="(slot.end - slot.start) /24 * 100"></div>                  
                         </ng-container>
                    </div>
                    <div class="time-line-separator">
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                    </div>
                </div>

                <!--timeline new-->
            </div>
        </div>

    </div>

</div>
