export class BaseParam {
    include: string;
    fields: string;
    filter: any
    paging: Paging
    sort:string
}

export class Paging {
    number: string
    size: string
}
