import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OptMaterialModule } from '../shared/opt-material/opt-material.module';
import { OptFormModules } from 'src/modules/shared/form.modules';
import { OptCommonModules } from 'src/modules/shared/common.modules';

import { NguCarouselModule } from '@ngu/carousel';


import { WpSeasonsCarouselComponent } from 'src/modules/regular-booking/wp-seasons-carousel/wp-seasons-carousel.component';
import { WpAddTeamsComponent } from 'src/modules/regular-booking/wp-add-teams/wp-add-teams.component';
import { WpConfirmationMessageComponent } from 'src/modules/regular-booking/messages/wp-confirmation-message/wp-confirmation-message.component';
import { WPErrorMessage } from 'src/modules/regular-booking/messages/wp-error-message/wp-error-message';
import { WPBoookingRolloverComponent } from 'src/modules/regular-booking/wp-booking-rollover/wp-booking-rollover';
import { WpRolloverBookingModalComponent } from 'src/modules/regular-booking/popups/wp-rollover-booking-modal/wp-rollover-booking-modal.component';

@NgModule({
    declarations: [WpSeasonsCarouselComponent, WpAddTeamsComponent, WPErrorMessage, WPBoookingRolloverComponent, WpRolloverBookingModalComponent, WpConfirmationMessageComponent],
    imports: [
        FormsModule, ReactiveFormsModule,
        CommonModule,
        OptFormModules,
        NguCarouselModule,
        OptFormModules,
        OptMaterialModule,
        OptCommonModules
    ],
    exports: [
        WpAddTeamsComponent,
        WpSeasonsCarouselComponent,
        WpConfirmationMessageComponent,
        WPBoookingRolloverComponent,
        WpRolloverBookingModalComponent,
        WPErrorMessage
    ],
    entryComponents: [
        WPErrorMessage,
        WpRolloverBookingModalComponent
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RBSharedModule { }
