
<div class="wp-modal-wrapper wp-message-modal">
    <div class="mmc-modal-body">
        <div class="mmc_flex--container pw-fd-col wp-modal-title">
            <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-h4 pw-text-semi-bold pw-text-color__primary">Please specify the changes you wish to request</div>
            <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-s1 pw-text-color__tertiary">Example: Date change, location etc.</div>
        </div>
        <div class="mmc_flex--container inner">
            <mat-form-field class="example-full-width">
                <!--<mat-label>Address</mat-label>-->
                <textarea matInput placeholder=""></textarea>
            </mat-form-field>
        </div>

        <div class="btn-wrapper">
            <button type="submit" class="mmc-btn mmc-btn-secondary--small main mmc_flex__item--vcenter" mat-button tabindex="0">Submit</button>
        </div>
    </div>
</div>

