

import { BaseResource } from '../base-resource';
import { Note } from 'src/modules/models/client/note';
import { AdHocDocument } from '../booking/adhoc-document';

export class CustomerAdditionalDetail extends BaseResource {
    public contactId: string;
    public contactRef: string;
    public clientId: string;
    public clientRef: string;
    public isUpdate: boolean;
    public note: Note;
    public documents: AdHocDocument[];
    public removedDocumentIds: string[];
    public sendAdditionalDetailNotificationMail: boolean;
    public sendNotificationForRegularBookingAccess: boolean;
  
}
