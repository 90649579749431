<mat-expansion-panel [expanded]="true" [disabled]="true">
  <mat-expansion-panel-header class="mat-header">
    <!--<div class="wp_flex--container flex-column">
            <div class="wp_flex__item--inherit wp_flex__item--vcenter">
                <div class="wp-txt-body1 wp-mr-10" *ngIf="isItemQuest">20th Oct 2019</div>
                <div class="wp-txt-body1 wp-txt--secondary" *ngIf="isItemQuest">09:00 AM - 01:00 PM</div>
            </div>
            <div class="wp_flex__item--inherit wp_flex__item--vcenter">
                <div class="wp-txt-body2">Q{{questionNumber}}. {{question.questionText}}?</div>
            </div>
        </div>-->
    <opt-question-header [dates]="dates" [isMandatory]="bookingQues?.question?.mandatory"
      [questionNumber]="questionNumber" [questionText]="bookingQues?.question?.questionText"
      (popselectedDates)="RecveselectedDates($event)"></opt-question-header>
  </mat-expansion-panel-header>
  <div class="wp_flex--container flex-column">
    <div class="wp_flex__item--inherit wp_flex__item--vcenter">
      <mat-radio-group aria-label="Select an option" (change)="selectionChanged($event)"
        [(ngModel)]="bookingQues.answer">
        <mat-radio-button id="yes{{bookingQues?.id}}" [value]="true" class="mr-2">{{"QUESTIONPOPUP.Yes" | optimotranslate}}</mat-radio-button><br>
        <mat-radio-button id="no{{bookingQues?.id}}" [value]="false">{{"QUESTIONPOPUP.No" | optimotranslate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</mat-expansion-panel>
<div class="wp_flex--container flex-column question-list " class="mt-4 conditional-question">
  <div class="wp_flex__item--inherit wp_flex__item--vcenter">
    <opt-question class="w-100" [isMandatory]="false" [questionNumber]="1"
      [questions]="bookingQues.conditionalBookingQuestions" [answeredQuestionIds]="answeredQuestionIds"
      (questionAnswerOutput)="conditionalQuestionAnswerChange($event)"></opt-question>
  </div>
</div>
