<div class="cdk-overlay-container">
    <div class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing">
    </div>
    <div class="loader-outer">
        <div class="loader">
            <div class="sniper">
                <mat-spinner [strokeWidth]="5" [diameter]="100"></mat-spinner>
                <div class="font__h4">{{'LOADER.Processing' | optimotranslate }}</div>
                <div class="font__small">{{'LOADER.Please be patient while we prepare this data for you.' | optimotranslate }}</div>
            </div>
        </div>
    </div>
    
</div>
