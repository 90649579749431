<main role="main">
<div class="body-wrapper" (keydown.enter)="$event.preventDefault()">
    <div class="individual-customer-wrapper pb-5" style="min-height:500px" id="pageTop">
        <opt-sub-header></opt-sub-header>

        <div class="container body-search-container" *ngIf="contact">
            <!--<div class="pw-txt-h2 pw-text-semi-bold title title-primary pw-text-color__quinary txt-color-same">
                My
                <span class="title-secondary pw-text-color__primary">Profile</span>
            </div>-->

            <div class="row customer-title-row">
                <div class="col-lg-6 col-md-6 pw-txt-h2 pw-text-semi-bold title title-primary pw-text-color__quinary">
                    {{'MYPROFILE.My'| optimotranslate}}
                    <span class="title-secondary pw-text-color__primary">{{'MYPROFILE.Profile'| optimotranslate}}</span>
                </div>

                <div class="col-lg-6 col-md-6 dflex" *ngIf="contact?.isPrimaryContact && contact?.type == 'corporateClient'">
                    <button mat-button class="mmc-btn-secondary pw-text-trans__upper" (click)="openManageContacts()" (keydown.enter)="openManageContacts()" tabindex="0"><span class="material-icons mr-2 pw-ico-color">settings</span>Manage Contacts</button>
                </div>
            </div>





          
            <div class="row customer-bio-row">
                <div [ngClass]="{'col-md-6 left-col': showCoparateDetails, 'col-md-12 left-col': !showCoparateDetails}">
                  <span class="account-customer-name">
                    <span class="section-title">{{contact?.title}} {{contact?.firstName}} {{contact?.lastName}}</span>
                  </span>
                </div>
                <div *ngIf="showCoparateDetails" class="col-md-6 right-col">
                  <span class="account-customer-detail">
                    <span class="section-title-secondary">{{contact?.client?.clientName }}</span>
                  </span>
                </div>
            </div>




            <form name="form" #formProfile="ngForm" novalidate>
                <div class="inner-container">
                    <div class="mmc_flex--container sub-section-title">
                        <div class="mmc_flex__item--inherit font__semibold">
                            {{customerDetails }} {{'MYPROFILE.details'| optimotranslate}}
                            <!--Profile or Customer-->
                        </div>
                        <div class="mmc_flex__item">
                            <hr />
                        </div>
                    </div>

                    <!--Coprate Component start-->
                    <div class="row mb-5 profile-image-row" style="display:none">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="mmc_flex--container user-profile">

                                <div class="mmc_flex__item--inherit mmc_flex__item--vcenter">
                                    <div class="profile-image">
                                        <img alt="profile_image" class="img-circle" height="80" src="../dist/assets/images/user-icon.png" width="80">
                                    </div>
                                </div>
                                <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter ml-5">
                                    <div class="mmc_flex--container mmc_fd--col">
                                        <div class="mmc_flex__item upload-require-message">
                                            <div class="pw-txt-s1">{{'SIGNUP.Upload your photo (maximum file size 3MB, resolution 2*2)'| optimotranslate}}</div>
                                        </div>

                                        <div class="mmc_flex__item">
                                            <div class="d-flex"><span class="pw-txt-s2 link-txt">{{'SIGNUP.Upload Image'| optimotranslate}}</span></div>
                                            <div class="d-flex"><span class="pw-txt-s2 link-txt">{{'SIGNUP.Remove Image'| optimotranslate}}</span></div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>




                    <!--Coprate Component start-->
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'SIGNUP.Username'| optimotranslate}}</mat-label>
                                <input matInput placeholder="James" [(ngModel)]="contact.userName" #userName="ngModel" autocomplete="off" name="userName" (ngModelChange)="valueChange($event,'userName')" [disabled]="true" required>
                                <mat-hint></mat-hint>
                            </mat-form-field>
                        </div>
                    </div>

                    <div *ngIf="showCoparateDetails" class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <mat-form-field appearance="outline">
                                <mat-label>{{this.selectedLanguage.toUpperCase() === 'EN' ?
                                    appData?.ClientRegistrationConfig?.Placeholder?.Corporate?.CompanyName?.Label : ('MYPROFILE.Company Name' |optimotranslate)}}
                                    {{(appData?.ClientRegistrationConfig?.Placeholder?.Corporate?.CompanyName?.IsMandatory == "1")?"*":""}}</mat-label>
                                <input matInput [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Corporate?.CompanyName?.Value   calss="form-control"
                                    [(ngModel)]="contact.client.companyName" name="companyName"
                                    autocomplete="off" (ngModelChange)="valueChange($event)"
                                    [formControl]="copformGroup.get('CompanyName')">
                                <mat-hint></mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'MYPROFILE.Registration No'| optimotranslate}}</mat-label>
                                <input matInput placeholder="" #companyRegistrationNo="ngModel" calss="form-control"
                                       [(ngModel)]="contact.client.companyRegistrationNo" name="companyRegistrationNo"
                                        autocomplete="off" (ngModelChange)="valueChange($event)">
                                <mat-hint></mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="showCoparateDetails" class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <mat-form-field appearance="outline">
                                <mat-label>{{this.selectedLanguage.toUpperCase() === 'EN' ?
                                    appData?.ClientRegistrationConfig?.Placeholder?.Corporate?.CompanyPhoneNumber?.Label :('MYPROFILE.Company Phone' |optimotranslate)}}
                                    {{(appData?.ClientRegistrationConfig?.Placeholder?.Corporate?.CompanyPhoneNumber?.IsMandatory ==
                                    "1") ? "*" : ""}}</mat-label>
                                <input matInput placeholder="{{placeholderSampleNumber}}" [formControl]="copformGroup.get('CompanyPhoneNumber')"
                                    autocomplete="off"  [(ngModel)]="companyPhone.value"  [pattern]="companyPhoneValidation" (input)="valueChange($event.target.value)"                                     autocomplete="off" [pattern]="companyPhoneValidation" (input)="valueChange($event.target.value)" [(ngModel)]="companyPhone.value"
                                    appFormControlValidator>
                                <mat-error style="margin-bottom: 5px"
                                    *ngIf="copformGroup.get('CompanyPhoneNumber').hasError('pattern')">
                                    {{'MYPROFILE.Please enter a valid company phone number'| optimotranslate}}.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <mat-form-field appearance="outline">
                                <mat-label for="CompanyEmail">
                                    {{this.selectedLanguage.toUpperCase() === 'EN' ? appData?.ClientRegistrationConfig?.Placeholder?.Corporate?.CompanyEmail?.Label : ('MYPROFILE.Company Email' | optimotranslate)}}
                                    {{(appData?.ClientRegistrationConfig?.Placeholder?.Corporate?.CompanyEmail?.IsMandatory == "1" )?"*":""}}</mat-label>
                                <input matInput [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Corporate?.CompanyEmail?.Value calss="form-control"
                                       [(ngModel)]="companyEmail.value" name="CompanyEmail" appFormControlValidator 
                                       autocomplete="off" [formControl]="copformGroup.get('CompanyEmail')"
                                       pattern='^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$' (ngModelChange)="valueChange($event)">
                                <mat-error style="margin-bottom: 5px" *ngIf=" copformGroup?.get('CompanyEmail')?.errors?.required">
                                    {{'MYPROFILE.Please enter valid Email Address'| optimotranslate}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                    </div>
                    <!--Coprate Component End-->

                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <div class="row">
                                <div class="col-sm-5 col-md-5 col-lg-3 no-right-padding">
                                    <mat-form-field appearance="outline" floatLabel="always">
                                        <mat-label>{{'MYPROFILE.Title'| optimotranslate}}</mat-label>
                                        <mat-select [(ngModel)]="contact.titleId" placeholder={{contact?.title}} name="title" (ngModelChange)="valueChange($event)">
                                            <mat-option *ngFor="let title of titles" (click)="setTitles(title)" (keydown.enter)="setTitles(title)" (onSelectionChange)="setTitles(title)" Value="title.id">{{title.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-7 col-md-7 col-lg-9 col-xl-9 no-left-padding">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{'MYPROFILE.First Name'| optimotranslate}}&nbsp;*</mat-label>
                                        <input matInput [placeholder]= firstNamePlaceholder 
                                               [(ngModel)]="contact.firstName" name="firstname" calss="form-control"
                                               autocomplete="off" [pattern]="nameValidation" (ngModelChange)="valueChange($event,'firstName')"
                                               [formControl]="copformGroup.get('FirstName')" appFormControlValidator >
                                        <mat-hint></mat-hint>
                                        <mat-error style="margin-bottom: 5px" *ngIf="copformGroup?.get('FirstName')?.errors?.required">{{'MYPROFILE.Please enter valid First Name'| optimotranslate}} .</mat-error>
                                        <mat-error *ngIf="copformGroup?.get('FirstName')?.errors?.pattern">{{'MYPROFILE.Invalid characters'| optimotranslate}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'MYPROFILE.Last Name'| optimotranslate}}&nbsp;*</mat-label>
                                <input matInput [placeholder]=lasttNamePlaceholder   calss="form-control"
                                       [(ngModel)]="contact.lastName" name="lastname" autocomplete="off" [pattern]="nameValidation" (ngModelChange)="valueChange($event,'lastName')"
                                       [formControl]="copformGroup.get('LastName')" appFormControlValidator >
                                <mat-hint></mat-hint>
                                <mat-error style="margin-bottom: 5px" *ngIf="copformGroup?.get('LastName')?.errors?.required">{{'MYPROFILE.Please enter valid Last Name'| optimotranslate}}.</mat-error>
                                <mat-error *ngIf="copformGroup?.get('LastName')?.errors?.pattern">{{'MYPROFILE.Invalid characters'| optimotranslate}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 my-profile-input-field">
                            <div class="row" *ngFor="let com of filterItemsOfType('1'); let i = index">
                                <div class="col-sm-12" *ngIf="com.format=='1'">

                                    <div class="mmc_flex--container cus-container">
                                        <div class="mmc_flex__item w120">
                                            <mat-form-field appearance="outline" floatLabel="always">
                                                <mat-label>{{'MYPROFILE.Phone'| optimotranslate}}</mat-label>
                                                <mat-select [placeholder]="'MYPROFILE.' + com.communicationType | optimotranslate"
                                                            [disabled]="!isNewCommunicationMethod(com.id)" (ngModelChange)="valueChange($event)">
                                                    <mat-option *ngFor="let ph of communicationTypesPhone" Value="ph.id" (click)="setCommunicationMethod(com,ph)" (keydown.enter)="setCommunicationMethod(com,ph)" (onSelectionChange)="setCommunicationMethod(com,ph)">
                                                        {{ 'MYPROFILE.' + ph.name | optimotranslate }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="mmc_flex__item customer-data">
                                            <mat-form-field appearance="outline">
                                                <mat-label>{{ 'MYPROFILE.' + com.communicationType | optimotranslate }}</mat-label>
                                                <input matInput placeholder="{{placholderSampleNumber}}" #PhoneNo
                                                       name="contactPhone{{i}}" [(ngModel)]="com.value"
                                                       calss="form-control" autocomplete="off" required
                                                       [pattern]="com.pattern" (ngModelChange)="valueChange($event,com)">
                                                <mat-error style="margin-bottom: 5px" *ngIf="!com.isValid || true">{{'MYPROFILE.Please enter valid'| optimotranslate}} {{com.communicationType | lowercase}} {{'MYPROFILE.number'| optimotranslate}}.</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="mmc_flex__item--inherit mmc_flex__item--vcenter customer-btn">
                                            <button class="add-more-btn" mat-button tabindex="0" (click)="addPhone()" (keydown.enter)="addPhone()" *ngIf="i==0">
                                                <i class="material-icons">add</i>
                                            </button>
                                            <button class="add-more-btn" mat-button tabindex="0" (click)="removeCommunication(com)" (keydown.enter)="removeCommunication(com)" *ngIf="i!=0">
                                                <i class="material-icons">remove</i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 my-profile-input-field">
                            <div class="row" *ngFor="let com of filterItemsOfType('2'); let j = index;">
                                <div class="col-sm-12" *ngIf="com.format=='2'">
                                    <div class="mmc_flex--container cus-container">
                                        <div class="mmc_flex__item w120">
                                            <mat-form-field appearance="outline" floatLabel="always">
                                                <mat-label>{{'MYPROFILE.Email'| optimotranslate}}</mat-label>
                                                <mat-select [placeholder]="'MYPROFILE.' + com.communicationType | optimotranslate"
                                                            name="communicationtype_email"
                                                            [disabled]="!isNewCommunicationMethod(com.id)" (ngModelChange)="valueChange($event)">
                                                    <mat-option *ngFor="let email of communicationTypesEmail"
                                                                Value="email.id" (click)="setCommunicationMethod(com,email)" (keydown.enter)="setCommunicationMethod(com,email)" (onSelectionChange)="setCommunicationMethod(com,email)">
                                                                {{ 'MYPROFILE.' + email.name | optimotranslate }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="mmc_flex__item customer-data">
                                            <mat-form-field appearance="outline">
                                                <mat-label>{{ 'MYPROFILE.' + com.communicationType | optimotranslate }}</mat-label>
                                                <input id="contactEmail{{j}}" name="contactEmail{{j}}" matInput
                                                       [placeholder]="'MYPROFILE.' + com.communicationType | optimotranslate" calss="form-control"
                                                       [(ngModel)]="com.value" autocomplete="off" required
                                                       pattern='^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$' (ngModelChange)="valueChange($event,com)">
                                                <mat-error style="margin-bottom: 5px" *ngIf="!com.isValid|| true"> {{'MYPROFILE.Please enter valid Email Address'| optimotranslate}}.</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="mmc_flex__item--inherit mmc_flex__item--vcenter customer-btn">
                                            <div class="mmc_flex__item--inherit add-more">
                                                <button class="add-more-btn" mat-button tabindex="0" (click)="addEmail()" (keydown.enter)="addEmail()"  *ngIf="j==0">
                                                    <i class="material-icons">add</i>
                                                </button>
                                                <button class="add-more-btn" mat-button tabindex="0" (click)="removeCommunication(com)" (keydown.enter)="removeCommunication(com)" *ngIf="j!=0">
                                                    <i class="material-icons">remove</i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>



                    <!--Address-->
                    <div class="mmc_flex--container sub-section-title">
                        <div class="mmc_flex__item--inherit font__semibold">
                            {{'MYPROFILE.Address'| optimotranslate}}
                        </div>
                        <div class="mmc_flex__item">
                            <hr />
                        </div>
                    </div>
                    <div class="row" *ngIf="showAddressSearchBar">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <mat-form-field appearance="outline" class="address-lookup">
                                <mat-label>{{'MYPROFILE.Address'| optimotranslate}} {{showIndividualDetails && appData.ClientRegistrationConfig.EnableManualAddress ? "" : "*"}}</mat-label>
                                <span matPrefix><i class="material-icons">person_pin_circle &nbsp;</i></span>
                                <input autocomplete="off" matInput placeholder="Start typing your address"
                                       aria-label="Number" name="addressSearchText" [ngModel]="inputSearchString" (ngModelChange)="findAddress($event)" [matAutocomplete]="auto">
                                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                    <mat-option *ngFor="let address of addressList.AddressDetails" [value]="address.Address" (click)="getFormattedAddress(address.Address)" (keydown.enter)="getFormattedAddress(address.Address)" (onSelectionChange)="getFormattedAddress(address.Address)">
                                        {{address.Address}}
                                    </mat-option>
                                    <mat-option *ngIf="isNoResultFound">{{'MYPROFILE.No results found'| optimotranslate}}</mat-option>
                                </mat-autocomplete>
                                <span class="address-loader ie9up" matSuffix *ngIf="qasLoader">
                                    <mat-spinner [strokeWidth]="5" [diameter]="50"></mat-spinner>
                                </span>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row" *ngIf="contact.invoiceAddress">
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <mat-form-field appearance="outline">

                                <mat-label>{{'MYPROFILE.Address Line'| optimotranslate}} 1{{(appData?.ClientRegistrationConfig?.Placeholder?.Address?.AddressLine1?.IsMandatory == "1" && IsEnableManualAddress )?"*":""}}</mat-label>
                                <input matInput maxlength="100" [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Address?.AddressLine1?.Value name="address1"
                                      [(ngModel)]="contact.invoiceAddress && contact.invoiceAddress.address1"
                                        (ngModelChange)="valueChange($event)"
                                        [disabled]="!IsEnableManualAddress"
                                        [formControl]="formGroup.get('AddressLine1')">
                                <mat-hint></mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'MYPROFILE.Address Line'| optimotranslate}} 2{{(appData?.ClientRegistrationConfig?.Placeholder?.Address?.AddressLine2?.IsMandatory == "1" && IsEnableManualAddress )?"*":""}}</mat-label>
                                <input matInput maxlength="100" [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Address?.AddressLine2?.Value  name="address2"
                                       [(ngModel)]="contact.invoiceAddress && contact.invoiceAddress.address2" [disabled]="!IsEnableManualAddress" (ngModelChange)="valueChange($event)"
                                       [formControl]="formGroup.get('AddressLine2')">
                                <mat-hint></mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row" *ngIf="contact.invoiceAddress">
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'MYPROFILE.Address Line'| optimotranslate}} 3{{(appData?.ClientRegistrationConfig?.Placeholder?.Address?.AddressLine3?.IsMandatory == "1" && IsEnableManualAddress )?"*":""}}</mat-label>
                                <input matInput maxlength="100" [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Address?.AddressLine3?.Value  name="address3"
                                       [(ngModel)]="contact.invoiceAddress.address3" [disabled]="!IsEnableManualAddress" (ngModelChange)="valueChange($event)"
                                       [formControl]="formGroup.get('AddressLine3')">
                                <mat-hint></mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'MYPROFILE.City/Town'| optimotranslate}}{{(appData?.ClientRegistrationConfig?.Placeholder?.Address?.City?.IsMandatory == "1" && IsEnableManualAddress )?"*":""}}</mat-label>
                                <input matInput maxlength="100" [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Address?.City?.Value name="city" [(ngModel)]="contact?.invoiceAddress.city"
                                       [disabled]="!IsEnableManualAddress" (ngModelChange)="valueChange($event)"
                                       [formControl]="formGroup.get('City')">
                                <mat-hint></mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row" *ngIf="contact.invoiceAddress">
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <mat-form-field appearance="outline">
                                <mat-label class="profile-before" >{{'MYPROFILE.State'| optimotranslate}}{{(appData?.ClientRegistrationConfig?.Placeholder?.Address?.State?.IsMandatory == "1" && IsEnableManualAddress )?"*":""}}</mat-label>
                                <input matInput maxlength="10" [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Address?.State?.Value name="stateCode"
                                       [(ngModel)]="contact.invoiceAddress.county" [disabled]="!IsEnableManualAddress" (ngModelChange)="valueChange($event)"
                                       [formControl]="formGroup.get('State')">
                                <mat-hint></mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'MYPROFILE.Postal Code'| optimotranslate}}{{(appData?.ClientRegistrationConfig?.Placeholder?.Address?.PostCode?.IsMandatory == "1" && IsEnableManualAddress )?"*":""}}</mat-label>
                                <input matInput maxlength="50" [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Address?.PostCode?.Value name="postCode"
                                       [(ngModel)]="contact.invoiceAddress.postCode" [disabled]="!IsEnableManualAddress" (ngModelChange)="valueChange($event)"
                                       [formControl]="formGroup.get('PostCode')">
                                <mat-hint></mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row" *ngIf="contact.invoiceAddress && !showAddressSearchBar">
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <mat-form-field appearance="outline">
                            <mat-label>{{'MYPROFILE.Country'| optimotranslate}}{{ (appData?.ClientRegistrationConfig?.Placeholder?.Address?.Country?.IsMandatory == "1" && IsEnableManualAddress )?"*":""}}</mat-label>
                            <input type="text" aria-label="Number" matInput [formControl]="countrySearch" [matAutocomplete]="auto">
                            <mat-icon matSuffix (click)="handleMatAutoComplete()">keyboard_arrow_down</mat-icon>
                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                              <mat-option *ngFor="let option of filteredCountries | async" [value]="option" (click)="SelectCountry(option.id)"
                              (keydown.enter)="SelectCountry(option.id)" (onSelectionChange)="SelectCountry(option.id)">
                              <div style="display: flex;">
                                <span [innerHTML]="option?.name"></span>
                              </div>
                                <span></span>
                              </mat-option>
                            </mat-autocomplete>
                          </mat-form-field>
                        </div>
                    </div>
                    <div class="row" *ngIf="contact.invoiceAddress && showAddressSearchBar">
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'MYPROFILE.Country'| optimotranslate}}{{ (appData?.ClientRegistrationConfig?.Placeholder?.Address?.Country?.IsMandatory == "1" && IsEnableManualAddress )?"*":""}}</mat-label>
                                <input matInput maxlength="100" [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Address?.Country?.Value name="country"
                                       [(ngModel)]="contact.invoiceAddress.country" [disabled]="!IsEnableManualAddress" (ngModelChange)="valueChange($event)"
                                       [formControl]="formGroup.get('Country')">
                                <mat-hint></mat-hint>
                            </mat-form-field>
                        </div>
                    </div>



                    <!--Reqular Booking access Request-->
                    <section *ngIf="requestToAccessReqularBookingHeaderText && enableWebPortalAccess">
                        <div class="mmc_flex--container sub-section-title">
                            <div class="mmc_flex__item--inherit font__semibold">
                                {{ this.selectedLanguage.toUpperCase() === 'EN' ? requestToAccessReqularBookingHeaderText : ('SIGNUP.Regular Booking Access' | optimotranslate) }}
                            </div>
                            <div class="mmc_flex__item">
                                <hr />
                            </div>
                        </div>

                        <div>
                            <div class="row" *ngIf="clientAccessGrantBookingTypes==0">
                                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div class="pw-txt-b1">
                                        <input type="checkbox" [(ngModel)]="requestToRegularBookingAccess" name="requestToRegularBookingAccess" aria-label="checkbox for request regular booking access"/> <span class="pl-2">{{ this.selectedLanguage.toUpperCase() === 'EN' ? requestToAccessReqularBookingAcceptText : ('SIGNUP.I would like to have access to regular booking portal' | optimotranslate) }}</span>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div class="individual-additinal1" *ngIf="clientAccessGrantBookingTypes">
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div class="mmc_flex--container  mmc_fd--col">


                                        <div class="mmc_flex__item font__semibold pw-txt-b1 pt-3" *ngIf="clientAccessGrantBookingTypes!=0">
                                            {{ this.selectedLanguage.toUpperCase() === 'EN' ? reqularBookingAccessRequestCompleteText : ('SIGNUP.You already have access to the online regular booking portal' | optimotranslate) }}
                                        </div>
                                        <div class="mmc_flex__item font__semibold pw-txt-b1 pt-3" *ngIf="clientAccessGrantBookingTypes">
                                            <ul>
                                                <li class="mmc_flex__item font__semibold pw-txt-b1 pt-3" *ngFor="let bk of clientAccessGrantBookingTypes; let b = index;">
                                                    <div>{{bk.name}}</div>
                                                </li>
                                            </ul>
                                          
                                        </div>
                                        <div class="mmc_flex__item font__semibold pw-txt-b1 pt-3" *ngIf="clientAccessGrantBookingTypes!=0">
                                            <div class="d-flex"><span class="pw-txt-s2 link-txt" tabindex="0" (click)="newRegularBooking()" (keydown.enter)="newRegularBooking()" >{{'SIGNUP.Click here to start booking online' | optimotranslate}}</span></div>
                                        </div>
                                        <div class="mmc_flex__item--inherit font__semibold pw-txt-h4 pw-txt-bold pt-3"  *ngIf="requestToRegularBookingAccess">
                                            {{ this.selectedLanguage.toUpperCase() === 'EN' ? reqularBookingAccessRequestHeaderText : ('SIGNUP.Request access to create regular booking online' | optimotranslate) }}
                                        </div>
                                        <div class="mmc_flex__item font__semibold pw-txt-b1 text-success pt-3" *ngIf="clientAccessGrantBookingTypes != requestToRegularBookingAccessCount && requestToRegularBookingAccessCount>0 && clientAccessGrantBookingTypes">
                                            {{reqularBookingAccessRequestReviewText}}
                                        </div>
                                        <div class="mmc_flex__item font__semibold pw-txt-b1 pt-3" *ngIf="requestToRegularBookingAccess">
                                            {{ this.selectedLanguage.toUpperCase() === 'EN' ? reqularBookingAccessRequestSubHeaderText : ('SIGNUP.Provide more details on the activities you wish to conduct along with your preferred venue' | optimotranslate) }}
                                        </div>
                                        <div class="mmc_flex__item font__semibold mt-2 pt-3" *ngIf="requestToRegularBookingAccess">
                                            <mat-form-field class="request-access-txtarea">
                                                <textarea matInput placeholder="" autocomplete="off" name="plainTextToRegularBookingAccessNote"
                                                          [(ngModel)]="requestToRegularBookingAccessNote" aria-label="request-access-txtarea"></textarea>
                                            </mat-form-field>
                                        </div>

                                        <div class="mmc_flex__item d-flex mmc_flex__item--hend" *ngIf="requestToRegularBookingAccess">
                                            <button type="button" mat-button class="mmc-btn-primary--small main mmc_flex__item--vcenter" tabindex="0" (click)="updateAdditionalDetails()" (keydown.enter)="updateAdditionalDetails()" [disabled]="!requestToRegularBookingAccessNote">{{'SIGNUP.Request' | optimotranslate}}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>

                    <!--imageupload-->
                    <div class="dp-upload-wrapper upload-profile" *ngIf="enableProfileImageUpload">
                        <!--Uploaded image-->
                        <div class="row profile-image-row">
                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div class="mmc_flex--container user-profile">

                                    <div class="mmc_flex__item--inherit mmc_flex__item--vcenter">
                                        <div class="profile-image" *ngIf="!profileImage && !contact?.client?.profilePicture">
                                            <img alt="profile_image" class="img-circle" height="80" src="../dist/assets/images/user-icon.png" width="80">
                                        </div>
                                        <div class="profile-image" *ngIf="!profileImage && contact?.client?.profilePicture">
                                            <img alt="profile_image" class="img-circle" height="80" src="{{contact?.client?.profilePicture}}" width="80">
                                        </div>
                                        <div class="profile-image" *ngIf="profileImage">
                                            <img alt="profile_image" class="img-circle" height="80" src={{profileImage}} width="80">
                                        </div>
                                    </div>
                                    <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter ml-5" *ngIf="profileImage ||contact?.client?.profilePicture">
                                        <div class="mmc_flex--container mmc_fd--col">
                                            <div class="mmc_flex__item" *ngIf="profileImage ||contact?.client?.profilePicture">
                                                <div class="d-flex"><span class="pw-txt-s2 link-txt" tabindex="0" (click)="removeProfileImage()" (keydown.enter)="removeProfileImage()" >{{'SIGNUP.Remove Image'| optimotranslate}}</span></div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <!--Profile image uploading section-->
                        <div class="document-upload" [ngClass]="{'disable-div' : profileImage || contact?.client?.profilePicture}">
                            <h1 class="pw-txt-h3 upload-doc-heading mmc_txt-align--center wp-mb-20">{{ (showCoparateDetails)?profileImageHeaderTextForCorporateClients:profileImageHeaderTextForIndividualClients}}</h1>
                            <div class="file-upload--card">
                                <input id="profileImageupload" type="file" (change)="onFileChangedForProfileImage($event)" #fileInput style="display:none" #fileInputTagforProfileImage>
                                <file-drop class="file-drop" headertext="" (onFileDrop)="droppedForProfileImage($event)" showBrowseBtn="false" >
                                    <div class="mmc_flex__item--vcenter" tabindex="0" onclick="$('#profileImageupload').trigger('click'); return false;">
                                        <div class="mmc_flex--container">
                                            <div class="mmc_flex__item--vcenter upload-icon mcc-file-upload">
                                                <i class="material-icons pw-ico-color">insert_drive_file</i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mmc_flex__item--vcenter doc-up-text--mobile d-mobile" *ngIf="!profileImage &&  !contact?.client?.profilePicture">
                                        <span class="link-primary" tabindex="0" onclick="$('#profileImageupload').trigger('click'); return false;" role="link">{{'MYPROFILE.Select a file from your device'| optimotranslate}}</span>
                                    </div>
                                    <div class="mmc_flex__item--vcenter doc-up-text--desktop h-mobile">
                                        <span class="pw-txt-b1 pr-2 pw-text-color__tertiary font-family" *ngIf="!profileImage && !contact?.client?.profilePicture">{{'MYPROFILE.Drag your files here or'| optimotranslate}}</span>
                                        <span class="link-primary font-family" role="link" tabindex="0" onclick="$('#profileImageupload').trigger('click'); return false;" *ngIf="!profileImage && !contact?.client?.profilePicture" role="link">{{'MYPROFILE.Select a file from your device'| optimotranslate}}</span>
                                    </div>
                                </file-drop>
                            </div>
                        </div>
                    </div>

                    <!--Additional Details-->

                    <div *ngIf="(showCoparateDetails && IsEnableToUpdateAdditionalDetailsSectionForCorporateClients) || ((!showCoparateDetails && IsEnableToUpdateAdditionalDetailsSectionForIndividualClients))">
                        <div class="mmc_flex--container sub-section-title">
                            <div class="mmc_flex__item--inherit font__semibold">
                                {{'MYPROFILE.Additional Detail'| optimotranslate}}
                            </div>
                            <div class="mmc_flex__item">
                                <hr />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div class="past-booking-details booking-document-upload gray-bg" id="targetFileBtn">
                                    <div class="container" *ngIf="priceConcessionQuestionText && additionalDetail.note.plainText && showAdditionalNote">
                                        <div class="pw-txt-b1 upload-doc-heading">{{ this.selectedLanguage.toUpperCase() === 'EN' ? PriceConcessionQuestionText : ('SIGNUP.Please select what category best describes your organisation (Note: Incorporated Organisations must hold a Certificate of Incorporation. Not for profit Organisations must provide proof of not for profit status.)*' | optimotranslate) }}</div>
                                        <mat-form-field appearance="outline">
                                            <!-- <mat-label>{{priceConcessionQuestionText}}</mat-label> -->
                                            <!--<input matInput placeholder="Your text Here" autocomplete="off" name="plainText" [(ngModel)]="additionalDetail.note.plainText" (ngModelChange)="additionDetailsChange()">-->
                                            <textarea disabled matInput placeholder="Your text Here" autocomplete="off" name="plainText"
                                                      [(ngModel)]="additionalDetailNote" (ngModelChange)="additionDetailsChange()"></textarea>
                                            <mat-hint></mat-hint>
                                        </mat-form-field>

                                    </div>

                                    <div class="container" *ngIf="(showCoparateDetails && IsEnableToUpdateAdditionalDocumentSectionForCorporateClients) || ((!showCoparateDetails && IsEnableToUpdateAdditionalDocumentSectionForIndividualClients))">
                                        <h1 class="pw-txt-b1 upload-doc-heading" [innerHtml]="ClientFileUploadDescription"><span class="pw-text-color__quinary">{{'SIGNUP.Upload'| optimotranslate}} </span></h1>
                                        <div class="file-upload--card">
                                            <input id="fileupload" type="file" (change)="onFileChanged($event)" #fileInput style="display:none" #fileInputTag>
                                            <file-drop class="file-drop" headertext="" (onFileDrop)="dropped($event)" showBrowseBtn="true" browseBtnLabel="Select a file from your computer">
                                                <div class="mmc_flex__item--vcenter" tabindex="0" onclick="$('#fileupload').trigger('click'); return false;">
                                                    <div class="mmc_flex--container">
                                                        <div class="mmc_flex__item--vcenter upload-icon mcc-file-upload">
                                                            <i class="material-icons pw-ico-color">insert_drive_file</i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mmc_flex__item--vcenter doc-up-text--mobile d-mobile">
                                                    <span class="link-primary" role="link" tabindex="0" onclick="$('#fileupload').trigger('click'); return false;">{{'MYPROFILE.Select a file from your device'| optimotranslate}}</span>
                                                </div>
                                                <div class="mmc_flex__item--vcenter doc-up-text--desktop h-mobile">
                                                    <span class="pw-txt-b1 pr-2 pw-text-color__tertiary font-family">{{'MYPROFILE.Drag your files here or'| optimotranslate}}</span>
                                                    <span class="link-primary font-family" role="link" tabindex="0" onclick="$('#fileupload').trigger('click'); return false;" role="link">{{'MYPROFILE.Select a file from your device'| optimotranslate}}</span>
                                                </div>
                                            </file-drop>
                                        </div>
                                        <div class="uploaded-file-list--card" *ngIf="((clientDocuments && clientDocuments.length>0) || additionalDetail.documents.length>0) ">
                                            <div *ngFor="let document of clientDocuments">
                                                <div class="file-list-row" *ngIf="!document.isDelete">
                                                    <div class="mmc_flex--container">
                                                        <div class="mmc_flex__item--inherit icon-col">
                                                            <span class="material-icons">file_copy</span>
                                                        </div>
                                                        <div class="mmc_flex__item label-col">
                                                            <div class="mmc_flex--container mmc_fd--col">
                                                                <span class="font__normal font-family">{{document?.name}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="mmc_flex__item--inherit download-col">
                                                            <a download="{{downloadDocument(document?.name,document?.documentFileURL)}}" href="{{document?.documentFileURL}}" *ngIf="document?.documentFileURL" target="_blank">
                                                                <span class="material-icons font__gray">cloud_download</span>
                                                            </a>
                                                        </div>
                                                        <div class="mmc_flex__item--inherit download-col">
                                                            <a><i class="material-icons font__gray" tabindex="0" (click)="removeAddedFile(document)" (keydown.enter)="removeAddedFile(document)">close</i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="additionalDetail.documents.length>0">
                                                <div class="file-list-row" *ngFor="let item of additionalDetail.documents; let i=index">
                                                    <div class="mmc_flex--container">
                                                        <div class="mmc_flex__item--inherit icon-col">
                                                            <span class="material-icons">file_copy</span>
                                                        </div>
                                                        <div class="mmc_flex__item label-col">
                                                            <div class="mmc_flex--container mmc_fd--col">
                                                                <span class="font__normal font-family">{{ item?.name }}</span>
                                                                <span class="font__small font__gray font-family">{{item?.fileSize}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="mmc_flex__item--inherit download-col">
                                                            <a [href]="get64Stringdata(item)" download="{{item?.name}}">
                                                                <span class="material-icons font__gray">cloud_download</span>
                                                            </a>
                                                        </div>
                                                        <div class="mmc_flex__item--inherit download-col">
                                                            <a><i class="material-icons font__gray" tabindex="0" (click)="removeFile(i)" (keydown.enter)="removeFile(i)">close</i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--<button class="btn-cancel-booking " mat-button><span class="icon-p2-delete pr5"></span><span class="section-subheading bold">Request cancellation</span></button>-->
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>
                    <div class="mmc_flex--container mmc_just-content-end" style="margin-top:10px;">
                        <div class="mmc_flex__item--inherit">
                            <button mat-button class="mmc-btn-primary pw-text-trans__upper mr-3" [disabled]="!isChanged && !isCommTypeChange && !isAdditionalDetailChange && !additionalDetail.documents.length>0 && !additionalDetail.removedDocumentIds.length>0" tabindex="0" (click)="cancel()" (keydown.enter)="cancel()">{{'MYPROFILE.CANCEL'| optimotranslate}}</button>
                            <button mat-button class="mmc-btn-primary pw-text-trans__upper" tabindex="0" (click)="updateCustomer(contact)" (keydown.enter)="updateCustomer(contact)" [disabled]="!isChanged && !isCommTypeChange && !isAdditionalDetailChange && !additionalDetail.documents.length>0 && !additionalDetail.removedDocumentIds.length>0">
                                {{'MYPROFILE.SAVE'| optimotranslate}}
                            </button>
                            <!--<mat-error class="pw-txt-s2" style="margin-top: 8px" *ngIf="!formProfile.valid">
                                Please fill all fields with valid information
                            </mat-error>-->
                        </div>
                    </div>

                    <div class="mmc_flex--container mmc_just-content-end">
                        <div class="mmc_flex__item--inherit">
                            <mat-error class="pw-txt-s2" style="margin-top: 8px" *ngIf="!formProfile.valid">
                                {{'MYPROFILE.Please fill all fields with valid information'| optimotranslate}}
                            </mat-error>
                        </div>
                    </div>

                </div>
            </form>

            <!--<div *ngIf="((loader$|async).isLoading)">
                <opt-mmc-loader></opt-mmc-loader>
            </div>-->
        </div>
    </div>
</div>
</main>
