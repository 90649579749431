<div class="mmc_alert __warning-alert" style="background-color:forestgreen">
    <div class="row">
        <div class="col sm 12 col md 12 col lg 12">
            <div class="">
                <div class="mmc_flex--container inner-wrapper mmc--depth__2">
                    <div class="mmc_flex__item--vcenter done-icon">
                        <mat-icon class="mat-icon material-icons" style="color: white !important;display: flex;align-items: center; font-size: 40px; width: 50px;">check_circle</mat-icon>
                    </div>
                    <div class="mmc_flex__item--vcenter middle-content">
                        <div class="text-content">
                            <div class="font__h5 wider-text" *ngFor="let successMessage of dataArray">{{successMessage}}</div>
                            <div class="font__small" ></div>
                        </div>
                      
                    </div> 
                    <div class="mmc_flex__item--vcenter d-none">
                        <!--<button mat-raised-button (click)="OK()"> Ok </button>-->
                    </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>

