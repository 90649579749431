<ng-container [ngSwitch]="qst?.question?.type" *ngFor="let qst of questions; let k=index">
  <div *ngIf="(!isMandatory|| (isMandatory && qst.question.mandatory)) && (!qst.conditionalQuestion || (qst.conditionalQuestion && qst.canDisplayQuestion))" class="mb-4">
    <opt-multiple-choice-question *ngSwitchCase="'multipleChoiceQuestion'" [bookingQues]="qst"
      [questionNumber]="qst.question.questionNo" [dates]="dates" [answeredQuestionIds]="answeredQuestionIds"
      (QuestionAnswerOutput)="OutputAnswer($event)" (DateSelectedOutput)="DateSelected($event)">
    </opt-multiple-choice-question>
    <opt-single-choice-question *ngSwitchCase="'singleChoiceQuestion'" [bookingQues]="qst"
      [questionNumber]="qst.question.questionNo" [dates]="dates" [answeredQuestionIds]="answeredQuestionIds"
      (QuestionAnswerOutput)="OutputAnswer($event)" (DateSelectedOutput)="DateSelected($event)">
    </opt-single-choice-question>
    <opt-boolean-question *ngSwitchCase="'booleanQuestion'" [bookingQues]="qst" [questionNumber]="qst.question.questionNo"
      [dates]="dates" [answeredQuestionIds]="answeredQuestionIds" (QuestionAnswerOutput)="OutputAnswer($event)"
      (DateSelectedOutput)="DateSelected($event)"></opt-boolean-question>
    <opt-text-question *ngSwitchCase="'textQuestion'" [bookingQues]="qst" [questionNumber]="qst.question.questionNo"
      [dates]="dates" [answeredQuestionIds]="answeredQuestionIds" (QuestionAnswerOutput)="OutputAnswer($event)"
      (DateSelectedOutput)="DateSelected($event)"></opt-text-question>
    <opt-rich-text-question *ngSwitchCase="'richTextQuestion'" [bookingQues]="qst" [questionNumber]="qst.question.questionNo"
      [dates]="dates" [answeredQuestionIds]="answeredQuestionIds" (QuestionAnswerOutput)="OutputAnswer($event)"
      (DateSelectedOutput)="DateSelected($event)"></opt-rich-text-question>
    <opt-date-time-question *ngSwitchCase="'dateAndTimeQuestion'" [bookingQues]="qst"
      [questionNumber]="qst.question.questionNo" [dates]="dates" [answeredQuestionIds]="answeredQuestionIds"
      (QuestionAnswerOutput)="OutputAnswer($event)" (DateSelectedOutput)="DateSelected($event)">
    </opt-date-time-question>
    <opt-file-upload-question *ngSwitchCase="'fileUploadQuestion'" [bookingQues]="qst"
      [questionNumber]="qst.question.questionNo" [dates]="dates" [answeredQuestionIds]="answeredQuestionIds"
      (QuestionAnswerOutput)="OutputAnswer($event)" (DateSelectedOutput)="DateSelected($event)">
    </opt-file-upload-question>
  </div>
</ng-container>
