import { Component, OnInit, ViewChild, OnDestroy, Input, SimpleChanges, OnChanges, ElementRef, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { PriceRangeComponent } from 'src/modules/booking-portal/modals/price-range/price-range.component';
import { MatDialog } from '@angular/material/dialog';
import { FacilitySummary } from 'src/modules/models/asset/facility-summary';
import { SubSink } from 'subsink';
import { CalendarTabViewSelectedDataSlots, TimeSlotSearchParam } from 'src/modules/models/calendar-tab-view';
import { Moment } from 'moment';
import moment from 'moment';
import { CalendarUtil, CalenderBookingSlotStatus, CalenderNavigateTypeEnum, CalenderViewTypeEnum, SlotAvailableTypeEnum } from 'src/modules/regular-booking/wp-calendar/calendar/calendar-util';
import { DaySegmentView, DayView, HourSegmentView, HourView, MonthView, WeekView } from 'src/modules/regular-booking/wp-calendar/calendar/week-view';
import { Item } from 'src/modules/models/item/item';
import { Utility } from 'src/modules/utility';
import { AssetService } from 'src/modules/services/asset.service';
import { FacilityAdaptor } from 'src/modules/models/asset/facility-adaptor';
import { CalenderTimeslot, Timeslot } from 'src/modules/models/asset/timeslots';
import { environment } from 'src/environments/environment';
import { Configuration } from 'src/modules/models/asset/configuration';
import { ItemPriceGroup } from 'src/modules/models/item/item-price-group';
import { Facility } from 'src/modules/models/asset/facility';
import { assign } from 'lodash';
import { Router } from '@angular/router';
import { DateFormat } from 'src/modules/date-format';
import { AppSetting } from 'src/modules/models/settings/casual-portal/app-setting';
const FacilityIndex: number = -1;
@Component({
  selector: 'app-calendar-tab-view',
  templateUrl: './calendar-tab-view.component.html',
  styleUrls: ['./calendar-tab-view.component.scss']
})
export class CalendarTabViewComponent implements OnInit, OnChanges, OnDestroy {

  constructor(
    public dialog: MatDialog,
    private _assetService: AssetService,
    private router: Router,
    private cdr: ChangeDetectorRef, 
  ) {}

  private subs = new SubSink();
  get calenderViewTypeEnum() { return CalenderViewTypeEnum }
  get calenderNavigateTypeEnum() { return CalenderNavigateTypeEnum }


  @Input() facilities: FacilitySummary[];
  @Input() bookingDate: Moment;
  @Input() minimumBookingDate: Moment;
  @Input() bookingPopUpClosedFacility: FacilitySummary;
  @Input() cartAddedFacilities: FacilitySummary[];
  @Output() selectedBookingData = new EventEmitter<FacilitySummary>();
  @Output() selectTimeLineColor = new EventEmitter<any[]>();
  @Output() removeSelectedFacility = new EventEmitter<FacilitySummary>();
  @Output() faciltySearch = new EventEmitter<Moment>();

  hours: HourView[];
  mobileStart: number = 0;
  mobileEnd: number = 5;
  weekDays: DayView[];
  initialWeekDays: DayView[];
  monthDays: any[];
  weekStateDate: Date;
  weekEndDate: Date;
  // bookingDate: Moment;
  selectedCalenderViewType: string = 'Day';
  timeLineItems;
  today: Date;
  drwaingcolour: string;
  calendarViewTypeList: string[] = ["Day", "Week", "Month"];
  // selectedMonthDay: DayView = null;
  selectedFacilityId: string = '';
  searchableTime: { startTime: string, endTime: string } = { startTime: '', endTime: '' };
  selectedSlots: CalendarTabViewSelectedDataSlots[] = [];
  previousSelectedSlotSegment: HourSegmentView | DaySegmentView;
  isDraggable: boolean = false;
  isClickable: boolean = true;
  isShowPopUp: boolean = false;
  // isMouseDown: boolean = false;
  selectedBookingSlots: Object = {};
  isConfigurationEnabled: boolean;
  startSlotSegment: HourSegmentView | DaySegmentView;
  selectedMonthSlots: { nodeId: string, selectedMonthDay: DayView }[] = [];

  //object to capture for mobile drwing
  pressed: any = {};

  mobilehours: HourView[];
  mobilehourschanged: HourView[];
  selectedFaciltyID: number = FacilityIndex;
  selectedDayID: number = -1;
  previousPanEvent: string;
  changed: boolean = false;
  counter : number = 0;
  appData: AppSetting;

  ngOnInit(): void {
    this.initialization();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.bookingDate && changes.bookingDate.currentValue && changes && changes.facilities && changes.facilities.currentValue) {
      this.bookingDate = changes.bookingDate.currentValue;
      this.today = this.bookingDate.toDate();
      this.facilities = changes.facilities.currentValue;
      this.populateTimeLineData();
    } else if(changes && changes.bookingDate && changes.bookingDate.currentValue) {
      this.bookingDate = changes.bookingDate.currentValue;
      this.today = this.bookingDate.toDate();
      this.populateTimeLineData();
    } else if (changes && changes.facilities && changes.facilities.currentValue) {
      this.facilities = changes.facilities.currentValue;
      this.populateTimeLineData();
    } else {

    }
    if (changes && changes.bookingPopUpClosedFacility && changes.bookingPopUpClosedFacility.currentValue) {
      this.bookingPopUpClosedFacility = changes.bookingPopUpClosedFacility.currentValue;
      if (this.selectedCalenderViewType === CalenderViewTypeEnum.MonthView) {
        this.removeSelectedMonth(this.bookingPopUpClosedFacility)
      } else {
        this.removeSelectedSlots(this.bookingPopUpClosedFacility)
      }

    }
    if (changes && changes.cartAddedFacilities && changes.cartAddedFacilities.currentValue) {
      if (changes.cartAddedFacilities.currentValue.length) {
        const cartAddedFacilities: FacilitySummary[] = changes.cartAddedFacilities.currentValue;
        if (cartAddedFacilities && cartAddedFacilities.length) {
          this.mapCartAddedFacilitiesTimeSlot(cartAddedFacilities);
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private initialization() {
    const currentDate = new Date();
    currentDate.setHours(0);
    currentDate.setMinutes(0);
    currentDate.setSeconds(0);
    currentDate.setMilliseconds(0);

    const leadDays = environment.LeadDaysForBooking ? environment.LeadDaysForBooking : 0;
    if (environment.DisableDatePickerForLeadDays) {
      currentDate.setDate(currentDate.getDate() + leadDays);
    }
    this.minimumBookingDate = moment(currentDate);
    this.selectedCalenderViewType = CalenderViewTypeEnum.DayView;
    this.facilities = [];

    this.appData = environment.AppSetting as AppSetting

    if (this.appData.ShowCalendarView && this.appData.ShowCalendarView.isEnable && this.appData.ShowCalendarView.TimeLineColors && this.appData.ShowCalendarView.TimeLineColors.length > 0)
    {
      this.timeLineItems = this.appData.ShowCalendarView.TimeLineColors;
    }

    this.isConfigurationEnabled = environment.ConfigurationSelection && environment.ConfigurationSelection.Enabled ? true : false;
    if (this.facilities && this.facilities.length) {
      this.populateTimeLineData();
    }
  }

  filterTimeLineLegendColors() {
    if (this.timeLineItems && this.timeLineItems.length) {
      switch (this.selectedCalenderViewType) {
        case CalenderViewTypeEnum.DayView:
          const temp: string[] = ['noneoperational', 'bookedColor', 'calendaravailable', 'selected'];
          this.selectTimeLineColor.emit(this.timeLineItems.filter(item => temp.includes(item.Identifier)));
          break;
        case CalenderViewTypeEnum.WeekView:
        case CalenderViewTypeEnum.MonthView:
          const tempLegendCodes: string[] = ['noneoperational', 'bookedColor', 'calendaravailable', 'selected', 'partiallyavailable'];
          this.selectTimeLineColor.emit(this.timeLineItems.filter(item => tempLegendCodes.includes(item.Identifier)));
          break;
        default:
          break;
      }
    }

  }

  openPriceConfigurationPopup(facility: FacilitySummary) {
    const dialogRef = this.dialog.open(PriceRangeComponent, {
      panelClass: ['price-range-modal'],
      width: '50vw',
      height: 'auto',
      data: { facilityId: facility.id, facilities: this.facilities },
    });
    // dialogRef.afterClosed().subscribe(result => {
      // if (result && result.selectedFacility && result.selectedItem) {
        // let selectedFacility: FacilitySummary = result.selectedFacility;
        // const selectedItem: Item = result.selectedItem;
        // const itemDetail:  { maxPF: ItemPriceGroup, minPF: ItemPriceGroup } = FacilityAdaptor.populateItemDetail(selectedItem, selectedItem.itemPriceGroups);
        // selectedFacility = {...selectedFacility, selectedItem: selectedItem, minPrice: itemDetail.minPF, maxPrice: itemDetail.maxPF}
        // const index = this.facilities.findIndex(f => f.id === selectedFacility.id);
        // this.facilities = this.facilities.splice(index, 1, selectedFacility);
      // }
    // });
  }

  changeSelectedView(selectedView: string) {
    this.changed = null;
    this.selectedCalenderViewType = selectedView;
    this.populateTimeLineData();

  }

  calendarDateNavigate(navigationType: number) {
    this.changed = null;
    let viewType;
    let currentDate = new Date();
    const leadDays = environment.LeadDaysForBooking ? environment.LeadDaysForBooking : 0;
    if (environment.DisableDatePickerForLeadDays) {
      currentDate.setDate(currentDate.getDate() + leadDays);
    }
    switch (this.selectedCalenderViewType) {
      case CalenderViewTypeEnum.DayView:
        viewType = 'days';
        break;
      case CalenderViewTypeEnum.WeekView:
        viewType = 'weeks';
        break;
      case CalenderViewTypeEnum.MonthView:
        viewType = 'months';
        break;
      default:
        break;
    }

    if (navigationType === CalenderNavigateTypeEnum.Next) {
      this.bookingDate = moment(this.bookingDate).add(1, viewType);
    }
    else {
      const bookingDateWithoutTime = this.bookingDate.toDate();//.format('YYYY MM DD');
      const minimumBookingDateWithoutTime = this.minimumBookingDate.toDate();//.format('YYYY MM DD');
      //const difference = moment(bookingDateWithoutTime).diff(moment(minimumBookingDateWithoutTime), viewType);
      const difference = Math.floor((Date.UTC(bookingDateWithoutTime.getFullYear(), bookingDateWithoutTime.getMonth(), bookingDateWithoutTime.getDate()) - Date.UTC(minimumBookingDateWithoutTime.getFullYear(), minimumBookingDateWithoutTime.getMonth(), minimumBookingDateWithoutTime.getDate()) ) /(1000 * 60 * 60 * 24));
      if (difference > 0) {
        this.bookingDate = moment(this.bookingDate).add(FacilityIndex, viewType);
      }
      else {
        return;
      }
    }
    this.faciltySearch.emit(this.bookingDate);
    this.populateTimeLineData();
  }

  populateTimeLineData() {
    const bookingDate: Date = this.bookingDate.toDate();
    const weekStartsOn: number = 0;
    const dayStart: number = 0;
    const dayEnd: number = 24;
    let hourSegments: number = 1;
    if (environment.MinimumBookingDuration) {
      hourSegments = Number((60 / environment.MinimumBookingDuration).toFixed());
    }
    const daySegments: number = 6;

    // DayView
    const tempWeekView: WeekView = CalendarUtil.PopulateWeekView(bookingDate, weekStartsOn, dayStart, dayEnd, hourSegments, daySegments);
    const dayView: DayView = tempWeekView.weekDays.find(weekDay => CalendarUtil.isSameDate(weekDay.date, bookingDate));
    this.hours = dayView && dayView.hours ? dayView.hours.filter(hour => CalendarUtil.isSameDate(hour.date, bookingDate)) : [];
    this.mobilehours = this.hours.slice(this.mobileStart, this.mobileEnd);

    // WeekView
    // const weekView: WeekView = CalendarUtil.PopulateWeekView(bookingDate, weekStartsOn, dayStart, dayEnd, hourSegments, daySegments);
    this.initialWeekDays = tempWeekView && tempWeekView.weekDays ? tempWeekView.weekDays : [];
    this.weekDays = tempWeekView && tempWeekView.weekDays ? tempWeekView.weekDays : [];
    this.weekDays.forEach(weekDay => {
      if (weekDay.daySegments && weekDay.daySegments.length) {
        weekDay.daySegments.forEach(daySegment => {
          daySegment.hours = weekDay.hours.splice(0, 24 / daySegments);
        });
      }
    });
    this.weekStateDate = this.weekDays.length ? [...this.weekDays].shift().date : null;
    this.weekEndDate = this.weekDays.length ? this.weekDays[this.weekDays.length - 1].date : null;
    // MonthView
    const monthView = CalendarUtil.PopulateMonthView(bookingDate, dayStart, dayEnd, hourSegments, 1);
    this.monthDays = monthView && monthView.monthDays ? monthView.monthDays : [];
    // this.selectedMonthDay = null;
    this.selectedFacilityId = '';
    this.selectedSlots = [];
    this.filterTimeLineLegendColors();
    this.getFacilitySearchableTime();
    let fromDate = null;
    switch (this.selectedCalenderViewType) {
      case CalenderViewTypeEnum.DayView:
        this.loadFacilityItemsAndTimeSlotData(this.facilities, this.bookingDate.toDate(), this.bookingDate.toDate());
        break;
      case CalenderViewTypeEnum.WeekView:
        this.minimumBookingDate.diff(moment(this.weekStateDate)) <= 0 ? fromDate = this.weekStateDate : fromDate = this.minimumBookingDate.toDate();
        this.loadFacilityItemsAndTimeSlotData(this.facilities, fromDate, this.weekEndDate);
        break;
      case CalenderViewTypeEnum.MonthView:
        if (this.monthDays && this.monthDays.length) {
          const isSameMonth = moment(this.minimumBookingDate.format('YYYY MM DD')).diff(moment(this.bookingDate.format('YYYY MM DD')), 'months', true) === 0;
          isSameMonth ? fromDate = this.bookingDate.toDate() : fromDate = this.monthDays[0].date;
          const monthLastDate: DayView = this.monthDays[this.monthDays.length - 1];
          this.loadFacilityItemsAndTimeSlotData(this.facilities, fromDate, monthLastDate.date);
        }
        break;
      default:
        break;
    }
  }

  loadFacilityItemsAndTimeSlotData(facilities: FacilitySummary[], searchFromDate: Date, searchToDate: Date) {
    if (facilities && facilities.length) {
      const assetIds = facilities.map(facility => facility.id).toString();
      const fromDate = new Date(searchFromDate.getTime());
      fromDate.setHours(0, 0, 0);
      // const timeNow = new Date();
      // fromDate.setHours(timeNow.getHours(), timeNow.getMinutes(), 0)
      const toDate = new Date(searchToDate.getTime());
      toDate.setHours(23, 59, 0);

      const timeSlotSearchParam: TimeSlotSearchParam = {
        assetIds,
        fromDate: Utility.convertToISO(Utility.convertISOToDate(fromDate)),
        toDate: Utility.convertToISO(Utility.convertISOToDate(toDate))
      };
      this.subs.sink = this._assetService.timeslotSearch(timeSlotSearchParam.assetIds, timeSlotSearchParam.fromDate, timeSlotSearchParam.toDate)
        .subscribe((timeSlots: Timeslot[]) => {
          facilities.forEach(element => {
            element.calenderTimeslots = [],
              element.availableTimeslots = []
          });
          if (timeSlots && timeSlots.length) {
            this.mapFacilityTimeSlot(facilities, timeSlots);
          } else {
            switch (this.selectedCalenderViewType) {
              case CalenderViewTypeEnum.DayView:
                let _date = Utility.convertISOToDate(searchFromDate);
                FacilityAdaptor.populateAvailableTimeslots(facilities, _date, true);
                break;
              case CalenderViewTypeEnum.WeekView:
                this.weekDays.forEach(weekDay => {
                  let _date = Utility.convertISOToDate(weekDay.date);
                  FacilityAdaptor.populateAvailableTimeslots(facilities, _date, true);
                });
              break;
              case CalenderViewTypeEnum.MonthView:
                this.monthDays.forEach((monthDay :DayView )=> {
                  let _date = Utility.convertISOToDate(monthDay.date);
                  FacilityAdaptor.populateAvailableTimeslots(facilities, _date, true);
                });
              break;

              default:
                break;
            }
          }
        }, err => {
        });
    }
  }

  mapFacilityTimeSlot(facilities: FacilitySummary[], timeSlots: Timeslot[]) {
    var test: { facilityId: string, slotData: { searchDate: string, timeSlots: Timeslot[] } }[] = [];
    var facilityWiseGroupedTimeSlots: Object = timeSlots.reduce((r, a) => {
      const facilityId = a.asset.id
      r[facilityId] = r[facilityId] || [];
      r[facilityId].push(a);
      return r;
    }, Object.create(null));

    facilities.forEach(facility => {
      if (!facilityWiseGroupedTimeSlots[facility.id]) {
        facilityWiseGroupedTimeSlots[facility.id] = []
      }
    });

    for (const key in facilityWiseGroupedTimeSlots) {
      if (Object.prototype.hasOwnProperty.call(facilityWiseGroupedTimeSlots, key)) {
        const element = facilityWiseGroupedTimeSlots[key];
        var dayWiseGroupedTimeSlots: { searchDate: string, timeSlots: Timeslot[] } = element.reduce((r, a, index) => {
          // const slotDate = `${a.startTime.getFullYear()}-${a.startTime.getMonth() + 1}-${a.startTime.getDate()}`
          // r[slotDate] = r[slotDate] || [];
          // r[slotDate].push(a);

          // if end and start are  different two slots
          // if(a.startTime.toDateString() != a.endTime.toDateString()){
          let currentDate = new Date(a.startTime) ;//Object.assign({}, a.startTime);

          while (new Date(moment(currentDate).format('DD MMM YYYY')) <= new Date(moment(a.endTime).format('DD MMM YYYY'))) {
            if(this.selectedCalenderViewType === CalenderViewTypeEnum.DayView && currentDate.toDateString() == this.bookingDate.toDate().toDateString() ){
              const slotDate2 = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
              r[slotDate2] = r[slotDate2] || [];
              let b = Object.assign({},a);
              r[slotDate2].push(b);
            }
            else if (this.selectedCalenderViewType != CalenderViewTypeEnum.DayView){
              const slotDate2 = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
              r[slotDate2] = r[slotDate2] || [];
              let b = Object.assign({},a);
              r[slotDate2].push(b);
            }
            currentDate.setDate(currentDate.getDate() + 1);
            console.log(currentDate <= a.endTime , currentDate , a.endTime);
            // }
            
          }

          return r;
        }, Object.create(null));

        if (this.selectedCalenderViewType === CalenderViewTypeEnum.WeekView) {
          const tempWeekDays = this.weekDays.map((day: DayView) => `${day.date.getFullYear()}-${day.date.getMonth() + 1}-${day.date.getDate()}`);
          const resultDays: string[] = Object.keys(dayWiseGroupedTimeSlots)
          const remainingDays: string[] = tempWeekDays.filter(day => !resultDays.includes(day));
          if (remainingDays.length) {
            remainingDays.forEach(day => {
              dayWiseGroupedTimeSlots[day] = [];
            });
          }

        } else if(this.selectedCalenderViewType === CalenderViewTypeEnum.MonthView) {
          const monthStartDateIndex = this.monthDays.findIndex(day => day.date.toDateString() === this.bookingDate.toDate().toDateString());
          let tempMonthDays = this.monthDays.slice(0);
          tempMonthDays = tempMonthDays.map((day: DayView) => `${day.date.getFullYear()}-${day.date.getMonth() + 1}-${day.date.getDate()}`);
          const resultDays: string[] = Object.keys(dayWiseGroupedTimeSlots)
          const remainingDays: string[] = tempMonthDays.filter(day => !resultDays.includes(day));
          if (remainingDays.length) {
            remainingDays.forEach(day => {
              dayWiseGroupedTimeSlots[day] = [];
            });
          }
        } else {
        }
        test.push({ facilityId: key, slotData: dayWiseGroupedTimeSlots })
      }
    }
    console.log(JSON.stringify(test), 'string data')
    this.counter =  0;
    facilities.forEach(facility => {
      this.counter =  this.counter + 1;
      const facilityUnavailableSlots = test.find(a => a.facilityId === facility.id)
      if (facilityUnavailableSlots) {
        const slotData = facilityUnavailableSlots.slotData;
        if (Object.keys(slotData).length === 0) {
            if (this.selectedCalenderViewType === CalenderViewTypeEnum.DayView) {
              let temp = [];
              temp.push(facility)
              const date = this.bookingDate.toDate();
              let _date = Utility.convertISOToDate(date);
              FacilityAdaptor.populateAvailableTimeslots(temp, _date, true);//  TODO
            }
        } else {
          for (const key in slotData) {
            if (Object.prototype.hasOwnProperty.call(slotData, key)) {
              const element = slotData[key];
              const calenderTimeSlots = FacilityAdaptor.populateTimeslot(element, Utility.convertISOToDate(new Date(moment(key, "YYYY-MM-DD").toDate())),null);
              if (calenderTimeSlots && calenderTimeSlots.length) {
                calenderTimeSlots.forEach(calenderTimeSlot => {
                  facility.calenderTimeslots.push(calenderTimeSlot);
                });
              }
              let temp = [];
              temp.push(facility)
              let _date = Utility.convertISOToDate(new Date(moment(key, "YYYY-MM-DD").toDate()));
              FacilityAdaptor.populateAvailableTimeslots(temp, _date, true);
            }
          }
        }

      }
    });
    if(this.counter > 0 ){
      // this.changed = null;
      this.facilities = [];
      this.facilities = [...facilities];
      
      this.cdr.markForCheck();
      this.cdr.detectChanges();
    }
    this.changed = true;
  }

  mapCartAddedFacilitiesTimeSlot(cartAddedFacilities: FacilitySummary[]) {
    this.selectedSlots = [];
    cartAddedFacilities.forEach((cartAddedFacility, index) => {
      const iaSlotAdd = index === 0 ? true : !this.selectedSlots.find(s => s.facilityId === cartAddedFacility.facilityTypeId && s.cartFacilityIndex === cartAddedFacility.index);
      if (iaSlotAdd) {
        if (cartAddedFacility.calenderTimeslots) {
          const selectCalendarTimeSlot = cartAddedFacility.calenderTimeslots.filter(s => s.isSelectedTimeSlot);
          if (selectCalendarTimeSlot && selectCalendarTimeSlot.length) {
            selectCalendarTimeSlot.forEach(slot => {
              // const filteredHours: HourView[] = this.hours.filter(hour => Date.parse(slot.startTime.toISOString()) <= Date.parse(hour.date.toISOString()) && Date.parse(hour.date.toISOString()) <= Date.parse(slot.endTime.toISOString()));
              let slots: HourSegmentView[] = [];
              this.hours.forEach(hour => {
                if (hour.hourSegments && hour.hourSegments.length) {
                  hour.hourSegments.forEach(segment => {
                    var _segment = Object.assign(new HourSegmentView(), segment)
                    if (slot.start <= segment.startMin/ 60 &&  segment.endMin/ 60 <= slot.end) {
                      _segment.date = slot.startTime;
                      slots.push(_segment)
                    }
                  });
                }
                // slots = hour && hour.hourSegments.length ? [...slots, ...hour.hourSegments] : [];
              });
              if (selectCalendarTimeSlot && slots.length) {
                var _slots = slots
                this.selectedSlots.push(
                  {
                    facilityId: cartAddedFacility.id,
                    facility: cartAddedFacility,
                    cartFacilityIndex: cartAddedFacility.index,
                    viewType: this.selectedCalenderViewType,
                    slots: _slots
                  }
                );
                slots = [];
              }
            });
          }
        }
      }
    });

    this.mapSelectedSlotsFacilityId();
  }

  mapSelectedSlotsFacilityId() {
    this.selectedBookingSlots = null;
    this.selectedBookingSlots = this.selectedSlots.reduce((r, a) => {
      if ( Object.keys(r).length && r[a.facilityId]) {
        r[a.facilityId] = [...r[a.facilityId], ...a.slots];
      } else {
        r[a.facilityId] = [];
        r[a.facilityId] = [...a.slots];
      }
      return r;
    }, Object.create(null));
  }

  setDayAvailabilityStyle(facility: FacilitySummary, hourSegment: HourSegmentView) {
    //if prices are defined and
    //if aviable or calander timeslots loaded check the vailble color
    //else show as disabled color as timeslots are not loaded
    if(facility.getItemPrice(facility.selectedItem) && (facility.availableTimeslots.length >0 || facility.calenderTimeslots.length >0)){
      const slot = facility.calenderTimeslots.find((slot: CalenderTimeslot) => slot.start <= (hourSegment.startMin / 60.0) && (hourSegment.endMin / 60.0) <= slot.end);
      if (slot) {
        return this.getSlotBackgroundStyle(slot);
      } else {
        const availableSlot = facility.availableTimeslots.find((slot: CalenderTimeslot) => slot.start <= (hourSegment.startMin / 60.0) && (hourSegment.endMin / 60.0) <= slot.end);
        if(availableSlot){
          if(facility.configuration && (availableSlot.end - availableSlot.start) > (facility.configuration.setupTime + facility.configuration.teardownTime) / 60.0){
            return this.getTimeLineColor('calendaravailable');
          }
          else {
          return this.getTimeLineColor('bookedColor');
          }
        } else {
          return this.getTimeLineColor('bookedColor');
        }
        
      }
    }
    else{
      return "#eae5e5";
    }
  }

  setWeekAvailabilityStyle(facility: FacilitySummary, daySegment: DaySegmentView) {
    let bg_Color = "";
    if( facility.getItemPrice(facility.selectedItem) && (daySegment.date >= this.minimumBookingDate.toDate()) ){//&& (daySegment.date.getDate() >= this.minimumBookingDate.toDate().getDate() || daySegment.date.getMonth() > this.minimumBookingDate.toDate().getMonth() || daySegment.date.getFullYear() > this.minimumBookingDate.toDate().getFullYear())){
    
      let availability = this.checkAvailability(facility,daySegment)

      if(availability == "noneoperational"){
        bg_Color = this.getTimeLineColor('noneoperational')
      }
      else if (availability == "bookedColor"){
        bg_Color = this.getTimeLineColor('bookedColor')
      }
      else if(availability == "calendaravailable"){
        bg_Color = this.getTimeLineColor('calendaravailable')
      }
      else{
        bg_Color = this.getTimeLineColor('partiallyavailable')
      }
      
    }

    else{
      bg_Color = "#eae5e5";
    }

    return bg_Color;
  }

  setMonthAvailabilityStyle(facility: FacilitySummary, monthDay: DayView) {
    const monthDayDatePart = monthDay.date.toDateString();
    const date = monthDay.date.getDate();
    
    //unavailable for more than a day and date is inbetween the starting and ending date
    const morethanaDayUnavailable_SLots = facility.calenderTimeslots.find((slot:CalenderTimeslot) => (slot.endTime.getDate() - slot.startTime.getDate()) > 1 && (slot.endTime.getDate() > date &&  slot.startTime.getDate() < date));
    if( morethanaDayUnavailable_SLots){
      return this.getTimeLineColor('bookedColor');
    }
    
    const filteredCalendarSlots = facility.calenderTimeslots.filter((slot: CalenderTimeslot) => slot.startTime.toDateString() === monthDayDatePart || slot.endTime.toDateString() === monthDayDatePart);
    const filteredAvailableSlots = facility.availableTimeslots.filter((slot: CalenderTimeslot) => slot.startTime.toDateString() === monthDayDatePart);
    const availabilityType = this.getMonthAvailability(filteredAvailableSlots, filteredCalendarSlots, facility.configuration);
    if( facility.getItemPrice(facility.selectedItem) && (monthDay.date >= this.minimumBookingDate.toDate())) // ((monthDay.date.getDate() >= this.minimumBookingDate.toDate().getDate()) || (monthDay.date.getMonth() > this.minimumBookingDate.toDate().getMonth()) || (monthDay.date.getFullYear() > this.minimumBookingDate.toDate().getFullYear())))
    {
      switch (availabilityType) {
        case SlotAvailableTypeEnum.FullyAvailable:
          return this.getTimeLineColor('calendaravailable');
          break;
        case SlotAvailableTypeEnum.PartialAvailable:
          return this.getTimeLineColor('partiallyavailable');
          break;
        case SlotAvailableTypeEnum.Unavailable:
          return this.getTimeLineColor('bookedColor');
          break;
        default:
          break;
      }
    }
    else{
      return "#eae5e5";
    }

  }

  getTimeLineColor(identifier: string): string {
    let colorItem = this.timeLineItems.filter(x => x.Identifier == identifier);
    if (colorItem != undefined && colorItem.length > 0)
      return colorItem[0].Value;
    return null;
  }

  getSlotBackgroundStyle(slot: CalenderTimeslot) {
    if (slot.isSelectedTimeSlot && slot.isBumpInBumpOut) {
      return this.getTimeLineColor('setupteardown');
    } else if (slot.isSelectedTimeSlot) {
      return this.getTimeLineColor('selected');
    } else if (slot.isUnAvailableDueToOperationalHours) {
      return this.getTimeLineColor('noneoperational');
    } else if (!slot.isSelectedTimeSlot && slot.isBumpInBumpOut) {
      return this.getTimeLineColor('bumpunavailable');
    } else {
      return this.getTimeLineColor('bookedColor');
    }
  }

  getSlotStatus(slot: CalenderTimeslot) {
    if (slot.isSelectedTimeSlot && slot.isBumpInBumpOut) {
      return CalenderBookingSlotStatus.SetupTearDown;
    } else if (slot.isSelectedTimeSlot) {
      return CalenderBookingSlotStatus.Selected;
    } else if (slot.isUnAvailableDueToOperationalHours) {
      return CalenderBookingSlotStatus.NonOperational;
    } else if (!slot.isSelectedTimeSlot && slot.isBumpInBumpOut) {
      return CalenderBookingSlotStatus.BumpUnavailable;
    } else {
      return CalenderBookingSlotStatus.BookedColor;
    }
  }

  getMonthAvailability(availableTimeslots: CalenderTimeslot[], calendarTimeSlots: CalenderTimeslot[], configuration: Configuration): number {
    const isCalendarTimeSlotExists = calendarTimeSlots.filter(slot => !slot.isUnAvailableDueToOperationalHours);
    if (!calendarTimeSlots || !(isCalendarTimeSlotExists && isCalendarTimeSlotExists.length))
      return SlotAvailableTypeEnum.FullyAvailable;
    else {
      if (configuration && availableTimeslots && availableTimeslots.length) {
        const isAvailableSlotExists = availableTimeslots.filter(availableSlot => {
          const availableSlotTimeDifference = availableSlot.end - availableSlot.start;
          const configurationTime = (configuration.setupTime + configuration.teardownTime) / 60.0;
          return availableSlotTimeDifference > configurationTime;
        });
        if (isAvailableSlotExists.length) {
          return SlotAvailableTypeEnum.PartialAvailable;
        } else {
          return SlotAvailableTypeEnum.Unavailable;
        }
      } else {
        return SlotAvailableTypeEnum.Unavailable;
      }
    }
  }

  rangeMouseDown(event, facility: FacilitySummary, hour: HourView, hourSegment: HourSegmentView, isMobile:Boolean = false) {
    // this.isMouseDown = true;
    if(facility.getItemPrice(facility.selectedItem))
    {
      let currentFacilityDrawer: CalendarTabViewSelectedDataSlots = null;
      if (this.selectedSlots.length) {
        currentFacilityDrawer = this.selectedSlots.find(s => s.facilityId === facility.id && s.cartFacilityIndex === FacilityIndex);
        if (currentFacilityDrawer && currentFacilityDrawer.slots && currentFacilityDrawer.slots.length) {
          this.rangeMouseUp(null, facility, hourSegment);
          return
        }
      }
      const isExistAvailableSlot = this.checkSlotIsAvailable(facility, hourSegment);
      this.isShowPopUp = isExistAvailableSlot;
      if (isExistAvailableSlot) {
        if (this.selectedSlots.length) {
          const cartAddedSlots = this.selectedSlots.find(s => s.facilityId === facility.id && facility.index === s.facility.index);
          if (cartAddedSlots && cartAddedSlots.slots && cartAddedSlots.slots.length) {
            this.selectedSlots.find(s => s.facilityId === facility.id).slots = [];
          }
        }
        const selectedSlots: CalendarTabViewSelectedDataSlots= {
          cartFacilityIndex: FacilityIndex,
          facility,
          facilityId: facility.id,
          slots: [],
          viewType: this.selectedCalenderViewType
        };

        this.isDraggable = true;
        this.isClickable = false;
        this.startSlotSegment = hourSegment;
        this.previousSelectedSlotSegment = hourSegment;
        this.selectedFacilityId = facility.id;
        selectedSlots.slots = [...selectedSlots.slots, hourSegment];
        if (selectedSlots.slots.length) {
          this.selectedSlots.push(selectedSlots);
          if(isMobile)
          {
            this.pressed[document.elementFromPoint(event.touches[0].clientX, event.touches[0].clientY).id]=hourSegment.startMin;
          }
        }
        this.mapSelectedSlotsFacilityId();
      }
    }

    else{
      return;
    }
  }

  rangeMouseMove(event, facility: FacilitySummary, hour: HourView, hourSegment: HourSegmentView, _startMin?:any, isMobile:Boolean = false) {
    if (this.selectedFacilityId === facility.id && this.isDraggable) {
      let currentFacilityDrawer: CalendarTabViewSelectedDataSlots = null;
      let index: number;
      if (this.selectedSlots.length) {
        index = this.selectedSlots.findIndex(s => s.facilityId === facility.id && s.cartFacilityIndex === FacilityIndex);
        currentFacilityDrawer = index >= 0 ? this.selectedSlots[index] : null;
      }

      const isExistAvailableSlot = this.checkSlotIsAvailable(facility, hourSegment);
      this.isShowPopUp = isExistAvailableSlot;

      if (isExistAvailableSlot && currentFacilityDrawer) {
        if (!(hourSegment.startMin === this.startSlotSegment.startMin && hourSegment.endMin === this.startSlotSegment.endMin)) {
          currentFacilityDrawer.slots = [...currentFacilityDrawer.slots, hourSegment];
        }
        currentFacilityDrawer.slots = currentFacilityDrawer.slots.sort((a, b) => a.startMin - b.startMin);
        currentFacilityDrawer.slots = currentFacilityDrawer.slots.filter((element, index) => {
          return currentFacilityDrawer.slots.indexOf(element) === index;
        });

        let startTimeSlotIndex = currentFacilityDrawer.slots.findIndex(s => s.startMin === this.startSlotSegment.startMin && s.endMin === this.startSlotSegment.endMin);
        let endTimeSlotIndex = currentFacilityDrawer.slots.findIndex(s => s.startMin === hourSegment.startMin && s.endMin === hourSegment.endMin);

        if (startTimeSlotIndex < endTimeSlotIndex) {
          endTimeSlotIndex += endTimeSlotIndex;
          currentFacilityDrawer.slots = currentFacilityDrawer.slots.slice(startTimeSlotIndex, endTimeSlotIndex);
        } else if(startTimeSlotIndex > endTimeSlotIndex) {
          startTimeSlotIndex += startTimeSlotIndex;
          currentFacilityDrawer.slots = currentFacilityDrawer.slots.slice(endTimeSlotIndex, startTimeSlotIndex);
        } else {
          currentFacilityDrawer.slots = currentFacilityDrawer.slots.slice(startTimeSlotIndex, 1);
        }
        let isSequenceBreak: boolean = false;
        if (currentFacilityDrawer.slots && currentFacilityDrawer.slots.length) {
          let previousSlot = null;
          for (const element of currentFacilityDrawer.slots) {
            if (previousSlot && previousSlot.endMin !== element.startMin) {
              this.isDraggable = false;
              this.isClickable = true;
              this.isShowPopUp = false;
              this.selectedFacilityId = null;
              currentFacilityDrawer.slots = [];
              isSequenceBreak = true;
              this.removeSelectedSlots(facility);
              break;
            }
            previousSlot = element;
          }
        }
        if (!isSequenceBreak && currentFacilityDrawer.slots.length) {
          this.selectedSlots.splice(index, 1, currentFacilityDrawer);
        } else {
          this.selectedSlots.splice(index, 1);
          if(isExistAvailableSlot)
            this.rangeMouseDown(event,facility,hour,hourSegment,false); //start as like from the begining
        }

        if(isMobile){
          this.pressed[_startMin]=hourSegment.startMin;
        }
      }
      this.mapSelectedSlotsFacilityId();
    }
  }

  rangeMouseUp(event, facility: FacilitySummary, segment: HourSegmentView | DaySegmentView) {
    // this.isMouseDown = false;
    if (this.selectedSlots.length && this.isShowPopUp) {
      const index = this.selectedSlots.findIndex(s => s.facilityId === facility.id && s.cartFacilityIndex === FacilityIndex);
      const currentFacilityDrawer = index >= 0 ? this.selectedSlots[index]: null;
      // if (currentFacilityDrawer && currentFacilityDrawer.slots && currentFacilityDraw
      if (currentFacilityDrawer && currentFacilityDrawer.slots.length) {
        currentFacilityDrawer.slots = currentFacilityDrawer.slots.sort((a, b) => a.startMin - b.startMin);
        const startTimeSlot: HourSegmentView | DaySegmentView = currentFacilityDrawer.slots[0];
        let startTimeHour = Math.floor(startTimeSlot.startMin / 60);
        let startTimeMinutes = Math.floor(startTimeSlot.startMin % 60);
        const startTime: Date = new Date(startTimeSlot.date);
        startTime.setHours(startTimeHour);
        startTime.setMinutes(startTimeMinutes);        

        let endTime: Date;
        if (currentFacilityDrawer.slots.length == 1 && this.selectedCalenderViewType === CalenderViewTypeEnum.DayView) {
          endTime = moment(startTime).add(environment.MinimumBookingDuration, 'm').toDate();
        } else {
          const endTimeSlot: HourSegmentView | DaySegmentView = currentFacilityDrawer.slots[currentFacilityDrawer.slots.length - 1];
          let endTimeHour = Math.floor(endTimeSlot.endMin / 60);
          let endTimeMinutes = Math.floor(endTimeSlot.endMin % 60);
          
          endTime = new Date(endTimeSlot.date);
          if (endTimeSlot.endMin === 1440) {
            endTime.setHours((endTimeHour - 1), 59);
          } else {
            endTime.setHours(endTimeHour);
            endTime.setMinutes(endTimeMinutes);
          }
        }

        const slot = FacilityAdaptor.populateCalenderTimeslot(startTime, endTime);
        slot.isSelectedTimeSlot = true;

        facility = { ...facility, calenderTimeslots: [...facility.calenderTimeslots, slot] };

        facility.startTime = Utility.convertToISO(startTime);
        facility.endTime = Utility.convertToISO(endTime);
        this.selectedBookingData.emit(facility);
        this.isShowPopUp = false;
        this.isDraggable = false;
      }

    }
  }

  removeSelectedSlots(facility: FacilitySummary, isRemoveSlotFromCart: boolean = false) {
    this.selectedSlots = this.selectedSlots.filter(s => !(s.facilityId === facility.id && s.cartFacilityIndex === FacilityIndex));
    if (isRemoveSlotFromCart) {
      this.removeSelectedFacility.emit(facility);
    }
    this.pressed = {};
    this.mapSelectedSlotsFacilityId();
  }

  checkIsAnotherSlotSelected(facility: FacilitySummary,startMin,endMin,selectedDate){
    let isAnotherSlotSelect = true
    if (this.cartAddedFacilities.length) {

      // all cart added facilitoes for the date
      const cartAddedFacility = this.cartAddedFacilities.filter(f => f.id === facility.id && ((new Date(f.startTime).toDateString() == new Date(selectedDate).toDateString()) || new Date(f.endTime.toString()).toDateString() == new Date(selectedDate).toDateString()));
      
      //all the booked slots
      const allbookedSlot = cartAddedFacility ? [].concat(...cartAddedFacility.map(f => f.calenderTimeslots.filter(c => c.isSelectedTimeSlot) )) : null;
      
      if (allbookedSlot && allbookedSlot.filter(s => s.isSelectedTimeSlot).length >0) {
        const bookedSlot = allbookedSlot.find(ab => (startMin <= ab.start && ab.start < endMin) || //booked start time inside the slot
                                                    (ab.end > startMin && ab.end <= endMin) || // booked slot end time is inside the slot
                                                    (ab.start <= startMin && ab.end >= endMin ))// booked time is out side of slot thats fully booked with starting before and ending aftr thge slot
                                                    
        // isAnotherSlotSelect = bookedSlot.start <= startMin && endMin <= bookedSlot.end ? false : true;
        // isAnotherSlotSelect = bookedSlot.start >= startMin && bookedSlot.start <= endMin ? false : true;
        isAnotherSlotSelect =  bookedSlot ? false : true;
      }
    }

    return isAnotherSlotSelect;
  }

  checkSlotIsAvailable(facility: FacilitySummary, hourSegment: HourSegmentView | DaySegmentView) {
    let availableSlot, selectableSlot = true;
    const startMin = hourSegment.startMin / 60.0;
    const endMin = hourSegment.endMin / 60.0;
    const selectedDate = hourSegment.date.toDateString();

    selectableSlot = this.checkIsAnotherSlotSelected(facility,startMin,endMin,selectedDate);

    switch (this.selectedCalenderViewType) {
      case CalenderViewTypeEnum.DayView:
        availableSlot = facility.availableTimeslots.find((slot: CalenderTimeslot) => slot.start <= startMin && endMin <= slot.end);
        break;
      case CalenderViewTypeEnum.WeekView:
        availableSlot = facility.availableTimeslots.find((slot: CalenderTimeslot) => slot.start <= startMin && endMin <= slot.end && slot.startTime.toDateString() === hourSegment.date.toDateString());
        break;
      default:
        break;
    }
    return availableSlot && selectableSlot ? true : false;
  }

  selectWeekTimeSlotMouseDown(event, facility: FacilitySummary, daySegment: DaySegmentView, isMobile:Boolean = false) {
    const segmentDatePart = daySegment.date.toDateString();
    const startMin = daySegment.startMin / 60;
    const endMin = daySegment.endMin / 60;
    let  setupTeardown = 0;
    if(facility.configuration){
      setupTeardown = facility.configuration.setupTime + facility.configuration.teardownTime;
    }

    if (this.minimumBookingDate.diff(moment(daySegment.date), 'days') > 0) {
      return;
    }
    let currentFacilityDrawer: CalendarTabViewSelectedDataSlots = null;
    if (this.selectedSlots.length) {
      currentFacilityDrawer = this.selectedSlots.find(s => s.facilityId === facility.id && s.cartFacilityIndex === FacilityIndex);
      if (currentFacilityDrawer && currentFacilityDrawer.slots && currentFacilityDrawer.slots.length) {
        this.rangeMouseUp(null, facility, daySegment);
        return
      }
    }
    const selectedSlots: CalendarTabViewSelectedDataSlots= {
      cartFacilityIndex: FacilityIndex,
      facility,
      facilityId: facility.id,
      slots: [],
      viewType: this.selectedCalenderViewType
    };
    
    const slot2 = facility.calenderTimeslots.find((slot: CalenderTimeslot) =>slot.startTime.toDateString() === segmentDatePart);
    const slot = facility.calenderTimeslots.find((slot: CalenderTimeslot) =>
      slot.start <= startMin && endMin <= slot.end && slot.startTime.toDateString() === segmentDatePart);
    if (slot && this.getSlotStatus(slot) === CalenderBookingSlotStatus.BookedColor) {
        return;
    }

    if(!this.checkIsAnotherSlotSelected(facility,startMin,endMin,daySegment.date.toDateString())){
      return
    }

    let availability = this.checkAvailability(facility,daySegment) 
    if (availability == "noneoperational" || availability=="bookedColor"){
      return;
    }
    else{
      selectedSlots.slots = [...selectedSlots.slots, daySegment];
      if (selectedSlots.slots.length) {
        this.selectedSlots.push(selectedSlots);
      }
      
      this.startSlotSegment = daySegment;
      this.selectedFacilityId = facility.id;


      if (availability == "partiallyavailable") {
        this.isDraggable = false;
        this.isShowPopUp = true;
        this.mapSelectedSlotsFacilityId();
        this.rangeMouseUp(null, facility, daySegment);
        return;
      }
      
      else if( availability == "calendaravailable"){
        this.isShowPopUp = true;
        this.isDraggable = true;
        this.mapSelectedSlotsFacilityId();

        if(isMobile){
          this.pressed = {};
          this.pressed[daySegment.startMin] = daySegment.startMin;
        }

        return;
      }
      else{
        return;
      }
    }

  }

  selectWeekTimeSlotMouseMove(event, facility: FacilitySummary, segment: DaySegmentView, _startMin?:any, isMobile:Boolean = false) {
    if (this.selectedFacilityId === facility.id  && this.isDraggable) {
      let currentFacilityDrawer: CalendarTabViewSelectedDataSlots = null;
      let index: number;
      if (this.selectedSlots.length) {
        index = this.selectedSlots.findIndex(s => s.facilityId === facility.id && s.cartFacilityIndex === FacilityIndex);
        currentFacilityDrawer = index >= 0 ? this.selectedSlots[index] : null;
      }
      
      const selectableSlot = this.checkIsAnotherSlotSelected(facility,segment.startMin,segment.endMin,segment.date.toDateString())

      const segmentDatePart = segment.date.toDateString();
      const isExistAvailableSlot = facility.availableTimeslots.find((slot: CalenderTimeslot) => slot.start <= segment.startMin / 60 && segment.endMin / 60 <= slot.end && slot.startTime.toDateString() === segmentDatePart);
      this.isShowPopUp = isExistAvailableSlot ? true : false;
      if (selectableSlot && isExistAvailableSlot && currentFacilityDrawer) {
        if (!(segment.startMin === this.startSlotSegment.startMin && segment.endMin === this.startSlotSegment.endMin)) {
          currentFacilityDrawer.slots = [...currentFacilityDrawer.slots, segment];
        }
        currentFacilityDrawer.slots = currentFacilityDrawer.slots.sort((a, b) => a.startMin - b.startMin);
        currentFacilityDrawer.slots = currentFacilityDrawer.slots.filter((element, index) => {
          return currentFacilityDrawer.slots.indexOf(element) === index;
        });

        let startTimeSlotIndex = currentFacilityDrawer.slots.findIndex(s => s.startMin === this.startSlotSegment.startMin && s.endMin === this.startSlotSegment.endMin);
        let endTimeSlotIndex = currentFacilityDrawer.slots.findIndex(s => s.startMin === segment.startMin && s.endMin === segment.endMin);

        if (startTimeSlotIndex < endTimeSlotIndex) {
          endTimeSlotIndex += endTimeSlotIndex;
          currentFacilityDrawer.slots = currentFacilityDrawer.slots.slice(startTimeSlotIndex, endTimeSlotIndex);
        } else if (startTimeSlotIndex > endTimeSlotIndex) {
          startTimeSlotIndex += startTimeSlotIndex;
          currentFacilityDrawer.slots = currentFacilityDrawer.slots.slice(endTimeSlotIndex, startTimeSlotIndex);
        } else {
          currentFacilityDrawer.slots = currentFacilityDrawer.slots.slice(startTimeSlotIndex, 1);
        }
        let isSequenceBreak: boolean = false;
        if (currentFacilityDrawer.slots && currentFacilityDrawer.slots.length) {
          let previousSlot = null;
          for (const element of currentFacilityDrawer.slots) {
            if (previousSlot && previousSlot.endMin !== element.startMin) {
              this.isDraggable = false;
              this.isClickable = true;
              this.isShowPopUp = false;
              this.selectedFacilityId = null;
              currentFacilityDrawer.slots = [];
              isSequenceBreak = true;
              this.removeSelectedSlots(facility);
              break;
            }
            previousSlot = element;
          }
        }
        if (!isSequenceBreak && currentFacilityDrawer.slots.length) {
          this.selectedSlots.splice(index, 1, currentFacilityDrawer);
        } else {
          this.selectedSlots.splice(index, 1);
          this.selectWeekTimeSlotMouseDown(event,facility,segment,false);
        }

        if(isMobile){
          this.pressed[_startMin] = segment.startMin;
        }
      }
      this.mapSelectedSlotsFacilityId();
    }
  }

  getTime(time:Date){
    // new Date(facility.openingTime).getHours() + new Date(facility.openingTime).getMinutes()/60;
    return time.getHours() + time.getMinutes()/60;
  }

  selectMonthTimeSlot(facility: FacilitySummary, monthDay: DayView) {
    if (this.minimumBookingDate.diff(moment(monthDay.date), 'days') > 0) {
      return;
    }
    const monthDayDatePart = monthDay.date.toDateString();
    
    const selectable = this.checkIsAnotherSlotSelected(facility,this.getTime(new Date(facility.openingTime)),this.getTime(new Date(facility.closingTime)),monthDayDatePart);
    if(!selectable){
      return;
    }

    const filteredCalendarSlots = facility.calenderTimeslots.filter((slot: CalenderTimeslot) => slot.startTime.toDateString() === monthDayDatePart);
    const filteredAvailableSlots = facility.availableTimeslots.filter((slot: CalenderTimeslot) => slot.startTime.toDateString() === monthDayDatePart);
    if (filteredAvailableSlots && filteredAvailableSlots.length) {
      const availabilityType = this.getMonthAvailability(filteredAvailableSlots, filteredCalendarSlots, facility.configuration);
      if (availabilityType !== SlotAvailableTypeEnum.Unavailable) {
        // this.selectedMonthDay = monthDay;
        this.selectedFacilityId = facility.id;
        this.selectedMonthSlots.push({ nodeId: facility.id, selectedMonthDay: monthDay });
        const startTime = new Date(monthDay.date);
        const date = new Date();
        startTime.setHours(date.getHours())
        const endTime = moment(startTime).add(environment.MinimumBookingDuration, 'm').toDate();
        facility.startTime = Utility.convertToISO(startTime);
        facility.endTime = Utility.convertToISO(endTime);
        const slot = FacilityAdaptor.populateCalenderTimeslot(startTime, endTime);
        slot.isSelectedTimeSlot = true;
        facility = { ...facility, calenderTimeslots: [...filteredCalendarSlots, slot], availableTimeslots: [...filteredAvailableSlots] };
        this.selectedBookingData.emit(facility);
      }
    }
  }

  getSelectedMonthSlotsByFacility(facility) {
    return this.selectedMonthSlots.find(s => s.nodeId === facility.id)
  }

  removeSelectedMonth(facility: FacilitySummary, isRemoveSlotFromCart: boolean = false) {
    // this.selectedMonthDay = null;
    this.selectedFacilityId = '';
    this.selectedMonthSlots = this.selectedMonthSlots.filter(s => s.nodeId === facility.id);
    if (isRemoveSlotFromCart) {
      this.removeSelectedFacility.emit(facility);
    }
  }

  getFacilitySearchableTime() {
    const startTime = new Date(this.bookingDate.toDate());
    const date = new Date();
    startTime.setHours(date.getHours())
    const endTime = moment(startTime).add(environment.MinimumBookingDuration, 'm').toDate();
    this.searchableTime = { startTime: Utility.convertToISO(startTime), endTime: Utility.convertToISO(endTime) };
    return this.searchableTime
  }

  //mobile day increase decrese
  mobileDayIncreaseDecrease(event, facility_index: number) {

    if(this.selectedFaciltyID != facility_index){
      this.pressed = {};
      this.mobileStart = ( this.mobileStart != 0) ? 0 : this.mobileStart;
      this.mobileEnd = ( this.mobileEnd != 5) ? 5 : this.mobileEnd;
    }

    this.mobileStart = ((this.mobileStart += event) > this.hours.length - 6) ? this.hours.length - 5 :
      ((this.mobileStart < 0) ? 0 : this.mobileStart);

    this.mobileEnd = ((this.mobileEnd += event) < 5) ? 5 :
      ((this.mobileEnd > this.hours.length - 1) ? this.hours.length : this.mobileEnd);

    this.mobilehourschanged = this.hours.slice(this.mobileStart, this.mobileEnd);

    this.selectedFaciltyID = facility_index;
  }

  //mobile day touch move (pan)
  touchMove(event, facility: FacilitySummary, hour: HourView, hourSegment: HourSegmentView, isMobile:Boolean = false,i,l){
    if(isMobile)
    {
      this.drwaingcolour = this.getTimeLineColor('selected');
      let _startMin = document.elementFromPoint(event.center.x, event.center.y).id

      let _hoursegments  = this.mobilehourschanged.map(mh=> mh.hourSegments) as any;
      let _hourSegment = _hoursegments.map(hs => hs.find(hsv => hsv.startMin==_startMin)).filter(x => x!=undefined);

      console.log(this.pressed,_hourSegment);
      this.rangeMouseMove(event,facility,hour,_hourSegment[0],_startMin,isMobile)

    }
  }

  //mobile week touch move (pan)
  mobileSelectWeekTimeSlotMouseMove(event, facility: FacilitySummary, id,fi,wd){

    this.drwaingcolour = this.getTimeLineColor('selected');

    if(this.selectedFaciltyID != fi || this.selectedDayID != wd ){
      this.pressed ={};
    }

    let _startMin = document.elementFromPoint(event.center.x, event.center.y).id
    let _daySegments = this.weekDays.map(wd => wd.daySegments)[id].find((x : any) => x.endMin == _startMin) as DaySegmentView;

    if(_daySegments){
      this.selectWeekTimeSlotMouseMove(event,facility,_daySegments,_startMin,true);
    }

    // if(this.previousPanEvent && this.previousPanEvent != event.additionalEvent && Object.keys(this.pressed).length > 1 && this.selectedDayID == wd){
    //   delete this.pressed[_daySegments.startMin];
    //   console.log(_daySegments.startMin);
    // }

    this.selectedFaciltyID = fi;
    this.selectedDayID = wd;
    if(Object.keys(this.pressed).length == 1){
      this.previousPanEvent = event.additionalEvent;
    }
  }

  checkAvailability(facility : FacilitySummary, daySegment: DaySegmentView){
    const segmentDatePart = daySegment.date.toDateString();
    const date = daySegment.date.getDate();
    const startMin = daySegment.startMin / 60;
    const endMin = daySegment.endMin / 60;
    let  setupTeardown = 0;
    let availbility = "";
    if(facility.configuration){
      setupTeardown = facility.configuration.setupTime + facility.configuration.teardownTime;
    }
    const nonOperationalHoursTimeSlots = facility.calenderTimeslots.find((slot: CalenderTimeslot) => slot.startTime.toDateString() === segmentDatePart &&
        slot.start <= startMin && endMin <= slot.end && slot.isUnAvailableDueToOperationalHours);
    if (nonOperationalHoursTimeSlots) {
      availbility = 'noneoperational';
    }

    else{

      //unavailable for more than a day and date is inbetween the starting and ending date
      const morethanaDayUnavailable_SLots = facility.calenderTimeslots.find((slot:CalenderTimeslot) => (slot.endTime.getDate() - slot.startTime.getDate()) > 1 && (slot.endTime.getDate() > date &&  slot.startTime.getDate() < date));
      if( morethanaDayUnavailable_SLots){
        availbility = 'bookedColor';
      }

      else{
        //if calenderTimeslots end is > than segmentstart time and calenderTimeslots start is less than end min then it is unavailble
        const unavailble_slots = facility.calenderTimeslots.find((slot: CalenderTimeslot) => (slot.end > startMin && slot.start < endMin ) && (slot.startTime.toDateString() === segmentDatePart || slot.endTime.toDateString() === segmentDatePart));
        
        //if calanderTimeSLot start is < than segmantEnd and calanderTimeslot end is superpasing the date and startdate is same date ||
        // if calanderTimeSLot end is > segmentStart and calnderTImeslot start is before the date AND endDate is samedate
        //this is a overnight unavailable
        const overnightUnavailable_SLots = facility.calenderTimeslots.find((slot:CalenderTimeslot) => (slot.start < endMin && slot.endTime.getDate() > date && slot.startTime.toDateString() === segmentDatePart) || 
                                                                                                      (slot.end > startMin && slot.startTime.getDate() < date && slot.endTime.toDateString() === segmentDatePart));
      
        if(unavailble_slots || overnightUnavailable_SLots){
          const partial_slots = facility.availableTimeslots.find((slot: CalenderTimeslot) => (slot.end > startMin && slot.start < endMin ) && ((slot.end - slot.start) > setupTeardown/60) && slot.startTime.toDateString() === segmentDatePart);
        
          if(partial_slots){
            availbility = 'partiallyavailable';
          }
          else{
            availbility = 'bookedColor';
          }
          
        }
        
        else{
          availbility = 'calendaravailable';
        }
      }
    }

    return availbility;
  }

  findselectedBookingslots(slots : any[], segment : DaySegmentView){
     console.log(slots); 
    if(slots!=undefined && slots.length > 0){
      let _slots = slots.filter(s => s.date.toDateString() == segment.date.toDateString() && s.startMin >= segment.startMin && s.endMin <= segment.endMin);
    
      return _slots.length > 0; 
    }
  }

  goToCart(){
    if(this.cartAddedFacilities && this.cartAddedFacilities.length > 0){
      this.router.navigate(['/booking-cart']);
    }
  }
}
