import { Component, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';

@Component({
    selector: 'app-nested',
    templateUrl: './nested.component.html',
    //styleUrls: ['./nested.component.scss']
})
export class NestedComponent implements AfterViewInit {
    name = 'Angular';
    slideNo = 0;
    withAnim = true;
    resetAnim = true;
    @ViewChild('myCarousel') myCarousel: NguCarousel<any>;
    carouselConfig: NguCarouselConfig = {
        grid: { xs: 1, sm: 1, md: 2, lg: 2, all: 0 },
        load: 3,
        interval: { timing: 4000, initialDelay: 1000 },
        loop: true,
        touch: true,
        velocity: 0.2
    }
    carouselItems = [1, 2, 3];

    constructor(private cdr: ChangeDetectorRef) { }

    ngAfterViewInit() {
        this.cdr.detectChanges();
    }

    reset() {
        this.myCarousel.reset(!this.resetAnim);
    }

    moveTo(slide) {
        this.myCarousel.moveTo(slide, !this.withAnim);
    }
}
