<mat-expansion-panel [expanded]="true" [disabled]="true">
  <mat-expansion-panel-header  class="mat-header">
    <opt-question-header [dates]="dates" [isMandatory]="bookingQues?.question?.mandatory"
      [questionNumber]="questionNumber" [questionText]="bookingQues?.question?.questionText"
      (popselectedDates)="RecveselectedDates($event)"></opt-question-header>
  </mat-expansion-panel-header>
  <div class="wp_flex--container flex-column wp-mt-10">
    <div class="wp_flex__item--inherit wp_flex__item--vcenter wp-mb-20"
      *ngFor="let ansChoice of bookingQues?.bookingQuestionChoices; let j=index;trackBy: trackByFn">
      <!--<div class="wp_flex__item--inherit wp_flex__item--vcenter" *ngFor="let ansChoice of bookingQues?.question?.answerChoices; let j=index;trackBy: trackByFn">-->
      <!--<mat-checkbox [value]="ansChoice.id">{{ansChoice.name}}</mat-checkbox>-->

      <mat-checkbox [(ngModel)]="ansChoice.isSelectedAnswer" [checked]="checkValue(ansChoice,bookingQues.question)"
        (change)="selectionChanged($event,ansChoice,bookingQues.question)">{{ansChoice.name}}</mat-checkbox>
      <!-- <div *ngIf="bookingQues.question.quantityRequired && ansChoice.isSelectedAnswer" class="pl-3"> -->
      <div *ngIf="bookingQues.question.quantityRequired" class="pl-3">
        <mat-form-field class="">
          <input type="number" matInput placeholder="Quantity"
            (change)="selectionChanged($event,ansChoice,bookingQues.question)" min="0" [(ngModel)]="ansChoice.quantity">
        </mat-form-field>

      </div>
    </div>
  </div>
</mat-expansion-panel>
<div class="wp_flex--container flex-column question-list " class="mt-4 conditional-question">
  <div class="wp_flex__item--inherit wp_flex__item--vcenter">
    <opt-question class="w-100" [isMandatory]="false" [questionNumber]="1"
      [questions]="bookingQues.conditionalBookingQuestions" [answeredQuestionIds]="answeredQuestionIds"
      (questionAnswerOutput)="conditionalQuestionAnswerChange($event)"></opt-question>
  </div>
</div>
