import { BaseParam } from '../base-param';
import { environment } from '../../../environments/environment';

export class Facilitysearchparams extends BaseParam {
    searchpharese: string;
    starttime: Date;
    endtime: Date;
    pagenumber: number;
    pagesize: number;
    filterattendens: string;
    filterfacilites: string;
    assetname: string[];
    timelable: string;
    assetTypeId: string;
    SalesCategoryId: string;
    VenueIds: number;
    AssetTypeIds: number;
    AssetCategoryIds: number;
    venueTypeIds: string[];
    selectedDate: Date;
}
