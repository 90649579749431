import { Component, OnInit } from '@angular/core';
import { BaseComponent } from "src/modules/shared/base.component";
import { TranslateService } from "@ngx-translate/core";
import { Store } from "@ngrx/store";
import * as LanguageSelector from 'src/modules/store/public-web/select-language/language-selector';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'opt-mmc-loader',
  templateUrl: './mmc-loader.component.html',
  styleUrls: ['./mmc-loader.component.scss']
})
export class MmcLoaderComponent extends BaseComponent implements OnInit {
  selectedLanguage: string;//='en';
  constructor(
    private store: Store<any>,
    private translate: TranslateService,
    private route: ActivatedRoute
  ){
    super();
  }

  ngOnInit() {
    this.store.select(LanguageSelector.getSelectedLanguage).safeSubscribe(this, storedLanguage => {
      this.selectedLanguage = !!storedLanguage ? storedLanguage : '';
    });
    this.route.queryParams.subscribe((params) => {
      if (params['lang'] && (params['lang'].toLowerCase() != this.selectedLanguage.toLowerCase())){
        const language = params['lang'];
        this.translate.use(language);
       }else {
         this.translate.use(this.selectedLanguage.toLowerCase());
       }
    });
  }

}
