import { Component, Inject, Input, Output, OnInit, EventEmitter, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileSystemDirectoryEntry, FileSystemFileEntry, UploadEvent } from 'ngx-file-drop';
import { AnswerUploadedFile, FileUploadQuestion, FileUploadQuestionAnswer } from 'src/modules/models/regular-portal/booking/question/question';
import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';
import { SuccessMessageComponent } from 'src/modules/shared/success-message/success-message.component';
import { Utility } from 'src/modules/utility';
// import { AlertMessageComponent } from '../../alert-message/alert-message.component';
// import { SuccessMessageComponent } from '../../success-message/success-message.component';


@Component({
  selector: 'opt-file-upload-question',
  templateUrl: './file-upload-question.component.html',
})
export class FileUploadQuestionComponent implements OnInit {
  @Input() dates: any[];
  @Input() bookingQues: FileUploadQuestionAnswer;
  @Output() QuestionAnswerOutput = new EventEmitter();
  @Output() DateSelectedOutput = new EventEmitter();
  @Input() answeredQuestionIds: string[];
  @Input() questionNumber: number;
  @ViewChild('fileInputTag') fileInputTagVariable: ElementRef; status: boolean = false;
  displayArrayData: any[] = [];

  fileData: any;
  // bookingQues.answerUplodedFiles: AnswerUplodedFile[];
  maximumDocUploadSize;
  files;
  documentUpldSize;
  minCount;
  maxCount;
  fileId;
  supportTypes = [];
  constructor(private snackBar: MatSnackBar) {
    this.minCount = 1
    this.fileId = -1;
    this.supportTypes = [];

  }

  ngOnInit() {
    this.maximumDocUploadSize = (this.bookingQues.question as FileUploadQuestion).maxSize;
    this.displayArrayData.push(this.dates)
    this.minCount = (this.bookingQues.question as FileUploadQuestion).minCount;
    if(this.minCount == undefined || this.minCount == null){
      this.minCount = 1
    }
    if(this.maxCount == undefined || this.maxCount == null){
      this.maxCount = 1
    }
    this.maxCount = (this.bookingQues.question as FileUploadQuestion).maxCount;
    if (this.maximumDocUploadSize) {
      this.documentUpldSize = this.maximumDocUploadSize + "MB";
    } else {
      this.documentUpldSize = "2GB"
    }
    this.bookingQues.answerUploadedFiles = !!this.bookingQues.answerUploadedFiles ? this.bookingQues.answerUploadedFiles : [];
    this.mapFileType((this.bookingQues.question as FileUploadQuestion).supportedFileType)
    this.inputChanged();
  }
  mapFileType(file: string) {
    if(file != undefined && file != null){
    this.supportTypes = file.split(",")
  }else{
    this.supportTypes.push('.doc','.pdf')
  }
  }
  getUplodedFiles() {
    return !!this.bookingQues.answerUploadedFiles ? this.bookingQues.answerUploadedFiles.filter(s => !s.isDelete) : [];
  }
  removeFile(id) {
    let index = this.bookingQues.answerUploadedFiles.findIndex(x => x.id === id);
    if (+this.bookingQues.answerUploadedFiles[index].id > 0) {
      this.bookingQues.answerUploadedFiles[index].isDelete = true;
    } else {
      this.bookingQues.answerUploadedFiles.splice(index, 1);
    }
    this.inputChanged();
  }

  viewFile(id) {
    let index = this.bookingQues.answerUploadedFiles.findIndex(x => x.id === id);
    if (+this.bookingQues.answerUploadedFiles[index] != undefined || +this.bookingQues.answerUploadedFiles[index] != null) {
      if (this.bookingQues.answerUploadedFiles[index].url) {
        window.open(this.bookingQues.answerUploadedFiles[index].url);
      } else if (this.bookingQues.answerUploadedFiles[index].documentFileURL) {
        window.open(this.bookingQues.answerUploadedFiles[index].documentFileURL);
      } else {
        if (this.bookingQues.answerUploadedFiles[index].fileType == "pdf") {
          this.fileData = "data:application/" + this.bookingQues.answerUploadedFiles[index].fileType + ";base64," + this.bookingQues.answerUploadedFiles[index].documentFile
        } else {
          //this.fileData="data:image/" +this.bookingQues.answerUplodedFiles[index].fileType+ ";base64,"+this.bookingQues.answerUplodedFiles[index].documentFile
          this.fileData = this.bookingQues.answerUploadedFiles[index].documentFileOriginal + this.bookingQues.answerUploadedFiles[index].documentFile
        }
        this.debugBase64(this.fileData)
      }
    }
  }

  debugBase64(base64URL) {
    var win = window.open();
    win.document.write('<iframe src="' + base64URL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
  }

  onFileChanged(event) {
    var thispage = this;
    if (this.checkFileExtesion(event.target.files[0].name)) {
      //if (event.target.files[0].size < 3000000) {
      if (event.target.files[0].size < this.AcceptedFileSize()) {
        var myReader: FileReader = new FileReader();
        myReader.onloadend = (e) => {
          let aHD = new AnswerUploadedFile();
          aHD.id = this.fileId.toString();
          aHD.file = event.target.files[0];
          aHD.name = event.target.files[0].name;
          aHD.fileSize = Utility.calculateFileSize(event.target.files[0].size);
          let sData = aHD.file.name.split('.');
          aHD.fileType = sData[sData.length - 1];
          let stringdata = myReader.result.toString().split(',');
          aHD.documentFile = stringdata[stringdata.length - 1];
          // if (this.booking.bookingTypeId > 1)
          //   aHD.documentCategoryId = this.selecteddocumentTemplateCategory;
          aHD.documentFileOriginal = myReader.result.toString().replace(stringdata[stringdata.length - 1], "");
          this.bookingQues.answerUploadedFiles.push(aHD)
          this.fileInputTagVariable.nativeElement.value = "";
          this.inputChanged();
          this.fileId--;
        };
        myReader.readAsDataURL(event.target.files[0]);

      }
      else {
        thispage.openSnackBarError(["Your request could not be processed. You have attempted to upload a document larger than" + this.documentUpldSize + "in size."]);
      }
    }
    else {
      thispage.openSnackBarError(["Your request could not be processed. You have uploaded an invalid document type. Only files with the following extensions are allowed: " + (this.bookingQues.question as FileUploadQuestion).supportedFileType]);
    }
  }
  inputChanged() {
    const data = this.bookingQues.answerUploadedFiles;
    var thisComponent = this;
    this.bookingQues.answerUploadedFiles = data;
    if (this.bookingQues.answerUploadedFiles.length > 0) {
      this.bookingQues.answeredTime = new (Date);
    } else {
      // this.bookingQues.answeredTime = undefined;
    }
    if (this.dates != undefined && this.dates.length > 0) {
      this.dates.forEach(function (dt, i) {
        dt.actualQuestionTobePatch.answerUplodedFiles = data;
        //  thisComponent.addOrRemoveQuestionID(dt.actualQuestionTobePatch.id, val.target.value != "" || val.target.value != undefined);
      });
    }
    //else {
    thisComponent.addOrRemoveQuestionID(this.bookingQues.id, data.length > 0 && data != undefined);
    //}
    if (!(this.dates != undefined && this.dates.length > 0))
      this.QuestionAnswerOutput.emit(this.bookingQues);
  }
  RecveselectedDates(event) {
    this.displayArrayData.push(event);
    this.DateSelectedOutput.emit(event);
  }
  addOrRemoveQuestionID(bookingId: string, isAnswered: boolean) {
    let index = this.answeredQuestionIds.findIndex(x => x == bookingId);
    if (index >= 0) {
      if (!isAnswered) {
        this.answeredQuestionIds.splice(index, 1)
      }
    } else {
      if (isAnswered) {
        this.answeredQuestionIds.push(bookingId);
      }
    }
  }
  dropped(event: UploadEvent) {
    this.files = event.files;
    var thispage = this;

    for (const droppedFile of event.files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (this.checkFileExtesion(file.name)) {
            //if (file.size < 3000000) {
            if (file.size < this.AcceptedFileSize()) {
              var myReader: FileReader = new FileReader();
              myReader.onloadend = (e) => {
                let aHD = new AnswerUploadedFile();
                aHD.id = this.fileId.toString();
                aHD.name = file.name;
                aHD.fileSize = Utility.calculateFileSize(file.size);

                let stringdata = myReader.result.toString().split(',');
                aHD.documentFile = stringdata[stringdata.length - 1];
                aHD.file = file;
                // if (this.booking.bookingTypeId > 1)
                //   aHD.documentCategoryId = this.selecteddocumentTemplateCategory;
                aHD.documentFileOriginal = myReader.result.toString().replace(stringdata[stringdata.length - 1], "");
                this.bookingQues.answerUploadedFiles.push(aHD)
                this.inputChanged();
                this.fileId--;
              };
              myReader.readAsDataURL(file);
            }
            else {
              thispage.openSnackBarError(["Your request could not be processed. You have attempted to upload a document larger than" + this.documentUpldSize + "in size."]);
            }
          }
          else {
            thispage.openSnackBarError(["Your request could not be processed. You have uploaded an invalid document type. Only files with the following extensions are allowed: .doc, .docx, .pdf, .txt, .xls, .xlsx, .jpeg, .jpg, .png"]);
          }
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }
  checkFileExtesion(name: string) {
    let fileExtensionArray = name.split('.');
    let fileExtension = fileExtensionArray[fileExtensionArray.length - 1];
    return (this.supportTypes.indexOf(fileExtension.toLowerCase()) !== -1);
  }
  AcceptedFileSize() {

    if (this.maximumDocUploadSize) {

      var _docSizeInBytes = this.maximumDocUploadSize * 1000000;
      return _docSizeInBytes;
    }
    else {
      return 2000000000;
    }
  }
  openSnackBarSuccess(message) {
    this.snackBar.openFromComponent(SuccessMessageComponent, {
      data: message,
      duration: 3000,
      verticalPosition: 'top'
    });
  }

  openSnackBarError(message) {
    this.snackBar.openFromComponent(AlertMessageComponent, {
      data: message,
      duration: 3000,
      verticalPosition: 'top'
    });
  }
}
