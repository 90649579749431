import { Action } from '@ngrx/store';

import { Contact } from '../models/client/contact';
import { Booking } from '../models/booking/booking';
import { Package } from '../models/booking/package';
import { PackageFilter } from '../models/booking/common';
import { FacilitySummary } from '../models/asset/facility-summary';
import { BespokePackage } from '../models/asset/bespoke-package';
import { ErrorState } from '../models/error/error';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdHocDocument } from 'src/modules/models/booking/adhoc-document'
import { Item } from 'src/modules/models/item/item';
import { UserDefinedFunction } from '../models/system/user-defined-function';

export enum CartActionTypes {
    REDIRECT = 'REDIRECT',
    SET_CONTACT = 'SET_CONTACT',

    SET_RB_INTERNAL_USER_DATA = 'SET_RB_INTERNAL_USER_DATA',
    SIGNOUT = 'SIGNOUT',

    ADD_FACILITY = 'ADD_FACILITY',
    DELETE_FACILITY = 'DELETE_FACILITY',
    UPDATE_FACILITY = 'UPDATE_FACILITY',
    EDIT_FACILITY = 'EDIT_FACILITY',

    UPDATE_FACILITIES = 'UPDATE_FACILITIES',

    PACKAGE_SEARCH = 'PACKAGE_SEARCH',
    UPDATE_PACKAGES = 'UPDATE_PACKAGES',

    SET_BOOKING = 'SET_BOOKING',
    POST_BOOKING = 'POST_BOOKING',
    PATCH_BOOKING_FACILITY = 'PATCH_BOOKING_FACILITY',
    UPDATE_CART = 'UPDATE_CART',
    COMPLETE_BOOKING = 'COMPLETE_BOOKING',
    ERROR_LOG = 'ERROR_LOG',
    UPDATE_BOOKING_PAKAGE = 'UPDATE_BOOKING_PAKAGE',
    PATCH_BOOKING_CONTACT = 'PATCH_BOOKING_CONTACT',
    ADD_DOCUMENTS = 'ADD_DOCUMENTS',
    DELETE_DOCUMENTS = 'DELETE_DOCUMENTS',
    UPDATE_BOOKING_EVENT_NAME = 'UPDATE_BOOKING_EVENT_NAME',
    DO_BOOKING = 'DO_BOOKING',
    UPDATE_FACILITY_UPSELL_FLAG = 'UPDATE_FACILITY_UPSELL_FLAG',
    SET_ITEM = 'SET_ITEM',
    VALIDATE_FACILITIES = 'VALIDATE_FACILITIES',
    POST_INVOICE = 'POST_INVOICE',
    UPDATE_FACILITY_PRICE = 'UPDATE_FACILITY_PRICE',
    DELETE_FACILITY_PRICE = 'DELETE_FACILITY_PRICE',
    UPDATE_BUSINESS_AREA = 'UPDATE_BUSINESS_AREA',
    SET_IMMEDIATE_DATA = 'SET_IMMEDIATE_DATA',
    CALCULATE_CART_PRICES = 'CALCULATE_CART_PRICES',
    STORE_ASSETS = 'STORE_ASSETS',
    MAKE_PAYMENT = 'MAKE_PAYMENT',
    CLEAR_CART = 'CLEAR_CART',
    STORE_CUSTOM_FUNCTIONS = 'STORE_CUSTOM_FUNCTIONS',

    SAVE_BOOKING = 'SAVE_BOOKING',
    POST_BOOKING_DATA = 'POST_BOOKING_DATA',

    SHOW_PACKAGE_QUESTION_POPUP ='SHOW_PACKAGE_QUESTION_POPUP',
    HIDE_PACKAGE_QUESTION_POPUP = 'HIDE_PACKAGE_QUESTION_POPUP',
    RESET_BOOKING= 'RESET_BOOKING'

}
//SetItem
export class SetItem implements Action {
    readonly type = CartActionTypes.SET_ITEM
    constructor(public payload: Item) { }
}

export class Redirect implements Action {
    readonly type = CartActionTypes.REDIRECT
    constructor(public payload: string) { }
}
export class SetContact implements Action {
    readonly type = CartActionTypes.SET_CONTACT
    constructor(public payload: Contact, public redirectActionPayload: string ,public navigateUrl: string, public queryParams: any, public redirectFullUrl: string, public action: CartActionTypes) { }
}


export class SetInternalUserData implements Action {
    readonly type = CartActionTypes.SET_RB_INTERNAL_USER_DATA
    constructor(public payload: any) { }
}

export class SignOut implements Action {
    readonly type = CartActionTypes.SIGNOUT
    constructor() { }
}


export class AddDocument implements Action {
    readonly type = CartActionTypes.ADD_DOCUMENTS
    constructor(public payload: AdHocDocument) { }
}

export class ValidateFacilities implements Action {
    readonly type = CartActionTypes.VALIDATE_FACILITIES
    constructor() { }
}

export class DeleteDocument implements Action {
    readonly type = CartActionTypes.DELETE_DOCUMENTS
    constructor(public payload: AdHocDocument) { }
}

export class AddFacility implements Action {
    readonly type = CartActionTypes.ADD_FACILITY
    constructor(public payload: FacilitySummary, public bookingAction?: string) { }
}
export class DeleteFacility implements Action {
    readonly type = CartActionTypes.DELETE_FACILITY
    constructor(public payload: number) { }
}
export class UpdateFacility implements Action {
    readonly type = CartActionTypes.UPDATE_FACILITY
    constructor(public payload: FacilitySummary) { }
}
export class EditFacility implements Action {
    readonly type = CartActionTypes.EDIT_FACILITY
    constructor(public payload: FacilitySummary) { }
}

export class UpdateFacilityUpsellFlag implements Action {
    readonly type = CartActionTypes.UPDATE_FACILITY_UPSELL_FLAG
    constructor(public payload: FacilitySummary, public bookingAction? : string) { }
}
export class UpdateFacilities implements Action {
    readonly type = CartActionTypes.UPDATE_FACILITIES
    constructor(public payload: FacilitySummary[]) { }
}

export class PackageSearch implements Action {
    readonly type = CartActionTypes.PACKAGE_SEARCH
    constructor() { }
}
export class UpdatePackages implements Action {
    readonly type = CartActionTypes.UPDATE_PACKAGES
    constructor(public payload: BespokePackage[]) { }
}

export class PostBooking implements Action {
    readonly type = CartActionTypes.POST_BOOKING
    constructor(public payload: Booking) { }
}

export class PatchBookingContact implements Action {
    readonly type = CartActionTypes.PATCH_BOOKING_CONTACT
    constructor() { }
}
export class PatchBookingFacility implements Action {
    readonly type = CartActionTypes.PATCH_BOOKING_FACILITY
    constructor(public payload: Booking) { }
}

export class UpdateCart implements Action {
    readonly type = CartActionTypes.UPDATE_CART
    constructor(public payload: Booking) { }
}
export class LogError implements Action {
    readonly type = CartActionTypes.ERROR_LOG
    constructor(public payload: ErrorState) { }
}
export class CompleteBooking implements Action {
    readonly type = CartActionTypes.COMPLETE_BOOKING
    constructor() { }
}

export class UpdateBookingPackage implements Action {
    readonly type = CartActionTypes.UPDATE_BOOKING_PAKAGE
    constructor(public payload: PackageFilter) { }
}

export class UpdateBookingEventName implements Action {
    readonly type = CartActionTypes.UPDATE_BOOKING_EVENT_NAME
    constructor(public payload: string) { }
}


export class DoBooking implements Action {
   readonly type = CartActionTypes.DO_BOOKING
   constructor() { }
}

export class PostInvoice implements Action {
    readonly type = CartActionTypes.POST_INVOICE
    constructor(public payload: Booking) { }
}

export class UpdateFacilityPrice implements Action {
    readonly type = CartActionTypes.UPDATE_FACILITY_PRICE
    constructor(public payload: FacilitySummary) { }
}

export class DeleteFacilityPrice implements Action {
    readonly type = CartActionTypes.DELETE_FACILITY_PRICE
    constructor(public payload: FacilitySummary) { }
}

export class UpdateBusinessArea implements Action {
    readonly type = CartActionTypes.UPDATE_BUSINESS_AREA
    constructor(public payload: Package) { }
}

export class SetImmediateData implements Action {
    readonly type = CartActionTypes.SET_IMMEDIATE_DATA
    constructor(public payload: Booking) { }
}
export class CalculateCartPrices implements Action {
    readonly type = CartActionTypes.CALCULATE_CART_PRICES
    constructor() { }
}
export class StoreAssets implements Action {
    readonly type = CartActionTypes.STORE_ASSETS
    constructor(public payload: Booking) { }
}

export class MakePayment implements Action {
    readonly type = CartActionTypes.MAKE_PAYMENT
    constructor(public payload: Booking) { }
}

export class ClearCart implements Action {
    readonly type = CartActionTypes.CLEAR_CART
    constructor() { }
}

export class SaveBooking implements Action {
    readonly type = CartActionTypes.SAVE_BOOKING
    constructor(public payload?: Booking) { }
}

export class PostBookingData implements Action {
    readonly type = CartActionTypes.POST_BOOKING_DATA
    constructor(public payload: Booking) { }
}

export class StoreCustomFunctions implements Action {
    readonly type = CartActionTypes.STORE_CUSTOM_FUNCTIONS
    constructor(public customFunctions: UserDefinedFunction[]) { }
}

export class PackageQuestionPopupShown implements Action {
  readonly type = CartActionTypes.SHOW_PACKAGE_QUESTION_POPUP;
  constructor() { }
}

export class PackageQuestionPopupHidden implements Action {
  readonly type = CartActionTypes.HIDE_PACKAGE_QUESTION_POPUP;
  constructor() { }
}

export class ResetBooking implements Action {
    readonly type = CartActionTypes.RESET_BOOKING
    constructor() { }
}

export type CartActions =
    SetItem | SetContact | Redirect | SignOut | SetInternalUserData |
    AddFacility | DeleteFacility | UpdateFacility | EditFacility |
    PackageSearch | UpdatePackages |
    PostBooking | PatchBookingFacility | CompleteBooking |
    UpdateCart | UpdateFacilities | UpdateBookingPackage |
    LogError | PatchBookingContact | AddDocument | DeleteDocument | UpdateBookingEventName | DoBooking | UpdateFacilityUpsellFlag | ValidateFacilities | PostInvoice | UpdateFacilityPrice | DeleteFacilityPrice | UpdateBusinessArea | SetImmediateData | CalculateCartPrices |
    StoreAssets | MakePayment | ClearCart | SaveBooking | PostBookingData | StoreCustomFunctions|PackageQuestionPopupShown|PackageQuestionPopupHidden |ResetBooking;
