import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertMessageComponent } from '../alert-message/alert-message.component';
import { WarningMessageComponent } from '../warning-message/warning-message.component';
import { SuccessMessageComponent } from '../success-message/success-message.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'opt-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss']
})
export class ToastMessageComponent implements OnInit {

    constructor(public dialog: MatDialog) { }
    //openSnackBarError() {
    //    this.snackBar.openFromComponent(AlertMessageComponent, {
    //        duration: 4000,
    //        verticalPosition: 'top',
    //        data: ["test message 1", "test message 1","test message 1"],
    //    });
    //}
    //openSnackBarWarning() {
    //    this.snackBar.openFromComponent(WarningMessageComponent, {
    //        duration: 4000,
    //        verticalPosition: 'top',
    //    });
    //}
    //openSnackBarSuccess() {
    //    this.snackBar.openFromComponent(SuccessMessageComponent, {
    //        duration: 4000,
    //        verticalPosition: 'top',
    //    });
    //}
    //openDialogError(): void {
    //    const dialogRef = this.dialog.open(AlertMessageComponent, {
    //        //panelClass: ['w80modal', 'dialog-container-common'],
    //        width: '250px'
    //    });
    //}
    public openDialogError() {
        this.dialog.open(WarningMessageComponent, {
            width: '80%',
            disableClose: true
        });
    }
  ngOnInit() {
  }

}
