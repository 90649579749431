import { BaseResource } from '../base-resource';
import { PackageUserDefinedField } from 'src/modules/models/user-defined-field/package-user-defined-field';

export class Package extends BaseResource {
    constructor() {
        super();
       this.type = "PrivatePackage";
    }
    name: string;
    businessAreaId: string;

    userDefinedFields : PackageUserDefinedField[]
    fixedPriceIncludingTax:string;
}

export class BusinessArea {
    id: string;
    name: string;
    billerCode: string;
}
