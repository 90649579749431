import { Component, Inject, Input, Output, OnInit, EventEmitter, OnDestroy } from '@angular/core';
//import { MatDialog } from '@angular/material/dialog';
//import {GlobalBookingQuestionnaire,ItemBookingQuestionnaire,PackageBookingQuestionnaire } from 'src/modules/models/regular-portal/booking/question/booking-questionnaire';
//import { Question, TextQuestion } from 'src/modules/models/regular-portal/booking/question/question';
//import { Question as Questions } from 'src/modules/models/regular-portal/booking/question/question-patch/question';
import { Answer } from 'src/modules/models/regular-portal/booking/question/answer';
import { TextQuestionAnswer } from 'src/modules/models/regular-portal/booking/question/booking-question';
@Component({
  selector: 'opt-text-question',
  templateUrl: './text-question.component.html',
})
export class TextQuestionComponent implements OnInit {
  @Input() dates: any[];
  @Input() bookingQues: TextQuestionAnswer;
  @Input() answeredQuestionIds: string[];
  @Output() QuestionAnswerOutput = new EventEmitter();
  @Output() DateSelectedOutput = new EventEmitter();
  displayArrayData: any[] = [];
  @Input() questionNumber: number;

  constructor() {
  }
  ngOnInit() {
    this.displayArrayData.push(this.dates)
    var thisComponent = this;

    // if (this.bookingQues.question.answer == undefined) {
    if (this.bookingQues.answer == undefined || this.bookingQues.answer == "") {
      if (this.bookingQues.question != undefined && this.bookingQues.question.defaultAnswer != "" && this.bookingQues.question.defaultAnswer != undefined) {
        this.bookingQues.answer = this.bookingQues.question.defaultAnswer;
      } else {
        this.bookingQues.answer = "";
      }
    } else {
      //if (this.dates != undefined && this.dates.length > 0) {
      //    this.dates.forEach(function (dt, i) {
      //        thisComponent.addOrRemoveQuestionID(dt.actualQuestionTobePatch.id, true);
      //    });
      //} else {
      thisComponent.addOrRemoveQuestionID(this.bookingQues.id, true);
      //}
    }
    if (!(this.dates != undefined && this.dates.length > 0))
      this.QuestionAnswerOutput.emit(this.bookingQues);
  }

  inputChanged(val) {
    var thisComponent = this;
    this.bookingQues.answer == val.target.value;
    this.bookingQues.answeredTime = new (Date);
    if (this.dates != undefined && this.dates.length > 0) {
      this.dates.forEach(function (dt, i) {
        dt.actualQuestionTobePatch.answer = val.target.value;
        //   thisComponent.addOrRemoveQuestionID(dt.actualQuestionTobePatch.id, val.target.value != "" && val.target.value != undefined);
      });
    }
    //else {
    thisComponent.addOrRemoveQuestionID(this.bookingQues.id, val.target.value != "" && val.target.value != undefined);
    // }
    if (!(this.dates != undefined && this.dates.length > 0))
      this.QuestionAnswerOutput.emit(this.bookingQues);
  }

  addOrRemoveQuestionID(bookingId: string, isAnswered: boolean) {
    let index = this.answeredQuestionIds.findIndex(x => x == bookingId);
    if (index >= 0) {
      if (!isAnswered) {
        this.answeredQuestionIds.splice(index, 1)
      }
    } else {
      if (isAnswered) {
        this.answeredQuestionIds.push(bookingId);
      }
    }
  }

  RecveselectedDates(event) {
    this.displayArrayData.push(event);
    this.DateSelectedOutput.emit(event);
  }

}
