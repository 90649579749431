import { environment } from "src/environments/environment";
import { Configuration } from "../../asset/configuration";
import { BaseResource } from "../../base-resource";
import { ItemPriceGroup } from "../../item/item-price-group";
import { BookingQuestion } from "../../regular-portal/booking/question/booking-question";
import { Question } from "../../regular-portal/booking/question/question";

export class Item extends BaseResource {
  constructor() {
    super();
    this.qty = 1;
    this.isNeedToBeUpdate = false;
  }
  name: string;
  active: boolean;
  repeatPolicy: number
  availableFrom: string;
  availableTo: string;
  commissionable: boolean;
  defaultPriceConcessionID: number;
  defaultPriceConcessionName: string;
  discountable: boolean;
  itemAssets: ItemAssets[];
  itemCategory: string;
  itemCategoryID: number;
  itemType: string;
  itemTypeID: number;
  itinararyItem: boolean;
  itemPriceGroups: ItemPriceGroup[];
  leadDays: number;
  multiplesOnly: boolean;
  nameOnReport: string;
  partOfPackage: string;
  upsellItem: boolean;
  packageId: string;
  salesCategories: SalesCategory[];
  allocateToAllAssets: boolean;
  question: Question[];
  qty: number;
  maxQuantity: number;
  itemMaxPrice: ItemPriceGroup;
  itemMinPrice: ItemPriceGroup;
  totalAnsweredQuestions?: number;
  allowImmediateConfirmedBookings: number;
  calculatedMinPrice: ItemPriceGroup;
  calculatedMaxPrice: ItemPriceGroup;
  isNeedToBeUpdate: boolean;
  bookingQuestions: BookingQuestion[];
  webShortDescription: string;
  webLongDescription: string;
  availabilityStatus: string;
  itemAvailabilityPublic : any[];
  itemAvailability : any[];
  backgroundColor: string = "";
}

export class ItemAssets extends BaseResource {
  configuration: Configuration;
  asset: BaseResource;
}
export class SalesCategory extends BaseResource { }

export class UpsellSummary extends Item {
  constructor() {
    super();
    this.qty = 1;
  }
  qty: number;
  minPrice: ItemPriceGroup;
  maxPrice: ItemPriceGroup;
  itemPrice: ItemPriceGroup;
  coverPrice: UnitPrice;
  sessionPrice: UnitPrice;
  hourlyPrice: UnitPrice;
  halfDayPrice: UnitPrice;
  dailyPrice: UnitPrice;
  startTime: string;
  endTime: string;
  isAdded: boolean;
  url: string = environment.ApiUrl + "dist/assets/images/no-image.png";
  question: Question[];
}

export class UnitPrice {
  minPrice: ItemPriceGroup;
  maxPrice: ItemPriceGroup;
}
