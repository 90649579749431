import { Component, OnInit, Inject } from '@angular/core';
import { Observable } from "rxjs";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';

import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store, ICart } from 'src/modules/store/index';

import { ItemService } from 'src/modules/services/item.service';

import { BookingCommonComponent } from 'src/modules/booking-portal/booking-common.component';

import { FacilityAdaptor } from 'src/modules/models/asset/facility-adaptor';
import { FacilitySummary } from 'src/modules/models/asset/facility-summary';
import { environment } from 'src/environments/environment';
import { Item } from 'src/modules/models/item/item';
import { ItemPriceGroup } from 'src/modules/models/item/item-price-group';
@Component({
  selector: 'opt-alternative-options',
  templateUrl: './alternative-options.component.html',
  styleUrls: ['./alternative-options.component.scss']
})
export class AlternativeOptionsComponent extends BookingCommonComponent implements OnInit {
    cart_subscriber: any;
    cart$: Observable<ICart>;
    facility: any;
    items: any;
  
    configurationDisplayMode: any;
   

   // configuration: any[];
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private _itemService: ItemService, public dialogRef: MatDialogRef<AlternativeOptionsComponent>
        , private store: Store<any>,snackBar:MatSnackBar) {
        super(store, _itemService,snackBar);
       
        this.configurationDisplayMode = environment.ConfigurationSelection.DisplayMode;        
        this.facility = data.fc;
        this.items = data.fc.items;        
    }

    ngOnInit() {
        //pasing the items to get the item configurations
        var items = this.facility.items;
        super.ngOnInit();
        super.calculateItemPrices([this.facility], this.facility.startTime, this.facility.endTime,true);
    }
    //returning the relevent configuration for the item
    configurations(item) {
       
        return FacilityAdaptor.getFacilityConfigurations(this.facility, item);
    }   

    //ngOnDestroy() {
    //    if (this.cart_subscriber)
    //        this.cart_subscriber.unsubscribe();
        
    //}   


    //add relevent Titem
    addToBookingAlternative(item: Item) {
        this.facility.selectedItem = item;
      
        this.facility.selectedConfiguration = FacilityAdaptor.getConfiguration(this.facility.selectedItem, this.facility);
           //this.facility.item = item;
        //this.facility.item.calculatedMinPrice.priceIncludingTax = item.calculatedMinPrice.priceIncludingTax;
        //this.facility.item.calculatedMaxPrice.priceIncludingTax = item.calculatedMaxPrice.priceIncludingTax;
        this.dialogRef.close();
    }

}
