import { Component, Inject, Input, Output, OnInit, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';
import { Answer } from 'src/modules/models/regular-portal/booking/question/answer';
import { RichTextQuestionAnswer } from 'src/modules/models/regular-portal/booking/question/booking-question';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'opt-rich-text-question',
  templateUrl: './rich-text-question.component.html',
})
export class RichTextQuestionComponent implements OnInit, AfterViewInit {
  @Input() dates: any[];
  @Input() bookingQues: RichTextQuestionAnswer;
  @Output() QuestionAnswerOutput = new EventEmitter();
  @Output() DateSelectedOutput = new EventEmitter();
  @Input() answeredQuestionIds: string[];
  @Input() questionNumber: number;

  displayArrayData: any[] = [];
  isAdvanced;
  toolbarConfig;
  public editor;
  constructor() {
    this.isAdvanced = false;
    this.editor = ClassicEditor;
    this.toolbarConfig = "{ toolbar: [] }";
  }
  ngAfterViewInit(): void {
    // this.editor.plugins.get('RestrictedEditingModeEditing').enableCommand('myCommand');
  }

  ngOnInit() {
    var thisComponent = this;
    this.displayArrayData.push(this.dates)

    if (this.bookingQues.answer == undefined) {
      this.bookingQues.answer = "";
    } else {
      //if (this.dates != undefined && this.dates.length > 0) {
      //    this.dates.forEach(function (dt, i) {
      //        thisComponent.addOrRemoveQuestionID(dt.actualQuestionTobePatch.id, true);
      //    });
      //}
      //else {
      thisComponent.addOrRemoveQuestionID(this.bookingQues.id, true);
      // }
    }
    if (!(this.dates != undefined && this.dates.length > 0))
      this.QuestionAnswerOutput.emit(this.bookingQues);

  }

  onAdChange() {
    let toolbarConfig = "{ toolbar: [ 'heading', '|', 'bold', 'italic' ] }";
    if (this.isAdvanced) {
      this.toolbarConfig = toolbarConfig
    } else {
      this.toolbarConfig = "{ toolbar: [] }";
    }
  }
  inputChanged({ editor }: ChangeEvent) {
    const data = editor.getData();
    var thisComponent = this;
    this.bookingQues.answer = data;
    this.bookingQues.answeredTime = new (Date);
    if (this.dates != undefined && this.dates.length > 0) {
      this.dates.forEach(function (dt, i) {
        dt.actualQuestionTobePatch.answer = data;
        //  thisComponent.addOrRemoveQuestionID(dt.actualQuestionTobePatch.id, val.target.value != "" || val.target.value != undefined);
      });
    }
    //else {
    thisComponent.addOrRemoveQuestionID(this.bookingQues.id, data != "" || data != undefined);
    //}
    if (!(this.dates != undefined && this.dates.length > 0))
      this.QuestionAnswerOutput.emit(this.bookingQues);
  }

  addOrRemoveQuestionID(bookingId: string, isAnswered: boolean) {
    let index = this.answeredQuestionIds.findIndex(x => x == bookingId);
    if (index >= 0) {
      if (!isAnswered) {
        this.answeredQuestionIds.splice(index, 1)
      }
    } else {
      if (isAnswered) {
        this.answeredQuestionIds.push(bookingId);
      }
    }
  }

  RecveselectedDates(event) {

    console.log('Evenet')
    console.log(event);

    this.displayArrayData.push(event);
    this.DateSelectedOutput.emit(event);
  }
}
