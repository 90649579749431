import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { BookingQuestion, BooleanQuestionAnswer, DateAndTimeQuestionAnswer } from 'src/modules/models/regular-portal/booking/question/booking-question';
import { DateAndTimeQuestion } from 'src/modules/models/regular-portal/booking/question/question';
import { Utility } from 'src/modules/utility';
import * as _ from 'lodash'
@Component({
  selector: 'opt-date-time-question',
  templateUrl: './date-time-question.component.html'
})
export class DateTimeQuestionComponent implements OnInit,AfterViewInit {
  @Input() bookingQues: DateAndTimeQuestionAnswer;
  @Input() dates: any[];
  @Output() QuestionAnswerOutput = new EventEmitter();
  @Output() DateSelectedOutput = new EventEmitter();
  displayArrayData: any[] = [];
  @Input() answeredQuestionIds: string[];
  @Input() questionNumber: number;
  isAnswerDate: boolean;
  isAnswerTime: boolean;
  isAnswerDateAndTime: boolean;
  isValidStart: boolean;
  dateModel;
  defaultDate:any;
  defaultTime:any;
  minuteStep = environment.MinimumBookingDuration ? environment.MinimumBookingDuration : 1;
  constructor() {
    this.isAnswerDate = false;
    this.isAnswerTime = false;
    this.isAnswerDateAndTime = false;

  }
  ngAfterViewInit(): void {
    let element=document.getElementsByClassName('mat-form-field-flex')
    for (let index = 0; index < element.length; index++) {
      const el = element[index];
      el.setAttribute('style','height:36px')

    }

  }
  ngOnInit() {
    var thisComponent = this;
    this.displayArrayData.push(this.dates)
    this.isAnswerDate = (this.bookingQues.question as DateAndTimeQuestion).answerDate;
    this.isAnswerTime = (this.bookingQues.question as DateAndTimeQuestion).answerTime;
    this.isAnswerDateAndTime = (this.bookingQues.question as DateAndTimeQuestion).answerDateAndTime;
    this.defaultDate=_.cloneDeep(this.bookingQues.date);
    if (this.isAnswerDate && this.bookingQues.date == undefined) {
      // this.bookingQues.date = new Date()
    } else {
      thisComponent.addOrRemoveQuestionID(this.bookingQues.id, true);
    }
    if (this.isAnswerTime && this.bookingQues.time == undefined) {
      // this.bookingQues.time = moment(new Date()).toDate();
    } else {
      thisComponent.addOrRemoveQuestionID(this.bookingQues.id, true);
    }
    if (this.isAnswerDateAndTime) {
      if(this.bookingQues.date != undefined) {
        thisComponent.addOrRemoveQuestionID(this.bookingQues.id, true);
      }
      if(this.bookingQues.time != undefined) {
        this.bookingQues.tempTime = moment(this.bookingQues.time).toDate()
        // new Date('1998-01-10 '+this.bookingQues.time)
        thisComponent.addOrRemoveQuestionID(this.bookingQues.id, true);
      }
      // this.bookingQues.date = new Date()
      // this.bookingQues.time = new Date().toLocaleTimeString();
    } 
    if (this.isAnswerTime) {
      if(this.bookingQues.time != undefined) {
        this.bookingQues.tempTime = moment(this.bookingQues.time).toDate()
        thisComponent.addOrRemoveQuestionID(this.bookingQues.id, true);
      }
    }
    this.bookingQues.conditionalBookingQuestions =
    this.checkConditionalQuestions(this.bookingQues.conditionalBookingQuestions)
    if (this.isAnswerDate) {
      this.bookingQues.time = undefined;
    }
    if (!(this.dates != undefined && this.dates.length > 0))
      this.QuestionAnswerOutput.emit(this.bookingQues);
  }
  dateChanged(e) {
    var thisComponent = this;
    // this.bookingQues.time == val.target.value;
    // this.bookingQues.date = Utility.convertToISO(
    //   Utility.convertISOToDate(moment(e.value).toDate())
    // );
    //this.bookingQues.date = moment(e.value).toDate()
    this.bookingQues.date = Utility.convertToISO(Utility.convertISOToDate(moment(e.value).toDate()));
    this.bookingQues.answeredTime = new (Date);
    if (this.dates != undefined && this.dates.length > 0) {
      this.dates.forEach(function (dt, i) {
        dt.actualQuestionTobePatch.date = this.bookingQues.date;
      });
    }
    //else {
    thisComponent.addOrRemoveQuestionID(this.bookingQues.id, this.bookingQues.date != undefined);
    // }
    this.bookingQues.conditionalBookingQuestions =
       this.checkConditionalQuestions(this.bookingQues.conditionalBookingQuestions)
    if (!(this.dates != undefined && this.dates.length > 0))
      this.QuestionAnswerOutput.emit(this.bookingQues);
  }
  clearDate(event) {
    event.stopPropagation();
    this.bookingQues.date = this.defaultDate;
    this.bookingQues.tempTime = this.defaultTime;
}
  closeTimeMenu() {

  }
  toTimestamp(strDate){
    var datum = Date.parse(strDate);
    return datum/1000;
 }
  timeChanged() {
    if (this.bookingQues.tempTime != null) {
      this.bookingQues.time =  Utility.convertDateToTime(this.bookingQues.tempTime)
      var dtObj = Utility.roundToMinDuration(this.bookingQues.tempTime, environment.MinimumBookingDuration);
      if (dtObj.isDateChanged)
        this.bookingQues.time = Utility.convertDateToTime(dtObj.newDate)
    }
    var thisComponent = this;
    this.bookingQues.answeredTime = new (Date);
    if (this.dates != undefined && this.dates.length > 0) {
      this.dates.forEach(function (dt, i) {
        dt.actualQuestionTobePatch.time = this.bookingQues.time;
      });
    }
    //else {
    thisComponent.addOrRemoveQuestionID(this.bookingQues.id, this.bookingQues.time != undefined);
    this.bookingQues.conditionalBookingQuestions =
       this.checkConditionalQuestions(this.bookingQues.conditionalBookingQuestions)
    // }
    if (!(this.dates != undefined && this.dates.length > 0))
      this.QuestionAnswerOutput.emit(this.bookingQues);
  }
  conditionalQuestionAnswerChange(val) {
    if(this.bookingQues.conditionalBookingQuestions && this.bookingQues.conditionalBookingQuestions.length > 0) {
      const i = this.bookingQues.conditionalBookingQuestions.findIndex(a => a.id == val.id && a.question.id === val.question.id);
      if(i !== -1)
        this.bookingQues.conditionalBookingQuestions[i] = val;
        this.bookingQues.answeredTime = new (Date);
      this.QuestionAnswerOutput.emit(this.bookingQues);
    }
  }
  checkConditionalQuestions(conditionalQues: BookingQuestion[]) {
    if (conditionalQues) {
      conditionalQues.forEach(bq => {
        bq.bookingConditionalQuestionAnswers.forEach(ansS => {
          const answerDate = Utility.convertToISO(
            Utility.convertISOToDate(moment(ansS.answerDate).toDate())
          );
          if((!!answerDate && !!ansS.answerTime && answerDate === this.bookingQues.date && ansS.answerTime === this.bookingQues.time)
            || (!!answerDate && answerDate === this.bookingQues.date )
            || (!!ansS.answerTime && ansS.answerTime === this.bookingQues.time)
          ) {
            bq.canDisplayQuestion = true;
          } else {
            bq.canDisplayQuestion = false;
          }
        })
      })
    }
    return conditionalQues
  }
  addOrRemoveQuestionID(bookingId: string, isAnswered: boolean) {
    let index = this.answeredQuestionIds.findIndex(x => x == bookingId);
    if (index >= 0) {
      if (!isAnswered) {
        this.answeredQuestionIds.splice(index, 1)
      }
    } else {
      if (isAnswered) {
        this.answeredQuestionIds.push(bookingId);
      }
    }
  }

  RecveselectedDates(event) {
    this.displayArrayData.push(event);
    this.DateSelectedOutput.emit(event);
  }

}
