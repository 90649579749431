import { BaseResource } from '../../../base-resource';

//import { Booking, BookingStatus } from 'src/modules/models/regular-portal/booking/save-booking/booking';
import { Booking, BookingStatus } from 'src/modules/models/regular-portal/booking/booking';
export class BookingTask extends BaseResource {

    constructor() {
        super();
        this.type = "BookingTask";
    }
    name: string
    taskTypeID: string
    taskType: string
    date: Date
    note: string
    reference: string
    dueDate: Date
    sentDate: Date
    completed: boolean
    readStatus: boolean
    cancelled: boolean
    notification: boolean
    booking: Booking;
    bookingStatus: BookingStatus;
    fromUser: User;
    assignedUsers: User[];
    assignedUserGroups: Group[];
}

export class User extends BaseResource {

}
export class Group extends BaseResource {

}