import { Injectable } from '@angular/core'
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild } from '@angular/router'
import { Observable } from 'rxjs'
import { ICart } from '../store/cart';
import { environment} from '../../environments/environment';
import { AppSetting, MenuSettings } from '../models/settings/casual-portal/app-setting';
import { Store } from '@ngrx/store';

@Injectable({
    providedIn: 'root'
})

export class AuthGuard implements CanActivate {
    isLoggedIn: boolean;
    clientCategory: number;
    isAgent: boolean = false;
    cart$: Observable<ICart>;
    appSetting: AppSetting;

    constructor(private router: Router, private store: Store<any>) {
        this.cart$ = this.store.select("cart");
         this.cart$.subscribe((state) => {
            this.isLoggedIn = state.contact != null && state.contact.firstName != null && state.contact.firstName != "" ? true : false;
            this.clientCategory = state.contact && state.contact?.client && state.contact.client?.clientCategoryId ? state.contact.client?.clientCategoryId : null;
         });
        this.appSetting = environment.AppSetting as AppSetting;
        //get login client was agent or not
        let menu: MenuSettings = this.appSetting.MenuSettings ? this.appSetting.MenuSettings.find(menu => menu.Id == 'PublicBooking') : null;
        if (menu && this.clientCategory) {
            this.isAgent = !!menu?.BookingPermittedClientCategoryIds ? menu?.BookingPermittedClientCategoryIds?.Value?.split(',').includes(this.clientCategory.toString()) : false
        }
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean | Promise<boolean> {
        let currentRoute = route.url.join('/');
        if (environment.LoggedInUser == '' && (this.appSetting.ClientLoginIsRequired && !this.isLoggedIn)) {
            window.open(
                environment.ApiUrl + "#/client/login",
                "_self"
            );
            return false;
        } else if (environment.PortalType === '4' && this.appSetting.ClientLoginIsRequired && !this.isAgent) {
            if (currentRoute == 'bookings' || 
                currentRoute == 'booking-cart' || 
                currentRoute == 'my-profile' ||
                currentRoute == 'change-password') {
                return true;
            } else {
                window.open(
                    environment.ApiUrl + "#/bookings", 
                    "_self"
                );
                return false;
            }
        }
        else
            return true;
    }
}
