<mat-expansion-panel [expanded]="true" [disabled]="true">
  <mat-expansion-panel-header  class="mat-header">
    <opt-question-header [dates]="dates" [isMandatory]="bookingQues?.question?.mandatory"
      [questionNumber]="questionNumber" [questionText]="bookingQues?.question?.questionText"
      (popselectedDates)="RecveselectedDates($event)"></opt-question-header>
  </mat-expansion-panel-header>
  <div class="wp_flex--container flex-column">
    <div class="wp_flex__item--inherit wp_flex__item--vcenter flex-column" style="align-items: flex-start;">
      <mat-radio-group class="mr-2" aria-label="Select an option"
        *ngFor="let ansChoice of bookingQues?.bookingQuestionChoices; let j=index" [(ngModel)]="selectedAnswer"
        (ngModelChange)="selectedAnswChoice($event,ansChoice,j)">
        <!--<mat-radio-group aria-label="Select an option" *ngFor="let ansChoice of bookingQues?.question?.answerChoices; let j=index">-->
        <mat-radio-button [value]="ansChoice.id">{{ansChoice.name}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</mat-expansion-panel>
<div class="wp_flex--container flex-column question-list " class="mt-4 conditional-question">
  <div class="wp_flex__item--inherit wp_flex__item--vcenter">
    <opt-question class="w-100" [isMandatory]="false" [questionNumber]="1"
      [questions]="bookingQues.conditionalBookingQuestions" [answeredQuestionIds]="answeredQuestionIds"
      (questionAnswerOutput)="conditionalQuestionAnswerChange($event)"></opt-question>
  </div>
</div>
