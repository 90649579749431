import { Component } from '@angular/core';

@Component({
    selector: 'app-facility-search',
    templateUrl: './facility-search.component.html'
})

export class FacilitySearchComponent {
    constructor() { }
}
