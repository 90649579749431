import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Store, ICart, AddDocument, DeleteDocument } from '../../../store/index';
import { Observable, Subscription } from "rxjs";
import { AdHocDocument } from 'src/modules/models/booking/adhoc-document'

@Component({
    selector: 'opt-doc-modal',
    templateUrl: './doc-modal.component.html'
})

export class DocModalComponent implements OnInit, OnDestroy {
    selectedFiles: AdHocDocument[] = [];
    cart$: Observable<ICart>;
    private subscriptions = new Subscription();

    constructor(public dialogRef: MatDialogRef<DocModalComponent>, private store: Store<any>, @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        this.cart$ = this.store.select('cart');
        this.subscriptions.add(this.cart$.subscribe(state => {
            if (state != undefined) this.selectedFiles = state.AddedDocuments;
        }));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();

    }

    onFileChanged(event) {
        this.store.dispatch(new AddDocument(event.target.files[0]));
    }

    remove(data: AdHocDocument) {
        this.store.dispatch(new DeleteDocument(data));
    }

    bytesToSize(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return 'N/A';
        var i = +(Math.floor(Math.log(bytes) / Math.log(1024)));
        if (i == 0) return bytes + ' ' + sizes[i];
        return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
    }

    public close() {
        this.dialogRef.close();
 
    }
}
