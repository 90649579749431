import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import "jquery";
import "signalr";
import { Store } from '@ngrx/store';
import { CheckSeat, SetAllDeselectSeat, SetAllSeats, SetContactID, SetDeselectSeat, SetSeat } from '../../../store/public-web/public-web-actions';
import { PBReducer } from 'src/modules/store/public-web/public-web-reducers';
declare var $: any;

@Injectable({
  providedIn: 'root'
})

export class SignalRService {

  connection: any;
  proxy: any;
  isConnectionStarted = false;
  activeUserId = '';
  connectionId = '';
  selectedSeats = [];
  enablePublicWebConcurrencySignalR: boolean;

  constructor(private store: Store<any>) {
    this.enablePublicWebConcurrencySignalR = environment.EnablePublicWebConcurrencySignalR;
    this.store.select('PBReducer').subscribe((state) => {
      if (state.allSeats) {
        this.selectedSeats = state.allSeats;
      }
    });
  }

  public connectSignalRHub(): void {
    let url = environment.NotificationProjectUrl;
    if (this.connectionId == '') {
      this.connection = $.hubConnection(url);
      this.proxy = this.connection.createHubProxy('BookingApplicationConcurrencyHub');

      this.proxy.on("sendSelectedSeats", (data) => {
        this.store.dispatch(new SetSeat(data));
      });

      this.proxy.on("sendAlreadySelectedSeat", (data) => {
        this.store.dispatch(new CheckSeat(data));
      });

      this.proxy.on("getAllSeats", (data) => {
        this.store.dispatch(new SetAllSeats(data));
        if (this.selectedSeats) {
          this.selectedSeats.forEach(seat => {
            if (!data.includes(seat)) {
              this.store.dispatch(new SetDeselectSeat(seat));
            }
          });
        }
      });

      this.proxy.on("getAllDeSelectSeats", (data) => {
        this.store.dispatch(new SetAllDeselectSeat(data));
      });

      this.proxy.on("sendDeSelectedSeats", (data) => {
        this.store.dispatch(new SetDeselectSeat(data));
      });

      this.connection.start().done(() => {
        this.isConnectionStarted = true;
        this.connectionId = this.connection?.id;
        if (this.connectionId != '') {
          console.log('Connection established successfully...');
        }
      }).fail(() => {
        this.isConnectionStarted = false;
      });
      this.connection.disconnected(function () {
        console.log('Disconnected...', this.connectionID);
      });
    }
  }

  //seatNumber : sessionID_ConfigID_blockID_RowNumber_ColNumber
  public sendSeatDetails(userId: string, seatNumber: String) {
    if (this.connectionId != '') {
      this.proxy.invoke('RegisterSeatDetails', userId, this.connectionId, seatNumber)
        .done(() => {
        })
        .catch((error: any) => {
        });
    }
  }
  public changeUserName(userId: string){
    if (this.connectionId != '') {
      this.proxy.invoke('ChangeUserName',this.activeUserId, userId, this.connectionId)
        .done(() => {
          this.store.dispatch(new SetContactID(userId));
        })
        .catch((error: any) => {
        });
    }
  }

  public registerUser(userId: string) {
    if (this.connectionId != '' && this.activeUserId == '') {
      this.proxy.invoke('RegisterUser', userId, this.connectionId).done(() => {
          this.activeUserId = userId;
        }).catch((error: any) => {
        });
    } else {
      setTimeout(() => {
        if(this.connectionId != '' && this.activeUserId != ''){
          this.proxy.invoke('GetAllSeats', this.connectionId)
          .done(() => {
          })
          .catch((error: any) => {
          });
        }
        if (this.enablePublicWebConcurrencySignalR) {
          this.connectSignalRHub();
          this.registerUser(userId);
        }
      }, 90000);
    }
  }
  getAllSeats(userID){
    if (this.connectionId != '') {
      this.proxy.invoke('GetAllSeats', this.connectionId, userID)
        .done(() => {
        })
        .catch((error: any) => {
        });
    }
  }
  deRegisterSeat(seatNumber: String) {
    if (this.connectionId != '') {
      this.proxy.invoke('DeRegisterSeat', seatNumber).done(() => {

        }).catch((error: any) => {

        });
    }
  }

  bulkDeRegisterSeats(seatNumbers: String[]) {
    if (this.connectionId != '') {
      this.proxy.invoke('DeRegisterSeatBulkUpdate', seatNumbers)
        .done(() => {
          return true
        })
        .catch((error: any) => {
        });
    }
  }

  RegisterBookedSeat(existingSeatNumber: String[], bookedSeatNumber: String[]) {
    if (this.connectionId != '') {
      this.proxy.invoke('RegisterBookedSeat', existingSeatNumber, bookedSeatNumber)
        .done(() => {
        })
        .catch((error: any) => {
        });
    }
  }
}
