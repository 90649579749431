<div class="mmc-modal-wrapper guest-checkout-modal">
  <div class="mmc-modal-header mmc_flex--container g-header">
    <div class="mmc_flex__item mmc_flex__item--vcenter pl-3">
      <span  class="pw-txt-h3_small pw-text-semi-bold g-info" style="color: #ffffff; font-size:20px" >{{"GUESTCHECKOUTINFO.Guest Information" | optimotranslate}}</span>
    </div>
    <div class="close-btn" style="align-items:center;display:flex;padding-right: 8px;">
      <mat-icon style="color: white;display: flex;align-items: center;cursor: pointer;"(click)="dialogRef.close()">close</mat-icon>
    </div>
  </div>
  <div class="mmc-modal-body p-2" style="padding:unset !important;">
    <form [formGroup]="individuallientSaveForm" autocomplete="off" class="form-container">
      <div class=" mmc_corporate client_wrapper individual_customer guest-wrapper">
        <section >
          <div class="font__semibold title">
            <div class="name">
              <div class="name__inner desktop pw-txt-h3_small pw-text-semi-bold" style="background-color: #fff;">{{"GUESTCHECKOUTINFO.Customer Details" | optimotranslate}}</div>
              <div class="name__inner mobile pw-txt-h3_small pw-text-semi-bold" style="background-color: #fff;">{{"GUESTCHECKOUTINFO.Individual Customer Details" | optimotranslate}}</div>
            </div>
          </div>
          <div class="row">
            <!-- <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6"> -->
              <!-- <div class="row"> -->
                <!-- <div class="col-sm-4 col-md-4 col-lg-3 remove_right_padding">
                  <mat-form-field appearance="outline">
                    <mat-label>{{SelectedTitleName}}</mat-label>
                    <mat-select placeholder={{SelectedTitleName}}>
                      <mat-option *ngFor="let title of Titles" (click)="SelectTitle(title.id)" (onSelectionChange)="SelectTitle(title.id)">{{title.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div> -->
                <!--remove_left_padding-->
                <div class="col-sm-12 col-md-12 col-lg-12 user-name" style="padding-right:10px;">
                  <mat-form-field appearance="outline">
                    <!-- TASK: Remove Validations Other Than Email , Name , Contact No -->
                    <!-- <mat-label>First Name</mat-label> -->
                    <!-- TASK: Remove Validations Other Than Email , Name , Contact No -->
                    <mat-label>{{"GUESTCHECKOUTINFO.Name" | optimotranslate}}</mat-label>
                    <input matInput placeholder="" calss="form-control" formControlName="FirstName" required autocomplete="off">
                    <mat-hint></mat-hint>
                  </mat-form-field>
                    <!-- </div> -->
                  <!-- </div> -->
                </div>
            
            <!-- TASK: Remove Validations Other Than Email , Name , Contact No -->
            <!-- <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <mat-form-field appearance="outline">
                <mat-label>Last Name</mat-label>
                <input matInput placeholder="" calss="form-control" formControlName="LastName" required autocomplete="off">
                <mat-hint></mat-hint>
              </mat-form-field>
            </div> -->
            <!-- END TASK: Remove Validations Other Than Email , Name , Contact No -->
          </div>

            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-right:10px;">
                <!-- <div class="mmc_flex--container contact-detail"> -->
                  <!-- <div class="mmc_flex__item--inherit drop-area">
                    <mat-form-field appearance="outline">
                      <mat-label>{{SelectedPhoneTypeName}}</mat-label>
                      <mat-select placeholder="">
                        <mat-option *ngFor="let comTypeAdPhone of CommunicationTypesPhone" (click)="SelectComType(comTypeAdPhone.id, 1, false)" (onSelectionChange)="SelectComType(comTypeAdPhone.id, 1, false)">{{comTypeAdPhone.name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div> -->
                  <!-- <div class="mmc_flex__item"> -->
                    <mat-form-field appearance="outline">
                      <mat-label>{{SelectedPhoneTypeName | optimotranslate}}</mat-label>
                      <input matInput placeholder="{{placholderSampleNumber}}" calss="form-control" formControlName="PersonalPhoneNumber" required autocomplete="off">
                      <mat-error *ngIf="individuallientSaveForm.controls.PersonalPhoneNumber.errors !== null && individuallientSaveForm.controls.PersonalPhoneNumber.touched">{{"GUESTCHECKOUTINFO.Please enter valid" | optimotranslate}} {{SelectedPhoneTypeName|lowercase }} {{"GUESTCHECKOUTINFO.number" | optimotranslate}}.</mat-error>
                    </mat-form-field>
                  </div>
                <!-- </div>
              </div> -->
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-right:10px;">
                <div class="mmc_flex--container contact-detail">
                  <!-- <div class="mmc_flex__item--inherit drop-area">
                    <mat-form-field appearance="outline">
                      <mat-label>{{SelectedEmailTypeName}}</mat-label>
                      <mat-select placeholder="">
                        <mat-option *ngFor="let comTypeAdEmail of CommunicationTypesEmail" (click)="SelectComType(comTypeAdEmail.id, 2, false)" (onSelectionChange)="SelectComType(comTypeAdEmail.id, 2, false)">{{comTypeAdEmail.name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div> -->
                  <div class="mmc_flex__item">
                    <mat-form-field appearance="outline">
                      <mat-label>{{SelectedEmailTypeName | optimotranslate}}</mat-label>
                      <input matInput (blur)="EmailAdressChanged($event.target.value)" placeholder="" calss="form-control" formControlName="PersonalEmail" required autocomplete="new-password">
                      <div matSuffix *ngIf="isLoading" class="loader"></div>
                      <mat-error *ngIf="individuallientSaveForm.controls.PersonalEmail.errors !== null && individuallientSaveForm.controls.PersonalEmail.touched">{{'GUESTCHECKOUTINFO.Please enter valid'| optimotranslate}} {{SelectedEmailTypeName |optimotranslate |lowercase}}.</mat-error>
                      <mat-error *ngIf="individuallientSaveForm.controls.PersonalEmail.errors?.serverError">
                        {{ individuallientSaveForm.controls.PersonalEmail.errors?.serverError }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
          </div>
          <style>
            .check-icon-green {
              color: #3d9b47;
              padding-right: 10px;
            }

            .check-icon-red {
              color: #dc3545;
              padding-right: 10px;
            }
          </style>
        <!-- TASK: Remove Validations Other Than Email , Name , Contact No -->
          <!--user name-->
          <!-- <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <mat-form-field appearance="outline">
                <mat-label>Username</mat-label>
                <input matInput placeholder="" calss="form-control" formControlName="UserName" required autocomplete="off">
                <mat-hint></mat-hint>
              </mat-form-field>
            </div>
          </div> -->
          <!--END TASK: Remove Validations Other Than Email , Name , Contact No -->
        </section>
        <!-- TASK: Remove Validations Other Than Email , Name , Contact No -->
        <!-- <section [ngClass]="loadingStyle ? 'effect-show' : ''">
          <div class="font__bold title">
            <div class="name">
              <div class="name__inner pw-txt-h3_small pw-text-semi-bold">Address</div>
            </div>
          </div>

          <div class="individual-additinal2">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <mat-form-field appearance="outline">
                  <mat-label>Address Line 1</mat-label>
                  <input matInput maxlength="100" required [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Address?.AddressLine1 calss="form-control" formControlName="Address_Line1" [(ngModel)]="Address_Line1">
                  <mat-hint></mat-hint>
                </mat-form-field>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <mat-form-field appearance="outline">
                  <mat-label>Address Line 2</mat-label>
                  <input matInput maxlength="100" [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Address?.AddressLine2  calss="form-control" formControlName="Address_Line2" [(ngModel)]="Address_Line2">
                  <mat-hint></mat-hint>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <mat-form-field appearance="outline">
                  <mat-label>Address Line 3</mat-label>
                  <input matInput maxlength="100" [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Address?.AddressLine3 calss="form-control" formControlName="Address_Line3" [(ngModel)]="Address_Line3">
                  <mat-hint></mat-hint>
                </mat-form-field>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <mat-form-field appearance="outline">
                  <mat-label>City</mat-label>
                  <input matInput maxlength="100" [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Address?.City calss="form-control" formControlName="Address_City" [(ngModel)]="Address_City">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <mat-form-field appearance="outline">
                  <mat-label class="profile-before">State</mat-label>
                  <input matInput maxlength="10" [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Address?.State calss="form-control" formControlName="Address_CountyCode" [(ngModel)]="Address_CountyCode">
                  <mat-hint></mat-hint>
                </mat-form-field>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <mat-form-field appearance="outline">
                  <mat-label>Post Code</mat-label>
                  <input matInput maxlength="50" [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Address?.PostCode calss="form-control" formControlName="Address_PostCode" [(ngModel)]="Address_PostCode">
                  <mat-hint></mat-hint>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6"> -->
              <!--END TASK: Remove Validations Other Than Email , Name , Contact No -->
                <!-- <mat-form-field appearance="outline">
                  <mat-label>Country</mat-label>
                  <input matInput maxlength="100" [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Address?.Country calss="form-control" formControlName="Address_Country" [(ngModel)]="Address_Country">
                </mat-form-field> -->
              <!-- TASK: Remove Validations Other Than Email , Name , Contact No -->
                <!-- <mat-form-field appearance="fill">
                  <mat-label>Country</mat-label>
                  <mat-select [placeholder]="appData?.ClientRegistrationConfig?.Placeholder?.Address?.Country" formControlName="Address_Country" [(ngModel)]="Address_Country">
                    <mat-option *ngFor="let country of countries" [value]="country.name">
                      {{country.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>

        </section> -->
        <!-- END TASK: Remove Validations Other Than Email , Name , Contact No -->
        
        <!-- <div class="privacy-policy pw-txt-b1">
          <mat-checkbox class="pw-txt-b1" [checked]="isChecked" (change)="onChange($event)">{{agreedText}}<a class="pw-txt-b1" target="_blank" rel="noopener noreferrer" href="{{url}}"> Privacy Policy.</a></mat-checkbox>
        </div>
        <div class="recaptcha mmc_flex--container" >
          <ngx-recaptcha2 #cap123 [siteKey]="siteKey"
                          [size]="size"
                          [hl]="lang"
                          [theme]="theme"
                          [type]="type"
                          (expire)="handleExpire()"
                          (load)="handleLoad()"
                          (success)="handleSuccess($event)"
                          formControlName="recaptcha1" id="456">
          </ngx-recaptcha2>
        </div> -->
      </div>
    </form>

  </div>
  <div class="mmc-modal-footer p-2"  style="padding-right: 16px !important;">
    <div class="mmc_flex--container mmc_just-content-end">
      <button mat-button class= " mmc-btn-primary mr-2 b-cancel"  (click)="dialogRef.close()">
        {{"GUESTCHECKOUTINFO.Cancel" | optimotranslate}}
      </button>
      <button mat-button type="submit" class=  "mmc-btn-secondary b-save " (click)="SaveIndividualClient()" [disabled]="!individuallientSaveForm.valid || isLoading">
        {{"GUESTCHECKOUTINFO.Save" | optimotranslate}}
      </button>
    </div>
  </div>
