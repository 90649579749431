import { Component, OnInit, Input, Inject, Output, EventEmitter } from '@angular/core';

///Date
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { MomentDateAdapter } from 'src/modules/booking-portal/common/date-time-adapter/moment-date-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
    parse: { dateInput: 'LL' },
    display: {
        dateInput: 'DD MMM YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'opt-question-header',
    templateUrl: './question-header.component.html',
    providers: [
        // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
        // application's root module. We provide it at the component level here, due to limitations of
        // our example generation script.
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
    ]
})
export class QuestionHeaderComponent implements OnInit {
    @Input() name: string; //item name, package name
    //@Input() startTime: Date;
    //@Input() endTime: Date;
    @Input() questionNumber: number;
    @Input() questionText: string;
    @Input() dates: any[];
    @Input() isMandatory: boolean;

    @Output() popselectedDates = new EventEmitter();

    //Mandatory
    mandatorySign: string;

    //Dates
    startTime: Date;
    endTime: Date;
    year: string;
    startingMonth: string;
    endingMonth: string;
    date: string;
    startDate: string;
    endDate: string;
    datesList: string[];
    monthsList: string[];
    yearsList: string[];
    yearMonthList: any[];

    //Dates Selections
    isAllSelected: boolean = true;
    isYearSelected: boolean;
    isMonthSelected: boolean;
    istartingMonthSelected: boolean;
    selectedDates: any[];
    selectedDateList: any[];
    selectedDatess: any[] = [];
    constructor() { }
    //constructor(public dialogRef: MatDialogRef<WpPopupsComponent>, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any ) { }

    ngOnInit() {

        if (this.isMandatory == true) {
            this.mandatorySign = "*";
        }


        if (this.dates != undefined) {
            this.startTime = moment(this.dates[0].startTime).toDate();
            this.endTime = moment(this.dates[this.dates.length - 1].endTime).toDate();


            //YEARS
            this.year = moment(this.startTime).format('YYYY');
            this.getYearMnthsArray(this.startTime, this.endTime);

            //MONTHS
            this.startingMonth = moment(this.startTime).format('MMMM');
            this.endingMonth = moment(this.endTime).format('MMMM');

            //this.getMonthsArray(this.startTime, this.endTime);


            //DATES
            this.date = moment(this.startTime).format('Do MMM');
            this.getDaysArray();
            this.selectAll({ checked:true});
        }
    }


    getDaysArray() {
        var arr = []
        this.dates.forEach((d, i) => {
            arr.push(moment(new Date(d.startTime)).format('Do MMM YYYY'));
        });

        this.datesList = arr;
    };


    getYearMnthsArray(startDate, endDate) {
        console.log('getYearMnthsArray');

        var arr = [];
        this.dates.forEach((d, i) => {
            var dtm = moment(new Date(d.startTime)).format('YYYY MMMM');
            var dt = moment(new Date(d.startTime)).format('Do MMM YYYY');
            let month = arr.find(x => x.month == dtm);
            if (!month) {
                arr.push({ month: dtm, dates: [dt] });
            } else {
                month.dates.push(dt);
            }
        });
        this.yearMonthList = arr;
    };



    selectedDatesChanged(val, date) {
        //  console.log('SELECTED val ---->', val.target.value)
        console.log('[Dates] ARRAY=>', this.dates);
        // console.log('SELECTED DATE ---->', date)
        //   console.log('SELECTED index ---->', index)

        if (this.selectedDatess.length > 0) {
            var findselectDate = this.selectedDatess.find(x => x == date);
            var index = this.selectedDatess.findIndex(x => x == date);

            if (val.checked) {
                if (findselectDate) {
                    //  this.selectedDates.splice(index, 0);
                } else {
                    let passData = {

                        actualQuestionTobePatch: this.dates[0].actualQuestionTobePatch,
                        startTime: this.dates[0].startTime,
                        endTime: this.dates[0].endTime,
                        Date: date
                    }
                    this.selectedDatess.push(date)
                    // this.selectedDatess.push(passData)
                }

            } else {

                if (findselectDate) {
                    if (index < 0) {
                    }
                    else {
                        this.selectedDatess.splice(index, 1);
                    }
                }
            }
        } else {

            this.selectedDatess.push(date);
        }
    }

    isSameMonth(date, month) {
        return moment(date, "Do MMM YYYY").format('YYYY MMMM') == month;
    }

    selectAll(val) {
        var thisComponent = this;
        var selectedMonthDates = this.datesList;
        if (val.checked) {
            if (selectedMonthDates.length > 0) {
                selectedMonthDates.forEach((d, i) => {
                    let index = this.selectedDatess.findIndex(x => x == d);
                    if (index < 0) {
                        thisComponent.selectedDatess.push(d)
                    }
                });
            }

        } else {
            if (selectedMonthDates.length > 0) {
                selectedMonthDates.forEach((d, i) => {
                    let index = this.selectedDatess.findIndex(x => x == d);
                    if (index >= 0) {
                        thisComponent.selectedDatess.splice(index, 1)
                    }
                });
            }
        }
    }

    selectedMonthChanged(val, month) {
        var thisComponent = this;
        var selectedMonthDates = this.datesList.filter(dt => moment(dt,"Do MMM YYYY").format('YYYY MMMM') == month)
        if (val.checked) {
            if (selectedMonthDates.length > 0) {
                selectedMonthDates.forEach((d, i) => {
                    let index = this.selectedDatess.findIndex(x => x == d);
                    if (index < 0) {
                        thisComponent.selectedDatess.push(d)
                    }
                });
            }

        } else {
            if (selectedMonthDates.length > 0) {
                selectedMonthDates.forEach((d, i) => {
                    let index = this.selectedDatess.findIndex(x => x == d);
                    if (index >= 0) {
                        thisComponent.selectedDatess.splice(index, 1)
                    }
                });
            }
        }
    }
    onClickSave() {
        var thisComponent = this;
        console.log('[Dates]=>', this.dates);
        console.log('[Dates]=>', this.selectedDatess);
        if (this.dates && this.selectedDatess && this.selectedDatess.length>0 && this.selectedDatess.length != this.date.length) {
            var datesListArray = this.datesList;
            //  datesList  //All the dates in the Dates Popup
            //   this.selectedDatess ///selecteed dates
            var deleteitem = [];
            datesListArray.forEach((d, i) => {
                let index = this.selectedDatess.findIndex(x => x == d);
                if (index < 0) {
                    deleteitem.push(d)
                }
            });

            if (deleteitem.length > 0) {
                deleteitem.forEach((d, i) => {
                    thisComponent.yearMonthList.forEach((ym, i) => {
                        let index = ym.dates.findIndex(x => x == d);
                        if (index >= 0) {
                            ym.dates.splice(index, 1);
                        }
                    });
                });
                this.popselectedDates.emit(deleteitem);
            }
        }
    }
}








