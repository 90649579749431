import { Directive, Input, HostBinding, NgModule } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
    selector: '[appFormControlValidator]'
})
export class FormControlValidatorDirective {
    @Input() control: AbstractControl;

    @HostBinding('class.invalid') get isInvalid() {
        if(this.control){
            return this.control.invalid && (this.control.dirty || this.control.touched);
        }
    }

    @HostBinding('class.valid') get isValid() {
        if(this.control){
            return this.control.valid && (this.control.dirty || this.control.touched);
        }
    }

    @HostBinding('class.touched') get isTouched() {
        if(this.control){
            return this.control.touched;
        }
    }
}
  