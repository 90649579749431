<html itemscope itemtype="http://schema.org/QAPage" class="html__responsive">
<head>
    <title>Manningham Error</title>
</head>
<body>
    <div class="man-con-page">
        <div class="">
            <div>
                <div class="man-logo">
                    <img class="man-img"
                         src="http://5.39.12.72/MC_PublicWeb_QA/MCC.PublicWeb/QA/dist/assets/images/logo.png" />
                </div>

                <div class="man-head-text">OOPS</div>
                <div class="man-head-texT-1">404-PAGE NOT FOUND</div>
            </div>
        </div>
    </div>
</body>
 </html>  
