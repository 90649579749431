import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'opt-existing-guest-mail',
  templateUrl: './existing-guest-mail.component.html'
})
export class ExistingGuestMailComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ExistingGuestMailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }
  login() {
    this.dialogRef.close({login:true,forgetPassword: false})
  }
  forgetPassword() {
    this.dialogRef.close({login:false,forgetPassword: true})
  }
}
