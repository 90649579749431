import { BaseResource } from '../base-resource';

export class RecurrenceOption extends BaseResource {
    type: string;
    id: string;
    startTime: string;
    endTime: string;
    recurrenceStartDate: string;
    recurrenceEndDate: string;
    occurrences: number;
    recurrenceOptionId: number;
    //recurrenceOptionID: number;
    recurrenceWeekDays: string;
    recurrenceDate: number;
    recurrenceMonth: number;
    recurrenceOptionType: number;
    recurrenceEndAfter: number;
    dateType: number;
    assets: string;   
    description: any;
    excludeDates: string[];
    constructor() {
        super();
        this.type = "RecurrenceOption";
    }
}