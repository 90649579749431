import { WMBookingStatusFontColor, WMBookingStatusTypeId, WMEditOrViewType } from '../models/waste-management/enum';
import { environment } from 'src/environments/environment';
import { WasteBookingSettings } from '../models/settings/waste-booking/waste-booking-setting';
import moment from 'moment';

export class WmUtility {
  private static pkgMinDate: any;


  static conCatAddress(address) {

    let state;
    if (address.StateCode && address.StateCode !== '') {
      state = address.StateCode;
    } else if (address.State && address.State !== '') {
      state = address.State;
    } else if (address.County && address.County !== '') {
      state = address.County;
    }

    let str = '';
    if (address.Address1) {
      if (str.length > 0)
        str += ' ';
      str += String(address.Address1);
    }
    if (address.Address2) {
      if (str.length > 0)
        str += ' ';
      str += String(address.Address2);
    }
    if (address.Address3) {
      if (str.length > 0)
        str += ' ';
      str += String(address.Address3);
    }
    if (address.City) {
      if (str.length > 0)
        str += ' ';
      str += String(address.City);
    }
    if (state) {
      if (str.length > 0)
        str += ' ';
      str += String(state);
    }
    if (address.PostCode) {
      if (str.length > 0)
        str += ' ';
      str += String(address.PostCode);
    }
    return str.trim();
  }

  static compareBookingPackagesDate(packages) {
    if (packages && packages.length > 1) {
      let res: boolean;
      for (let i = 1; i < packages.length && (packages.length > 1); i++) {
        // tslint:disable-next-line: max-line-length
        if (packages[0].startDate) {
          // tslint:disable-next-line: max-line-length
          res = new Date(packages[0].startDate).setHours(0, 0, 0, 0).valueOf() === new Date(packages[i].startDate).setHours(0, 0, 0, 0).valueOf();
        } else {
          // tslint:disable-next-line: max-line-length
          res = new Date(packages[0].StartDate).setHours(0, 0, 0, 0).valueOf() === new Date(packages[i].StartDate).setHours(0, 0, 0, 0).valueOf();

        }
      }
      return res;
    }
  }

  static getPackageStatusColor(status, collectionStatuses?) {
    if (status ==  WMBookingStatusTypeId.CANCELLED) {
      return WMBookingStatusFontColor.CANCELLED;
    } else {
      return this.getStatusColor(status, collectionStatuses);
    }
  }

  static getStatusColor(statusId, collectionStatuses) {
    const status = collectionStatuses && collectionStatuses.find(status => status.Id === statusId);
    if (status) {
      return status.ColorCode;
    }
  }

  static getViewOrEdit(dataArr, type, serverDate?, from?) {
    const currentDate = new Date(serverDate);
    const pkgDates = [];
    let maxDate = null;

    switch (type) {
      case WMEditOrViewType.BOOKING: // booking list view or edit
        if (dataArr.bookingPackages && dataArr.bookingPackages.length > 0) {
          dataArr.bookingPackages.forEach(pkg => {
            pkgDates.push(pkg.startDate);
          });
        }
        break;
      case WMEditOrViewType.PACKAGE: // when open the cart via edit/view button booking editable/view should check using packages
        if (dataArr && dataArr.length > 0) {
          dataArr.forEach(pkg => {
            pkgDates.push(pkg.startDate);
          });
        }
        break;
      case WMEditOrViewType.DATE:  // cart page package level edit or view
        maxDate = dataArr.startDate;
        break;
      default:
        break;
    }

    if (pkgDates && pkgDates.length > 0) {
      if (pkgDates.length === 1) {
        maxDate = pkgDates[0];
      } else {
        pkgDates.sort(function (a, b) {  // if multiple package dates exist have to get maximum date and identify booking can editable
          // @ts-ignore
          return new Date(b) - new Date(a);
        });

        maxDate = pkgDates[0];
      }
    }

    currentDate.setHours(0, 0, 0, 0);
    currentDate.setHours(48);


    // true = EDit ; False = VIew
    if (dataArr.isCancelled) {
      return false;
    } else {
      const max = new Date(maxDate).setHours(0, 0, 0, 0);
      this.pkgMinDate = maxDate;
      return max > currentDate.getTime();
    }

  }

  static returnPkgMinDate() {
    if (this.pkgMinDate)
      return this.pkgMinDate;
  }

  static imagePath() {
    return environment.ApiUrl + environment.publishFolder;
  }

  static isWasteImageUploadEnable() {
    const enable = (environment.WasteBookingSetting as WasteBookingSettings).bookingSetting.imageUploadSupportForWasteCollection;
    if (enable === undefined) {
      return true;
    } else {
      return (/true/i).test(enable);
    }
  }

  static sortDates(a, b) {
    return a.getTime() - b.getTime();
  }

  static includes(text: any, searchString: string) {
    return text.indexOf(searchString) >= 0;
  }

  static isMissedCollectionNotResolved(resolvedStatusId) {
    return resolvedStatusId === '1' || resolvedStatusId === '2' || resolvedStatusId === null ? false : true;
  }

  static ValidateAddressCacheExpiry(date, systemDate) {
    const endTime = moment(date).add(environment.addressCacheExpiryInHrs, 'hours');
    return endTime >= systemDate; // false API call again
  }

  static packageCollectionDateSessionCheck(dataArr, serverDate) { // while submit the booking check all the package dates > 48 hrs from current time
    const currentDate = new Date(serverDate);
    const pkgDates = [];
    let minDate = null;

    if (dataArr && dataArr.length > 0) {
      dataArr.forEach(pkg => {
        pkgDates.push(pkg.startDate);
      });
    }

    if (pkgDates && pkgDates.length > 0) {
      if (pkgDates.length === 1) {
        minDate = pkgDates[0];
      } else {
        pkgDates.sort(function (a, b) {
          // @ts-ignore
          return new Date(a) - new Date(b);
        });
        minDate = pkgDates[0];
      }
    }

    currentDate.setHours(0, 0, 0, 0);
    currentDate.setHours(48);

    const min = new Date(minDate).setHours(0, 0, 0, 0);
    this.pkgMinDate = minDate;
    return min > currentDate.getTime();

  }
}
