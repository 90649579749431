<div class="header__user sub-header-user mobile-header" style="">
    <nav class="navbar-light bg--light">
        <!--<button class="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarNav"
              aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <i class="fa fa-bars toggle-icon font__sz--32" aria-hidden="true"></i>
      </button>-->
        <div class="navbar-collapse" id="navbarNav">
            <div class="container user border__r ">
                <div class="row align--center">
                    <div class="col-12 p-0 header__alter-menu">
                        <div [ngClass]="{'sub-header-nav-left': isPublicBookingPortal, 'col-3 col-md-6 d--flex align--center sub_left': !isPublicBookingPortal}">
                            <div class="wp-mr-20" *ngIf="getMenuVisibility('Home')">
                                <!-- header-nav-venue -->
                                <span *ngIf="!isPublicBookingPortal" class="wp-txt-body1--small wp-txt--link header-nav wm-go-home" tabindex="0"
                                    (click)="RedirectToPortal()" (keydown.enter)="RedirectToPortal()" [class.link-disabled]="!((cart$|async)?.contact?.id && (cart$|async)?.contact?.name) && isInternalUser"
                                    role="button">{{isWasteBookingPortal ? "" : (getMenuName('Home' , 'Home') |
                                    optimotranslate)}}</span>
                                <span *ngIf="isPublicBookingPortal">
                                    <i class="material-icons home-icon" style="cursor: pointer; margin-left:15px" (click)="RedirectToPortal()" (keydown.enter)="RedirectToPortal()"
                                    [class.link-disabled]="!canAccess"> home </i>
                                </span>
                            </div>

                            <div class="waste-header-flex" *ngIf="!isWasteBookingPortal && !isPublicBookingPortal">
                                <div *ngIf="showHomeIcon && isDisplayCasualHireLink && !isInternalUser"
                                    class="wp-mr-20 casual-hire">
                                    <span class="wp-txt-body1--small wp-txt--link header-nav-venue" tabindex="0"
                                        (click)="venueSearch()" (keydown.enter)="venueSearch()">{{'NAVBAR.New Casual
                                        Hire' | optimotranslate}}</span>
                                </div>
                                <div *ngIf="isDisplayRegularHireLink && !isInternalUser" class="regular-hire">
                                    <span class="wp-txt-body1--small wp-txt--link header-nav-rb" tabindex="0"
                                        (click)="NewBooking()" (keydown.enter)="NewBooking()">{{'NAVBAR.New Regular
                                        Hire' | optimotranslate}}</span>
                                </div>
                            </div>
                            <div class="d-flex wm-sub-d-flex">

                                <!--for all internal users-->
                                <div *ngIf="(isInternalUser || isWmCustomerServiceProcess) && !CustomerDetails"
                                    class="wp-mr-20 wm-wp-mr">
                                    <span
                                        class="wp-txt-body1--small wp-txt--link header_nav wm-search_customer wm-header-search"
                                        (click)="clientSearch()" (keypress)="clientSearch()" tabindex=0>
                                        Search
                                        Customer
                                    </span>
                                </div>
                                <div *ngIf="isWmCustomerServiceProcess && !CustomerDetails" class="wm-customer"
                                    tabindex="0">
                                    <span
                                        class="wp-txt-body1--small wp-txt--link header_nav wm-new_customer wm-header-customer"
                                        (click)="addOrEditClient()" (keydown.enter)="addOrEditClient()">New
                                        Customer</span>
                                </div>
                                <!--menu and profile  for waste-->
                                <div *ngIf="CustomerDetails && (isWmCustomerServiceProcess || isInternalUser)"
                                    class="wm-login-user-profile-icon-wrapper wm-selected-customer-profile">
                                    <div class="wm-profile-avatar wm-selected-cus-img"
                                        *ngIf="CustomerDetails.client?.profilePicture; else CustomerEmptyProImg">
                                        <img [src]="CustomerDetails.client.profilePicture" />
                                    </div>
                                    <ng-template #CustomerEmptyProImg>
                                        <span class="wp-txt-body1--small  wm-signIn-user-icon wm-relative-img"
                                            role="button" tabindex="0"></span>
                                    </ng-template>
                                    <span
                                        class="wp-txt-body1--small wp-txt--link header_nav wm-selected-customer-profile wm-select-profile"
                                        style="width: max-content;">
                                        <span style="padding: 0 15px;" (click)="addOrEditClient(CustomerDetails)"
                                            (keydown.enter)="addOrEditClient(CustomerDetails)" tabindex="0">
                                            <p style="font-size:  small !important; margin-bottom: -4px; color: blue;">
                                                {{CustomerDetails.name}}
                                            </p>
                                            <p style="font-size: x-small !important;margin-bottom: -3px;">
                                                {{CustomerDetails.tel}} | {{CustomerDetails.email}}
                                            </p>
                                            <p style="font-size: xx-small !important;">{{CustomerDetails.address}}</p>
                                        </span>
                                        <span style="margin-top: -20px; cursor: pointer;" (click)="removeCustomer()"
                                            (keydown.enter)="removeCustomer()" (keypress)="removeCustomer()"
                                            tabindex="0">
                                            <i class="fa fa-times wm-header-close-btn" aria-hidden="true"></i>
                                        </span>
                                    </span>
                                </div>

                            </div>
                        </div>

                        <div [ngClass]="{'sub-header-nav-right': isPublicBookingPortal, 'col-12 col-md-6 d--flex align--center sub wm-top-name align-items-center width-limiter': !isPublicBookingPortal}">
                            <!-- <div class="mmc_flex__item--inherit align--center wm-name-left">
                            <div class="mmc_flex--container"> -->

                            <div class="col-12 col-md-auto p-0 float-right d-flex align--center user-controls-container justifyContent">
                                <div class="userprofile-wrapper float-right pr-2">
                                    <div class="wm-login-user-profile-icon-wrapper wm-dashboard-icon-wrapper" *ngIf="isInternalUser"
                                        (click)="redirectToDashboard()" (keydown.enter)="redirectToDashboard()"
                                        tabindex="0">
                                        <span class="wp-txt-body1--small  wm-dashboard-icon wm-go-back"></span>
                                    </div>

                                    <!--Start- language-selector -->
                                    <opt-language-selector></opt-language-selector>
                                    <!--End- language-selector -->

                                    <!-- User profile -->
                                    <div class="wm-login-user-profile-icon-wrapper">

                                        <div *ngIf="isInternalUser; else externalClient">

                                            <!--<span class="wp-txt-body1--small  wm-signIn-user-icon wm-profile-img"></span>-->
                                            <!-- Internal user profile for casual and regular -->
                                            <div class="wm-login-user-profile-icon-wrapper wm-"
                                                *ngIf="!isWasteBookingPortal">
                                                <div class="wm-profile-avatar"
                                                    *ngIf="(cart$|async)?.InternalUserData?.userImageUrl; else emptyProImg">
                                                    <img [src]="(cart$|async)?.InternalUserData?.userImageUrl" />
                                                </div>
                                            </div>
                                            <!--waste todo image not getting-->
                                            <div class="wm-login-user-profile-icon-wrapper wm-"
                                                *ngIf="isWasteBookingPortal">
                                                <div class="wm-profile-avatar"
                                                    *ngIf="(wmInternalUser$|async)?.userData?.userImageUrl; else emptyProImg">
                                                    <img [src]="(wmInternalUser$|async)?.userData?.userImageUrl" />
                                                </div>
                                            </div>
                                        </div>
                                        <ng-template #externalClient>
                                            <div class=wm-profile-avatar
                                                *ngIf="(cart$|async)?.contact?.profilePicture || (cart$|async)?.contact?.client?.profilePicture; else emptyProImg"
                                                (click)="myprofile()" (keydown.enter)="myprofile()" tabindex="0">
                                                <img [src]="(cart$|async)?.contact?.profilePicture || (cart$|async)?.contact?.client?.profilePicture" class="user__avatar" />
                                            </div>

                                        </ng-template>
                                        <ng-template #emptyProImg>
                                            <span aria-label="profile image"
                                                [class.wm-profile-img-internal]="isInternalUser"
                                                class="wp-txt-body1--small  wm-signIn-user-icon wm-profile-img"
                                                (click)="SignIn()" (keydown.enter)="myprofile()" role="button"
                                                tabindex="0">
                                                <div class="user__avatar border--circle d--flex ml-auto" *ngIf="this.PortalType === 4"
                                                    [style.background-image]="'url(' + emptyUserIconImgUrl + ')'">
                                                </div>
                                            </span>
                                        </ng-template>
                                    </div>

                                <div class="mmc_flex__item--vcenter wm-sign-in-btn">
                                    <div *ngIf="!isInternalUser">
                                        <a (click)="SignIn()" (keydown.enter)="SignIn()" tabindex="0"
                                            class="px-3 pw-txt-b2 pw-text-color__primary link-primary" [class.name-available]="(cart$ | async)?.contact.firstName">
                                            {{(cart$|async)?.contact?.firstName ? (cart$|async)?.contact?.firstName: ('NAVBAR.Sign In' | optimotranslate)}}</a>
                                    </div>
                                    <div *ngIf="isInternalUser">
                                        <a class="px-3 pw-txt-b2 pw-text-color__primary link-primary"
                                            *ngIf="!isWasteBookingPortal" (click)="linkClick($event)"
                                            (keydown.enter)="linkClick($event)" tabindex="0" [class.name-available]="(cart$ | async)?.contact.firstName">
                                            {{ (cart$|async)?.InternalUserData?.firstName ?
                                            (cart$|async)?.InternalUserData?.firstName:('NAVBAR.Sign In' | optimotranslate)}}
                                        </a>
                                        <a class="px-3 pw-txt-b2 pw-text-color__primary link-primary"
                                            *ngIf="isWasteBookingPortal" (click)="linkClick($event)"
                                            (keydown.enter)="linkClick($event)" tabindex="0" [class.name-available]="(cart$ | async)?.contact.firstName">
                                            {{(wmInternalUser$|async)?.userData ?
                                            (wmInternalUser$|async)?.userData?.firstName: ('NAVBAR.Sign In' | optimotranslate)}}
                                        </a>

                                        </div>

                                    </div>
                                </div>

                                <!-- User profile  End-->

                                <div class="mmc_flex__item user__left wm-home-cart d--flex align--center">


                                    <button class="header-menu-btn" mat-button [matMenuTriggerFor]="logout"
                                    (keydown.enter)="logout"
                                    *ngIf="(cart$|async)?.contact?.firstName || (!isWasteBookingPortal && (cart$|async)?.InternalUserData?.userId) || (isWasteBookingPortal &&  (wmInternalUser$|async)?.userData)"
                                    role="button" tabindex="0">
                                        <i
                                            class="cog-d-none material-icons btn-click pw-ico-color__tertiary header-hamburger-icon">
                                            menu
                                        </i>
                                    </button>

                                    <mat-menu #logout="matMenu">
                                        <button mat-menu-item (click)="venueSearch()" (keydown.enter)="venueSearch()"
                                            *ngIf="(isDisplayCasualHireLink && !isWasteBookingPortal && !isPublicBookingPortal)
                                                    && getMenuVisibility('NewCasualHire')"
                                            class="header-before-menu-casual">
                                            {{(getMenuName('NewCasualHire' , 'New Casual Hire') | optimotranslate)}}
                                        </button>
                                        <button mat-menu-item (click)="NewBooking()" (keydown.enter)="NewBooking()"
                                            tabindex="0" *ngIf="(isDisplayRegularHireLink && !isWasteBookingPortal && !isPublicBookingPortal)
                                                    && getMenuVisibility('NewRegularHire')"
                                            class="header-before-menu-regular">
                                            {{(getMenuName('NewRegularHire' , 'New Regular Hire') | optimotranslate)}}
                                        </button>
                                        <button mat-menu-item (click)="WasteBooking()" (keydown.enter)="WasteBooking()"
                                            tabindex="0" *ngIf="WasteBookingPortalUrl && WasteBookingPortalUrl.length > 0 && !isWmCustomerServiceProcess
                                                    && getMenuVisibility('WasteBooking')">
                                            {{(getMenuName('WasteBooking', 'Waste Booking') | optimotranslate)}}
                                        </button>
                                        <button mat-menu-item (click)="PublicBooking()"
                                            (keydown.enter)="PublicBooking()" tabindex="0" *ngIf="(PublicBookingPortalUrl && PublicBookingPortalUrl.length > 0 && !isWmCustomerServiceProcess)
                                                    && getMenuVisibility('PublicBooking')">
                                            {{(getMenuName('PublicBooking', 'Public Booking') | optimotranslate)}}
                                        </button>
                                        <button mat-menu-item (click)="myprofile()" (keydown.enter)="myprofile()"
                                            tabindex="0" *ngIf="!isInternalUser
                                                    && getMenuVisibility('MyProfile')">
                                            {{(getMenuName('MyProfile' , 'My Profile') | optimotranslate)}}
                                        </button>
                                        <button mat-menu-item (click)="myBooking(isInternalUser)"
                                            (keydown.enter)="myBooking(isInternalUser)" tabindex="0" *ngIf="!isWmCustomerServiceProcess
                                                    && getMenuVisibility('MyBookings')">
                                            {{isInternalUser
                                            ? "Booking Search"
                                            : (getMenuName('MyBookings','My Bookings') | optimotranslate)}}
                                        </button>
                                        <button mat-menu-item (click)="changePassword()"
                                            (keydown.enter)="changePassword()" tabindex="0" *ngIf="!isInternalUser
                                                    && getMenuVisibility('ChangePassword')">
                                            {{(getMenuName('ChangePassword' , 'Change Password') | optimotranslate)}}
                                        </button>
                                        <button mat-menu-item (click)="signOut()" (keydown.enter)="signOut()"
                                            tabindex="0" *ngIf="getMenuVisibility('SignOut')">
                                            {{(getMenuName('SignOut', 'Sign Out') | optimotranslate)}}
                                        </button>
                                    </mat-menu>


                                    <div class="user__account pw-txt-b2 pw-text-color__tertiary d--flex align--center pl-0" *ngIf="isDisplayCart && !isPublicBookingPortal">


                                        <div class="user__account__text pw-txt-b2 pw-text-color__tertiary pr-2 pl-2 text-center"
                                            (click)="cartClick()" (keydown.enter)="cartClick()"
                                            *ngIf="(minAmount==maxAmount) && !isWasteBookingPortal" tabindex="0">
                                            {{(minAmount|CurrencyFormat) }}
                                        </div>
                                        <div class="user__account__text pw-txt-b2 pw-text-color__tertiary pr-2 pl-2 text-center"
                                            (click)="cartClick()" (keydown.enter)="cartClick()"
                                            *ngIf="minAmount!=maxAmount && !isWasteBookingPortal" tabindex="0">
                                            {{(minAmount|CurrencyFormat) }}-{{ (maxAmount|CurrencyFormat) }}
                                        </div>
                                        <div class="user__account__text pw-txt-b2 pw-text-color__tertiary pr-2 pl-2 text-center"
                                            (click)="cartClick()" (keydown.enter)="cartClick()" *ngIf="isWasteBookingPortal" tabindex="0">
                                            {{(WMTotalCartAmount|CurrencyFormat) }}
                                        </div>

                                        <div class="user__cart  border__r--3 d--flex" (click)="cartClick()"
                                            (keydown.enter)="cartClick()" role="button" tabindex="0" [class.link-disabled]="!((cart$|async)?.contact?.id && (cart$|async)?.contact?.name) && isInternalUser">
                                            <i class="material-icons pw-ico-color">
                                                shopping_cart
                                            </i>
                                            <!-- img src="dist/assets/images/shopping-cart.svg" alt="" class="user__cart__icon margin--auto" /> -->
                                            <div class="user__counter border--circle circle d--flex">
                                                <div class="counter__number margin--auto" *ngIf="!isWasteBookingPortal">
                                                    {{totalCount}}
                                                </div>
                                                <div class="counter__number margin--auto" *ngIf="isWasteBookingPortal">
                                                    {{wmCartCount}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mmc_flex__item user__left d--flex align--center" *ngIf="isPublicBookingPortal">
                                      <div class="user__account pw-txt-b2 pw-text-color__tertiary d--flex align--center pl-0 mt-2">
                                        <div *ngIf="!bookingSession.timerPaused">
                                          <div *ngIf=" bookingSession.isSessionTimerStart" class="nav-session" style="width: 152px;
                                                                          text-align: center;">
                                            <div class="row session-resp" style="margin-right: 0px;
                                                                              margin-left: 0px;
                                                                              border-right: 1px solid #b7b7b7;">
                                              <div class="col-12" style="font-size: 10px;">{{"NAVBAR.Session Expires in"| optimotranslate}}</div>
                                              <div class="col-12 text-danger" style="font-weight: 900;font-size: 17px;">{{bookingSession.minutes |
                                                number:'1.0-0'}} : {{bookingSession.seconds | number:'2.0-0'}}</div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div *ngIf="isPublicBookingPortal" class="user__account user-section pw-txt-b2 pw-text-color__tertiary d--flex align--center pl-0">


                                      <div class="user__account__text pw-txt-b2 pw-text-color__tertiary pr-0 px-3"
                                        style="color: white !important; width:max-content"
                                        (click)="cartClick()">
                                        {{ pwMaxAmount | PBCurrencyPipe }}
                                      </div>

                                      <div class="user__cart border__r--3 d--flex" (click)="cartClick()">
                                        <i class="material-icons pw-ico-color"> shopping_cart </i>
                                        <!-- img src="dist/assets/images/shopping-cart.svg" alt="" class="user__cart__icon margin--auto" /> -->
                                        <div class="user__counter border--circle circle d--flex">
                                          <div class="counter__number margin--auto">
                                            {{ bookingPackages | PBCustomItemQuantityPipe : (isBooking && isPendingChanges)}}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        

                    </div>
                </div>
            </div>
        </div>
    </nav>
</div>
<div class="mmc_expansion__body--row my-bookings-expansion-body"
    *ngIf="!((cart$|async)?.contact?.id && (cart$|async)?.contact?.name) && isInternalUser"
    style="justify-content: center">
    <div class="mmc_flex--container my-bookings-expansion-body--container mcc-wrap-items">
        <div
            class="mmc_flex__item mmc_flex__item--vcenter  mmc_flex__item--hcenter pw-txt-h2 pw-text-color__tertiary not-found">
            <span class="icon-p2-not-found pr-2"></span>{{'NAVBAR.Select Custormer' | optimotranslate}}
        </div>
    </div>
</div>
