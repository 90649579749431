import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SiteLayoutComponent } from './site-layout.component';
//import { SiteHeaderComponent } from './site-header/site-header.component';
//import { SiteFooterComponent } from './site-footer/site-footer.component';

import { SiteHeaderDirective } from 'src/modules/layout/site-header.directive';
import { SiteFooterDirective } from 'src/modules/layout/site-footer.directive';

@NgModule({
    declarations: [SiteLayoutComponent, SiteHeaderDirective, SiteFooterDirective],
    imports: [CommonModule, RouterModule],
    exports: [SiteLayoutComponent, SiteLayoutComponent, SiteHeaderDirective, SiteFooterDirective]
    //declarations: [SiteLayoutComponent, SiteHeaderComponent, SiteFooterComponent],
    //exports: [SiteLayoutComponent, SiteHeaderComponent, SiteFooterComponent]
})

export class SiteLayoutModule { }
