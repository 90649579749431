import { EventConfigurationDetail } from "src/modules/public-web/booking-application/models/BookingEvent";
import { EventConfiguration } from "../../booking/event-configuration";

export class EventSeatingConfiguration extends EventConfiguration {

  constructor() {
    super();
    this.type = "EventSeatingConfiguration";
    //this.eventSeats = [];
  }
  //Allocation have eventseat. so, doesn't need eventSeat in EventSeatingConfiguration (PR:17344)
  //public eventSeats: EventSeat[];
}

export class EventSeat extends EventConfigurationDetail {
  blockID: string | null;
  seatColumnNo: number | null;
  seatColumnName: string;
  seatRowNo: number | null;
  seatRowName: string;
  seatNumber: number | null;
  aisle: boolean | null;
  nonSeated: boolean | null;
  priceBandId: string | null;
  priceBandName: string;
  rankId: number | null;
  rank: string;
  allocated: boolean | null;
  unavailable: boolean | null;
  constructor() {
    super()
    this.type = 'EventSeat'
  }
}

