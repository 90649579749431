<main role="main">
    <div class="past-booking">
        <opt-sub-header></opt-sub-header>
        <div class="body-wrapper" style="top: 0px !important;">
            <div class="change-password-wrapper">
                    <form novalidate (ngSubmit)="onSubmit()" [formGroup]="cpForm">
                            <div class="container change-password-container">
                                <div class="pw-txt-h2 pw-text-semi-bold title title-primary pw-text-color__quinary txt-color-same">
                                    {{'CHANGEPASSWORD.Change'|optimotranslate}}
                                    <span class="title-secondary pw-text-color__primary">{{'CHANGEPASSWORD.Password'|optimotranslate}}</span>
                                </div>
                                <div class="inner-container">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <div class="mmc_flex--container mmc_fd--col">
                                                <div class="mmc_flex__item--inherit">
                                                    <mat-form-field appearance="outline">
                                                        <mat-label>{{'CHANGEPASSWORD.Current Password' | optimotranslate}}</mat-label>
                                                        <input type="password" matInput calss="form-control" formControlName="currentPassword" [(ngModel)]="currentPassword" placeholder="{{'CHANGEPASSWORD.Current Password' | optimotranslate}}" required>
                                                        <mat-error *ngIf="cpForm.controls.currentPassword.invalid && cpForm.controls.currentPassword.touched">
                                                            {{'CHANGEPASSWORD.Please enter the current password'|optimotranslate}}
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div class="mmc_flex__item--inherit">
                                                    <mat-form-field appearance="outline">
                                                        <mat-label>{{'CHANGEPASSWORD.New Password' | optimotranslate}}</mat-label>
                                                        <input type="password" matInput calss="form-control" formControlName="newPassword" [(ngModel)]="newPassword" (ngModelChange)="passwordChange($event)" (focusout)="focusOutFunction($event)" placeholder="{{'CHANGEPASSWORD.New Password' | optimotranslate}}" required>
                                                        <mat-error *ngIf="cpForm.controls.newPassword.invalid && cpForm.controls.newPassword.touched">
                                                            {{'CHANGEPASSWORD.Please enter the new password' | optimotranslate}}
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div class="mmc_flex__item--inherit">
                                                    <mat-form-field appearance="outline">
                                                        <mat-label>{{'CHANGEPASSWORD.Confirm Password'|optimotranslate}}</mat-label>
                                                        <input type="password" matInput calss="form-control" formControlName="confirmPassword" [(ngModel)]="confirmPassword" (ngModelChange)="confirmPasswordChange($event)" (focusout)="focusOutFunctionCp($event)" placeholder="{{'CHANGEPASSWORD.Confirm Password'| optimotranslate}}" required>
                                                        <mat-error *ngIf="cpForm.controls.confirmPassword.invalid && cpForm.controls.confirmPassword.touched">
                                                            {{'CHANGEPASSWORD.Please re-enter the new password'|optimotranslate}}
                                                        </mat-error>
                                                    </mat-form-field>
                                                    <div class="mmc_flex--container" *ngIf="showValidationMessage">
                                                        <div class="mmc_flex__item--inherit">
                                                            <span class="error-message">{{'CHANGEPASSWORD.' + validationMessage|optimotranslate}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <style>
                                            .check-icon-green {
                                                color: #3d9b47;
                                            }

                                            .check-icon-red {
                                                color: #dc3545;
                                            }
                                        </style>

                                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <div *ngIf="pwPolicy?.EnforceComplexPassword">
                                                <h3 class="mmc_flex--container pw-txt-h3 pb-2">
                                                    <div class="mmc_flex__item--inherit">
                                                    {{'CHANGEPASSWORD.Minimum Password Requirements:'|optimotranslate}}
                                                    </div>
                                                </h3>

                                                <div class="mmc_flex--container mmc_fd--col">
                                                    <div class="mmc_flex__item--inherit mmc_flex__item--vcenter suggestion-label" *ngIf="pwPolicy?.EnforceMinimumPasswordLength">
                                                        <div *ngIf="isValidCharactersMinimum">
                                                            <i class="material-icons  check-icon-green">done</i>
                                                        </div>
                                                        <div *ngIf="!isValidCharactersMinimum">
                                                            <i class="material-icons  check-icon-red">clear</i>
                                                        </div>
                                                        <span class="pw-txt-h5 pw-text-medium">{{pwPolicy?.PasswordLength}} {{'CHANGEPASSWORD.Characters minimum'|optimotranslate}}</span>
                                                    </div>

                                                    <div class="mmc_flex__item--inherit mmc_flex__item--vcenter suggestion-label" *ngIf="pwPolicy?.EnforceLowercase">
                                                        <div *ngIf="isValidOneLowercase">
                                                            <i class="material-icons check-icon-green">done</i>
                                                        </div>
                                                        <div *ngIf="!isValidOneLowercase">
                                                            <i class="material-icons check-icon-red">clear</i>
                                                        </div>
                                                        <span class="pw-txt-h5 pw-text-medium">{{'CHANGEPASSWORD.One lowercase'|optimotranslate}}</span>
                                                    </div>

                                                    <div class="mmc_flex__item--inherit mmc_flex__item--vcenter suggestion-label" *ngIf="pwPolicy?.EnforceUppercase">
                                                        <div *ngIf="isValidOneUppercase">
                                                            <i class="material-icons  check-icon-green">done</i>
                                                        </div>
                                                        <div *ngIf="!isValidOneUppercase">
                                                            <i class="material-icons  check-icon-red">clear</i>
                                                        </div>
                                                        <span class="pw-txt-h5 pw-text-medium">{{'CHANGEPASSWORD.One uppercase'|optimotranslate}}</span>
                                                    </div>

                                                    <div class="mmc_flex__item--inherit mmc_flex__item--vcenter suggestion-label" *ngIf="pwPolicy?.EnforceDigit">
                                                        <div *ngIf="isValidOneDigit">
                                                            <i class="material-icons check-icon-green">done</i>
                                                        </div>
                                                        <div *ngIf="!isValidOneDigit">
                                                            <i class="material-icons check-icon-red">clear</i>
                                                        </div>
                                                        <span class="pw-txt-h5 pw-text-medium">{{'CHANGEPASSWORD.One digit'|optimotranslate}}</span>
                                                    </div>

                                                    <div class="mmc_flex__item--inherit mmc_flex__item--vcenter suggestion-label" *ngIf="pwPolicy?.EnforceSpecialCharacters">
                                                        <div *ngIf="isValidOneSpecialCharacter">
                                                            <i class="material-icons  check-icon-green">done</i>
                                                        </div>
                                                        <div *ngIf="!isValidOneSpecialCharacter">
                                                            <i class="material-icons  check-icon-red">clear</i>
                                                        </div>
                                                        <span class="pw-txt-h5 pw-text-medium">{{'CHANGEPASSWORD.One special character'|optimotranslate}}</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mmc_flex--container mmc_just-content-end">
                                        <div class="mmc_flex__item--inherit">
                                            <button mat-button class="mmc-btn-primary" [disabled]="isDisabledSubmit" [ngClass]="{'disable-div' : isDisabledSubmit }" tabindex="0">{{'CHANGEPASSWORD.Save' | optimotranslate}}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </form>
            </div>
        </div>
    </div>
</main>