import { Component, AfterViewInit, ViewChild, ChangeDetectorRef, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';

import { Facility } from 'src/modules/models/asset/facility';
import { environment } from 'src/environments/environment';

import { Observable } from "rxjs";
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';
const moment = _rollupMoment || _moment;

@Component({
    selector: 'app-simple',
    templateUrl: './simple.component.html'
})

export class SimpleComponent implements AfterViewInit {
    @ViewChild('myCarousel') myCarousel: NguCarousel<any>;
    @Input() venueFacilities: Observable<Facility[]>;
    slideNo = 0;
    withAnim = true;
    resetAnim = true;
    webAPiBaseUrl: string;
    dateInUrl: any;

    carouselConfig: NguCarouselConfig = {
        grid: { xs: 1, sm: 1, md: 3, lg: 3, all: 0 },
        load: 10,
        interval: { timing: 4000, initialDelay: 1000 },
        loop: true,
        touch: true,
        velocity: 0.2,
        point: {
            visible: true,
            hideOnSingleSlide: true
        }
    }
    carouselItems = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];

    constructor(private cdr: ChangeDetectorRef) {
        this.webAPiBaseUrl = environment.WebApiUrl;
    }

    ngOnInit() {
        var leadDays = (environment.LeadDaysForBooking == null) ? 0 : environment.LeadDaysForBooking;
        this.dateInUrl = moment().add(leadDays, 'days');
    }

    ngOnChanges(changes: SimpleChanges) {
        const data: SimpleChange = changes.venueFacilities;
        if (data.currentValue.length > 0) {
            this.carouselItems = data.currentValue;
            this.ngAfterViewInit();
        }
    }

    ngAfterViewInit() {
        this.cdr.detectChanges();
    }

    reset() {
        this.myCarousel.reset(!this.resetAnim);
    }

    moveTo(slide) {
        this.myCarousel.moveTo(slide, !this.withAnim);
    }
}