import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[optSiteHeader]'
})
export class SiteHeaderDirective {

    constructor(public viewContainerRef: ViewContainerRef) { }

}
