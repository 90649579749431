import { ILoadingStatus } from 'src/modules/store/loading/status-store'
import { LoadingActionTypes, LoadingActions } from 'src/modules/store/loading/actions'
import { Facilitysearchparams } from 'src/modules/models/asset/facility-search-params';

export let initialState: ILoadingStatus = {
    Facilitysearchparams: new Facilitysearchparams(),
    isLoading_facilitySearch: false,
    isLoading_bookingSave: false,
    isLoading: false,
    isPublicLoading: false,
    requestCounter: 0,
};

export function statusReducer(state = initialState, action: LoadingActions): ILoadingStatus {
    switch (action.type) {
        case LoadingActionTypes.FACILITY_SEARCH_LOADING:
            return { ...state, isLoading_facilitySearch: true };
        case LoadingActionTypes.FACILITY_SEARCH_LOADED:
            return { ...state, isLoading_facilitySearch: false };

        case LoadingActionTypes.BOOKING_SAVE_STARTED:
            return { ...state, isLoading_bookingSave: true };
        case LoadingActionTypes.BOOKING_SAVE_COMPLETED:
            return { ...state, isLoading_bookingSave: false };

        case LoadingActionTypes.LOADING:
            return { ...state, isLoading: true };
        case LoadingActionTypes.LOADED:
            return { ...state, isLoading: false };

        //Public Web Loading
        case LoadingActionTypes.PUBLIC_LOADING:
            return { ...state, isPublicLoading: true };
        case LoadingActionTypes.PUBLIC_LOADED:
            return { ...state, isPublicLoading: false };
            
        case LoadingActionTypes.ADD_REQUEST_COUNTER:
            return { ...state, requestCounter: action.payload };
        case LoadingActionTypes.SAVE_SEARCH_FILTER_PARAM:
            return { ...state, Facilitysearchparams: action.payload };
        default:
            return state;
    }
}
