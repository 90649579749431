import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ILanguageReducer  } from "./language-reducer";

const getAllSate = createFeatureSelector<ILanguageReducer>('LanguageReducer');

export const getSelectedLanguage = createSelector(
    getAllSate,
    (state) => {
      return state ? state.selectedLanguage?.toUpperCase(): null;
    }
);

export const getLanguages = createSelector(
  getAllSate,
  (state) => {
    return state ? state.languages: [];
  }
);
