<div class="mat-dialog-content delete-modal">
   
    <div class="modal-body">

        <!--modal body start-->
        <div class="mmc_flex--container">
            <div class="mmc_flex__item">
                {{data?.message}}
            </div>
        </div>
    </div>
    <div class="mmc_flex--container footer-modal">        
        <div class="btn__card main mmc_flex__item--vcenter add" (click)="okClick()" (keydown.enter)="okClick()">{{data?.yesText}}</div>
        <div class="btn__card main mmc_flex__item--vcenter add" (click)="cancelClick()" (keydown.enter)="cancelClick()">{{data?.noText}}</div>
    </div>

</div>


