import { Action } from "@ngrx/store";
import { Booking, DeliveryMethod } from "../../models/booking/booking";
import { BOOKING_CART, BOOKING_FROM, PAGE_TYPE } from "../../models/public-web/enum";
import { IBooingPackageBookedSeat, IDateAndSession, IPublicEvent, IPublicPackage, PaymentInfo, } from "./public-web-reducers";
import { Contact } from "../../models/client/contact";
import { Package } from "src/modules/models/public-web/Package";
import { SystemOption } from "src/modules/models/public-web/SystemOption";
import { PatchItems } from "src/modules/public-web/components/pages/public-booking-cart/public-booking-cart.component";
import { PublicEvent } from "src/modules/models/public-web/PublicEvent";
import { BOOKING_FLOW_OPTION } from "src/modules/public-web/components/layout/models/enums";

export enum PBActionType {
    SET_SELECTED_EVENT_CATEGORY_ID = "SET_SELECTED_EVENT_CATEGORY_ID",
    RESET_SELECTED_EVENT_CATEGORY_ID = "RESET_SELECTED_EVENT_CATEGORY_ID",
    SET_SELECTED_EVENT_ID = "SET_SELECTED_EVENT_ID",
    ADD_PACKAGE = "ADD_PACKAGE",
    SET_SELECTED_SESSION = "SET_SELECTED_SESSION",
    ADD_UPSELL = "ADD_UPSELL",
    PATCH_UPSELL_QUANTITY = "PATCH_UPSELL_QUANTITY",
    SET_CURRENT_PAGE_TYPE = "SET_CURRENT_PAGE_TYPE",
    POST_PUBLIC_BOOKING = "POST_PUBLIC_BOOKING",  // To call the booking post
    PATCH_BOOKING = "PATCH_BOOKING",
    SET_BOOKING = "SET_BOOKING",
    GET_PAYMENT_URL = "GET_PAYMENT_URL",
    SET_PAYMENT_URL = "SET_PAYMENT_URL",
    ADD_PUBLIC_INTERNAL_BOOKING = "ADD_PUBLIC_INTERNAL_BOOKING",
    BOOKING_FAILED = "BOOKING_FAILED",
    SET_REDIRECTION_POINT = "SET_REDIRECTION_POINT",
    RESET_PUBLIC_WEB_STATE = "RESET_PUBLIC_WEB_STATE",
    RESET_PACKAGES = "RESET_PACKAGES",
    CHECK_BOOKING_HAS_CLIENT = "CHECK_BOOKING_HAS_CLIENT",
    UPDATE_SELECTED_BOOKING = "UPDATE_SELECTED_BOOKING",
    UPDATE_TRANSLATE_SELECTED_BOOKING = "UPDATE_TRANSLATE_SELECTED_BOOKING",
    FETCH_PACKAGE_DELIVERY_METHODS = "FETCH_PACKAGE_DELIVERY_METHODS",
    SET_PAGE_CONFIGURATIONS = "SET_PAGE_CONFIGURATIONS",
    SELECT_PACKAGE_SESSIONS = "SELECT_PACKAGE_SESSIONS",
    REMOVE_PACKAGE_SESSION = "REMOVE_PACKAGE_SESSION",
    RESET_SELECT_PACKAGE_STATUS = "RESET_SELECT_PACKAGE_STATUS",
    SET_PACKAGE_AVAILABILITY = "SET_FILTER_PACKAGE_AVAILABILITY",
    SET_SECONDARY_CLIENT = "SET_SECONDARY_CLIENT",
    BOOKING_PATCH_PREVIEW = "BOOKING_PATCH_PREVIEW",
    SET_BOOKING_PATCH_PREVIEW_STATUS = "SET_BOOKING_PATCH_PREVIEW_STATUS",
    SET_GUEST_CONTACT = "SET_GUEST_CONTACT",
    REMOVE_GUEST_CONTACT = "REMOVE_GUEST_CONTACT",
    SET_BOOKED_PACKAGES = 'SET_BOOKED_PACKAGES',
    SET_UPSELLS_FOR_BOOKED_PACKAGES = 'SET_UPSELLS_FOR_BOOKED_PACKAGES',
    ADD_NEW_SESSIONS = 'ADD_NEW_SESSIONS',
    SET_BOOKING_TYPE = 'SET_BOOKING_TYPE',
    SET_FREEZED_BOOKING = 'SET_FREEZED_BOOKING',
    SET_PACKAGE_LIST = 'SET_PACKAGE_LIST',
    UPDATE_PACKAGE = 'UPDATE_PACKAGE',
    RESET_ALL_PACKAGE_QUANTITY = "RESET_ALL_PACKAGE_QUANTITY",
    SET_EVENT_LIST = 'SET_EVENT_LIST',
    SET_BOOKING_FLOW = 'SET_BOOKING_FLOW',
    ADD_PAYMENT_SESSION = "ADD_PAYMENT_SESSION",
    ADD_BOOKING_SESSION = "ADD_BOOKING_SESSION",
    START_PAYMENT_SESSION = "START_PAYMENT_SESSION",
    START_BOOKING_SESSION = "START_BOOKING_SESSION",
    PAUSE_BOOKING_SESSION = "PAUSE_BOOKING_SESSION",
    RESUME_BOOKING_SESSION = "RESUME_BOOKING_SESSION",
    PAUSE_PAYMENT_SESSION = "PAUSE_PAYMENT_SESSION",
    RESUME_PAYMENT_SESSION = "RESUME_PAYMENT_SESSION",
    START_GLOBAL_SESSION = "START_GLOBAL_SESSION",
    ADD_GLOBAL_SESSION = "ADD_GLOBAL_SESSION",
    SET_PATCH_PREVIEW_ID = "SET_PATCH_PREVIEW_ID",
    SET_SELECTED_BOOKING = "SET_SELECTED_BOOKING",
    SET_ELIGIBLE_DELIVERY_METHOD = "SET_ELIGIBLE_DELIVERY_METHOD",
    SET_SELECTED_BOOKED_PACKAGE = "SET_SELECTED_BOOKED_PACKAGE",
    SET_BOOKING_FROM = "SET_BOOKING_FROM",
    SET_UPDATED_BOOKING_PACKAGES = "SET_UPDATED_BOOKING_PACKAGES",
    SET_SEAT = 'SET_SEAT',
    CHECK_SEAT = 'CHECK_SEAT',
    SET_ALLSEATS = 'SET_ALLSEATS',
    SET_DESELECT_SEAT = 'SET_DESELECT_SEAT',
    SET_DESELECT_ALLSEATS = 'SET_DESELECT_ALLSEATS',
    SET_CONTACTID = 'SET_CONTACTID',
}

export class SetPatchPreviewId implements Action {
    readonly type = PBActionType.SET_PATCH_PREVIEW_ID;

    constructor(public payload: string) {
    }
}

export class StartPaymentSession implements Action {
    readonly type = PBActionType.START_PAYMENT_SESSION;

    constructor(public payload: Date) {
    }
}

export class StartBookingSession implements Action {
    readonly type = PBActionType.START_BOOKING_SESSION;

    constructor(public payload?: Date) {
    }
}

export class StartGlobalSession implements Action {
    readonly type = PBActionType.START_GLOBAL_SESSION;

    constructor(public payload?: Date) {
    }
}

export class PauseBookingSession implements Action {
    readonly type = PBActionType.PAUSE_BOOKING_SESSION;
}

export class PausePaymentSession implements Action {
    readonly type = PBActionType.PAUSE_PAYMENT_SESSION;
}

export class ResumeBookingSession implements Action {
    readonly type = PBActionType.RESUME_BOOKING_SESSION;
}

export class ResumePaymentSession implements Action {
    readonly type = PBActionType.RESUME_PAYMENT_SESSION;
}

export class AddPaymentSession implements Action {
    readonly type = PBActionType.ADD_PAYMENT_SESSION;

    constructor(public payload: SystemOption) {
    }
}

export class AddBookingSession implements Action {
    readonly type = PBActionType.ADD_BOOKING_SESSION;

    constructor(public payload: SystemOption) {
    }
}

export class AddGlobalSession implements Action {
    readonly type = PBActionType.ADD_GLOBAL_SESSION;

    constructor(public payload: SystemOption) {
    }
}

export class SetFreezedBooking implements Action {
    readonly type = PBActionType.SET_FREEZED_BOOKING;

    constructor(public payload: Booking) {
    }

}

export class AddNewSession implements Action {
    readonly type = PBActionType.ADD_NEW_SESSIONS;

    constructor(public payload: IDateAndSession[]) {
    }
}

export class SetBookedPackage implements Action {
    readonly type = PBActionType.SET_BOOKED_PACKAGES;

    constructor(public payload: Package[]) {
    }
}

export class SetUpsellsForBookedPackage implements Action {
    readonly type = PBActionType.SET_UPSELLS_FOR_BOOKED_PACKAGES;

    constructor(public payload: { packageID: string, upsells: [] }) {}
}
export class SetGuestContact implements Action {
    readonly type = PBActionType.SET_GUEST_CONTACT;

    constructor(public payload: Contact) {
    }
}

export class RemoveGuestContact implements Action {
    readonly type = PBActionType.REMOVE_GUEST_CONTACT;

    constructor() {
    }
}

export class RemovePackageSession implements Action {
    readonly type = PBActionType.REMOVE_PACKAGE_SESSION;

    constructor(public payload: IDateAndSession) {
    }
}

export class BookingPatchPreview implements Action {
    readonly type = PBActionType.BOOKING_PATCH_PREVIEW;

    constructor() {
    }
}

export class BookingFailed implements Action {
    readonly type = PBActionType.BOOKING_FAILED;

    constructor() {
    }
}

export class SetPatchPreviewStatus implements Action {
    readonly type = PBActionType.SET_BOOKING_PATCH_PREVIEW_STATUS;

    constructor(public payload: boolean) {
    }
}

export class SetSecondaryClient implements Action {
    readonly type = PBActionType.SET_SECONDARY_CLIENT;

    constructor(public payload: any) {
    }
}

export class SetPackageAvailability implements Action {
    readonly type = PBActionType.SET_PACKAGE_AVAILABILITY;

    constructor(public payload: number) {
    }
}

export class ResetSelectedPackageStatus implements Action {
    readonly type = PBActionType.RESET_SELECT_PACKAGE_STATUS;

    constructor() {
    }
}

export class SelectPackageSessions implements Action {
    readonly type = PBActionType.SELECT_PACKAGE_SESSIONS;

    constructor(public payload: IDateAndSession[]) {
    }
}

export class SetPageConfigurations implements Action {
    readonly type = PBActionType.SET_PAGE_CONFIGURATIONS;

    constructor(public payload: string[]) {
    }
}

export class UpdateSelectedBooking implements Action {
    readonly type = PBActionType.UPDATE_SELECTED_BOOKING;
}

export class UpdateTranslatedSelectedBooking implements Action {
    readonly type = PBActionType.UPDATE_TRANSLATE_SELECTED_BOOKING;
}

export class ResetAllPackageQuantity implements Action {
    readonly type = PBActionType.RESET_ALL_PACKAGE_QUANTITY
}

export class ResetPackages implements Action {
    readonly type = PBActionType.RESET_PACKAGES
}

export class SetPackageList implements Action {
    readonly type = PBActionType.SET_PACKAGE_LIST

    constructor(public payload: IPublicPackage[]) {
    }
}

export class UpdatePackage implements Action {
    readonly type = PBActionType.UPDATE_PACKAGE

    constructor(public payload?: IPublicPackage) {
    }
}

export class SetEventList implements Action {
    readonly type = PBActionType.SET_EVENT_LIST

    constructor(public payload: IPublicEvent[]) {
    }
}

export class CheckBookingHasClient implements Action {
    readonly type = PBActionType.CHECK_BOOKING_HAS_CLIENT;
}

export class ResetPublicWebState implements Action {
    readonly type = PBActionType.RESET_PUBLIC_WEB_STATE;
}

export class SetRedirectionPoint implements Action {
    readonly type = PBActionType.SET_REDIRECTION_POINT;

    constructor(public payload: string) {
    }
}

export class SetBooking implements Action {
    readonly type = PBActionType.SET_BOOKING;

    constructor(public payload: Booking) {
    }
}

export class GetPaymentUrl implements Action {
    readonly type = PBActionType.GET_PAYMENT_URL;
    constructor() {
    }
}

export class SetPaymentUrl implements Action {
    readonly type = PBActionType.SET_PAYMENT_URL;
    constructor(public payload: PaymentInfo) {
    }
}

export class AddPublicInternalBooking implements Action {
    readonly type = PBActionType.ADD_PUBLIC_INTERNAL_BOOKING;

    constructor(public payload: Booking) {
    }
}

export class PatchUpSellQuantity implements Action {
    readonly type = PBActionType.PATCH_UPSELL_QUANTITY;

    constructor(public payload: PatchItems[]) {
    }
}

export class PostBooking implements Action {
    readonly type = PBActionType.POST_PUBLIC_BOOKING;

    constructor() {
    }
}

export class PatchBooking implements Action {
    readonly type = PBActionType.PATCH_BOOKING;

    constructor() {
    }
}

export class SetCurrentPageType implements Action {
    readonly type = PBActionType.SET_CURRENT_PAGE_TYPE;

    constructor(public payload: PAGE_TYPE) {
    }
}

export class SetSelectedEventCategoryId implements Action {
    readonly type = PBActionType.SET_SELECTED_EVENT_CATEGORY_ID;
    constructor(public payload: string) {
    }
}

export class ResetSelectedEventCategoryId implements Action {
  readonly type = PBActionType.RESET_SELECTED_EVENT_CATEGORY_ID;
}

export class SetSelectedEventId implements Action {
    readonly type = PBActionType.SET_SELECTED_EVENT_ID;

    constructor(public payload: string) {
    }
}

export class AddPackage implements Action {
    readonly type = PBActionType.ADD_PACKAGE;

    constructor(public payload: string) {
    }
}

export class SetSelectedSession implements Action {
    readonly type = PBActionType.SET_SELECTED_SESSION;

    constructor(public payload: IDateAndSession) {
    }
}

export class SetBookingType implements Action {
    readonly type = PBActionType.SET_BOOKING_TYPE;

    constructor(public payload: BOOKING_CART) {
    }
}

export class SetSelectedBooking implements Action {
    readonly type = PBActionType.SET_SELECTED_BOOKING;

    constructor(public payload: any) {
    }
}

export class SetEligibleDeliveryMethod implements Action {
    readonly type = PBActionType.SET_ELIGIBLE_DELIVERY_METHOD;

    constructor(public payload: DeliveryMethod[]) {
    }
}

export class SetSelectedBookingPackage implements Action {
    readonly type = PBActionType.SET_SELECTED_BOOKED_PACKAGE;

    constructor(public payload: IBooingPackageBookedSeat[]) {
    }
}

export class SetBookingFrom implements Action {
    readonly type = PBActionType.SET_BOOKING_FROM;

    constructor(public payload: BOOKING_FROM) {
    }
}

export class SetBookingFlow implements Action {
    readonly type = PBActionType.SET_BOOKING_FLOW

    constructor(public payload?: BOOKING_FLOW_OPTION) {
    }
}

export class SetUpdatedBookingPackages implements Action {
    readonly type = PBActionType.SET_UPDATED_BOOKING_PACKAGES;

    constructor(public payload: any[]) {
    }
}

export class SetContactID implements Action {
    readonly type = PBActionType.SET_CONTACTID

    constructor(public payload: string) {
    }
}

export class SetSeat implements Action {
    readonly type = PBActionType.SET_SEAT

    constructor(public payload: string) {
    }
}

export class CheckSeat implements Action {
    readonly type = PBActionType.CHECK_SEAT

    constructor(public payload: string) {
    }
}


export class SetAllSeats implements Action {
    readonly type = PBActionType.SET_ALLSEATS

    constructor(public payload: string[]) {
    }
}

export class SetDeselectSeat implements Action {
    readonly type = PBActionType.SET_DESELECT_SEAT

    constructor(public payload: string) {
    }
}

export class SetAllDeselectSeat implements Action {
    readonly type = PBActionType.SET_DESELECT_ALLSEATS

    constructor(public payload: string[]) {
    }
}

export type PublicWebActions =
    | SetSelectedEventCategoryId
    | ResetSelectedEventCategoryId
    | SetCurrentPageType
    | SetSelectedEventId
    | RemoveGuestContact
    | AddPackage
    | SetSelectedSession
    | SetBooking
    | AddPublicInternalBooking
    | PatchUpSellQuantity
    | PostBooking
    | PatchBooking
    | SetRedirectionPoint
    | ResetPublicWebState
    | AddNewSession
    | CheckBookingHasClient
    | UpdateSelectedBooking
    | UpdateTranslatedSelectedBooking
    | SetPageConfigurations
    | SelectPackageSessions
    | ResetSelectedPackageStatus
    | SetSecondaryClient
    | AddPaymentSession
    | AddBookingSession
    | AddGlobalSession
    | StartPaymentSession
    | StartBookingSession
    | StartGlobalSession
    | PauseBookingSession
    | PausePaymentSession
    | ResumeBookingSession
    | ResumePaymentSession
    | SetPatchPreviewStatus
    | SetFreezedBooking
    | SetPackageAvailability
    | BookingPatchPreview
    | RemovePackageSession
    | BookingFailed
    | SetBookedPackage
    | SetUpsellsForBookedPackage
    | SetBookingType
    | SetGuestContact
    | SetPatchPreviewId
    | SetSelectedBooking
    | SetSelectedBookingPackage
    | SetPackageList
    | UpdatePackage
    | ResetAllPackageQuantity
    | ResetPackages
    | SetEventList
    | SetEligibleDeliveryMethod
    | SetContactID
    | SetSeat
    | SetDeselectSeat
    | SetAllSeats
    | SetAllDeselectSeat
    | GetPaymentUrl
    | SetPaymentUrl
    | CheckSeat
    | SetBookingFrom
    | SetBookingFlow;
