export class WmCustomer {
  contactId: number;
  clientId: number;
  ref: string;
  name: string;
  email: string;
  tel: string;
  postCode: string;
  address: string;
  // profilePicture?: string;
  client?: any;
  constructor(obj?) {
    if (obj) {
      this.contactId = obj.contactId;
      this.clientId = obj.clientId;
      this.ref = obj.ref;
      this.name = obj.name;
      this.email = obj.email;
      this.tel = obj.tel;
      this.postCode = obj.postCode;
      this.address = obj.address;
      // this.profilePicture = obj.profilePicture;
    }

  }
}
