import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HttpLoaderFactory, OptCommonModules } from 'src/modules/shared/common.modules';

import { CustomerProfileComponent } from 'src/modules/booking-portal/account/customer-profile/customer-profile.component';
import { ChangePasswordComponent } from 'src/modules/booking-portal/account/change-password/change-password.component';

//translation
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient} from "@angular/common/http";
// File Drop 
import { FileDropModule } from 'ngx-file-drop';

@NgModule({
    declarations: [ChangePasswordComponent, CustomerProfileComponent],
  imports: [
      CommonModule,
      OptCommonModules,
      FileDropModule
    ],
    exports: [ChangePasswordComponent, CustomerProfileComponent],
})
export class CustomerAccountModule { }
