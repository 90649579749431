import { SafeHtml } from '@angular/platform-browser';
import { BaseResource } from '../base-resource';

export class Asset extends BaseResource {
    active: boolean;
    classId: number;
    description: SafeHtml;
    name: string;
    operationalMinutes: number;
}
