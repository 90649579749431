import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { WMCustomerOptions, WMModelTitle } from 'src/modules/models/booking/enum';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { WmInternalUser } from 'src/modules/models/booking/wm-internal-user';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { AppSetting } from 'src/modules/models/settings/casual-portal/app-setting';
import { BaseComponent } from 'src/modules/shared/base.component';
import { Store } from 'src/modules/store';
import { selectUserDataState } from 'src/modules/store/waste-management/selectors';
import { pipe } from 'rxjs';

@Component({
  selector: 'opt-add-edit-client',
  templateUrl: './add-edit-client.component.html',
  styleUrls: ['./add-edit-client.component.scss']
})
export class AddEditClientComponent extends BaseComponent implements OnInit {

  url: SafeResourceUrl;
  internalUser: WmInternalUser;
  UserID: number;

  constructor(public dialogRef: MatDialogRef<AddEditClientComponent>,
    private sanitizer: DomSanitizer,
    private ngZone: NgZone,
    private store: Store<any>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    super();
    window['angularComponentRef'] = { component: this, zone: ngZone };
  }

  ngOnInit() {
    let addClientUrl = "";
    if (this.data.portalType == "5") {
      addClientUrl = environment.WasteBookingClientAddUrl;
      if (this.data.data.type === WMCustomerOptions.EDIT_CUSTOMER) {
        addClientUrl += '/' + this.data.data.cusId;
      } else {
        this.store.select(pipe(selectUserDataState)).safeSubscribe(this, (data) => {
          if (data && data.userData) {
            this.internalUser = data.userData;
            this.UserID = this.internalUser.userID;
            const defaultCountryId = (environment.AppSetting as AppSetting).ClientRegistrationConfig.DefaultCountryId.Id;
            console.log(defaultCountryId);
            addClientUrl += '?userId=' + this.UserID + '&defaultCountryId=' + defaultCountryId;

          }
        });
      }
    } else {
      addClientUrl = environment.CBClientAddUrl;
      if (this.data.data.type === WMCustomerOptions.EDIT_CUSTOMER) {
        addClientUrl += '/' + this.data.data.cusId + '?returnUrl=' + environment.ApiUrl;
      } else {
        addClientUrl += '?returnUrl=' + environment.ApiUrl;
      }
    }
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(addClientUrl);
  }

  addClientAngularFunctionCalled(message) {
    this.dialogRef.close(message);
  }

  close() {
    this.dialogRef.close();
  }
  get WMModelTitle() {
    return WMModelTitle;
  }
  get WMCustomerOptions() {
    return WMCustomerOptions;
  }

}
