import { BaseResource } from '../base-resource';
import { Item } from '../item/item';
import { ItemPriceGroup } from '../item/item-price-group';
import { Configuration } from '../asset/configuration';

export class BookingFacility extends BaseResource {
    constructor() {
        super();
        this.item=new Item()
    }
    item: Item;
    configuration: Configuration;
    itemPrice: ItemPriceGroup;
    startTime: string;
    endTime: string;
    attendees: number;
    selectedItem: Item;
    selectedConfiguration: Configuration;
    
}

