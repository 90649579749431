import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'opt-secondary-search',
  templateUrl: './secondary-search.component.html',
  //styleUrls: ['./secondary-search.component.scss']
})
export class SecondarySearchComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
