export enum Action {

    OnAddItemToBooking = 1,
    OnUpdateBookingItem = 2,
    OnBookingItemDateChange = 3,
    OnSubmitEnquiry = 4,
    OnPopulateUnavailableCalenderSlots = 5,
    OnChangeItem = 6,
    OnPackageChange = 7,
    OnTimeChange = 8
}

export enum ErrorType {

    Error = 1,
    Warning = 2,
}

export enum PriceType {

    // priority is similar to id
    Varied = 1,
    Hourly = 2,
    Daily = 3,
    Half_day = 4,
    Session = 5,
    Seasonal = 6,
    Covers = 7,
    Monthly = 8,
    Yearly = 9, 
}

export enum PORTAL_TYPE {
    CASUAL_PORTAL = "1",
    PAYMENT_PORTAL = "2",
    REGULAR_PORTAL = "3",
    PUBLIC_PORTAL = "4",
    WASTE_PORTAL = "5",
    BOOKING_PORTAL = "6"
}

export enum ConditionOperator{
    EQUAL_OPERATOR = '1', 
    GREATERTHAN_OPERATOR = '2',
    LESSTHAN_OPERATOR = '3',
    ANY_OPERATOR = '4',
    ALL_OPERATOR = '5',
}