import { Observable ,  Subscription } from "rxjs";
import { BaseComponent } from "src/modules/shared/base.component";


export function safeSubscribe<T>(this: Observable<T>, component: BaseComponent,
    next?: (value: T) => void, error?: (error: T) => void, complete?: () => void, ): Subscription {
    let sub = this.subscribe(next, error, complete);
    component.markForSafeDelete(sub);
    return sub;
}
//Observable.prototype.safeSubscribe = safeSubscribe;

//declare module 'rxjs/Observable' {
//    interface Observable<T> {
//        safeSubscribe: typeof safeSubscribe;
//    }
//}

/****  add safeSubscribe to Observable ****/
(Observable as any).prototype.safeSubscribe = safeSubscribe;
declare module 'rxjs/internal/Observable' { // rxjs5 would be rxjs/Observable
    export interface Observable<T> {
        safeSubscribe: typeof safeSubscribe;
    }
}