import { ICart } from 'src/modules/store/index';
import { environment } from 'src/environments/environment';
import { ItemPriceGroup } from 'src/modules/models/item/item-price-group';
import { Item} from 'src/modules/models/item/item';
import { DecimalPipe } from '@angular/common';

export class BookingAdaptor
{
    //Booking price concession need to be consider for edit booking
    static getPriceConcession(state: ICart):number
    {
        let isLoggedIn = (state.contact != null && state.contact.firstName != null && state.contact.firstName != "") ? true : false;
        let customerDefaultPriceConsessionId = (isLoggedIn) ? (state.contact.client.priceConcessionId != "") ? state.contact.client.priceConcessionId : 0 : 0;
        let isAllImmediateConfirmed = state.isAllImmediateConfirmed;

        let priceConsessionId = 0;
        if (isLoggedIn && customerDefaultPriceConsessionId && customerDefaultPriceConsessionId >= 0) {
            priceConsessionId = customerDefaultPriceConsessionId;
        } else if (isAllImmediateConfirmed)
        {
            priceConsessionId = environment.ImmediateConfirmation.DefaultPriceConsessionId;
        }
        return priceConsessionId;
    }

    static isRange(minP: ItemPriceGroup, maxP: ItemPriceGroup) {
        if ((minP == undefined || minP.priceIncludingTax == undefined) ||
            (maxP == undefined || maxP.priceIncludingTax == undefined))
            return false;

        var min = (minP && minP.priceIncludingTax) ? minP.priceIncludingTax : 0;
        var max = (maxP && maxP.priceIncludingTax) ? maxP.priceIncludingTax : 0;

        var dp: DecimalPipe = new DecimalPipe('en');
        return dp.transform(min, '1.2-2') != dp.transform(max, '1.2-2');

    }
    static getItemPrice = (itm: Item, isCalculated = 0) => {
        if (itm)
        {
            if (isCalculated == 0)
                return (itm.itemMinPrice != undefined) ? itm.itemMinPrice : itm.itemMaxPrice; 
            else
                return (itm.calculatedMinPrice != undefined && itm.calculatedMinPrice.priceIncludingTax != undefined) ? itm.calculatedMinPrice : itm.calculatedMaxPrice;
        }
        return;
    }  
}