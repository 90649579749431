import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'opt-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  //styleUrls: ['./delete-confirmation.component.scss']
})
export class DeleteConfirmationComponent implements OnInit {
    @Output() onAdd = new EventEmitter<any>(true);
    constructor(public matDialogRef: MatDialogRef<DeleteConfirmationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }


  ngOnInit() {
  }

    cancelClick() {
        this.onAdd.emit(false);
        this.matDialogRef.close();
    }

    okClick() {
        this.onAdd.emit(true);
        this.matDialogRef.close();
    }
}
