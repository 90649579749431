<mat-expansion-panel [expanded]="true" [disabled]="true">
  <mat-expansion-panel-header  class="mat-header">
    <opt-question-header [dates]="dates" [isMandatory]="bookingQues?.question?.mandatory"
      [questionNumber]="questionNumber" [questionText]="bookingQues?.question?.questionText"
      (popselectedDates)="RecveselectedDates($event)"></opt-question-header>
  </mat-expansion-panel-header>
  <div class="wp_flex--container flex-column">
    <div class="wp_flex__item--inherit ">

      <!-- <div class="wp_flex--container flex-column">
        <div class="wp_flex__item--inherit wp_flex__item--vcenter">
          <div>
            <mat-slide-toggle [(ngModel)]="isAdvanced" (ngModelChange)="onAdChange()">Advanced</mat-slide-toggle>
          </div>
        </div>
      </div> -->
      <ckeditor id="editor{{bookingQues?.id}}"
            [config] = "toolbarConfig"
            [data]="bookingQues.answer"
            (change)="inputChanged($event)"
            [editor]="editor"></ckeditor>
      <!-- <mat-form-field class="" *ngIf="!isAdvanced">
        <textarea id="{{bookingQues?.id}}" matInput placeholder="" (input)="inputChanged($event)"
          [(ngModel)]="bookingQues.answer"></textarea>
      </mat-form-field> -->
    </div>
  </div>
</mat-expansion-panel>
