
<div class="wp-modal-wrapper wp-message-modal">
    <div class="mmc-modal-body">
        <div class="mmc_flex--container pw-fd-col wp-modal-title">
            <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-h4 pw-text-semi-bold pw-text-color__primary">Request access to create reqular bookings online</div>
            <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-s1 pw-text-color__wp-primery">Your request has been sent, we will respond to you once we have reviewed your profile.</div>
            <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-s1 pw-text-color__tertiary">Provide more details on the activities you wish to conduct along with your preferred venues.</div>
        </div>
        <div class="mmc_flex--container inner">
            <mat-form-field class="example-full-width">
                <!--<mat-label>Address</mat-label>-->
                <textarea matInput placeholder=""></textarea>
            </mat-form-field>
        </div>

        <div class="btn-wrapper">
            <button type="submit" class="mmc-btn mmc-btn-secondary--small main mmc_flex__item--vcenter" tabindex="0" mat-button>Resend request</button>
        </div>
    </div>
</div>


