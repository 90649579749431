import { environment } from 'src/environments/environment';

import { Item, UpsellSummary, UnitPrice } from '../item/item';
import { ItemPriceGroup } from '../item/item-price-group';
import { AppSetting } from '../settings/casual-portal/app-setting';


export class UpsellAdaptor {

    static populateItemDetail(upsell: UpsellSummary, itemPriceGroups: ItemPriceGroup[], packageClassId: number = 0, minPF?: ItemPriceGroup, maxPF?: ItemPriceGroup, concessionsId: number = 0) {

        if (itemPriceGroups != undefined) {
            //filter for hourly price type           
            if (environment.PriceTypeId && environment.PriceTypeId > 0) {
                upsell.itemPriceGroups = [];
                itemPriceGroups.forEach(ip => {
                    if (ip.priceTypeId == environment.PriceTypeId) {
                        upsell.itemPriceGroups.push(ip);
                    }
                });
            }

            //1: min and max price, 
            //2: defined price concession(PriceMinConsessionId, PriceMaxConsessionId)
            //3: package class wise min max price concession

            let _minP: ItemPriceGroup, _maxP: ItemPriceGroup;
            let minPriceConcession = 0;
            let maxPriceConcession = 0;
            var _appSettings = environment.AppSetting as AppSetting;
            var _upsell =   _appSettings.BookingConfig.UpsellPrice;
            if (concessionsId > 0)//customer concession or default concession for immediate booking
            {
                minPriceConcession = maxPriceConcession = concessionsId;
            }
            else if (_upsell.PriceDisplayOption == 2) {
                minPriceConcession = _upsell.PriceMinConsessionId;
                maxPriceConcession = _upsell.PriceMaxConsessionId;
            } else if (_upsell.PriceDisplayOption == 3) {
                let pc = environment.DefaultPackageClassId;
                if (packageClassId && packageClassId > 0)
                    pc = packageClassId;
                let selectedPackageClass = _upsell.PackageClases.find(a => a.Id == pc);
                if (selectedPackageClass) {
                    minPriceConcession = selectedPackageClass.PriceMinConsessionId;
                    maxPriceConcession = selectedPackageClass.PriceMaxConsessionId;
                } else {
                    return;//Data issue
                }
            }
            //item.itemPriceGroups.forEach(pr => 
            if (upsell.itemPriceGroups && upsell.itemPriceGroups.length>0)
            {
                for (var i = 0; i < upsell.itemPriceGroups.length; i++)
                {
                    var pr = upsell.itemPriceGroups[i];
                   
                     // unit price calcuation 
                    //2	Hourly
                    //3	Daily
                    //4	Half - day
                    //5	Session
                    //7	Covers

                    //3 Wine            Covers, Session
                    //4	Resources		Hourly, Half - Day, Daily, Session
                    //5	Product			Covers, Session
                    //6	Service			Hourly, Half - Day, Daily, Session
                    //7	Catering		Covers, Session

                    if (upsell.itemTypeID == 3 || upsell.itemTypeID == 7 || upsell.itemTypeID == 5)
                    {
                        //if (pr.priceTypeId == 5 || pr.priceTypeId==7)
                        {
                            //min
                            if (minPriceConcession == 0 || pr.concessionId == minPriceConcession) {
                                var prc = UpsellAdaptor.getUpsellPriceInstance(upsell, pr.priceTypeId);
                                if (prc && (prc.minPrice == undefined || pr.priceIncludingTax < prc.minPrice.priceIncludingTax)) {
                                    prc.minPrice = pr;
                                }
                            }
                            //max
                            if (maxPriceConcession == 0 || pr.concessionId == maxPriceConcession) {
                                var prc = UpsellAdaptor.getUpsellPriceInstance(upsell, pr.priceTypeId);
                                if (prc && (prc.maxPrice == undefined || pr.priceIncludingTax > prc.maxPrice.priceIncludingTax)) {
                                  prc.maxPrice = pr;
                                }
                            }
                        }
                    } else
                    {
                        //if (pr.priceTypeId == 2 || pr.priceTypeId == 3 || pr.priceTypeId == 4)
                        {
                            //min
                            if (minPriceConcession == 0 || pr.concessionId == minPriceConcession) {
                                var prc = UpsellAdaptor.getUpsellPriceInstance(upsell, pr.priceTypeId);
                                if (prc && (prc.minPrice == undefined || pr.priceIncludingTax < prc.minPrice.priceIncludingTax)) {
                                    prc.minPrice = pr;
                                }
                            }
                            //max
                            if (maxPriceConcession == 0 || pr.concessionId == maxPriceConcession) {
                                var prc = UpsellAdaptor.getUpsellPriceInstance(upsell, pr.priceTypeId);
                                if (prc && (prc.maxPrice == undefined || pr.priceIncludingTax > prc.maxPrice.priceIncludingTax)) {
                                    prc.maxPrice = pr;
                                }
                            }
                        }
                    }

                    if (minPriceConcession == 0 || pr.concessionId == minPriceConcession) {
                        if (_minP == undefined || pr.priceIncludingTax < _minP.priceIncludingTax) {
                            _minP = pr;
                        }
                    }
                    if (maxPriceConcession == 0 || pr.concessionId == maxPriceConcession) {
                        if (_maxP == undefined || pr.priceIncludingTax > _maxP.priceIncludingTax) {
                            _maxP = pr;
                        }
                    }
                }
                //);

                //if cover price is there ignore session price
                //Todo
                
                   
                    //no restriction in price concession
                    if (!environment.IsAllowMultiplePriceConsession && _minP == undefined && _maxP == undefined && upsell.itemPriceGroups.length > 0) {
                        _minP = upsell.itemPriceGroups[0];
                    }
                    //set price group for booking Item                
                    upsell.itemPrice = _minP == undefined ? _maxP : _minP;
                    upsell.minPrice = _minP == undefined ? _maxP : _minP;
                    upsell.maxPrice = _maxP == undefined ? _minP : _maxP;
                    upsell.maxPriceConcessionId = upsell.maxPrice.concessionId;
                    upsell.minPriceConcessionId = upsell.minPrice.concessionId;


                    if (maxPF == undefined || _maxP.priceIncludingTax > maxPF.priceIncludingTax)
                        maxPF = _maxP;

                    if (minPF == undefined || _minP.priceIncludingTax < minPF.priceIncludingTax)
                        minPF = _minP;
                
                    upsell.itemPriceGroups = itemPriceGroups;
            }

            // 

        }
    }

    static getUpsellPriceInstance(upsell: UpsellSummary, priceTypeId: number):UnitPrice
    {
        if (upsell)
        {
            switch (priceTypeId)
            {
                case 7:
                    if (upsell.coverPrice == undefined) upsell.coverPrice = new UnitPrice();
                    return upsell.coverPrice;
                    break;
                case 5:
                    if (upsell.sessionPrice == undefined) upsell.sessionPrice = new UnitPrice();
                    return upsell.sessionPrice;
                    break;
                case 2:
                    if (upsell.hourlyPrice == undefined) upsell.hourlyPrice = new UnitPrice();
                    return upsell.hourlyPrice;
                 
                    break;
                case 3:
                    if (upsell.dailyPrice == undefined) upsell.dailyPrice = new UnitPrice();
                    return upsell.dailyPrice;
                    break;
                case 4:
                    if (upsell.halfDayPrice == undefined) upsell.halfDayPrice = new UnitPrice();
                    return upsell.halfDayPrice;
                    break;
              
            }
        }
        return null;
    }
    /*static populateItemDetail(item: UpsellSummary, itemPriceGroups: ItemPriceGroup[], packageClassId: number, minP?: ItemPriceGroup, maxP?: ItemPriceGroup, priceConsessionId?: number) {

        if (item.itemPriceGroups != undefined) {
            if (environment.UpsellPrice.PriceDisplayOption == 3) {
                this.populateItemUnitPrice(item, packageClassId, priceConsessionId);
            } else {
                //filter for hourly price type
                item.itemPriceGroups = [];
                itemPriceGroups.forEach(ip => {
                    if (ip.priceTypeId == environment.PriceTypeId) {
                        item.itemPriceGroups.push(ip);
                    }
                });

                item.itemPriceGroups.forEach(pr => {
                    //1:min and max price, 
                    //2: defined price concession(PriceMinConsessionId, PriceMaxConsessionId)
                    if (environment.PriceDisplayOption == 1) {
                        if (minP == undefined || pr.priceIncludingTax < minP.priceIncludingTax) {
                            minP = pr;
                        }
                        if (maxP == undefined || pr.priceIncludingTax > maxP.priceIncludingTax) {
                            maxP = pr;
                        }
                    } else if (environment.PriceDisplayOption == 2) {
                        if (environment.PriceMinConsessionId == pr.concessionId) {
                            if (minP == undefined || pr.priceIncludingTax < minP.priceIncludingTax) {
                                minP = pr;
                            }
                        }
                        if (environment.PriceMaxConsessionId == pr.concessionId) {
                            if (maxP == undefined || pr.priceIncludingTax > maxP.priceIncludingTax) {
                                maxP = pr;
                            }
                        }
                    }
                }
                );
                if (minP == undefined && maxP == undefined && item.itemPriceGroups.length > 0) {
                    minP = item.itemPriceGroups[0];
                }
                //set price group for booking Item
                item.itemPrice = minP == undefined ? maxP : minP;
                item.minPrice = minP == undefined ? maxP : minP;
                item.maxPrice = maxP == undefined ? minP : maxP;
                item.itemPriceGroups = itemPriceGroups;
            }
            // 
        }
    }

 
    static populateItemUnitPrice(upsell: UpsellSummary, packageClass?: number, priceConsessionId?: number) {
        if ((priceConsessionId == undefined) || upsell.itemPriceGroups == undefined) return;
        let minConcessionId = 0;
        let maxConcessionId = 0;
        let displayPriceRange: boolean = true;
        if (priceConsessionId != undefined && priceConsessionId != 0) {
            minConcessionId = priceConsessionId;
            displayPriceRange = false;
        } else if (packageClass != undefined) {
            let selectedPackageClass = environment.UpsellPrice.PackageClases.find(a => a.Id == packageClass);

            if ((selectedPackageClass.PriceMinConsessionId == "" && selectedPackageClass.PriceMaxConsessionId == "") || upsell.itemPriceGroups == undefined) {
                return;
            } else {
                minConcessionId = selectedPackageClass.PriceMinConsessionId;
                maxConcessionId = selectedPackageClass.PriceMaxConsessionId;
            }
        }


        // unit price calcuation 

        //2	Hourly
        //3	Daily
        //4	Half - day
        //5	Session
        //7	Covers

        //3 Wine            Covers, Session
        //4	Resources		Hourly, Half - Day, Daily, Session
        //5	Product			Covers, Session
        //6	Service			Hourly, Half - Day, Daily, Session
        //7	Catering		Covers, Session


        upsell.itemPriceGroups.forEach(function (pr) {
            if (upsell.itemTypeID == 3 || upsell.itemTypeID == 7 || upsell.itemTypeID == 5) {
                // cover price 
                if (displayPriceRange) {
                    if (minConcessionId == pr.concessionId && pr.priceTypeId == 7 && pr.priceIncludingTax != 0 && !isNaN(pr.priceIncludingTax)) {
                        upsell.coverPrice = upsell.coverPrice == undefined ? new UnitPrice() : upsell.coverPrice;
                        upsell.coverPrice.minPrice = JSON.parse(JSON.stringify(pr));
                    }
                    if (maxConcessionId == pr.concessionId && pr.priceTypeId == 7 && pr.priceIncludingTax != 0 && !isNaN(pr.priceIncludingTax)) {
                        upsell.coverPrice = upsell.coverPrice == undefined ? new UnitPrice() : upsell.coverPrice;
                        upsell.coverPrice.maxPrice = JSON.parse(JSON.stringify(pr));
                    }
                } else {
                    if (minConcessionId == pr.concessionId && pr.priceTypeId == 7 && pr.priceIncludingTax != 0 && !isNaN(pr.priceIncludingTax)) {
                        upsell.coverPrice = upsell.coverPrice == undefined ? new UnitPrice() : upsell.coverPrice;
                        upsell.coverPrice.minPrice = JSON.parse(JSON.stringify(pr));

                        upsell.coverPrice = upsell.coverPrice == undefined ? new UnitPrice() : upsell.coverPrice;
                        upsell.coverPrice.maxPrice = null;
                    }
                }

            }
            else {
                if (displayPriceRange) {
                    // Hourly price 
                    if (minConcessionId == pr.concessionId && pr.priceTypeId == 2 && pr.priceIncludingTax != 0 && !isNaN(pr.priceIncludingTax)) {
                        upsell.hourlyPrice = upsell.hourlyPrice == undefined ? new UnitPrice() : upsell.hourlyPrice;
                        upsell.hourlyPrice.minPrice = JSON.parse(JSON.stringify(pr));
                    }
                    if (maxConcessionId == pr.concessionId && pr.priceTypeId == 2 && pr.priceIncludingTax != 0 && !isNaN(pr.priceIncludingTax)) {
                        upsell.hourlyPrice = upsell.hourlyPrice == undefined ? new UnitPrice() : upsell.hourlyPrice;
                        upsell.hourlyPrice.maxPrice = JSON.parse(JSON.stringify(pr));
                    }


                    // Half-Day price 
                    if (minConcessionId == pr.concessionId && pr.priceTypeId == 4 && pr.priceIncludingTax != 0 && !isNaN(pr.priceIncludingTax)) {
                        upsell.halfDayPrice = upsell.halfDayPrice == undefined ? new UnitPrice() : upsell.halfDayPrice;
                        upsell.halfDayPrice.minPrice = JSON.parse(JSON.stringify(pr));
                    }
                    if (maxConcessionId == pr.concessionId && pr.priceTypeId == 4 && pr.priceIncludingTax != 0 && !isNaN(pr.priceIncludingTax)) {
                        upsell.halfDayPrice = upsell.halfDayPrice == undefined ? new UnitPrice() : upsell.halfDayPrice;
                        upsell.halfDayPrice.maxPrice = JSON.parse(JSON.stringify(pr));
                    }

                    // daily price 
                    if (minConcessionId == pr.concessionId && pr.priceTypeId == 3 && pr.priceIncludingTax != 0 && !isNaN(pr.priceIncludingTax)) {
                        upsell.dailyPrice = upsell.dailyPrice == undefined ? new UnitPrice() : upsell.dailyPrice;
                        upsell.dailyPrice.minPrice = JSON.parse(JSON.stringify(pr));
                    }
                    if (maxConcessionId == pr.concessionId && pr.priceTypeId == 3 && pr.priceIncludingTax != 0 && !isNaN(pr.priceIncludingTax)) {
                        upsell.dailyPrice = upsell.dailyPrice == undefined ? new UnitPrice() : upsell.dailyPrice;
                        upsell.dailyPrice.maxPrice = JSON.parse(JSON.stringify(pr));
                    }
                } else {
                    // Hourly price 
                    if (minConcessionId == pr.concessionId && pr.priceTypeId == 2 && pr.priceIncludingTax != 0 && !isNaN(pr.priceIncludingTax)) {
                        upsell.hourlyPrice = upsell.hourlyPrice == undefined ? new UnitPrice() : upsell.hourlyPrice;
                        upsell.hourlyPrice.minPrice = JSON.parse(JSON.stringify(pr));

                        upsell.hourlyPrice = upsell.hourlyPrice == undefined ? new UnitPrice() : upsell.hourlyPrice;
                        upsell.hourlyPrice.maxPrice = null
                    }
                    // Half-Day price 
                    if (minConcessionId == pr.concessionId && pr.priceTypeId == 4 && pr.priceIncludingTax != 0 && !isNaN(pr.priceIncludingTax)) {
                        upsell.halfDayPrice = upsell.halfDayPrice == undefined ? new UnitPrice() : upsell.halfDayPrice;
                        upsell.halfDayPrice.minPrice = JSON.parse(JSON.stringify(pr));

                        upsell.halfDayPrice = upsell.halfDayPrice == undefined ? new UnitPrice() : upsell.halfDayPrice;
                        upsell.halfDayPrice.maxPrice = null
                    }
                    // daily price 
                    if (minConcessionId == pr.concessionId && pr.priceTypeId == 3 && pr.priceIncludingTax != 0 && !isNaN(pr.priceIncludingTax)) {
                        upsell.dailyPrice = upsell.dailyPrice == undefined ? new UnitPrice() : upsell.dailyPrice;
                        upsell.dailyPrice.minPrice = JSON.parse(JSON.stringify(pr));

                        upsell.dailyPrice = upsell.dailyPrice == undefined ? new UnitPrice() : upsell.dailyPrice;
                        upsell.dailyPrice.maxPrice = null
                    }
                }


            }

            // session price
            if (upsell.coverPrice == undefined) {
                if (displayPriceRange) {
                    if (minConcessionId == pr.concessionId && pr.priceTypeId == 5 && pr.priceIncludingTax != 0 && !isNaN(pr.priceIncludingTax)) {
                        upsell.sessionPrice = upsell.sessionPrice == undefined ? new UnitPrice() : upsell.sessionPrice;
                        upsell.sessionPrice.minPrice = JSON.parse(JSON.stringify(pr));
                    }
                    if (maxConcessionId == pr.concessionId && pr.priceTypeId == 5 && pr.priceIncludingTax != 0 && !isNaN(pr.priceIncludingTax)) {
                        upsell.sessionPrice = upsell.sessionPrice == undefined ? new UnitPrice() : upsell.sessionPrice;
                        upsell.sessionPrice.maxPrice = JSON.parse(JSON.stringify(pr));
                    }
                } else {
                    if (minConcessionId == pr.concessionId && pr.priceTypeId == 5 && pr.priceIncludingTax != 0 && !isNaN(pr.priceIncludingTax)) {
                        upsell.sessionPrice = upsell.sessionPrice == undefined ? new UnitPrice() : upsell.sessionPrice;
                        upsell.sessionPrice.minPrice = JSON.parse(JSON.stringify(pr));

                        upsell.sessionPrice = upsell.sessionPrice == undefined ? new UnitPrice() : upsell.sessionPrice;
                        upsell.sessionPrice.maxPrice = null;
                    }
                }
            }
        });

    }
   */ 
}
