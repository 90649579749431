import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { PublicBookingSettings, RegionalSetting } from 'src/modules/models/settings/public-booking/public-booking-setting';
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: 'PbCustomDate',
  pure:false
})
export class PbCustomDatePipe implements PipeTransform {
  regionalSetting: RegionalSetting;
  dateFormat: string;

  constructor(public datePipe: DatePipe, private translateService: TranslateService) {
    this.regionalSetting = (environment.PublicBookingSetting as PublicBookingSettings).regionalSetting;
    if (this.regionalSetting)
    {
      this.dateFormat = this.regionalSetting.defaultDateFormat;
      this.dateFormat = this.dateFormat.replace('ddd', 'EEE');
    }
  }

  transform(value: any, formatType: string): any {
    this.datePipe = new DatePipe(this.translateService.currentLang);
    if (formatType) {
      return this.datePipe.transform(value, formatType);
    }
    else if (this.dateFormat === '' || this.dateFormat === undefined) {
      return this.datePipe.transform(value, 'EEEE, MMMM d, y');
    }
    return this.datePipe.transform(value, this.dateFormat);
  }

}
