
export class Venue {
  public id: string;
  public name: string;
  public type: string;
  public latitude: number;
  public longitude: number;
  public description: string;
  public direction: string;
  public url: string;
  public telephone: string;
  public email: string;
  public width: number;
  public length: number;
  public image?: string;
  public documents: any[];

  constructor() { }

  inilialize(obj: any) {
    this.id = obj && obj.id;
    this.name = (obj && obj.name) || '';
    this.type = (obj && obj.type) || '';
    this.latitude = obj && obj.latitude ? +obj.latitude : 0;
    this.longitude = obj && obj.longitude ? +obj.longitude : 0;
    this.description = (obj && obj.description) || '';
    this.direction = (obj && obj.direction) || '';
    this.url = (obj && obj.url) || '';
    this.telephone = (obj && obj.telephone) || '';
    this.email = (obj && obj.email) || '';
    this.documents = (obj && obj.documents ) || [];
    this.image = this.getImageURL(this.documents);
    // this.width = CommonService.getRandomInt(8000, 5000);
    // this.length = CommonService.getRandomInt(8000, 5000);
    this.width = 2000
    this.length = 2000;
    return this;
  }

  getImageURL(documents: any []): string {
    if(this.id === "2") {
      return "assets/images/greek t.jpg";
    }else if(this.id === "4") {
      return "assets/images/gt.jpg";
    }
    return undefined
    //   // for (const document of documents) {
    //   //   const documentFileType = document.documentFile.split('.')[1];
    //   //   if (documentFileType === 'png' || documentFileType === 'jpg') {
    //   //   }

    //   // }
    // return '';
  }
}




