import { BaseResource } from '../base-resource';
import { ItemPriceGroup } from './item-price-group';
import { ItemAvailabilityPrivate } from './item-availability-private';
import { ItemAvailabilityPublic } from './item-availability-public';
import { ItemAvailability } from './item-availability';
import { Configuration } from '../asset/configuration';
import { Question } from 'src/modules/models/item/question';
import { environment } from '../../../environments/environment'
import { DecimalPipe } from '@angular/common';
import { ItemUserDefinedField } from '../user-defined-field/item-user-defined-field';

export class Item extends BaseResource {
    constructor() {
        super();
        this.qty = 1;
    }
    name: string;
    active: boolean;
    availableFrom: string;
    availableTo: string;
    commissionable: boolean;
    defaultPriceConcessionID: number;
    defaultPriceConcessionName: string;
    discountable: boolean;
    itemAssets: ItemAssets[];
    itemCategory: string;
    itemCategoryID: number;
    itemType: string;
    itemTypeID: number;
    itinararyItem: boolean;
    itemPriceGroups: ItemPriceGroup[];
    leadDays: number;
    multiplesOnly: boolean;
    nameOnReport: string;
    partOfPackage: string;
    upsellItem: boolean;
    salesCategories: SalesCategory[];
    allocateToAllAssets: boolean;
    question: Question[];
    qty: number;
    itemMaxPrice: ItemPriceGroup;
    itemMinPrice: ItemPriceGroup;
    totalAnsweredQuestions?: number;
    allowImmediateConfirmedBookings: number;
    calculatedMinPrice: ItemPriceGroup;
    calculatedMaxPrice: ItemPriceGroup;
    userDefinedFields: ItemUserDefinedField[];
    originalAvailability = 0;
    availabilityStatus = 0;
    covers = 0;
    itemAvailabilityPublic: any[];
    itemAvailability: any[];
    CoversPerItem: number;
}

export class ItemAssets extends BaseResource {
    configuration: Configuration;
    asset: BaseResource;
}
export class SalesCategory extends BaseResource {

}


export class UpsellSummary extends Item {
    constructor() {
        super();
        this.qty = 1;

    }
    qty: number;
    qtyPerDay: number;
    minPrice: ItemPriceGroup;
    maxPriceConcessionId: number;
    minPriceConcessionId: number;
    maxPrice: ItemPriceGroup;
    itemPrice: ItemPriceGroup;
    coverPrice: UnitPrice;
    sessionPrice: UnitPrice;
    hourlyPrice: UnitPrice;
    halfDayPrice: UnitPrice;
    dailyPrice: UnitPrice;
    startTime: string;
    endTime: string;
    isAdded: boolean;
    url: string = environment.ApiUrl + "dist/assets/images/no-image.png";
    question: Question[];
    itemAvailabilityPrivate: ItemAvailabilityPrivate[];
    itemAvailabilityPublic: ItemAvailabilityPublic[];
    itemAvailability: ItemAvailability[];
    availabilityUl: boolean;
    availabilityQty: number;
    loaded: boolean;
    bookingItemPrices: BookingItemPrices[];

}

export class UnitPrice   {
    minPrice: ItemPriceGroup;
    maxPrice: ItemPriceGroup;
}

export class BookingItemPrices {
    priceType: string;
    rate: number;
    unit: number;
}





