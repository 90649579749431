<div class="message--error">
    <div class="wp_flex--container">
        <div class="wp_flex__item--inherit wp_flex__item--vcenter">
            <div class="message-icon">
                <span class="wp-txt-body1--small">!</span>
            </div>
        </div>
        <div class="wp_flex__item wp_flex__item--vcenter">
            <div class="wp_flex--container flex-column">
                <div class="wp_flex__item" *ngIf ="errorMessageType==1">
                    <h4 class="wp-heading-4">{{messageSummary}}</h4>
                </div>
                <div class="wp_flex__item" *ngIf="MessageDetail">
                    <div class="wp-txt-body1--small">{{MessageDetail}}</div>
                </div>

                <div class="wp_flex__item" *ngFor="let MessageDetailItem of MessageDetailList; let j=index">
                    <div class="wp-txt-body1--small">{{MessageDetailItem}}</div>
                </div>

            </div>
        </div>
        <div class="wp_flex__item--inherit wp_flex__item--vcenter" *ngIf ="errorMessageType==1">
            <button mat-button class="mat-btn-secondary--small">Resolve Conflict</button>
        </div>
    </div>
</div>