
import { BespokePackage } from 'src/modules/models/asset/bespoke-package';
import { BaseResource } from '../base-resource';
export class TeamsGroup extends BaseResource {
    constructor() {
        super();
        this.type = "TeamsGroup";
    }
    id: string;
    packageId: string;
    activityGroup: string;
    participants: number;
    disable: boolean = true;
    packages: BespokePackage[];
    
}