import { createSelector } from "@ngrx/store";
import { BookingAppState } from ".";
import { SeatingConfigurationState } from "./configuration.reducer";

export const selectConfiguration = (state: BookingAppState) => state.seatingConfiguration;

export const selectSelectedEvent = createSelector(selectConfiguration,
  (state: SeatingConfigurationState) => state.selectedEvent
);

/*
export const selectPriceBands = createSelector(selectConfiguration,
  (state: SeatingConfigurationState) => state.priceBands
);
*/

export const selectEventDate = createSelector(selectConfiguration,
  (state: SeatingConfigurationState) => state?.eventDate
);

export const selectTimeslot = createSelector(selectConfiguration,
  (state: SeatingConfigurationState) => state.timeslots
);
export const selectSelectedSeats = createSelector(selectConfiguration,
  (state: SeatingConfigurationState) => state?.selectedSeats
);
export const selectSelectedBlocks = createSelector(selectConfiguration,
  (state: SeatingConfigurationState) => state?.selectedBlocks
)
export const selectMiniMapView = createSelector(selectConfiguration,
  (state: SeatingConfigurationState) => state.venueMapConfiguration.enableMiniMap
);
export const selectReset = createSelector(selectConfiguration,
  (state: SeatingConfigurationState) => state.venueMapConfiguration.reset
);
export const selectBAMode = createSelector(selectConfiguration,
  (state: SeatingConfigurationState) => state.accessMode
);
export const selectVenueMapSeatView = createSelector(selectConfiguration,
  (state: SeatingConfigurationState) => state.venueMapConfiguration.enableSeatView
);
export const selectLocatorId = createSelector(selectConfiguration,
  (state: SeatingConfigurationState) => state.IDForLocator
);
export const selectVenueMapZoom = createSelector(selectConfiguration,
  (state: SeatingConfigurationState) => state.venueMapConfiguration.zoom
);
export const selectVenueMapConfiguration = createSelector(selectConfiguration,
  (state: SeatingConfigurationState) => state.venueMapConfiguration
);

export const selectPriceBandFilter = createSelector(selectConfiguration,
  (state: SeatingConfigurationState) => state.filters.priceBands
);

export const selectPriceRangeFilter = createSelector(selectConfiguration,
  (state: SeatingConfigurationState) => state.filters.priceRange
);

export const selectSeatTypeFilter = createSelector(selectConfiguration,
  (state: SeatingConfigurationState) => state.filters.seatTypes
);

export const selectEventPackages = createSelector(selectConfiguration,
  (state: SeatingConfigurationState) => state.eventPackages
);

