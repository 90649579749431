import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PublicBookingService } from 'src/modules/services/public-web/public-booking.service';
import * as LanguageSelector from '../../../store/public-web/select-language/language-selector';

import { BaseComponent } from '../../base.component';

@Component({
  selector: 'opt-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent extends BaseComponent implements OnInit {
  languages = [];
  selectedLanguage: string = '';

  constructor(
    private _publicBookingService: PublicBookingService,
    private store: Store<any>,
    private translate: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this.store.select(LanguageSelector.getLanguages).safeSubscribe(this, storedLanguages => {
      this.languages = !!storedLanguages ? storedLanguages : [];
    });
    this.store.select(LanguageSelector.getSelectedLanguage).safeSubscribe(this, storedLanguage => {
      this.selectedLanguage = !!storedLanguage ? storedLanguage : '';
    });
    this.translate.use(this.selectedLanguage.toLowerCase());
  }

  switchLanguage() {
    //Call below line whenever language is changed
    this._publicBookingService.saveSelectedLanguage(this.selectedLanguage).safeSubscribe(this, () => { this.translate.use(this.selectedLanguage.toLowerCase()); });
  }
}
