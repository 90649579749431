import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { SiteLayoutModule } from "../layout/site-layout/site-layout.module";
import { OptCommonModules } from "../shared/common.modules";
import { OptFormModules } from "../shared/form.modules";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, } from "@angular/material/core";
import { MomentDateAdapter } from "../booking-portal/common/date-time-adapter/moment-date-adapter";
import { OptMaterialModule } from "../shared/opt-material/opt-material.module";
import { PbCustomDatePipe } from "./pipe/pb-custom-date.pipe";
import { PbCustomItemQuantityPipe } from "./pipe/pb-custom-item-quantity.pipe";

import { QuestionComponent } from "./question/question.component";
import { QuestionHeaderComponent } from "./question/question-header.component";
import { BooleanQuestionComponent } from "./question/boolean-question/boolean-question.component";
import { FileUploadQuestionComponent } from "./question/file-upload-question/file-upload-question.component";
import {
  MultipleChoiceQuestionComponent
} from "./question/multiple-choice-question/multiple-choice-question.component";
import { RichTextQuestionComponent } from "./question/rich-text-question/rich-text-question.component";
import { SingleChoiceQuestionComponent } from "./question/single-choice-question/single-choice-question.component";
import { TextQuestionComponent } from "./question/text-question/text-question.component";
import { DateTimeQuestionComponent } from "./question/date-time-question/date-time-question.component";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { TimepickerModule } from "projects/libs/opt-timepicker/src/timepicker.module";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

const DT_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "DD MMM YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

//translation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "../dist/assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    PbCustomDatePipe,
    QuestionComponent,
    QuestionHeaderComponent,
    BooleanQuestionComponent,
    FileUploadQuestionComponent,
    MultipleChoiceQuestionComponent,
    RichTextQuestionComponent,
    SingleChoiceQuestionComponent,
    TextQuestionComponent,
    DateTimeQuestionComponent
  ],
  imports: [
    CommonModule,
    SiteLayoutModule,
    OptCommonModules,
    OptFormModules,
    CKEditorModule,
    TimepickerModule.forRoot()
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    OptMaterialModule,
    OptCommonModules,
    OptFormModules,
    SiteLayoutModule,
    PbCustomDatePipe,
    // Question
    QuestionComponent,
    QuestionHeaderComponent,
    BooleanQuestionComponent,
    FileUploadQuestionComponent,
    MultipleChoiceQuestionComponent,
    RichTextQuestionComponent,
    SingleChoiceQuestionComponent,
    TextQuestionComponent,
    DateTimeQuestionComponent,
  ],

  providers: [
    DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: DT_FORMATS },
  ],
})
export class PbCommonModule {
}
