import { HttpResponse, HttpResponseBase, HttpEvent } from '@angular/common/http';
import { Injectable} from '@angular/core';

import { BaseService } from './base/base.service';
import { Deserializer, Serializer } from 'json-api-format';
import { environment } from 'src/environments/environment';
import { RegularBookingSettings, BookingTypes } from 'src/modules/models/settings/regular-portal/regular-booking-setting';

import { Observable } from "rxjs";
import { map, catchError, switchMap, debounceTime } from "rxjs/operators";

import { BaseParam } from '../models/base-param';
//import { ParameterMapper } from 'src/modules/services/base/parameter-mapper';

//models
import { RecurrentTimeSlots } from 'src/modules/models/regular-portal/recurrent-time-slots';
import { AdHocDocument } from '../models/booking/adhoc-document';
import { QuestionSummary } from 'src/modules/models/booking/question-summary';
import { Question } from '../models/item/question'
import { BookingContact } from 'src/modules/models/booking/booking-contact';
import { UserData } from 'src/modules/models/login/userData';
//import { Booking } from 'src/modules/models/booking/booking';
//import { BookingPackages } from 'src/modules/models/booking/booking-packages';

import { VenueAssetsearchparams } from 'src/modules/models/regular-portal/venue-assets-search-param'
import { BookingSearchResult } from 'src/modules/models/booking/booking-search/booking-search-result';
import { InvoiceSearchResult, InvoicePreviewResult } from 'src/modules/models/booking/booking-search/invoice-search-result';

import { jsonIgnoreReplacer, jsonIgnore } from 'json-ignore';
import { BookingCancelPatch } from 'src/modules/models/booking/booking-cancel/booking-cancel';
//import { BookingPackages } from 'src/modules/models/booking/booking-packages';

import { VenueWiseAssets } from 'src/modules/models/regular-portal/location-resource';

import { ItemBookingQuestionnaire, PackageBookingQuestionnaire, GlobalBookingQuestionnaire, AnswerWisedBookingQuestionnaire } from 'src/modules/models/regular-portal/booking/question/booking-questionnaire'

//import { BookingItem } from 'src/modules/models/regular-portal/booking/booking-item';
import { BookingItemVm } from 'src/modules/models/regular-portal/booking/manage-teams/booking-item-vm';
import { Venue } from 'src/modules/models/regular-portal/booking/manage-teams/venue';
import { RecurrenceOption } from 'src/modules/models/regular-portal/recurrence-option';
import { RecurrentBookingItem, SplitBookingItem, AdHocBookingItem } from 'src/modules/models/regular-portal/booking/manage-teams/recurrent-booking';
import { BookingStatus } from 'src/modules/models/regular-portal/booking/booking'
//import { BookingStatus } from 'src/modules/models/regular-portal/booking/booking-status'
import { TimeSlot } from 'src/modules/models/regular-portal/booking/manage-teams/time-slot';
import { Booking } from 'src/modules/models/regular-portal/booking/booking';
import { Package } from 'src/modules/models/regular-portal/booking/booking-package';
import { BookingPackage, BookingVenues } from 'src/modules/models/regular-portal/booking/booking-package';
import { Timeslot as Timeslots } from '../models/asset/timeslots'
import { BaseResource } from '../models/base-resource';
import { Utility } from 'src/modules/utility';

import { ProductItemFilters } from 'src/modules/models/product/product-item-filters';
import { Item } from '../models/item/item'

import { BookingQuestion } from 'src/modules/models/regular-portal/booking/question/booking-question';
import { QuestionPackageFilters, QuestionItemFilters } from 'src/modules/models/regular-portal/booking/question/question-filter'
import { ReferenceData } from 'src/modules/models/reference-data';
import { RegularBookingAdapter } from 'src/modules/models/regular-portal/regular-booking-adapter';
///Date
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';

const moment = _rollupMoment || _moment;

@Injectable({
    providedIn: 'root'
})
export class RegualrBookingService {

    constructor(private _baseService: BaseService) {

    }

    // SaveBooking(BookingData: Booking, bookingID: string) {
    //    return this._baseService.Internal_WebAPI_POST("api/booking/save-booking/" + bookingID, BookingData).pipe(
    //        map((response) => {
    //            return response;
    //        })
    //    );
    //}

    SelectBooking(id: any): Observable<any> {

        return this._baseService.InternalWebAPIGET("api/regular-bookings/" + id).pipe(
            map((response: any) => {

                //let bk = new Booking();

                const getBookingData = this.populateBooking(response);

                return getBookingData;

                //if (response.data) {

                //    bk.id = response.data.id;

                //    // var _updateDate = moment(response.data.updateDate).format('DD MMM dddd hh:mm A');
                //    var _updateDate = moment(response.data.updateDate).format('DD MMM YYYY');
                //    bk.updateDate = _updateDate;
                //    bk.bookingStatus = new BookingStatus();
                //    bk.bookingStatus.name = response.data.bookingStatus.name;
                //    bk.contact = new BookingContact();
                //    bk.contact.id = response.data.contact.id;
                //    bk.totalAmount = response.data.totalAmount;
                //    bk.bookingReference = response.data.bookingReference;
                //    bk.eventName = response.data.eventName;
                //    bk.bookingDate = response.data.bookingDate;
                //    bk.seasonId = response.data.seasonId;
                //    bk.bookingTypeId = response.data.bookingTypeId;                   
                //    if (response.data.bookingQuestionnaires) {
                //        //global
                //        if (response.data.bookingQuestionnaires.globalQuestionnaires && response.data.bookingQuestionnaires.globalQuestionnaires.length > 0) {
                //            bk.globalbookingQuestionnaires = new GlobalBookingQuestionnaire();
                //            bk.globalbookingQuestionnaires.questionsTobeDisplay = [];
                //            response.data.bookingQuestionnaires.globalQuestionnaires.forEach(gq => {
                //                if (gq.bookingQuestions && gq.bookingQuestions.length > 0) {
                //                    gq.bookingQuestions.forEach(q => {
                //                        bk.globalbookingQuestionnaires.questionsTobeDisplay.push(q)

                //                    });
                //                }
                //            });
                //        }
                //        //package
                //        if (response.data.bookingQuestionnaires.packageQuestionnaires) {
                //            bk.packagebookingQuestionnaires = [];

                //            response.data.bookingQuestionnaires.packageQuestionnaires.forEach(pq => {
                //                let pkq = new PackageBookingQuestionnaire();
                //                pkq.packageId = pq.packageId;
                //                pkq.packageName = pq.packageName;
                //                pkq.type = pq.type;
                //                pkq.isHaveMandatoryQuestion = false;
                //                pkq.questionsTobeDisplay = [];
                //                bk.packagebookingQuestionnaires.push(pkq);
                //                if (pq.bookingQuestions) {
                //                    pq.bookingQuestions.forEach(q => {
                //                        pkq.questionsTobeDisplay.push(q);
                //                        if (q.question && q.question.mandatory)
                //                            pkq.isHaveMandatoryQuestion = true;

                //                    });
                //                }
                //            });
                //        }
                //        //Item
                //        if (response.data.bookingQuestionnaires.itemQuestionnaires) {
                //            bk.itembookingQuestionnaires = [];

                //            response.data.bookingQuestionnaires.itemQuestionnaires.forEach(pq => {
                //                let pkq = new ItemBookingQuestionnaire();
                //                pkq.itemName = pq.name;
                //                pkq.itemId = pq.itemId;
                //                pkq.isHaveMandatoryQuestion = pq.isHaveMandatoryQuestion;
                //                pkq.answerWisedBookingQuestionnaireGroups = [];
                //                pq.questionMappingGroupViewModels.forEach(qmg => {
                //                    let awbqg = new AnswerWisedBookingQuestionnaire();
                //                    awbqg.dates = [];
                //                    awbqg.questionsTobeDisplay = [qmg.questionsTobeDisplay];
                //                    qmg.dates.forEach(q => {
                //                        awbqg.dates.push(q);
                //                    });
                //                    pkq.answerWisedBookingQuestionnaireGroups.push(awbqg);
                //                });
                //                bk.itembookingQuestionnaires.push(pkq);


                //            });
                //        }

                //    }


                //    if (response.data.bookingPackages && response.data.bookingPackages.length > 0) {                       
                //        bk.bookingPackages = [] as BookingPackage[];
                //        bk.questionPackageFilters = [] as QuestionPackageFilters[];                        
                //        response.data.bookingPackages.forEach((q, i) => {
                //            let pkg = new BookingPackage();
                //            pkg.attendees = q.attendees;
                //            pkg.id = q.id;
                //            pkg.name = q.name;
                //            pkg.packageEnd = q.packageEnd;
                //            pkg.packageStart = q.packageStart;
                //            pkg.unavailableDates = q.unavailableDates;
                //            pkg.totalAmount = q.totalAmount;
                //            pkg.bookingItem = [] as BookingItemVm[];
                //            pkg.bookingVenues = [] as BookingVenues[];
                //            pkg.package = new Package();
                //            pkg.package.id = q.packageId;
                //            if (q.salesCategories)
                //            pkg.salesCategories = q.salesCategories;
                //            if (q.packageRecurrenceOption != undefined)
                //            {
                //                pkg.packageRecurrenceOption = q.packageRecurrenceOption;
                //            }


                //            if (q.unavailableDatesPackage)
                //            {
                //                pkg.unavailableDatesPackage = [] as Date[];
                //                pkg.unavailableDatesPackage = q.unavailableDatesPackage;

                //            }

                //            if (q.venueDetails != undefined) {
                //                pkg.venue = new Venue();
                //                pkg.venue.id = q.venueDetails.id;
                //                pkg.venue.name = q.venueDetails.name;
                //            }

                //            let qpf = bk.questionPackageFilters.find(x => x.id == q.packageId);
                //            if (!qpf) {
                //                qpf = new QuestionPackageFilters();
                //                qpf.questionItemFilters = [] as QuestionItemFilters[];
                //                qpf.name = q.name;
                //                qpf.id = q.packageId;
                //            }
                //            //Venue level
                //            if (response.data.bookingPackages[i].bookingVenues != undefined && response.data.bookingPackages[i].bookingVenues.length > 0) {
                //                pkg.selectedDates = RegularBookingAdapter.timeslotsCountData(response.data.bookingPackages[i].bookingVenues);
                //                response.data.bookingPackages[i].bookingVenues.forEach((v, vi) => {
                //                    let bv = new BookingVenues();
                //                    bv.venue = new Venue();
                //                    bv.venue.id = v.venueDetails.id;
                //                    if (v.venueDetails.name)
                //                        bv.venue.name = v.venueDetails.name;
                //                    bv.bookingItem = [] as BookingItemVm[];

                //                    //bookingItem array
                //                    if (v.bookingItems) {
                //                        bk.totalSelectedDates = RegularBookingAdapter.GetTotalNumofDays(response.data.bookingPackages);

                //                        pkg.selectedLocationsCount = RegularBookingAdapter.GetSelectedLocations(response.data.bookingPackages[i]);
                //                        v.bookingItems.forEach((k, i) => {
                //                            let bi = new BookingItemVm();
                //                            let qif = new QuestionItemFilters();
                //                            bi.id = k.id;
                //                            bi.asset = k.asset;
                //                            bi.name = k.name;
                //                            if (k.item)
                //                                bi.item = k.item;
                //                            bi.endDate = k.endDate;
                //                            bi.startDate = k.startDate;
                //                            bi.attendees = k.attendees;
                //                            bi.priceType = k.priceType;
                //                            qif.id = k.item.id;
                //                            qif.name = k.name;
                //                            // bi.grossAmount = k.grossAmount;
                //                            //bi.id = k.id;
                //                            if (k.recurrenceFilter) {
                //                                bi.recurrenceFilter = new RecurrenceOption();
                //                                bi.recurrenceFilter.endTime = k.recurrenceFilter.endTime;
                //                                bi.recurrenceFilter.startTime = k.recurrenceFilter.startTime;
                //                                bi.recurrenceFilter.id = k.recurrenceFilter.id;
                //                                bi.recurrenceFilter.occurrences = k.recurrenceFilter.occurrences;
                //                                bi.recurrenceFilter.recurrenceEndAfter = k.recurrenceFilter.recurrenceEndAfter;
                //                                bi.recurrenceFilter.recurrenceOptionId = k.recurrenceFilter.recurrenceOptionID;
                //                                bi.recurrenceFilter.recurrenceStartDate = k.recurrenceFilter.recurrenceStartDate;
                //                                bi.recurrenceFilter.recurrenceEndDate = k.recurrenceFilter.recurrenceEndDate;
                //                                bi.recurrenceFilter.recurrenceWeekDays = k.recurrenceFilter.recurrenceWeekDays;
                //                                bi.recurrenceFilter.recurrenceOptionType = k.recurrenceFilter.recurrenceOptionType;
                //                                bi.recurrenceFilter.dateType = k.recurrenceFilter.dateType;
                //                                bi.itemRecurrenceOption = k.itemRecurrenceOption;
                //                                bi.recurrentBookingItem = [] as RecurrentBookingItem[];


                //                                if (k.recurrentBookingItem != undefined) {
                //                                    k.recurrentBookingItem.forEach((r, i) => {
                //                                        let rbi = new RecurrentBookingItem();
                //                                        rbi.endDate = r.endTime;
                //                                        rbi.id = r.id;
                //                                        rbi.startDate = r.startTime;
                //                                        rbi.unitPriceIncludingTax = r.unitPriceIncludingTax;
                //                                        rbi.unit = r.units;
                //                                        if (r.allocations != undefined) {
                //                                            rbi.attendees = r.allocations[0].attendees;
                //                                        } else {
                //                                            rbi.attendees = 0;
                //                                        }

                //                                        rbi.priceTypeId = r.priceTypeId;
                //                                        rbi.priceType = r.priceType;
                //                                        rbi.grossAmount = r.grossAmount;
                //                                       // rbi.isUnavailable = r.isUnavailable;
                //                                        if (k.unavailableDatesItem != undefined) {
                //                                            rbi.isUnavailable = RegularBookingAdapter.CheckUnavailableDays(k, r, k.unavailableDatesItem);
                //                                        }
                //                                        rbi.itemNumber = i + 1;
                //                                        rbi.dayOfWeek = moment(r.startTime).format('ddd');
                //                                        bi.recurrentBookingItem.push(rbi);
                //                                    });
                //                                }

                //                            }
                //                            if (k.unavailableDates != undefined) {
                //                                if (k.unavailableDates) {
                //                                    bi.unavailableDates = [] as TimeSlot[];
                //                                    k.unavailableDates.forEach((ud, i) => {
                //                                        let uDays = new TimeSlot();
                //                                        if (ud != undefined && ud != null) {
                //                                            uDays.endTime = ud.endTime;
                //                                            uDays.startTime = ud.startTime;
                //                                            uDays.id = ud.id;
                //                                            uDays.preliminary = ud.preliminary;
                //                                            uDays.type = ud.type;
                //                                            bi.unavailableDates.push(uDays);
                //                                        }
                //                                    });
                //                                }
                //                            }
                //                            if (k.unavailableDatesItem != undefined) {
                //                                bi.unavailableDatesItem = [] as Date[];
                //                                bi.unavailableDatesItem = k.unavailableDatesItem;
                //                            }
                //                            if (k.splitBookingItems != undefined) {
                //                                if (k.splitBookingItems) {
                //                                    bi.splitBookingItem = [] as SplitBookingItem[];
                //                                    k.splitBookingItems.forEach((r, i) => {
                //                                        let sbi = new SplitBookingItem();
                //                                        sbi.endDate = r.endTime;
                //                                        sbi.id = r.id;
                //                                        sbi.startDate = r.startTime;
                //                                        sbi.unitPriceIncludingTax = r.unitPriceIncludingTax;
                //                                        sbi.unit = r.units;


                //                                        if (r.allocations != undefined) {
                //                                            sbi.attendees = r.allocations[0].attendees;
                //                                        } else {
                //                                            sbi.attendees = 0;
                //                                        }

                //                                        sbi.priceTypeId = r.priceTypeId;
                //                                        sbi.priceType = r.priceType;
                //                                        bi.grossAmount = r.grossAmount;
                //                                       // sbi.isUnavailable = r.isUnavailable;

                //                                        if (k.unavailableDatesItem != undefined) {
                //                                            sbi.isUnavailable = RegularBookingAdapter.CheckUnavailableDays(k, r, k.unavailableDatesItem);
                //                                        }
                //                                        sbi.itemNumber = i + 1;
                //                                        sbi.dayOfWeek = moment(r.startTime).format('ddd');
                //                                        bi.splitBookingItem.push(sbi);
                //                                    });

                //                                }
                //                            }
                //                            if (k.splitBookingItems == undefined && k.recurrentBookingItem == undefined && k.adHocBookingItem != undefined) {

                //                                bi.adHocBookingItem = new AdHocBookingItem();


                //                                if (k.adHocBookingItem.allocations != undefined) {
                //                                    bi.adHocBookingItem.attendees = k.adHocBookingItem.allocations[0].attendees;
                //                                } else {
                //                                    bi.adHocBookingItem.attendees = 0;
                //                                }


                //                                bi.adHocBookingItem.endDate = k.adHocBookingItem.endTime;
                //                                bi.adHocBookingItem.id = k.adHocBookingItem.id;
                //                                bi.adHocBookingItem.startDate = k.adHocBookingItem.startTime;
                //                                bi.adHocBookingItem.priceType = k.adHocBookingItem.priceType;
                //                                bi.adHocBookingItem.priceTypeId = k.adHocBookingItem.priceTypeId;
                //                                bi.adHocBookingItem.unit = k.adHocBookingItem.units;
                //                                bi.adHocBookingItem.unitPriceIncludingTax = k.adHocBookingItem.unitPriceIncludingTax;
                //                                bi.grossAmount = k.adHocBookingItem.grossAmount;
                //                               // bi.adHocBookingItem.isUnavailable = k.adHocBookingItem.isUnavailable;
                //                                if (k.unavailableDatesItem != undefined) {
                //                                    bi.adHocBookingItem.isUnavailable = RegularBookingAdapter.CheckUnavailableDays(k, k.adHocBookingItem, k.unavailableDatesItem);
                //                                }

                //                                bi.adHocBookingItem.itemNumber = 1;
                //                                bi.adHocBookingItem.dayOfWeek = moment(k.adHocBookingItem.startTime).format('ddd');
                //                            }

                //                            bv.bookingItem.push(bi);
                //                            let index = qpf.questionItemFilters.findIndex(x => x.id == qif.id);
                //                            if (!(index >= 0)) {
                //                                qpf.questionItemFilters.push(qif);
                //                            }

                //                        })
                //                    }
                //                    pkg.bookingVenues.push(bv);
                //                })
                //            }















                //            response.data.bookingPackages[i].bookingItem.forEach((k, i) => {
                //                let bi = new BookingItemVm();

                //                bi.id = k.id;
                //                bi.asset = k.asset;
                //                bi.name = k.name;
                //                if (k.item)
                //                    bi.item = k.item;
                //                bi.endDate = k.endDate;
                //                bi.startDate = k.startDate;
                //                bi.attendees = k.attendees;
                //                // bi.grossAmount = k.grossAmount;
                //                //bi.id = k.id;
                //                if (k.recurrenceFilter) {
                //                    bi.recurrenceFilter = new RecurrenceOption();
                //                    bi.recurrenceFilter.endTime = k.recurrenceFilter.endTime;
                //                    bi.recurrenceFilter.startTime = k.recurrenceFilter.startTime;
                //                    bi.recurrenceFilter.id = k.recurrenceFilter.id;
                //                    bi.recurrenceFilter.occurrences = k.recurrenceFilter.occurrences;
                //                    bi.recurrenceFilter.recurrenceEndAfter = k.recurrenceFilter.recurrenceEndAfter;
                //                    bi.recurrenceFilter.recurrenceOptionId = k.recurrenceFilter.recurrenceOptionID;
                //                    bi.recurrenceFilter.recurrenceStartDate = k.recurrenceFilter.recurrenceStartDate;
                //                    bi.recurrenceFilter.recurrenceEndDate = k.recurrenceFilter.recurrenceEndDate;
                //                    bi.recurrenceFilter.recurrenceWeekDays = k.recurrenceFilter.recurrenceWeekDays;
                //                    bi.recurrentBookingItem = [] as RecurrentBookingItem[];


                //                    if (k.recurrentBookingItem != undefined) {
                //                        k.recurrentBookingItem.forEach((r, i) => {
                //                            let rbi = new RecurrentBookingItem();
                //                            rbi.endDate = r.endTime;
                //                            rbi.id = r.id;
                //                            rbi.startDate = r.startTime;
                //                            rbi.unitPriceIncludingTax = r.unitPriceIncludingTax;
                //                            rbi.unit = r.units;
                //                            if (r.attendees != undefined) {
                //                                rbi.attendees = r.attendees;
                //                            } else {
                //                                rbi.attendees = 0;
                //                            }

                //                            rbi.priceTypeId = r.priceTypeId;
                //                            rbi.priceType = r.priceType;
                //                            rbi.grossAmount = r.grossAmount;
                //                            bi.recurrentBookingItem.push(rbi);
                //                        });
                //                    }

                //                }
                //                if (k.unavailableDates != undefined) {
                //                    if (k.unavailableDates) {
                //                        bi.unavailableDates = [] as TimeSlot[];
                //                        k.unavailableDates.forEach((ud, i) => {
                //                            let uDays = new TimeSlot();
                //                            if (ud != undefined && ud != null) {
                //                                uDays.endTime = ud.endTime;
                //                                uDays.startTime = ud.startTime;
                //                                uDays.id = ud.id;
                //                                bi.unavailableDates.push(uDays);
                //                            }
                //                        });
                //                    }
                //                }
                //                if (k.splitBookingItems != undefined) {
                //                    if (k.splitBookingItems) {
                //                        bi.splitBookingItem = [] as SplitBookingItem[];
                //                        k.splitBookingItems.forEach((r, i) => {
                //                            let sbi = new SplitBookingItem();
                //                            sbi.endDate = r.endTime;
                //                            sbi.id = r.id;
                //                            sbi.startDate = r.startTime;
                //                            sbi.unitPriceIncludingTax = r.unitPriceIncludingTax;
                //                            sbi.unit = r.units;


                //                            if (r.attendees != undefined) {
                //                                sbi.attendees = r.attendees;
                //                            } else {
                //                                sbi.attendees = 0;
                //                            }

                //                            sbi.priceTypeId = r.priceTypeId;
                //                            sbi.priceType = r.priceType;
                //                            bi.grossAmount = r.grossAmount;
                //                            bi.splitBookingItem.push(sbi);
                //                        });

                //                    }
                //                }
                //                if (k.splitBookingItems == undefined && k.recurrentBookingItem == undefined && k.adHocBookingItem != undefined) {

                //                    bi.adHocBookingItem = new AdHocBookingItem();


                //                    if (k.adHocBookingItem.attendees != undefined) {
                //                        bi.adHocBookingItem.attendees = k.attendees;
                //                    } else {
                //                        bi.adHocBookingItem.attendees = 0;
                //                    }


                //                    bi.adHocBookingItem.endDate = k.adHocBookingItem.endTime;
                //                    bi.adHocBookingItem.id = k.adHocBookingItem.id;
                //                    bi.adHocBookingItem.startDate = k.adHocBookingItem.endTime;
                //                    bi.adHocBookingItem.priceType = k.adHocBookingItem.priceType;
                //                    bi.adHocBookingItem.priceTypeId = k.adHocBookingItem.priceTypeId;
                //                    bi.adHocBookingItem.unit = k.adHocBookingItem.units;
                //                    bi.adHocBookingItem.unitPriceIncludingTax = k.adHocBookingItem.unitPriceIncludingTax;
                //                    bi.grossAmount = k.adHocBookingItem.grossAmount;
                //                }

                //                pkg.bookingItem.push(bi)
                //            });
                //            bk.bookingPackages.push(pkg);
                //        });

                //    }
                //}
                //return bk;
                //return response;
            })
        );
    }
    //BookingSearchUisngContactRef(id: string, pagenumber: number, pagesize: number, sort: string, bookingRef: string, bookingDateFrom: string, bookingDateTo: string, eventDateFrom: string, eventDateTo: string, statusIds: string, isPendingPayments: boolean): Observable<Booking[]> {
    //BookingPackages(id: string): Observable<any> {
    //    return this._baseService.WebAPIGet("api/v4.1/bookings/" + id + "?include=bookingpackages,package").pipe(
    //        map((response) => {
    //            var json = new Deserializer().deserialize(response);
    //
    //            return json;
    //        })
    //    );
    //}

    postRecurrentOption(recurrentFilter): Observable<any> {

        var json = new Serializer().serialize(recurrentFilter);

        return this._baseService.Internal_WebAPI_POST("api/common/invoke?id=5", json).pipe(
            map((response: HttpResponse<any>) => {
                if (!response.body.isError) {

                    var _recurrentTimeSlots: RecurrentTimeSlots[] = [];
                    if (response.body != undefined) {
                        var json = response.body.recurringTimeSlot;
                        if (json != undefined && json.length > 0) {
                            json.forEach(t => {
                                let recurrentTimeSlots = new RecurrentTimeSlots();
                                recurrentTimeSlots.id = t.id;
                                recurrentTimeSlots.startTime = t.startTime;
                                recurrentTimeSlots.endTime = t.endTime;
                                recurrentTimeSlots.availability = t.availability;
                                recurrentTimeSlots.type = t.type;
                                _recurrentTimeSlots.push(recurrentTimeSlots);

                            })
                        }
                    }
                    return _recurrentTimeSlots;

                }
                else {

                    return response.body;
                }

            }
            )
        )
    }

    itemSearch(productItemFilters: ProductItemFilters): Observable<any> {
        //"&include=venue,Facility.Amenities,Facility.configurations"
        var UrlParam = "";
        UrlParam = "&page.size=1000&page.number=1&sort=1&include=ItemPriceGroups,ItemAssets";

        return this._baseService.Internal_WebAPI_POST("api/common/invoke?id=6&itemSearchFilter.ItemIds=" + productItemFilters.ItemIds + "&itemSearchFilter.PriceConcessionId=" + productItemFilters.PriceConcessionId + UrlParam, productItemFilters).pipe(
            map((response: any) => {
                var JsonData = JSON.parse(response.body);
                var json = new Deserializer().deserialize(JsonData);
                var lst: Item[] = [];

                json.forEach((c, i) => {
                    var item = (c as Item);

                    lst.push(item);
                });
                return lst;
            })
        )
    }


    SaveBooking(booking: Booking, rolloverBookingid = "0", isRolloverBookingDocument = false): Observable<any> {
        var json = new Serializer().serialize(booking);
        // when order is not in this oder some models are not there in the MVC layer booking object
        if (json.included && json.included.length > 0) {
            let changingOrder = [
                "globalBookingQuestionnaire",
                "packageBookingQuestionnaire",
                "singleChoiceQuestionAnswer",
                "multipleChoiceQuestionAnswer",
                "booleanQuestionAnswer",
                "textQuestionAnswer",
                "multipleChoiceQuestion",
                "singleChoiceQuestion",
                "textQuestion",
                "booleanQuestion",
                "bookingQuestionChoice",
                "bookingAnswerChoice",
                "answerChoice"
            ];
            changingOrder.reverse();
            json.included.sort((a, b) => changingOrder.indexOf(a.type) - changingOrder.indexOf(b.type));
            json.included.reverse();
            console.log(JSON.stringify(json));
        }
        return this._baseService.Internal_WebAPI_POST("api/regular-bookings/save-booking/" + booking.id + ((rolloverBookingid == "0") ? "" : "?rollverBookingId=" + rolloverBookingid) + ((isRolloverBookingDocument) ? "&isRolloverBookingDocument=" + isRolloverBookingDocument : ""), json).pipe(
            map((response: HttpResponse<any>) => {

                return response;
            })
        );
    }


    QuestionSavePatch(id: string, answers: BookingQuestion[]): Observable<any> {


        // var data =  JSON.stringify(postdata);
        var json = new Serializer().serialize(answers);

        console.log("list of answers before serializing ", answers)
        console.log("Serialized JSON", json)

        return this._baseService.Internal_WebAPI_PATCH("api/regular-bookings/" + id + "/answers", json).pipe(map((response : HttpResponse<any>) => {



            return response.body["data"];
        }));
    }

    DeleteBookingPackage(bookingId, bookingPackageId) {

        var json = '';
        return this._baseService.Internal_WebAPI_POST("api/common/invoke?id=7&bookingId=" + bookingId + "&packageId=" + bookingPackageId, json).pipe(
            map((response) => {
                return response;
            })
        )
    }

    DeleteBookingItem(bookingId, bookingItemId, bookingItemList = false) {

        var json = '';
        var urlParam = '';
        if (bookingItemList == true) {
            urlParam = `bookingId=${bookingId}&bookingItemIds=${bookingItemId}`;
        } else {
            urlParam = `bookingId=${bookingId}&bookingItem=${bookingItemId}`;
        }

        return this._baseService.Internal_WebAPI_POST("api/common/invoke?id=8&" + urlParam, json).pipe(
            map((response: any) => {
                //var result = new Deserializer().deserialize(response);

                return response;

            })
        )

    }


    GetDiaryNotes(fromDate, toDate) {

        var json = '';
        return this._baseService.Internal_WebAPI_POST("api/common/invoke?id=9&fromDate=" + fromDate + "&toDate=" + toDate, json).pipe(
            map((response: any) => {
                var JsonData = JSON.parse(response.body.data);
                var result = new Deserializer().deserialize(JsonData);
                return result;
            })
        )

    }

    GetTimeslots(fromDate, toDate, assetId) {

        var jsn = '';
        return this._baseService.Internal_WebAPI_POST("api/common/invoke?id=10&fromDate=" + fromDate + "&toDate=" + toDate + "&assetId=" + assetId, jsn).pipe(
            map((response: any) => {
                //var result = new Deserializer().deserialize(response.body.data);
                //return result;
                if (response.body && response.body.data == undefined) {
                    return response;
                }
                var JsonData = JSON.parse(response.body.data);
                var json = new Deserializer().deserialize(JsonData);
                // return result;
                var slots: Timeslots[] = [];
                if (json != undefined && json.length > 0) {
                    json.forEach(t => {
                        let timeslot = new Timeslots();
                        timeslot.preliminary = t.preliminary;
                        timeslot.multidayId = t.multidayId;
                        timeslot.capacity = t.capacity;
                        if (t.blockingCategory)
                            timeslot.blockedReason = t.blockingCategory;


                        timeslot.allocatedQuantity = t.allocatedQuantity;
                        timeslot.startTime = Utility.convertISOToDate(t.startTime);
                        timeslot.endTime = Utility.convertISOToDate(t.endTime);
                        timeslot.reserved = t.reserved;
                        timeslot.asset = t.asset as BaseResource;
                        timeslot.eventConfiguration = t.eventConfiguration as BaseResource;
                        timeslot.privateEventInstance = t.privateEventInstance as BaseResource;
                        timeslot.type = t.type;
                        if (t.owner != undefined && t.owner != null) {
                            let timeslotOwner = new Timeslots();
                            timeslotOwner.preliminary = t.owner.preliminary;
                            timeslotOwner.multidayId = t.owner.multidayId;
                            timeslotOwner.capacity = t.owner.capacity;
                            timeslotOwner.allocatedQuantity = t.owner.allocatedQuantity;
                            timeslotOwner.startTime = Utility.convertISOToDate(t.owner.startTime);
                            timeslotOwner.endTime = Utility.convertISOToDate(t.owner.endTime);
                            timeslotOwner.reserved = t.owner.reserved;
                            timeslotOwner.asset = t.owner.asset as BaseResource;
                            timeslotOwner.eventConfiguration = t.owner.eventConfiguration as BaseResource;
                            timeslotOwner.privateEventInstance = t.owner.privateEventInstance as BaseResource;
                            timeslotOwner.owner = t.owner.owner as Timeslots;
                            timeslotOwner.type = t.type;
                            timeslot.owner = timeslotOwner;
                        }

                        slots.push(timeslot);

                    });
                }

                return slots;

            })
        )

    }

    //Temporary
    getFacilityAndResource(parameters: VenueAssetsearchparams): Observable<VenueWiseAssets> {
        return this._baseService.InternalWebAPIGET("api/assets/location-resources?" + parameters.geturlforsearch()).pipe(
            //return this._baseService.InternalWebAPIGET("api/assets/location-resources").pipe(
            map((response) => {
                return response["data"];
                var json = new Deserializer().deserialize(response);
                var retObj = new VenueWiseAssets();

            })
        );

    };

    PostNotes(bookingId, jsonObj) {

        var json = new Serializer().serialize(jsonObj);


        return this._baseService.Internal_WebAPI_POST("api/common/invoke?id=3&bookingId=" + bookingId, json).pipe(
            map((response: any) => {
                var JsonData = JSON.parse(response.body.data);
                var result = new Deserializer().deserialize(JsonData);
                return result;
            })
        )

    }

    //Temporary
    getDocumentTemplateCategories(): Observable<ReferenceData[]> {
        return this._baseService.InternalWebAPIGET("api/system/document-categories").pipe(
            map((response: any) => {
                var lst: ReferenceData[] = [];
                response.result.forEach((c, i) => {
                    let refData = new ReferenceData();
                    refData.Id = c.id;
                    refData.Name = c.name;
                    lst.push(refData);
                });
                return lst
            })
        );

    };

    getBookingDocuments(bookingid): Observable<AdHocDocument[]> {
        var json = '';
        var urlParam = `bookingId=${bookingid}`;
        return this._baseService.Internal_WebAPI_POST("api/common/invoke?id=2&" + urlParam, json).pipe(
            map((response: any) => {
                var JsonData = JSON.parse(response.body.data);
                var json = new Deserializer().deserialize(JsonData);
                var lst: AdHocDocument[] = [];
                json.forEach((c, i) => {
                    let aDH = new AdHocDocument();
                    aDH.id = c.id;
                    aDH.name = c.name;
                    aDH.documentCategory = c.documentCategory;
                    aDH.documentCategoryId = c.documentCategoryId;
                    aDH.documentFile = c.documentContent;
                    aDH.documentFilePath = c.documentFile;
                    lst.push(aDH);
                });
                return lst
            })
        )
    }

    BookingDocumentsSave(id: string, postdata: AdHocDocument): Observable<AdHocDocument> {
        var json = new Serializer().serialize(JSON.parse(JSON.stringify(postdata, jsonIgnoreReplacer)));

        return this._baseService.Internal_WebAPI_POST("api/common/invoke?id=1&bookingId=" + id, json).pipe(
            map((response: any) => {
                var JsonData = JSON.parse(response.body.data);
                var json = new Deserializer().deserialize(JsonData);
                var aDH = new AdHocDocument();
                aDH.id = json.id;
                aDH.name = json.name;
                aDH.documentCategory = json.documentCategory;
                aDH.documentCategoryId = json.documentCategoryId;
                aDH.documentFile = json.documentContent;
                aDH.documentFilePath = json.documentFile;
                return aDH;
            })
        )
    }

    BookingDocumentsDelete(id: string, postdata: AdHocDocument): Observable<any> {
        var json = '';
        var urlParam = `bookingId=${id}&documentId=${postdata.id}`;
        return this._baseService.Internal_WebAPI_POST("api/common/invoke?id=11&" + urlParam, json).pipe(
            map((response: any) => {
                return response;
            })
        )
    }

    SendEmails(bookingId, email: string, bookingRef: string, venueId: string, draftBookingType, bookingType: string, name:string) {

        var json = "";


        return this._baseService.Internal_WebAPI_POST("api/common/invoke?id=12&email=" + email + "&bookingId=" + bookingId + "&bookingRef=" + bookingRef + "&venueId=" + venueId + "&draftBookingType=" + draftBookingType + "&bookingTypeId=" + bookingType + "&bookingTypeName=" + name, json).pipe(
            map((response: any) => {
                //var JsonData = JSON.parse(response.body.data);
                //var JsonData = JSON.parse(response.body);
                //var result = new Deserializer().deserialize(JsonData);
                return response;
            })
        )

    }

    BooingExport(bookingId) {

        //  var json = "";
        return this._baseService.InternalWebAPIGET("api/regular-bookings/booking-export/" + bookingId + "?isCSV=1").pipe(
            map((response) => {
                //var json = new Deserializer().deserialize(response);

                 return response["data"];
            })
        )

    }

    ChangeItem(bookingId, bookingPackageId, bookingItemId, assetId, itemId, configurationId) {

        var json = "";
        return this._baseService.Internal_WebAPI_POST("api/common/invoke?id=15&bookingId=" + bookingId + "&bookingPackageId=" +
            bookingPackageId + "&bookingItemIds=" + bookingItemId + "&assetId=" + assetId + "&itemId=" + itemId + "&configurationId=" + configurationId, json).pipe(
                map((response: any) => {
                    var result;
                    if (response && response.body && response.body.data) {
                        var JsonData = JSON.parse(response.body.data);
                         result = new Deserializer().deserialize(JsonData);
                    }

                    return result;
                })
            )

    }



    PostTask(jsonObj) {

        var json = new Serializer().serialize(jsonObj);


        return this._baseService.Internal_WebAPI_POST("api/common/invoke?id=13", json).pipe(
            map((response: any) => {
                var JsonData = JSON.parse(response.body.data);
                var result = new Deserializer().deserialize(JsonData);
                return result;
            })
        )

    }

    populateBooking(response) {

        let bk = new Booking();
        if (response.data) {

            bk.id = response.data.id;

            // var _updateDate = moment(response.data.updateDate).format('DD MMM dddd hh:mm A');
            var _updateDate = moment(response.data.updateDate).format('DD MMM YYYY');
            bk.updateDate = _updateDate;
            bk.bookingStatus = new BookingStatus();
            bk.bookingStatus.name = response.data.bookingStatus.name;
            bk.bookingStatus.id = response.data.bookingStatus.id;
            bk.contact = new BookingContact();
            bk.contact.id = response.data.contact.id;
            bk.totalAmount = response.data.totalAmount;
            bk.bookingReference = response.data.bookingReference;
            bk.eventName = response.data.eventName;
            bk.bookingDate = response.data.bookingDate;
            bk.eventStartDate = response.data.eventStartDate;
            bk.seasonId = response.data.seasonId;
            bk.bookingTypeId = response.data.bookingTypeId;
            if (response.data.bookingQuestionnaires) {
                //global
                if (response.data.bookingQuestionnaires.globalQuestionnaires && response.data.bookingQuestionnaires.globalQuestionnaires.length > 0) {
                    bk.globalbookingQuestionnaires = new GlobalBookingQuestionnaire();
                    bk.globalbookingQuestionnaires.questionsTobeDisplay = [];
                    response.data.bookingQuestionnaires.globalQuestionnaires.forEach(gq => {
                        if (gq.bookingQuestions && gq.bookingQuestions.length > 0) {
                            gq.bookingQuestions.forEach(q => {
                                bk.globalbookingQuestionnaires.questionsTobeDisplay.push(q)

                            });
                        }
                    });
                }
                //package
                if (response.data.bookingQuestionnaires.packageQuestionnaires) {
                    bk.packagebookingQuestionnaires = [];

                    response.data.bookingQuestionnaires.packageQuestionnaires.forEach(pq => {
                        let pkq = new PackageBookingQuestionnaire();
                        pkq.packageId = pq.packageId;
                        pkq.packageName = pq.packageName;
                        pkq.type = pq.type;
                        pkq.isHaveMandatoryQuestion = false;
                        pkq.questionsTobeDisplay = [];
                        bk.packagebookingQuestionnaires.push(pkq);
                        if (pq.bookingQuestions) {
                            pq.bookingQuestions.forEach(q => {
                                pkq.questionsTobeDisplay.push(q);
                                if (q.question && q.question.mandatory)
                                    pkq.isHaveMandatoryQuestion = true;

                            });
                        }
                    });
                }
                //Item
                if (response.data.bookingQuestionnaires.itemQuestionnaires) {
                    bk.itembookingQuestionnaires = [];

                    response.data.bookingQuestionnaires.itemQuestionnaires.forEach(pq => {
                        let pkq = new ItemBookingQuestionnaire();
                        pkq.itemName = pq.name;
                        pkq.itemId = pq.itemId;
                        pkq.isHaveMandatoryQuestion = pq.isHaveMandatoryQuestion;
                        pkq.answerWisedBookingQuestionnaireGroups = [];
                        pq.questionMappingGroupViewModels.forEach(qmg => {
                            let awbqg = new AnswerWisedBookingQuestionnaire();
                            awbqg.dates = [];
                            awbqg.questionsTobeDisplay = [qmg.questionsTobeDisplay];
                            qmg.dates.forEach(q => {
                                awbqg.dates.push(q);
                            });
                            pkq.answerWisedBookingQuestionnaireGroups.push(awbqg);
                        });
                        bk.itembookingQuestionnaires.push(pkq);


                    });
                }

            }


            if (response.data.bookingPackages && response.data.bookingPackages.length > 0) {
                bk.bookingPackages = [] as BookingPackage[];
                bk.questionPackageFilters = [] as QuestionPackageFilters[];
                bk.unavailableDates = [];
                bk.unavailableDates = RegularBookingAdapter.unavailableDatesBooking(response.data.bookingPackages);
                response.data.bookingPackages.forEach((q, i) => {
                    let pkg = new BookingPackage();

                    pkg.attendees = q.attendees;
                    pkg.id = q.id;
                    pkg.name = q.name;
                    pkg.packageEnd = q.packageEnd;
                    pkg.packageStart = q.packageStart;
                    pkg.unavailableDates = q.unavailableDates;
                    pkg.totalAmount = q.totalAmount;
                    //pkg.bookingItem = [] as BookingItemVm[];
                    pkg.bookingVenues = [] as BookingVenues[];
                    pkg.package = new Package();
                    pkg.package.id = q.packageId;
                    if (q.salesCategories)
                        pkg.salesCategories = q.salesCategories;
                    if (q.packageRecurrenceOption != undefined) {
                        pkg.packageRecurrenceOption = q.packageRecurrenceOption;
                    }


                    if (q.unavailableDatesPackage) {
                        pkg.unavailableDatesPackage = [] as Date[];
                        pkg.unavailableDatesPackage = q.unavailableDatesPackage;
                        //bk.unavailableDates = RegularBookingAdapter.unavailableDatesBooking(q.unavailableDatesPackage);
                    }

                    if (q.venueDetails != undefined) {
                        pkg.venue = new Venue();
                        pkg.venue.id = q.venueDetails.id;
                        pkg.venue.name = q.venueDetails.name;
                    }

                    let qpf = bk.questionPackageFilters.find(x => x.id == q.packageId);
                    if (!qpf) {
                        qpf = new QuestionPackageFilters();
                        qpf.questionItemFilters = [] as QuestionItemFilters[];
                        qpf.name = q.name;
                        qpf.id = q.packageId;
                    }
                    //Venue level
                    if (response.data.bookingPackages[i].bookingVenues != undefined && response.data.bookingPackages[i].bookingVenues.length > 0) {
                        pkg.selectedDates = RegularBookingAdapter.timeslotsCountData(response.data.bookingPackages[i].bookingVenues);
                        response.data.bookingPackages[i].bookingVenues.forEach((v, vi) => {
                            let bv = new BookingVenues();
                            bv.venue = new Venue();
                            bv.venue.id = v.venueDetails.id;
                            if (v.venueDetails.name)
                                bv.venue.name = v.venueDetails.name;
                            bv.bookingItem = [] as BookingItemVm[];

                            //bookingItem array
                            if (v.bookingItems) {
                                bk.totalSelectedDates = RegularBookingAdapter.GetTotalNumofDays(response.data.bookingPackages);

                                pkg.selectedLocationsCount = RegularBookingAdapter.GetSelectedLocations(response.data.bookingPackages[i]);
                                v.bookingItems.forEach((k, i) => {
                                    let bi = new BookingItemVm();
                                    let qif = new QuestionItemFilters();
                                    bi.id = k.id;
                                    bi.asset = k.asset;
                                    bi.name = k.name;
                                    if (k.item)
                                        bi.item = k.item;
                                    bi.endDate = k.endDate;
                                    bi.startDate = k.startDate;
                                    bi.attendees = k.attendees;
                                    bi.priceType = k.priceType;

                                    bi.type = k.type;
                                    qif.id = k.item.id;
                                    qif.name = k.name;
                                    // bi.grossAmount = k.grossAmount;
                                    //bi.id = k.id;
                                    if (k.recurrenceFilter) {
                                        bi.recurrenceFilter = new RecurrenceOption();
                                        bi.recurrenceFilter.endTime = k.recurrenceFilter.endTime;
                                        bi.recurrenceFilter.startTime = k.recurrenceFilter.startTime;
                                        bi.recurrenceFilter.id = k.recurrenceFilter.id;
                                        bi.recurrenceFilter.occurrences = k.recurrenceFilter.occurrences;
                                        bi.recurrenceFilter.recurrenceEndAfter = k.recurrenceFilter.recurrenceEndAfter;
                                        bi.recurrenceFilter.recurrenceOptionId = k.recurrenceFilter.recurrenceOptionID;
                                        bi.recurrenceFilter.recurrenceStartDate = k.recurrenceFilter.recurrenceStartDate;
                                        bi.recurrenceFilter.recurrenceEndDate = k.recurrenceFilter.recurrenceEndDate;
                                        bi.recurrenceFilter.recurrenceWeekDays = k.recurrenceFilter.recurrenceWeekDays;
                                        bi.recurrenceFilter.recurrenceOptionType = k.recurrenceFilter.recurrenceOptionType;
                                        bi.recurrenceFilter.recurrenceDate = k.recurrenceFilter.recurrenceDate;
                                        bi.recurrenceFilter.dateType = k.recurrenceFilter.dateType;
                                        bi.itemRecurrenceOption = k.itemRecurrenceOption;
                                        bi.recurrentBookingItem = [] as RecurrentBookingItem[];


                                        if (k.recurrentBookingItem != undefined) {
                                            k.recurrentBookingItem.forEach((r, i) => {
                                                let rbi = new RecurrentBookingItem();
                                                rbi.endDate = r.endTime;
                                                rbi.id = r.id;
                                                rbi.startDate = r.startTime;
                                                rbi.unitPriceIncludingTax = r.unitPriceIncludingTax;
                                                rbi.unit = r.units;
                                                if (r.allocations != undefined) {
                                                    rbi.attendees = r.allocations[0].attendees;
                                                } else {
                                                    rbi.attendees = 0;
                                                }

                                                rbi.priceTypeId = r.priceTypeId;
                                                rbi.priceType = r.priceType;
                                                rbi.grossAmount = r.grossAmount;
                                                // rbi.isUnavailable = r.isUnavailable;
                                                if (k.unavailableDatesItem != undefined) {
                                                    rbi.isUnavailable = RegularBookingAdapter.CheckUnavailableDays(k, r, k.unavailableDatesItem);
                                                }
                                                rbi.itemNumber = i + 1;
                                                rbi.dayOfWeek = moment(r.startTime).format('ddd');
                                                bi.recurrentBookingItem.push(rbi);
                                            });
                                            bi.itemCount = k.recurrentBookingItem.length;
                                        }

                                    }
                                    if (k.unavailableDates != undefined) {
                                        if (k.unavailableDates) {
                                            bi.unavailableDates = [] as TimeSlot[];
                                            k.unavailableDates.forEach((ud, i) => {
                                                let uDays = new TimeSlot();
                                                if (ud != undefined && ud != null) {
                                                    uDays.endTime = ud.endTime;
                                                    uDays.startTime = ud.startTime;
                                                    uDays.id = ud.id;
                                                    uDays.preliminary = ud.preliminary;
                                                    uDays.type = ud.type;
                                                    bi.unavailableDates.push(uDays);
                                                }
                                            });
                                        }
                                    }
                                    if (k.unavailableDatesItem != undefined) {
                                        bi.unavailableDatesItem = [] as Date[];
                                        bi.unavailableDatesItem = k.unavailableDatesItem;
                                    }
                                    if (k.splitBookingItems != undefined) {
                                        if (k.splitBookingItems) {
                                            bi.splitBookingItem = [] as SplitBookingItem[];
                                            k.splitBookingItems.forEach((r, i) => {
                                                let sbi = new SplitBookingItem();
                                                sbi.endDate = r.endTime;
                                                sbi.id = r.id;
                                                sbi.startDate = r.startTime;
                                                sbi.unitPriceIncludingTax = r.unitPriceIncludingTax;
                                                sbi.unit = r.units;


                                                if (r.allocations != undefined) {
                                                    sbi.attendees = r.allocations[0].attendees;
                                                } else {
                                                    sbi.attendees = 0;
                                                }

                                                sbi.priceTypeId = r.priceTypeId;
                                                sbi.priceType = r.priceType;
                                                bi.grossAmount = r.grossAmount;
                                                // sbi.isUnavailable = r.isUnavailable;

                                                if (k.unavailableDatesItem != undefined) {
                                                    sbi.isUnavailable = RegularBookingAdapter.CheckUnavailableDays(k, r, k.unavailableDatesItem);
                                                }
                                                sbi.itemNumber = i + 1;
                                                sbi.dayOfWeek = moment(r.startTime).format('ddd');
                                                bi.splitBookingItem.push(sbi);
                                            });
                                            bi.itemCount = k.splitBookingItems.length;
                                        }
                                    }
                                    if (k.splitBookingItems == undefined && k.recurrentBookingItem == undefined && k.adHocBookingItem != undefined) {

                                        bi.adHocBookingItem = new AdHocBookingItem();


                                        if (k.adHocBookingItem.allocations != undefined) {
                                            bi.adHocBookingItem.attendees = k.adHocBookingItem.allocations[0].attendees;
                                        } else {
                                            bi.adHocBookingItem.attendees = 0;
                                        }

                                        bi.itemCount = 1;
                                        bi.adHocBookingItem.endDate = k.adHocBookingItem.endTime;
                                        bi.adHocBookingItem.id = k.adHocBookingItem.id;
                                        bi.adHocBookingItem.startDate = k.adHocBookingItem.startTime;
                                        bi.adHocBookingItem.priceType = k.adHocBookingItem.priceType;
                                        bi.adHocBookingItem.priceTypeId = k.adHocBookingItem.priceTypeId;
                                        bi.adHocBookingItem.unit = k.adHocBookingItem.units;
                                        bi.adHocBookingItem.unitPriceIncludingTax = k.adHocBookingItem.unitPriceIncludingTax;
                                        bi.grossAmount = k.adHocBookingItem.grossAmount;
                                        // bi.adHocBookingItem.isUnavailable = k.adHocBookingItem.isUnavailable;
                                        if (k.unavailableDatesItem != undefined) {
                                            bi.adHocBookingItem.isUnavailable = RegularBookingAdapter.CheckUnavailableDays(k, k.adHocBookingItem, k.unavailableDatesItem);
                                        }

                                        bi.adHocBookingItem.itemNumber = 1;
                                        bi.adHocBookingItem.dayOfWeek = moment(k.adHocBookingItem.startTime).format('ddd');
                                    }

                                    bv.bookingItem.push(bi);
                                    let index = qpf.questionItemFilters.findIndex(x => x.id == qif.id);
                                    if (!(index >= 0)) {
                                        qpf.questionItemFilters.push(qif);
                                    }

                                })
                            }
                            pkg.bookingVenues.push(bv);
                        })
                    }
                    
                    bk.bookingPackages.push(pkg);
                });

            }
        }
        return bk;
    }

   
    getInternalUserData() {
        return this._baseService.Internal_WebAPI_GET('api/regular-bookings/get-user-data').pipe(map((res: any) => {
            var internalUser = new UserData();
            if (res.body && res.body.userRightList) {
                //  this.wmAccessRightService.allAccessRights.next(res.body.userRightList);
                
                internalUser.userId = res.body.userID;
                internalUser.firstName = res.body.firstName;
                internalUser.lastName = res.body.lastName;
                internalUser.contactEmail = res.body.contactEmail;
                internalUser.contactId = res.body.contactID;
                internalUser.userImageUrl = res.body.userImageUrl;
            }
            return internalUser;
        })
        );
    }

}
