import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BaseService {

    constructor(private _http: HttpClient) {

    }
    //Web API

    //WebAPIGet(url: string): any {
    //    return this._http.get(environment.WebApiUrl+url);
    //}
    //WebAPIGetResponse(url: string, isobserve: boolean): any {
    //    return this._http.get(environment.WebApiUrl + url, { observe: 'response' });
    //}
    //add additional post parameters as optional
    //WebAPIPOST(url: string, body: string): any {
    //    return this._http.post((environment.WebApiUrl + url), body);
    //}
    //add additional post parameters as optional
    //WebAPIPOSTResponse(url: string, body: string, isobserve: boolean): any {
    //    return this._http.post((environment.WebApiUrl + url), body, { observe: 'response' });
    //}
/* No direct API calls
    WebAPIPATCH(url: string, body: string): any {
        return this._http.patch((environment.WebApiUrl + url), body);
    }
    WebAPIPATCHResponse(url: string, body: string): any {
        return this._http.patch((environment.WebApiUrl + url), body,{ observe: 'response' });
    }
    WebAPIDELETEResponse(url: string): any {
        return this._http.delete((environment.WebApiUrl + url), { observe: 'response' });
    }*/
    // Intermediate MVC Web API Calls

    InternalWebAPIPOST(url: string, body: any): any {
        return this._http.post((environment.ApiUrl + url), body);
    }
    Internal_WebAPI_POST(url: string, body: any): any {
        return this._http.post((environment.ApiUrl + url), body, { observe: 'response' });
    }
    Internal_WebAPI_POST_FullUrl(url: string, body: any): any {
        return this._http.post(url, body, { observe: 'response' });
    }
    Internal_WebAPI_PATCH(url: string, body: any): any {
        return this._http.patch((environment.ApiUrl + url), body, { observe: 'response' });
    }
    Internal_WebAPI_GET(url: string): any {
        return this._http.get((environment.ApiUrl + url), { observe: 'response' });
    }
    InternalWebAPIGET(url: string): any {
        return this._http.get((environment.ApiUrl + url));
    }
    InternalWebAPIGET_FullUrl(url: string): any {
      return this._http.get(url);
    }
    Internal_WebAPI_DELETE(url: string): Observable<any> {
        return this._http.delete((environment.ApiUrl + url), { observe: 'response' });
    }

}
