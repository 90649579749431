import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CurrencyFormat } from 'src/modules/currency-format';
import { FacilitySummary } from 'src/modules/models/asset/facility-summary';
import { ItemPriceOption } from 'src/modules/models/calendar-tab-view/item-price-options';
import { Item } from 'src/modules/models/item/item';

@Component({
  selector: 'price-range',
  templateUrl: './price-range.component.html',
  styleUrls: ['./price-range.component.scss']
})
export class PriceRangeComponent implements OnInit, OnDestroy {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private currencyFormat: CurrencyFormat,
  public dialogRef: MatDialogRef<PriceRangeComponent>,) {
    this.items = [];
    if (data && data.facilities && data.facilities.length && data.facilityId) {
      this.selectedFacilityId = data.facilityId;
      this.facilities = data.facilities;
      this.selectedFacility = (data.facilities as FacilitySummary[]).find(facility => facility.id === data.facilityId);
      this.items = this.selectedFacility && this.selectedFacility.items ? this.selectedFacility.items: [];
    };
  }

  items: Item[] = [];
  priceConfigurationOptions: ItemPriceOption[] = [];
  selectedFacilityId: string = '';
  selectedFacility: FacilitySummary;
  facilities: FacilitySummary[];
  selectedPriceConfigurationOption: ItemPriceOption;


  ngOnInit(): void {
    this.setPriceConfigOptions();
  }

  setPriceConfigOptions(){
    if (this.items.length) {
      this.items.forEach((item) => {
        let priceLabel;
        let priceTypeLabel;
        if (item && item.itemMinPrice) {
          priceLabel = `${this.currencyFormat.transform(item.itemMinPrice.priceIncludingTax)}`;
          priceTypeLabel = `${item.itemMinPrice.priceType}`;
        } else if(item && item.itemMaxPrice) {
          priceLabel = `${this.currencyFormat.transform(item.itemMaxPrice.priceIncludingTax)}`;
          priceTypeLabel = `${item.itemMaxPrice.priceType}`;
        } else if(item && item.itemMinPrice && item.itemMaxPrice){
          priceLabel = `${this.currencyFormat.transform(item.itemMinPrice.priceIncludingTax)} - ${this.currencyFormat.transform(item.itemMaxPrice.priceIncludingTax)}`;
          priceTypeLabel = `${item.itemMinPrice.priceType} - ${item.itemMaxPrice.priceType}`;
        }
        else {
        }
        if (item.id === this.selectedFacility.item.id) {
          this.selectedPriceConfigurationOption = {
            item,
            priceLabel,
            priceTypeLabel
          }
        }

        const tempPriceConfigurationOptions: ItemPriceOption = {
          item,
          priceLabel,
          priceTypeLabel
        };
        this.priceConfigurationOptions.push(tempPriceConfigurationOptions);

      });
    }
  }

  changePriceConfigurationOption(priceConfigurationOption: ItemPriceOption){
    this.selectedFacility.item = priceConfigurationOption.item;
    // this.facilities.find(facility => facility.id === this.selectedFacilityId).selectedItem = priceConfigurationOption.item;
    this.facilities.find(facility => facility.id === this.selectedFacilityId).item = priceConfigurationOption.item;
    this.selectedPriceConfigurationOption = this.priceConfigurationOptions.find(p => p.item.id === priceConfigurationOption.item.id)
    this.dialogRef.close({facilities: this.facilities, selectedFacility: this.selectedFacility, selectedItem:priceConfigurationOption.item});
  }

  ngOnDestroy(): void {
  }
}
