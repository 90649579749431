<div style="padding:30px;">
    <button mat-button class="mmc-btn-primary" tabindex="0" (click)="openAddExtras()" (keydown.enter)="openAddExtras()">Add Extras</button>
    <button mat-button class="mmc-btn-primary" tabindex="0" (click)="openQuestionnaire()" (keydown.enter)="openQuestionnaire()">Questionnaire</button>
    <button mat-button class="mmc-btn-primary" tabindex="0" (click)="openCancelBooking()" (keydown.enter)="openCancelBooking()">Cancel Booking</button>
    <button mat-button class="mmc-btn-primary" tabindex="0" (click)="openAlternativeOptions()" (keydown.enter)="openAlternativeOptions()">Alternative Options</button>
    <button mat-button class="mmc-btn-primary" tabindex="0" (click)="openWpMessage()" (keydown.enter)="openWpMessage()">Message</button>
    <button mat-button class="mmc-btn-primary" tabindex="0" (click)="openWpChangeRequest()" (keydown.enter)="openWpChangeRequest()">Change Request</button>
    <button mat-button class="mmc-btn-primary" tabindex="0" (click)="openWpRequestAccess()" (keydown.enter)="openWpRequestAccess()">Request access</button>
    <button mat-button class="mmc-btn-primary" tabindex="0" (click)="openWpAlreadyAccess()" (keydown.enter)="openWpAlreadyAccess()">Already access</button>
    <button mat-button class="mmc-btn-primary" tabindex="0" (click)="openManageContacts()" (keydown.enter)="openManageContacts()">Manage Contacts</button>
    <button mat-button class="mmc-btn-primary" tabindex="0" (click)="openExtraAllocation()" (keydown.enter)="openExtraAllocation()">Extra Allocation</button>
</div>
