import { Booking, DeliveryMethod } from "../../models/booking/booking";
import { BOOKING_CART, PAGE_TYPE, BOOKING_FROM } from "../../models/public-web/enum";
import { Package, PackageDurationRange } from "../../models/public-web/Package";
import { PBActionType, PublicWebActions } from "./public-web-actions";
import { Contact } from "../../models/client/contact";
import { SystemOption } from "src/modules/models/public-web/SystemOption";
import * as _ from 'lodash'
import { Utility } from "src/modules/utility";
import { EventDate, PackageSession } from "src/modules/models/public-web/PackageSession";
import { PatchItems } from "src/modules/public-web/components/pages/public-booking-cart/public-booking-cart.component";
import { PublicEvent } from "src/modules/models/public-web/PublicEvent";
import { environment } from "src/environments/environment";
import { BOOKING_FLOW_OPTION } from "src/modules/public-web/components/layout/models/enums";

export interface IPublicWebSession {
  bookingSession: SystemOption;
  paymentSession: SystemOption;
  globalSession: SystemOption;
}

export interface IPublicPackage {
  availability: number;
  id: string;
  bookingPackageId: string;
  isBooked: boolean;
  quantity: number;
  maximumAttendees: number;
  isHaveLinkPackage: boolean;
  singleDayPackage: boolean;
  hasTicketing: boolean;
  isLinkPackageExpand: boolean;
  linkPackages: Package[];
  itemQuantity: number;
  isNotMemberPackage: boolean;
  clientCategories: any[];
  addOns?: any[];
  durationRange?: PackageDurationRange[];
  packageSessions:PackageSession[];
  attendeesPerPackage : number;
}

export interface IPublicEvent {
  id: string;
  bookingFlowOptionId: BOOKING_FLOW_OPTION;
  hasTicketing: boolean;
  packageSessions:PackageSession[];
  maximumCountOfPackages: number;
}

export interface IPBReducer {
  sessionTimers: IPublicWebSession;
  selectedEventCategoryId: string;  // Used to navigate to event-list page when click on the continue shopping.
  eventId: string;
  patchPreviewId: string;
  packageIds: Array<string>;
  selectedSessionTime: IDateAndSession;
  selectedSessions: IDateAndSession[]; // * name changed from "selectedSessionList"
  booking: Booking;
  pageType: PAGE_TYPE;
  pageConfiguration: string[];
  paymentUrl:PaymentInfo;
  redirectionPoint: string;
  bookedPackages: Package[];
  publicPackages: IPublicPackage[];  // To hold the public packages for selected event.
  publicEvent: IPublicEvent[];
  selectedDeliveryMethod: string;
  packageAvailability: number;
  selectedClient: any;
  guestContact: Contact;
  patchPreviewFailed: boolean;
  bookingMode: BOOKING_CART;
  freezedBooking: Booking;
  selectedBooking: ISelectBooking;
  eligibleDeliveryMethod:DeliveryMethod[];
  selectedBookingPackage: IBooingPackageBookedSeat[];
  bookingFrom:BOOKING_FROM;
  bookingFlowOption : BOOKING_FLOW_OPTION;
  contactID: string; // GUID of the user generated by signalR Hub or logged in contactId.
  seat: string;
  deSelectSeat: string;
  allSeats: string[];
  allDeSelectSeats: string[];
  checkSeat: string;
  PatchItems: PatchItems[];
  updatedBookingPackages: any[];
}
export interface IBooingPackage {
  id: string;
  packageId: string;
  quantity: number;
}
export interface ISelectBooking {
  id: string;
  bookingpackageCount: number;
}
export interface IBooingPackageBookedSeat {
  id: string;
  packageId: string;
  seat: any;
  quantity : number;
}
export interface IDateAndSession {
  id: string;
  startTime: string;
  endTime: string;
  availability: number;
  capacity?: number;
  eventDate?: EventDate;
  bookingPackages?: IBooingPackage[];
  durationRange?: PackageDurationRange;
  packages?: IPublicPackage[];
  sessionCode?: string;
  eventTimeSlotId?: string;
}

export interface PaymentInfo {
  amount: number;
  billerCode: string;
  paymentUrl: string;
}

export interface IPackageDeliveryMethod {
  id: string;
  name: string;
  deliveryMethodId: string;
}
//set default values
let defaultContact = new Contact();
defaultContact.firstName = "";
defaultContact.profilePicture = "";

export let initialState: IPBReducer = {
  eventId: undefined,
  sessionTimers: {
    bookingSession: undefined,
    paymentSession: undefined,
    globalSession: undefined
  },
  selectedEventCategoryId: undefined,
  packageIds: [],
  publicPackages: [],
  publicEvent: [],
  selectedSessionTime: undefined,
  selectedSessions: [],
  booking: undefined,
  packageAvailability: undefined,
  pageType: undefined,
  pageConfiguration: [],
  paymentUrl: undefined,
  redirectionPoint: undefined,
  selectedDeliveryMethod: undefined,
  selectedClient: undefined,
  patchPreviewFailed: false,
  guestContact: defaultContact,
  bookedPackages: [],
  bookingMode: null,
  freezedBooking: new Booking(),
  patchPreviewId: undefined,
  selectedBooking: undefined,
  eligibleDeliveryMethod:null,
  selectedBookingPackage: undefined,
  bookingFrom: BOOKING_FROM.PUBLIC_WEB,
  bookingFlowOption: null,
  contactID: null,
  seat: null,
  deSelectSeat: null,
  allSeats: null,
  allDeSelectSeats: null,
  checkSeat: null,
  PatchItems: [],
  updatedBookingPackages: [],
};

export function PBReducer(state = initialState, action: PublicWebActions): IPBReducer {
  switch (action.type) {
    case PBActionType.SET_PATCH_PREVIEW_ID:
      return { ...state, patchPreviewId: action.payload }
    case PBActionType.SET_FREEZED_BOOKING:
      return { ...state, freezedBooking: action.payload }
    case PBActionType.SET_BOOKED_PACKAGES:
      return { ...state, bookedPackages: action.payload }
    case PBActionType.SET_UPSELLS_FOR_BOOKED_PACKAGES:
      var bookedPacks = _.cloneDeep(state.bookedPackages);
      if (bookedPacks && action.payload.packageID && action.payload.upsells) {
        let packIndex = bookedPacks.findIndex(ep => ep.id == action.payload.packageID)
        if (packIndex != -1) {
          bookedPacks[packIndex].upsellItem = action.payload.upsells
        }
      }
      return { ...state, bookedPackages: bookedPacks }
    case PBActionType.SET_SECONDARY_CLIENT:
      return { ...state, selectedClient: action.payload };
    case PBActionType.SET_BOOKING_PATCH_PREVIEW_STATUS:
      return { ...state, patchPreviewFailed: action.payload };
    case PBActionType.SET_PACKAGE_AVAILABILITY:
      return { ...state, packageAvailability: action.payload };
    case PBActionType.REMOVE_GUEST_CONTACT:
      return { ...state, guestContact: defaultContact };
    case PBActionType.SET_GUEST_CONTACT:
      return { ...state, guestContact: action.payload };
    case PBActionType.ADD_NEW_SESSIONS:
      return { ...state, selectedSessions: action.payload };
    case PBActionType.RESET_SELECT_PACKAGE_STATUS:
      const sessionData = state.selectedSessions;
      sessionData.forEach((ses) =>
        ses.packages.forEach((pk) => {
          pk.isBooked = true;
          if (!!pk.addOns && pk.addOns.length > 0) {
            pk.addOns.forEach((a) => (a.isNeedToBeUpdate = false));
          }
          if (pk.isHaveLinkPackage && pk.linkPackages.length > 0) {
            pk.linkPackages.forEach((lk) => (lk.isBooked = true));
          }
        })
      );
      return { ...state, selectedSessions: sessionData };
    case PBActionType.REMOVE_PACKAGE_SESSION:
      return {
        ...state,
        selectedSessions: state.selectedSessions.filter(
          (x) => x.id != action.payload.id
        ),
      };
    case PBActionType.SELECT_PACKAGE_SESSIONS:
      let sessions = state.selectedSessions;
      action.payload.map((y) => {
        var isExist = sessions.findIndex((x) => x?.id === y?.id);
        if (isExist === -1) sessions.push(y);
        else {
          if (!!y?.packages && y?.packages.length !== 0) {
            sessions[isExist] = y;
          }
        }
      });
      return { ...state, selectedSessions: sessions };
    case PBActionType.SET_SELECTED_SESSION:
      return { ...state, selectedSessionTime: action.payload };
    case PBActionType.BOOKING_PATCH_PREVIEW:
      return state;
    case PBActionType.SET_SELECTED_EVENT_CATEGORY_ID:
      return { ...state, selectedEventCategoryId: action.payload };
    case PBActionType.RESET_SELECTED_EVENT_CATEGORY_ID:
      return { ...state, selectedEventCategoryId: null };
    case PBActionType.SET_PAGE_CONFIGURATIONS:
      return { ...state, pageConfiguration: action.payload };
    case PBActionType.POST_PUBLIC_BOOKING:
      return state;
    case PBActionType.UPDATE_SELECTED_BOOKING:
      return state;
    case PBActionType.UPDATE_TRANSLATE_SELECTED_BOOKING:
      return state;
    case PBActionType.SET_PAYMENT_URL:
      return{ ...state, paymentUrl: action.payload };
    case PBActionType.GET_PAYMENT_URL:
      return state;
    // case PBActionType.CHECK_BOOKING_HAS_CLIENT:
    //   return state;
    case PBActionType.PATCH_BOOKING:
      return state;
    case PBActionType.SET_SELECTED_EVENT_ID:
      return { ...state, eventId: action.payload };
    case PBActionType.ADD_PACKAGE:
      {
        var added = state.packageIds == undefined ? [] : state.packageIds;
        var isExist = added.find((x) => x === action.payload);
        if (isExist === undefined) added.push(action.payload);
        return { ...state, packageIds: added };
      }

    case PBActionType.SET_CURRENT_PAGE_TYPE:
      return { ...state, pageType: action.payload };
    case PBActionType.SET_BOOKING:
      return { ...state, booking: action.payload };
    case PBActionType.ADD_PUBLIC_INTERNAL_BOOKING:
      return { ...state, booking: action.payload };
    case PBActionType.PATCH_UPSELL_QUANTITY:
      return { ...state, PatchItems: action.payload };
    case PBActionType.SET_REDIRECTION_POINT:
      return { ...state, redirectionPoint: action.payload };
    // case PBActionType.SET_DELIVERY_METHOD:
    //   return { ...state, selectedDeliveryMethod: action.payload };
    case PBActionType.RESET_PUBLIC_WEB_STATE:
      let newState: IPBReducer = {
        eventId: undefined,
        sessionTimers: {
          bookingSession: new SystemOption().initialize(environment.SystemOption.SessionTimers.bookingSession),
          paymentSession: new SystemOption().initialize(environment.SystemOption.SessionTimers.paymentSession),
          globalSession: new SystemOption().initialize(environment.SystemOption.SessionTimers.globalSession)
        },
        selectedEventCategoryId: undefined,
        packageIds: [],
        selectedSessionTime: undefined,
        selectedSessions: [],
        booking: undefined,
        pageType: undefined,
        redirectionPoint: undefined,
        selectedDeliveryMethod: undefined,
        pageConfiguration: [],
        packageAvailability: undefined,
        selectedClient: undefined,
        patchPreviewFailed: false,
        paymentUrl:undefined,
        bookedPackages: [],
        guestContact: defaultContact,
        bookingMode: null,
        freezedBooking: new Booking(),
        publicPackages: [],
        publicEvent: [],
        patchPreviewId: undefined,
        selectedBooking: undefined,
        eligibleDeliveryMethod:null,
        selectedBookingPackage: undefined,
        bookingFrom:BOOKING_FROM.PUBLIC_WEB,
        bookingFlowOption: null,
        contactID: null,
        seat: null,
        deSelectSeat: null,
        allSeats: null,
        allDeSelectSeats: null,
        checkSeat: null,
        PatchItems: [],
        updatedBookingPackages: [],
      };
      return { ...newState };
    case PBActionType.RESET_PACKAGES:
      return { ...state, publicPackages: [] };
    case PBActionType.SET_PACKAGE_LIST:
      {
        let added = state.publicPackages === undefined ? [] : state.publicPackages;

        action.payload.map((x) => {
          let isExist = added.findIndex((y) => y.id === x.id);
          if (isExist === -1) {
            if (x.isHaveLinkPackage && x.linkPackages.length > 0) {
              x.linkPackages = x.linkPackages
                .sort((x, y) => x.linkedPackageType.id > y.linkedPackageType.id ? -1 : 1)
                .sort((x, y) => x.linkedPackageType.adult ? -1 : 1)
            }
            added.push(x)
          }
          else added[isExist] = x;
        });

        return { ...state, publicPackages: added };
      }
    case PBActionType.UPDATE_PACKAGE:
      {
        let p = state.publicPackages;
        if (action.payload) {
          let index = p.findIndex((x) => x.id === action.payload.id);
          if (index != -1) p[index] = action.payload;
        }
        return { ...state, publicPackages: p };
      }
    case PBActionType.RESET_ALL_PACKAGE_QUANTITY:
      {
        let pk = state.publicPackages;
        pk.forEach((p) => {
          p.quantity = 0;
          p.isLinkPackageExpand = false;
          p.isBooked = true;
          if (p.isHaveLinkPackage && p.linkPackages && p.linkPackages.length > 0)
            p.linkPackages.forEach((lp) => {
              lp.quantity = 0;
            });
        });
        return { ...state, publicPackages: pk };
      }
    case PBActionType.SET_EVENT_LIST:
      {
        let added = state.publicEvent === undefined ? [] : state.publicEvent;

        action.payload.map((x) => {
          let isExist = added.findIndex((y) => y.id === x.id);
          if (isExist === -1) {
            added.push(x)
          }
          else added[isExist] = x;
        });

        return { ...state, publicEvent: added };
      }
    case PBActionType.BOOKING_FAILED:
      return state;
    case PBActionType.SET_BOOKING_TYPE:
      return { ...state, bookingMode: action.payload };

    case PBActionType.ADD_BOOKING_SESSION:
      var sessionTimers = _.cloneDeep(state.sessionTimers);
      sessionTimers.bookingSession = action.payload;
      return { ...state, sessionTimers: sessionTimers };

    case PBActionType.ADD_PAYMENT_SESSION:
      var sessionTimers = _.cloneDeep(state.sessionTimers);
      sessionTimers.paymentSession = action.payload;
      return { ...state, sessionTimers: sessionTimers };

    case PBActionType.START_PAYMENT_SESSION:
      {
        var session = state.sessionTimers.paymentSession;
        var sessionTimers = _.cloneDeep(state.sessionTimers);
        if (session && !session.isSessionTimerStart && action.payload) {
          session.sessionStartDate = Utility.convertISOToDate(action.payload);
          session.sessionEndTime = session.sessionStartDate.getTime() + (session.displayValue * 60000);
          session.isSessionTimerStart = true;
        }
        session.timerPaused = false;
        sessionTimers.paymentSession = session;
        return { ...state, sessionTimers: sessionTimers };
      }
    case PBActionType.PAUSE_BOOKING_SESSION:
      {
        var sessionTimers = _.cloneDeep(state.sessionTimers);
        sessionTimers.bookingSession.timerPaused = true;
        sessionTimers.bookingSession.timerPauseTime = new Date().getTime();
        return { ...state, sessionTimers: sessionTimers };
      }
    case PBActionType.RESUME_BOOKING_SESSION:
      {
        var sessionTimers = _.cloneDeep(state.sessionTimers);
        sessionTimers.bookingSession.timerPaused = false;
        sessionTimers.bookingSession.sessionEndTime= new Date().getTime()+(sessionTimers.bookingSession.sessionEndTime - sessionTimers.bookingSession.timerPauseTime);
        sessionTimers.bookingSession.timerPauseTime = null;
        return { ...state, sessionTimers: sessionTimers };
      }
    case PBActionType.START_BOOKING_SESSION:
      var session = state.sessionTimers.bookingSession;
      var sessionTimers = _.cloneDeep(state.sessionTimers);

      if (session && !session.isSessionTimerStart && action.payload) {
        session.sessionStartDate = Utility.convertISOToDate(action.payload);
        session.sessionEndTime = session.sessionStartDate.getTime() + (session.displayValue * 60000);
        session.isSessionTimerStart = true;
      }
      sessionTimers.bookingSession = session
      return { ...state, sessionTimers: sessionTimers };

    case PBActionType.PAUSE_PAYMENT_SESSION:
      {
        var sessionTimers = _.cloneDeep(state.sessionTimers);
        if(sessionTimers && !!sessionTimers.paymentSession ) {
          sessionTimers.paymentSession.timerPaused = true;
          sessionTimers.paymentSession.timerPauseTime = new Date().getTime();
        }
        return { ...state, sessionTimers: sessionTimers };
      }

    case PBActionType.RESUME_PAYMENT_SESSION:
      {
        var sessionTimers = _.cloneDeep(state.sessionTimers);
        if(sessionTimers && sessionTimers.paymentSession) {
          sessionTimers.paymentSession.timerPaused = false;
          sessionTimers.paymentSession.sessionEndTime= new Date().getTime()+(sessionTimers.paymentSession.sessionEndTime - sessionTimers.paymentSession.timerPauseTime);
          sessionTimers.paymentSession.timerPauseTime = null;
        }
        return { ...state, sessionTimers: sessionTimers };
      }

    case PBActionType.ADD_GLOBAL_SESSION:
      var sessionTimers = _.cloneDeep(state.sessionTimers);
      sessionTimers.globalSession = action.payload;

      return { ...state, sessionTimers: sessionTimers };

    case PBActionType.START_GLOBAL_SESSION:
      var session = state.sessionTimers.globalSession;
      var sessionTimers = _.cloneDeep(state.sessionTimers);

      if (session && !session.isSessionTimerStart && action.payload) {
        session.sessionStartDate = Utility.convertISOToDate(action.payload);
        session.sessionEndTime = session.sessionStartDate.getTime() + (session.displayValue * 60000);
        session.isSessionTimerStart = true;
      }
      sessionTimers.globalSession = session
      return { ...state, sessionTimers: sessionTimers };
    case PBActionType.SET_SELECTED_BOOKING:
      return {...state, selectedBooking: action.payload};
    case PBActionType.SET_ELIGIBLE_DELIVERY_METHOD:
        return {...state, eligibleDeliveryMethod: action.payload};
    case PBActionType.SET_SELECTED_BOOKED_PACKAGE:
        return {  ...state, selectedBookingPackage: action.payload };
    case PBActionType.SET_BOOKING_FROM:
      return { ...state, bookingFrom: action.payload };
    case PBActionType.SET_BOOKING_FLOW:
      return { ...state, bookingFlowOption: action.payload };
    case PBActionType.SET_CONTACTID:
        return { ...state, contactID: action.payload };
    case PBActionType.SET_SEAT:
        return { ...state, seat: action.payload };
    case PBActionType.CHECK_SEAT:
        return { ...state, checkSeat: action.payload };
    case PBActionType.SET_DESELECT_SEAT:
        return{ ...state, deSelectSeat: action.payload};
    case PBActionType.SET_ALLSEATS:
        return { ...state, allSeats: action.payload };
    case PBActionType.SET_DESELECT_ALLSEATS:
        return { ...state, allDeSelectSeats: action.payload };
    default:
      return state;
  }
}
