import { LAYOUT_TYPE, VenueMapStatus } from './utils';
import { Image, ImageSrc, MousePosition, Shape, TransformData, Label } from './CommonDTOs';
import { Facility, OtherStructure } from './FacilityAndOtherStructure';
import { Venue } from './Venue';

export class VenueMap {
  public id: string
  public venue: Venue
  public name: string
  public status: boolean
  public ArrangedLayouts: ArrangedLayout[] = []
  public backgroundImage?: Image
  public centerPoint?: MousePosition
  constructor() { }

  initialize(data, venue?) {
    if (data) {
      const obj = !!data.layout && JSON.parse(data.layout);
      this.id = !!data.id ? data.id : '';
      this.name = !!data.name ? data.name : '';
      if (obj.length > 0) {
        this.ArrangedLayouts = obj.map(x => {
          return new ArrangedLayout().initialize(x);
        });
      }
      else {
        this.ArrangedLayouts = [];
      }
      this.backgroundImage = data.backgroundImage && new Image().initialize(data.backgroundImage);
      this.status = data.isActive;
      this.venue = venue? new Venue().inilialize(venue): data.venue;
      return this;
    }
  }
}

export class  ArrangedLayout {
  constructor(
    public type?: LAYOUT_TYPE,
    public layoutId?: string,
    public structure?: Facility | OtherStructure,
    public layout?: Shape [],
    public image?: Image,
    public isPrivate?: Boolean,
    public transformation = new TransformData(),
    public labels?: Label[],
    public centerPoint = new MousePosition()) {
  }
  initialize(d) {
    this.type = d.Type || d.type;
    this.layoutId = d.LayoutId || d.layoutId;
    this.structure = d.Structure || d.structure;
    this.layout = d.Layout || d.layout;
    if (d.Image || d.image ){
      this.image = d.Image || d.image;
    }
    this.transformation = d.Transformation || d.transformation;
    this.labels = d.Labels || d.labels;
    this.centerPoint = d.CenterPoint || d.centerPoint;
    return this;
  }
}
