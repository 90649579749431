<!--<form [formGroup]="manageCotactsForm" (ngSubmit)="SaveCorporateContact()">-->
    <div class="mmc-modal-wrapper manage-contacts-modal">
        <div class="mmc-modal-header mmc_flex--container">
            <div class="mmc_flex__item--inherit mmc_flex__item--vcenter icon-cage mmc_just-content-center">
                <i class="material-icons pw-ico-color">settings</i>
            </div>
            <div class="mmc_flex__item mmc_flex__item--vcenter">
                <span class="section-title">Manage Contacts</span>
            </div>
            <div class="mmc_flex__item--inherit mmc_flex__item--vcenter close-icon-cage mmc_just-content-center">
                <button mat-button [mat-dialog-close]="true"><i class="material-icons">close</i></button>
            </div>
        </div>
        <div class="mmc-modal-body">
            <form [formGroup]="manageCotactsForm" (ngSubmit)="SaveCorporateContact()">
                <div class="row">
                    <div class="col-sm-12" #focusContent>
                        <div class="inner-container add-contact-container" >
                            <div class="mmc_flex--container sub-section-title">
                                <div class="mmc_flex__item--inherit font__semibold">
                                    Contact Details
                                </div>
                                <div class="mmc_flex__item">
                                    <hr />
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <div class="row">
                                        <div class="col-sm-5 col-md-5 col-lg-3 no-right-padding">
                                            <mat-form-field appearance="outline">
                                                <!--<mat-label>Select me</mat-label>-->
                                                <mat-label>{{SelectedTitleName}}</mat-label>
                                                <mat-select placeholder={{SelectedTitleName}}>
                                                    <mat-option *ngFor="let title of Titles" (click)="SelectTitle(title.id)" (keydown.enter)="SelectTitle(title.id)">{{title.name}}</mat-option>
                                                </mat-select>

                                                <!--<mat-hint>Hint</mat-hint>-->
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-7 col-md-7 col-lg-9 col-xl-9 no-left-padding">
                                            <mat-form-field appearance="outline">
                                                <mat-label>First Name</mat-label>
                                                <input matInput placeholder="James" formControlName="FirstName" required autocomplete="off">

                                                <!--<mat-hint>Hint</mat-hint>-->
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Last Name</mat-label>
                                        <input matInput placeholder="Smith" formControlName="LastName" required autocomplete="off">

                                        <!--<mat-hint>Hint</mat-hint>-->
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <div class="row">
                                        <div class="col-sm-12">

                                            <div class="mmc_flex--container cus-container">
                                                <div class="mmc_flex__item w120">
                                                    <mat-form-field appearance="outline">
                                                        <!--<mat-label>Select me</mat-label>-->
                                                        <mat-label>{{SelectedPhoneTypeName}}</mat-label>
                                                        <mat-select>
                                                            <mat-option *ngFor="let comTypeAdPhone of CommunicationTypesPhone" (click)="SelectComType(comTypeAdPhone.id, 1, false)" (keydown.enter)="SelectComType(comTypeAdPhone.id, 1, false)">{{comTypeAdPhone.name}}</mat-option>
                                                        </mat-select>

                                                        <!--<mat-hint>Hint</mat-hint>-->
                                                    </mat-form-field>
                                                </div>

                                                <div class="mmc_flex__item customer-data">
                                                    <mat-form-field appearance="outline">
                                                        <mat-label>{{SelectedPhoneTypeName}}</mat-label>
                                                        <input matInput placeholder="{{placholderSampleNumber}}" calss="form-control" formControlName="PersonalPhoneNumber_1" required autocomplete="off">
                                                        <mat-error *ngIf="manageCotactsForm.controls.PersonalPhoneNumber_1.errors !== null || manageCotactsForm.controls.PersonalPhoneNumber_1.touched || manageCotactsForm.controls.PersonalPhoneNumber_1.dirty">Please enter valid {{SelectedPhoneTypeName|lowercase}} number.</mat-error>

                                                        <!--<mat-hint>Hint</mat-hint>-->
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="mmc_flex--container cus-container">
                                                <div class="mmc_flex__item w120">
                                                    <mat-form-field appearance="outline">
                                                        <!--<mat-label>Select me</mat-label>-->
                                                        <mat-label>{{SelectedEmailTypeName}}</mat-label>
                                                        <mat-select>
                                                            <mat-option *ngFor="let comTypeAdEmail of CommunicationTypesEmail" (click)="SelectComType(comTypeAdEmail.id, 2, false)" (keydown.enter)="SelectComType(comTypeAdEmail.id, 2, false)">{{comTypeAdEmail.name}}</mat-option>
                                                        </mat-select>

                                                        <!--<mat-hint>Hint</mat-hint>-->
                                                    </mat-form-field>
                                                </div>
                                                <div class="mmc_flex__item customer-data">
                                                    <mat-form-field appearance="outline">
                                                        <mat-label>{{SelectedEmailTypeName}}</mat-label>
                                                        <input type="email" matInput placeholder="abc@dep.com.au" calss="form-control" formControlName="PersonalEmail_1" required autocomplete="off">

                                                        <!--<mat-hint>Hint</mat-hint>-->
                                                    </mat-form-field>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="mmc_flex--container row">
                                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>User Name</mat-label>
                                        <input matInput placeholder="User Name" formControlName="UserName" required autocomplete="off" [readonly]="isEditContact">

                                        <!--<mat-hint>Hint</mat-hint>-->
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="mmc_flex--container row">
                                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6" *ngIf="isEmailAddressChanged">
                                    <mat-checkbox class="terms-checkbox" (change)="AcceptConditions($event)"><span class="wp-txt-body1">Send password reset email.</span></mat-checkbox>
                                </div>                             
                                </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="mmc_flex__item--inherit mmc_just-content-end opt-regular-booking">
                                        <button *ngIf="!isEditContact" type="submit" mat-button class="mat-btn-primary pw-text-trans__upper" tabindex="0">
                                            Add Contact
                                        </button>
                                        <button *ngIf="isEditContact" (click)="Cancelled()" (keydown.enter)="Cancelled()" mat-button class="mat-btn-tertiary wp-mr-10 pw-text-trans__upper" tabindex="0">
                                            Cancel
                                        </button>
                                        <button *ngIf="isEditContact" type="submit" mat-button class="mat-btn-primary pw-text-trans__upper" tabindex="0">
                                            Edit Contact
                                        </button>
                                        
                                    </div>
                                </div>
                            </div>
                                <!--<div class="mmc_flex--container mmc_just-content-end">
            <div class="mmc_flex__item--inherit">
                <mat-error class="pw-txt-s2" style="margin-top: 8px" *ngIf="!formProfile.valid">
                    Please fill all fields with valid information
                </mat-error>
            </div>
        </div>-->

                            </div>
                    </div>
                </div>
            </form>
            <form name="form">
            <div class="row">
                <div class="col-sm-12" #focusContactList>
                    <!--<form name="form">-->
                        <div class="inner-container contact-list-container mb-2">
                            <div class="mmc_flex--container sub-section-title">
                                <div class="mmc_flex__item--inherit font__semibold">
                                    List of Contacts
                                </div>
                                <div class="mmc_flex__item">
                                    <hr />
                                </div>
                            </div>

                            <div class="contact-list-wrapper">
                                <div class="mmc_flex--container contact-item" *ngFor="let contact of Getcontacts()">
                                    <div class="mmc_flex__item--inherit">
                                        <div class="name-ico">{{GetFirstLetterOfName(contact.firstName)}}</div>

                                    </div>
                                    <div class="mmc_flex__item w-25 contact-name">
                                        <div class="pw-txt-b3 pw-text-color__primary pw-txt-lh-1">{{contact.title}} {{contact.firstName}} {{contact.lastName}}</div>
                                    </div>
                                    <div class="mmc_flex__item w-25">
                                        <div class="pw-txt-b3 pw-text-color__primary pw-txt-lh-1nh">{{GetPhoneType(contact.communicationMethods)}}</div>
                                        <div class="pw-txt-b3 pw-text-color__secondary">{{GetPhoneNumber(contact.communicationMethods)}}</div>
                                    </div>

                                    <div class="mmc_flex__item p33 wp-mr-10 email-col">
                                        <div class="pw-txt-b3 pw-text-color__primary pw-txt-lh-1nh">{{GetEmailType(contact.communicationMethods)}}</div>
                                        <div class="pw-txt-b3 pw-text-color__secondary">{{GetEmailAddress(contact.communicationMethods)}}</div>
                                    </div>

                                    <div class="mmc_flex__item w-25">
                                        <div class="pw-txt-b3 pw-text-color__primary pw-txt-lh-1nh">Date Invited</div>
                                        <div class="pw-txt-b3 pw-text-color__secondary">{{contact.dateRegistered | dateFormat}}</div>
                                    </div>

                                    <div class="mmc_flex__item--inherit mmc_just-content-end contact-status">
                                        <mat-button-toggle-group name="{{contact.id}}" aria-label="Font Style" id="{{contact.id}}"  [(ngModel)]="contact.active" (change)="ChangeActiveStatus(contact.active, contact)">
                                            <mat-button-toggle class="pw-txt-s2" [value]="true">Active</mat-button-toggle>
                                            <mat-button-toggle class="pw-txt-s2" [value]="false">Inactive</mat-button-toggle>
                                            
                                        </mat-button-toggle-group>
                                    </div>
                                    <div class="mmc_flex__item--inherit mmc_just-content-end">
                                         <button mat-button class="mmc-btn-primary--small" tabindex="0" (click)="PopulateFormToEdit(contact)" (keydown.enter)="PopulateFormToEdit(contact)" >EDIT</button>
                                    </div>
                                </div>

                                <!--<div class="mmc_flex--container contact-item">
                                    <div class="mmc_flex__item--inherit">
                                        <div class="name-ico">A</div>

                                    </div>
                                    <div class="mmc_flex__item">
                                        <div class="pw-txt-b3 pw-text-color__primary pw-txt-lh-1">Mr Richar Gerald</div>
                                    </div>
                                    <div class="mmc_flex__item">
                                        <div class="pw-txt-b3 pw-text-color__primary pw-txt-lh-1nh">Mobile</div>
                                        <div class="pw-txt-b3 pw-text-color__secondary">+61 0000 0000</div>
                                    </div>

                                    <div class="mmc_flex__item">
                                        <div class="pw-txt-b3 pw-text-color__primary pw-txt-lh-1nh">Email</div>
                                        <div class="pw-txt-b3 pw-text-color__secondary">richardg@gmail.com</div>
                                    </div>

                                    <div class="mmc_flex__item contact-status">
                                        <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
                                            <mat-button-toggle class="pw-txt-s2" value="bold">Active</mat-button-toggle>
                                            <mat-button-toggle class="pw-txt-s2" value="italic">Inactive</mat-button-toggle>
                                        </mat-button-toggle-group>
                                    </div>
                                </div>

                                <div class="mmc_flex--container contact-item">
                                    <div class="mmc_flex__item--inherit">
                                        <div class="name-ico">A</div>

                                    </div>
                                    <div class="mmc_flex__item">
                                        <div class="pw-txt-b3 pw-text-color__primary pw-txt-lh-1">Mr Richar Gerald</div>
                                    </div>
                                    <div class="mmc_flex__item">
                                        <div class="pw-txt-b3 pw-text-color__primary pw-txt-lh-1nh">Mobile</div>
                                        <div class="pw-txt-b3 pw-text-color__secondary">+61 0000 0000</div>
                                    </div>

                                    <div class="mmc_flex__item">
                                        <div class="pw-txt-b3 pw-text-color__primary pw-txt-lh-1nh">Email</div>
                                        <div class="pw-txt-b3 pw-text-color__secondary">richardg@gmail.com</div>
                                    </div>

                                    <div class="mmc_flex__item contact-status">
                                        <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
                                            <mat-button-toggle class="pw-txt-s2" value="bold">Active</mat-button-toggle>
                                            <mat-button-toggle class="pw-txt-s2" value="italic">Inactive</mat-button-toggle>
                                        </mat-button-toggle-group>
                                    </div>
                                </div>-->
                            </div>

                        </div>
                    <!--</form>-->
                </div>
            </div>
            </form>
        </div>
    </div>
<!--</form>-->