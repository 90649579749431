export enum WMModelTitle {
    ADD_CLIENT = 'New Customer',
    CLIENT_SEARCH = 'Client Search',
    EDIT_CLIENT = 'Edit Customer',
  }

  export enum WMCustomerOptions {
    ADD_CUSTOMER = 'ADD CUSTOMER',
    EDIT_CUSTOMER = 'EDIT CUSTOMER',
 }
 
