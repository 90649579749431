import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DocModalComponent } from "../../booking-cart/doc-modal/doc-modal.component";
import { CalendarModalComponent } from "../../common/calendar-modal/calendar-modal.component";
import { EditModalComponent } from "../../booking-cart/edit-modal/edit-modal.component";
import { QuestionnaireComponent } from "../../modals/questionnaire/questionnaire.component";
import { AssetService } from "src/modules/services/asset.service";
import { Assetsearchparams } from "src/modules/models/asset/asset-search-params";
import { environment } from "src/environments/environment";
import { Utility } from "src/modules/utility";
import moment from "moment";

@Component({
  selector: "opt-modal-window",
  templateUrl: "./modal-window.component.html",
})
export class ModalWindowComponent {
  constructor(public dialog: MatDialog, public assetService: AssetService) {}

  public openDocPopup() {
    this.dialog.open(DocModalComponent, {
      data: { name: "Upload Documents" },
      panelClass: ["custom-dialog-container", "w80modal"],
      height: "auto",
      width: "80%",
    });
  }

  public openTimePopup() {
    this.dialog.open(CalendarModalComponent, {
      panelClass: ["custom-dialog-container", "w60modal"],
      height: "auto",
      width: "60%",
    });
  }

  public openEditPopup() {
    this.dialog.open(EditModalComponent, {
      panelClass: ["custom-dialog-container", "w60modal"],
      height: "auto",
      width: "60%",
    });
  }
  getDatefomTime(selected: Date) {
    /*
        this will combine data picker date anda time form time piker and return Iso date string.
        the this.date refere to moment date object
        */
    let searchabledate = new Date(
      this.date.year(),
      this.date.month(),
      this.date.date()
    );
    searchabledate.setHours(
      selected.getHours(),
      selected.getMinutes(),
      selected.getSeconds()
    );
    //return searchabledate.toISOString();
    return Utility.convertToISO(searchabledate);
  }
  date = moment(); //default the filter date to three days in the future
  StartTime: Date = new Date("2019-04-03 08:00:00.000");
  EndTime: Date = new Date('2019-04-03 08:00:00.000');
  public openQuesPopup() {
    // this.assetService.
    let searchParam = new Assetsearchparams();
    searchParam.pagesize = 10;
    searchParam.searchpharese = "36th Battalion Park";
    searchParam.pagenumber = 1;
    searchParam.starttime = this.getDatefomTime(this.StartTime);
    searchParam.endtime = this.getDatefomTime(this.EndTime);
    searchParam.considerOpeningHours = true;
    let dtS = new Date(this.date.year(), this.date.month(), this.date.date());
    let dtE = new Date(this.date.year(), this.date.month(), this.date.date());
    dtE.setHours(23);
    dtE.setMinutes(59);
    dtE.setSeconds(0);
    searchParam.starttime = this.getDatefomTime(dtS);
    searchParam.endtime = this.getDatefomTime(dtE);
    searchParam.considerOpeningHours = false;
    searchParam.duration = environment.MinimumBookingDuration;
    searchParam.filterattendens = "0";
    searchParam.assetname = "";
    searchParam.timelable = "Any";
    searchParam.sort = "Name";
    let availablityResult = this.assetService.facilittySearch(searchParam);
    availablityResult.subscribe((data) => {
      console.log(data);
    });
    this.dialog.open(QuestionnaireComponent, {
      data: { name: "Upload Documents" },
      panelClass: ["custom-dialog-container", "w80modal"],
      height: "auto",
      width: "80%",
    });
  }
}
