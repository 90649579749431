import { Question, TextQuestion, BooleanQuestion, SingleChoiceQuestion, MultipleChoiceQuestion } from '../question/question';
import { BookingAnswerChoice } from '../question/booking-answer-choice';
import { AnswerChoice } from '../question/answer';
import { BaseResource } from 'src/modules/models/base-resource';
import { Time } from '@angular/common';
import { jsonIgnore } from 'json-ignore';


export class BookingQuestion extends BaseResource {
    constructor() {
        super();
        this.type = "BookingQuestion";
    }
    question: Question;
    undecided: boolean;
    answeredTime: Date;
    isChanged: boolean;
    conditionalQuestion: boolean
    conditionalBookingQuestions: BookingQuestion[];
    bookingConditionalQuestionAnswers: ConditionalQuestionAnswer[];
    @jsonIgnore() canDisplayQuestion: boolean = false;

}

export class BookingQuestionChoice extends BaseResource {
    constructor() {
        super();
        this.type = "BookingQuestionChoice";
    }
    name: string;
    answerChoice: AnswerChoice;
    quantity: string;
    isSelectedAnswer: boolean;
}

export class BooleanQuestionAnswer extends BookingQuestion {
    constructor() {
        super();
        this.type = "BooleanQuestionAnswer";
    }
    answer: boolean;
}
export class DateAndTimeQuestionAnswer extends BookingQuestion {
    constructor() {
        super();
        this.type = "DateAndTimeQuestionAnswer";
    }
  date: any;
  time: any;
  @jsonIgnore() tempTime: any;
  // defaultDate: Date;
  // defaultTime: Date;
}
export class MultipleChoiceQuestionAnswer extends BookingQuestion {

    constructor() {
        super();
        this.type = "MultipleChoiceQuestionAnswer";
    }
    bookingQuestionChoices: BookingQuestionChoice[];
    bookingAnswerChoice: BookingAnswerChoice[];
}

export class TextQuestionAnswer extends BookingQuestion {
    constructor() {
        super();
        this.type = "TextQuestionAnswer";
    }
    answer: string;
}

export class SingleChoiceQuestionAnswer extends BookingQuestion {
    constructor() {
        super();
        this.type = "SingleChoiceQuestionAnswer";
    }
    bookingQuestionChoices: BookingQuestionChoice[]

    bookingAnswerChoice: BookingAnswerChoice;
}

export class RichTextQuestionAnswer extends TextQuestionAnswer {

}
export class FileUploadQuestionAnswer extends TextQuestionAnswer {
    constructor() {
        super();
        this.type = "FileUploadQuestionAnswer";
    }
    maxSize: number;
    supportedFileType: string;
    maxCount:number;
    minCount:number;
}
export class ConditionalQuestionAnswer extends BaseResource{
    constructor() {
        super();
        this.type = "ConditionalQuestionAnswer";
    }
  answerChoiceId: string;
  primaryQuestion: Question;
  answerYes: boolean;
  answerDate:Date;
  answerTime:Date;
  dateCount:number
}
