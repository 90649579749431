<div class="mmc-modal-wrapper cancel-booking-modal session-expire-modal">
  <div class="title-close-wrapper">
    <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-h2" [ngClass]="{'session-timerout-title':isSessionTimeReset}">
     {{"SESSIONEXPIRE.Session Expiring" |optimotranslate}}
    </div>
    <div class="close-btn" style="align-items:center;display:flex;background: #00507c;">
      <mat-icon style="color: #ffffff;display: flex;justify-content:flex-end;cursor: pointer;"(click)="dialogRef.close()">close</mat-icon>
    </div>
  </div>
  
  <div class="mmc-modal-body" style="padding: 17px 32px 0 20px !important;">
    <div class="mmc_flex--container" >
      <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-h2" [ngClass]="{'session-timerout-title':isSessionTimeReset}">
        {{ header }}
      </div>
     
    </div>
    <div class="mmc_flex--container inner" [ngSwitch]="modelType">
      <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-b2" style="justify-content: center;" *ngSwitchCase="SESSION_CONFIRMATION_M_TYPE.BOOKING_SESSION_RESET">
        {{"SESSIONEXPIRE.Would you like some more time?" | optimotranslate}}
      </div>
      <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-b2" style="justify-content: center;" *ngSwitchCase="SESSION_CONFIRMATION_M_TYPE.SESSION_EXPIRATION_CONFIRMATION">
        {{"SESSIONEXPIRE.Click Proceed to create a new session."| optimotranslate}}
      </div>
      <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-b2" style="justify-content: center;" *ngSwitchCase="SESSION_CONFIRMATION_M_TYPE.NonCommonDeliveryMethodMessage">
        {{nonCommonDeliveryMethodMessage}}
      </div>
    </div>
  </div>
    <div [ngSwitch]="modelType"class="mmc-modal-footer">
      <div class="btn-wrapper" *ngSwitchCase="SESSION_CONFIRMATION_M_TYPE.BOOKING_SESSION_RESET" style="justify-content: flex-end;display: flex;margin-right: 30px;margin-top: 15px;padding-bottom: 15px;">
        <button type="submit" class="mmc-btn mmc-btn-primary--small main mmc_flex__item--vcenter s-no" style="border-radius: 25px !important;" mat-button
          (click)="dialogRef.close(false)">
          {{"SESSIONEXPIRE.No" | optimotranslate}}
        </button>
        <button type="submit" class="mmc-btn mmc-btn-secondary--small main mmc_flex__item--vcenter s-yes" style="margin-right:-10px; margin-left:unset;" mat-button
          (click)="confirm()">
          {{"SESSIONEXPIRE.Yes" | optimotranslate}}
        </button>
      </div>
      <div class="btn-wrapper" style="display:flex; justify-content: flex-end; height: 55px;align-items: center;" *ngSwitchCase="SESSION_CONFIRMATION_M_TYPE.SESSION_EXPIRATION_CONFIRMATION">
        <button type="submit" class="mmc-btn mmc-btn-secondary--small main mmc_flex__item--vcenter" mat-button
          (click)="dialogRef.close(false)" style="margin-right:20px;">
          {{"SESSIONEXPIRE.Proceed" | optimotranslate}}
        </button>
      </div>
      <div class="btn-wrapper" style="display:flex; justify-content: flex-end; height: 55px;align-items: center;" *ngSwitchCase="SESSION_CONFIRMATION_M_TYPE.NonCommonDeliveryMethodMessage">
        <button type="submit" class="mmc-btn mmc-btn-secondary--small main mmc_flex__item--vcenter" mat-button
          (click)="dialogRef.close(true)" style="margin-right:20px;">
          {{"SESSIONEXPIRE.Proceed" | optimotranslate}}
        </button>
      </div>
      <div class="btn-wrapper" style="justify-content: space-between;display:flex;" *ngSwitchCase="SESSION_CONFIRMATION_M_TYPE.GLOBAL_SESSION_EXPIRATION_CONFIRMATION">
        <button type="submit" class="mmc-btn mmc-btn-primary--small main mmc_flex__item--vcenter" mat-button
          (click)="dialogRef.close(true)">
          {{"SESSIONEXPIRE.Cancel Booking" | optimotranslate}}
        </button>
        <button type="submit" class="mmc-btn mmc-btn-secondary--small main mmc_flex__item--vcenter" mat-button
          (click)="dialogRef.close(true)">
          {{"SESSIONEXPIRE.Proceed" | optimotranslate}}
        </button>
      </div>
       <div class="btn-wrapper"style="justify-content:flex-end;" *ngSwitchCase="SESSION_CONFIRMATION_M_TYPE.GLOBAL_SESSION_EXPIRED">
        <button type="submit" class="mmc-btn mmc-btn-secondary--small main mmc_flex__item--vcenter" mat-button
          (click)="dialogRef.close(true)">
          {{"SESSIONEXPIRE.Proceed" | optimotranslate}}
        </button>
      </div>
    </div>
  
</div>
