import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'opt-questionnaire-modal',
  templateUrl: './questionnaire-modal.component.html',
  styleUrls: ['./questionnaire-modal.component.scss']
})
export class QuestionnaireModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
