import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AddExtrasComponent } from './add-extras/add-extras.component';
import { CancelBookingComponent } from './cancel-booking/cancel-booking.component';
//import { BookingChangeRequestComponent } from './booking-change-request/booking-change-request.component';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { ManageContactsComponent } from './manage-contacts/manage-contacts.component';

//wp-modals

import { WpMessageComponent } from './wp-message/wp-message.component';
import { WpChangeRequestComponent } from './wp-change-request/wp-change-request.component';
import { WpRequestAccessComponent } from './wp-request-access/wp-request-access.component';
import { WpAlreadyAccessComponent } from './wp-already-access/wp-already-access.component';


import { AlternativeOptionsComponent } from 'src/modules/booking-portal/booking-cart/edit-modal/alternative-options/alternative-options.component';

import { ExtraAllocationComponent } from './extra-allocation/extra-allocation.component';
import { ExtraDeletionComponent } from './extra-deletion/extra-deletion.component';

@Component({
    selector: 'opt-modals',
    templateUrl: './modals.component.html'
})

export class ModalsComponent {

    constructor(public dialog: MatDialog) { }

    openAddExtras(): void {
        const dialogRef = this.dialog.open(AddExtrasComponent, {
            panelClass: ['w80modal', 'dialog-container-common'],
            width: '250px'
        });
    }

    openQuestionnaire(): void {
        const dialogRef = this.dialog.open(QuestionnaireComponent, {
            panelClass: ['w80modal', 'dialog-container-common'],
            width: '250px'
        });
    }

    openCancelBooking(): void {
        const dialogRef = this.dialog.open(CancelBookingComponent, {
            panelClass: ['w60modal', 'dialog-container-common'],
            width: '150px'
        });
    }

    //openBookingChangeRequest(): void {
    //    const dialogRef = this.dialog.open(BookingChangeRequestComponent, {
    //        panelClass: ['w60modal', 'dialog-container-common'],
    //        width: '150px'
    //    });
    //}

    openManageContacts(): void {
        const dialogRef = this.dialog.open(ManageContactsComponent, {
            panelClass: ['w80modal', 'dialog-container-common'],
            width: '250px'
        });
    }

    //wp-modals

    openWpMessage(): void {
        const dialogRef = this.dialog.open(WpMessageComponent, {
            panelClass: ['w60modal', 'dialog-container-common'],
            width: '150px'
        });
    }


    openWpChangeRequest(): void {
        const dialogRef = this.dialog.open(WpChangeRequestComponent, {
            panelClass: ['w60modal', 'dialog-container-common'],
            width: '150px'
        });
    }

    openWpRequestAccess(): void {
        const dialogRef = this.dialog.open(WpRequestAccessComponent, {
            panelClass: ['w60modal', 'dialog-container-common'],
            width: '150px'
        });
    }

    openWpAlreadyAccess(): void {
        const dialogRef = this.dialog.open(WpAlreadyAccessComponent, {
            panelClass: ['w60modal', 'dialog-container-common'],
            width: '150px'
        });
    }

    openAlternativeOptions(): void {
        const dialogRef = this.dialog.open(AlternativeOptionsComponent, {
            panelClass: ['w80modal', 'dialog-container-common'],
            width: '250px'
        });
    }

    openExtraAllocation(): void {
        const dialogRef = this.dialog.open(ExtraAllocationComponent, {
            panelClass: ['w80modal', 'dialog-container-common'],
            width: '250px'
        });
    }

    openDeletionAllocation(): void {
        const dialogRef = this.dialog.open(ExtraDeletionComponent, {
            panelClass: ['w80modal', 'dialog-container-common'],
            width: '250px'
        });
    }
}
