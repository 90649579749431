<ngu-carousel #myCarousel [inputs]="carouselConfig" [dataSource]="carouselItems" class="mmc_carousel">
    <div *nguCarouselDef="let item;" class="item">
        <div class="tile">
            <div class="mmc_flex--container slider-card">
                <div class="mmc_flex__item image-cage">
                    
                    <div class="slider-carousel-image">
                        <a href="#/facility-detail?Time=Any&&AssetId={{item.id}}&&Date={{dateInUrl | date:'medium'}}">
                            <!-- <img src={{item.url}} alt="{{item.name}}" class="img" style="width:100%"> -->
                            <div class="img" [style.background-image]="'url(' + item.url + ')'">
                                <div class="overlay"></div>
                            </div>
                        </a>
                        <div class="overlay"></div>
                    </div>
                </div>

                <div class="mmc_flex__item details">
                    <div class="mmc_flex--container slider-details">
                        <div class="mmc_flex__item">
                            <div class="item_name">
                                <div class="pw-txt-h5"><a href="#/facility-detail?Time=Any&&AssetId={{item.id}}&&Date={{dateInUrl | date:'medium'}}">{{item.name}}</a></div>
                            </div>
                        </div>
                        <div class="mmc_flex__item">
                            <div class="item_description pt-1">
                                <div class="pw-txt-b2">{{item.directions}}</div>
                            </div>
                        </div>
                        <div class="mmc_flex__item">
                            <div class="aminity-icons">
                                <img matTooltip="{{amenity.name}}" *ngFor="let amenity of item.amenities" src="{{amenity.icon}}" width="15" height="15" padding-right="10" alt="{{amenity.name}}">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <button NguCarouselNext [style.opacity]="myCarousel.isLast ? 0:1"  class="rightRs">
        <i class="material-icons">keyboard_arrow_right</i>
    </button>
    <button NguCarouselPrev [style.opacity]="myCarousel.isFirst ? 0:1" class="leftRs">
        <i class="material-icons">keyboard_arrow_left</i>
    </button>
    <ul class="myPoint" NguCarouselPoint>
        <li *ngFor="let p of myCarousel.pointNumbers; let j = index" [class.active]="p==myCarousel.activePoint" (click)="myCarousel.moveTo(j)" (keydown.enter)="myCarousel.moveTo(j)"></li>
    </ul>
</ngu-carousel>
