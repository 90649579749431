import { BaseResource } from '../base-resource';

export class Note extends BaseResource {

  public plainText: string;
  public richText: string;
  public html: string;
  public entityTypeID: string;
  public entityType: string;
  public noteTypeId: string;
  public noteType: string;
  public entityConfigurationProfileId: string;
  public imageUrl: string;
  public imageName: string;
  public base64Image: string;
  public imageType: string;
  public insertedTime: Date;
  public insertedUser: string;

  constructor() {
    super();
    this.type =  'note';
  }
}

export enum EntityNoteType {
  WasteCollectionNotes = '10',
  ReportMissedCollectionNotes = '11',
  DriverNotes = '12',
  RescheduleNotes = '13',
  RequestRescheduleNotes = '14',
  CollectionRejectNotes = '15',
  NotPresentNotes = '19'
}

export enum EntityNoteTypeName {
  WasteCollectionNotes = 'Waste Collection Notes',
  ReportMissedCollectionNotes = 'Report Missed Collection Notes',
  DriverNotes = 'Driver Notes',
  RescheduleNotes = 'Reschedule Notes',
  RequestRescheduleNotes = 'Request Reschedule Notes',
  CollectionRejectNotes = 'Collection Reject Notes'
}
