<div class="wp-rollover-booking-modal wp-modal">
    <div class="wp-modal-header">
        <div class="wp_flex--container w-100">
            <div class="wp_flex__item wp_flex__item--vcenter">
                <h4 class="wp-heading-4">Rollover of Application - {{rolloverBooking?.bookingReference}}</h4>
            </div>
            <div class="wp_flex__item--inherit wp_flex__item--vcenter">
                <button [mat-dialog-close]="true"><i class="material-icons wp-primary-icon">close</i></button>
            </div>
        </div>
    </div>
    <div class="wp-modal-body">
        <div class="wp-txt-body1 wp-txt-title wp-mb-20" id="scrollElement">Select an appropriate term for your booking</div>
        <div class="wp-mb-30 term-card" *ngIf="displaySeasonComponents">
            <opt-wp-seasons-carousel [inputSeasonId]="rollOverSeasonId" [inputBookingTypeId]="rollOverBookingTypeId" (emitChangeSeason)="ReciveSeasonChanges($event)"></opt-wp-seasons-carousel>
        </div>
        <div class="wp-mb-30" *ngIf="showRolloverTeams">
            <opt-wp-add-teams [inputBookingPackage]="RollOverBookingpackage" [inputSeasonId]="rollOverSeasonId" [isRollover]="true"
                             [inputBookingTypeId]="rollOverBookingTypeId" (bookingSaveResult)="navigateNextStepper($event)"></opt-wp-add-teams>
        </div>
  
      
        <div class="row wp-section-title wp-mb-30" *ngIf="showRolloverTeams">
            <div class="col-sm-12 ">
                <div class="wp-txt-body1 wp-txt-title" *ngIf="selectedStartDate"><div class="wp-pr-10">{{avalibilityAssetList?.length}} Facilities are being rolled over</div><div class="wp-txt--danger" *ngIf="getFacilityAvalability() > 0">, {{getFacilityAvalability()}} Unavailable</div></div>
                <div class="wp-txt-body1 wp-txt-subtitle wp-mb-20">Select the following details that you wish to roll over</div>
            </div>

            <div class="col-sm-12">
                <div class="d-flex">
                    <div class="wp-txt-body1 wp-txt-title wp-pr-10">Start the rollover bookings from</div>
                    <div class="wp-pr-10">
                        <mat-form-field  [ngClass]="{'wp-form-field--error':(selectedStartDate==null || selectedStartDate==undefined)?true:false}">
                            <input matInput [(ngModel)]="selectedStartDate" (dateChange)="DateSelection()" [min]="StartFrom" [max]="endBy"  [matDatepicker]="picker"  placeholder="Choose a date">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="">
                        <i class="material-icons mat-btn-icon--secondary" matTooltip="Select the date you want to start rolling over bookings from">help</i>
                    </div>
                </div>
            </div>




            <div *ngIf="showErrorMessage" id="topElement">

                <wp-error-message [errorMessageType]="2" [MessageDetail]="messageDetail"></wp-error-message>
            </div>

            <div class="col-sm-12">
                <div class="wp_flex--container">
                    <div class="wp-txt-body1 wp-txt-title wp-mb-10">Select the following details that you wish to roll over</div>
                </div>
                <div class="wp_flex--container">
                    <div class="wp_flex__item wp_flex__item--vcenter rollover-items">
                        <mat-checkbox [(ngModel)]="recurrentCheckBox" (change)="validateRecurrentItems($event)">Recurrence pattern</mat-checkbox>
                        <div class="wp-txt-body1 wp-txt-subtitle wp-mb-10 item-count">{{totalRecurrentDatesCount}} Date(s)</div>
                    </div>
                    <div class="wp_flex__item wp_flex__item--vcenter rollover-items">
                        <mat-checkbox [(ngModel)]="addHocCheckBox"  (change)="validateAdhocItems($event)">Ad hoc dates</mat-checkbox>
                        <div class="wp-txt-body1 wp-txt-subtitle wp-mb-10 item-count">{{totalAdHocDatesCount}} Date(s)</div>
                    </div>
                    <div class="wp_flex__item wp_flex__item--vcenter rollover-items">
                        <mat-checkbox [(ngModel)]="questionCheckBox" (change)="DisplayMessage($event)">Answers of questionnaires</mat-checkbox>
                    </div>
                    <div class="wp_flex__item wp_flex__item--vcenter rollover-items">
                        <mat-checkbox [(ngModel)]="documentCheckBox">Documents</mat-checkbox>
                    </div>
                    <div class="wp_flex__item wp_flex__item--vcenter rollover-items">
                        <mat-checkbox [(ngModel)]="upsellCheckBox" (change)="validateUpsellItems($event)">Upsells</mat-checkbox>
                        <div class="wp-txt-body1 wp-txt-subtitle wp-mb-10 item-count">{{totalUpsellItemsCount}} Item(s)</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="wp_flex--container justify-content-end">
            <div class="wp_flex__item--inherit wp_flex__item--vcenter">
                <button mat-button class="mat-btn-primary" (click)="onClickCreateBooking()" (keydown.enter)="onClickCreateBooking()">Create Booking</button>
            </div>
        </div>
    </div>
</div>