import { BaseResource } from './../../../modules/models/base-resource';

export class ClientGroup extends BaseResource {
    name: string;

    initialize(d) {
        this.id = d.id;
        this.name = d.name;
        return this;
    }
}