<div class="cdk-overlay-container">
    <div class="cdk-overlay-backdrop cdk-overlay-dark-backdrop-obacity cdk-overlay-backdrop-showing">
    </div>
    <div class="loader-outer">
        <div class="loader">
            <div class="sniper">
                <div class="loading-spinner">
                    <div class="loading-spinner-inner">
                      <div class="loading-spinner-circle"></div>
                      <div class="loading-spinner-circle"></div>
                      <div class="loading-spinner-circle"></div>
                      <div class="loading-spinner-circle"></div>
                      <div class="loading-spinner-circle"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

