import { BaseResource } from '../base-resource';
import { RecurrenceOption } from 'src/modules/models/regular-portal/recurrence-option';

export class RecurrentTimeSlots extends BaseResource {
    constructor() {
        super();
        this.type = "RecurrentTimeSlots";
    }
    id: string;
    startTime: string;
    endTime: string;
    availability: number;
}

export class RolloverRecurrentTimeSlots extends BaseResource {
    constructor() {
        super();
        this.type = "RolloverRecurrentTimeSlots";
    }
    id: string;
    availableFinalDate: string;
    totalCount: number;
    availableTimeslots: RecurrentTimeSlots[];
    recurrenceOption: RecurrenceOption;
}