import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'opt-navigation-confirmation',
  templateUrl: './navigation-confirmation.component.html',
  styleUrls: ['./navigation-confirmation.component.scss']
})
export class NavigationConfirmationComponent implements OnInit {
  @Output() onAdd = new EventEmitter<any>(true);
  constructor(public matDialogRef: MatDialogRef<NavigationConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  cancelClick() {
    this.matDialogRef.close();
  }
  navigateClick() {
    this.matDialogRef.close(true);
  }

}
