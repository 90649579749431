export class VenueWiseAssets {

    
    id: number;
    name: string;
    List: Asset[];
    type: string;
    constructor() {
        this.type = "VenueWiseAssets";
    }

}


export class Asset {
    id: number;
    name: string;
    type: string;
    constructor() {
        this.type = "Asset";
    }
    //capacity: number;
    //facilityType: string;
}

