import { Action } from '@ngrx/store';


export enum LoadingActionTypes {
    FACILITY_SEARCH_LOADING = 'FACILITY_SEARCH_LOADING',
    FACILITY_SEARCH_LOADED = 'FACILITY_SEARCH_LOADED',
    BOOKING_SAVE_STARTED = 'BOOKING_SAVE_STARTED',
    BOOKING_SAVE_COMPLETED = 'BOOKING_SAVE_COMPLETED',
    LOADING = 'LOADING',
    LOADED = 'LOADED',
    PUBLIC_LOADING="PUBLIC_LOADING",
    PUBLIC_LOADED="PUBLIC_LOADED",
    ADD_REQUEST_COUNTER="ADD_REQUEST_COUNTER",
    SAVE_SEARCH_FILTER_PARAM = 'SAVE_SEARCH_FILTER_PARAM',
}

export class FacilitySearchLoading implements Action {
    readonly type = LoadingActionTypes.FACILITY_SEARCH_LOADING
}

export class FacilitySearchLoaded implements Action {
    readonly type = LoadingActionTypes.FACILITY_SEARCH_LOADED
}
export class BookingSaveStarted implements Action {
    readonly type = LoadingActionTypes.BOOKING_SAVE_STARTED
}

export class BookingSaveCompleted implements Action {
    readonly type = LoadingActionTypes.BOOKING_SAVE_COMPLETED
}

export class LoadingStarted implements Action {
    readonly type = LoadingActionTypes.LOADING
}

export class LoadingCompleted implements Action {
    readonly type = LoadingActionTypes.LOADED
}
//PublicWeb Loading
export class PublicLoading implements Action {
    readonly type = LoadingActionTypes.PUBLIC_LOADING
}

export class PublicLoaded implements Action {
    readonly type = LoadingActionTypes.PUBLIC_LOADED
}
export class AddRequestCount implements Action {
    readonly type = LoadingActionTypes.ADD_REQUEST_COUNTER
    constructor(public payload: number) { }
}
export class SetFacilitySearchData implements Action {
    readonly type = LoadingActionTypes.SAVE_SEARCH_FILTER_PARAM
    constructor(public payload: any) { }
}
export type LoadingActions = FacilitySearchLoading
  | FacilitySearchLoaded
  | BookingSaveStarted
  | BookingSaveCompleted
  | LoadingStarted
  | LoadingCompleted
  | PublicLoading 
  | PublicLoaded 
  | AddRequestCount
  | SetFacilitySearchData;
