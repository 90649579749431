export class Configuration {
    id: number;
    name: string;
    configurationThumbnail: string;
    type: string;
    configurationCategoryId: number;
    capacity: number;
    default: boolean;
    setupTime: number;
    teardownTime: number;
    internetDefault: boolean;
}

