import { Component, ChangeDetectorRef, Renderer2, OnInit } from '@angular/core';
import { Observable, forkJoin, from, Subject } from "rxjs";
import { Store, ICart, AddFacility, PackageSearch, SetContact } from 'src/modules/store/index';
import { ILoadingStatus } from 'src/modules/store/loading/status-store'
import { ScriptService } from 'src/modules/services/script.service';
import { SystemService } from '../services/system.service';
import { StoreCustomFunctions } from '../store/actions';
import { ApplicationIds } from '../models/common/application-ids.enum';
import { BaseComponent } from "../shared/base.component";
import { SetLanguages } from '../store/public-web/select-language/language-action';
import { getSelectedLanguage } from '../store/public-web/select-language/language-selector';
import { PublicBookingService } from '../services/public-web/public-booking.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomerService } from '../services/customer.service';
import { environment } from 'src/environments/environment';
import { AppSetting } from '../models/settings/casual-portal/app-setting';
import { SystemOption } from '../models/public-web/SystemOption';
import { IPublicWebSession } from '../store/public-web/public-web-reducers';
import * as PBSelector from '../store/public-web/public-web-selectors';
import moment from 'moment';
import { AddBookingSession, ResetPublicWebState } from '../store/public-web/public-web-actions';
import { ResetBlock, ResetSeat } from 'src/modules/store/booking-application/configuration.action';
import { selectSelectedBlocks, selectSelectedSeats } from 'src/modules/store/booking-application/configuration.selector';
import { SESSION_CONFIRMATION_M_TYPE } from '../models/public-web/enum';
import { ConfirmationComponent } from '../public-web/components/modals/confirmation/confirmation.component';
import { MatDialog, MatDialogState } from '@angular/material/dialog';
import { EventDate } from '../public-web/booking-application/models/BookingEvent';
import * as fromConfiguration from "../store/booking-application/configuration.selector";
import { SignalRService } from '../public-web/booking-application/services/signal-r.service';
@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})

export class MainComponent
    extends BaseComponent
    implements OnInit {
    bookingSessionKey: NodeJS.Timeout;
    sessionObs$: Observable<IPublicWebSession>;
    booking: any;
    booking$: any;
    eventDate: EventDate;
    loader$: Observable<ILoadingStatus>;
    languages: any[];
    isLoggedIn: boolean;
    appSetting: AppSetting;
    bookingSession: SystemOption;
    constructor(
        private store: Store<any>,
        private cd: ChangeDetectorRef,
        private renderer: Renderer2,
        private scriptService: ScriptService,
        private systemService: SystemService,
        private bookingService: PublicBookingService,
        private translate: TranslateService,
        private customerService: CustomerService,
        public dialog: MatDialog,
        private signalR: SignalRService
    ) {
        super();

        this.appSetting = environment.AppSetting as AppSetting;
        this.sessionObs$ = this.store.select(PBSelector.selectSessionTimers);
        this.booking$ = this.store.select(PBSelector.selectBookingCartModel);
        if (this.appSetting && this.appSetting.EnableLanguageTranslation) {
            this.systemService.getLanguages().safeSubscribe(this,
                data => {
                    if (data != null) {
                        data.forEach(lang => {
                            lang.languageCode = lang.languageCode.toUpperCase();
                        })
                        this.store.dispatch(new SetLanguages(data));
                    }
                    this.languages = data;
                },
                error => {
                    this.bookingService.saveSelectedLanguage('EN').safeSubscribe(this, () => { this.translate.use('en'); });
                }
            );
        } else {
            this.bookingService.saveSelectedLanguage('EN').safeSubscribe(this, () => { this.translate.use('en'); });
        }

        /*    this.store.select('cart').safeSubscribe(this, state =>
            {
                this.isLoggedIn = (state.contact != null && state.contact.firstName != null && state.contact.firstName != "") ? true : false;
                if (!this.isLoggedIn && environment.LoggedInUser != '') {
                    console.log("session load from main component")
                    this.customerService.GetSession().safeSubscribe(this, (res) => {
                        if (res != null) {
                          this.store.dispatch(new SetContact(res, null, null, null, null, null));
                        }
                    });

                }
            });*/
        this.loader$ = this.store.select('loader');

        let _externalJsScripts: string[] = [];
        let _customJsScripts: string[] = [];
        let _applicationId = ApplicationIds.CasualPortal;
        systemService.GetUserDefinedFunctions(_applicationId.toString()).subscribe(userDefinedFunctions => {
            if (userDefinedFunctions && userDefinedFunctions.length > 0) {
                userDefinedFunctions.forEach(x => {
                    if (x.externalJSUrl) {
                        let _externalJsUrlList = x.externalJSUrl.split(',');
                        if (_externalJsUrlList && _externalJsUrlList.length > 0) {
                            _externalJsUrlList.forEach(_externalJsUrl => {
                                let externalScript = _externalJsScripts.find(y => y == _externalJsUrl);
                                if (!externalScript) {
                                    _externalJsScripts.push(_externalJsUrl);
                                }
                            });
                        }

                    }

                    if (x.inlineFunction) {
                        let customScript = _customJsScripts.find(y => y == x.inlineFunction);
                        if (!customScript) {
                            _customJsScripts.push(x.inlineFunction);
                        }
                    }
                });

                _externalJsScripts.forEach(externalScript => {
                    this.scriptService.loadExternalJsScript(this.renderer, externalScript);
                });
                _customJsScripts.forEach(customScript => {
                    this.scriptService.loadCustomJsScript(this.renderer, customScript);
                });

                //Add To Store

                store.dispatch(new StoreCustomFunctions(userDefinedFunctions));

            }
           // console.log(userDefinedFunctions);

        });

        // let scriptElement=this.scriptService.loadExternalJsScript(this.renderer, 'http://localhost/Optimo.PrivateWeb/CustomJS/test.js');

        //scriptElement.onload = () => {
        //    console.log('Test Script loaded');
        //    if (window['testJS'] && typeof (window['testJS']) == "function") {
        //        window['testJS'].call();
        //    }
        //}
        //scriptElement.onerror = () => {
        //    console.log('Could not load the Script!');
        //}
    }

    ngAfterViewInit() {
        this.cd.detectChanges();
        this.booking$.safeSubscribe(this, (data: any) => {
          this.booking = data.booking;
        });
    }

    ngOnInit() {
        if (this.appSetting.EnableLanguageTranslation) {
            this.store.select(getSelectedLanguage).safeSubscribe(this, storedLanguage => {
                if (storedLanguage == null || storedLanguage == '') {
                    if (this.languages != null) {
                        this.bookingService.saveSelectedLanguage(this.languages[0].languageCode).safeSubscribe(this, () => { this.translate.use(this.languages[0].languageCode.toLowerCase()); });
                    } else {
                        this.bookingService.saveSelectedLanguage('EN').safeSubscribe(this, () => { this.translate.use('en'); });
                    }
                }
                else {
                    this.bookingService.saveSelectedLanguage(storedLanguage).safeSubscribe(this, () => { this.translate.use(storedLanguage.toLowerCase()); });
                }
            });
        }

        this.sessionObs$.safeSubscribe(this, (config) => {
            if (config.bookingSession) {
              //This line is needed for reduce the session
              this.bookingSession = config.bookingSession;
              if (this.bookingSession && this.bookingSession.isSessionTimerStart && this.bookingSession.sessionKey && !this.bookingSessionKey) {
                this.startBookingSession();
              }
            }
          });

          this.store.select(fromConfiguration.selectEventDate).subscribe((eventDate) => {
            if (eventDate) {
              this.eventDate = eventDate;
            }
          });
    }

    startBookingSession() {
      let matDialogRef: any
      let dialog: any = null;
      let cancelServiceCalled = false;
      this.bookingSession.sessionEndDate = moment().add(this.bookingSession.displayValue, "minutes").format('LLLL');
      this.bookingSessionKey = setInterval(() => {
        //moment get today date and add 10 minutes
        const now = new Date().getTime();
        if (!this.bookingSession.timerPaused && this.bookingSession.isSessionTimerStart) {
          // Time calculations for days, hours, minutes and seconds
          var distance = this.bookingSession.sessionEndTime - now;
          this.bookingSession.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString();
          this.bookingSession.seconds = Math.floor((distance % (1000 * 60)) / 1000).toString();

          if (!this.bookingSession.popupShowed && this.bookingSession.minutes === "1" && +this.bookingSession.seconds <= 10 && !this.bookingSession.sessionExpended) {
            this.bookingSession.popupShowed = true;
            this.store.dispatch(new AddBookingSession(this.bookingSession));
            matDialogRef = this.popupConfirmation(SESSION_CONFIRMATION_M_TYPE.BOOKING_SESSION_RESET);
          }
          if (+this.bookingSession.minutes == 0 && +this.bookingSession.seconds == 0 && --distance < 0) {
            if (matDialogRef && matDialogRef.getState() === MatDialogState.OPEN) {
              matDialogRef.close();
            }
            let dialog = this.popupConfirmation(SESSION_CONFIRMATION_M_TYPE.SESSION_EXPIRATION_CONFIRMATION);
            this.ResetAll();
            this.unRegisterSeatsSignalR();
            this.unRegisterPrivateBoxSignalR();
            let sub = dialog.afterClosed().subscribe((e) => {
              this.GoToBase();
              sub.unsubscribe();
            });
          }
          if (--distance < 0 && this.bookingSessionKey) {
            this.onBookingSessionReset();
            if (matDialogRef && matDialogRef.getState() === MatDialogState.OPEN) {
              matDialogRef.close();
            }
            if (!dialog) {
              dialog = this.popupConfirmation(SESSION_CONFIRMATION_M_TYPE.SESSION_EXPIRATION_CONFIRMATION);
            }
            dialog?.afterClosed().subscribe((e) => {

              this.ResetAll(true);
            });
            this.unRegisterSeatsSignalR();
            this.unRegisterPrivateBoxSignalR();

            setTimeout(() => {
              dialog.close();
            }, 5000);
          }
        }
      }, 1000);
      }

      unRegisterPrivateBoxSignalR() {
        this.store
          .select(selectSelectedBlocks)
          .subscribe((blockDetails) => {
            if (blockDetails) {
              blockDetails.forEach(block => {
                const privateBoxSignalRID = this.eventDate?.id + '_' + block.configurationId + '_' + block.block.staticId + '_privateBox';
                this.signalR.deRegisterSeat(privateBoxSignalRID);
              });
            }
          });
      }

      unRegisterSeatsSignalR() {
        this.store
          .select(selectSelectedSeats)
          .subscribe((seatDetails) => {
            if (seatDetails) {
              seatDetails.forEach(pack => {
                const seatNumber = this.eventDate?.id + '_' + pack.facility.configuration.id + '_' + pack.block.staticId + '_' + pack.row.name + '_' + pack.seat.name;
                this.signalR.deRegisterSeat(seatNumber);
              });
            }
          });
      }

      onBookingSessionReset() {
        clearInterval(this.bookingSessionKey);
        this.bookingSession.minutes = "0";
        this.bookingSession.seconds = "0";
        this.bookingSession.isSessionExpired = true;
        this.bookingSession.sessionExpended = false;
        this.bookingSession.isSessionTimerStart = false;
        this.bookingSession.sessionKey = undefined;
        this.bookingSession.sessionStartDate = undefined;
        this.bookingSession.sessionEndTime = 0;
        this.bookingSession.timerPauseTime = null;
        this.bookingSession.timerPaused = false;
        this.bookingSession.popupShowed = false;
        this.store.dispatch(new AddBookingSession(this.bookingSession));
      }

      onPaymentSessionReset() {
        // clearInterval(this.paymentSessionKey);
        // this.paymentSession.minutes = "0";
        // this.paymentSession.seconds = "0";
        // this.paymentSession.isSessionExpired = true;
        // this.paymentSession.sessionExpended = false;
        // this.paymentSession.isSessionTimerStart = false;
        // this.paymentSession.sessionKey = undefined;
        // this.paymentSessionKey = undefined;
        // this.store.dispatch(new AddPaymentSession(this.paymentSession));
      }

      onGlobalSessionReset() {
        // clearInterval(this.globalSessionKey);
        // this.globalSession.minutes = "0";
        // this.globalSession.seconds = "0";
        // this.globalSession.isSessionExpired = true;
        // this.globalSession.sessionExpended = false;
        // this.globalSession.isSessionTimerStart = false;
        // this.globalSession.sessionKey = undefined;
        // this.globalSessionKey = undefined;
        // this.store.dispatch(new AddGlobalSession(this.globalSession));
      }

      popupConfirmation(confirmationType: SESSION_CONFIRMATION_M_TYPE) {
        return this.dialog.open(ConfirmationComponent, {
          data: {
            type: confirmationType,
          },
          panelClass: [
            "custom-dialog-container",
            "w50modal",
            "modal-width",
            "extrapop",
            "sessionpop",
          ],
          height: "auto",
          width: "50%",
        });
      }
      cancelBooking() {
        this.bookingService.cancelBooking(this.booking).safeSubscribe(
          this,
          (res) => {
            if (!(res.body && res.body.isError)) {
              this.ResetAll(true);
            }
          },
          (error) => {
            this.ResetAll(true);
          }
        );
      }

      ResetAll(navigate: boolean = false) {
        this.onBookingSessionReset();
        this.onPaymentSessionReset();
        this.store.dispatch(new ResetPublicWebState());
        this.store.dispatch(new ResetSeat());
        this.store.dispatch(new ResetBlock());
        if(navigate) {
          this.GoToBase()
        }
      }

      GoToBase() {
        window.location.href = environment.PublicPortalHomeUrl.trim() ? environment.PublicPortalHomeUrl : environment.PublicWebPortalUrl;
      }
      ngOnDestroy() {
        if (this.bookingSessionKey) {
          clearInterval(this.bookingSessionKey);
        }
      }
}
