import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BookingQuestion } from 'src/modules/models/regular-portal/booking/question/booking-question';

@Component({
    selector: 'opt-question',
    templateUrl: './question.component.html'   
})
export class QuestionComponent {
    @Input() dates: any[];
    @Input() questions: BookingQuestion[];
    @Input() isMandatory: boolean;
    @Input() questionNumber: number;
    @Input() answeredQuestionIds: string[] ;
    @Output() questionAnswerOutput = new EventEmitter();
    @Output() dateSelectedOutput = new EventEmitter();

    OutputAnswer(event) {
        this.questionAnswerOutput.emit(event);
    }

    DateSelected(event) {
        this.dateSelectedOutput.emit(event);
    }
}





