import { Component, OnInit, Output, ViewChild, EventEmitter, ElementRef, Input, Renderer2 } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { BehaviorSubject, Subject, Observable } from 'rxjs'
import { Router, ActivatedRoute, Params } from '@angular/router';
//MatComponent
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";

import { Store, IRegularBooking, LoadBooking, SpinnerLoaded, SpinnerLoading } from 'src/modules/store/regular-booking/index';
//model
import { TeamsGroup } from 'src/modules/models/regular-portal/add-teams';
import { Booking, Contact, BookingPackage, BookingStatus, PriceConcession } from 'src/modules/models/regular-portal/booking/save-booking/booking';
import { Package } from 'src/modules/models/booking/package';
import { BespokePackage } from 'src/modules/models/asset/bespoke-package';

import { BookingPackage as Bpackage } from 'src/modules/models/regular-portal/booking/booking-package';

import { BookingService } from 'src/modules/services/booking.service';
import { RegualrBookingService } from 'src/modules/services/regular-booking.service';
import { PackageService } from 'src/modules/services/package.service';

import { BookingCommonComponent } from 'src/modules/regular-booking/booking-common.component';
import { environment } from 'src/environments/environment';
import { RegularBookingSettings, BookingTypes } from 'src/modules/models/settings/regular-portal/regular-booking-setting';
import { WpConfirmationMessageComponent } from 'src/modules/regular-booking/messages/wp-confirmation-message/wp-confirmation-message.component';

//helper classes
import { Utility } from 'src/modules/utility';
import { CalendarUtil, DAYS_OF_WEEK } from 'src/modules/regular-booking/wp-calendar/calendar/calendar-util';
import { RegularBookingAdapter } from 'src/modules/models/regular-portal/regular-booking-adapter';
///Date
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { MomentDateAdapter } from 'src/modules/booking-portal/common/date-time-adapter/moment-date-adapter';
import { WpRbMainLayoutComponent } from 'src/modules/regular-booking/pages/wp-rb-main-layout/wp-rb-main-layout.component';

//model

import { AssetService } from 'src/modules/services/asset.service';
const moment = _rollupMoment || _moment;

@Component({
    selector: 'opt-wp-add-teams',
    templateUrl: './wp-add-teams.component.html',
    styleUrls: ['./wp-add-teams.component.scss']
})
export class WpAddTeamsComponent extends BookingCommonComponent implements OnInit {
    @Input() inputBookingPackage: Bpackage[];
    @Input() inputSeasonId: number;
    @Input() inputBookingTypeId: number;
    @Input() isRollover: boolean;
    @Output() bookingSaveResult = new EventEmitter<any>();
   // @Output() emitAddTeamsSuccessMesage = new EventEmitter();
    bookingData: RegularBookingSettings;

    teamGroups: TeamsGroup[] = [];
    bespokePackages: BespokePackage[];
    incrementNumber: number = 0;
    allSelectedActivity:String[]=[];
    messageDetail: string;
    showErrorMessage: boolean = false;

    //caputure save event
    $bookingSave = new EventEmitter();
    $selectBooking = new EventEmitter();
    $deleteBooking = new EventEmitter();
    teamsBookingType: BookingTypes;
    rolloverTeams: TeamsGroup[] = [];

    constructor(public _packageService: PackageService, private _bookingService: BookingService, private _regualrbookingService: RegualrBookingService, _store: Store<IRegularBooking>, private _route: ActivatedRoute
        , private dialog: MatDialog, public _assetService: AssetService ,private vps: ViewportScroller
) {
        super(_store, _route, _regualrbookingService, _assetService, _packageService);

        this.bookingData = (environment.RegularBookingSetting as RegularBookingSettings);

    }


    ngOnInit() {

        super.ngOnInit();
        ////////////////getting BookingTypeId from Regular XML/////////////////////
        const regularSetting = environment.RegularBookingSetting as RegularBookingSettings;
        if (regularSetting && this.inputBookingTypeId) {
            let _findBookingType = regularSetting.booking.bookingTypes.find(type => type.id == String(this.inputBookingTypeId));
            if (_findBookingType) {
                this.teamsBookingType = _findBookingType;
            };
        }
        /////////////////////////////////////////////

            this.setDefaultBookingPackage();


        this.$bookingSave.safeSubscribe(this, (res) => {
            if (res)
                this.handleSaveSuccessFailure(res);
        });



        this.$selectBooking.safeSubscribe(this, (res) => {
            if (res) {
                this.bookingSaveResult.emit(res);
            }
        })



        this.$deleteBooking.safeSubscribe(this, (res) => {
            if (res) {
                //  this.bookingSaveResult.emit(res);
            }
        })


    };



    handleSaveSuccessFailure(response) {
        if (response && response.body && !response.body.isError && response.body.data) {
            if (response.body.data.id) {
                this.LoadBooking(response.body.data.id, this.$selectBooking);
                //this.emitAddTeamsSuccessMesage.emit(true)
            }
        } else {
            //handle booking save error
            this.showErrorMessage = true;
            this._store.dispatch(new SpinnerLoaded());
            this.messageDetail = response.body.errors[0].detail;
        }
    }
    setDefaultBookingPackage() {
        //check in the store
        this.teamGroups = [];
        var _storeBookingPackages = null;
            if (this.booking != null){
                this.bookingId = this.booking.id;

        };
        _storeBookingPackages = this.inputBookingPackage;
        //if (this.booking == undefined) {
        //    _storeBookingPackages = this.inputBookingPackage;
        //}

    //    this.bookingType = this.bookingType;
    //    this.seasonId = this.seasonId;

        if (_storeBookingPackages != undefined) {
            //If InputBookingPackage id is minus

            var _findMinusBookingPackage = _storeBookingPackages.find(bk => Number(bk.id) < 0);
                if (_findMinusBookingPackage)
                this.incrementNumber = 0 -_storeBookingPackages.length;

            _storeBookingPackages.forEach((bk, i) => {
                    var teamGroup = new TeamsGroup();
                    teamGroup.id = bk.id;
                    teamGroup.activityGroup = bk.name;
                    teamGroup.participants = bk.attendees;
                    teamGroup.packageId = bk.package.id;
                    this.teamGroups.push(teamGroup);
                });
            }

        if (this.teamsBookingType != undefined) {
            var _findSeason = this.teamsBookingType.seasons.find(s => s.id == this.inputSeasonId.toString());
            var startDate = new Date(_findSeason.from).toISOString();
            var endDate = new Date(_findSeason.to).toISOString();




        //get
            this._packageService.privatePackages(this.teamsBookingType.id, startDate, endDate ).safeSubscribe(this ,data => {
                this.bespokePackages = data;

           // this.bespokePackage = data;
                if (_storeBookingPackages == undefined && this.teamGroups.length == 0) {
                    this.showErrorMessage = false;
                var teamGroup = new TeamsGroup();
                var curentIncrment = this.incrementNumber - 1;
                this.incrementNumber = curentIncrment;
                teamGroup.packageId = this.bespokePackages[0].id
                teamGroup.id = curentIncrment.toString();
                teamGroup.participants = null;
                teamGroup.packages = this.bespokePackages;


                this.teamGroups.push(teamGroup);
                }


                this.teamGroups.forEach((team, ind) => {

                    if (this.isRollover) {
                        var isValidTeam = this.bespokePackages.find(bspkg => bspkg.id == team.packageId);
                        if (!isValidTeam) {
                            team.disable = false;
                        }
                    } else {
                        if (Number(team.id) < 0) {
                            team.disable = false;
                        }

                    }

                    });
                if (this.inputBookingPackage) {
                    if (this.inputBookingPackage.length > 0) {
                        this.GetActivityTypes();
                    }}



        });
        }
    }

  
    GetActivityTypes() {

        this.inputBookingPackage.forEach((_package, index) => {

            var _team = this.teamGroups.find(tm => tm.id == _package.id);
            if (_team) {
                _team.packages = [] as BespokePackage[];
                var _besPackg = this.bespokePackages.find(bpkg => bpkg.id == _package.package.id);

                if (!_besPackg) {
                    this.bespokePackages.forEach((bp, indx) => {

                        const selectedIds = _package.salesCategories.map(({ id }) => id);
                        var _tempArray1 = (selectedIds.sort()).join();
                        var _tempArray2 = (bp.salesCategories.sort()).join();

                        if (_tempArray1 == _tempArray2) {
                            _team.packages.push(bp);
                        }
                    });


                }
                else {

                    _team.packages = this.bespokePackages;
                }
            }


        })


    }

    removeForm(teamGroup: TeamsGroup, i: number) {

        let displayMessage;
        if(teamGroup.activityGroup){
            displayMessage = `Are you sure you want to delete activity ${teamGroup.activityGroup}`;
        }else{
            displayMessage = `Are you sure you want to delete activity`;
        }


        if (this.booking && this.booking.bookingPackages && Number(teamGroup.id) > 0) {

            var findpackage = this.booking.bookingPackages.find(bkp => bkp.id == teamGroup.id);
            if (findpackage && findpackage.bookingVenues.length > 0) {
                displayMessage = `There are time slots booked for ${teamGroup.activityGroup}, Do you wish to proceed`;
            };
        };

        const dialogRef = this.dialog.open(WpConfirmationMessageComponent, {
            panelClass: ['message--confirmation', 'opt-regular-booking'],
            data: { displayHeaderMessage: 'Delete Activity from Booking', displayMessage: displayMessage },
            height: 'auto',
            width: '500px'
        });

        dialogRef.afterClosed().safeSubscribe(this,result => {
            if (result.confirm == 'yes') {


                if (Number(teamGroup.id) > 0) {
                    let findBookingPackage = this.teamGroups.find(d => d.id == teamGroup.id);
                    var deleteObject = new Booking();
                    deleteObject.id = this.bookingId;
                    deleteObject.bookingPackages = [];
                    let bookingPackage = new BookingPackage();
                    bookingPackage.id = findBookingPackage.id;
                    deleteObject.bookingPackages.push(bookingPackage);

                    if (this._regualrbookingService != null) {
                        this._store.dispatch(new SpinnerLoading());
                        this._regualrbookingService.DeleteBookingPackage(this.bookingId, bookingPackage.id).safeSubscribe(this, response => {
                            if (response != undefined ){
                            if (response.body.isError == false) {
                                this.showErrorMessage = false;
                                this.LoadBooking(this.bookingId, this.$deleteBooking)
                                let index = this.teamGroups.findIndex(d => d == teamGroup);
                                this.teamGroups.splice(index, 1);
                            } else {
                                this._store.dispatch(new SpinnerLoaded());
                                this.messageDetail = response.body["Errors"][0].errorMessage;
                                this.showErrorMessage = true;
                                window.scrollTo(0, 0);
                                }
                            }
                            console.log(response);

                        });
                    }

                } else {
                    let index = this.teamGroups.findIndex(d => d == teamGroup);
                    this.teamGroups.splice(index, 1);
                    this.showErrorMessage = false;
                }

            }
        });

    }

    PackageChange(event, index) {


        var _findPackage = this.bespokePackages.find(d => d.id == event.value);
        var findGroupCount = this.teamGroups.filter(tm => tm.packageId == _findPackage.id);
        var clonedObj = Object.assign({}, _findPackage);
        this.teamGroups[index].packageId = clonedObj.id;
        this.allSelectedActivity[index]=clonedObj.name;
        //this.teamGroups[index].activityGroup = clonedObj.name + ' Group ' + (findGroupCount.length).toString();
    //    this.teamGroups[index].participants = clonedObj.attendees;
        this.teamGroups[index].participants = null;
        console.log(this.teamGroups);
    }

    //adding row
    clickAddMore() {
        this.incrementNumber = this.incrementNumber - 1;
        // this.incrementGroupPackage = this.incrementGroupPackage + 1;

        var teamGroup = new TeamsGroup();
        if (this.bespokePackages != null && this.bespokePackages.length > 0) {
            var findGroupCount = this.teamGroups.filter(tm => tm.packageId == this.bespokePackages[0].id);
            teamGroup.packageId = this.bespokePackages[0].id;
            //teamGroup.activityGroup = this.bespokePackages[0].name +' Group ' + (findGroupCount.length+1).toString();
           // teamGroup.participants = this.bespokePackages[0].attendees;
            teamGroup.participants = null;
        }
        teamGroup.id = this.incrementNumber.toString();

        var findDuplicateName = this.teamGroups.filter(tm => tm.activityGroup == teamGroup.activityGroup);
        if (findDuplicateName.length > 0) {
            var lastChar = teamGroup.activityGroup[teamGroup.activityGroup.length - 1];
           // teamGroup.activityGroup = this.bespokePackages[0].name + (Number() + 1).toString()
        }


            teamGroup.disable = false;
        teamGroup.packages = this.bespokePackages;

        this.teamGroups.push(teamGroup);
        this.rolloverTeams.push(teamGroup);

        //emitRollover
     //   let _generateteamPackage = this.getGeneratedBookingPackage(this.bespokePackages, this.teamGroups, this.teamsBookingType);

    }


    //Bookiing Save
    BookingSave() {
        if (this.seasonId == undefined || this.seasonId ==0) {
            this.showErrorMessage = true;
            this.messageDetail = 'Please select a season';
            this.vps.scrollToAnchor('topElement');
            return false;
        };

        // if no package(Activity) selected return with error
        if(this.teamGroups.some(tg => !tg.packageId)){
            this.showErrorMessage = true;
            this.messageDetail = 'Please select activity type in your request(s) to proceed';
            this.vps.scrollToAnchor('topElement');
            return false;
        }

        var invalidParticipantsCount = this.teamGroups.find(tg => tg.participants > 9999);
        if (invalidParticipantsCount) {
            this.showErrorMessage = true;
            this.messageDetail = 'The maximum number of participants that can be entered is 9999 ';
            this.vps.scrollToAnchor('topElement');
            return false;
        }
        //this.validatePackageAndTeam();
        //this.validateTeamNameAndAttendees();

        var IsPackage = this.validatePackageAndTeam();
        var IsvalidTeam = this.validateTeamNameAndAttendees();
        if (IsPackage != false && IsvalidTeam != false) {

            const returnBookingPackage = this.getBookingFormat();
            //check changes
            //  var _changesArray = [];
            // var _incrementNumber = 0;
            if (returnBookingPackage) {
                if (returnBookingPackage.bookingPackages.length > 0) {
                    super.SaveBooking(returnBookingPackage, this.$bookingSave);
                }
            }
           else {
                this.bookingSaveResult.emit(true);
            }

        }

    }


    validateTeamNameAndAttendees() {

        var _emptyParticipant = this.teamGroups.filter(team => team.participants == 0);
        var _nullParticipant = this.teamGroups.filter(team => team.participants == null);

        var _emptyTeam = this.teamGroups.filter(team => team.activityGroup == undefined || team.activityGroup == "");
        var _nullTeam = this.teamGroups.filter(team => team.activityGroup == null);

        if ((_emptyTeam != undefined && _emptyTeam.length > 0) || (_nullTeam != undefined && _nullTeam.length > 0)) {
            this.showErrorMessage = true;
            this.messageDetail = 'The activity name cannot be empty, please enter the activity name for your activity';
            this.vps.scrollToAnchor('topElement');
            //if (this.teamGroups.length)
            // window.scrollTo(500, 0);
            return false;
        }

        else if ((_emptyParticipant != undefined && _emptyParticipant.length > 0) || (_nullParticipant != undefined && _nullParticipant.length > 0)) {
            this.showErrorMessage = true;
            this.messageDetail = 'The number of participants cannot be 0, please enter the number of participants in your activity group';
            this.vps.scrollToAnchor('topElement');
            //if (this.teamGroups.length)
            // window.scrollTo(500, 0);
            return false;
        };
    }

    validatePackageAndTeam() {
        if (this.teamGroups.length == 0) {
            this.showErrorMessage = true;

            this.messageDetail = 'Please enter at least one activity before proceeding';
            this.vps.scrollToAnchor('topElement');
            // this.myErrorText.nativeElement.focus();
            return false;
        }
    }

    getBookingFormat() {
        var _changesArray = [];
        this.teamGroups.forEach((team, index) => {
            if (this.booking && this.booking.bookingPackages)
                var _findpackage = this.booking.bookingPackages.find(pk => pk.id == team.id);

            if (_findpackage) {
                if ((_findpackage.name != team.activityGroup) || _findpackage.attendees != team.participants) {

                    _changesArray.push(team);

                }
            } else {
                _changesArray.push(team);
            }

        });

        if (_changesArray.length > 0) {
            var findBookingSeason = this.bookingData.booking.bookingTypes.find(s => s.id == this.teamsBookingType.id.toString());

            var pathBooking = new Booking();

            if (this.bookingId != undefined) {
                pathBooking.id = this.bookingId;
            }
            else {
                pathBooking.id = '-1';
                pathBooking.salesChannelId = 5;
                pathBooking.bookingStatus = new BookingStatus();
                pathBooking.bookingStatus.id = findBookingSeason.bookingWorkFlow.draftBookingStatusId;
                pathBooking.seasonId = (this.seasonId == undefined) ? this.inputSeasonId : Number(this.seasonId);
                pathBooking.bookingTypeId = Number(this.teamsBookingType.id);
                pathBooking.temporary = false;
                pathBooking.eventName = _changesArray[0].activityGroup;

                if (this.teamsBookingType != undefined || this.teamsBookingType != null) {
                    var _regularXmlData = (environment.RegularBookingSetting as RegularBookingSettings);
                    let _pcon;
                    //customer Price Concession defined
                    if (this.clientPriceConcessionId != undefined ) {
                        _pcon= this.clientPriceConcessionId.toString();
                    } else {
                        _pcon = _regularXmlData.booking.bookingTypes.find(d => d.id == this.teamsBookingType.id).defaultPriceConcessionId;;
                    }
                    if(_pcon!=undefined && Number(_pcon)>0){
                        pathBooking.priceConcession = new PriceConcession();
                        pathBooking.priceConcession.id=_pcon;
                    }

                }
            }
            pathBooking.bookingPackages = [];
            _changesArray.forEach((team: TeamsGroup, index) => {

                let bpkge = new BookingPackage();
                bpkge.id = team.id;
                //bpkge.name = team.activityGroup;
                var packageDetails = this.bespokePackages.find(bp => bp.id == team.packageId)
                if (packageDetails) {
                    bpkge.name = packageDetails.name + " - " + team.activityGroup;
                }

                bpkge.attendees = Number(team.participants);


                if (team.id.startsWith('-')) {
                    bpkge.quantity = 1;
                    if (packageDetails) {
                        bpkge.package = new Package()
                        bpkge.package.id = team.packageId;
                    }

                    var findSeason = this.teamsBookingType.seasons.find(s => s.id == String(this.inputSeasonId));
                    bpkge.startDate = RegularBookingAdapter.getStartDateEndDate(findSeason.from);
                    bpkge.endDate = Utility.convertToISO(Utility.appendTimePart(moment(findSeason.to).toDate(), moment(findSeason.to).toDate(), true));
                }
                pathBooking.bookingPackages.push(bpkge);
            });




        }
        return pathBooking;
    }

    applyNumberFilter(event){
       if(event.key=="e"||event.key=="E"||event.key=="+"||event.key=="-"){
        event.preventDefault();
       }
    }
   
}






