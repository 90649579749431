import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'opt-wp-message',
  templateUrl: './wp-message.component.html',
  styleUrls: ['./wp-message.component.scss']
})
export class WpMessageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
