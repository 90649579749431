import { Component, Inject, Input, Output, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Utility } from 'src/modules/utility'

import { BookingQuestion, MultipleChoiceQuestionAnswer } from 'src/modules/models/regular-portal/booking/question/booking-question';
//import { Question, MultipleChoiceQuestion } from 'src/modules/models/regular-portal/booking/question/question';
//import { Question as Questions} from 'src/modules/models/regular-portal/booking/question/question-patch/question';

import { AnswerChoice } from 'src/modules/models/regular-portal/booking/question/answer';
import { BookingAnswerChoice } from 'src/modules/models/regular-portal/booking/question/booking-answer-choice';
import * as _ from 'lodash';

@Component({
  selector: 'opt-multiple-choice-question',
  templateUrl: './multiple-choice-question.component.html',
})
export class MultipleChoiceQuestionComponent implements OnInit {
  @Input() dates: any[];
  @Input() bookingQues: MultipleChoiceQuestionAnswer;
  @Output() QuestionAnswerOutput = new EventEmitter();
  @Output() DateSelectedOutput = new EventEmitter();
  displayArrayData: any[] = [];
  SelectedanswerChoices: BookingAnswerChoice[] = [];
  @Input() answeredQuestionIds: string[];
  @Input() questionNumber: number;


  constructor() {
  }
  ngOnInit() {
    this.displayArrayData.push(this.dates)
    var thisComponent = this;
    let isAnswered = false;
    if (thisComponent.bookingQues.bookingAnswerChoice != undefined && this.bookingQues.bookingAnswerChoice.length > 0) {
      thisComponent.bookingQues.bookingAnswerChoice.forEach(function (bac) {
        thisComponent.bookingQues.bookingQuestionChoices.forEach(function (bqc) {
          if (bac.name == bqc.name) {
            bqc.isSelectedAnswer = true;
            if (bac.quantity)
              bqc.quantity = bac.quantity.toString();
            isAnswered = true;
          }
        });
      });
    }

    //if (this.dates != undefined && this.dates.length > 0 && isAnswered) {
    //    this.dates.forEach(function (dt, i) {
    //        thisComponent.addOrRemoveQuestionID(dt.actualQuestionTobePatch.id, true);
    //    });
    //}
    //else
    if (isAnswered) {
      thisComponent.addOrRemoveQuestionID(this.bookingQues.id, true);
      this.bookingQues.conditionalBookingQuestions =
        this.checkConditionalQuestions(this.bookingQues.conditionalBookingQuestions)
    }
    if (!(this.dates != undefined && this.dates.length > 0))
      this.QuestionAnswerOutput.emit(this.bookingQues);
  }
  checkConditionalQuestions(conditionalQues: BookingQuestion[]) {
    if (conditionalQues) {
      conditionalQues.forEach(bq => {
        const filterd = this.bookingQues.bookingAnswerChoice.filter(a => bq.bookingConditionalQuestionAnswers.findIndex(x => x.answerChoiceId === a.bookingQuestionChoice.id) !== -1)
        if (filterd.length > 0) {
          bq.canDisplayQuestion = true;
        } else {
          bq.canDisplayQuestion = false;
        }
      })
    }
    return conditionalQues
  }
  selectedAnswers(val, index) {
  }

  trackByFn(index, item) {
    return index;
  };

  checkValue(event, qst) {
    let selecting = event;

    if (selecting.quantity > 0 && qst.quantityRequired) {
      selecting.isSelectedAnswer = true;
      return true
    }

    //if (this.bookingQues.bookingAnswerChoice != undefined) {
    //    let selectedValue = this.bookingQues.bookingAnswerChoice.find(q => q.answerChoice.id == selecting.id);
    //    if (selectedValue) {
    //        return true;
    //    }
    //}
    return false;
  }

  RecveselectedDates(event) {
    this.displayArrayData.push(event);
    this.DateSelectedOutput.emit(event);
  }

  selectionChanged(val, ansChoice, qst) {
    if (val.checked == false && qst.quantityRequired) {
      ansChoice.quantity = '';
    } else if (val.checked == true && qst.quantityRequired) {
      ansChoice.quantity = 1;
    } else if (val.srcElement && val.srcElement.checked && ansChoice.quantity == '' && qst.quantityRequired) {
      ansChoice.quantity = 1;
    } else if (val.srcElement && val.srcElement.checked && !ansChoice.quantity && qst.quantityRequired) {
      ansChoice.quantity = 1;
    }
    var thisComponent = this;
    if (this.dates != undefined && this.dates.length > 0) {
      this.dates.forEach(function (dt, i) {
        dt.actualQuestionTobePatch.bookingAnswerChoice = [];
        dt.actualQuestionTobePatch.bookingQuestionChoices.forEach(function (bqc, i) {
          thisComponent.bookingQues.bookingQuestionChoices.forEach(function (qtbdbcq) {
            if (qtbdbcq.isSelectedAnswer && bqc.name == qtbdbcq.name) {
              let bookingAnswerChoice = new BookingAnswerChoice();
              bookingAnswerChoice.id = (+(bqc.id) * -1).toString();
              bookingAnswerChoice.type = "BookingAnswerChoice";
              bookingAnswerChoice.name = bqc.name;
              bookingAnswerChoice.quantity = +qtbdbcq.quantity;
              bookingAnswerChoice.bookingQuestionChoice = _.cloneDeep(bqc);
              dt.actualQuestionTobePatch.bookingAnswerChoice.push(bookingAnswerChoice);
            }
          });
        });
        //  thisComponent.addOrRemoveQuestionID(dt.actualQuestionTobePatch.id, dt.actualQuestionTobePatch.bookingAnswerChoice.length > 0);
      });
    }

    thisComponent.bookingQues.bookingAnswerChoice = [];
    thisComponent.bookingQues.answeredTime = undefined;
    thisComponent.bookingQues.bookingQuestionChoices.forEach(function (qtbdbcq) {
      if (qtbdbcq.isSelectedAnswer) {
        let bookingAnswerChoice = new BookingAnswerChoice();
        bookingAnswerChoice.id = (+(qtbdbcq.id) * -1).toString();
        bookingAnswerChoice.type = "BookingAnswerChoice";
        bookingAnswerChoice.quantity = +qtbdbcq.quantity;
        bookingAnswerChoice.name = qtbdbcq.name;
        bookingAnswerChoice.bookingQuestionChoice = _.cloneDeep(qtbdbcq);
        thisComponent.bookingQues.bookingAnswerChoice.push(bookingAnswerChoice);
        thisComponent.bookingQues.answeredTime = new (Date);

      }
    });
    // if (!(this.dates != undefined && this.dates.length > 0)) {
    thisComponent.addOrRemoveQuestionID(this.bookingQues.id, thisComponent.bookingQues.bookingAnswerChoice.length > 0);
    this.bookingQues.conditionalBookingQuestions =
      this.checkConditionalQuestions(this.bookingQues.conditionalBookingQuestions)
    // }
    this.bookingQues.answeredTime = new (Date);
    if (!(this.dates != undefined && this.dates.length > 0))
      this.QuestionAnswerOutput.emit(this.bookingQues);
  }
  conditionalQuestionAnswerChange(val) {
    if (this.bookingQues.conditionalBookingQuestions && this.bookingQues.conditionalBookingQuestions.length > 0) {
      const i = this.bookingQues.conditionalBookingQuestions.findIndex(a => a.id == val.id && a.question.id === val.question.id);
      if (i !== -1)
        this.bookingQues.conditionalBookingQuestions[i] = val;
      this.bookingQues.answeredTime = new (Date);
      this.QuestionAnswerOutput.emit(this.bookingQues);
    }
  }
  addOrRemoveQuestionID(bookingId: string, isAnswered: boolean) {
    let index = this.answeredQuestionIds.findIndex(x => x == bookingId);
    if (index >= 0) {
      if (!isAnswered) {
        this.answeredQuestionIds.splice(index, 1)
      }
    } else {
      if (isAnswered) {
        this.answeredQuestionIds.push(bookingId);
      }
    }
  }
}
