import { BaseResource } from '../../../base-resource';
import { Asset } from 'src/modules/models/booking/Asset';
import { EventConfiguration } from 'src/modules/models/booking/event-configuration';


export class Timeslot extends BaseResource {
    constructor() {
        super();
        this.type = "Timeslot";
    }
    asset: Asset;
    endTime: string;
    startTime: string;
    eventConfiguration: EventConfiguration;
}