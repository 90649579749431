import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { BehaviorSubject } from 'rxjs';

export enum AccessRights {
  AllowToCreateANewWasteBooking = 3675,
  AllowToEditWasteBooking = 3676,
  AllowToCancelAndRefundWasteBooking = 3677,
  AllowToExtendFreeWasteEligibilityByWaivingOffTheFees = 3678,
  AllowToAccessWastePortal = 3679,
  AllowToResetTheWasteCollectionFees = 3680,
  AllowToToAccessCoorporateBooking = 3689
}

@Injectable({
  providedIn: 'root'
})
export class WmAccessRightService {

  public allAccessRights = new BehaviorSubject<any>(null);

  public allowToCreateNewBooking$ = new BehaviorSubject<boolean>(null);
  public allowToEditBooking$ = new BehaviorSubject<boolean>(null);
  public allowToCancelAndRefundBooking$ = new BehaviorSubject<boolean>(null);
  public allowToExtendFreeByWaivingOffTheFees = new BehaviorSubject<boolean>(null);
  public allowToResetWasteCollectionFees = new BehaviorSubject<boolean>(null);
  public allowToAccessWastePortal$ = new BehaviorSubject<boolean>(null);
  public allowToAccessCoorporateBooking$ = new BehaviorSubject<boolean>(null);

  constructor() {
    this.allAccessRights.subscribe(list => {
      if (list && list.length > 0) {
        this.allowToAccessWastePortal$.next(false);
        list.forEach(element => {
          switch (element.accessRightNo) {
            case AccessRights.AllowToCreateANewWasteBooking:
              this.allowToCreateNewBooking$.next(true);
              break;
            case AccessRights.AllowToEditWasteBooking:
              this.allowToEditBooking$.next(true);
              break;
            case AccessRights.AllowToCancelAndRefundWasteBooking:
              this.allowToCancelAndRefundBooking$.next(true);
              break;
            case AccessRights.AllowToExtendFreeWasteEligibilityByWaivingOffTheFees:
              this.allowToExtendFreeByWaivingOffTheFees.next(true);
              break;
            case AccessRights.AllowToAccessWastePortal:
              this.allowToAccessWastePortal$.next(true);
              break;
            case AccessRights.AllowToResetTheWasteCollectionFees:
              this.allowToResetWasteCollectionFees.next(true);
              break;
            case AccessRights.AllowToToAccessCoorporateBooking:
              this.allowToAccessCoorporateBooking$.next(true);
              break;
            default:
          }
        });
      }
    });
  }
}




