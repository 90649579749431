
<div class="wp-modal-wrapper wp-message-modal">
    <div class="mmc-modal-body">
        <div class="mmc_flex--container pw-fd-col wp-modal-title">
            <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-h4 pw-text-semi-bold pw-text-color__primary">Request access to create reqular bookings online</div>
            <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-s1 pw-text-color__tertiary">You already have access to online regular booking portal.</div>
            <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-s1 link-primary">Click here to start booking online</div>
        </div>     
    </div>
</div>


