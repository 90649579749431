export enum WMBookingType {
  CANCEL_BOOKING = 'CANCEL_BOOKING',
  NEW_BOOKING = 'NEW_BOOKING',
  UPDATE_BOOKING = 'UPDATE_BOOKING',
  UPDATE_BOOKING_WITH_PAYMENT = 'UPDATE_BOOKING_WITH_PAYMENT',
  UPDATE_BOOKING_WITH_REFUND = 'UPDATE_BOOKING_WITH_REFUND',
}

export enum WMFormType {
  NEW = 'NEW',
  EDIT = 'EDIT'
}

export enum WMBookingStatusType {
  PENDING = 'Pending',
  CONFIRMED = 'Confirmed',
  ASSIGNED = 'Assigned',
  COLLECTED = 'Collected',
  REQUESTRESCHEDULE = 'Requestreschedule',
  RESCHEDULE = 'Reschedule',
  CANCELLED = 'Cancelled',
  NOTPRESENT = 'Notpresent',
  SCHEDULED = 'Scheduled',
}

export enum WMBookingStatusTypeId {
  ALL = '0',
  PENDING = '1',
  Scheduled = '2',
  COLLECTED = '3',
  REQUESTRESCHEDULE = '4',
  REJECTED = '5',
  NOT_PRESENT = '6',
  CANCELLED = '32',
  RESCHEDULE = '101',
}

export enum WMBookingStatusFontColor {
  PENDING = '#f58616',
  CONFIRMED = '#f58616',
  ASSIGNED = '#4975bd',
  COLLECTED = '#35bd08',
  REQUESTRESCHEDULE = '#a07445',
  RESCHEDULE = '#a07445',
  CANCELLED = '#ff0000',
  NOTPRESENT = '#a07445',
  SCHEDULED = '#a07445',
}

export enum WMCollectionType {
  CONFIRMED = 1,
  SCHEDULED = 2,
  COLLECTED = 3,
  REQUEST_RESCHEDULE = 4,
  REJECTED = 5,
  NOT_PRESENT = 6
}

export enum WMDateType {
  COLLECTION_DATE = '1',
  BOOKED_DATE = '2',
  BOOKING_ADDRESS = '3'
}

export enum WMCollectionStatusType {
  UPCOMING = 'UPCOMING',
  PREVIOUS = 'PREVIOUS'
}


export enum WMAlertMessagesText {

  PACKAGE_DATE_NOT_FOUND = 'Unable to book any more clean-up collections for the address you have selected for next 6 weeks.!',
  MAX_LIMIT_REACHED = 'You have fully used the clean-up collection entitlement for the date selected for this address, please select a different date from the calendar.',
  BOOKING_ERROR = 'Error occurred while confirming the booking .Try Again...!',

  BOOKING_CANCEL_ERROR = 'Error occurred while cancelling the booking',
  INVOICE_NOT_FOUND_ERROR = 'Invoice is not generated for this booking. Please contact support team',

  BOOKING_UPDATE_ERROR = 'Error occurred while updating the booking',

  PACKAGE_DATES_NOT_AVAILABLE = 'Service is not on the selected date. Please choose different date.',

  IMAGE_UPLOAD_FILE_LARGER_3MB_ERROR = 'You have attempted to upload a document larger than 3MB in size',
  // tslint:disable-next-line: max-line-length
  IMAGE_UPLOAD_INVALID_FILE_TYPE_ERROR = 'You have uploaded an invalid document type. Only files with the following extensions are allowed: .jpeg, .jpg, .png',

  INTERNAL_SERVER_ERROR = 'Internal server error occurred',
  CANCEL_CONFIRMATION_MESSAGE = 'Are you sure you need to cancel this booking ?',

  CLEAR_CART_MESSAGE = 'Do you want to clear the cart ?',

  PROCEED_WITH_NEW_BOOKING = 'Do you want to proceed with a new booking?',

  DELETE_PACKAGE_CANCEL_MESSAGE = 'If you wish to cancel this booking, please click on the \'Cancel Booking\'',

  PACKAGE_DELETE_CONFIRMATION = 'Do you want to remove this waste collection?',

  BOOKING_SESSION_ALERT = 'Selected collection date is invalid, Please remove the items and add again',

  FREE_QUOTA_FINISHED = 'Free quota is finished. reselect the collection or choose different date',

  // tslint:disable-next-line:max-line-length
  EXCEED_WEEKLY_BOOKING_LIMIT = 'You have fully used the clean-up collection entitlement for the date selected for this address, please select a different date from the calendar.',
  PAYMENT_CONFIRMATION= 'Payment already has been made for this booking, Do you want to continue?',

  COLLECTION_NOT_AVAILABLE = 'Unable to book selected clean-up collections, Please contact administrator'

}

export enum WMMatToolTipText {
  WM_CART_VIEW_MC_REPORT_TIP = 'Click to view the details'
}

export enum WMPipeTransformType {

  PKG_NOTE_COUNT = 'NOTE COUNT',
  PKG_SAME_BOOKING_DATE = 'BOOKING PKG CHECK SAME DATE',
  PKG_SAME_BOOKING_STATUS = 'BOOKING PKG CHECK SAME STATUS',
  CONCAT_ADDRESS = 'CONCAT_ADDRESS',
  STATUS_FONT_COLOR = 'STATUS_FONT_COLOR',
  EDIT_OR_VIEW = 'EDIT OR VIEW',
  SAME_DATE_OR_STATUS = 'SAME DATE OR STATUS',
  SHOW_RATING_STAR = 'SHOW RATING STAR',
  PKG_SAME_BOOKING_AMOUNT = 'BOOKING PKG CHECK SAME AMOUNT',
  PKG_SAME_LOCATION = 'BOOKING PKG CHECK SAME LOCATION',
  PKG_EDIT_DELETE_OPTION_STATUS = 'PKG_EDIT_DELETE_OPTION_STATUS',
  IS_RESOLVED = 'IS_RESOLVED',
  LATEST_REPORT_MISSED_NOTE = 'LATEST_REPORT_MISSED_NOTE',
  CONFIRM_BUTTON_TEXT = 'CONFIRM_BUTTON_TEXT',
  WASTE_TYPE = 'WASTE_TYPE',
}

export enum WMComponentsTitleText {
    ADDRESS_COMPONENT_TITLE = 'Book a clean-up or extra red lid bin collection',
  COLLECTION_COMPONENT_TITLE = 'Your clean-up booking status',
  CART_COMPONENT_TITLE = 'Add another clean-up',
  NEW_WASTE_TITLE = 'New clean-up or extra red lid bin collection',
  COLLECTION_TYPE = 'Clean-up type',
  STEPPER_ONE = 'Choose clean-up category',
  STEPPER_TWO = 'Collection details',
  STEPPER_THREE = 'Customer details',
  STEPPER_FOUR = 'Confirm booking',
  UPCOMING_BOOKING_EMPTY = 'There is no upcoming booking.',
  PREVIOUS_BOOKING_EMPTY = 'There is no previous booking.',
  BOOKING_SEARCH_EMPTY = 'There are no bookings matching your search criteria.'
}

export enum WMPaymentMethod {
  CARD_PAY = 'CARD PAYMENT',
  CASH_PAY = 'CASH PAYMENT',
  CASH_REFUND = 'CASH REFUND'
}

export enum WMPaymentType {
  CARD = 'card',
  CASH = 'cash',
  FREE = 'free'
}

// Address
export enum WMAddressValidationText {
  EMPTY_INPUT = 'Please type the address for clean-up collection',
  NOT_FOUND = ' This address is not found in the system.',
  // tslint:disable-next-line: max-line-length
  INVALID = 'This property is not entitled to a clean-up. Only residential properties (not businesses) can book a clean-up.',
  NOT_SELECT_ADVANCE_SEARCH = 'Please select the address for clean-up collection',
}

export enum WMUserRightsToolTips {
  CREATE_RIGHTS = 'You don\'t have permission to create new clean-up booking',
  CANCEL_RIGHTS = 'You don\'t have permission to Cancel and refund clean-up booking'
}

export enum WMEditOrViewType {
  PACKAGE = 'PACKAGE',
  BOOKING = 'BOOKING',
  DATE = 'DATE'
}

export enum WMModelTitle {
  ADD_CLIENT = 'New Customer',
  CLIENT_SEARCH = 'Client Search',
  REFUND_CLEANUP = 'Refund Clean-up Booking',
  CANCEL_WASTE_COLLECTION = 'Cancel Clean-up Booking',
  SELECT_PAYMENT_METHOD = 'Select Payment Method',
  SELECT_REFUND_METHOD = 'Select Refund Method',
  RATING = 'How was Clean-up service?',
  REPORT_MISSED_COLLECTION = 'Report missed collection',
  REFUND_NOTE = 'Refund Note',
  PAYMENT_NOTE = 'Payment',
  EDIT_CLIENT = 'Edit Customer',
}

export enum WmPaymentReceiptType {
  CARD_RECEIPT = 'creditCardReceipt',
  CASH_RECEIPT = 'cashReceipt',
  CASH_REFUND = 'cashRefund',
  CARD_REFUND = 'creditCardRefund'
}

export enum WmEmailType {
  NEW_BOOKING = 1,
  UPDATE_BOOKING = 2,
  CANCEL_FREE_BOOKING = 3,
  REPORT_MISSED_COLLECTION = 4,
  CANCEL_PAID_BOOKING = 5
}

export enum WMCustomerOptions {
   ADD_CUSTOMER = 'ADD CUSTOMER',
   EDIT_CUSTOMER = 'EDIT CUSTOMER',
}


export enum WMConfirmBookingButtonTextType {
  CONFIRM_BOOKING = 'Confirm Booking',
  SIGN_IN_AND_CONFIRM_BOOKING = 'Sign in and Confirm Booking',
  MAKE_PAYMENT = 'Make Payment',
  SIGN_IN_AND_MAKE_PAYMENT = 'Sign in and Make Payment',
  UPDATE_BOOKING = 'Update'
}

export enum WMCancelBookingFilterType {
  ALL,
  INCLUDE_CANCEL ,
  EXCLUDE_CANCEL
}

export enum WMCollectionWeek {
  WEEK_A = 'Week A',
  WEEK_B = 'Week B',
}