
<div *ngIf="configurations?.length > 0">
    <div *ngIf="displayMode==1">
        <div class="pw-txt-s3" *ngIf="configurations?.length == 1">
            <mat-label class="">Booking For {{configurations[0].name}}</mat-label>
        </div>
        <mat-form-field appearance="outline" floatLabel="always" class="pw-txt-s3" *ngIf="configurations?.length > 1 ">
            <mat-label class="">Booking For</mat-label>
            <mat-select class="" [(ngModel)]="selectedConfiguration.id">
                <mat-option *ngFor="let config of configurations" [value]="config.id" (change)="changeConfiguration($event)">{{config.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="mmc_flex--container fd-col-mob config-row" *ngIf="displayMode==2">
        <div class="mmc_flex__item p50-web">
            <div class="item-w" *ngIf="configurations?.length ==1">
                <div class="config-item">
                    <div class="cog-d-none config-img"><img *ngIf="configurations[0].configurationThumbnail" src="{{configurations[0].configurationThumbnail}}"  alt="{{configurations[0].name}}"/></div>
                    <div class="pw-txt-s2">{{configurations[0].name}}</div>
                </div>
            </div>
            <div class="pw-txt-b3 mb-3" *ngIf="configurations?.length > 1">Select Configuration</div>
            <mat-radio-group aria-label="Select an option">

                <div *ngIf="configurations?.length > 1">
                    <mat-radio-button class="item-w" *ngFor="let config of configurations" [checked]="selectedConfiguration.id ==config.id" [value]="config.id"
                                      (change)="changeConfiguration($event)">
                        <div class="config-item">
                            <div class="cog-d-none config-img"><img *ngIf="config.configurationThumbnail" src="{{config.configurationThumbnail}}"  alt="{{config.name}}" /></div>
                            <div class="pw-txt-s2">{{config.name}}</div>
                        </div>
                    </mat-radio-button>
                </div>

            </mat-radio-group>
        </div>
    </div>
</div>