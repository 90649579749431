import { BaseParam } from '../base-param';
import { environment } from 'src/environments/environment';

export class Assetsearchparams extends BaseParam {
    searchpharese: string;
    starttime: string;
    endtime: string;
    pagenumber: number;
    pagesize: number;
    filterattendens: string;
    filterfacilites: string;
    filtersuberbs: string;
    sort: string;
    fields: string;
    assetclass: number = 1;
    activeStatus: number = 1;
    salesChannelId: number = environment.SalesChannel;
    assetname: string = "";
    duration: number;
    timelable: string;
    considerOpeningHours: boolean;
    assetTypeId: string;
    url: string;
    SalesCategoryId: string;
    BookingTypeIds: number = 1;
    VenueIds: number;
    AssetTypeIds: number;
    AssetCategoryIds: number;
    showAvailableFacilities: boolean;
    venueTypeIds: string = "";

    geturlforsearch(showAvailableFacilities?: boolean) {

        this.url = "page.number=" + this.pagenumber + "&page.size=" + this.pagesize +
            (this.filterattendens != "0" && this.filterattendens != undefined && this.filterattendens != "" ? ("&assetSearchFilter.CapacityFrom=" + this.filterattendens) : "") +
            "&sort=" + this.sort +
          
            "&assetSearchFilter.assetClassId=" + this.assetclass +
            "&assetSearchFilter.activeStatus=" + this.activeStatus +
            "&assetSearchFilter.BookingTypeIds=" + this.BookingTypeIds +
          
            (this.assetname.length > 0 && this.assetname != "0" && this.assetname != "" ? ("&assetSearchFilter.AssetIds=" + this.assetname) : "") +

            (this.venueTypeIds.length > 0 && this.venueTypeIds != "0" && this.venueTypeIds != "" ? ("&assetSearchFilter.VenueId=" + this.venueTypeIds) : "") +
            "&assetSearchFilter.saleschannelId=" + this.salesChannelId +

            (this.assetTypeId != "0" && this.assetTypeId != undefined && this.assetTypeId != "" ? ("&assetSearchFilter.AssetTypeId=" + this.assetTypeId) : "") +
            ((this.SalesCategoryId && this.SalesCategoryId.length > 0) ? ("&assetSearchFilter.SalesCategoryId=" + this.SalesCategoryId) : "") +
            "&include=venue,Facility.Amenities,Facility.configurations" 


        if (environment.AppSetting &&
            environment.AppSetting["BookingConfig"] &&
            environment.AppSetting["BookingConfig"]["CheckAvailability"] &&
            environment.AppSetting["BookingConfig"]["CheckAvailability"]["DisplayPreliminarySlotAsUnavailble"] == true) {
            this.url = this.url + "&assetSearchFilter.ConsiderPreliminaryAsUnavailable=true";
        }
        
        this.url = this.url +
                "&assetSearchFilter.StartTime=" + this.starttime +
                "&assetSearchFilter.EndTime=" + this.endtime;

        if (showAvailableFacilities) {
            this.url = this.url +
                (this.timelable == "Any" ? ("&assetSearchFilter.Duration=" + this.duration) : "") +
                "&assetSearchFilter.ConsiderOpeningHours=" + this.considerOpeningHours;
        }
        else{
            this.url = this.url + "&assetSearchFilter.Duration=0";
        }

        if (this.searchpharese != undefined) {
            this.url = this.url + "&assetSearchFilter.SearchText=" + this.searchpharese;
        }

        if (this.VenueIds)
        {
            this.url = this.url + "&assetSearchFilter.VenueId=" + this.VenueIds;
        }

        if (this.AssetCategoryIds)
        {
            this.url = this.url + "&assetSearchFilter.AssetCategoryId=" + this.AssetCategoryIds ;
        }

        if (this.AssetTypeIds)
        {
            this.url = this.url + "&assetSearchFilter.AssetTypeId=" + this.AssetTypeIds;
        }

       
        return this.url;

    }
}
