export class Tree {
	root: TreeNode;
}
 
export class TreeNode {
    label: string;
    id: string;
    parentId: string;
    children: TreeNode[];
    selectable: boolean = false;
    data: object;
    //selectedItem: object = null;
}