<div class="edit-booking-modal">
  <!--<div style="max-height:80vh" class="mat-dialog-content">-->

    <div class="mmc-modal-header mmc_flex--container">
      <div class="mmc_flex__item--inherit mmc_flex__item--vcenter icon-cage mmc_just-content-center extra-icon">
          <i class="material-icons">delete</i>
      </div>
      <div class="mmc_flex__item mmc_flex__item--vcenter">
          <span class="section-title">Extras Deletion For</span>
          <span class="section-title-second">{{facilitySummary?.name}}</span>
      </div>
      <div class="mmc_flex__item--inherit mmc_flex__item--vcenter close-icon-cage mmc_just-content-center">
          <button mat-button [mat-dialog-close]="true" tabindex="0"><i class="material-icons" >close</i></button>
      </div>
  </div>



  <div class="extra-deletion-body">
    <div class="extra-deletion-body-header-wrapper">
      <div class="extra-deletion-body-image">
        <img class="extra-deletion-image" src="{{apiUrl}}{{upsellSummary.url}}"  alt="img" (error)="errorHandler($event)" >
      </div>
      <div class="extra-deletion-header-title-wrapper">
        <div class="extra-deletion-select-item-name">
          {{upsellSummary.name}}
        </div>
        <div class="extra-deletion-select-item-quantity">
          (X{{upsellSummary.qty}})
        </div>
      </div>
      <div class="extra-deletion-header-price" >
        <div class="pw-txt-s2" *ngIf="isPriceRange(upsellSummary) && upsellSummary.minPrice?.priceIncludingTax !=0 && upsellSummary.minPrice">{{upsellSummary.minPrice?.priceIncludingTax | CurrencyFormat}} - {{upsellSummary.maxPrice?.priceIncludingTax | CurrencyFormat}}</div>
        <div class="pw-txt-s2" *ngIf="!(isPriceRange(upsellSummary) && upsellSummary.minPrice?.priceIncludingTax !=0 && upsellSummary.minPrice)">{{upsellSummary.minPrice?.priceIncludingTax | CurrencyFormat}}</div>
      </div>
    </div>


      <div class="extra-deletion-content-radio-wrapper">
        <div class="deletion-radio-btn">
          <mat-radio-group [(ngModel)]="isAllSelected" >
            <mat-radio-button [value]="0">Delete the extra only from this occurrence</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="deletion-radio-btn">
          <mat-radio-group [(ngModel)]="isAllSelected" >
            <mat-radio-button [value]="1">Delete the extra from all {{totalCount}} occurrence</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

  </div>

  <div class="mmc-modal-footer mmc_flex--container deletion-allocation-footer">
      <div class="mmc_flex__item item-w-25 mmc_flex__item--vcenter mmc_just-content-center deletion-footer-wrap">
          <div class="btn__card main mmc_flex__item--vcenter check-availability--btn deletion-footer-btn" (click)="delete()" (keydown.enter)="delete()" >DELETE</div>
      </div>
  </div>
</div>
