import { LanguageAction, LanguageActionType } from './language-action'

export interface ILanguageReducer {
    selectedLanguage: any;
    languages: any[];
}

export let initialState: ILanguageReducer = {
    selectedLanguage: '',
    languages: []
};

export function LanguageReducer(state = initialState, action: LanguageAction) {
    switch (action.type) {
        case LanguageActionType.SET_SELECTED_LANGUAGE:
            return { ...state, selectedLanguage: action.payload };
        case LanguageActionType.SET_LANGUAGES:
            return { ...state, languages: action.payload };
        case LanguageActionType.RESET_LANGUAGES:
            return { ...state, languages: [] };
        default:
            return state;
    }
}
