export class PaymentConfiguration {
    public billName: string;
    public merchantId: string;
    public postUrl: string;
    public txnType: string;
    public returnUrl: string;
    public returnUrlText: string;
    public returnUrlTarget: string;
    public cancelUrl: string;
    public pageHeaderImageUrl: string;
    public pageFooterImage: string;
    public pageTitle: string;
    public store: string;
    public storeType: string;
    public currency: string;
    public displayCardholderName: string;
    public displayReceipt: string;
    public confirmation: string;
    public pageStyleUrl: string;
    public timestamp: string;
    public sha1Code: string;
    public amount: string;

    constructor() { }
} 