import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { WMModelTitle } from 'src/modules/models/booking/enum';
import { environment } from 'src/environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SetContact, Store } from "src/modules/store";
import { CustomerService } from 'src/modules/services/customer.service';
@Component({
  selector: 'opt-client-search',
  templateUrl: './client-search.component.html',
  styleUrls: ['./client-search.component.scss']
})
export class ClientSearchComponent implements OnInit {

  url: SafeResourceUrl;
  isInternalUser: boolean = environment.IsInternalUser;
  clientSearchUrl: string;
  constructor(public dialogRef: MatDialogRef<ClientSearchComponent>, private customerService: CustomerService, private sanitizer: DomSanitizer, private ngZone: NgZone,private store: Store<any>,
     @Inject(MAT_DIALOG_DATA) public data: any) {
    window['angularComponentRef'] = { component: this, zone: ngZone };
  }

  ngOnInit() {
    if (this.isInternalUser) {
      if (this.data.portalType == "5") {
        this.clientSearchUrl = environment.WasteBookingClientSearchUrl;
      } else if (this.data.portalType == "4") {
        this.clientSearchUrl = environment.PublicWebClientSearchUrl;
      } else {
        this.clientSearchUrl = environment.CBClientSearchUrl+'?returnUrl='+environment.ApiUrl;
      }

    }



    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.clientSearchUrl);
  }

  clientSearchAngularFunctionCalled(message) {
    this.dialogRef.close(message);
  }

  close() {
    this.dialogRef.close();
  }
  get WMModelTitle() {
    return WMModelTitle;
  }


}
