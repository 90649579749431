<div class="wp-modal-wrapper wp-message-modal">
    <div class="mmc-modal-body">

        <div class="mmc_flex--container inner">
            <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-b2">Are you sure you want to request cancellation of this booking?</div>
        </div>

        <div class="mmc_flex--container pw-fd-col wp-modal-title">
            <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-h4 pw-text-semi-bold pw-text-color__primary">Please state more information for your cancellation request</div>
            <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-s1 pw-text-color__tertiary">Example: Dates or location and reason</div>
        </div>
        <div class="mmc_flex--container inner">
            <mat-form-field class="example-full-width">
                <textarea matInput placeholder="" [(ngModel)]="requestString"></textarea>
            </mat-form-field>
        </div>

        <div class="btn-wrapper">  
            <button type="submit" role="button" class="mmc-btn mmc-btn-secondary--small main mmc_flex__item--vcenter cancelBooking-btn" mat-button (click)="confirm()" (keydown.enter)="confirm()" tabindex="0">YES</button>
            <button type="submit" role="button" class="mmc-btn mmc-btn-secondary--small main mmc_flex__item--vcenter cancelBooking-btn" mat-button (click)="dialogRef.close(false)" (keydown.enter)="dialogRef.close(false)" tabindex="0">NO</button>
        </div>
    </div>
</div>

<!--<div *ngIf="showLoader">
    <opt-mmc-loader></opt-mmc-loader>
</div>

<ng-template #bookingCancellSpinner>
    <div class="container" style="min-height:400px">
        <opt-mmc-loader></opt-mmc-loader>
    </div>
</ng-template>-->


