import { BaseResource } from '../base-resource';

export class PriceGroup extends BaseResource {
    constructor() {
        super();
        this.type = "PriceGroup";
        //this.id = "1";
    }
}

export class PriceConcession extends BaseResource {
    constructor() {
        super();
        this.type = "priceConcession";
        //this.id = "1";
    }
}