import { BaseResource } from 'src/modules/models/base-resource';
export class RecurrentBookingItem extends BaseResource {
    constructor() {
        super();
        this.type = "RecurrentBookingItem";
    }
    startDate: Date;
    endDate: Date;
    unitPriceIncludingTax: number = 0;
    unit: number = 0;    
    attendees: number;
    priceType: string;
    priceTypeId: number;
    isUnavailable: boolean;
    grossAmount: number;
    date: Date
    itemNumber: number;
    dayOfWeek: string;
    diaryNote: string;
}

export class SplitBookingItem extends RecurrentBookingItem {

}
export class AdHocBookingItem extends RecurrentBookingItem {

}