import { BaseResource } from '../base-resource';
import { EventSeat } from '../public-web/booking/eventSeatingConfiguration';
import { Timeslot } from './timeslot';

export class Allocations extends BaseResource {
    constructor() {
        super();
        this.timeslot = new Timeslot();
        this.seat = new EventSeat();
        this.type = "Allocation";
    }
    
    seat: EventSeat;
    timeslot: Timeslot;
    attendees: number;
}