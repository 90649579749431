import { Action } from '@ngrx/store';
import { IAddress, UserDataState } from './index';
import { WMBookingPackage } from '../../models/waste-management/wm-collection-status';
import { WMSearchParam } from 'src/modules/models/waste-management/wm-search-param';

export enum WMActionsTypes {

  GetInternalUserData = '[Internal User] Get Internal User Data',
  GetInternalUserDataSuccess = '[Internal User] Get Internal User Data Success',
  GetInternalUserDataReset = '[Internal User] Get Internal User Data Reset',

  AddSingleAddress = '[Address] Add Address',
  RemoveAddress = '[Address] Remove Address',

  SelectCustomer = '[Customer] Selected Customer',
  ClearSelectCustomer = '[Customer] Clear Selected Customer',

  GetBooking = '[Waste Collection] Get Booking',
  GetBookingSuccess = '[Waste Collection] Get Booking Success',
  GetBookingReset = '[Waste Collection] Get Booking Reset',

  GetSavedBookingSuccess = '[Waste Collection] Get Saved Booking Success',
  GetSavedBookingReset = '[Waste Collection] Get Saved Booking Reset',

  // GetWastePackages = '[Waste Collection] Get Waste Packages',
  // GetWastePackagesSuccess = '[Waste Collection] Get Waste Packages Success',
  // GetWastePackagesReset = '[Waste Collection] Get Waste Packages Reset',

  // GetWasteTypes = '[Waste Types] Get Waste Types',
  // GetWasteTypesSuccess = '[Waste Types] Get Waste Types Success',
  // GetWasteTypesReset = '[Waste Types] Get Waste Types Reset',

  GetCollectionLocations = '[Waste Collection] Get Collection Location',
  GetCollectionLocationsSuccess = '[Waste Collection] Get Collection Location Success',
  GetCollectionLocationsReset = '[Waste Collection] Get Collection Location Reset',

  ReportMissedCollectionSuccess = '[Waste Collection] Update Booking Package Notes',

  GetUpComingWasteBookings = '[Waste Collection] Get UpComing Waste Collection Booking',
  GetUpComingWasteBookingsSuccess = '[Waste Collection] Get UpComing Waste Booking Success',
  GetUpComingWasteBookingsReset = '[Waste Collection] Get UpComing Waste Booking Reset',

  GetPreviousWasteBookings = '[Waste Collection] Get Previous Waste Collection Booking',
  GetPreviousWasteBookingsSuccess = '[Waste Collection] Get Previous Waste Booking Success',
  GetPreviousWasteBookingsReset = '[Waste Collection] Get Previous Waste Booking Reset',

  AddBookingPackage = '[Package] Add Booking Package',
  UpdateBookingPackage = '[Package] Update Booking Package',
  RemoveBookingPackage = '[Package] Remove Booking Package',
  ClearAllBookingPackages = '[Package] Clear All Booking Packages',

  previousCartAmountSuccess = '[Cart] Add Previous Cart Total Amount',
  ResetPreviousCartAmount = '[Cart] Clear Previous Cart Total Amount',

  customerFeedBackSuccess = '[Customer Rating] Add Customer Feedback with booking',

  ResetInternalUserState = '[Internal User] Reset Internal User Data',
  SignOut = 'SignOut',
  IsInternalUserPortal = 'IsInternalUserPortal',

  SaveBookingProcessStart = 'SaveBookingProcessStart',

  // GetStreetsSuburbs = '[Waste Collection] Get Streets Suburbs',
  // GetStreetsSuburbsSuccess = '[Waste Collection] Get Streets Suburbs Success',
  // GetStreetsSuburbsReset = '[Waste Collection] Get Streets Suburbs Reset',

  ClearUnwantedArrays = 'ClearUnwantedArrays'
}

export class GetInternalUserDataAction implements Action {
  readonly type = WMActionsTypes.GetInternalUserData;
}

export class GetInternalUserDataSuccessAction implements Action {
  readonly type = WMActionsTypes.GetInternalUserDataSuccess;

  constructor(public payload: UserDataState) {
  }
}

export class GetInternalUserDataResetAction implements Action {
  readonly type = WMActionsTypes.GetInternalUserDataReset;
}

export class StoreSingleAddress implements Action {
  readonly type = WMActionsTypes.AddSingleAddress;

  constructor(public payload: IAddress) {
  }
}
export class RemoveAddressAction implements Action {
  readonly type = WMActionsTypes.RemoveAddress;
}

export class SelectCustomerAction implements Action {
  readonly type = WMActionsTypes.SelectCustomer;

  constructor(public payload: any) {
  }
}

export class ClearSelectedCustomerAction implements Action {
  readonly type = WMActionsTypes.ClearSelectCustomer;
}

// region Existing booking actions
export class GetBookingAction implements Action {
  readonly type = WMActionsTypes.GetBooking;

  constructor(public payload: number) {
  }
}

export class GetBookingSuccessAction implements Action {
  readonly type = WMActionsTypes.GetBookingSuccess;

  constructor(public payload: any) {
  }
}

export class GetBookingResetAction implements Action {
  readonly type = WMActionsTypes.GetBookingReset;
}

// endregion


// region Saved booking actions
export class GetSavedBookingSuccessAction implements Action {
  readonly type = WMActionsTypes.GetSavedBookingSuccess;

  constructor(public payload: any) {
  }
}

export class GetSavedBookingResetAction implements Action {
  readonly type = WMActionsTypes.GetSavedBookingReset;
}

// endregion

// region Waste Type Public Packages actions
// export class GetWastePackagesAction implements Action {
//   readonly type = WMActionsTypes.GetWastePackages;
// }

// export class GetWastePackagesSuccessAction implements Action {
//   readonly type = WMActionsTypes.GetWastePackagesSuccess;

//   constructor(public payload: any) {
//   }
// }

// export class GetWastePackagesResetAction implements Action {
//    readonly type = WMActionsTypes.GetWastePackagesReset;
//}

// endregion

// region New Booking Packages
export class AddBookingPackage implements Action {
  readonly type = WMActionsTypes.AddBookingPackage;

  constructor(public payload: WMBookingPackage) {
  }
}

export class UpdateBookingPackage implements Action {
  readonly type = WMActionsTypes.UpdateBookingPackage;

  constructor(public payload: WMBookingPackage) {
  }
}

export class RemoveBookingPackage implements Action {
  readonly type = WMActionsTypes.RemoveBookingPackage;

  constructor(public payload: WMBookingPackage) {
  }
}

export class ClearAllBookingPackagesAction implements Action {
  readonly type = WMActionsTypes.ClearAllBookingPackages;
}

// endregion

// region Upcoming and Previous Bookings
export class GetUpComingWasteBookingsAction implements Action {
  readonly type = WMActionsTypes.GetUpComingWasteBookings;

  constructor(public contactId: number, public pageNumber: number, public bookingId?: number, public params?: WMSearchParam) {
  }
}

export class GetUpComingWasteBookingsSuccessAction implements Action {
  readonly type = WMActionsTypes.GetUpComingWasteBookingsSuccess;

  constructor(public payload: any) {
  }
}

export class GetPreviousWasteBookingsResetAction implements Action {
  readonly type = WMActionsTypes.GetPreviousWasteBookingsReset;
}

export class GetPreviousWasteBookingsAction implements Action {
  readonly type = WMActionsTypes.GetPreviousWasteBookings;

  constructor(public contactId: number, public pageNumber: number, public bookingId?: number, public params?: WMSearchParam) {
  }
}

export class GetPreviousWasteBookingsSuccessAction implements Action {
  readonly type = WMActionsTypes.GetPreviousWasteBookingsSuccess;

  constructor(public payload: any) {
  }
}

export class GetUpComingWasteBookingsResetAction implements Action {
  readonly type = WMActionsTypes.GetUpComingWasteBookingsReset;
}

// endregion

// region Waste Types Actions
// export class GetWasteTypesAction implements Action {
//   readonly type = WMActionsTypes.GetWasteTypes;
// }

// export class GetWasteTypesSuccessAction implements Action {
//   readonly type = WMActionsTypes.GetWasteTypesSuccess;

//   constructor(public payload: any) {
//   }
// }

// export class GetWasteTypesResetAction implements Action {
//    readonly type = WMActionsTypes.GetWasteTypesReset;
// }

// endregion

// region Collection Actions
export class GetCollectionLocationsAction implements Action {
  readonly type = WMActionsTypes.GetCollectionLocations;
}

export class GetCollectionLocationsSuccessAction implements Action {
  readonly type = WMActionsTypes.GetCollectionLocationsSuccess;

  constructor(public payload: any) {
  }
}

export class GetCollectionLocationsResetAction implements Action {
  readonly type = WMActionsTypes.GetCollectionLocationsReset;
}

// endregion

export class ReportMissedCollectionSuccessAction implements Action {
  readonly type = WMActionsTypes.ReportMissedCollectionSuccess;

  constructor(public payload: any) {
  }
}

export class WMSignOutAction implements Action {
  readonly type = WMActionsTypes.SignOut;
}

export class WMIsInternalUserPortalAction implements Action {
  readonly type = WMActionsTypes.IsInternalUserPortal;
  constructor(public payload: boolean) {
  }
}

export class ResetInternalUserDataAction implements Action {
  readonly type = WMActionsTypes.ResetInternalUserState;
}

export class CustomerFeedbackSuccessAction implements Action {
  readonly type = WMActionsTypes.customerFeedBackSuccess;
  constructor(public payload: any) {
  }
}
export class PreviousCartAmountSuccessAction implements Action {
  readonly type = WMActionsTypes.previousCartAmountSuccess;
  constructor(public payload: any) {
  }
}
export class ReestPreviousCartAmountAction implements Action {
  readonly type = WMActionsTypes.ResetPreviousCartAmount;
}
export class SaveBookingProcessingStartAction implements Action {
  readonly type = WMActionsTypes.SaveBookingProcessStart;
  constructor(public payload: any) {
  }
}

// region suburbs and streets
// export class GetStreetsSuburbsAction implements Action {
//   readonly type = WMActionsTypes.GetStreetsSuburbs;
// }

// export class GetStreetsSuburbsSuccessAction implements Action {
//   readonly type = WMActionsTypes.GetStreetsSuburbsSuccess;

//   constructor(public payload: any) {
//   }
// }

// export class GetSStreetsSuburbsResetAction implements Action {
//    readonly type = WMActionsTypes.GetStreetsSuburbsReset;
// }

// endregion

export class ClearUnwantedArraysAction implements Action {
  readonly type = WMActionsTypes.ClearUnwantedArrays;
}

export type WMActions = StoreSingleAddress
  | SelectCustomerAction
  | ClearSelectedCustomerAction
  | GetInternalUserDataAction
  | GetInternalUserDataSuccessAction
  | GetInternalUserDataResetAction
  | GetUpComingWasteBookingsAction
  | GetUpComingWasteBookingsSuccessAction
  | GetUpComingWasteBookingsResetAction
  | GetPreviousWasteBookingsAction
  | GetPreviousWasteBookingsSuccessAction
  | GetPreviousWasteBookingsResetAction
  | GetBookingAction
  | GetBookingSuccessAction
  | GetBookingResetAction
  | GetSavedBookingSuccessAction
  | GetSavedBookingResetAction
  // | GetWastePackagesAction
  // | GetWastePackagesSuccessAction
 // | GetWastePackagesResetAction
  | AddBookingPackage
  | UpdateBookingPackage
  | RemoveBookingPackage
  | ClearAllBookingPackagesAction
  //| GetWasteTypesAction
  // | GetWasteTypesSuccessAction
 // | GetWasteTypesResetAction
  | GetCollectionLocationsAction
  | GetCollectionLocationsSuccessAction
  | GetCollectionLocationsResetAction
  | ReportMissedCollectionSuccessAction
  | WMSignOutAction
  | WMIsInternalUserPortalAction
  | ResetInternalUserDataAction
  | CustomerFeedbackSuccessAction
  | RemoveAddressAction
  | PreviousCartAmountSuccessAction
  | ReestPreviousCartAmountAction
  | SaveBookingProcessingStartAction
  // | GetStreetsSuburbsAction
  // | GetStreetsSuburbsSuccessAction
  //| GetSStreetsSuburbsResetAction
  | ClearUnwantedArraysAction
