import { BaseResource } from 'src/modules/models/base-resource';


export class TimeSlot extends BaseResource {

    constructor() {
        super();
        this.type = "TimeSlot";
    }
    startTime: Date;
    endTime: Date;
    preliminary: boolean;
    reserved: boolean;
}