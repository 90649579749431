import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'opt-wp-request-access',
  templateUrl: './wp-request-access.component.html',
  styleUrls: ['./wp-request-access.component.scss']
})
export class WpRequestAccessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
