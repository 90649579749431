<div class="mat-dialog-content">
    <div class="modal-header ">
        <div class="mmc_flex--container">
            <div class="mmc_flex__item title">
                <div class="font__h5 font__semibold">Documents</div>
            </div>
            <div class="mmc_flex__item--inherit icon">
                <button class="mat-icon-button mat-button" tabindex="0" (click)="close()" (keydown.enter)="close()" role="button">
                    <mat-icon class="mat-icon material-icons" role="img" aria-hidden="true">Close</mat-icon>
                </button>
            </div>
        </div>
    </div>

    <div class="modal-body">
        <!--modal body start-->
        <div class="doc-modal">
            <div class="doc-sec">
                <div class="doc-container">
                    <div *ngIf="selectedFiles.length > 0">
                        <div class="mmc_flex--container" *ngFor="let selectedFile of selectedFiles">
                            <div class="mmc_flex__item--inherit doc-icon font__h4">
                                <i class="icon-doc"></i>
                            </div><div class="mmc_flex__item middle-sec">
                                <div class="font__small">{{selectedFile.name}}</div>
                                <div class="font__medium font__xsmall">{{bytesToSize(selectedFile.size)}}</div>
                            </div>
                            <div class="mmc_flex__item">
                                <mat-form-field appearance="outline">
                                    <mat-label>Document category</mat-label>
                                    <input matInput placeholder="Placeholder">
                                </mat-form-field>
                            </div>
                            <div class="mmc_flex__item--inherit down-sec" tabindex="0" (click)="remove(selectedFile)" (keydown.enter)="remove(selectedFile)">
                                <i class="material-icons down-icon">Close</i>
                                <div class="font__medium font__xsmall">Remove</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mmc_flex--container footer-modal">
        <input id="fileupload" type="file" (change)="onFileChanged($event)" #fileInput style="display:none">
        <button class="btn__card main mmc_flex__item--vcenter add" tabindex="0" role="button" onclick="$('#fileupload').trigger('click'); return false;">ADD</button>
    </div>
</div>

