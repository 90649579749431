<div class="mmc_flex__item">
    <div class="enquiry-submit mmc_card enquiry">
        <div class="mmc_flex--container cart">
            <div class="mmc_flex__item--vcenter">
                <i class="material-icons pw-ico-color">
                    shopping_cart
                </i>
            </div>
        </div>
        <div class="outer-wrapper">
            <div class="mmc_flex--container outer-wrapper-title">
                <div class="mmc_flex__item--vcenter">
                    <!-- <div class="pw-txt-b2 pw-text-color__quinary"><span class="pw-text-semi-bold">{{facilitySummaries?.length}}</span> Venue(s)</div> -->
                    <div class="pw-txt-b2 pw-text-color__quinary venueSec"><span class="pw-text-semi-bold">{{facilitySummaries?.length}}</span> Venue(s)</div>
                    <div class="pw-txt-b2 pw-text-color__quinary spaceSec" style="display: none;"><span class="pw-text-semi-bold">{{facilitySummaries?.length}}</span> Space(s)</div>
                </div>
            </div>

            <div class="mmc_flex--container booking">
                <div class="mmc_flex__item--vcenter">
                    <h4 class="pw-txt-h4 pw-text-regular pw-text-trans__upper pw-text-color">BOOKING DETAILS</h4>
                </div>
            </div>
            <div class="mmc_flex--container confirmation">
                <span class="font__nt-available">
                    <i class="material-icons pw-ico-color__quinary">
                        comment
                    </i>
                </span>
                <span class="pw-txt-b1 pw-txt-p"  [innerHtml]="bookingCartPageTopNavPannelMessage">

                </span>
            </div>
            <div class="mobile-only">
                <div class="mmc_card">
                    <div class="cart-outer-wrapper">
                        <div class="mmc_flex--container">
                            <!--<mat-form-field>
                                <mat-select placeholder="Event Category" name="businessArea">
                                    <mat-option *ngFor="let businessArea of businessAreas" ><!--[value]="businessArea.Id"- ->
                                        {{businessArea?.Name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>-->
                        </div>
                        <!--<div class="mmc_flex--container" style="display:none">
                            <mat-radio-group name="opList" fxLayout="column" [(ngModel)]="selectedClass">
                                <mat-radio-button *ngFor="let op of packageClasses" [value]="op.Id" name="opList">{{op.Value}}</mat-radio-button>
                            </mat-radio-group>
                        </div>-->
                        <div class="mmc_flex--container">
                            <mat-form-field class="example-full-width">
                                <input matInput placeholder="Your Event Name">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="rpt">
                <div *ngIf="facilitySummaries?.length>0">
                    <div  *ngIf="(eventTotal != undefined) && showEventTotal()">
                        <div class="mmc_flex--container flex-column inner">
                            <div class="panel-event-total-wrapper d-flex cart-flex-item">
                                <div class="panel-event-totle-text cart-item-one">
                                    {{PackageTotalLabel}}
                                </div>
                                <div class="panel-event-totle-price d-flex cart-item-two">
                                    {{eventTotal | CurrencyFormat}}
                                </div>
                            </div>
                            <div class="mmc_flex__item--vcenter">
                                <div class="mmc_flex--container-s">
                                    <div class="mmc_flex__item">
                                        <div class="pw-txt-b2 pw-text-medium pw-text-color__tertiary">{{getDate() | dateFormat:'date'}}</div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div *ngFor="let facilitySummary of facilitySummaries">
                        <div class="mmc_flex--container flex-column inner cart-flex-item">

                            <div class="mmc_flex__item--vcenter cart-item-one">
                                <div class="pw-txt-b2 pw-text-medium pb-1">{{facilitySummary?.name}}</div>
                            </div>

                            <div class="mmc_flex__item--vcenter cart-item-two">
                                <div class="pw-txt-b2 pw-text-semi-bold pb-1  mr-3" style="text-decoration:line-through" *ngIf="isPriceChangedAfterLoggedIn(facilitySummary) && !isPriceRange(getPreviousFacilitySummary(facilitySummary))">
                                     {{getCalculatedItemPrice(getPreviousFacilitySummary(facilitySummary))?.priceIncludingTax | CurrencyFormat }}</div>
                                <div class="pw-txt-b2 pw-text-semi-bold pb-1  mr-3" style="text-decoration:line-through" *ngIf="isPriceChangedAfterLoggedIn(facilitySummary) && isPriceRange(getPreviousFacilitySummary(facilitySummary))">
                                    {{ getPreviousFacilitySummary(facilitySummary)?.item?.calculatedMinPrice?.priceIncludingTax | CurrencyFormat}} - {{getPreviousFacilitySummary(facilitySummary)?.item?.calculatedMaxPrice?.priceIncludingTax | CurrencyFormat }}                                </div>
                                    <!--<div class="pw-txt-b2 pw-text-semi-bold pb-1 "  *ngIf="isPriceRange(facilitySummary,upsellSummary,false)">{{facilitySummary.minPrice?.priceIncludingTax | CurrencyFormat}} - {{facilitySummary.maxPrice?.priceIncludingTax | CurrencyFormat}}</div>
                                <div class="pw-txt-b2 pw-text-semi-bold pb-1"  *ngIf="!isPriceRange(facilitySummary,upsellSummary,false) && facilitySummary.minPrice && facilitySummary.minPrice?.priceIncludingTax !=0">{{facilitySummary.minPrice?.priceIncludingTax | CurrencyFormat}}</div>-->
                                <div class="total__amount pw-txt-b2 pw-text-color__tertiary pw-text-medium" *ngIf="!getCalculatedItemPrice(facilitySummary)">Prices are not defined</div>
                                <div *ngIf="getCalculatedItemPrice(facilitySummary)">
                                    <div class="pw-txt-b2 pw-text-semi-bold pb-1 " *ngIf="isPriceRange(facilitySummary,upsellSummary,false)">{{facilitySummary.item?.calculatedMinPrice?.priceIncludingTax | CurrencyFormat}} - {{facilitySummary.item?.calculatedMaxPrice?.priceIncludingTax | CurrencyFormat}}</div>
                                    <div class="pw-txt-b2 pw-text-semi-bold pb-1" *ngIf="!isPriceRange(facilitySummary,upsellSummary,false)">{{getCalculatedItemPrice(facilitySummary)?.priceIncludingTax | CurrencyFormat}}</div>
                                </div>
                            </div>

                            <div class="mmc_flex__item--vcenter cart-item-three">
                                <div class="mmc_flex--container-s">
                                    <div class="mmc_flex__item">
                                        <div class="pw-txt-b2 pw-text-medium pw-text-color__tertiary">{{facilitySummary?.startTime | dateFormat:'date'}}</div>
                                    </div>
                                </div>
                                <div class="mmc_flex__item right-align" [ngStyle]="cartData && cartData?.booking?.id ? {'cursor': 'not-allowed'} : {}">
                                    <i class="btn-click pw-ico-color__tertiary icon-trash" tabindex="0" (click)="deleteItem(facilitySummary)" (keydown.enter)="deleteItem(facilitySummary)" role="button" aria-label="icon trash" [ngStyle]="cartData && cartData?.booking?.id ? {'pointer-events': 'none'} : {}"></i>
                                    <i class="btn-click pw-ico-color__tertiary icon-edit" tabindex="0" (click)="openEditPopup(facilitySummary)" (keydown.enter)="openEditPopup(facilitySummary)" role="button" aria-label="icon edit" [ngStyle]="cartData && cartData?.booking?.id ? {'pointer-events': 'none'} : {}"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="facilitySummary.upsellSummaries?.length>0">
                            <div class="mmc_flex--container flex-column inner cart-flex-item" *ngFor="let upsellSummary of facilitySummary.upsellSummaries ; let i = index">

                                <div class="mmc_flex__item--vcenter  cart-item-one">
                                    <div class="pw-txt-b2 pw-text-medium pb-1">{{upsellSummary?.name}}</div>
                                </div>

                                <div class="mmc_flex__item--vcenter cart-item-two ">

                                    <div class="pw-txt-b2 pw-text-semi-bold pb-1  mr-3" style="text-decoration:line-through" *ngIf="isPriceChangedAfterLoggedIn(facilitySummary,upsellSummary) && !isPriceRange(getPreviousFacilitySummary(facilitySummary),getPreviousUpsell(facilitySummary,upsellSummary),true)">
                                        {{upsellSummaryPrice(getPreviousUpsell(facilitySummary,upsellSummary)) | CurrencyFormat }}
                                    </div>
                                    <div class="pw-txt-b2 pw-text-semi-bold pb-1  mr-3" style="text-decoration:line-through" *ngIf="isPriceChangedAfterLoggedIn(facilitySummary,upsellSummary) && isPriceRange(getPreviousFacilitySummary(facilitySummary),getPreviousUpsell(facilitySummary,upsellSummary),true)">
                                       {{getPreviousUpsell(facilitySummary,upsellSummary)?.minPrice?.priceIncludingTax | CurrencyFormat}} - {{getPreviousUpsell(facilitySummary,upsellSummary)?.maxPrice?.priceIncludingTax | CurrencyFormat}}
                                    </div>
                                    <div class="total__amount pw-txt-b2 pw-text-color__tertiary pw-text-medium" *ngIf="!isHaveupsellSummaryPrice(upsellSummary)">Prices are not defined</div>
                                    <div *ngIf="isHaveupsellSummaryPrice(upsellSummary)" >
                                        <div class="pw-txt-b2 pw-text-semi-bold pb-1 " *ngIf="isPriceRange(facilitySummary,upsellSummary,true)">{{upsellSummary.minPrice?.priceIncludingTax | CurrencyFormat}} - {{upsellSummary.maxPrice?.priceIncludingTax | CurrencyFormat}}</div>
                                        <div class="pw-txt-b2 pw-text-semi-bold pb-1" *ngIf="!isPriceRange(facilitySummary,upsellSummary,true)">{{upsellSummaryPrice(upsellSummary) | CurrencyFormat}}</div>

                                        </div>
                                    </div>
                                <div class="mmc_flex__item--vcenter cart-item-three" [ngStyle]="cartData && cartData?.booking?.id ? {'cursor': 'not-allowed'} : {}">
                                    <div class="mmc_flex__item right-align" [ngStyle]="cartData && cartData?.booking?.id ? {'pointer-events': 'none'} : {}">
                                        <i class="btn-click pw-ico-color__tertiary icon-trash" tabindex="0" (click)="deleteUpsellItem(facilitySummary,upsellSummary)" (keydown.enter)="deleteUpsellItem(facilitySummary,upsellSummary)" role="button"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="mmc_flex--container flex-column inner">
                            <div class="panel-total-wrapper">
                                <div class="panel-totle-text">
                                    Total
                                </div>
                                <div class="panel-totle-price" *ngIf="(minAmount==maxAmount)">
                                    {{minAmount | CurrencyFormat}}
                                </div>
                                <div class="panel-totle-price" *ngIf="minAmount!=maxAmount">
                                    {{minAmount|CurrencyFormat  }}-{{ maxAmount|CurrencyFormat }}
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div class="doc-summary" *ngIf="( cart$|async)?.AddedDocuments && (cart$|async)?.AddedDocuments.length>0">
                    <div class="mmc_flex--container">
                        <div class="mmc_flex__item--vcenter file-icon">
                            <span class="icon-file"></span>
                        </div>
                        <div class="mmc_flex__item--vcenter doc-outer">
                            <div class="doc-available">
                                <div><span>{{( cart$|async)?.AddedDocuments?.length}}</span>document(s)</div>
                                <div>available</div>
                            </div>
                        </div>
                        <div class="mmc_flex__item--vcenter">
                            <div class="mobi-btn" tabindex="0" (click)="clickEvent2()" (keydown.enter)="clickEvent2()" role="button">See all documents</div>
                        </div>
                    </div>
                </div>
                <div class="mmc_flex--container bottom-section flex-column">
                    <div class="mmc_flex__item">
                        <div>
                            <mat-checkbox [(ngModel)]="isAgreed" aria-label="Agree"></mat-checkbox>
                            <span class="pw-txt-b3 pw-txt-p pw-text-regular pw-text-color__tertiary terms-n-conditions" [innerHtml]="bookingTermAndConditionMessage"></span>
                        </div>
                    </div>
                    <div *ngIf="!isAllImmediateConfirmed" class="btn__card main mmc_flex__item--vcenter submit" [ngClass]="{'disable-div' : !isAgreed || (!isValid(facilitySummaries)) || !(!isInternalUser ||(cart$|async)?.contact?.id) }" tabindex="0" (click)="clickSubmitEnquiry()" (keydown.enter)="clickSubmitEnquiry()" role="button">SUBMIT ENQUIRY</div>
                    <div *ngIf="isAllImmediateConfirmed && minAmount==0" class="btn__card main mmc_flex__item--vcenter submit" [ngClass]="{'disable-div' : !isAgreed || (!isValid(facilitySummaries)) || !(!isInternalUser ||(cart$|async)?.contact?.id) }" tabindex="0" (click)="clickSubmitEnquiry()" (keydown.enter)="clickSubmitEnquiry()" role="button">CONFIRM</div>
                    <div *ngIf="isAllImmediateConfirmed && minAmount>0" class="btn__card main mmc_flex__item--vcenter submit" [ngClass]="{'disable-div' : !isAgreed || (!isValid(facilitySummaries)) ||  !(!isInternalUser ||(cart$|async)?.contact?.id) } "tabindex="0" (click)="clickSubmitEnquiry()" (keydown.enter)="clickSubmitEnquiry()" role="button">MAKE PAYMENT</div>
                    <div *ngIf="!( cart$|async)?.contact?.firstName && !isInternalUser">
                        <div class="btn__card main mmc_flex__item--vcenter justify-content-center sign btn-click hide-in-cog"  (click)="Login()" (keydown.enter)="Login()" tabindex="0" role="button">SIGN IN</div>
                        <div class="mmc_flex__item pw-text-color__primery pw-txt-b2 txt-sign hide-in-cog" tabindex="0" (click)="signUp()" (keydown.enter)="signUp()">Don't have account? <a style="color:Blue" class="btn-click">Sign up</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
