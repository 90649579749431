import { Component } from '@angular/core';
import { AssetService } from 'src/modules/services/asset.service';
import { Utility } from 'src/modules/utility';
import { FacilityAdaptor } from 'src/modules/models/asset/facility-adaptor';
import { Timeslot } from 'src/modules/models/asset/timeslots';
import { FacilitySummary } from 'src/modules/models/asset/facility-summary';
import { BaseComponent } from 'src/modules/shared/base.component';

@Component({
  selector: 'opt-facility-common',
  template:''
})
export class FacilityCommonComponent extends BaseComponent{

    constructor(protected _assetService: AssetService) {
        super();
    }

  loadFacilityTimeslots(searchFacilities:FacilitySummary[],searchDate:Date) {

    if (searchFacilities.length > 0) {
        var fc = "";
        searchFacilities.forEach(f => {
            if (fc.length > 0)
                fc += ",";
            fc += f.id;

            if (!f.calenderTimeslots)
                f.calenderTimeslots = [];             

        });
        let dtS = new Date(searchDate.getTime());
        let dtE = new Date(searchDate.getTime());
        dtE.setHours(23);
        dtE.setMinutes(59);
        dtE.setSeconds(0);


        this._assetService.timeslotSearch(fc, Utility.convertToISO(dtS), Utility.convertToISO(dtE)).subscribe(res => {
            if (res) {
                res.forEach(t => {
                    if (t.asset.id) {
                        var facility = searchFacilities.find(f => f.id == t.asset.id);
                        if (facility) {
                            if (!facility.calenderTimeslots)
                                facility.calenderTimeslots = [];
                            facility.timeslotLoaded = true;
                            var arr: Timeslot[] = [];
                            arr.push(t);
                            var ts = FacilityAdaptor.populateTimeslot(arr, Utility.convertISOToDate(searchDate));
                            if (ts) {
                                ts.forEach(x => {
                                    facility.calenderTimeslots.push(x);
                                });

                            }
                        }
                    }
                });
            }
            FacilityAdaptor.populateAvailableTimeslots(searchFacilities, Utility.convertISOToDate(dtS));
        }, err => {
            //  this.store.dispatch(new FacilitySearchLoaded());
        });
    }
}
}
