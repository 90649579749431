import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
@Injectable()
export class OptimoFilterPipe implements PipeTransform {
  transform(items: any[], field : string, value : string): any[] {
    if (!items) return [];
    if (!value || value.length == 0) return items;
    return items.filter(it => {
      if(!!it[field]) {
       return it[field].toLowerCase() == value.toLowerCase()
      } else {
        console.log('eventId:', it[field])
      }
    });
  }
}
