<div *ngIf="showErrorMessage">

    <wp-error-message [errorMessageType]="2" [MessageDetail]="displayMessage"></wp-error-message>

</div>
<ngu-carousel #myCarousel [inputs]="carouselConfig" [dataSource]="carouselItems" class="wp-carousel"  [ngClass]="{'hide-caraousel-nav-button': checkCaraousalHide}" >
    <div *nguCarouselDef="let item;" class="item">
        <div class="wp-seasons-card-wrapper"  (click)="storeBookingId(item)" (keydown.enter)="storeBookingId(item)">
            <div matRipple matRippleColor="#2574A950" class="wp-seasons-card">
                <div class="wp_flex--container flex-column wp-mb-10">
                    <div class="wp_flex__item--inherit">
                        <div class="wp-txt-body1--small wp-txt--secondary">{{setupNameForBooking}}</div>
                    </div>
                    <div class="wp_flex__item--inherit">
                        <div class="wp-txt-body1">{{item?.name}}</div>
                    </div>
                </div>
                <div class="wp_flex--container wp-mb-10">
                    <div class="wp_flex__item">
                        <div class="wp_flex--container flex-column">
                            <div class="wp_flex__item--inherit">
                                <div class="wp-txt-body1--small wp-txt--secondary">From</div>
                            </div>
                            <div class="wp_flex__item--inherit">
                                <div class="wp-txt-body1">{{displaySeasonDate(item?.from)}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="wp_flex__item">
                        <div class="wp_flex--container flex-column">
                            <div class="wp_flex__item--inherit">
                                <div class="wp-txt-body1--small wp-txt--secondary" *ngIf="isCutoffDateAvailable(item)">To</div>
                                <div class="wp-txt-body1--small wp-txt--secondary" *ngIf="!isCutoffDateAvailable(item)">Book before</div>
                            </div>
                            <div class="wp_flex__item--inherit">
                                <div class="wp-txt-body1">{{displaySeasonDate(item.to) | dateFormat:'date'}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="wp_flex--container" *ngIf="isCutoffDateAvailable(item)">
                    <div class="wp_flex__item--inherit">
                        <div class="wp-txt-body1--small">Book before {{displaySeasonDate(item.cutoffDate)| dateFormat:'date' }}</div>
                    </div>
                </div>
                <div *ngIf ="checkSeasonSelection(item)"  class="card-selection selected" >
                    <i class="material-icons">done</i>
                </div>
                <!--<div *ngIf="item?.id?.toString()!=seasonId?.toString()" class="card-selection ">-->
                    <div *ngIf="!checkSeasonSelection(item)" class="card-selection ">
                        <i class="material-icons">done</i>
                    </div>
            </div>
        </div>
    </div>

    <button NguCarouselNext class="right-nav-icon">
        <i class="material-icons">keyboard_arrow_right</i>
    </button>
    <button NguCarouselPrev class="left-nav-icon">
        <i class="material-icons">keyboard_arrow_left</i>
    </button>
    <ul class="myPoint" NguCarouselPoint>
        <li *ngFor="let p of myCarousel.pointNumbers; let j = index" [class.active]="p==myCarousel.activePoint" (click)="myCarousel.moveTo(j)" (keydown.enter)="myCarousel.moveTo(j)"></li>
    </ul>
</ngu-carousel>
