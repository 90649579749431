import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { WmWasteTypeApiService } from './wm-waste-type-api-service';


@Injectable({
  providedIn: 'root'
})
export class WmCommonService {

  private address = new BehaviorSubject<string>(null);
  staticAddress$ = this.address.asObservable();

  private cartCount = new BehaviorSubject<number>(null);
  cartCount$ = this.cartCount.asObservable();

  private resetPageIndex = new BehaviorSubject<number>(null);
  resetPageIndex$ = this.resetPageIndex.asObservable();

  private customerRating = new BehaviorSubject<any>(null);
  customerFeedback$ = this.customerRating.asObservable();

  private collectionPackageLoader = new BehaviorSubject<any>(false);
  collectionPackageRefreshLoader$ = this.collectionPackageLoader.asObservable();

  private errorState = new BehaviorSubject<any>(false);
  BookingPackageErrorState$ = this.errorState.asObservable();

  private bookingPaidAmount = new BehaviorSubject<any>(null);
  getBookingPaidAmount$ = this.bookingPaidAmount.asObservable();

  private collectionImages = new BehaviorSubject<any>(null);
  getCollectionImages$ = this.collectionImages.asObservable();

  private refreshBookingPackage = new BehaviorSubject<any>(true);
  refreshBookingPackage$ = this.refreshBookingPackage.asObservable();

  private userLogStatus = new BehaviorSubject<any>(false);
  isUserDestroy$ = this.userLogStatus.asObservable();

  private paymentUrl = new BehaviorSubject<any>(null);
  userPaymentUrl$ = this.paymentUrl.asObservable();


  private paidBookingType = new BehaviorSubject<any>(null);
  paidBookingType$ = this.paidBookingType.asObservable();

  currentTabIndex = 0;

  constructor(private wmWasteTypeApiService: WmWasteTypeApiService) {
  }

  updateCartCount(count: number) {
    this.cartCount.next(count);
  }


  setCustomerRate(data) {
    this.customerRating.next(data);
  }

  setDefaultPageIndex(data) {
    this.resetPageIndex.next(data);
  }

  makePkgLoaderTrueFalse(data: boolean) {
    this.collectionPackageLoader.next(data);
  }

  isBookingPkgReturnErrorState(res: boolean) {
    this.errorState.next(res);

  }

  setBookingPaidAmount(res) {
    if (res === null) {
      this.bookingPaidAmount.next(0);
    } else {
      this.bookingPaidAmount.next(res);
    }
  }

  refreshBookingPackageLoaderComplete(res) {
    this.refreshBookingPackage.next(res);
  }

  getCollectionImages(res: any) {
    this.collectionImages.next(res);
  }

  setUserLogStatus(res) {
   this.userLogStatus.next(res);
  }

  setPaymentUrl(url) {
    this.paymentUrl.next(url);
  }

  paidBookingStatus(type) {
    this.paidBookingType.next(type);
  }

  getCancellationPolicies(bookingId , paidAmount?) {
    let cancellationCharge = 0 ;
    const cancellationPolicy = this.wmWasteTypeApiService.getCancellationPolicies(bookingId);
    forkJoin([cancellationPolicy]).subscribe((resp) => {
      if (resp[0] && resp[0].length > 0) {
        cancellationCharge = 0;
        resp[0].forEach(policy => {
          cancellationCharge += policy.CancellationChargeIncludingTax;
        });
      }
    });
    return cancellationCharge;
  }
}



