import { CartActionTypes, CartActions } from "./actions";
import { ICart } from "./cart";

import { Contact } from '../models/client/contact';
import { Booking } from '../models/booking/booking';
import { environment } from 'src/environments/environment'
import { PackageFilter } from '../models/booking/common';
import { FacilityAdaptor } from 'src/modules/models/asset/facility-adaptor'
import { FacilitySummary } from 'src/modules/models/asset/facility-summary'
import { Utility } from 'src/modules/utility';
import { Allocations } from 'src/modules/models/booking/allocations'
import { Asset } from 'src/modules/models/booking/asset'

import { Item } from 'src/modules/models/item/item';
import { Facilitysearchparams } from "../models/asset/facility-search-params";



//set default values
let defaultContact = new Contact();
defaultContact.firstName = "";
defaultContact.profilePicture = "";


export let initialState: ICart =
{    contact: defaultContact,
    booking: new Booking(),
    bespokePackages: [],
    // defaultPackage: null,
    addedFacilites: [],
    //bookingPackage: null,
    packageFilter: null,
    AddedDocuments: [],
    Item: new Item(),
    isAllImmediateConfirmed: false,
    //isImmediateConfirmed: false,
    priceChangedFacilities: [],
    //hasZeroPrice: false,
    LastUpdatedTime: null,
    InternalUserData: null,
    userDefinedFunctions: [],
    showPackageQuestionPopup: false,
    BookingCreatedTime: null
};

export function isCardExpired(LastUpdateTime: Date) {
  if (environment.BookingExpiryInMinutes && environment.BookingExpiryInMinutes != 0 && LastUpdateTime) {
    var BasketExpireDate = new Date(LastUpdateTime);
    BasketExpireDate.setMinutes(BasketExpireDate.getMinutes() + environment.BookingExpiryInMinutes);
    if (BasketExpireDate < new Date()) {
      return true;
    }
  }
  return false;
}


export function cartReducer(state = initialState, action: CartActions) {
    switch (action.type) {
        case CartActionTypes.REDIRECT:
        case CartActionTypes.PACKAGE_SEARCH:
        case CartActionTypes.POST_BOOKING:
        // case CartActionTypes.DO_BOOKING:
        return { ...state, LastUpdatedTime: new Date() };
        case CartActionTypes.SET_ITEM:
        return { ...state, Item: action.payload, LastUpdatedTime: new Date() };
        case CartActionTypes.SET_CONTACT:

            let pcfc = state.addedFacilites == undefined ? [] : state.addedFacilites;
            var json = JSON.parse(JSON.stringify(pcfc));
        return { ...state, contact: action.payload, priceChangedFacilities: json, LastUpdatedTime: new Date()};
        case CartActionTypes.SIGNOUT:
            return { ...state, contact: new Contact(), priceChangedFacilities: [], AddedDocuments: [], addedFacilites: [], InternalUserData: null, packageFilter: new PackageFilter(), isAllImmediateConfirmed: false, booking: new Booking() };
        case CartActionTypes.SET_RB_INTERNAL_USER_DATA:
            return { ...state, InternalUserData: action.payload }
        case CartActionTypes.ADD_DOCUMENTS:
            let added = state.AddedDocuments == undefined ? [] : state.AddedDocuments;

            var exsitingFile = added.find(o => o == action.payload);
            if (exsitingFile == undefined) {
                added.push(action.payload);
             }

        return { ...state, AddedDocuments: added, LastUpdatedTime: new Date() };

        case CartActionTypes.DELETE_DOCUMENTS:

            let ad = state.AddedDocuments == undefined ? [] : state.AddedDocuments;
            let indexnum = ad.findIndex(x => x == action.payload);
            if (indexnum != undefined) {
                ad.splice(indexnum, 1);
            }

            if (ad.length == 0) {
                ad = [];
        }

        return { ...state, AddedDocuments: ad, LastUpdatedTime: new Date() };



        case CartActionTypes.VALIDATE_FACILITIES:

            var facilities = state.addedFacilites == undefined ? [] : state.addedFacilites;
            if (facilities && facilities.length > 0) {
                var deleteFacilities: FacilitySummary[] = [];
                // latest future date validation
                var CurrentDate = new Date();

                var leadDays = (environment.LeadDaysForBooking == null) ? 0 : environment.LeadDaysForBooking;
                if (environment.DisableDatePickerForLeadDays) {
                    CurrentDate.setDate(CurrentDate.getDate() + leadDays);
                }

                facilities.forEach(fc => {
                    if (new Date(fc.startTime) < CurrentDate) {
                        deleteFacilities.push(fc);
                    }
                });
                if (deleteFacilities.length > 0) {
                    deleteFacilities.forEach(fc => {
                        let index = facilities.findIndex(x => x.index == fc.index);
                        if (index != undefined) {
                            facilities.splice(index, 1);
                        }
                    });
                }
            }
        return { ...state, addedFacilites: facilities, LastUpdatedTime: new Date() };

        case CartActionTypes.ADD_FACILITY:{


            let fcs = state.addedFacilites == undefined ? [] : state.addedFacilites;
            let priceChangeFacility = state.priceChangedFacilities == undefined ? JSON.parse(JSON.stringify(fcs)) : state.priceChangedFacilities;

            let payloadStartTime = Utility.convertToISO(Utility.convertISOToDate(action.payload.startTime));
            let payloadEndTime = Utility.convertToISO(Utility.convertISOToDate(action.payload.endTime));

            let _recalculatePrices=[];
            // removing duplication
            var exsitingItem = fcs.find(fc => fc.id == action.payload.id && fc.startTime == action.payload.startTime && fc.endTime == action.payload.endTime);
            if (exsitingItem == undefined) {
                if (action.payload.allocations == undefined || action.payload.allocations.length == 0) {
                    action.payload.allocations = [];

                    var allocation = new Allocations();
                    allocation.timeslot.asset = new Asset();
                    allocation.attendees = action.payload.attendees;
                    allocation.timeslot.asset.id = action.payload.id;
                    allocation.timeslot.startTime = payloadStartTime;
                    allocation.timeslot.endTime = payloadEndTime;
                    allocation.timeslot.eventConfiguration.id = action.payload.configuration.id.toString();
                    //allocation.id = (tid * (-1)).toString();
                    //allocation.timeslot.id = (tid * (-1)).toString();
                    action.payload.allocations.push(allocation);
                } else {
                    action.payload.allocations.forEach(a => {
                        a.attendees = action.payload.attendees;
                        a.timeslot.startTime = payloadStartTime;
                        a.timeslot.endTime = payloadEndTime;
                    });
                }

                // Combined Room Selection logic
                let isCombinedRoomAdded = false;
                if (fcs.length > 0 && environment.AutomaticallyPickItemBasedOnFacility) {
                    var matchedOtherFc: FacilitySummary[] = [];

                    fcs.forEach(function (fc) {
                        if (!isCombinedRoomAdded) {
                            var st = Utility.convertToISO(Utility.convertISOToDate(fc.startTime));
                            var end = Utility.convertToISO(Utility.convertISOToDate(fc.endTime));

                            matchedOtherFc = [];

                            if (st == payloadStartTime && end == payloadEndTime) {
                                if (fc.items != undefined && action.payload.items != undefined) {
                                    //Loop through the item of existing cart item
                                    let SelectedItem = null;
                                    var items = fc.items.sort((a, b) => { return b.itemAssets.length - a.itemAssets.length });
                                    items.forEach(function (item) {
                                        if (SelectedItem == null && ///not found matching item yet
                                            !isCombinedRoomAdded &&
                                            item.allocateToAllAssets && item.itemAssets != undefined && item.itemAssets.length > 1) {
                                            //List all the facilities which are in same slot
                                            var fcArr = [action.payload.id];
                                            if (fc.allocations.length > 0) {
                                                fc.allocations.forEach(function (alc) {
                                                    fcArr.push(alc.timeslot.asset.id);
                                                });
                                            }
                                            var includeAll = true;
                                            fcArr.forEach((f) => {
                                                if (includeAll) {
                                                    var itemAsset = item.itemAssets.find(i => i.asset.id == f);
                                                    if (itemAsset == undefined)
                                                        includeAll = false;
                                                }
                                            });
                                            //Check Combined item has only assets which are booked
                                            var assetNotSelected = item.itemAssets.filter(i => fcArr.indexOf(i.asset.id) < 0);
                                            //check missed items are already added as seperate cart item
                                            if (includeAll && assetNotSelected != undefined && assetNotSelected.length > 0) {

                                                var remainingAssets = assetNotSelected;
                                                fcs.forEach((f) => {
                                                    if (f.index != fc.index && remainingAssets && remainingAssets.length > 0) {
                                                        //times are matched with facility time
                                                        var alc: any[] = []
                                                        f.allocations.forEach(fa => {
                                                            if (assetNotSelected.findIndex(ans => ans.asset.id == fa.timeslot.asset.id) >= 0
                                                                && Utility.convertToISO(Utility.convertISOToDate(fa.timeslot.startTime)) == payloadStartTime
                                                                && Utility.convertToISO(Utility.convertISOToDate(fa.timeslot.endTime)) == payloadEndTime) {
                                                                alc.push(fa);
                                                            }
                                                        }
                                                        );

                                                        if (f.allocations.length > 0 && alc != undefined && alc.length == f.allocations.length) {
                                                            matchedOtherFc.push(f);
                                                            remainingAssets = assetNotSelected.filter(u => { alc.findIndex(a => a.timeslot.asset.id == u.asset.id) < 0 });
                                                        }

                                                    }
                                                });
                                                if (matchedOtherFc.length > 0) {
                                                    assetNotSelected.forEach((mf) => {
                                                        if (includeAll) {
                                                            var obj = matchedOtherFc.find(u => u.allocations.findIndex(i => i.timeslot.asset.id == mf.asset.id) < 0);
                                                            if (obj) {
                                                                includeAll = false;
                                                            }
                                                        }
                                                    });
                                                } else {
                                                    includeAll = false;
                                                }
                                                if (includeAll) {
                                                    matchedOtherFc.forEach((i) => {
                                                        i.allocations.forEach(al => {
                                                            fc.allocations.push(al);
                                                        });

                                                    });
                                                }
                                            }

                                            if (includeAll) {
                                                SelectedItem = item;
                                                fc.item = item;
                                                isCombinedRoomAdded = true;
                                                fc.isCombinedRoomFacility = true;
                                                fc.name = SelectedItem.name;

                                                let allocation = new Allocations();
                                                allocation.attendees = action.payload.attendees;
                                                allocation.timeslot.asset = new Asset();
                                                allocation.timeslot.asset.id = action.payload.id;
                                                allocation.timeslot.startTime = Utility.convertToISO(Utility.convertISOToDate(action.payload.startTime));
                                                allocation.timeslot.endTime = Utility.convertToISO(Utility.convertISOToDate(action.payload.endTime));
                                                allocation.timeslot.eventConfiguration.id = action.payload.configuration.id.toString();
                                                //allocation.id = (tid * (-1)).toString();
                                                //allocation.timeslot.id = (tid * (-1)).toString();
                                                fc.allocations.push(allocation);
                                                let pk;
                                                if (state.packageFilter) {
                                                    pk = state.packageFilter.PackageClass
                                                }
                                                //todo immediate booking scenaio need to be check for price concession
                                                FacilityAdaptor.populateItemDetail(SelectedItem, SelectedItem.itemPriceGroups, pk, FacilityAdaptor.getBookingPriceConcession(state.contact.client.priceConcessionId, fcs.filter(facility => facility.item.allowImmediateConfirmedBookings == 1).length == fcs.length));
                                                _recalculatePrices.push(SelectedItem);
                                            }
                                        }
                                    });
                                }
                            }
                        }
                    }
                    );
                    matchedOtherFc.forEach((i) => {
                        let index = fcs.findIndex(x => x.index == i.index);
                        if (index != undefined) {
                            fcs.splice(index, 1);
                        }

                    });
                }

                if (!isCombinedRoomAdded) {
                    action.payload.index = action.payload.index == undefined ?
                        (fcs.length > 0 ? (Math.max.apply(Math, fcs.map(function (o) { return o.index; }))) + 1 : 0) : action.payload.index;
                    fcs.push(action.payload);
                    priceChangeFacility.push(action.payload);
                }
            }
            let _isAllImmediateConfirmed = false;

            //let zeroPrceiItems = [];
            //let zeroPrceiUpsellItems = [];
            if (fcs.length == 0) {
                let bk = new Booking();
              return { ...state, addedFacilites: fcs, booking: bk, isAllImmediateConfirmed: _isAllImmediateConfirmed, priceChangedFacilities: fcs, LastUpdatedTime: new Date() };
            }
            /*else {
                let immediateConfirmationFacilityList = [];
                immediateConfirmationFacilityList = fcs.filter(facility => facility.item.allowImmediateConfirmedBookings == 1);

                if (environment.ImmediateConfirmation.Allow)
                _isAllImmediateConfirmed = (fcs.length == immediateConfirmationFacilityList.length);
            }*/
            var arr = fcs.sort(function (a, b) { return a.index - b.index })

            // set default package
            {

                /* let matchingPackages = [];

                arr.forEach(itm => {
                    var pks = state.bespokePackages;//state.bespokePackages.sort(function (a, b) { return a.salesCategories.length - b.salesCategories.length })

                    pks.forEach(p => {
                        let index = matchingPackages.findIndex(selectedP => selectedP.id == p.id);
                        if (p.salesCategories.findIndex(ps => itm.item.salesCategories.findIndex(i => i.id == ps) >= 0) >= 0) {
                            if (index <= 0)
                                matchingPackages.push(p);
                        } else if (index > 0) {
                            matchingPackages.splice(index, 1);
                        }

                    })

                });
                */
                if (!state.packageFilter) {
                    state.packageFilter = new PackageFilter();
                }
                let matchingPackages = FacilityAdaptor.getMatchedPackages(arr, state.bespokePackages);
                if (matchingPackages.length > 0) {

                    let distinctPC = matchingPackages.map(p => p.packageClassId).filter((age, index, arr) => arr.indexOf(age) == index);
                    state.packageFilter.FilteredPackageClasses = environment.PackageClasses.filter(cls => cls.Id != "0" && distinctPC.indexOf(cls.Id)>=0);
                    if (!state.packageFilter.Package || matchingPackages.findIndex(selectedP => selectedP.id == state.packageFilter.Package) < 0) {
                       // state.packageFilter.Package = matchingPackages[0].id;
                       // state.packageFilter.PackageDetail = matchingPackages[0];
                        state.packageFilter.PackageClass = matchingPackages[0].packageClassId;
                        state.packageFilter.BussinessAreaId = matchingPackages[0].businessAreaId;
                        //if (state.packageFilter.PackageDetail) {
                        //    _isAllImmediateConfirmed = (_isAllImmediateConfirmed && state.packageFilter.PackageDetail.AllowImmediateConfirmedBookings == 1);
                        //}
                    }
                } else {
                    state.packageFilter.Package = 0;
                }
                _isAllImmediateConfirmed =isImmediateBooking(state);

                let pck;
                if (state.packageFilter) {
                    pck = state.packageFilter.PackageClass
                }
                _recalculatePrices.forEach(function (SelectedItem) {
                    FacilityAdaptor.populateItemDetail(SelectedItem, SelectedItem.itemPriceGroups, pck, FacilityAdaptor.getBookingPriceConcession(state.contact.client.priceConcessionId, _isAllImmediateConfirmed));
                });

            }

        return { ...state, addedFacilites: arr,priceChangedFacilities: priceChangeFacility, isAllImmediateConfirmed: _isAllImmediateConfirmed,  LastUpdatedTime: new Date()};
        }
        case CartActionTypes.DELETE_FACILITY:
            {
                let _fc = state.addedFacilites == undefined ? [] : state.addedFacilites;
                let index = _fc.findIndex(x => x.index == action.payload);
                let priceChangefc = state.priceChangedFacilities == undefined ? [] : state.priceChangedFacilities;
                let priceChangefcindex = priceChangefc.findIndex(x => x.index == action.payload);

                if (index != undefined) {
                    _fc.splice(index, 1);
                }

                if (priceChangefcindex != undefined) {
                    priceChangefc.splice(priceChangefcindex, 1);
                }

                //set package class and package based on the item in cart
                if (_fc.length == 0) {
                    state.packageFilter.FilteredPackageClasses = environment.PackageClasses.filter(cls => cls.Id != "0");
                    state.packageFilter.PackageDetail = undefined;
                    state.packageFilter.Package =0;
                }
                else {
                    let matchingPackages = FacilityAdaptor.getMatchedPackages(_fc, state.bespokePackages);
                    if (matchingPackages.length > 0) {
                        if (!state.packageFilter) {
                            state.packageFilter = new PackageFilter();
                        }
                        let distinctPC = matchingPackages.map(p => p.packageClassId).filter((age, index, arr) => arr.indexOf(age) == index);
                        state.packageFilter.FilteredPackageClasses = environment.PackageClasses.filter(cls => cls.Id != "0" && distinctPC.indexOf(cls.Id) >= 0);
                        if (!state.packageFilter.Package || matchingPackages.findIndex(selectedP => selectedP.id == state.packageFilter.Package) < 0) {
                            // state.packageFilter.Package = matchingPackages[0].id;
                            // state.packageFilter.PackageDetail = matchingPackages[0];
                            state.packageFilter.PackageClass = matchingPackages[0].packageClassId;
                            state.packageFilter.BussinessAreaId = matchingPackages[0].businessAreaId;
                        }
                    }
                }


               let  _isAllImmediateConfirmed =isImmediateBooking(state);

          return { ...state, addedFacilites: _fc, isAllImmediateConfirmed: _isAllImmediateConfirmed, priceChangedFacilities: priceChangefc,  LastUpdatedTime: new Date() };

            }

        case CartActionTypes.UPDATE_FACILITY:
            {
                let _fc = state.addedFacilites == undefined ? [] : state.addedFacilites;
                let _isAllImmediateConfirmed =isImmediateBooking(state);

                let _obj = _fc.find(x => x.index == action.payload.index);
                if (_obj != undefined) {
                    _obj = action.payload;
                }

                return { ...state, addedFacilites: _fc, isAllImmediateConfirmed: _isAllImmediateConfirmed,  LastUpdatedTime: new Date() };

            }
        case CartActionTypes.EDIT_FACILITY:
            {
                let _fc = state.addedFacilites == undefined ? [] : state.addedFacilites;

                let index = _fc.findIndex(x => x.index == action.payload.index);
                if (index != undefined) {
                    _fc.splice(index, 1);
                }

                let _isAllImmediateConfirmed =isImmediateBooking(state);
                return { ...state, addedFacilites: _fc, isAllImmediateConfirmed: _isAllImmediateConfirmed,  LastUpdatedTime: new Date() };
            }
        case CartActionTypes.UPDATE_FACILITY_PRICE:
            {

                let _fcEdit = state.addedFacilites == undefined ? [] : JSON.parse(JSON.stringify(state.addedFacilites));
                let _objs = _fcEdit.find(x => x.index == action.payload.index);
                if (_objs != undefined) {
                    _objs = action.payload;

                }

                return { ...state, priceChangedFacilities: _fcEdit, LastUpdatedTime: new Date()};
            }
        case CartActionTypes.DELETE_FACILITY_PRICE:
            {

                let _dfc = state.priceChangedFacilities == undefined ? [] : state.priceChangedFacilities;

                let _index = _dfc.findIndex(x => x.index == action.payload.index);
                if (_index != undefined) {
                    _dfc.splice(_index, 1);
                }

                return { ...state, priceChangedFacilities: _dfc, LastUpdatedTime: new Date() };
            }

        case CartActionTypes.UPDATE_FACILITY_UPSELL_FLAG:
            {
                let _fc = state.addedFacilites == undefined ? [] : state.addedFacilites;
                let _obje = _fc.find(x => x.index == action.payload.index);
                if (_obje != undefined) {
                    _obje = action.payload;
                }

                //var priceChangefc = state.priceChangedFacilities == undefined ? [] : state.priceChangedFacilities;
                //let priceChangeobje = priceChangefc.find(x => x.index == action.payload.index);
                //if (priceChangeobje != undefined) {
                //    priceChangeobje = action.payload;
                //}

                return { ...state, addedFacilites: _fc, };
            }
        case CartActionTypes.UPDATE_FACILITIES:
        return { ...state, addedFacilites: action.payload, LastUpdatedTime: new Date() };
        case CartActionTypes.UPDATE_PACKAGES:
            {

            let _defP = action.payload.find(p =>
                p.businessAreaId == environment.DefaultBussinessAreaId &&
                p.packageClassId == environment.DefaultPackageClassId);

            let _packageFilter = new PackageFilter();
            _packageFilter.BussinessAreaId = environment.DefaultBussinessAreaId;
            //packageFilter.PackageClass = environment.DefaultPackageClassId;
            //let selectedPackageClass = environment.PackageClasses.find(a => a.Id == this.selectedClass);
            let _pkf = (state.packageFilter == null) ? _packageFilter : state.packageFilter;
            (_pkf.BussinessAreaId == null) ? _pkf.BussinessAreaId = environment.DefaultBussinessAreaId : null;
            return { ...state, bespokePackages: action.payload, defaultPackage: _defP, bookingPackage: _defP, packageFilter: _pkf, LastUpdatedTime: new Date() };
            }
        //case CartActionTypes.UPDATE_PACKAGES:
        //    {
        //        var fc = state.addedFacilites == undefined ? [] : state.addedFacilites;
        //        var isAllImmediateConfirmed = false;
        //        let immediateConfirmationFacilityList = [];
        //        immediateConfirmationFacilityList = fc.filter(facility => facility.item.allowImmediateConfirmedBookings == 1);
        //        if (environment.ImmediateConfirmation.Allow && immediateConfirmationFacilityList.length > 0 && fc.length == immediateConfirmationFacilityList.length)
        //            isAllImmediateConfirmed = true;

        //    var defP = action.payload.find(p =>
        //        p.businessAreaId == environment.DefaultBussinessAreaId &&
        //        p.packageClassId == environment.DefaultPackageClassId);

        //    var packageFilter = new PackageFilter();
        //    packageFilter.BussinessAreaId = environment.DefaultBussinessAreaId;
        //    //packageFilter.PackageClass = environment.DefaultPackageClassId;
        //    //let selectedPackageClass = environment.PackageClasses.find(a => a.Id == this.selectedClass);
        //    var pkf = (state.packageFilter == null) ? packageFilter : state.packageFilter;
        //    (pkf.BussinessAreaId == null) ? pkf.BussinessAreaId = environment.DefaultBussinessAreaId : null;
        //    if (state.packageFilter.PackageDetail) {
        //        isAllImmediateConfirmed = (isAllImmediateConfirmed && state.packageFilter.PackageDetail.AllowImmediateConfirmedBookings == 1);
        //    }
        //    return { ...state, bespokePackages: action.payload, isAllImmediateConfirmed: isAllImmediateConfirmed ,defaultPackage: defP, bookingPackage: defP, packageFilter: pkf, LastUpdatedTime: new Date()};
        //    }
      case CartActionTypes.ERROR_LOG:
        return { ...state, LastUpdatedTime: new Date() };

        case CartActionTypes.UPDATE_CART:
        return { ...state, booking: action.payload, LastUpdatedTime: new Date()};
        case CartActionTypes.COMPLETE_BOOKING:
            let bk = new Booking();
            bk.bookingReference = state.booking.bookingReference;
        return { ...state, booking: bk, addedFacilites: [], packageFilter: null, AddedDocuments: [], priceChangedFacilities: [], LastUpdatedTime: null,isImmediateBooking:false };


        case CartActionTypes.UPDATE_BOOKING_EVENT_NAME:{
            let _pkf = (state.packageFilter == null) ? (new PackageFilter()) : state.packageFilter;
            _pkf.EventName = action.payload;
        return { ...state, packageFilter: _pkf, LastUpdatedTime: new Date() };
        }
        case CartActionTypes.UPDATE_BOOKING_PAKAGE:
           {
            let _packageFilter = new PackageFilter();
            _packageFilter.BussinessAreaId = action.payload.BussinessAreaId;
            _packageFilter.PackageClass = action.payload.PackageClass
            _packageFilter.EventName = action.payload.EventName
            _packageFilter.PackageDetail = action.payload.PackageDetail
            _packageFilter.Package = action.payload.Package
            _packageFilter.FilteredPackageClasses = action.payload.FilteredPackageClasses
            _packageFilter.ledgerNumber = action.payload.ledgerNumber;
            let _isAllImmediateConfirmed =isImmediateBooking(state);

            return { ...state, isAllImmediateConfirmed: _isAllImmediateConfirmed, packageFilter: _packageFilter, LastUpdatedTime: new Date()};
           }
        case CartActionTypes.POST_INVOICE:
        return { ...state, booking: action.payload, LastUpdatedTime: new Date()};

        case CartActionTypes.UPDATE_BUSINESS_AREA:
        return { ...state, LastUpdatedTime: new Date() }
        case CartActionTypes.SET_IMMEDIATE_DATA:
        return { ...state, booking: action.payload, LastUpdatedTime: new Date() }
        case CartActionTypes.CALCULATE_CART_PRICES:
        return { ...state, LastUpdatedTime: new Date()}
        case CartActionTypes.MAKE_PAYMENT:
        return { ...state, LastUpdatedTime: new Date() }
        case CartActionTypes.CLEAR_CART:
          let booking = new Booking();
            return { ...state, booking: booking, addedFacilites: [], packageFilter: null, AddedDocuments: [], priceChangedFacilities: [], LastUpdatedTime: null, InternalUserData: null, isAllImmediateConfirmed: false };
          
        case CartActionTypes.SAVE_BOOKING:
            if (action.payload)
                return { ...state, booking: action.payload, LastUpdatedTime: new Date() };
            else
                return { ...state,  LastUpdatedTime: new Date() };
        case CartActionTypes.POST_BOOKING_DATA:
            return { ...state, LastUpdatedTime: new Date() };
        case CartActionTypes.STORE_CUSTOM_FUNCTIONS:
        return { ...state,userDefinedFunctions:action.customFunctions };
        case CartActionTypes.SHOW_PACKAGE_QUESTION_POPUP:
          return { ...state,showPackageQuestionPopup:true };
        case CartActionTypes.HIDE_PACKAGE_QUESTION_POPUP:
          return { ...state,showPackageQuestionPopup:false };
        case CartActionTypes.RESET_BOOKING:
            let _bk = new Booking();
            return { ...state, booking: _bk, LastUpdatedTime: new Date() };
        default:
            return { ...state };
    }


}

function isImmediateBooking(state:ICart)
{
    let _isAllImmediateConfirmed = false;

    let _fc = state.addedFacilites == undefined ? [] : state.addedFacilites;
    let _immediateConfirmationFacilityList = [];
    _immediateConfirmationFacilityList = _fc.filter(facility => facility.item.allowImmediateConfirmedBookings == 1);
    if (environment.ImmediateConfirmation.Allow
        //All item immediate
        && _immediateConfirmationFacilityList.length > 0 && _fc.length == _immediateConfirmationFacilityList.length
        //selected package is immediate
        && (state.packageFilter && state.packageFilter.PackageDetail && state.packageFilter.PackageDetail.AllowImmediateConfirmedBookings == 1)
        )
         {
            _isAllImmediateConfirmed = true;
         }
    return _isAllImmediateConfirmed;
}
