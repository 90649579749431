<div class=".price-range">
  <div class="mmc-modal-header mmc_flex--container">
    <div class="modal-title">
      {{selectedPriceConfigurationOption?.item?.name}} - {{selectedPriceConfigurationOption?.priceLabel}}
    </div>
    <div class="mmc_flex__item--inherit mmc_flex__item--vcenter close-icon-cage mmc_just-content-center">
      <button mat-button [mat-dialog-close]="true"><i class="material-icons">close</i></button>
    </div>
  </div>
  <div class="mmc-modal-body">
    <div class="mmc_flex--container expansion-price-wrapper">
      <div class="left-section-expansion-pannel">
        <mat-accordion class="mat-accordion">
          <mat-expansion-panel class="mat-expansion-panel mat-expanded mat-expansion-panel-spacing" [expanded]="true">
            <mat-expansion-panel-header role="button"
              class="mat-expansion-panel-header mat-focus-indicator mat-expansion-toggle-indicator-after mat-expanded"
              id="mat-expansion-panel-header-3">
              <span class="mat-content">
                <mat-panel-title class="mat-expansion-panel-header-title">
                  <div class="pw-txt-b3 option-card-wrapper d-flex align-items-center custom-title-wrapper">
                    <div class="d-flex icon-with-text">
                      <div class="pw-txt-b3 option-card-name"> {{priceConfigurationOptions?.length}} more option available </div>
                    </div>
                  </div>
                </mat-panel-title>
              </span>
            </mat-expansion-panel-header>
            <ng-container *ngFor="let priceConfigurationOption of priceConfigurationOptions;">
              <div class="venue-price-wrapper">
                <div class="venue-opt pw-txt-b3">
                  <mat-radio-button (change)="changePriceConfigurationOption(priceConfigurationOption)" [checked]="selectedPriceConfigurationOption?.item?.id == priceConfigurationOption?.item?.id" [value]="priceConfigurationOption?.item.id">{{priceConfigurationOption?.item?.name}}</mat-radio-button>
                </div>
                <div class="price-time-wrapper pw-txt-b3">
                  <div class="price">
                    {{priceConfigurationOption?.priceLabel}}
                  </div>
                  <div class="time">
                    {{priceConfigurationOption?.priceTypeLabel}}
                  </div>
                </div>
              </div>
            </ng-container>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>
