import { VenueMap } from "./VenueMap";
import { Configuration } from "./Configuration";
import { Block, MousePosition, PriceBand, Style } from "./CommonDTOs";
import { BaseResource } from "src/modules/models/base-resource";
import { BOOKING_FLOW_OPTION } from "../../components/layout/models/enums";

export class Event {
  public id: string;
  public name: string;
  public venueMap: VenueMap;
  public configurations: Configuration[];
  public date: Date;
  public restrictSingleSeat: boolean;
  public eventCategoryId : string;
  public bookingFlowOptionId: BOOKING_FLOW_OPTION;

  initialize(d) {
    this.id = d.id;
    this.name = d.name;
    this.venueMap = new VenueMap().initialize(d.venueMapLayout, d.primaryVenue);
    this.date = new Date();
    this.restrictSingleSeat = d.restrictSingleSeat ? d.restrictSingleSeat : false;
    this.eventCategoryId = d.eventCategoryId ? String(d?.eventCategoryId) : null;
    this.bookingFlowOptionId = d.bookingFlowOptionId ? d.bookingFlowOptionId : null;
    return this;
  }
}

export class EventDate {
  public id: string;
  public date: Date;
  public startTime: Date;
  public endTime: Date;

  initialize(d) {
    this.id = d.id;
    this.date = new Date(d.date);
    this.startTime = new Date(d.startTime);
    this.endTime = new Date(d.endTime);
    return this;
  }
}

export class TimeSlot {
  id: string;
  configurationId: string;
  configuration: Configuration;
  startTime: Date;
  endTime: Date;
  reserved: boolean;
  facilityId: string;
  eventConfigurationDetails: EventConfigurationDetail[];
  blocks: Block[];

  initialize(data) {
    this.id = data.id;
    this.startTime = data.startTime;
    this.endTime = data.endTime;
    this.reserved = data.reserved;

    let eventBlocks = [];
    let eventRows = [];

    data.eventConfigurationDetails?.forEach(eventConfig => {
      if (eventConfig?.eventBlock != undefined && eventConfig?.eventBlock?.id != "" && eventBlocks.findIndex(el => el.id === eventConfig?.eventBlock.id) == -1) {
        eventConfig.eventBlock.isPrivate = Boolean(eventConfig.eventBlock.private)
        eventBlocks.push(eventConfig?.eventBlock);
      }
      if (eventConfig?.eventRow != undefined && eventConfig?.eventRow?.id != "" && eventRows.findIndex(el => el.id === eventConfig?.eventRow.id) == -1) {
        eventRows.push(eventConfig?.eventRow);
      }
    });

    if (data.configuration) {
      this.configurationId = data.configuration.id;
      data.configuration.blocks = eventBlocks;
      data.configuration.rows = eventRows;
      data.configuration.seats = data.eventConfigurationDetails;
      this.configuration = new Configuration().initialize(
        data.configuration,
        data.asset.id
      );
    }
    this.facilityId = data.asset.id;

    /*if (data.eventConfigurationDetails) {
      this.eventConfigurationDetails = data.eventConfigurationDetails.map((e) =>
        new EventConfigurationDetail().initialize(e)
      );
    }*/
    return this;
  }
}

export class EventConfigurationDetail extends BaseResource {
  reserved: boolean;
  sequenceNumber: number;
  width: number;
  length: number;
  unitOfMesurementId: number;
  centerPoint;
  sideStartPoint?: MousePosition;
  sideEndPoint?: MousePosition;
  radius;
  hasAllocation: boolean
  priceBand?: PriceBand;
  priceBandId?: string;
  style: Style;
  name: string;
  angle: number;
  id: string;

  initialize(d) {
    this.id = d.id;
    this.radius = 0;
    this.style = new Style();
    this.centerPoint = new MousePosition();
    this.angle = d.angle;
    this.unitOfMesurementId = d.unitOfMesurementId;
    this.length = d.length;
    this.hasAllocation = d.hasAllocation;
    this.width = d.width;
    this.sequenceNumber = d.sequenceNumber;
    this.reserved = d.reserved;
    this.name = d.name;
    this.priceBand = d.priceBand;
    this.priceBandId = d.priceBand?.id;
    // this.id = 'seat_' + seat.id;
    // this.centerPoint.x = seat.xPosition;
    // this.centerPoint.y = seat.yPosition;
    // this.priceBand = PRICE_BAND[1];
    // this.radius = r;
    // this.name = seat.name;
    if (d.layout) {
    const obj = !!d.layout && JSON.parse(d.layout);
    }
    return this;
  }
}

export class PublicEvent {
  public id: string;
  public name: string;
  public primaryVenueName: string;
  public eventDates;
  public hasTicketing: boolean;
  public shortWebDescription: string;
  public eventStartDate: Date;
  public eventEndDate: Date;

  initialize(d) {
    this.id = d.id;
    this.name = d.name;
    this.primaryVenueName = (d.primaryVenue && d.primaryVenue.name) ? d.primaryVenue.name : "";
    this.eventDates = d.eventDates ? d.eventDates : null;
    this.hasTicketing = d.hasSeating ? d.hasSeating : false;
    this.shortWebDescription = d.shortWebDescription ? d.shortWebDescription : "";
    this.eventStartDate = new Date(d.eventStartDate);
    this.eventEndDate = new Date(d.eventEndDate);
    return this;
  }
}
