
export class WmPackage {
  Name: string;
  Active: boolean;
  Type: string;
  DefaultImageFile: string;
  Id: string;
  WebDescription: string;
  ShortWebDescription: string;
  WasteType: WmWasteType;
  CollectionDays: string;
  MeasureOption: WmMeasureOption[];
  Quantity: number;
  PackageDates: WmPackageDate[];
  PackageSessions: WmPackageSession[];
  PriceIncludingTax: number;
  
  constructor(obj) {
    this.Name = obj.Name;
    this.DefaultImageFile = obj.DefaultImageFile ? obj.DefaultImageFile : '';
    this.Active = obj.Active;
    this.Type = obj.Type;
    this.Id = obj.Id;
    this.WebDescription = obj.WebDescription;
    this.ShortWebDescription = obj.ShortWebDescription;
    this.PackageDates = obj.PackageDates;
    this.PackageSessions = obj.PackageSessions;
    this.PriceIncludingTax = obj.VariablePriceIncludingTax;
  }
}

export class WmWasteType {
  id: string;
  CollectionDates: WmCollectionDate[];
  DefaultPackageID: number;
  Name: string;
  Active: boolean;
  // ZoneId: string;
  MeasureOption: WmMeasureOption[];
  MeasureByCubicMeters: boolean;
  MeasureByCapacity: boolean;
  RestrictionDateOptionTypeId: number;
  RestrictionDateOptionUnits: number;
  FreeCollectionsPerBooking: number;          // how many free collection per booking
  MaxBookingsPerRestrictionPeriod: number;   //  how many bookings per week
  MaxCollectionsPerBooking: number;         // maximum Quantity per booking
  AllowPaidBookingsForMultiUnits: boolean;
  EnableAddressCategoryRestriction: boolean;
  IgnoreAddressMultiUnits: boolean;
  Sequence: number;

  constructor(obj?) {
    if (obj) {
      this.id = obj.Id;
      this.CollectionDates = obj.ZoneSettings;
      this.DefaultPackageID = obj.DefaultPackageID;
      this.Name = obj.Name;
      this.Active = obj.Active;
      // this.ZoneId = obj.ZoneId;
      this.MeasureOption = obj.MeasureOption;
      this.MeasureByCubicMeters = obj.MeasureByCubicMeters;
      this.MeasureByCapacity = obj.MeasureByCapacity;
      this.RestrictionDateOptionTypeId = obj.RestrictionDateOptionTypeId;
      this.RestrictionDateOptionUnits = obj.RestrictionDateOptionUnits;
      this.FreeCollectionsPerBooking =  obj.FreeCollectionsPerBooking;
      this.MaxBookingsPerRestrictionPeriod = obj.MaxBookingsPerRestrictionPeriod;
      this.MaxCollectionsPerBooking =  obj.MaxCollectionsPerBooking;
      this.AllowPaidBookingsForMultiUnits = obj.AllowPaidBookingsForMultiUnits;
      this.EnableAddressCategoryRestriction = obj.EnableAddressCategoryRestriction;
      this.IgnoreAddressMultiUnits = obj.IgnoreAddressMultiUnits;
      this.Sequence = obj.Sequence;
    }

  }
}

export class WmCollectionDate {
  id: string;
  CollectionDates: string;
  ZoneId: number;
  DateOptionUnits: number;
  DateOptionTypeID: number;

  constructor(obj) {
    this.id = obj.Id;
    this.CollectionDates = obj.CollectionDates;
    this.DateOptionUnits = obj.DateOptionUnits;
    this.DateOptionTypeID = obj.DateOptionTypeID;
  }
}

export class WmMeasureOption {
  Id: string;
  Quantity: number;
  Name: string;

  constructor(obj) {
    this.Id = obj.Id;
    this.Quantity = obj.Quantity;
    this.Name = obj.Name;
  }
}

export class WmPackageDate {
  Id: string;
  EventDate: WmEventDate;

  constructor(obj) {
    this.Id = obj.Id;
    this.EventDate = obj.eventDate;
  }
}

export class WmEventDate {
  Id: string;
  EndTime: string;
  StartTime: string;
  Date: string;

  constructor(obj) {
    this.Id = obj.Id;
    this.EndTime = obj.EndTime;
    this.StartTime = obj.StartTime;
    this.Date = obj.Date;
  }

}

export class WmPackageSession {
  Availability: number;
  Capacity: number;
  StartTime: string;
  EndTime: string;

  constructor(obj) {
    this.Availability = obj.Availability;
    this.Capacity = obj.Capacity;
    this.StartTime = obj.StartTime;
    this.EndTime = obj.EndTime;
  }
}

export enum CollectDays {
  Monday = 2,
  Tuesday = 3,
  Wednesday = 4,
  Thursday = 5,
  Friday = 6,
  Saturday = 7,
  Sunday = 1
}

export class WmCollectionStatus {
  Id: string;
  Name : string;
  ColorCode: string;

  constructor(obj) {
    this.Id = obj.Id;
    this.Name = obj.Name;
    this.ColorCode =  obj.ColorCode;
  }
}

