<div class="mmc-modal-wrapper add-extras-modal">
    <div class="mmc-modal-header mmc_flex--container">
        <div class="mmc_flex__item--inherit mmc_flex__item--vcenter icon-cage mmc_just-content-center">
            <i class="material-icons">star_border</i>
        </div>
        <div class="mmc_flex__item mmc_flex__item--vcenter">
            <span class="section-title">Add Extras</span>
        </div>
        <div class="mmc_flex__item--inherit mmc_flex__item--vcenter close-icon-cage mmc_just-content-center">
            <button mat-button [mat-dialog-close]="true" tabindex="0"><i class="material-icons">close</i></button>
        </div>
    </div>
    <div class="mmc-modal-body">
        <div class="row">
            <div class="col-sm-12">
                <div class="main_search">
                    <div class="mmc_flex--container">
                        <div class="mmc_flex__item search-field">
                            <mat-form-field class="pw-txt-h4">
                                <input matInput placeholder="Search for extras" value="">
                            </mat-form-field>
                        </div>
                        <div class="mmc_flex__item search-options">
                            <div class="mmc_flex--container">
                                <div class="mmc_flex__item search-date">
                                    <div class="mmc_flex--container">
                                        <div class="mmc_flex__item">
                                            <div class="font__small opt-txt__caption">Select Date</div>
                                            <span class="selected-date">All</span>
                                        </div>
                                        <div class="mmc_flex__item mmc_just-content-end">
                                            <span class="down-arrow" [matMenuTriggerFor]="dateMenu"><i class="material-icons">keyboard_arrow_down</i></span>
                                            <mat-menu class="date-select-menu" #dateMenu="matMenu">
                                                <span class="font__semibold">Select Dates</span>
                                                <div class="all">
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <mat-checkbox>All</mat-checkbox>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="date-select-row">
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <mat-checkbox class="year">2018</mat-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <mat-checkbox class="month">Jan</mat-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div><mat-checkbox class="day">10th Jan</mat-checkbox></div>
                                                            <div><mat-checkbox class="day">11th Jan</mat-checkbox></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="date-select-row">
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <mat-checkbox class="year">2019</mat-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <mat-checkbox class="month">Jan</mat-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div><mat-checkbox class="day">10th Jan</mat-checkbox></div>
                                                            <div><mat-checkbox class="day">11th Jan</mat-checkbox></div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <mat-checkbox class="month">Feb</mat-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div><mat-checkbox class="day">10th Feb</mat-checkbox></div>
                                                            <div><mat-checkbox class="day">11th Feb</mat-checkbox></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </mat-menu>
                                        </div>
                                    </div>
                                </div>
                                <div class="mmc_flex__item search-category">
                                    <mat-form-field>
                                        <mat-label>Item Category</mat-label>
                                        <mat-select>
                                            <mat-option>Any</mat-option>
                                            <mat-option>a</mat-option>
                                            <mat-option>b</mat-option>
                                            <mat-option>c</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="mmc_flex__item search-btn btn--search">
                                    <i class="material-icons">
                                        search
                                    </i>
                                    <span class="btn-text">Search</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="font__small items-available-label opt-txt__caption font__semibold">13 items available</div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="inner-container">
                    <div class="mmc_card search--result">
                        <div class="mmc_flex--container mobile-card">
                            <div class="mmc_flex__item image-cage">
                                <a>
                                    <img class="img" src="dist/assets/images/no-image.png"/>
                                    <div class="overlay"></div>
                                </a>
                                <a>
                                    <img class="img"/>
                                    <div class="overlay"></div>
                                </a>
                            </div>
                            <div class="mmc_flex__item content">
                                <div class="mmc_flex--container">
                                    <div class="mmc_flex__item">
                                        <div class='item_name'>
                                            <div class="pw-txt-h3_small pw-text-color__primary pw-text-semi-bold">CATERING PACKAGE #01 INDIAN MENU - LINCOLN EVENTS</div>
                                        </div>
                                        <div class='item_address opt-txt__caption'>
                                            <div class="mmc_flex--container ">
                                                <div class="mmc_flex__item--vcenter">Package inclusions - Food and beverages, venu hire, AV equipment, chair covers, bows, table decors.
                                          
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mmc_flex__item--inherit mmc_just-content-center mmc_fd--col quentity">
                                        <label class="qty font__semibold">QTY</label>
                                        <div class="quentity-increase">
                                            <button mat-raised-button tabindex="0">-</button>
                                            <mat-form-field class="qty-input">
                                                <input matInput value="1">
                                            </mat-form-field>
                                            <button mat-raised-button tabindex="0">+</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mmc_flex__item butn__cage">
                                <div class="price">
                                    <span class="per-item__amount font__semibold">$95/Item</span>
                                    <span class="total__amount">$190.00</span>
                                </div>
                                <button mat-button class="mmc-btn-primary mmc_flex__item--vcenter" tabindex="0">ADD</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>