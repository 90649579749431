import { CurrencyPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { environment } from "src/environments/environment";
import { PublicBookingSettings, RegionalSetting } from "src/modules/models/settings/public-booking/public-booking-setting";

@Pipe({
  name: 'PBCurrencyPipe'
})
export class PBCurrencyPipe implements PipeTransform {
  symbol: string;
  mainFraction: number;
  maxFraction: number;
  minDigits: number;
  regionalSetting: RegionalSetting;

  constructor(private currencyPipe: CurrencyPipe) {
    this.regionalSetting = (environment.PublicBookingSetting as PublicBookingSettings).regionalSetting;
    if (this.regionalSetting) {
      this.symbol = this.getCurrencyFormatFromXMLSetting(
        this.regionalSetting.defaultCurrencyFormat
      );
    }
  }
  transform(value: any, symbol = true) {
    if (value !== undefined && value != null && !isNaN(value)) {
      value = Math.round(value * 1000) / 1000;
      if (symbol) {
        if (this.regionalSetting) {
          if (value == 0) {
            return this.currencyPipe.transform(value, this.regionalSetting.defaultCurrencySymbol, 'symbol', '1.2-2');
          } else
            if (value < 100) {
              return this.currencyPipe.transform(value, this.regionalSetting.defaultCurrencySymbol, 'symbol',
                this.minDigits + '.' + this.mainFraction + '-' + this.maxFraction);
            }
          return this.currencyPipe.transform(value, this.regionalSetting.defaultCurrencySymbol, 'symbol', this.symbol);
        }
      } else {
        return this.currencyPipe.transform(value, '', '', '1.2-2');
      }
      // return this.currencyPipe.transform(value, environment.Currency, 'symbol', '1.2-2');
    }
  }
  getCurrencyFormatFromXMLSetting(format) {
    this.mainFraction = format.substring(format.lastIndexOf('.') + 1).length;
    this.maxFraction = format.substring(format.lastIndexOf('.') + 1).length;
    const maxDigits = format.substring(format.lastIndexOf(format[0]) + 1, format.lastIndexOf(',')).length;
    this.minDigits = format.substring(format.lastIndexOf(',') + 3, format.lastIndexOf('.')).length;
    return maxDigits + '.' + this.mainFraction + '-' + this.maxFraction;
  }
}
