

<ng-container >
  <div class="top-title-facility-name-time week-time " *ngFor="let facility of facilities">

    <div class="top-title-facility-name facility-name-items">
      <p>
        <a href="#/facility-detail?StartDate={{searchTime.startTime}}&&EndDate={{searchTime.endTime}}&&AssetId={{facility.id}}&&Attendees=0">
          {{facility?.name}}
        </a>
      </p>
      <div class="wp_flex__item price-icon-wrapper" >
        <div class="wp-txt-body1--small wp-txt--link  price-label">{{facility?.minPrice?.priceIncludingTax
          | CurrencyFormat}} - {{facility?.maxPrice?.priceIncludingTax | CurrencyFormat}}
        </div>
        <div class="info-icon">
          <!-- <mat-icon (click)="openPriceConfigurationPopup(facility)">info</mat-icon> --> 
          <mat-icon (click)="openPriceConfigurationPopup(facility)">expand_circle_down</mat-icon>
        </div>
      </div>
      <div class="people">
        {{facility?.capacity}} people
      </div>
      <div class="people">
        Open: {{facility?.openingTime |dateFormat:'time'}} - Closes :{{facility?.closingTime |dateFormat:'time'}}
      </div>
    </div>



    <!-- [(startAt)]="bookingDate"
    [dateClass]="mobileDates"
    [minDate]="mobileMinDate"
    (selectedChange)="DateChanged($event, facility)" -->
    <!-- [(selected)]="mobilebookingDate"
    {{bookingDate}} -->
    <mat-calendar
    #calendar
    [(startAt)]="bookingDate"
    [dateClass]="dateClass(facility)"
    [minDate]="mobileMinDate"
    (selectedChange)="DateChanged($event, facility)"
    >
    </mat-calendar>

  </div>
</ng-container>
<!-- <button (click)="changeDate()">Change date</button>

<mat-calendar #calendar [selected]="date"></mat-calendar> -->
