<div class="mmc-modal-wrapper guest-checkout-modal">
  <div class="mmc-modal-header mmc_flex--container g-header" style="height: 45px;background: #00507c;">
    <div class="mmc_flex__item mmc_flex__item--vcenter pl-3 g-header-title" style="color: white;font-size: 20px;">
      <span class="pw-txt-h3_small pw-text-semi-bold" style="color: white;font-size: 20px;">{{'EXIST_GUEST_MAIL.Email already exists'|optimotranslate}}</span>
    </div>
    <div class="close-btn" style="align-items:center;display:flex;padding-right: 13px;justify-content: center;">
      <mat-icon style="color: white;display: flex;align-items: center;cursor: pointer;justify-content: flex-end;"(click)="dialogRef.close()">close</mat-icon>
    </div>
  </div>
  <div class="mmc-modal-body px-5" style="padding: 10px 15px !important;overflow-y: auto;">
   <div class=" pw-text-semi-bold">
     {{'EXIST_GUEST_MAIL.The email address you entered got already registered a standard client account. Please log in to continue.If it is not you, please reset the password'|optimotranslate}}
   </div>
  </div>
  <div class="mmc-modal-footer p-2" style="margin-top: 30px;">
    <div class="mmc_flex--container mmc_just-content-end" style="margin-right: 10px !important;">
      <button mat-button class="mmc-btn-primary mr-2" (click)="login()">
        {{'EXIST_GUEST_MAIL.Log in'|optimotranslate}}
      </button>
      <button mat-button class="mmc-btn-primary"  (click)="forgetPassword()">
        {{'EXIST_GUEST_MAIL.Reset Password'|optimotranslate}}
      </button>
    </div>
  </div>
