<div class='mmc_card'>
  <div class="three-tab-cal-container">
    <div class="date-wrapper">
      <div class="forw-back-icon">
        <button class="date-forw-btn"  (click)="calendarDateNavigate(calenderNavigateTypeEnum.Previous)">
          <mat-icon>arrow_back_ios</mat-icon>
        </button>
        <button class="date-back-btn" (click)="calendarDateNavigate(calenderNavigateTypeEnum.Next)">
          <mat-icon>arrow_forward_ios</mat-icon>
        </button>
      </div>
      <div class="day-date-month-cal-wrapper">
        <div [ngSwitch]="selectedCalenderViewType">
          <div *ngSwitchCase="calenderViewTypeEnum.DayView">
            {{ bookingDate | date:'EE d MMM, y'}}
          </div>
          <div *ngSwitchCase="calenderViewTypeEnum.WeekView">
            {{ weekStateDate | date:'MMM dd'}} - {{ weekEndDate | date:'dd, y'}}
          </div>
          <div *ngSwitchCase="calenderViewTypeEnum.MonthView">
            {{ bookingDate | date:'MMMM y'}}
          </div>
          <div *ngSwitchDefault></div>
        </div>
      </div>
    </div>
    <div class="three-tab-cal-main-wrapper">
      <div class="mmc_flex__item--vcenter three-tab-wrapper">
        <mat-button-toggle-group #group="matButtonToggleGroup" [(ngModel)]="selectedCalenderViewType">
          <mat-button-toggle *ngFor="let calendarViewType of calendarViewTypeList" [value]="calendarViewType"
            class="map-toggale map-icon-word" (click)="changeSelectedView(calendarViewType)">
            {{calendarViewType}}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

    </div>
  </div>
  <div class="showInWeb">
    <div class="web-inner-container">
    <ng-container *ngIf="selectedCalenderViewType === calenderViewTypeEnum.DayView">
      <div class="cal-date"> {{ bookingDate | date:'EEEE, MMMM d, y'}} </div>
      <div class="main-wrapper">
        <div class="top-title-facility-name-time">
          <div class="top-title-facility-name">
          </div>
          <div class="inner-time-slot day-inner-time">
            <div class="row-top-time-slot">
              <ng-container *ngFor="let hour of hours;let i = index">
                <div class="row-main-time-slot">
                  {{hour?.date | date: 'HH'}}
                </div>
              </ng-container>
            </div>
          </div>
        </div>

          <ng-container *ngFor="let facility of facilities">
            <div class="top-title-facility-name-time day-top-title-facility-name-time">
              <div class="top-title-facility-name facility-name-items">
                <p>
                  <a
                    href="#/facility-detail?StartDate={{searchableTime.startTime}}&&EndDate={{searchableTime.endTime}}&&AssetId={{facility.id}}&&Attendees=0">
                    {{facility?.name}}
                  </a>
                </p>
                <div class="wp_flex__item price-icon-wrapper">
                  <div class="price" *ngIf="!isConfigurationEnabled">
                    <div class="wp-txt-body1--small wp-txt--link  price-label" *ngIf="facility.isPriceRange()">
                      {{facility.minPrice?.priceIncludingTax | CurrencyFormat}} - {{facility.maxPrice?.priceIncludingTax
                      | CurrencyFormat}}</div>
                    <div class="wp-txt-body1--small wp-txt--link  price-label"
                      *ngIf="!facility.isPriceRange() && facility.getPrice()">{{facility.getPrice()?.priceIncludingTax |
                      CurrencyFormat}}</div>
                    <div class="wp-txt-body1--small wp-txt--link  price-label" *ngIf="!facility.getPrice()">Prices are
                      not defined</div>
                  </div>
                  <div class="price" *ngIf="isConfigurationEnabled && facility.item">
                    <div class="wp-txt-body1--small wp-txt--link  price-label" *ngIf="facility.isItemPriceRange()">
                      {{facility.item?.itemMinPrice?.priceIncludingTax | CurrencyFormat}} -
                      {{facility.item?.itemMaxPrice?.priceIncludingTax | CurrencyFormat}}</div>
                    <div class="wp-txt-body1--small wp-txt--link  price-label"
                      *ngIf="!facility.isItemPriceRange() && facility.getItemPrice()">
                      {{facility.getItemPrice(facility.item)?.priceIncludingTax | CurrencyFormat}}</div>
                    <div class="wp-txt-body1--small wp-txt--link  price-label" *ngIf="!facility.getItemPrice()">Prices
                      are not defined</div>
                  </div>

                <div class="info-icon">
                  <!-- <mat-icon (click)="openPriceConfigurationPopup(facility)">info</mat-icon> --> 
                  <mat-icon (click)="openPriceConfigurationPopup(facility)">expand_circle_down</mat-icon>
                </div>
              </div>
              <div class="people">
                {{facility?.capacity}} people
              </div>

              <!-- <div>
                <small><span>Open: {{facility?.openingTime |dateFormat:'time'}} - Closes :{{facility?.closingTime |dateFormat:'time'}}</span></small>
              </div> -->
            </div>
            <div class="inner-time-slot facility-time-items"  [ngClass]="{'disablecurser' : !facility.getItemPrice()}">
              <ng-container *ngFor="let hour of hours;">
                <div class="row-main-time-slot">
                  <ng-container *ngFor="let segment of hour.hourSegments">
                    <div class="sub-inner-time-slot" [ngStyle]="{'backgroundColor':setDayAvailabilityStyle(facility, segment)}"
                      (mousedown)="rangeMouseDown($event, facility, hour, segment);$event.stopPropagation();"
                      (mouseover)="rangeMouseMove($event, facility, hour, segment)" (mouseup)="rangeMouseUp($event, facility, segment)"
                      >
                      <!-- (click)="testClickDay(facility, segment)"> -->
                      <ng-container *ngIf="selectedBookingSlots[facility?.id] && selectedBookingSlots[facility?.id]?.length">
                        <div class="test" *ngIf=" findselectedBookingslots(selectedBookingSlots[facility?.id],segment)">
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="selectedCalenderViewType === calenderViewTypeEnum.WeekView">
      <div class="main-wrapper">
        <div class="top-title-facility-name-time week-top-title-facility-name-time">
          <div class="week-top-title-facility-name top">
          </div>
          <div class="inner-time-slot">
            <div class="row-top-time-slot-week">
              <ng-container *ngFor="let weekDay of weekDays;let i = index">
                <div class="week-row-main-time-slot week-row-main-time-slot-top" [ngClass]="{'current-date-select': weekDay.date.toDateString() === today.toDateString()}">
                  <div class="week-day">{{weekDay?.date | date: 'd'}}</div>
                  <div class="week-date">{{weekDay?.date | date: 'EE'}}</div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <ng-container *ngFor="let facility of facilities">
          <div class="top-title-facility-name-time">
            <div class="week-top-title-facility-name facility-name-items">
              <p>
                <a href="#/facility-detail?StartDate={{searchableTime.startTime}}&&EndDate={{searchableTime.endTime}}&&AssetId={{facility.id}}&&Attendees=0">
                  {{facility?.name}}
                </a>
              </p>
              <div class="wp_flex__item price-icon-wrapper" >
                <div class="price" *ngIf="!isConfigurationEnabled">
                  <div class="wp-txt-body1--small wp-txt--link  price-label" *ngIf="facility.isPriceRange()">{{facility.minPrice?.priceIncludingTax | CurrencyFormat}} - {{facility.maxPrice?.priceIncludingTax | CurrencyFormat}}</div>
                  <div class="wp-txt-body1--small wp-txt--link  price-label" *ngIf="!facility.isPriceRange() && facility.getPrice()">{{facility.getPrice()?.priceIncludingTax | CurrencyFormat}}</div>
                  <div class="wp-txt-body1--small wp-txt--link  price-label" *ngIf="!facility.getPrice()">Prices are not defined</div>
              </div>
              <div class="price" *ngIf="isConfigurationEnabled && facility.item">
                  <div class="wp-txt-body1--small wp-txt--link  price-label" *ngIf="facility.isItemPriceRange()">{{facility.item?.itemMinPrice?.priceIncludingTax | CurrencyFormat}} - {{facility.item?.itemMaxPrice?.priceIncludingTax | CurrencyFormat}}</div>
                  <div class="wp-txt-body1--small wp-txt--link  price-label" *ngIf="!facility.isItemPriceRange() && facility.getItemPrice()">{{facility.getItemPrice(facility.item)?.priceIncludingTax | CurrencyFormat}}</div>
                  <div class="wp-txt-body1--small wp-txt--link  price-label" *ngIf="!facility.getItemPrice()">Prices are not defined</div>
              </div>
                <div class="info-icon">
                   <!-- <mat-icon (click)="openPriceConfigurationPopup(facility)">info</mat-icon> --> 
                   <mat-icon (click)="openPriceConfigurationPopup(facility)">expand_circle_down</mat-icon>
                </div>
              </div>
              <div class="people">
                {{facility?.capacity}} people
              </div>
              <!-- <div>
                <small><span>Open: {{facility?.openingTime |dateFormat:'time'}} - Closes :{{facility?.closingTime |dateFormat:'time'}}</span></small>
              </div> -->
            </div>
            <div class="inner-time-slot facility-time-items"  [ngClass]="{'disablecurser' : !facility.getItemPrice()}">
              <ng-container *ngFor="let weekDay of weekDays;let i = index">
                <div class="week-row-main-time-slot">
                  <div class="inner-slot-wrapper">
                    <ng-container *ngFor="let segment of weekDay.daySegments;let i = index">
                      <div class="inner-slots week-inner-slots"
                        [ngStyle]="{'backgroundColor':setWeekAvailabilityStyle(facility, segment)}"
                        (mousedown)="selectWeekTimeSlotMouseDown($event, facility, segment);$event.stopPropagation();"
                        (mouseover)="selectWeekTimeSlotMouseMove($event, facility, segment)"
                        (mouseup)="rangeMouseUp($event, facility, segment)">
                        <!-- (click)="testClickWeek(facility, segment)"> -->
                        <ng-container *ngIf="selectedBookingSlots[facility?.id] && selectedBookingSlots[facility?.id]?.length">
                          <ng-container >
                            <div class="test"
                              *ngIf=" findselectedBookingslots(selectedBookingSlots[facility?.id],segment)">
                              <!-- <div class="delete-timeslot-selection custom-delete-btn"
                              (click)="removeSelectedSlots(facility, true);$event.stopPropagation();"
                              (mousedown)="$event.stopPropagation();"
                              (mouseover)="$event.stopPropagation();"
                              (mouseup)="$event.stopPropagation();"
                              matTooltip="Delete Timeslot"
                                *ngIf="i === (selectedSlots.slots.length - 1)">
                                <i class="material-icons">delete</i>
                              </div> -->
                              </div>
                            </ng-container>
                          </ng-container>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>

      </ng-container>

    <ng-container *ngIf="selectedCalenderViewType === calenderViewTypeEnum.MonthView">
      <div class="main-wrapper">
        <div class="top-title-facility-name-time">
          <div class="top-title-facility-name month-main-facility">
          </div>
          <div class="inner-time-slot">
            <div class="row-top-time-slot">
              <ng-container *ngFor="let monthDay of monthDays;let i = index">
                <div class=" month-row-main-time-slot" [ngClass]="{'current-date-select': monthDay.date.toDateString() === today.toDateString()}">
                  <p>{{monthDay?.date | date: 'd'}} </p>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <ng-container *ngFor="let facility of facilities">
          <div class="top-title-facility-name-time">
            <div class="top-title-facility-name facility-name-items ">
              <p>
                <a href="#/facility-detail?StartDate={{searchableTime.startTime}}&&EndDate={{searchableTime.endTime}}&&AssetId={{facility.id}}&&Attendees=0">
                  {{facility?.name}}
                </a>
              </p>
              <div class="wp_flex__item price-icon-wrapper" >
                <div class="price" *ngIf="!isConfigurationEnabled">
                  <div class="wp-txt-body1--small wp-txt--link  price-label" *ngIf="facility.isPriceRange()">{{facility.minPrice?.priceIncludingTax | CurrencyFormat}} - {{facility.maxPrice?.priceIncludingTax | CurrencyFormat}}</div>
                  <div class="wp-txt-body1--small wp-txt--link  price-label" *ngIf="!facility.isPriceRange() && facility.getPrice()">{{facility.getPrice()?.priceIncludingTax | CurrencyFormat}}</div>
                  <div class="wp-txt-body1--small wp-txt--link  price-label" *ngIf="!facility.getPrice()">Prices are not defined</div>
              </div>
              <div class="price" *ngIf="isConfigurationEnabled && facility.item">
                  <div class="wp-txt-body1--small wp-txt--link  price-label" *ngIf="facility.isItemPriceRange()">{{facility.item?.itemMinPrice?.priceIncludingTax | CurrencyFormat}} - {{facility.item?.itemMaxPrice?.priceIncludingTax | CurrencyFormat}}</div>
                  <div class="wp-txt-body1--small wp-txt--link  price-label" *ngIf="!facility.isItemPriceRange() && facility.getItemPrice()">{{facility.getItemPrice(facility.item)?.priceIncludingTax | CurrencyFormat}}</div>
                  <div class="wp-txt-body1--small wp-txt--link  price-label" *ngIf="!facility.getItemPrice()">Prices are not defined</div>
              </div>
                <div class="info-icon">
                   <!-- <mat-icon (click)="openPriceConfigurationPopup(facility)">info</mat-icon> --> 
                   <mat-icon (click)="openPriceConfigurationPopup(facility)">expand_circle_down</mat-icon>
                </div>
              </div>
              <div class="people">
                {{facility?.capacity}} people
              </div>
              <div class="people">
                Open: {{facility?.openingTime |dateFormat:'time'}} - Closes :{{facility?.closingTime |dateFormat:'time'}}
              </div>
            </div>
            <div class="inner-time-slot facility-time-items month-facility-name-items"  [ngClass]="{'disablecurser' : !facility.getItemPrice()}">
              <ng-container *ngFor="let monthDay of monthDays;let i = index">
                <div class="row-main-time-slot" [ngStyle]="{'backgroundColor':setMonthAvailabilityStyle(facility, monthDay)}"
                 (click)="selectMonthTimeSlot(facility, monthDay)"><!-- ;testClick(facility, monthDay)"> -->
                  <!-- <div class="test" *ngIf="facility?.id === getSelectedMonthSlotsByFacility(facility)?.facilityId && monthDay.date.toDateString() === getSelectedMonthSlotsByFacility(facility)?.selectedMonthDay?.date.toDateString()"> -->
                    
                      <!-- <i class="material-icons">delete</i> -->
                    <ng-container *ngIf="selectedBookingSlots[facility?.id] && selectedBookingSlots[facility?.id]?.length">
                      <ng-container *ngFor="let selectedSlot of selectedBookingSlots[facility?.id]; let i = index">
                        <div class="test"
                          *ngIf="selectedSlot.date.toDateString() === monthDay.date.toDateString()">
                          <div class="delete-timeslot-selection custom-delete-btn" (click)="removeSelectedMonth();$event.stopPropagation();"
                            (mousedown)="$event.stopPropagation();"
                            (mouseover)="$event.stopPropagation();"
                            (mouseup)="$event.stopPropagation();" matTooltip="Delete Timeslot"></div>
                          <!-- <div class="delete-timeslot-selection custom-delete-btn"
                          (click)="removeSelectedSlots(facility, true);$event.stopPropagation();"
                          (mousedown)="$event.stopPropagation();"
                          (mouseover)="$event.stopPropagation();"
                          (mouseup)="$event.stopPropagation();"
                          matTooltip="Delete Timeslot"
                            *ngIf="i === (selectedSlots.slots.length - 1)">
                            <i class="material-icons">delete</i>
                          </div> -->
                        </div>
                      </ng-container>
                    </ng-container>
                  <!-- </div> -->
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    </div>
    <div class="btn-got-cart mt-4">
      <button type="button" [ngClass]="{'disable-div' :!(cartAddedFacilities?.length > 0)}" mat-button class="btn__card main mmc_flex__item--vcenter btn-cal-view-apply" role="button" (click)="cartAddedFacilities?.length > 0 && goToCart()" (keydown.enter)="cartAddedFacilities?.length > 0 && goToCart()" >GO TO CART</button>
    </div>
  </div>
  <div class="showInMobile">
    <div>
      <!-- mobile calender Dayview start -->
      <ng-container *ngIf="selectedCalenderViewType === calenderViewTypeEnum.DayView">
        <div class="day-mobile main-wrapper mmc_card" *ngFor="let facility of facilities;let fi = index">
          <ng-container>
            <div class="top-title-facility-name-time">
              <div class="top-title-facility-name facility-name-items">
                <a href="#/facility-detail?StartDate={{searchableTime.startTime}}&&EndDate={{searchableTime.endTime}}&&AssetId={{facility.id}}&&Attendees=0">
                  {{facility?.name}}
                </a>
                <div class="wp_flex__item price-icon-wrapper" (click)="openPriceConfigurationPopup(facility)">
                  <div class="wp-txt-body1--small wp-txt--link  price-label">{{facility?.minPrice?.priceIncludingTax
                    | CurrencyFormat}} - {{facility?.maxPrice?.priceIncludingTax | CurrencyFormat}}
                  </div>
                  <div class="info-icon">
                    <!-- <mat-icon>info</mat-icon> -->
                    <mat-icon>expand_circle_down</mat-icon>
                  </div>
                </div>
                <div class="people">
                  {{facility?.capacity}} people
                </div>
                <!-- <div>
                  <small><span>Open: {{facility?.openingTime |dateFormat:'time'}} - Closes :{{facility?.closingTime |dateFormat:'time'}}</span></small>
                </div> -->
              </div>

            </div>
          </ng-container>

          <div class="top-title-facility-name-time time-slot">
            <button class="forw-btn" (click)="mobileDayIncreaseDecrease(-1,fi)">
              <mat-icon>arrow_back_ios</mat-icon>
            </button>
            <div *ngIf="selectedFaciltyID == fi" class="inner-time-slot">
              <div class="row-top-time-slot">
                <ng-container *ngFor="let hour of mobilehourschanged;let i = index">
                  <div class="row-main-time-slot">
                    {{hour?.date | date: 'HH'}}
                  </div>
                </ng-container>

              </div>
              <div class="inner-time-slot facility-time-items">
                <ng-container *ngFor="let hour of mobilehourschanged; let i = index">
                  <div class="sub-inner-row-main-time-slot">
                    <ng-container *ngFor="let segment of hour.hourSegments; let l = index">
                      <div class="in-row-main-time-slot"
                        (touchstart)="rangeMouseDown($event, facility, hour, segment, true);$event.stopPropagation();"
                        (touchend)="rangeMouseUp($event, facility, segment)"
                        (pan)="touchMove($event, facility, hour, segment, true,i,l)" [ngStyle]="(pressed[segment.startMin] && selectedFaciltyID == fi)
                                ? {'background-color': drwaingcolour}
                                :{'backgroundColor':setDayAvailabilityStyle(facility, segment)}"
                      [id]="segment.startMin">
                        <ng-container *ngIf="selectedBookingSlots[facility?.id] && selectedBookingSlots[facility?.id]?.length">
                          <div class="test" *ngIf=" findselectedBookingslots(selectedBookingSlots[facility?.id],segment)">
                          </div>
                        </ng-container>
                        <!-- <ng-container *ngIf="selectedSlots?.nodeId === facility?.id && selectedSlots?.slots?.length">
                          <ng-container *ngFor="let selectedSlot of selectedSlots.slots; let i = index">
                            <div class="test"
                              *ngIf="selectedSlot?.startMin === segment?.startMin && selectedSlot?.endMin === segment?.endMin">
                              <div class="delete-timeslot-selection custom-delete-btn" (click)="removeSelectedSlots();$event.stopPropagation();"
                              (touchstart)="$event.stopPropagation();"
                              (touchmove)="$event.stopPropagation();"
                              (touchend)="$event.stopPropagation();"
                              matTooltip="Delete Timeslot"
                                *ngIf="i === (selectedSlots.slots.length - 1)">
                                <i class="material-icons">delete</i>
                              </div>
                            </div>
                          </ng-container>
                        </ng-container> -->
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </div>
            <div *ngIf="selectedFaciltyID != fi" class="inner-time-slot">
              <div class="row-top-time-slot">
                <ng-container *ngFor="let hour of mobilehours;let i = index">
                  <div class="row-main-time-slot">
                    {{hour?.date | date: 'HH'}}
                  </div>
                </ng-container>

              </div>
              <div class="inner-time-slot facility-time-items">
                <ng-container *ngFor="let hour of mobilehours; let i = index">
                  <div class="sub-inner-row-main-time-slot">
                    <ng-container *ngFor="let segment of hour.hourSegments; let i = index">
                      <div class="in-row-main-time-slot" [ngStyle]="{'backgroundColor':setDayAvailabilityStyle(facility, segment)}"
                      (touchstart)="rangeMouseDown($event, facility, hour, segment, true);$event.stopPropagation();" (touchend)="rangeMouseUp($event, facility, segment)"
                      (pan)="rangeMouseMove($event, facility, hour, segment, true)" [class.bg-warning]="pressed[segment.startMin] && selectedFaciltyID == fi" [id]="segment.startMin">
                      
                        <ng-container *ngIf="selectedBookingSlots[facility?.id] && selectedBookingSlots[facility?.id]?.length">
                          <div class="test" *ngIf=" findselectedBookingslots(selectedBookingSlots[facility?.id],segment)">
                          </div>
                        </ng-container>
                        <!-- <ng-container *ngIf="selectedSlots?.nodeId === facility?.id && selectedSlots?.slots?.length">
                          <ng-container *ngFor="let selectedSlot of selectedSlots.slots; let i = index">
                            <div class="test"
                              *ngIf="selectedSlot?.startMin === segment?.startMin && selectedSlot?.endMin === segment?.endMin">
                              <div class="delete-timeslot-selection custom-delete-btn" (click)="removeSelectedSlots();$event.stopPropagation();"
                              (touchstart)="$event.stopPropagation();"
                              (touchmove)="$event.stopPropagation();"
                              (touchend)="$event.stopPropagation();"
                              matTooltip="Delete Timeslot"
                                *ngIf="i === (selectedSlots.slots.length - 1)">
                                <i class="material-icons">delete</i>
                              </div>
                            </div>
                          </ng-container>
                        </ng-container> -->
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </div>
            <button class="back-btn" (click)="mobileDayIncreaseDecrease(1,fi)">
              <mat-icon>arrow_forward_ios</mat-icon>
            </button>
          </div>
        </div>
      </ng-container>
      <!-- mobile calender Dayview end -->

      <!-- mobile calender Weekview start -->
      <ng-container *ngIf="selectedCalenderViewType === calenderViewTypeEnum.WeekView">

          <div class="main-wrapper mmc_card" *ngFor="let facility of facilities; let fi = index ">
            <ng-container>
              <div class="top-title-facility-name-time week-time ">
                <div class="week-top-title-facility-name facility-name-items">
                  <a href="#/facility-detail?StartDate={{searchableTime.startTime}}&&EndDate={{searchableTime.endTime}}&&AssetId={{facility.id}}&&Attendees=0">
                    {{facility?.name}}
                  </a>
                  <div class="wp_flex__item price-icon-wrapper" >
                    <div class="wp-txt-body1--small wp-txt--link  price-label">{{facility?.minPrice?.priceIncludingTax
                      | CurrencyFormat}} - {{facility?.maxPrice?.priceIncludingTax | CurrencyFormat}}
                    </div>
                    <div class="info-icon">
                        <!-- <mat-icon (click)="openPriceConfigurationPopup(facility)">info</mat-icon> --> 
                        <mat-icon (click)="openPriceConfigurationPopup(facility)">expand_circle_down</mat-icon>
                    </div>
                  </div>
                  <div class="people">
                    {{facility?.capacity}} people
                  </div>
                  <!-- <div>
                    <small><span>Open: {{facility?.openingTime |dateFormat:'time'}} - Closes :{{facility?.closingTime |dateFormat:'time'}}</span></small>
                  </div> -->
                </div>
                <div class="top-title-facility-name-time week-top-title-facility-name-time ">
                  <div class="week-top-title-facility-name">
                  </div>
                  <div class="inner-time-slot">
                    <div class="row-top-time-slot-week">
                      <ng-container *ngFor="let weekDay of weekDays;let i = index">
                        <div class="week-row-main-time-slot" [ngClass]="{'current-date-select': weekDay.date.toDateString() === today.toDateString()}">
                          <div>{{weekDay?.date | date: 'd'}}</div>
                          <div>{{weekDay?.date | date: 'EE'}}</div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div class="inner-time-slot facility-time-items">
                  <ng-container *ngFor="let weekDay of weekDays;let wd = index">
                    <div class="week-row-main-time-slot">
                      <div class="inner-slot-wrapper">
                        <ng-container *ngFor="let segment of weekDay.daySegments;let i = index">
                          <div class="inner-slots week-inner-slots" [ngStyle]="(pressed[segment.startMin] && selectedFaciltyID == fi && selectedDayID == wd)
                                ? {'background-color': drwaingcolour}
                                :{'backgroundColor':setWeekAvailabilityStyle(facility, segment)}"
                        (touchstart)="selectWeekTimeSlotMouseDown($event, facility, segment,true);$event.stopPropagation();"
                        (pan)="mobileSelectWeekTimeSlotMouseMove($event, facility, wd,fi,wd)" [id] = "segment.startMin"
                        (touchend)="rangeMouseUp($event, facility, segment)" >
                        <!-- (click)="testClickWeek(facility, segment)">   -->
                            <ng-container *ngIf="selectedBookingSlots[facility?.id] && selectedBookingSlots[facility?.id]?.length">
                              <ng-container>
                                <div class="test"
                                      *ngIf=" findselectedBookingslots(selectedBookingSlots[facility?.id],segment)">
                                </div>
                              </ng-container>
                            </ng-container>
                            
                        <!-- <ng-container *ngIf="getSelectedSlotsByFacility(facility)?.facilityId === facility?.id && getSelectedSlotsByFacility(facility)?.slots?.length">
                          <ng-container *ngFor="let selectedSlot of getSelectedSlotsByFacility(facility).slots; let i = index">
                            <div class="test"
                              *ngIf="selectedSlot?.startMin === segment?.startMin && selectedSlot?.endMin === segment?.endMin && selectedSlot.date.toDateString() === segment.date.toDateString()"> -->
                              <!-- <div class="delete-timeslot-selection custom-delete-btn"
                              (click)="removeSelectedSlots(facility, true);$event.stopPropagation();"
                              (mousedown)="$event.stopPropagation();"
                              (mouseover)="$event.stopPropagation();"
                              (mouseup)="$event.stopPropagation();"
                              matTooltip="Delete Timeslot"
                                *ngIf="i === (selectedSlots.slots.length - 1)">
                                <i class="material-icons">delete</i>
                              </div> -->
                              <!-- </div>
                          </ng-container>
                        </ng-container> -->
                        <!-- </div> -->
                      </div>
                      </ng-container>
                    </div>
                    </div>
                  </ng-container>
                </div>
              </div>

            </div>
          </ng-container>
        </div>


      </ng-container>

      <!-- mobile calender Weekview end -->
      <ng-container *ngIf="selectedCalenderViewType === calenderViewTypeEnum.MonthView">

          <!-- <div class="main-wrapper mmc_card mobile-month" *ngFor="let facility of facilities" > -->
            <!-- <mat-calendar
              [(selected)]="bookingDate"
              [dateClass]="mobileDates"
              [minDate]="mobileMinDate"
            >
            </mat-calendar> -->
            <mobile-month-calendar [selectedBookingSlots] = "selectedBookingSlots" [changed] = "changed" [facilities]="facilities" [bookingDate]="bookingDate" ></mobile-month-calendar>
          <!-- </div> -->

      </ng-container>
      <!-- [minDate]="bookingDate" -->
      <!-- mobile calender Monthview start -->
      <!-- <p>month view</p> -->
      <!-- mobile calender Monthview end -->

      <!-- <div class="title-facility-name">
        <a>Ashburton Library Copland Room</a>
      </div>
      <div class="price-capacity-wrapper">
        <div class="price-num">
          $ 125 - $ 175
          <button class="icon-drop" (click)="openPrices()">
            <mat-icon>tune</mat-icon>
          </button>
        </div>
        <div class="capacity-num">
          80 people
        </div>
      </div> -->
    </div>
  </div>
</div>