import { Component } from "@angular/core";

@Component({
  selector: 'opt-add-extras',
  templateUrl: './add-extras.component.html'
})

export class AddExtrasComponent {

  constructor() { }

}
