import { Component, OnInit, Inject } from '@angular/core';
//Angular Material
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'opt-wp-confirmation-message',
  templateUrl: './wp-confirmation-message.component.html',
  styleUrls: ['./wp-confirmation-message.component.scss']
})
export class WpConfirmationMessageComponent implements OnInit {
    displayMessage: string;
    displayHeaderMessage: string;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<any>) { }

    ngOnInit() {
        this.displayMessage = this.data.displayMessage;
        this.displayHeaderMessage = this.data.displayHeaderMessage;
  }


    confrim(confirmationMessage) {
        this.dialogRef.close({ confirm: confirmationMessage });
    }
}
