<div class="confirmation-message-inner-container">
    <div class="wp_flex--container wp-mb-10">
        <div class="wp_flex__item--inherit">
            <h4 class="wp-heading-4">{{displayHeaderMessage}}</h4>
        </div>
    </div>
    <div class="wp_flex--container wp-mb-20">
        <div class="wp_flex__item--inherit">
            <div class="wp-txt-body1 wp-txt--secondary">{{displayMessage}} ?</div>
        </div>
    </div>
    <div class="wp_flex--container justify-content-end">
        <div class="wp_flex__item--inherit wp-mr-10">
            <button mat-button class="mat-btn-secondary--small" (click)="confrim('yes')" (keydown.enter)="confrim('yes')" tabindex="0">Yes</button>
        </div>
        <div class="wp_flex__item--inherit">
            <button mat-button class="mat-btn-primary--small" (click)="confrim('no')" (keydown.enter)="confrim('no')" tabindex="0">No</button>
        </div>
    </div>
</div>
