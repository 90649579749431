export class WmCollectionLocation {
  id: string;
  name: string;
  active: boolean;

  constructor(obj) {
    this.id = obj.Id;
    this.name = obj.Name;
    this.active = obj.Active;
  }
}
