import { Action } from "@ngrx/store";

export enum LanguageActionType {
    SET_SELECTED_LANGUAGE = "SET_SELECTED_LANGUAGE",
    SET_LANGUAGES = "SET_LANGUAGES",
    RESET_LANGUAGES = "RESET_LANGUAGES"
}

export class SetSelectedLanguage implements Action {
    readonly type = LanguageActionType.SET_SELECTED_LANGUAGE;

    constructor(public payload: any) {
    }
}

export class SetLanguages implements Action {
    readonly type = LanguageActionType.SET_LANGUAGES;

    constructor(public payload: any[]) {
    }
}

export class ResetLanguages implements Action {
    readonly type = LanguageActionType.RESET_LANGUAGES;
}

export type LanguageAction =
    | SetSelectedLanguage
    | ResetLanguages
    | SetLanguages;
