import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IWasteManagementState } from './index';

const getAllSate = createFeatureSelector<IWasteManagementState>('WMReducer');


export const selectUserDataState = createSelector(
  getAllSate,
  (state) => {
    return state ? state.userDataState : null;
  }
);

export const selectSingleAddressState = createSelector(
  getAllSate,
  (state) => {
    return state.addressState;
  }
);

export const selectSelectedCustomer = createSelector(
  getAllSate,
  (state) => {
    return state.selectedCustomer;
  }
);

export const selectGetBookingState = createSelector(
  getAllSate,
  (state) => {
    return state.singleBooking;
  }
);

export const selectGetSavedBookingState = createSelector(
  getAllSate,
  (state) => {
    return state.savedBookingData;
  }
);

export const selectBookingPackageState = createSelector(
  getAllSate,
  (state) => {
    return state.bookingPackageState;
  }
);

export const selectUpComingBookingsState = createSelector(
  getAllSate,
  (state) => {
    return state.upComingBookingCollectionState;
  }
);

export const selectPreviousBookingsState = createSelector(
  getAllSate,
  (state) => {
    return state.previousBookingCollectionState;
  }
);

// export const selectWasteTypeState = createSelector(
//   getAllSate,
//   (state) => {
//    // return state.wasteTypeData;
//   }
// );

export const selectCollectionLocations = createSelector(
  getAllSate,
  (state) => {
    return state.collectionLocationData;
  }
);

// export const selectWastePackages = createSelector(
//   getAllSate,
//   (state) => {
//     return state.wastePackages;
//   }
// );

export const selectIsInternalUserPortal = createSelector(
  getAllSate,
  (state) => {
    return state ? state.isInternalUserPortal : null;
  }
);

// export const selectSuburbs = createSelector(
//   getAllSate,
//   (state) => {
//    // return state.suburbListState;
//   }
// );
