export class BookingFilter {
    searchText: string;
    contactRef: string;
    bookingRef: string;
    bookingStatusId: string;
    eventDateFrom: string;
    eventDateTo: string;
    bookingDateFrom: string;
    bookingDateTo: string;
    invoiceStatus: string;
    paymentStatus: string;  

}