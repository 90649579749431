<div class="d--flex align--center" *ngIf="(languages && languages.length > 1)">
    <!-- language-selector flags -->
    <img *ngIf="selectedLanguage !==''" src="../dist/assets/country-flags/{{selectedLanguage}}.png"
        class="image-transform">

    <!-- language-selector menue items -->
    <div *ngIf="languages && languages.length > 1" class="language-selector">
        <mat-select [(ngModel)]="selectedLanguage" (ngModelChange)="switchLanguage()" panelClass="dropClass">
            <mat-option *ngFor="let language of languages" value="{{ language.languageCode }}" style="width:100px;">
                <img style="height: 15px; width: 15px;" src="../dist/assets/country-flags/{{language.languageCode}}.png"
                    alt=""> {{language.languageCode}}
            </mat-option>
        </mat-select>
    </div>
</div>