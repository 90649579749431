import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'opt-wp-already-access',
  templateUrl: './wp-already-access.component.html',
  styleUrls: ['./wp-already-access.component.scss']
})
export class WpAlreadyAccessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
