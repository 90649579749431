/**
 * Generated bundle index. Do not edit.
 */

export * from './public-api';

export {CustomHammerConfig as ɵa} from './lib/index';
export {NgxGalleryActionComponent as ɵc} from './lib/ngx-gallery-action.component';
export {NgxGalleryArrowsComponent as ɵg} from './lib/ngx-gallery-arrows.component';
export {NgxGalleryBulletsComponent as ɵh} from './lib/ngx-gallery-bullets.component';
export {NgxGalleryHelperService as ɵi} from './lib/ngx-gallery-helper.service';
export {NgxGalleryImageComponent as ɵd} from './lib/ngx-gallery-image.component';
export {NgxGalleryPreviewComponent as ɵf} from './lib/ngx-gallery-preview.component';
export {NgxGalleryThumbnailsComponent as ɵe} from './lib/ngx-gallery-thumbnails.component';
export {NgxGalleryComponent as ɵb} from './lib/ngx-gallery.component';