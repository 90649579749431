import { BaseResource } from 'src/modules/models/base-resource';
import { RecurrenceOption } from 'src/modules/models/regular-portal/recurrence-option';
import { Configuration } from 'src/modules/models/regular-portal/booking/manage-teams/configuration';
//import { Venue } from 'src/modules/models/regular-portal/booking/manage-teams/venue';
import { Asset } from 'src/modules/models/regular-portal/booking/manage-teams/asset';
import { TimeSlot } from 'src/modules/models/regular-portal/booking/manage-teams/time-slot';
import { RecurrentBookingItem, SplitBookingItem, AdHocBookingItem } from 'src/modules/models/regular-portal/booking/manage-teams/recurrent-booking';
import { Item } from 'src/modules/models/item/item';
export class BookingItemVm extends BaseResource {
    constructor() {
        super();
        this.type = "BookingItemVm";
    }
    //venue: Venue;
    asset: Asset[];
    name: string;
    startDate: Date;
    endDate: Date;
    configuration: Configuration;
    recurrenceFilter: RecurrenceOption;
    recurrentBookingItem: RecurrentBookingItem[];
    itemCount: number;
    splitBookingItem: SplitBookingItem[];
    adHocBookingItem: AdHocBookingItem;
    unavailableDates: TimeSlot[];
    unavailableDatesItem: Date[];
    attendees: number;
    grossAmount: number;
    item: Item;
    diaryNote: string;
    priceType: string;
    itemRecurrenceOption: string;
   // upsellItem: BookingItemVm[];
}