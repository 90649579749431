export class Addresses {
    public AddressDetails: AddressDetail[];
}

export class AddressDetail {
    public AddressLine1: string;
    public AddressLine2: string;
    public AddressLine3: string;
    public Locality: string;
    public StateCode: string;
    public Country: string;
    public Postcode: string;
    public DPID: string;
    public AUSBAR: string;
    public Address: string;
    public QASID: string;
}