import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
    selector: 'opt-warning-alert-message',
    templateUrl: './warning-alert-message.component.html',
    styleUrls: ['./warning-alert-message.component.scss']
})

export class WarningAlertMessageComponent implements OnInit {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}

    ngOnInit() {}

    close() { }
}