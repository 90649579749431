import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';

import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AppSetting } from 'src/modules/models/settings/casual-portal/app-setting';

import { RegularBookingSettings } from 'src/modules/models/settings/regular-portal/regular-booking-setting'
import { ReferenceData } from 'src/modules/models/reference-data'
import { UserDefinedFunction } from '../models/system/user-defined-function';
import { WasteBookingSettings } from 'src/modules/models/settings/waste-booking/waste-booking-setting'
import { SystemOption } from '../models/public-web/SystemOption';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { PublicBookingSettings } from '../models/settings/public-booking/public-booking-setting';
import Jsona from 'jsona';


@Injectable({
  providedIn: 'root'
})
export class SystemService {

  constructor(private _baseService: BaseService, private store?: Store<any>, public dialog?: MatDialog) {
  }

  loadConfiguration(): Observable<AppSetting> {
    return this._baseService.InternalWebAPIGET("api/system/app-settings").pipe(
      map((response : any) => {
        return response.Data as AppSetting;
      })
    );
  }

  //load regularBookingConfiguration
  loadRegularBookingConfiguration(): Observable<RegularBookingSettings> {
    return this._baseService.InternalWebAPIGET("api/system/regular-booking").pipe(
      map((response) => {
        return response as RegularBookingSettings;
      })
    );
  }

  getExternalIntegration(id: string): Observable<any> {
    return this._baseService.InternalWebAPIGET("api/system/GetExternalIntergration?id=" + id).pipe(
      map((response) => {
        return response;
      })
    );
  }
  getAvailabilityThreshold(): Observable<any> {
    return this._baseService.InternalWebAPIGET("api/system/GetAvailabilityThreshold").pipe(
      map((response) => {
        return response;
      })
    );
  }

  loadSystemOptions(): Observable<SystemOption[]> {  // Load system option for public portal
    let url = 'api/public/system-options';
    return this._baseService.InternalWebAPIGET(url).pipe(map((response: any) => {
        return response.data?.map(d => new SystemOption().initialize(d));
      })
    );
  }

  getLanguages(): Observable<any> {   // Used in public portal
    return this._baseService.InternalWebAPIGET('api/system/languages').pipe(map((response) => {
      if (response != null) {
        const dataFormatter = new Jsona();
        return dataFormatter.deserialize(response);
      }
      return null;
    }),
      catchError(error => {
        return error
      })
    )
  }
  //Temporary
  getDocumentTemplateCategories(): Observable<ReferenceData[]> {
    return this._baseService.InternalWebAPIGET("api/system/document-categories").pipe(
      map((response: any) => {
        var lst: ReferenceData[] = [];
        response.result.forEach((c, i) => {
          let refData = new ReferenceData();
          refData.Id = c.id;
          refData.Name = c.name;
          lst.push(refData);
        });
        return lst
      })
    );
  };

  // load wasteBookingConfiguration
  loadWasteBookingConfiguration(): Observable<WasteBookingSettings> {
    return this._baseService.InternalWebAPIGET('api/system/waste-booking').pipe(
      map((response) => {
        return response as WasteBookingSettings;
      })
    );
  }

  // load loadPublicBookingConfiguration
  loadPublicBookingConfiguration(): Observable<PublicBookingSettings> {
    return this._baseService.InternalWebAPIGET('api/system/public-booking').pipe(
      map((response : any) => {
        return response.data as PublicBookingSettings;
      })
    );
  }

  GetUserDefinedFunctions(applicationIds: string): Observable<UserDefinedFunction[]> {
    return this._baseService.InternalWebAPIGET("api/system/GetUserDefinedFunctions?applicationIds=" + applicationIds).pipe(

      map((response : any) => {
        return response.data;
      })
    );
  }

  getLoginUserAccessRights(): Observable<any> {
    return this._baseService.InternalWebAPIGET("api/system/user/access-rights").pipe(

      map((response : any) => {
        return response.data;
      })
    );
  }

}
