export class APIResult {
    isError: boolean
    errorMessage: string
    errorCode: string

}

export class Error {
    Id: string;
    Status:string;
    Code:string;
    Title:string;
    Detail:string
}