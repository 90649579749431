import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from '../base/base.service';
import { WmCollectionStatus, WmWasteType } from '../../models/waste-management/wm-package';
import { Serializer } from 'json-api-format';
import { jsonIgnoreReplacer } from 'json-ignore';
import { WmCollectionLocation } from 'src/modules/models/waste-management/wm-collection-location';
import { WmInvoice } from 'src/modules/models/waste-management/wm-invoice';
import { WmAccessRightService } from './wm-access-right.service';
import { Note } from '../../models/waste-management/wm-note';
import { WmEmailType } from 'src/modules/models/waste-management/enum';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WmWasteTypeApiService {

  readonly WasteTypeAPIEndPoint = 'api/waste-collection/waste-types';
  readonly RatingPostAPIEndPoint = 'api/waste-collection/customer-feedback';
  readonly collectionLocationEndPoint = 'api/waste-collection/collection-locations';
  readonly userDataEndpoint = 'api/waste-collection/get-user-data';
  readonly invoiceDataEndpoint = 'api/waste-collection/get-invoice/';
  readonly cancellationPolicyEndPoint = 'api/waste-collection/get-cancellation-policy/';
  readonly wmAPIEndPoint = 'api/waste-collection/';
  readonly wmBookingSelectEndPoint = 'api/waste-collection/get-booking/';
  readonly wmPaymentGetEndPoint = 'api/waste-collection/get-payments/';
  readonly wmCollectionStatuses = 'api/waste-collection/collection-statuses';
  wmWasteTypes$: BehaviorSubject<WmWasteType[]> = new BehaviorSubject(null);

  constructor(private http: BaseService, private wmAccessRightService: WmAccessRightService) {
  }

  getInternalUserData() {
    return this.http.Internal_WebAPI_GET(this.userDataEndpoint).pipe(map((res: any) => {
        if (res.body && res.body.userRightList) {
          this.wmAccessRightService.allAccessRights.next(res.body.userRightList);
        }
        return res.body;
      })
    );
  }

  getWasteTypes(): Observable<WmWasteType[]> {
    const wasteTypes: Array<any> = [];
    return this.http.Internal_WebAPI_GET(this.WasteTypeAPIEndPoint).pipe(
      map((res: any) => {
        if (res.body) {
          res.body.forEach((item) => {
            if (item.ZoneSettings && item.MeasureOption) {
              const wst: WmWasteType = new WmWasteType(item);
              wasteTypes.push(wst);
            }
          });
        }
        return wasteTypes;
      })
    );
  }

  saveCustomerFeedBack(feedBack): Observable<any> {
    const json = new Serializer().serialize(
      JSON.parse(JSON.stringify(feedBack, jsonIgnoreReplacer))
    );
    return this.http.Internal_WebAPI_POST(this.RatingPostAPIEndPoint, json)
      .pipe(map((res: any) => {
          return res.body;
        })
      );
  }

  updateCustomerFeedBack(id, feedBack): Observable<any> {
    const json = new Serializer().serialize(
      JSON.parse(JSON.stringify(feedBack, jsonIgnoreReplacer))
    );
    return this.http.Internal_WebAPI_PATCH(this.RatingPostAPIEndPoint + '/' + id, json)
      .pipe(map((res: any) => {
          return res.body;
        })
      );
  }

  getCollectionLocations(): Observable<WmCollectionLocation[]> {
    const collectionLocation: Array<any> = [];
    return this.http.Internal_WebAPI_GET(this.collectionLocationEndPoint).pipe(
      map((res: any) => {
        if (res.body) {
          res.body.forEach((item) => {
            const wcl: WmCollectionLocation = new WmCollectionLocation(item);
            collectionLocation.push(wcl);
          });
        }
        return collectionLocation;
      })
    );
  }

  getInvoice(id): Observable<WmInvoice[]> {
    const invoice: Array<any> = [];
    return this.http.Internal_WebAPI_GET(this.invoiceDataEndpoint + id).pipe(
      map((res: any) => {
        if (res.body && res.body.Data) {
          res.body.Data.forEach((item) => {
            invoice.push(item);
          });
        }
        return invoice;
      })
    );
  }

  saveNote(note: any, bookingId, detailId): Observable<any> {
      note.noteTypeId = parseInt(note.noteTypeId, 0);
      if (note && note.length > 0) {
          note.forEach(function (n) {
              if (!n.id)
                  n.id = "-1"
          });

      }
    const json = new Serializer().serialize(
      JSON.parse(JSON.stringify(note, jsonIgnoreReplacer))
    );
    return this.http.Internal_WebAPI_POST(this.wmAPIEndPoint + bookingId + '/booking-packages/' + detailId +
      '/notes', json)
      .pipe(
        map((res: any) => {
          return res.body;
        })
      );
  }

  // getting notes from internal API
  getNote(noteTypeId, bookingId, detailId): Observable<Note[]> {
    return this.http.Internal_WebAPI_GET(this.wmAPIEndPoint + bookingId + '/booking-packages/' + detailId +
      '/notes/' + noteTypeId).pipe(
      map((res: any) => {
        const notes: Array<Note> = [];
        if (!res.body.IsError) {
          if (res.body.Data) {
            res.body.Data.forEach((item) => {
              const note: Note = new Note();
              note.id = item.Id;
              note.noteTypeId = item.noteTypeId;
              note.noteType = item.NoteType;
              note.entityType = item.EntityType;
              note.entityTypeID = item.EntityTypeID;
              note.html = item.Html;
              note.plainText = item.PlainText;
              note.imageUrl = item.ImageUrl;
              notes.push(note);
            });
          }
          return notes;
        } else {
          return { isInternalError: res.body.IsError };
        }
      })
    );
  }

  updateNote(note: any, bookingId, detailId): Observable<any> {
      note.noteTypeId = parseInt(note.noteTypeId, 0);
      if (note && note.length > 0) {
          note.forEach(function (n) {
              if (!n.id)
                  n.id = "-1"
          });

      }
    const json = new Serializer().serialize(
      JSON.parse(JSON.stringify(note, jsonIgnoreReplacer))
    );
    return this.http.Internal_WebAPI_PATCH(this.wmAPIEndPoint + bookingId + '/packages/' + detailId +
      '/note', json)
      .pipe(map((res: any) => {
          return res.body;
        })
      );
  }

  getCancellationPolicies(id: number): Observable<any[]> {
    const cancelPolicies: Array<any> = [];
    return this.http.Internal_WebAPI_GET(this.cancellationPolicyEndPoint + id).pipe(
      map((res: any) => {
        if (res.body && res.body.Data) {
          res.body.Data.forEach((item) => {
            cancelPolicies.push(item);
          });
        }
        return cancelPolicies;
      })
    );
  }

  bookingSelect(id: number): Observable<any> {
    return this.http.Internal_WebAPI_GET(this.wmBookingSelectEndPoint + id).pipe(map((res: any) => {
        if (res.body && res.body.Data) {
          return res.body.Data;
        }
      })
    );
  }

  getPayments(id: number): Observable<any[]> {
    const payments: Array<any> = [];
    return this.http.Internal_WebAPI_GET(this.wmPaymentGetEndPoint + id).pipe(map((res: any) => {
        if (res.body && res.body.Data) {
          res.body.Data.forEach((item) => {
            payments.push(item);
          });
        }
        return payments;
      })
    );
  }

  sendEmail(bookingId: number, contactId: string, emailType: number, bookingref: string): Observable<any> {
    const url = this.wmAPIEndPoint + 'send-emails?bookingId='+ bookingId + '&contactId=' + contactId 
    + '&emailType=' + emailType + '&bookingReference=' + bookingref;
    
    return this.http.Internal_WebAPI_POST(url, null)
      .pipe(
        map((res: any) => {
          return res.body;
        })
      );
  }

  getCollectionStatuses(): Observable<any[]> {
    const collectionStatuses: Array<any> = [];
    return this.http.Internal_WebAPI_GET(this.wmCollectionStatuses).pipe(
      map((res: any) => {
        if (res.body && res.body.Data) {
          res.body.Data.forEach((item) => {
            collectionStatuses.push(item);
          });
        }
        return collectionStatuses;;
      })
    );
  }

  
}
