import { Pipe, PipeTransform } from '@angular/core';
import { BookingPackages } from 'src/modules/models/booking/booking-packages';
import { BookingCart } from '../components/layout/models/bookingCart.model';

@Pipe({
  name: 'PBCustomItemQuantityPipe',
  pure:false
})
export class PbCustomItemQuantityPipe implements PipeTransform {

  constructor() {
  }
  transform(bookingPackages: BookingPackages[], showQuantity: boolean = true): unknown {
    let itemQuantity = 0;
    if (showQuantity) {
      bookingPackages.forEach(bPack => {
        itemQuantity += (bPack.attendees / bPack?.package?.attendees);
      });
    }
    return itemQuantity;
  }
}
