<div class="edit-booking-modal edit-modal-booking-header">
    <!--<div style="max-height:80vh" class="mat-dialog-content">-->

    <div class="mmc-modal-header mmc_flex--container edit-modal-header edit-moda-height">
        <div class="mmc_flex__item--inherit mmc_flex__item--vcenter icon-cage mmc_just-content-center d-none">
            <i class="material-icons">star_border</i>
        </div>
        <div style="padding-left:24px;" class="mmc_flex__item mmc_flex__item--hstart mmc_flex__item--vcenter title-cage">
            <h3>
                <span class="pw-txt-h4 pw-text-semi-bold pw-text-color__primary" *ngIf="isFacilityAndItemNameSame()">{{ getItem()?.name}}</span>
                <span class="pw-txt-h4 pw-text-semi-bold pw-text-color__primary" *ngIf="!isFacilityAndItemNameSame()">{{ getItem()?.name}} - <span class="pw-txt-b3 pw-text-medium pw-text-color__tertiary ml-3 facility-cage sub-title-cage">{{facilitySummary?.name}}</span></span>
                <span class="pw-txt-s2 pw-text-color__tertiary d-none">{{facilitySummary?.directions}}</span>
            </h3>
        </div>


        <div class="mmc_flex__item mr-1 mmc_flex__item--vcenter mmc_just-content-end">
            <div class="pw-txt-b3 estimated-price-wrapper">
                Estimated Price<span *ngIf="!estimatedPriceRange(facilitySummary) &&  isHaveEstimatedPrice(facilitySummary)">{{ getEstimatedPrice(facilitySummary)| CurrencyFormat}}</span>
                <span *ngIf="estimatedPriceRange(facilitySummary)">{{getItem()?.calculatedMinPrice?.priceIncludingTax | CurrencyFormat}} - {{getItem()?.calculatedMaxPrice?.priceIncludingTax | CurrencyFormat}}</span>
            </div>
            <div class="pw-txt-h4 d-none">
                <span *ngIf="facilitySummary.item.itemMaxPrice == facilitySummary.item.itemMinPrice ">${{facilitySummary.item.itemMinPrice?.priceIncludingTax}}</span>
                <span *ngIf="facilitySummary.item.itemMaxPrice != facilitySummary.item.itemMinPrice ">${{facilitySummary.item.itemMinPrice?.priceIncludingTax}} - ${{facilitySummary.item.itemMaxPrice?.priceIncludingTax}}</span>
            </div>
        </div>

        <div class="mmc_flex__item--inherit mmc_flex__item--vcenter close-icon-cage mmc_just-content-center">
            <button mat-button [mat-dialog-close]="true"><i class="material-icons">close</i></button>
        </div>
    </div>



    <div class="mmc-modal-body">
        <!--<div class="modal-body">-->
        <!--modal body start-->
        <div class="edit-modal">
            <div class="err-msg mmc_flex--container" *ngIf="showBookingLeadDayValidationMessage">
                <div class="mmc_flex__item--vcenter">
                    <i class=" icon-error"></i>
                </div>
                <div class="mmc_flex__item--inherit">
                    <div>{{bookingLeadDayValidationMessage}}</div>
                </div>
            </div>

            <div class="err-msg mmc_flex--container" *ngIf="this.isBookable() && errorMessages.length > 0">
                <div class="mmc_flex__item--vcenter">
                    <i class="icon-error"></i>
                </div>
                <div class="mmc_flex__item--inherit" *ngFor="let error of errorMessages;" >
                    <div>{{error}}</div>
                </div>
            </div>
            <div class="err-msg mmc_flex--container" *ngIf="this.isBookable() && warningMessages.length > 0">
                <div class="mmc_flex__item--vcenter">
                    <i class="icon-error"></i>
                </div>
                <div class="mmc_flex__item--inherit" *ngFor="let error of warningMessages;" >
                    <div>{{error}}</div>
                </div>
            </div>

            <div class="err-msg mmc_flex--container" *ngIf="showUnavailableMessage && !showUnavailableOperationalMessage">
                <div class="mmc_flex__item--vcenter">
                    <i class=" icon-error"></i>
                </div>
                <div class="mmc_flex__item--inherit">
                    <div>Selected time slot is unavailable.</div>
                    <div *ngIf="availableSlots && availableSlots.length>0">Please try a different time.</div>
                    <div *ngIf="!availableSlots || availableSlots.length==0">No more bookings are accepted on this day for this venue. Please select another day of venue.</div>

                </div>
            </div>
            <div class="available-msg mmc_flex--container" *ngIf="isCheckAvailabilityTriggered && isAvailable && continueProcess && !(errorMessages.length > 0 || warningMessages.length > 0)">
                <div class="mmc_flex__item--vcenter">
                    <i class="mmc icon-verified"></i>
                </div>
                <div class="mmc_flex__item--vcenter">
                    <div>Selected time slot is available.</div>
                </div>
            </div>
            <div class="err-msg mmc_flex--container" *ngIf="isItemPricesLoaded && !isHaveEstimatedPrice(facilitySummary)">
                <div class="mmc_flex__item--vcenter">
                    <i class="icon-error"></i>
                </div>
                <div class="mmc_flex__item--inherit">
                    <div>Prices are not defined for the selected time range</div>
                </div>
            </div>
            <div class="err-msg mmc_flex--container" *ngIf="showUnavailableFutureDateMessage">
                <div class="mmc_flex__item--vcenter">
                    <i class="icon-error"></i>
                </div>
                <div class="mmc_flex__item--inherit">
                    <div>{{showUnavailableFutureDateErrorMessage}}</div>
                </div>
            </div>
            <div class="err-msg mmc_flex--container" *ngIf="showUnavailableOperationalMessage && !showUnavailableFutureDateMessage">
                <div class="mmc_flex__item--vcenter">
                    <i class="icon-error"></i>
                </div>
                <div class="mmc_flex__item--inherit">
                    Your selected time is outside operating hours,
                    <div *ngIf="facilitySummary.openingTime">Opening Time: {{facilitySummary.openingTime |dateFormat:'time'}}</div>
                    <div *ngIf="facilitySummary.closingTime">Closing Time: {{facilitySummary.closingTime |dateFormat:'time'}}</div>
                </div>
            </div>

            <div class="mmc_flex--container edit-row" *ngIf="isConfigurationEnabled">
                <div class="mmc_flex__item p50 pickers">
                    <div class="mmc_flex--container fd-col">
                        <div class="mmc_flex__item m1 mr-3 p25">
                            <mat-form-field appearance="outline" class="pw-txt-s3">
                                <mat-label>Date</mat-label>
                                <input matInput [matDatepicker]="picker" [(ngModel)]="date" tabindex="0" (click)="picker.open()" (keydown.enter)="picker.open()" name="date" (dateChange)="dateChanged()" [min]="minDate" readonly>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="mmc_flex__item item-attendess m1 mr-3 p25">
                            <mat-form-field appearance="outline" class="pw-txt-s3" [ngClass]="{'form-field-error' : !attendeesIsValid() || !attendeesCountIsValid() }">
                                <mat-label class="d-flex pw-fd-col"><span [ngStyle]="{'color': attendees == 0 ? '#D32F2F' : '#616161'}">Number of Attendees</span> <span>(Maximum {{getSelectedConfiguration()?.capacity}})</span></mat-label>
                                <input [formControl]="attendessControl"  class="" matInput type="number" style="text-align:left" [(ngModel)]="attendees" id="attendees" (ngModelChange)="attendeesChanged($event)" min="1" onkeypress="return ((event.charCode > 48 && event.charCode <= 57) || (event.charCode == 48 && this.value !=''))" autocomplete="off">
                            </mat-form-field>
                            <div class="btn-wrapper"><button mat-button="" tabindex="0" (click)="add(1)" (keydown.enter)="add(1)"><i class="material-icons">arrow_drop_up</i></button><button mat-button="" tabindex="0" (click)="add(-1)" (keydown.enter)="add(-1)"><i class="material-icons">arrow_drop_down</i></button></div>

                            <span *ngIf="!attendeesIsValid()" class="max-count-error" id="attendees-error">Specified number of attendees exceeds maximum capacity of {{getSelectedConfiguration()?.capacity}} for the room. </span>
                            <span *ngIf="!attendeesCountIsValid()" class="" id="attendees-error">Please specify the number of attendees.</span>
                        </div>


                    </div>

                    <div class="mmc_flex--container fd-col" [ngStyle]="cartData && cartData?.booking?.id ? {'cursor': 'not-allowed'} : {}">
                        <div class="mmc_flex__item m1 mr-3 p25" [ngStyle]="cartData && cartData?.booking?.id ? {'pointer-events': 'none'} : {}">
                            <mat-form-field appearance="outline" class="pw-txt-s3" #hoverStartMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="startmenu">
                                <mat-label>Start Time</mat-label>
                                <input class="" matInput placeholder="START TIME" value="{{startTime |dateFormat:'time'}}" readonly>
                                <mat-icon matSuffix>access_time</mat-icon>
                            </mat-form-field>
                            <mat-menu #startmenu="matMenu" class="time-popup" [overlapTrigger]="false">
                                <div class="mmc_flex--container" (mouseleave)="closeTimeMenu()" tabindex="0" (click)="$event.stopPropagation();" (keydown.tab)="$event.stopPropagation();">
                                    <div class="mmc_flex__item">
                                        <div class="mmc_flex--container">
                                            <div class="mmc_flex__item">
                                                <timepicker [(ngModel)]="startTime"
                                                            [min]="minStartDate"
                                                            [max]="maxStartDate"
                                                            (ngModelChange)="startTimeChanged()"
                                                            (isValid)="isValidStart = $event">
                                                </timepicker>
                                                <pre *ngIf="!isValidStart" class="alert alert-danger">Invalid time format</pre>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-menu>
                        </div>

                        <div class="mmc_flex__item m1 mr-3 p25" [ngStyle]="cartData && cartData?.booking?.id ? {'pointer-events': 'none'} : {}">
                            <mat-form-field appearance="outline" class="pw-txt-s3" #hoverEndMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="endmenu">
                                <mat-label>End Time</mat-label>
                                <input matInput placeholder="END TIME" value="{{endTime |dateFormat:'time'}}" readonly>
                                <mat-icon matSuffix>access_time</mat-icon>
                            </mat-form-field>
                            <mat-menu #endmenu="matMenu" class="time-popup" [overlapTrigger]="false">
                                <div class="mmc_flex--container" (mouseleave)="closeTimeMenu()" tabindex="0" (click)="$event.stopPropagation();" (keydown.tab)="$event.stopPropagation();">
                                    <div class="mmc_flex__item">
                                        <div class="mmc_flex--container">
                                            <div class="mmc_flex__item">
                                                <timepicker [(ngModel)]="endTime"
                                                            [min]="minEndDate"
                                                            [max]="maxEndDate"
                                                            (isValid)="isValidEnd = $event"
                                                            (ngModelChange)="endTimeChanged()">
                                                </timepicker>
                                                <pre *ngIf="!isValidEnd" class="alert alert-danger">Invalid time format</pre>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-menu>
                        </div>
                    </div>
                    </div>
    <!--<div></div>-->
    <!--Start-TIME SLOTS-->
    <div class="mmc_flex__item item-availability m2 p50">
        <div class="pw-txt-b3 title mb-3">{{getTimeslotAvailabilityHeader()}}<span></span> </div>
        <div *ngIf="availableSlots && availableSlots.length==1">
            <mat-chip-list aria-label="Fish selection" *ngFor="let slot of availableSlots;">
                <mat-chip class="available-chip" tabindex="0" (click)="setStartEndTime(slot)" (keydown.enter)="setStartEndTime(slot)" selected>{{slot.startTime | dateFormat:'time'}} <span>to</span>{{slot.endTime | dateFormat:'time'}}
                </mat-chip>
            </mat-chip-list>
        </div>
        <div *ngIf="availableSlots && availableSlots.length>1" class="chip-cage">
            <mat-chip-list aria-label="Fish selection" *ngFor="let slot of availableSlots;">
                <mat-chip class="available-chip  mr-3 my-3" tabindex="0" (click)="setStartEndTime(slot)" (keydown.enter)="setStartEndTime(slot)" selected>{{slot.startTime | dateFormat:'time'}} <span>to</span>{{slot.endTime | dateFormat:'time'}}
                </mat-chip>
            </mat-chip-list>
        </div>

                </div>
                <!--END-TIMESLOTS-->
                <!--<span *ngIf="!attendeesIsValid()" class="max-count-error" id="attendees-error">Specified number of attendees exceeds maximum capacity of {{getSelectedConfiguration()?.capacity}} for the room. </span>
                <span *ngIf="!attendeesCountIsValid()" class="" id="attendees-error">Please specify the number of attendees.</span>-->



            </div>

            <div class="mmc_flex--container edit-timeSlot edit-row" *ngIf="!isConfigurationEnabled">
                <form class="mmc_flex__item m-1">
                    <mat-form-field class="pw-txt-s3">
                        <input matInput [matDatepicker]="picker" [(ngModel)]="date" tabindex="0" (click)="picker.open()" (keydown.enter)="picker.open()" name="date" (dateChange)="dateChanged()" [min]="minDate" readonly>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </form>
                <div class="mmc_flex__item m-1 item-secondary item-attendess">
                    <mat-form-field floatLabel="always" class="pw-txt-s3">
                        <mat-label class="d-flex pw-fd-col">
                            <span [ngStyle]="{'color': attendees == 0 ? '#D32F2F' : '#616161'}">ATTENDEES (Maximum {{getSelectedConfiguration()?.capacity}})</span>
                        </mat-label>
                        <input class="" matInput type="number" style="text-align:left" [(ngModel)]="attendees" id="attendees" (ngModelChange)="attendeesChanged($event)" min="1" onkeypress="return ((event.charCode > 48 && event.charCode <= 57) || (event.charCode == 48 && this.value !=''))" autocomplete="off">
                    </mat-form-field>

                    <span *ngIf="!attendeesIsValid()" class="max-count-error" id="attendees-error">Specified number of attendees exceeds maximum capacity of {{getSelectedConfiguration()?.capacity}} for the room. </span>
                    <span *ngIf="!attendeesCountIsValid()" class="" id="attendees-error">Please specify the number of attendees.</span>
                </div>
                <div class="mmc_flex__item m-1">
                    <mat-form-field class="example-full-width pw-txt-s3" #hoverStartMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="startmenu">
                        <input class="" matInput placeholder="START TIME" value="{{startTime |dateFormat:'time'}}" readonly>
                        <mat-icon matSuffix>access_time</mat-icon>
                    </mat-form-field>
                    <mat-menu #startmenu="matMenu" class="time-popup" [overlapTrigger]="false">
                        <div class="mmc_flex--container" (mouseleave)="closeTimeMenu()" tabindex="0" (click)="$event.stopPropagation();" (keydown.tab)="$event.stopPropagation();">
                            <div class="mmc_flex__item table-no-border">
                                <div class="mmc_flex--container">
                                    <div class="mmc_flex__item">
                                        <timepicker [(ngModel)]="startTime"
                                                    [min]="minStartDate"
                                                    [max]="maxStartDate"
                                                    (ngModelChange)="startTimeChanged()"
                                                    (isValid)="isValidStart = $event">
                                        </timepicker>
                                        <pre *ngIf="!isValidStart" class="alert alert-danger">Invalid time format</pre>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-menu>
                </div>
                <div class="mmc_flex__item m-1">
                    <mat-form-field class="example-full-width pw-txt-s3" #hoverEndMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="endmenu">
                        <input matInput placeholder="END TIME" value="{{endTime |dateFormat:'time'}}" readonly>
                        <mat-icon matSuffix>access_time</mat-icon>
                    </mat-form-field>
                    <mat-menu #endmenu="matMenu" class="time-popup" [overlapTrigger]="false">
                        <div class="mmc_flex--container" (mouseleave)="closeTimeMenu()" tabindex="0" (click)="$event.stopPropagation();" (keydown.tab)="$event.stopPropagation();">
                            <div class="mmc_flex__item table-no-border">
                                <div class="mmc_flex--container">
                                    <div class="mmc_flex__item">
                                        <timepicker [(ngModel)]="endTime"
                                                    [min]="minEndDate"
                                                    [max]="maxEndDate"
                                                    (isValid)="isValidEnd = $event"
                                                    (ngModelChange)="endTimeChanged()">
                                        </timepicker>
                                        <pre *ngIf="!isValidEnd" class="alert alert-danger">Invalid time format</pre>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-menu>
                </div>
            </div>

          


            <div class="mmc_flex--container confirmation my-3" *ngIf ="getBumpInOutNotification()?.length > 0" >
                <i class="material-icons">info</i>
                <span class="font__xsmall">
                    {{getBumpInOutNotification()}}
                </span>

            </div>
            <div class="mmc_flex--container" *ngIf="!isEnableAutoAvailabilityCheck" >
                <div class="btn__card main mmc_flex__item--vcenter check-availability--btn" tabindex="0" (click)="checkAvailability()" (keydown.enter)="checkAvailability()" [ngClass]="{'disable-div' : !isValidStart || !isValidEnd || showUnavailableOperationalMessage }" role="button">CHECK AVAILABILITY</div>
                <div class="btn__card main mmc_flex__item--vcenter check-availability--btn" tabindex="0" (click)=" refreshBooking(facilitySummary)" (keydown.enter)="refreshBooking(facilitySummary)" [ngClass]="{'disable-div' : !isBookable()}" *ngIf="!isAddFacility" role="button">UPDATE BOOKING</div>
                <div class="btn__card main mmc_flex__item--vcenter check-availability--btn bookBtn" (click)="!isBookingLeadDaysError && addBooking('1')" (keydown.enter)="!isBookingLeadDaysError && addBooking('1')" [ngClass]="{'disable-div' :!isBookable()}" *ngIf="isAddFacility" role="button">BOOK</div>
                <div class="btn__card main mmc_flex__item--vcenter check-availability--btn ml-3 addMoreBtn" tabindex="0" (click)="!isBookingLeadDaysError && addBooking('0')" (keydown.enter)="!isBookingLeadDaysError && addBooking('0')"  [ngClass]="{'disable-div' : !isBookable()}" *ngIf="isAddFacility" role="button">BOOK AND ADD MORE</div>
            </div>

        </div>

        <div *ngIf="facilitySummary?.timeslotLoaded">
            <opt-calendar-modal [inputSlots]="facilitySummary.calenderTimeslots"
                                [inputAvailableSlots]="facilitySummary.availableTimeslots"
                                [configuration]="getSelectedConfiguration()"
                                (updateAvailableSlots)="setAvailableSlots($event)"></opt-calendar-modal>
        </div>

        <!--legend-->

            <fieldset class="availabiblity-legend">
                <legend class="pw-txt-s2">Colour key:</legend>
                <div class="mmc_flex--container key-container">
                    <div class="mmc_flex__item key-item" *ngFor="let timeLineColor of timeLineColors;">
                        <div class="key-color"  [ngStyle]="{'backgroundColor':timeLineColor.Value}"></div>
                        <div class="pw-txt-s2">{{timeLineColor.Name}}</div>
                    </div>
                </div>
            </fieldset>





        <!--/legend-->

        <!--Other Alternatives-->

        <div class="mmc_flex--container mt-5 fd-col-mob" *ngIf="isConfigurationEnabled">

            <div *ngIf="configurationDisplayMode==1 && isConfigurationEnabled" class="mmc_flex__item mx-3 p50">
                <select-configuration (messageEvent)="receiveConfigurationMessage($event)"
                                      [configurations]="getConfigurationData()"
                                      [displayMode]="configurationDisplayMode"
                                      [selectedConfiguration]="getSelectedConfiguration()">
                </select-configuration>
            </div>

            <div *ngIf="configurationDisplayMode==2 && isConfigurationEnabled" class="mmc_flex__item m1  mx-3 p50">
                <select-configuration (messageEvent)="receiveConfigurationMessage($event)"
                                      [configurations]="getConfigurationData()"
                                      [displayMode]="configurationDisplayMode"
                                      [selectedConfiguration]="getSelectedConfiguration()">
                </select-configuration>
            </div>
            <div class="mmc_flex__item p50-web Alternatives fd-col-mob" *ngIf="isConfigurationEnabled && facilitySummary.items.length > 1">
                <div class="pw-txt-b3 mb-3">The selected option for this facility: Event</div>
                <div class="mmc_flex--container fd-col-mob">
                    <div class="mmc_flex__item p50-web">
                        <div class="pw-txt-s1">
                            There {{facilitySummary.items.length == 2 ? 'is' : 'are'}} {{facilitySummary.items.length-1}} other pricing and configuration options to book the {{facilitySummary.name}}
                            <!--Please use button in the right to view these options.--> <!--<span class="" (click)="openAlternativeOptions()>View other options</span>-->
                        </div>
                        <div class="pw-txt-b2 mt-2 d-center" tabindex="0" (click)="panelOpenState=(!panelOpenState)" (keydown.enter)="panelOpenState=(!panelOpenState)">
                          <a class="pw-txt-b2 pw-txt-h3_small--link">
                            {{panelOpenState ?'Hide Options' : 'View Options'}}
                          </a>
                            <span class="material-icons pointer">
                                {{panelOpenState ?'keyboard_arrow_up' : 'keyboard_arrow_down'}}
                            </span>
                        </div>
                    </div>
                    <!--<div class="mmc_flex__item p20-web">
                        <div class="btn__card main mmc_flex__item--vcenter" (click)="openAlternativeOptions()">VIEW OTHER OPTIONS</div>
                    </div>-->
                </div>
            </div>
        </div>

        <!--/Other Alternatives-->
        <!--alternatives-->

        <div class="alternative-list mt-3" *ngIf="showItemsAvailability(facilitySummary)">
            <mat-accordion multi="false">
                <mat-expansion-panel [expanded]="panelOpenState" [disabled]="false" (opened)="panelOpenState=true" (closed)="panelOpenState=false">
                    <!--[disabled]="true"-->
                    <mat-expansion-panel-header collapsedHeight="65px" expandedHeight="65px">
                        <div class="mmc_flex--container header-container">
                            <div class="mmc_flex__item mmc_flex__item--vcenter">
                                <div class="pw-txt-b3 pw-text-color__primary">
                                    Selected Option: {{facilitySummary.selectedItem?.name}}-<span class="">{{facilitySummary.items.length - 1}} more options available</span>
                                </div>
                            </div>
                        </div>


                    </mat-expansion-panel-header>
                    <div class="edit-modal-alternative-body d-block">

                        <mat-radio-group class="alternative-radio-group" aria-label="Select an option" role="radio">

                            <div class="mmc_flex--container body-container" *ngFor="let itm of facilitySummary.items">

                                <div class="mmc_flex__item mmc_flex__item--vcenter alternative-radio">
                                    <mat-radio-button role="radio" (change)="addToBookingAlternative(itm)" [checked]="itm.id == facilitySummary.item?.id" [value]="itm.id">{{itm?.name}}</mat-radio-button>
                                </div>
                                <div class="mmc_flex__item mmc_flex__item--vcenter f-end text-right">
                                    <div class="session-text pw-text-color__primary">
                                        <div class="total__amount pw-txt-h4 pw-text-color__primary pw-text-semi-bold" *ngIf="isUnitPriceRange(itm)">{{itm.itemMinPrice?.priceIncludingTax | CurrencyFormat}} - {{itm.itemMaxPrice?.priceIncludingTax | CurrencyFormat}}</div>
                                        <div class="total__amount pw-txt-s2 pw-text-color__tertiary pw-text-medium" *ngIf="isUnitPriceRange(itm)">{{itm.itemMinPrice?.priceType}}: - {{itm.itemMaxPrice?.priceType}}</div>
                                        <div class="total__amount pw-txt-h4 pw-text-color__primary pw-text-semi-bold" *ngIf="!isUnitPriceRange(itm) && itm.itemMinPrice ">{{itm.itemMinPrice?.priceIncludingTax | CurrencyFormat}}</div>
                                        <div class="total__amount pw-txt-s2 pw-text-color__tertiary pw-text-medium" *ngIf="!isUnitPriceRange(itm) && itm.itemMinPrice">{{itm.itemMinPrice?.priceType}}</div>
                                        <div class="total__amount pw-txt-s2 pw-text-color__tertiary pw-text-medium" *ngIf="!itm.itemMinPrice">Prices are not defined</div>

                                    </div>
                                </div>
                            </div>

                        </mat-radio-group>
                    </div>
                </mat-expansion-panel>

            </mat-accordion>
        </div>

        <div>
            <!--<div class="edit-modal-alternative-body d-block mt-4">

                <mat-radio-group class="alternative-radio-group" aria-label="Select an option">

                    <div class="mmc_flex--container body-container">

                        <div class="mmc_flex__item mmc_flex__item--vcenter alternative-radio">
                            <mat-radio-button>Auditorium</mat-radio-button>
                        </div>
                        <div class="mmc_flex__item mmc_flex__item--vcenter f-end text-right">
                            <div class="pw-text-color__primary session-text">
                                <div class="total__amount pw-txt-h4 pw-text-color__primary pw-text-semi-bold">$479.00 - $606.00</div>
                                <div class="total__amount pw-txt-s2 pw-text-color__tertiary pw-text-medium">Session - Session</div>


                            </div>
                        </div>
                    </div>

                    <div class="mmc_flex--container body-container">

                        <div class="mmc_flex__item mmc_flex__item--vcenter alternative-radio">
                            <mat-radio-button>Auditorium</mat-radio-button>
                        </div>
                        <div class="mmc_flex__item mmc_flex__item--vcenter f-end text-right">
                            <div class="pw-text-color__primary session-text">
                                <div class="total__amount pw-txt-h4 pw-text-color__primary pw-text-semi-bold">$479.00 - $606.00</div>
                                <div class="total__amount pw-txt-s2 pw-text-color__tertiary pw-text-medium">Session - Session</div>


                            </div>
                        </div>
                    </div>

                </mat-radio-group>
            </div>-->
            <div class="mmc_flex--container edit-timeSlot category-event-selection-row" *ngIf="isCategoryAndEventEnabled">
                <div class="mmc_flex__item m-1" >
                    <mat-form-field class="bookingpopup-input">
                        <mat-select disableOptionCentering placeholder="Please select a Category" [(ngModel)]="selectedClass" (ngModelChange)="packageSelectorChange()" required [disabled]="cartData && cartData?.booking?.id">
                            <mat-option *ngFor="let packageClass of packageFilter?.FilteredPackageClasses" [value]="packageClass.Id">{{packageClass.Name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="mmc_flex__item m-1">
                    <mat-form-field class="bookingpopup-input">
                        <mat-select disableOptionCentering placeholder="Please select an Event" [(ngModel)]="selectedPackage" (ngModelChange)="packageChange()" [required]="isCategoryAndEventMandatory" [disabled]="cartData && cartData?.booking?.id">
                            <mat-option value="">Select</mat-option>
                            <mat-option *ngFor="let package of selectPackageList()" [value]="package.id">{{package.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <span *ngIf="isCategoryAndEventMandatory && !isPackageSelected()" class="" id="event-error">Please select an Event.</span>
                </div>
            </div>
        </div>
    </div>

    
    

    <div class="mmc-modal-footer mmc_flex--container">
        <div class="mmc_flex__item item-w-10 mmc_just-content-end">
            <!-- <div class="pw-txt-b3">Estimated Price: <span *ngIf="!estimatedPriceRange(facilitySummary) &&  isHaveEstimatedPrice(facilitySummary)">{{ getEstimatedPrice(facilitySummary)| CurrencyFormat}}</span>
                <span *ngIf="estimatedPriceRange(facilitySummary)">{{getItem()?.calculatedMinPrice?.priceIncludingTax | CurrencyFormat}} - {{getItem()?.calculatedMaxPrice?.priceIncludingTax | CurrencyFormat}}</span>
            </div> -->
        </div>
        <div class="mmc_flex__item col-12 col-xs-12 col-sm-12 col-md-6 mmc_flex__item--vcenter mmc_just-content-center" *ngIf="isEnableAutoAvailabilityCheck">
            <div class="btn__card main mmc_flex__item--vcenter check-availability--btn" tabindex="0" (click)="refreshBooking(facilitySummary)" (keydown.enter)="refreshBooking(facilitySummary)" [ngClass]="{'disable-div' : !isBookable()}" *ngIf="!isAddFacility" role="button">UPDATE BOOKING</div>
            <div class="btn__card main mmc_flex__item--vcenter check-availability--btn bookBtn" tabindex="0" (click)="!isBookingLeadDaysError && addBooking('1')" (keydown.enter)="!isBookingLeadDaysError && addBooking('1')" [ngClass]="{'disable-div' :!isBookable()}" *ngIf="isAddFacility" role="button">BOOK</div>
            <div class="btn__card main mmc_flex__item--vcenter check-availability--btn ml-3 addMoreBtn" (click)="!isBookingLeadDaysError && addBooking('0')" (keydown.enter)="!isBookingLeadDaysError && addBooking('0')" [ngClass]="{'disable-div' :!isBookable()}" *ngIf="isAddFacility" role="button">BOOK AND ADD MORE</div>
        </div>
    </div>
</div>
