import { Component, OnInit, OnDestroy } from '@angular/core';

import { SystemService } from '../../services/system.service';
import { environment } from 'src/environments/environment';

import { Store, ICart } from '../../store/index';
import { Observable } from "rxjs";

@Component({
    selector: 'opt-mmc-enquiry',
    templateUrl: './mmc-enquiry.component.html'
})

export class MmcEnquiryComponent implements OnInit, OnDestroy {
    cart$: Observable<ICart>;
    cart_subscriber: any;
    submitEnquiryMessage: string;

    constructor(private store: Store<any>, private _systemService: SystemService) {
        if (this.cart_subscriber) {
            this.cart_subscriber.unsubscribe();
        }
      this.submitEnquiryMessage = environment.SubmitEnquiryMessage ;
    }

    ngOnInit() {
        this.cart$ = this.store.select('cart');
    }

    ngOnDestroy() {
        if (this.cart_subscriber) {
            this.cart_subscriber.unsubscribe();
        }
    }

    getConfigurationSettings() {
        this._systemService.loadConfiguration().subscribe(data => {
            if (data != null) {
                this.submitEnquiryMessage = data.SubmitEnquiryConfig.Message;
            }
        })
    }
}
