import { BaseResource } from '../base-resource';
import { Asset } from './asset';
import { EventConfiguration } from './event-configuration';

export class Timeslot extends BaseResource {
    constructor() {
        super();
        this.eventConfiguration = new EventConfiguration();
        this.type = "PrivateEventFacilityTimeslot";
    }

    asset: Asset;
    eventConfiguration: EventConfiguration;
    endTime: string;
    startTime: string;
    
}