import {Component, OnInit, OnDestroy, Inject, Output, EventEmitter} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {Question} from 'src/modules/models/item/question';
import {FacilitySummary} from 'src/modules/models/asset/facility-summary';

import {AlertMessageComponent} from 'src/modules/shared/alert-message/alert-message.component';
import {Store, ICart, UpdateFacility, UpdateBookingPackage} from 'src/modules/store/index';
import {Observable} from 'rxjs';
import {BespokePackage} from 'src/modules/models/asset/bespoke-package';
import {ConditionOperator} from 'src/modules/models/enum';
import {Item} from '../../../models/item/item';

@Component({
  selector: 'opt-questionnaire',
  templateUrl: './questionnaire.component.html'
})

export class QuestionnaireComponent implements OnInit, OnDestroy {
  cart$: Observable<ICart>;
  cart_subscriber: any;
  isFormValid: boolean = true;
  item: Item;
  facilitySummary: FacilitySummary;
  package: BespokePackage;
  isFullBookingView: boolean = false;
  booking: any;
  answeredCurrentCount: number = 0;
  isReadOnly: boolean;
  isUpsellHasQuestion: boolean = false;
  upsellTotalQuestion: number = 0;
  @Output() onSubmit = new EventEmitter<any>(true);
  isAnswerChanged: boolean = false;
  visibleQuestionsCount: number = 0;
  itemVisibleQuestionsCount: number = 0;


  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<any>,
    public snackBar: MatSnackBar
  ) {
    //Package Question =>  will have  this.package
    //Facility Question=> data.fc(facility summary) , item is facility Item
    //                     this schenario item can haved upsell linked in that case upsell also need to be shown in popup
    //Upsell question=> when add upsell this will call and it shows upsell questions only, this scenaio item is upsell item and isUpsellItem is true
    //Booking questions

    this.facilitySummary = data.fc;
    this.cart$ = this.store.select('cart');
    this.item = data.item;
    if (data.packageFilter && data.packageFilter.PackageDetail) {
      this.package = data.packageFilter.PackageDetail;
      // Loop through package questions and set isHidden for child questions
      if (this.package && this.package.question) {
        this.package.question.forEach(question => {
          this.setHiddenFalseForChildQuestions(this.package.question, question.id, true);
        });
      }
    }
    // Second condition
    else if (data.isFullBookingView == true && data.booking) {
      this.isFullBookingView = data.isFullBookingView;
      this.booking = data.booking;

    }
    // Set isReadOnly
    this.isReadOnly = (data.isReadOnly == true);
    if (this.item != undefined) {
      this.item.question = (data.item) ? data.item.question as Question[] : [];
      if (!data.isUpsellItem && this.facilitySummary.upsellSummaries && this.facilitySummary.upsellSummaries.length > 0) {
        this.facilitySummary.upsellSummaries.forEach((upslSum) => {
          if (upslSum.question) {
            this.isUpsellHasQuestion = true;
            this.upsellTotalQuestion += upslSum.question.length;
            upslSum.question.forEach(question => {
              this.setHiddenFalseForChildQuestions(this.item.question as Question[], question.id, true);
            });
          }
        });
      } else {
        if (!data.isUpsellItem && data.item) {
          this.item.question.forEach(question => {
            this.setHiddenFalseForChildQuestions(this.item.question as Question[], question.id, true);
          });
        }

      }
    }

    this.countAnswer();

  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.cart_subscriber) {
      this.cart_subscriber.unsubscribe();
    }
  }

  updateQuestion(ques) {
    this.isAnswerChanged = true;
    this.countAnswer();
  }

  countAnswer() {
    var count = 0;
    this.isFormValid = true;
    if (!this.isFullBookingView) {
      if (this.item) {
        if (!this.data.isUpsellItem) {
          for (var q of this.item.question) {
            var isQuestionAnswered = this.isAnswerd(this.item.question, q);
            if (isQuestionAnswered) {
              count++;
            } else if ((q.type == 'textQuestion' || q.type == 'booleanQuestion' || q.type == 'singleChoiceQuestion')
              && (q.answeredText !== undefined && q.answeredText != null && q.answeredText != '') && q.mandatory !== undefined) {
              count++;
            } else if (q.type == 'multipleChoiceQuestion') {
              var answeredCount = 0;
              if (q.bookingQuestionChoices && q.isBookingQuestion) {
                q.bookingQuestionChoices.forEach((answ, indx) => {
                  if (q.quantityRequired) {
                    if (answ.Quantity) {
                      answeredCount++;
                    }
                  } else if (answ.isSelectedAnswer) {
                    answeredCount++;
                  }
                });
                if (answeredCount > 0) {
                  count++;
                }
              } else if (q.answerChoices && !q.isBookingQuestion) {
                q.answerChoices.forEach((answ, indx) => {
                  if (q.quantityRequired) {
                    if (answ.Quantity) {
                      answeredCount++;
                    }
                  } else if (answ.isSelectedAnswer) {
                    answeredCount++;
                  }
                });
                if (answeredCount > 0) {
                  count++;
                }
              }
            } else if (q.mandatory) {
              this.isFormValid = false;
            }
          }
        }

        if (this.facilitySummary.upsellSummaries) {
          this.facilitySummary.upsellSummaries.forEach((upsl, i) => {
            if (upsl.question) {
              for (var q of upsl.question) {
                var isQuestionAnswered = this.isAnswerd(upsl.question, q); // Call to isAnswerd method
                if (isQuestionAnswered) {
                  count++;
                } else if ((q.type == 'textQuestion' || q.type == 'booleanQuestion' || q.type == 'singleChoiceQuestion')
                  && (q.answeredText !== undefined && q.answeredText != null && q.answeredText != '')
                  && q.mandatory !== undefined) {
                  count++;
                } else if (q.type == 'multipleChoiceQuestion') {
                  var answeredCount = 0;
                  if (q.bookingQuestionChoices && q.isBookingQuestion) {
                    q.bookingQuestionChoices.forEach((answ, indx) => {
                      if (q.quantityRequired) {
                        if (answ.Quantity) {
                          answeredCount++;
                        }
                      } else if (answ.isSelectedAnswer) {
                        answeredCount++;
                      }
                    });
                    if (answeredCount > 0) {
                      count++;
                    }
                  } else if (q.answerChoices && !q.isBookingQuestion) {
                    q.answerChoices.forEach((answ, indx) => {
                      if (q.quantityRequired) {
                        if (answ.Quantity) {
                          answeredCount++;
                        }
                      } else if (answ.isSelectedAnswer) {
                        answeredCount++;
                      }
                    });
                    if (answeredCount > 0) {
                      count++;
                    }
                  }
                } else if (q.mandatory) {
                  this.isFormValid = false;
                }
              }
            }
          });
        }
        this.item.totalAnsweredQuestions = count;
      }
      let totalPackageAnsweredQuestions = 0;
      if (this.package) {
        for (var question of this.package.question) {
          var isQuestionAnswerd = this.isAnswerd(this.package.question, question);
          if (isQuestionAnswerd) {
            totalPackageAnsweredQuestions++;
          }
        }
        this.package.totalAnsweredQuestions = totalPackageAnsweredQuestions;
      }
      this.answeredCurrentCount = count + totalPackageAnsweredQuestions;
    }
    //Booking Questions
    else if (this.isFullBookingView) {
      if (this.booking) {
        if (this.booking.packageQuestion && this.booking.packageQuestion.length > 0) {
          this.booking.totalAnsweredPackageQuestions = 0;
          for (var pQuestion of this.booking.packageQuestion) {
            var isQuestionAnswerd = this.isAnswerd(this.booking.packageQuestion, pQuestion);
            if (isQuestionAnswerd) {
              this.booking.totalAnsweredPackageQuestions++;
              //count++;
            }
          }
        }
        if (this.booking.facilitySummaries && this.booking.facilitySummaries.length > 0) {
          for (var facilitySummary of this.booking.facilitySummaries) {
            facilitySummary.totalQuestions = 0;
            facilitySummary.totalAnswerdQuestions = 0;
            if (facilitySummary.bookingItemSummaries && facilitySummary.bookingItemSummaries.length > 0) {
              for (var bookingitemSummary of facilitySummary.bookingItemSummaries) {
                if (bookingitemSummary.question && bookingitemSummary.question.length > 0) {
                  // item question
                  for (var itemQuestion of bookingitemSummary.question) {

                    var isQuestionAnswerd = this.isAnswerd(bookingitemSummary.question, itemQuestion);
                    if (isQuestionAnswerd) {
                      facilitySummary.totalAnswerdQuestions++;
                      //count++;
                    }
                    facilitySummary.totalQuestions++;
                  }
                }

                // upsell item question
                if (bookingitemSummary.upsellSummaries && bookingitemSummary.upsellSummaries.length > 0) {

                  bookingitemSummary.isUpsellHasQuestion = false;

                  for (var upsellSummary of bookingitemSummary.upsellSummaries) {
                    if (upsellSummary.question && upsellSummary.question.length > 0) {
                      for (var upsellQuestion of upsellSummary.question) {

                        //even if one upsell has question it should be shown
                        bookingitemSummary.isUpsellHasQuestion = true;

                        var isQuestionAnswerd = this.isAnswerd(upsellSummary.question, upsellQuestion);
                        if (isQuestionAnswerd) {
                          facilitySummary.totalAnswerdQuestions++;
                          //count++;
                        }
                        facilitySummary.totalQuestions++;
                      }
                    }//else{
                    //     bookingitemSummary.isUpsellHasQuestion = false;
                    // }
                  }
                } else {
                  bookingitemSummary.isUpsellHasQuestion = false;
                }
              }
            }

          }
        }

      }
    }
  }

  isAnswerd(question: Question[], q) {
    var isQuestionAnswerd = false;
    if ((
        (q.type == 'textQuestion' || q.type == 'booleanQuestion' || q.type == 'singleChoiceQuestion') ||
        (q.type == 'textQuestionAnswer' || q.type == 'booleanQuestionAnswer' || q.type == 'singleChoiceQuestionAnswer')
      )
      && (q.answeredText !== undefined && q.answeredText != null && q.answeredText != '')) {
      isQuestionAnswerd = true;
      if (q.type == 'booleanQuestion' || q.type == 'booleanQuestionAnswer') {
        if (q.answeredText == 'Yes') {
          this.checkForConditionalQuestion(question, event, q.id, 'true');

        } else if (q.answeredText == 'No') {
          this.checkForConditionalQuestion(question, event, q.id, 'false');
        }
      }
      if (q.type == 'singleChoiceQuestion' || q.type == 'singleChoiceQuestionAnswer') {
        this.checkForConditionalQuestion(question, event, q.id, q.answeredText);
      }
      if (q.type == 'textQuestion' || q.type == 'textQuestionAnswer') {
        this.checkForConditionalQuestion(question, event, q.id, q.answeredText);
      }
    }
    if (q.type == 'multipleChoiceQuestion' || q.type == 'multipleChoiceQuestionAnswer') {
      var selectedAnswerIds = [];
      if (q.bookingQuestionChoices && q.isBookingQuestion) {
        q.bookingQuestionChoices.forEach((answ, indx) => {
          if ((q.quantityRequired && answ.Quantity) || (!q.quantityRequired && answ.isSelectedAnswer)) {
            selectedAnswerIds.push(answ.id);
          } else {
            const index = selectedAnswerIds.indexOf(answ.id);
            if (index !== -1) {
              selectedAnswerIds.splice(index, 1); // Remove deselected answer ID
            }
          }
        });
      } else if (q.answerChoices && !q.isBookingQuestion) {
        q.answerChoices.forEach((answ, indx) => {
          if ((q.quantityRequired && answ.Quantity) || (!q.quantityRequired && answ.isSelectedAnswer)) {
            selectedAnswerIds.push(answ.id);
          } else {
            const index = selectedAnswerIds.indexOf(answ.id);
            if (index !== -1) {
              selectedAnswerIds.splice(index, 1); // Remove deselected answer ID
            }
          }
        });
      }

      if (selectedAnswerIds) {
        isQuestionAnswerd = true;
        this.checkForConditionalQuestion(question, event, q.id, selectedAnswerIds);
      }
    }
    if (q.mandatory && !isQuestionAnswerd) {
      this.isFormValid = false;
    }
    this.updateVisibleQuestionsCount(question);
    return isQuestionAnswerd;

  }

  submitBookingData() {
    console.log('submitBookingData', this.booking);
    // return;
    if (this.isFormValid) {
      if (this.isFullBookingView) {
        this.onSubmit.emit({booking: this.booking, isAnswerChanged: this.isAnswerChanged});
        this.dialog.closeAll();
      }
    } else {
      this.openSnackBarError(['Please fill all the mandatory questions.']);
    }

  }


  submitData(data) {
    this.countAnswer();
    if (this.isFormValid) {
      if (!this.isFullBookingView && this.data.isEditMode == true) {
        this.onSubmit.emit(this.item);
        this.dialog.closeAll();
        return;
      }
      if (this.package) {
        this.data.packageFilter.PackageDetail = this.package;
        this.store.dispatch(new UpdateBookingPackage(this.data.packageFilter));
        this.dialog.closeAll();
        return;
      }
      if (this.data.isUpsellItem) {
        let item = this.facilitySummary.upsellSummaries.filter(us => us.id == this.item.id)[0];
        if (item != undefined) {
          item.question = this.item.question;
        }
      } else {
        this.facilitySummary.item.question = this.item.question;
      }
      this.store.dispatch(new UpdateFacility(this.facilitySummary));
      this.dialog.closeAll();
    } else {
      this.openSnackBarError(['Please fill all the mandatory questions.']);
    }
  }

  openSnackBarError(message) {
    this.snackBar.openFromComponent(AlertMessageComponent, {
      data: message,
      duration: 4000,
      verticalPosition: 'top'
    });
  }

  generateRandomName(): string {
    return 'answer-radio-groupUpsell' + Math.random().toString(36).substring(2);
  }

  checkForConditionalQuestion(questions: Question[], event: any, questionId: string, selectedChoice: any) {
    let _condQArray = questions.filter(q => q.primaryQuestionId === questionId);
    let _primQ = questions.find(q => q.id === questionId);

    if (!_primQ) {
      return;
    }

    let isAnyCondQMatched = false;

    if (Array.isArray(_condQArray) && _condQArray.length > 0) {
      _condQArray.forEach(_condQ => {
        if (!_condQ || !_condQ.conditionOperator) {
          return;
        }

        let conditionArray = _condQ.condition.split(',');
        let selectedValues = Array.isArray(selectedChoice) ? selectedChoice : [selectedChoice];

        switch (_condQ.conditionOperator.toString()) {
          case ConditionOperator.ANY_OPERATOR:
            if (conditionArray.some(cond => selectedValues.includes(cond))

            ) {
              _condQ.isHidden = false;
              isAnyCondQMatched = true;
            } else {
              _condQ.isHidden = true;
            }
            break;
          case ConditionOperator.EQUAL_OPERATOR:
            if (
              conditionArray.every(cond => selectedValues.includes(cond))
              && conditionArray.length == selectedValues.length
            ) {
              _condQ.isHidden = false;
              isAnyCondQMatched = true;
            } else {
              _condQ.isHidden = true;
            }
            break;
          case ConditionOperator.GREATERTHAN_OPERATOR:
            if (
              conditionArray.some(cond => selectedValues.includes(cond))

            ) {
              _condQ.isHidden = false;
              isAnyCondQMatched = true;
            } else {
              _condQ.isHidden = true;
            }
            break;
          case ConditionOperator.LESSTHAN_OPERATOR:
            if (
              conditionArray.some(cond => selectedValues.includes(cond))

            ) {
              _condQ.isHidden = false;
              isAnyCondQMatched = true;
            } else {
              _condQ.isHidden = true;
            }
            break;
          case ConditionOperator.ALL_OPERATOR:
            if (
              conditionArray.every(cond => selectedValues.includes(cond))

            ) {
              _condQ.isHidden = false;
              isAnyCondQMatched = true;
            } else {
              _condQ.isHidden = true;
            }
            break;
          default:
            break;
        }
      });
    }

    // If none of the _condQ objects match, call setHiddenFalseForChildQuestions
    if (!isAnyCondQMatched) {
      this.setHiddenFalseForChildQuestions(questions, questionId);
    }
  }


  setHiddenFalseForChildQuestions(questions: Question[], parentQuestionId: string, _freshLoad: boolean = false) {
    if (!questions || !parentQuestionId) {
      return;
    }
    const childQuestions = questions.filter(questions => questions.primaryQuestionId === parentQuestionId);
    console.log(childQuestions);
    if (childQuestions.length > 0) {
      childQuestions.forEach(child => {
        child.isHidden = true;
        if (!_freshLoad) {
          child.answeredText = undefined;
        }
        this.updateVisibleQuestionsCount(questions);
        this.setHiddenFalseForChildQuestions(questions, child.id, _freshLoad);
      });
    }
  }


  updateVisibleQuestionsCount(question: Question[]): void {

    this.visibleQuestionsCount = question.filter(question => !question.isHidden).length;

  }


  questionAnswered(question: Question[], event: any, questionId, selectedChoice) {
    this.countAnswer();
    this.isAnswerd(question, question);
  }
}
