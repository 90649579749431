export class cacheUtility {
    static pushToCache(data, cacheKey) {
        if (data) {
            var obj = {}
            obj[cacheKey] = data;
            obj['cacheTime'] = Date.now();
            localStorage.setItem("casheObj", JSON.stringify(obj))

            //chrome.storage.local.set(obj, function () {
            //    return true;
            //});
        }

    }

    static fetch(cacheKey, validCacheTime) {
        let cacheObj = JSON.parse(localStorage.getItem('casheObj'));
        if (cacheObj) {
            var _findKeyObjectArray = cacheObj[cacheKey];
            if (_findKeyObjectArray) {
                if (cacheObj['cacheTime']) {
                    if (cacheObj['cacheTime'] > Date.now() - validCacheTime * 1000) {
                        return _findKeyObjectArray;
                    }
                }
            } else {
                return null;
            }
        }
        return null;
        //localStorage.getItem([cacheKey, 'cacheTime'], (items)=> {
        //    if (items[cacheKey] && items.cacheTime && items.cacheTime) {
        //        if (items.cacheTime > Date.now() - 3600 * 1000) {
        //            return callback(items[cacheKey]); // Serialization is auto, so nested objects are no problem
        //        }
        //    }

    }
}