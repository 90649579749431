<opt-sub-header></opt-sub-header>
<main role="main">
<div class="booking--details">
    <div class="container"></div>
    <div *ngIf="isFacilityDetailLoaded">
        <opt-mmc-slider [facilityDetailDTO]="facilityDetail"
                        [searchFacilities]="searchFacilities"
                        [facilityId]="facilityId"
                        [startDateTime]="startDate"
                        [endDateTime]="endDate"
                        [facilityAvailablity]="isAvailable"
                        [defaultTimeIsAny]="isTimeAny"
                        [dateInUrl]="dateInUrl"
                        [viewOnly]="viewOnly"
                        [bookingTypeId] ="bookingTypeId"
                        [bookingDetails] ="bookingDetails"
                        (childSearchEvent)="checkAvailablity($event)"
                        (childBookingEnquiry)="bookingEnquiry($event)"
                        (changeStatus)="changeAvailbleStatus($event)">
        </opt-mmc-slider>
    </div>

    <div class="container">
        <div class="details-section">
            <div class="mmc_flex--container main-container">
                <div class="mmc_flex__item left">
                    <div class="mmc_flex--container pb-4">
                        <div class="pw-txt-b1 pw-txt-p pb-3 dark-on-light"  [innerHtml]="facilityDetail.description" ></div>

                        <div class="pw-txt-b2 dark-on-light" *ngIf="youtubeUrl">
                            Click here to see <a style="color:#0275d8 !important;cursor:pointer;" (click)="isShowVirtualTour=!isShowVirtualTour" (keydown.enter)="isShowVirtualTour=!isShowVirtualTour" tabindex="0">Virtual tour</a>
                        </div>
                    </div>

                    <div class="mmc_flex--container video" *ngIf="youtubeUrl && isShowVirtualTour">
                        <iframe [src]="youtubeUrl" frameborder="1" allowfullscreen></iframe>
                    </div>
                    <div class="mmc_flex--container facility-list" *ngIf="facilityDetail.amenities.length>0">
                        <div class="font__bold title">
                            <div class="name">
                                <div class="name__inner copp-venue-title">Amenities of <span>{{facilityDetail?.name}}</span></div>
                            </div>
                        </div>
                        <div class="items--wrapper">
                            <ul class="list-item">
                                <li *ngFor="let amenity of facilityDetail.amenities">
                                    <span><img matTooltip="{{amenity?.name}}" src="{{amenity?.icon}}" alt="{{amenity?.name}}"/></span>
                                    <span matTooltip="{{amenity?.name}}">{{amenity?.name}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!--<div class='mmc_cart' *ngIf="facilityDetail.id && (facilityDetail.linkedDocuments == null || facilityDetail.linkedDocuments== undefined || facilityDetail.linkedDocuments?.length==0)">
                        <div class="cart_facility not ng-star-inserted">
                            <div class="label-warn">
                                <div class="icon text-align"><i class="material-icons">block</i></div>
                                <div class="text-bold text-align">No documents available</div>
                            </div>
                        </div>
                    </div>-->
                    <div class="mmc_flex--container doc-sec" *ngIf="facilityDetail.linkedDocuments?.length>0">
                        <div class="title my-3">
                            <div class="name">
                                <div class="name__inner pw-txt-h3 pw-text-semi-bold pw-text-color__tertiary">Documents</div>
                            </div>
                            <!--<hr>-->
                        </div>
                        <div class="doc-container" *ngFor="let linkedDocument of facilityDetail.linkedDocuments">
                            <div class="mmc_flex--container doc-inner" *ngIf="linkedDocument">
                                <div class="mmc_flex__item--inherit doc-icon">
                                    <i class="material-icons pw-ico-color">file_copy</i>
                                </div>
                                <div class="mmc_flex__item middle-sec">
                                    <div class="pw-txt-b2 pw-text-color__tertiary font__small">{{linkedDocument?.name}}</div>
                                </div>
                                <div class="mmc_flex__item--inherit down-sec">
                                    <a download="{{downloadDocument(linkedDocument?.name,linkedDocument?.documentFileURL)}}" href="{{linkedDocument?.documentFileURL}}" *ngIf="linkedDocument.documentFileURL" target="_blank">
                                        <i class="material-icons down-icon link-icon">cloud_download</i>
                                    </a>
                                    <a download="{{downloadDocument(linkedDocument.name,linkedDocument?.documentFileURL)}}" href="{{linkedDocument?.documentFileURL}}" *ngIf="linkedDocument.documentFileURL" target="_blank">
                                        <div class="pw-txt-s2 pw-text-color__quaternary down-icon">Download</div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="map-right">
                    <div class="mmc_flex--container right">
                        <div id="googleMap" class="map">
                            <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" class="map">
                                <agm-marker [latitude]="lat" [longitude]="lng" *ngIf="lat&&lng"></agm-marker>
                            </agm-map>
                        </div>
                        <div class="contact-wrapper">
                            <div class="contact-wrapper--inner">
                                <div class="pw-txt-b2 link2"><a class="pw-text-color" href="tel:{{facilityDetail?.venue?.telephone}} " rel="nofollow">{{facilityDetail?.venue?.telephone}}</a></div>
                                <div class="pw-txt-b2 link2"><a class="pw-text-color" href="mailto:{{facilityDetail?.venue?.email}} " target="_top">{{facilityDetail?.venue?.email}}</a></div>
                                <br />
                                <div class="pw-txt-h4 pw-txt-lh-1nh pw-text-color">{{facilityDetail?.name}}</div>
                                <div class="pw-txt-s2 pw-text-color">{{facilityDetail?.directions}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      
    </div>

    <div class="carousel-wrapper" *ngIf="venueFacilities.length > 0">
        <div class="container">
            <div class="font__semibold title">
                <div class="name">
                    <h3 class="name__inner pw-txt-h3 pw-text-semi-bold pw-text-color__tertiary detail-before">Rooms of the same <span>Venue</span></h3>
                </div>
                <!--<hr>-->
            </div>
            <opt-mmc-carousel [venueFacilitiesDTO]="venueFacilities"></opt-mmc-carousel>
        </div>
    </div>

   
</div>
</main>
