<div class="modal-content">
    <div class="mat-dialog-content">
        <div class="modal-header ">
            <div class="test opt--depth__1">
                <div class="mmc_flex--container test-wrapper">
                    <div class="mmc_flex__item--inherit test-inner">
                        <div class="inner-icon-black">
                            <i class="material-icons">email</i>
                        </div>
                    </div>
                    <div class="mmc_flex__item test-inner-expand">
                        <div class="title">
                            <h1 mat-dialog-title>Doc Upload</h1>
                        </div>
                    </div>
                    <div class="mmc_flex__item--inherit test-inner-2">
                        <div class="inner-action">
                            <button class="material-icons icon1" tabindex="0">launch</button>
                            <button class="material-icons icon2" tabindex="0" (click)="close()" (keydown.enter)="close()">close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-body">
            <div class="">
                <div class="mmc_flex--container">
                    <div class="mmc_flex__item">
                        test component
                    </div>
                </div>
            </div>
            <br>
            <br>
            <div class="mmc_flex--container">
                <div class="mmc_flex__item"></div>
                <div class="mmc_flex__item--inherit">
                    <button class="mat-btn-primary mat-button" tabindex="0" (click)="close()">Send</button>
                </div>
            </div>
        </div>
    </div>
</div>




