import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { SESSION_CONFIRMATION_M_TYPE } from "src/modules/models/public-web/enum";
import { SystemOption } from "src/modules/models/public-web/SystemOption";
import { BaseComponent } from "src/modules/shared/base.component";
import { Store } from "src/modules/store";
import { AddBookingSession } from "../../../../store/public-web/public-web-actions";
import { IPublicWebSession } from "../../../../store/public-web/public-web-reducers";

import * as PBSelector from '../../../../store/public-web/public-web-selectors';

@Component({
  selector: "opt-confirmation",
  templateUrl: "./confirmation.component.html",
})
export class ConfirmationComponent extends BaseComponent implements OnInit {
  isSessionTimeReset = false;
  title = "";
  modelType;
  publicWebSession$: Observable<IPublicWebSession>;
  bookingSession: SystemOption;
  nonCommonDeliveryMethodMessage;
  header='';
  constructor(
    public dialogRef: MatDialogRef<ConfirmationComponent>,
    private store: Store<any>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
    dialogRef.disableClose = true;
    this.publicWebSession$ = this.store.select(PBSelector.selectSessionTimers);
    this.modelType = data.type;
    this.isSessionTimeReset = true;

    if (data.type === SESSION_CONFIRMATION_M_TYPE.BOOKING_SESSION_RESET) {
      this.title = "Your Session is about to expire";
    } else if ( data.type === SESSION_CONFIRMATION_M_TYPE.SESSION_EXPIRATION_CONFIRMATION) {
      this.title = "Your Session has expired";
    } else if (data.type === SESSION_CONFIRMATION_M_TYPE.NonCommonDeliveryMethodMessage) {
      this.title = "No Common Delivery Method";
      this.nonCommonDeliveryMethodMessage = data.NonCommonDeliveryMethodMessage;
    }else if(data.type === SESSION_CONFIRMATION_M_TYPE.GLOBAL_SESSION_EXPIRATION_CONFIRMATION){
      this.title = "Your Booking will cancel shortly";
    }else if(data.type === SESSION_CONFIRMATION_M_TYPE.GLOBAL_SESSION_EXPIRED){
      this.title = "Your Booking has been cancelled";
    }
    this.translate.get("SESSIONEXPIRE." +this.title).safeSubscribe(this, (res: string) => {
      this.header =res;
    });
}
  get SESSION_CONFIRMATION_M_TYPE() {
    return SESSION_CONFIRMATION_M_TYPE;
  }
  ngOnInit() {
    this.publicWebSession$.safeSubscribe(this, (config) => {
      if (config.paymentSession) {
        this.bookingSession = config.bookingSession;
        // if (
        //   this.bookingSession.minutes === "0" &&
        //   this.bookingSession.seconds === "10"
        // ) {
        //   this.confirm();
        // }
      }
    });
  }
  confirm() {
    this.bookingSession.sessionExpended = true;
    // clearInterval(this.bookingSession.sessionKey);
    // this.bookingSession.sessionKey = undefined;
    this.bookingSession.sessionEndTime +=
      this.bookingSession.bonusSessionTime * 60000;
    this.store.dispatch(new AddBookingSession(this.bookingSession));
    this.dialogRef.close();
  }
}
